import { Button, Chip, Stack, SxProps, Theme, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import { tenantToTenantEventEmitter } from "@/common/eventEmmiters/tenantToTenantEventEmitter";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useTenantToTenantCounters } from "@/common/hooks/entity/tenantToTenant/useTenantToTenantCounters";
import { usePageTabs } from "@/common/hooks/layout/usePageTabs";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  DataUpdatesHubClientMethodName,
  EntityType,
  TenantConnectionRequestDto,
  TenantConnectionRequestGetPaginatedDto,
} from "@/core/api/generated";

import TenantInline from "../../Tenant/TenantInline";
import TenantConnectionRequestCreateUpdateModal from "../TenantConnectionRequestCreateUpdateModal";
import TenantConnectionRequestViewModal from "../View/TenantConnectionRequestViewModal";

export enum TenantConnectionRequestPaginatedListTabs {
  All = "All",
  Incoming = "Incoming",
  Outcoming = "Outcoming",
}

const defaultDisplayProps = {
  breadcrumbs: true,
  counters: true,
  filters: true,
  create: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultPropagatedDeps = {
  refetch: {},
};
export type TenantConnectionRequestPaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;

export interface TenantConnectionRequestPaginatedListOwnProps {
  displayProps?: Partial<typeof defaultDisplayProps>;
  defaultValues?: {
    limit?: number;
  };
  propagatedDeps?: PropagatedDeps<TenantConnectionRequestPaginatedListPropagatedDepsMap>;
  sx?: SxProps<Theme>;
}

export type TenantConnectionRequestPaginatedListProps =
  TenantConnectionRequestPaginatedListOwnProps;

export default function TenantConnectionRequestPaginatedList({
  displayProps,
  defaultValues,
  propagatedDeps,
  sx,
}: TenantConnectionRequestPaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const currentTenant = useCurrentTenant();
  const pageTabs = usePageTabs<TenantConnectionRequestPaginatedListTabs>({
    tabIdsDefinition: TenantConnectionRequestPaginatedListTabs,
    defaultTabId: TenantConnectionRequestPaginatedListTabs.All,
    tabs: [],
  });
  const commonRequestParams = useCommonRequestParams<TenantConnectionRequestGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.TenantConnectionRequest,
    },
    defaultValues: {
      limit: defaultValues?.limit,
      params: {
        ...defaultValues,
      },
    },
  });
  const counters = useTenantToTenantCounters();

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [toView, setToView] = useState<TenantConnectionRequestDto | null | undefined>(undefined);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const isCountersVisible = displayProps?.counters && !commonRequestParams.isAnyFilters;

  const paginatedTenantConnectionRequestsRequest = useApiRequest(
    apiClient.tenantConnectionRequestsApi.apiV1TenantToTenantTenantConnectionRequestsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      tenantConnectionRequestGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
        receiverTenantId:
          pageTabs.activeTabId === TenantConnectionRequestPaginatedListTabs.Incoming
            ? currentTenant?.id || undefined
            : undefined,
        senderTenantId:
          pageTabs.activeTabId === TenantConnectionRequestPaginatedListTabs.Outcoming
            ? currentTenant?.id || undefined
            : undefined,
      },
    },
    {
      deps: [...commonRequestParams.deps, pageTabs.activeTabId, propagatedDeps?.depsMap["refetch"]],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedTenantConnectionRequests = paginatedTenantConnectionRequestsRequest?.data;

  useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entities(undefined, EntityType.TenantConnectionRequest),
      DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.TenantConnectionRequest),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedTenantConnectionRequestsRequest.handleEntityChanged(data);
    },
  });

  return (
    <Box>
      <ViewLayout
        displayProps={displayProps}
        header={
          <SimpleViewPageHeader
            title={undefined}
            primaryActions={
              displayProps?.create && (
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<AppIcon of='add' />}
                  // component={RouterLink}
                  // to={ROUTE_PATH.MANAGEMENT_TENANT_CONNECTION_REQUEST_CREATE}
                  onClick={() => setIsCreateModalOpen(true)}
                >
                  Create new connection request
                </Button>
              )
            }
          />
        }
      >
        <Stack direction='column' spacing={1}>
          <DataTabular<TenantConnectionRequestDto>
            tabs={{
              value: pageTabs.activeTabId,
              onChange: (e, val) => pageTabs.setActiveTab(val),
              tabs: [
                {
                  label: (
                    <Box>
                      {TenantConnectionRequestPaginatedListTabs.All}
                      {isCountersVisible && counters && (
                        <Tooltip title='Total'>
                          <Chip
                            sx={{ ml: 1 }}
                            size='small'
                            color='default'
                            variant='outlined'
                            label={counters?.connectionRequests?.totalCount ?? "..."}
                          />
                        </Tooltip>
                      )}
                    </Box>
                  ),
                  value: TenantConnectionRequestPaginatedListTabs.All,
                },
                {
                  label: (
                    <Box>
                      {TenantConnectionRequestPaginatedListTabs.Incoming}
                      {isCountersVisible && counters && (
                        <>
                          {((counters?.connectionRequests?.incoming?.pendingCount ?? 0 > 0) && (
                            <Tooltip title='Pending'>
                              <Chip
                                sx={{ ml: 1 }}
                                size='small'
                                color='primary'
                                variant='filled'
                                label={
                                  counters?.connectionRequests?.incoming?.pendingCount ?? "..."
                                }
                              />
                            </Tooltip>
                          )) ||
                            undefined}

                          <Tooltip title='Total'>
                            <Chip
                              sx={{ ml: 1 }}
                              size='small'
                              color='default'
                              variant='outlined'
                              label={counters?.connectionRequests?.incoming?.totalCount ?? "..."}
                            />
                          </Tooltip>
                        </>
                      )}
                    </Box>
                  ),
                  value: TenantConnectionRequestPaginatedListTabs.Incoming,
                },
                {
                  label: (
                    <Box>
                      {TenantConnectionRequestPaginatedListTabs.Outcoming}
                      {isCountersVisible && counters && (
                        <>
                          {((counters?.connectionRequests?.outcoming?.pendingCount ?? 0 > 0) && (
                            <Tooltip title='Pending'>
                              <Chip
                                sx={{ ml: 1 }}
                                size='small'
                                color='primary'
                                variant='filled'
                                label={
                                  counters?.connectionRequests?.outcoming?.pendingCount ?? "..."
                                }
                              />
                            </Tooltip>
                          )) ||
                            undefined}

                          {((counters?.connectionRequests?.outcoming?.acceptedCount ?? 0 > 0) && (
                            <Tooltip title='Accepted'>
                              <Chip
                                sx={{ ml: 1 }}
                                size='small'
                                color='success'
                                variant='outlined'
                                label={
                                  counters?.connectionRequests?.outcoming?.acceptedCount ?? "..."
                                }
                              />
                            </Tooltip>
                          )) ||
                            undefined}

                          {((counters?.connectionRequests?.outcoming?.declinedCount ?? 0 > 0) && (
                            <Tooltip title='Declined'>
                              <Chip
                                sx={{ ml: 1 }}
                                size='small'
                                color='error'
                                variant='outlined'
                                label={
                                  counters?.connectionRequests?.outcoming?.declinedCount ?? "..."
                                }
                              />
                            </Tooltip>
                          )) ||
                            undefined}

                          {((counters?.connectionRequests?.outcoming?.notRespondedCount ??
                            0 > 0) && (
                            <Tooltip title='Not responded'>
                              <Chip
                                sx={{ ml: 1 }}
                                size='small'
                                color='default'
                                variant='outlined'
                                label={
                                  counters?.connectionRequests?.outcoming?.notRespondedCount ??
                                  "..."
                                }
                              />
                            </Tooltip>
                          )) ||
                            undefined}

                          {((counters?.connectionRequests?.outcoming?.revokedCount ?? 0 > 0) && (
                            <Tooltip title='Revoked'>
                              <Chip
                                sx={{ ml: 1 }}
                                size='small'
                                color='default'
                                variant='outlined'
                                label={
                                  counters?.connectionRequests?.outcoming?.revokedCount ?? "..."
                                }
                              />
                            </Tooltip>
                          )) ||
                            undefined}

                          <Tooltip title='Total'>
                            <Chip
                              sx={{ ml: 1 }}
                              size='small'
                              color='default'
                              variant='outlined'
                              label={counters?.connectionRequests?.outcoming?.totalCount ?? "..."}
                            />
                          </Tooltip>
                        </>
                      )}
                    </Box>
                  ),
                  value: TenantConnectionRequestPaginatedListTabs.Outcoming,
                },
              ],
            }}
            columns={[
              {
                field: "globalNumber",
                title: "Number",
                flex: 1,
                renderCell: (item) => item.globalNumber,
              },

              {
                field: "status",
                title: "Status",
                flex: 1,
                renderCell: (item) => (
                  <InlineApiEnumValue type='TenantConnectionRequestStatus' value={item.status} />
                ),
              },
              {
                field: "senderTenant.id",
                title: "Sender",
                flex: 1,
                renderCell: (item) => <TenantInline entity={item.senderTenant} />,
              },
              {
                field: "receiverTenant.id",
                title: "Receiver",
                flex: 1,
                renderCell: (item) => <TenantInline entity={item.receiverTenant} />,
              },
              {
                field: "createdAt",
                title: "Created at",
                flex: 1,
                renderCell: (item) => <Datetime datetime={item.createdAt} withDurationFromNow />,
              },
            ]}
            rows={paginatedTenantConnectionRequests?.items}
            isLoading={paginatedTenantConnectionRequestsRequest.isLoading}
            getRowId={(item) => item.id!}
            // rowTo={(item) => ROUTE_PATH.MANAGEMENT_TENANT_CONNECTION_REQUEST_VIEW(item.id)}
            onRowClick={(item) => {
              setToView(item);
              setIsViewModalOpen(true);
            }}
            // renderRowAction={({ item }) => (
            //   <MenuWithTrigger
            //     withAuthCloseOnClick
            //     trigger={
            //       <IconButton
            //       >
            //         <AppIcon of='moreVert' />
            //       </IconButton>
            //     }
            //   ></MenuWithTrigger>
            // )}
            statePersistence={commonRequestParams.dataTabularProps.statePersistence}
            pagination={commonRequestParams.dataTabularProps.pagination}
            sort={commonRequestParams.dataTabularProps.sort}
            quickFilter={commonRequestParams.dataTabularProps.quickFilter}
            filters={commonRequestParams.dataTabularProps.filters}
            refetch={commonRequestParams.dataTabularProps.refetch}
          />
        </Stack>

        {/* View modal */}
        {toView && (
          <TenantConnectionRequestViewModal
            open={isViewModalOpen}
            onClose={() => setIsViewModalOpen(false)}
            viewProps={{
              tenantConnectionRequestId: toView.id,
              tenantConnectionRequest: undefined,
              onChanged: (newValue) => {
                paginatedTenantConnectionRequestsRequest.updateData((fetchedData) => {
                  fetchedData.items = fetchedData.items?.map((x) =>
                    x.id === newValue.id ? newValue : x,
                  );
                });
                tenantToTenantEventEmitter.emit("tenantConnectionRequestUpdated", newValue);
              },
            }}
          />
        )}

        {/* Create modal */}
        <TenantConnectionRequestCreateUpdateModal
          open={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
          createUpdateProps={{
            onSave: (newValue) => {
              setIsCreateModalOpen(false);
              paginatedTenantConnectionRequestsRequest.refetch();
              tenantToTenantEventEmitter.emit("tenantConnectionRequestCreated", newValue);
            },
          }}
        />
      </ViewLayout>
    </Box>
  );
}
