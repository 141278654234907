import { useParams } from "react-router-dom";

import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import VehicleDamagePaginatedList from "@/common/components/Entity/VehicleDamage/VehicleDamagePaginatedList";
import { FilterDefinition } from "@/common/filters/filterDefinition";
import { useLocationState } from "@/common/hooks/useLocationParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import { FilterDefinitionDto, VehicleDamageState } from "@/core/api/generated";

export interface VehicleDamagesPageRouteParams {
  vehicleId?: string;
}

export interface VehicleDamageListPageLocationState {
  initialValues?: {
    filterDefinition?: Nil<FilterDefinition>;
    filterDefinitionDto?: Nil<FilterDefinitionDto>;
    isResetFilterDefinition?: boolean;
    state?: VehicleDamageState;
  };
}

export type VehicleDamagesPageQueryParams = GeneralQueryParams;

export default function VehicleDamagesPage() {
  const { vehicleId } = useParams<VehicleDamagesPageRouteParams>();
  // const queryParams = useQueryParams<VehicleDamagesPageQueryParams>();
  const locationState = useLocationState<VehicleDamageListPageLocationState>();

  return (
    <>
      <VehicleDamagePaginatedList
        initialValues={locationState?.initialValues}
        defaultValues={{ vehicleId: vehicleId }}
        displayProps={{
          viewVariant: ViewLayoutVariant.Page,
        }}
        headerProps={{
          withLink: false,
          typographyProps: {
            variant: "h4",
          },
        }}
      />
    </>
  );
}
