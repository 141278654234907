import { Box, Link, Typography } from "@mui/material";
import Markdown from "react-markdown";

import { ChatMessageDto, ChatMessageNodeType } from "@/core/api/generated";

import GeneralTagDisplay from "../General/GeneralTag/GeneralTagDisplay";

interface Props {
  message: ChatMessageDto;
}

function ChatMessageBodyView({ message }: Props) {
  if (!message.body || message.body.length === 0) {
    return null;
  }

  return (
    <Box>
      {message.rootNode?.nodes?.map((node, i) => {
        if (node.type === ChatMessageNodeType.Tag && node.tag?.tag) {
          return <GeneralTagDisplay key={i} tag={node.tag!.tag!} enableLink />;
        } else if (node.type === ChatMessageNodeType.WebLink) {
          return (
            <Typography component='span' key={i} variant='body1'>
              <Link
                href={node.webLink?.url || undefined}
                target='_blank'
                sx={{ wordBreak: "break-all" }}
              >
                {node.text?.text}
              </Link>
            </Typography>
          );
        } else if (node.type === ChatMessageNodeType.Text) {
          return (
            <Typography
              component='span'
              key={i}
              variant='body1'
              sx={{
                "& > *": {
                  marginBlockStart: 0,
                  marginBlockEnd: 0,
                },
              }}
            >
              {node.text?.text}
            </Typography>
          );
        } else if (node.type === ChatMessageNodeType.MarkdownText) {
          return (
            <Typography
              component='span'
              key={i}
              variant='body1'
              sx={{
                "& > *": {
                  marginBlockStart: 0,
                  marginBlockEnd: 0,
                },
              }}
            >
              <Markdown>{node.markdown?.markdown}</Markdown>
            </Typography>
          );
        } else {
          return (
            <Typography component='span' key={i} variant='body1'>
              {node.text?.text}
            </Typography>
          );
        }
      })}
    </Box>
  );
}

export default ChatMessageBodyView;
