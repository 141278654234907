import _ from "lodash";
import { useCallback, useEffect } from "react";

import { NegotiationAllowedActionsDto } from "@/core/api/generated";
import { getNegotiationAllowedActions } from "@/store/communication/negotiationsSlice";

import { useAppSelector, useAppThunkDispatch } from "../../redux";

export const useNegotiationAllowedActions = (
  negotiationId?: string | null,
): NegotiationAllowedActionsDto | undefined => {
  const dispatch = useAppThunkDispatch();
  const allowedActions = useAppSelector(
    (x) =>
      x.communication.negotiations.negotiationAllowedActionsMap[negotiationId || ""] || undefined,
  );

  const dispatchDebounce = useCallback(
    _.debounce(dispatch, 5000, {
      leading: true,
      trailing: false,
    }),
    [dispatch],
  );

  useEffect(() => {
    (async () => {
      if (negotiationId && allowedActions === undefined) {
        await dispatchDebounce(
          getNegotiationAllowedActions({
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            negotiationId: negotiationId!,
          }),
        );
      }
    })();
  }, [allowedActions]);

  return allowedActions;
};
