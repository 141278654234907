import { EnvHelper } from "../helpers/env";

class ClipboardService {
  async writeText(text = "") {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text);
    } else {
      // if browser is safari
      const textArea = document.createElement("textArea") as any;
      textArea.readOnly = true;
      textArea.contentEditable = true;
      textArea.value = text;
      document.body.appendChild(textArea);
      let range, selection;
      if (
        navigator.userAgent.indexOf("Safari") !== -1 &&
        navigator.userAgent.indexOf("Chrome") === -1 &&
        navigator.userAgent.indexOf("Chromium") === -1
      ) {
        range = document.createRange();
        range.selectNodeContents(textArea);
        selection = window.getSelection();
        selection?.removeAllRanges();
        selection?.addRange(range);
        textArea.setSelectionRange(0, 999999);
      } else {
        textArea.select();
      }
      document.execCommand("copy");
      document.body.removeChild(textArea);
    }
  }
  async readText() {
    return navigator.clipboard.readText();
  }
}

export class MockedClipboard {
  private text: string | undefined;
  constructor() {
    this.text = undefined;
  }

  async writeText(text = "") {
    return new Promise<void>((resolve) => {
      this.text = text;
      resolve();
    });
  }

  async readText() {
    return new Promise((resolve) => resolve(this.text));
  }
}

export const mockClipboard = new MockedClipboard();

export const clipboardService = !EnvHelper.isTestingAny ? new ClipboardService() : mockClipboard;
