import _ from "lodash";
import moment from "moment";

import { appCommonConfig } from "@/config/config";
import { apiClient } from "@/core/api/ApiClient";
import {
  AccessoryStatus,
  AccessoryType,
  AdminTestDomainEventType,
  AllocationStatus,
  AppModule,
  AppPermission,
  ApprovalResponseType,
  ApprovalStatus,
  AssetEntityType,
  AutomationCreateMode,
  AvailabilityStatus,
  BillingPeriod,
  ChatActivityCategory,
  ChatActivityType,
  ChatEventCategory,
  ChatEventType,
  ChatHistoryItemType,
  ChatMessageNodeType,
  ChatType,
  ConsensusType,
  ContactChannel,
  ContractAssessmentFlowStateFilterType,
  ContractCommunicationSubjectType,
  ContractFilterType,
  ContractProductsSubscriptionsStateFilterType,
  ContractReminderType,
  ContractStage,
  ContractType,
  CurrencyCode,
  CustomerType,
  DamageCostEvaluationStage,
  DamageCostEvaluationStatusInContract,
  DamageDetectionStatusInContract,
  DamageTypeCategory,
  DataGrantPermission,
  DataGrantType,
  DiscountType,
  DiscountValueType,
  DocumentSourcingStrategy,
  DocumentStage,
  DocumentStatus,
  DocumentType,
  EmailProviderType,
  EntitySourceSubType,
  EntitySourceType,
  EntityType,
  EnumCatalogDto,
  EnumDto,
  EnumTransitionSpecDto,
  FileAccessLevel,
  FileUsageLockSubjectType,
  FileUsageVersion,
  FilterOperator,
  FrontEndAppType,
  GeneralHistoryEventType,
  GeneralHistoryEventTypeOfVehicle,
  GeneralHistoryEventTypeOfVehicleDamage,
  GeneralHistorySubjectType,
  GeneralHistoryType,
  GeneralScopeType,
  GeneralStatusIdentifier,
  GeneralStatusSubjectType,
  GlobalSearchCategory,
  HashAlgorithmType,
  ImportEntityType,
  ImportRowResultStatus,
  ImportStatus,
  InAppNotificationType,
  IntegrationApiKeyStatus,
  IntegrationApiScopeType,
  InviteType,
  InviteUserType,
  InvoiceStatus,
  MaintenanceScheduleType,
  MaintenanceStage,
  MeasurementUnit,
  MileageUnitType,
  MimeBaseType,
  NegotiationStatus,
  NegotiationType,
  NegotiationValueType,
  NotificationChannel,
  NotificationDeliveryStatus,
  NotificationImportance,
  OperabilityStatus,
  ParticipantOnlineStatus,
  PartyType,
  PaymentMethod,
  PaymentRegularityType,
  PoolItemEntityType,
  PoolItemStatus,
  PoolItemType,
  PoolStructureType,
  ProposalStatus,
  RepairOperationStage,
  RepairSparePartDetalizationType,
  RepairSpecDetalizationType,
  RepairSpecItemType,
  RepairType,
  SortOrder,
  SpotType,
  SubscriptionPlanOptionForVehicleType,
  SubscriptionPlanOptionType,
  SubscriptionStatus,
  SupplierType,
  TagEntityType,
  TagSubTargetType,
  TagTargetType,
  TaxType,
  TaxValueType,
  TenantConnectionRequestResponseType,
  TenantConnectionRequestStatus,
  TenantConnectionStatus,
  TenantRequestOperationType,
  TenantRequestResponseType,
  TenantRequestStage,
  TenantRequestType,
  ThumbnailSizeType,
  UnitOfTime,
  UserMembershipType,
  UserStatus,
  UtilizationStatus,
  VehicleArea,
  VehicleBatteryHealthRating,
  VehicleBatteryHealthStatus,
  VehicleBodyType,
  VehicleDamageState,
  VehicleDrivetrainType,
  VehicleFuelType,
  VehicleGearboxType,
  VehicleHistoryItemType,
  VehiclePartCategory,
  VehiclePartDescriptor,
  VehiclePartType,
  VehicleProjection,
  VehiclePropulsionType,
  VehicleSize,
  VehicleStatus,
  VehicleTaxType,
  VehicleType,
  VehicleVisualModelType,
  WashScheduleType,
  WashStage,
  WashType,
  WebhookActivationStatus,
  WebhookEventType,
  WheelOperationScheduleType,
  WheelOperationStage,
  WheelServiceType,
  WhoType,
} from "@/core/api/generated";
import { TeslaAuthTokenType } from "@/core/api/generated/v0.1-demo";

import { LocalStorageKey } from "../constants/localStorage";
import { EnvHelper } from "../helpers/env";
import { LocalStorageHelper } from "../helpers/localStorage";
import { ObjectHelper } from "../helpers/object";
import { StringHelper } from "../helpers/string";
import { TextHelper } from "../helpers/text";
import { TypeHelper } from "../helpers/type";

/** Maps enum name to enum object (as TS doesn't have nameof()).
 * @type {[EnumKey]: [EnumObj]}
 */
export const ApiEnumMap = {
  AccessoryStatus: AccessoryStatus,
  AppModule: AppModule,
  AppPermission: AppPermission,
  BillingPeriod: BillingPeriod,
  ChatEventCategory: ChatEventCategory,
  ChatEventType: ChatEventType,
  ChatHistoryItemType: ChatHistoryItemType,
  ChatMessageNodeType: ChatMessageNodeType,
  ChatType: ChatType,
  ChatActivityCategory: ChatActivityCategory,
  ChatActivityType: ChatActivityType,
  ContractStage: ContractStage,
  ContractType: ContractType,
  CustomerType: CustomerType,
  SupplierType: SupplierType,
  DamageTypeCategory: DamageTypeCategory,
  EntitySourceType: EntitySourceType,
  EntitySourceSubType: EntitySourceSubType,
  DocumentSourcingStrategy: DocumentSourcingStrategy,
  DocumentStage: DocumentStage,
  DocumentStatus: DocumentStatus,
  DocumentType: DocumentType,
  EntityType: EntityType,
  GeneralScopeType: GeneralScopeType,
  InAppNotificationType: InAppNotificationType,
  InviteUserType: InviteUserType,
  InviteType: InviteType,
  InvoiceStatus: InvoiceStatus,
  MimeBaseType: MimeBaseType,
  NegotiationStatus: NegotiationStatus,
  NegotiationType: NegotiationType,
  NegotiationValueType: NegotiationValueType,
  NotificationChannel: NotificationChannel,
  NotificationDeliveryStatus: NotificationDeliveryStatus,
  NotificationImportance: NotificationImportance,
  ParticipantOnlineStatus: ParticipantOnlineStatus,
  PartyType: PartyType,
  PaymentMethod: PaymentMethod,
  ApprovalResponseType: ApprovalResponseType,
  ApprovalStatus: ApprovalStatus,
  ProposalStatus: ProposalStatus,
  GlobalSearchCategory: GlobalSearchCategory,
  SubscriptionStatus: SubscriptionStatus,
  ThumbnailSizeType: ThumbnailSizeType,
  UserStatus: UserStatus,
  VehicleArea: VehicleArea,
  VehicleBodyType: VehicleBodyType,
  VehicleFuelType: VehicleFuelType,
  VehiclePartCategory: VehiclePartCategory,
  VehiclePartType: VehiclePartType,
  VehicleProjection: VehicleProjection,
  VehicleType: VehicleType,
  VehicleVisualModelType: VehicleVisualModelType,
  VehicleSize: VehicleSize,
  VehicleBatteryHealthStatus: VehicleBatteryHealthStatus,
  VehicleBatteryHealthRating: VehicleBatteryHealthRating,
  VehiclePropulsionType: VehiclePropulsionType,
  RepairSparePartDetalizationType: RepairSparePartDetalizationType,
  RepairSpecDetalizationType: RepairSpecDetalizationType,
  RepairSpecItemType: RepairSpecItemType,
  MeasurementUnit: MeasurementUnit,
  CurrencyCode: CurrencyCode,
  TaxType: TaxType,
  TaxValueType: TaxValueType,
  UnitOfTime: UnitOfTime,
  DamageCostEvaluationStage: DamageCostEvaluationStage,
  ConsensusType: ConsensusType,
  TagTargetType: TagTargetType,
  TagSubTargetType: TagSubTargetType,
  TagEntityType: TagEntityType,
  VehicleDamageState: VehicleDamageState,
  DamageDetectionStatusInContract: DamageDetectionStatusInContract,
  DamageCostEvaluationStatusInContract: DamageCostEvaluationStatusInContract,
  ContactChannel: ContactChannel,
  ContractCommunicationSubjectType: ContractCommunicationSubjectType,
  ContractReminderType: ContractReminderType,
  ContractAssessmentFlowStateFilterType: ContractAssessmentFlowStateFilterType,
  ContractProductsSubscriptionsStateFilterType: ContractProductsSubscriptionsStateFilterType,
  DiscountType: DiscountType,
  DiscountValueType: DiscountValueType,
  RepairOperationStage: RepairOperationStage,
  VehicleHistoryItemType: VehicleHistoryItemType,
  VehiclePartDescriptor: VehiclePartDescriptor,
  UserMembershipType: UserMembershipType,
  WhoType: WhoType,
  SpotType: SpotType,
  AccessoryType: AccessoryType,
  AssetEntityType: AssetEntityType,
  SubscriptionPlanOptionType: SubscriptionPlanOptionType,
  SubscriptionPlanOptionForVehicleType: SubscriptionPlanOptionForVehicleType,
  PaymentRegularityType: PaymentRegularityType,
  PoolStructureType: PoolStructureType,
  PoolItemEntityType: PoolItemEntityType,
  PoolItemType: PoolItemType,
  PoolItemStatus: PoolItemStatus,
  VehicleGearboxType: VehicleGearboxType,
  VehicleDrivetrainType: VehicleDrivetrainType,
  ImportEntityType: ImportEntityType,
  ImportStatus: ImportStatus,
  ImportRowResultStatus: ImportRowResultStatus,
  IntegrationApiScopeType: IntegrationApiScopeType,
  IntegrationApiKeyStatus: IntegrationApiKeyStatus,
  TenantConnectionRequestStatus: TenantConnectionRequestStatus,
  TenantConnectionStatus: TenantConnectionStatus,
  TenantConnectionRequestResponseType: TenantConnectionRequestResponseType,
  TenantRequestType: TenantRequestType,
  TenantRequestStage: TenantRequestStage,
  TenantRequestResponseType: TenantRequestResponseType,
  TenantRequestOperationType: TenantRequestOperationType,
  DataGrantPermission: DataGrantPermission,
  DataGrantType: DataGrantType,
  ContractFilterType: ContractFilterType,
  AllocationStatus: AllocationStatus,
  RepairType: RepairType,
  VehicleTaxType: VehicleTaxType,
  EmailProviderType: EmailProviderType,
  WebhookEventType: WebhookEventType,
  WebhookActivationStatus: WebhookActivationStatus,
  FilterOperator: FilterOperator,
  SortOrder: SortOrder,
  AutomationCreateMode: AutomationCreateMode,
  GeneralHistoryType: GeneralHistoryType,
  GeneralHistoryEventType: GeneralHistoryEventType,
  GeneralHistoryEventTypeOfVehicle: GeneralHistoryEventTypeOfVehicle,
  GeneralHistoryEventTypeOfVehicleDamage: GeneralHistoryEventTypeOfVehicleDamage,
  AdminTestDomainEventType: AdminTestDomainEventType,
  GeneralHistorySubjectType: GeneralHistorySubjectType,
  TeslaAuthTokenType: TeslaAuthTokenType,
  WheelOperationScheduleType: WheelOperationScheduleType,
  WheelServiceType: WheelServiceType,
  WheelOperationStage: WheelOperationStage,
  WashType: WashType,
  WashScheduleType: WashScheduleType,
  WashStage: WashStage,
  MaintenanceScheduleType: MaintenanceScheduleType,
  MaintenanceStage: MaintenanceStage,
  VehicleStatus: VehicleStatus,
  GeneralStatusSubjectType: GeneralStatusSubjectType,
  AvailabilityStatus: AvailabilityStatus,
  UtilizationStatus: UtilizationStatus,
  OperabilityStatus: OperabilityStatus,
  GeneralStatusIdentifier: GeneralStatusIdentifier,
  FileAccessLevel: FileAccessLevel,
  HashAlgorithmType: HashAlgorithmType,
  FileUsageVersion: FileUsageVersion,
  FileUsageLockSubjectType: FileUsageLockSubjectType,
  MileageUnitType: MileageUnitType,
  FrontEndAppType: FrontEndAppType,
} as const;
export type ApiEnumMap = {
  [Key in keyof typeof ApiEnumMap]: (typeof ApiEnumMap)[Key][keyof (typeof ApiEnumMap)[Key]];
};

/** Enum that describes all the enum names.
 * @type {[EnumKey]: [EnumKey]}
 */
export const ApiEnumName = _.chain(Object.keys(ApiEnumMap))
  .keyBy((key) => key)
  .mapValues((key) => key)
  .value() as { [Key in keyof typeof ApiEnumMap]: Key };
export type ApiEnumName = (typeof ApiEnumName)[keyof typeof ApiEnumName];

/** Maps enum name to enum values type. */
export type ApiEnumValueTypeMap = {
  [Key in ApiEnumName]: (typeof ApiEnumMap)[Key][keyof (typeof ApiEnumMap)[Key]];
};

// /** Describes enum object type with the given enum name. */
// export type ApiEnumObj<TEnumName extends ApiEnumName> = Record<
//   ApiEnumKey<TEnumName>,
//   ApiEnumValue<TEnumName>
// >;
export type ApiEnumObj<TEnumName extends ApiEnumName> = (typeof ApiEnumMap)[TEnumName];
// export type ApiEnumObj<TEnumName extends ApiEnumName> = {
//   [Key in keyof (typeof ApiEnumMap)[TEnumName]]: (typeof ApiEnumMap)[TEnumName][keyof (typeof ApiEnumMap)[TEnumName]];
// };

/** Describes enum key type with the given enum name. */
export type ApiEnumKey<TEnumName extends ApiEnumName> = keyof (typeof ApiEnumMap)[TEnumName];

/** Describes enum value type with the given enum name. */
export type ApiEnumValue<TEnumName extends ApiEnumName> = ApiEnumMap[TEnumName];

export type ApiEnumKeyValuePair<TEnumName extends ApiEnumName> = {
  key: ApiEnumKey<TEnumName>;
  value: ApiEnumValue<TEnumName>;
};
// export type ApiEnumKeyValuePair<TEnumName extends ApiEnumName> = {
//   key: keyof ApiEnumObj<TEnumName>;
//   value: ApiEnumObj<TEnumName>[keyof ApiEnumObj<TEnumName>];
// };

interface EnumCatalogInMemory {
  /** Fetched from API. */
  catalog: EnumCatalogDto;
  /** Computed. */
  renewedAt: string;
  /** Computed. App version when the catalog was saved. */
  appVersion: string;
  /** Computed. Local version of the catalog structure. */
  version: string;
  /** Computed. {[enumName]: {[enumValue]: dto}} */
  enumsMapByEnumValue: Record<string, Record<string, EnumDto>>;
}

interface EnumCatalogInLocalStorage {
  /** Fetched from API. */
  catalog: EnumCatalogDto;
  /** Computed. */
  renewedAt: string;
  /** Computed. App version when the catalog was saved. */
  appVersion: string;
  /** Computed. Local version of the catalog structure. */
  version: string;
}

const noneEnumValue = "None";

export class EnumService {
  /** NB: for now set big limit, but the enum catalog and its growing size will be refactored! */
  public readonly maxItemSizeInLocalStorageInBytes = 500 * 1024; // 500 KiB.
  /**  NB: `true` until refactored. */
  public readonly isForceSetItemInLocalStorage = true;

  private _catalogVersion = "1.0.0";
  private _catalog: EnumCatalogInMemory | null | undefined = undefined;

  /** Loads enum catalog from BE and stores locally.
   *  Also, renews the catalog from time to time.
   */
  public async handleEnumCatalogOnAppInit() {
    const catalog = this.getOrRestoreEnumCatalog();
    const isRenewCatalog =
      !catalog ||
      catalog.appVersion !== appCommonConfig.version ||
      catalog.version !== this._catalogVersion ||
      EnvHelper.isLocalhostAny;
    // console.log("EnumService.handleEnumCatalogOnAppInit.", {
    //   catalog: _.cloneDeep(catalog),
    //   isRenewCatalog,
    // });
    if (isRenewCatalog) {
      await this._renewEnumCatalog();
    }
  }

  /** Reads catalog from the memory or restores persisted catalog and sets it to the memory. */
  public getOrRestoreEnumCatalog(): EnumCatalogInMemory | null {
    if (!this._catalog) {
      this._catalog = this.getEnumCatalogFromLocalStorage();
    }
    return this._catalog;
  }

  /** Returns different enum name naming variants to handle different cases (camel, pascal, etc).
   * E.g. enum MyStatus can be in camelCase in JSON response from server -> myStatus.
   */
  public getEnumNameNamingVariants(enumName: ApiEnumName): string[] {
    const keys = _.uniq([
      enumName,
      TextHelper.toCamelCase(enumName),
      TextHelper.toPascalCase(enumName),
    ]);
    return keys;
  }

  /** Returns different enum value naming variants to handle different cases (camel, pascal, etc).
   * E.g. enum MyStatus can be in camelCase in JSON response from server -> myStatus.
   */
  public getEnumValueNamingVariants<TEnumName extends ApiEnumName>(
    enumName: TEnumName,
    enumValue: ApiEnumValue<TEnumName>,
  ): string[] {
    // check for different keys to handle different cases (camel, pascal, etc)
    const keys = _.uniq([
      enumValue,
      TextHelper.toCamelCase(enumValue),
      TextHelper.toPascalCase(enumValue),
    ]);
    return keys;
  }

  /** Returns full enum object. */
  public getEnumObj<TEnumName extends ApiEnumName>(enumName: TEnumName): ApiEnumObj<TEnumName> {
    return ApiEnumMap[enumName];
  }

  public getEnumObjKeyValuePairs<TEnumName extends ApiEnumName>(
    enumName: TEnumName,
  ): ApiEnumKeyValuePair<TEnumName>[] {
    if (!this._catalog) {
      return [];
    }

    const result: ApiEnumKeyValuePair<TEnumName>[] = [];
    const enumObj = this.getEnumObj(enumName);
    Object.keys(enumObj).map((key) => {
      const enumKey = key as ApiEnumKey<TEnumName>;
      const enumKeyValue = enumObj[enumKey] as unknown as ApiEnumValue<TEnumName>;
      result.push({
        key: enumKey,
        value: enumKeyValue,
      });
    });

    return result;
  }

  /** Returns all enum values. */
  public getEnumValues<TEnumName extends ApiEnumName, TEnumNameLinkedTo extends ApiEnumName>(
    enumName: TEnumName,
    options?: {
      only?: Array<ApiEnumValue<TEnumName>>;
      except?: Array<ApiEnumValue<TEnumName>>;
      exceptNone?: boolean;
      linkedTo?: { type: TEnumNameLinkedTo; value: ApiEnumValue<TEnumNameLinkedTo> };
    },
  ): Array<ApiEnumValue<TEnumName>> {
    const enumObj = this.getEnumObj(enumName);
    if (!enumObj) {
      return [];
    }
    let values = Object.values(enumObj) as Array<ApiEnumValue<TEnumName>>;
    if (options?.only) {
      values = values.filter((value) => (options?.only ? options.only.includes(value) : true));
    }
    if (options?.except) {
      values = values.filter((value) => (options?.except ? !options.except.includes(value) : true));
    }
    if (options?.exceptNone) {
      values = values.filter((value) => value !== noneEnumValue);
    }
    if (options?.linkedTo) {
      values = values.filter((value) =>
        this.isEnumLinkedToOtherEnum(
          { type: enumName, value },
          { type: options.linkedTo!.type, value: options.linkedTo!.value },
        ),
      );
    }
    return values;
  }

  /** Returns enum dto for the enum value obtained from the BE API. */
  public getEnumDto<TEnumName extends ApiEnumName>(
    enumName: TEnumName,
    enumValue: ApiEnumValue<TEnumName>,
  ): EnumDto | null {
    if (!this._catalog) {
      return null;
    }

    const enumNameVariants = this.getEnumNameNamingVariants(enumName);
    for (const key of enumNameVariants) {
      if (key in this._catalog.enumsMapByEnumValue) {
        return this._catalog.enumsMapByEnumValue[key][enumValue] || null;
      }
    }
    return null;
  }

  /** Returns enum dtos for all enum values obtained from the BE API. */
  public getEnumDtos<TEnumName extends ApiEnumName>(
    enumName: TEnumName,
    options?: {
      exceptNone?: boolean;
    },
  ): EnumDto[] {
    const enumNameVariants = this.getEnumNameNamingVariants(enumName);
    const defaultList: EnumDto[] = this.getEnumObjKeyValuePairs(enumName).map((x) => ({
      name: x.key.toString(),
      value: x.value as string,
    }));
    let dtos: EnumDto[] | null = null;
    if (this._catalog?.catalog?.enumsMap) {
      for (const key of enumNameVariants) {
        if (key in this._catalog.catalog.enumsMap) {
          dtos = this._catalog.catalog!.enumsMap[key] || null;
        }
      }
    }
    if (options?.exceptNone) {
      dtos = dtos?.filter((x) => x.value !== noneEnumValue) || null;
    }
    return dtos || defaultList;
  }

  /** Returns user-friendly name for the enum value or fallbacks to the enum value. */
  public getEnumValueName<TEnumName extends ApiEnumName>(
    enumName: TEnumName,
    enumValue: ApiEnumValue<TEnumName> | null | undefined,
  ): string {
    if (!enumValue) {
      return "";
    }
    return this.getEnumDto(enumName, enumValue)?.name || enumValue;
  }

  /** Return user-friendly enum value description or empty string. */
  public getEnumValueDescription<TEnumName extends ApiEnumName>(
    enumName: TEnumName,
    enumValue: ApiEnumValue<TEnumName> | null | undefined,
  ): string {
    if (!enumValue) {
      return "";
    }
    return this.getEnumDto(enumName, enumValue)?.description || "";
  }

  /** Checks if EnumA value is linked to EnumB value, by checking metadata returned from the BE (dtos). */
  public isEnumLinkedToOtherEnum<TEnumName1 extends ApiEnumName, TEnumName2 extends ApiEnumName>(
    enum1: {
      type: TEnumName1;
      value: ApiEnumValue<TEnumName1>;
    },
    enum2: {
      type: TEnumName2;
      value: ApiEnumValue<TEnumName2>;
    },
  ): boolean {
    const dto1 = this.getEnumDto(enum1.type, enum1.value);
    return dto1 && dto1.enumLinks && dto1.enumLinks.length !== 0
      ? dto1.enumLinks.some(
          (link) =>
            link.enumTypeName === enum2.type &&
            (link.enumValuesMap![enum2.value] || link.enumValues!.includes(enum2.value)),
        )
      : false;
  }

  // #region Enum transition (specs retrieved from BE)

  /** Checks if transition */
  public canTransit<TEnumName extends ApiEnumName>(params: {
    enumName: TEnumName;
    transitionSpec: EnumTransitionSpecDto;
    fromEnumValue: ApiEnumValue<TEnumName>;
    toEnumValue: ApiEnumValue<TEnumName>;
  }): boolean {
    const fromEnumValueKeys = this.getEnumValueNamingVariants(
      params.enumName,
      params.fromEnumValue,
    );
    const entry = fromEnumValueKeys
      .map((key) => params.transitionSpec.entriesMap![key])
      .find((x) => !_.isNil(x));
    if (!entry) {
      return false;
    }
    const canTransit = !!entry.targets && entry.targets.includes(params.toEnumValue);
    return canTransit;
  }

  // #endregion

  // #region Private

  /** Builds in-memory representation of the catalog with all the computed fields. */
  private _buildEnumCatalogInMemory(params: {
    dto: EnumCatalogDto | undefined;
    persisted: EnumCatalogInLocalStorage | undefined;
  }): EnumCatalogInMemory | null {
    if (!params.dto && !params.persisted?.catalog) {
      return null;
    }

    const catalog: EnumCatalogInMemory = {
      catalog: (params.dto || params.persisted?.catalog)!,
      // if there is 'persisted' then we have to set 'appVersion' from it (or 'version' for backward compatibility),
      // otherwise from app info because 'dto' was fetched from BE
      appVersion: params.persisted
        ? params.persisted?.appVersion || params.persisted?.version || ""
        : appCommonConfig.version,
      version: this._catalogVersion,
      renewedAt: params.persisted?.renewedAt || moment().utc().format(),
      enumsMapByEnumValue: {},
    };

    // handle computed fields
    for (const enumName in catalog.catalog.enumsMap) {
      const dtos = catalog.catalog.enumsMap![enumName];
      catalog.enumsMapByEnumValue[enumName] = {};
      for (const dto of dtos) {
        catalog.enumsMapByEnumValue[enumName][dto.value!] = dto;
      }
    }

    return catalog;
  }

  private async _renewEnumCatalog(): Promise<void> {
    try {
      const response = await apiClient.enumsApi.apiV1EnumsCatalogGet();
      this._catalog = this._buildEnumCatalogInMemory({ dto: response.data, persisted: undefined });
      if (this._catalog) {
        console.log("EnumService. Renew enum catalog.", {
          catalogDto: this._catalog.catalog,
          catalog: this._catalog,
          catalogDtoSizeKiB: StringHelper.getSizeInKiB(JSON.stringify(this._catalog.catalog)),
          catalogInMemorySizeKiB: StringHelper.getSizeInKiB(JSON.stringify(this._catalog)),
        });
        this.persistEnumCatalogToLocalStorage(this._catalog);
      }
    } catch (err) {
      console.error("Unable to fetch enum catalog:", err);
    }
  }

  public getEnumCatalogFromLocalStorage(): EnumCatalogInMemory | null {
    const persisted = LocalStorageHelper.getTypedJsonItem<EnumCatalogInLocalStorage>(
      LocalStorageKey.enumCatalog,
    );
    const catalog: EnumCatalogInMemory | null = persisted
      ? this._buildEnumCatalogInMemory({ dto: undefined, persisted: persisted })
      : null;
    return catalog;
  }

  public persistEnumCatalogToLocalStorage(catalog: EnumCatalogInMemory | null): void {
    // persist only required info to not exceed the local storage limits
    const persisted: EnumCatalogInLocalStorage | null = catalog?.catalog
      ? {
          catalog: catalog.catalog,
          renewedAt: catalog.renewedAt,
          appVersion: catalog.appVersion,
          version: catalog.version,
        }
      : null;

    // size optimization - remove key/values that are empty
    if (persisted) {
      // console.log("EnumService.persistEnumCatalogToLocalStorage. Before compression.", {
      //   persisted: persisted,
      //   sizeKiB: StringHelper.getSizeInKiB(JSON.stringify(persisted)),
      // });
      persisted.catalog = ObjectHelper.compactObjectDeep(persisted.catalog, {
        isEmpty: (params) =>
          TypeHelper.isNil(params.value) ||
          TypeHelper.isEmptyString(params.value) ||
          TypeHelper.isEmptyArray(params.value),
      });
      // console.log("EnumService.persistEnumCatalogToLocalStorage. After compression.", {
      //   persisted: persisted,
      //   sizeKiB: StringHelper.getSizeInKiB(JSON.stringify(persisted)),
      // });
    }

    // validate
    const json = persisted ? JSON.stringify(persisted) : null;
    const sizeBytes = StringHelper.getSizeInBytes(json);
    if (sizeBytes > this.maxItemSizeInLocalStorageInBytes) {
      console.error(
        "EnumService.persistEnumCatalogToLocalStorage. Item size in local storage is exceeded:",
        {
          sizeBytes,
          sizeKiB: StringHelper.getSizeInBytes(json),
          maxItemSizeInLocalStorageInBytes: this.maxItemSizeInLocalStorageInBytes,
          isForceSetItemInLocalStorage: this.isForceSetItemInLocalStorage,
        },
        persisted,
      );
      throw new Error("Item size in local storage is exceeded.");
    }

    LocalStorageHelper.setJsonItem(LocalStorageKey.enumCatalog, persisted, {
      isForce: this.isForceSetItemInLocalStorage,
    });
  }

  // #endregion
}

export const enumService = new EnumService();

// setTimeout(() => {
//   console.log(1, enumService.getEnumValueName(ApiEnumName.ContractStage, ContractStage.Completed));
//   console.log(
//     2,
//     enumService.getEnumValueDescription(ApiEnumName.ContractStage, ContractStage.Completed),
//   );
//   console.log(3, enumService.getEnumObj(ApiEnumName.ContractStage));
//   console.log(4, enumService.getEnumObjKeyValuePairs(ApiEnumName.ContractStage));
// }, 3000);
