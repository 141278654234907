import DamageCostEvaluationPaginatedList from "@/common/components/Entity/DamageCostEvaluation/ListView/DamageCostEvaluationPaginatedList";
import { useLocationState } from "@/common/hooks/useLocationParams";
import { FilterDefinitionDto } from "@/core/api/generated";

export interface DamageCostEvaluationListPageLocationState {
  initialValues?: {
    filterDefinitionDto?: Nil<FilterDefinitionDto>;
  };
}

export default function DamageCostEvaluationsPage() {
  const locationState = useLocationState<DamageCostEvaluationListPageLocationState>();

  return (
    <>
      <DamageCostEvaluationPaginatedList initialValues={locationState?.initialValues} />
    </>
  );
}
