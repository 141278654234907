import { Box, ClickAwayListener } from "@mui/material";
import _ from "lodash";
import { useCallback } from "react";

import { FeatureName } from "@/common/constants/featureName";
import { useAppDispatch } from "@/common/hooks/redux";
import { featureManager } from "@/config/features";
import * as chatActivitySlice from "@/store/communication/chatActivityOverviewSlice";

interface Props {
  chatId: string;
  children?: React.ReactNode;
}

/** Tracks whether user interacts with the chat. */
function ChatInteractionTracker({ chatId, children }: Props) {
  const dispatch = useAppDispatch();

  const handleChatUserInteraction = useCallback(
    _.throttle(() => {
      if (featureManager.isEnabled(FeatureName.ChatActivity)) {
        dispatch(chatActivitySlice.trackChatUserInteractionStarted({ chatId }));
      }
    }, 100),
    [dispatch, chatActivitySlice, chatId],
  );

  const handleChatUserInteractionEnded = () => {
    if (featureManager.isEnabled(FeatureName.ChatOnlineStatus)) {
      dispatch(chatActivitySlice.trackChatUserInteractionEnded({ chatId }));
    }
  };

  return (
    <ClickAwayListener onClickAway={handleChatUserInteractionEnded}>
      <Box
        data-testid='chat-interaction-tracker'
        onClick={handleChatUserInteraction}
        onFocus={handleChatUserInteraction}
        onMouseEnter={handleChatUserInteraction}
        onBlur={handleChatUserInteractionEnded}
        sx={{ height: "inherit" }}
      >
        {children}
      </Box>
    </ClickAwayListener>
  );
}

export default ChatInteractionTracker;
