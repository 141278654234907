import { Alert, AlertTitle, Stack, SxProps, Theme } from "@mui/material";
import _ from "lodash";
import { useMemo } from "react";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import ContractInline from "@/common/components/Entity/Contract/ContractInline";
import GeneralInspectorDisplay from "@/common/components/Entity/General/Display/GeneralInspectorDisplay";
import AdminTenantInline from "@/common/components/Entity/Tenant/AdminTenantInline";
import VehicleInline from "@/common/components/Entity/Vehicle/VehicleInline";
import VehicleMileageInline from "@/common/components/Entity/Vehicle/VehicleMileageInline";
import VehicleVisualModelInline from "@/common/components/Entity/VehicleVisualModel/VehicleVisualModelInline";
import EntityTagsInfoDisplay from "@/common/components/EntityInfo/EntityTagsInfoDisplay";
import TableCellContentOfAttachments from "@/common/components/Table/TableCell/TableCellContentOfAttachments";
import TableCellContentOfCount from "@/common/components/Table/TableCell/TableCellContentOfCount";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { TagStaticFilterInputDto, TagsHelper } from "@/common/helpers/entity/tags";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  AdminDamageDetectionGetPaginatedDto,
  DamageDetectionDto,
  EntityType,
  GeneralAttachedTagsInputDto,
  TagEntityType,
} from "@/core/api/generated";

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  filters: true,
  create: false,
  edit: false,
  delete: false,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultPropagatedDeps = {
  refetch: {},
};
export type DamageDetectionPaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;

type DamageDetectionGetPaginatedInputDto = Omit<AdminDamageDetectionGetPaginatedDto, "tags"> & {
  tags?: TagStaticFilterInputDto;
};

export interface DamageDetectionPaginatedListOwnProps {
  displayProps?: Partial<typeof defaultDisplayProps>;
  defaultValues?: {
    limit?: number;
    vehicleId?: string | null;
    contractId?: string | null;
    customerId?: string | null;
    anyVehicleDamageIds?: string[] | null;
  };
  propagatedDeps?: PropagatedDeps<DamageDetectionPaginatedListPropagatedDepsMap>;
  sx?: SxProps<Theme>;
}

export type DamageDetectionPaginatedListProps = DamageDetectionPaginatedListOwnProps;

export default function DamageDetections({
  displayProps,
  defaultValues,
  propagatedDeps,
  sx,
}: DamageDetectionPaginatedListProps) {
  const commonRequestParams = useCommonRequestParams<DamageDetectionGetPaginatedInputDto>({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityInAdminArea(EntityType.DamageDetection),
    },
    initParams: (filterDefinition) => {
      const tagFilter = filterDefinition.filterDefinition?.items.find(
        (x) => x.field === getTypedPath<DamageDetectionDto>().tags.$path,
      );

      const tenantId = filterDefinition?.filterDefinition?.items
        ?.find((x) => x.field === getTypedPath<DamageDetectionDto>().tenantId.$path)
        ?.valueAsString();

      return {
        tags: {
          operator: tagFilter?.operator,
          query: tagFilter?.value as GeneralAttachedTagsInputDto,
        } as TagStaticFilterInputDto,
        tenantId: tenantId,
      };
    },
  });

  const paginatedDamageDetectionsRequest = useApiRequest(
    apiClient.adminDamageDetectionsApi.apiV1AdminDamageDetectionsGetPost,
    {
      adminDamageDetectionGetPaginatedDto: {
        ..._.omit(commonRequestParams.params, "tags"),
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        tags:
          commonRequestParams.filterDefinition?.items
            .filter((x) => x.field === getTypedPath<DamageDetectionDto>().tags.$path)
            .map((x) => TagsHelper.mapFromFilter(x))[0] || undefined,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
        tenantId: commonRequestParams.params?.tenantId,
      },
    },
    {
      deps: [
        ...commonRequestParams.deps,
        commonRequestParams.filterDefinition,
        propagatedDeps?.depsMap["refetch"],
      ],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedDamageDetections = useMemo(
    () => paginatedDamageDetectionsRequest?.data,
    [paginatedDamageDetectionsRequest.data],
  );

  return (
    <ViewLayoutV2
      displayProps={displayProps}
      header={displayProps?.header && <SimpleViewPageHeader title='Damage detections' />}
    >
      <Alert severity='warning'>
        <AlertTitle>Tenant ID Filter Required</AlertTitle>
      </Alert>
      <DataTabular
        columns={[
          {
            field: getTypedPath<DamageDetectionDto>().id.$path,
            title: "ID",
            width: 250,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.id,
            filters: {
              fieldType: FilterFieldType.Id,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().localNumber.$path,
            title: "Number",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <Stack direction='row' spacing={0.5}>
                <span>{item.localNumber}</span>
              </Stack>
            ),
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().vehicle.id.$path,
            title: "Vehicle",
            width: 300,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => <VehicleInline entity={item.vehicle} />,
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfTenant(),
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().contract.id.$path,
            title: "Contract",
            width: 300,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => (item.contract ? <ContractInline entity={item.contract} /> : "-"),
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfContract(),
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().vehicleVisualModel.id.$path,
            title: "Visual model",
            width: 300,
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.vehicleVisualModel ? (
                <VehicleVisualModelInline entity={item.vehicleVisualModel} />
              ) : (
                "-"
              ),
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfVehicleVisualModel(),
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().inspectedAt.$path,
            title: "Inspected at",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.inspectedAt ? <Datetime datetime={item.inspectedAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().inspector.$path,
            title: "Inspector",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) =>
              item.inspector && (
                <GeneralInspectorDisplay
                  inspector={item.inspector}
                  inlineUserProps={{
                    withLink: false,
                  }}
                />
              ),
          },
          {
            field: getTypedPath<DamageDetectionDto>().mileage.$path,
            title: "Mileage",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => <VehicleMileageInline value={item.mileage} />,
            filters: {
              fieldType: FilterFieldType.Number,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().fuelLevel.$path,
            title: "Fuel level",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.fuelLevel ?? "-",
            filters: {
              fieldType: FilterFieldType.Number,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().notes.$path,
            title: "Notes",
            width: 100,
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => item.notes || "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().createdAt.$path,
            title: "Created at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().updatedAt.$path,
            title: "Updated at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().createdBy.$path,
            title: "Created by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().updatedBy.$path,
            title: "Updated by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().items.$path,
            title: "Items",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            isToDisabled: true,
            renderCell: (item) => <TableCellContentOfCount count={item.items?.length} />,
          },
          {
            field: getTypedPath<DamageDetectionDto>().attachments.$path,
            title: "Attachments",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            isToDisabled: true,
            renderCell: (item) => <TableCellContentOfAttachments attachments={item.attachments} />,
          },
          {
            field: getTypedPath<DamageDetectionDto>().tags.$path,
            title: "Tags",
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            isColumnMenuDisabled: false,
            isToDisabled: true,
            renderCell: (item) => (
              <EntityTagsInfoDisplay
                entityType={TagEntityType.DamageDetection}
                entity={item}
                noDataPlaceholder='-'
                edit={{
                  onSaved: (newValue) =>
                    paginatedDamageDetectionsRequest.updateData((data) => {
                      data.items?.forEach((item2) => {
                        if (item2.id === item.id) {
                          item2.tags = newValue || undefined;
                        }
                      });
                    }),
                }}
              />
            ),
            filters: {
              fieldType: FilterFieldType.Arbitrary,
              isUseSingleFilter: true,
              operators: FilterCatalog.getOperatorsForTagsField(),
            },
          },
          {
            field: getTypedPath<DamageDetectionDto>().tenantId.$path,
            title: "Tenant #",
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            isColumnMenuDisabled: false,
            isToDisabled: true,
            renderCell: (item) => <AdminTenantInline entity={null} entityId={item.tenantId} />,
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfAdminTenant(),
            },
          },
        ]}
        rows={paginatedDamageDetections?.items}
        isLoading={paginatedDamageDetectionsRequest.isLoading}
        getRowId={(item) => item.id!}
        rowTo={(item) =>
          ROUTE_PATH.ADMIN_DAMAGE_DETECTION_VIEW(item.id, commonRequestParams.params?.tenantId)
        }
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
        refetch={commonRequestParams.dataTabularProps.refetch}
      />
    </ViewLayoutV2>
  );
}
