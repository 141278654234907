import { useParams } from "react-router-dom";

import RepairSpecView from "@/common/components/Entity/RepairSpec/View/RepairSpecView";

export default function RepairSpecViewPage() {
  const { repairSpecId } = useParams<{ repairSpecId?: string }>();

  return (
    <>
      <RepairSpecView
        repairSpecId={repairSpecId}
        headerProps={{
          withGoBack: true,
          withLink: false,
        }}
        displayProps={{
          breadcrumbs: true,
        }}
      />
    </>
  );
}
