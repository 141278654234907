import { ReactNode, useMemo, useRef } from "react";

import { ArePropsEqualFunc } from "@/common/helpers/memo";
import { useRefWithFactory } from "@/common/hooks/ref/useRefWithFactory";

// WIP: this is experimental and not ready for usage.

interface MemoWrapperProps<TProps = any> {
  /** Re-renders when these props change. */
  props: TProps;
  arePropsEqualFactory: (params: { props: TProps }) => ArePropsEqualFunc<TProps>;
  /** Children that are rendered when props change. */
  children: (params: { props: TProps }) => ReactNode;
}

/** Helper component that adds memoization to its children. */
export default function MemoWrapper<TProps = any>({
  props,
  arePropsEqualFactory,
  children,
}: MemoWrapperProps<TProps>) {
  const arePropsEqual = useMemo(() => arePropsEqualFactory({ props }), []);
  const memorizedChildrenRef = useRefWithFactory<ReactNode>(() => children({ props }));
  const prevPropsRef = useRef(props);

  const arePropsEqualCheck = arePropsEqual(prevPropsRef.current, props);
  if (!arePropsEqualCheck) {
    memorizedChildrenRef.current = children({ props });
  }
  prevPropsRef.current = props;

  return memorizedChildrenRef.current;
}
