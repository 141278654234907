import { apiClient } from "@/core/api/ApiClient";
import {
  VehicleSpecDto,
  VehicleSpecInputDto,
  VehicleVisualModelDto,
  VehicleVisualModelSvgPathMetadataDto,
} from "@/core/api/generated";

export class VehicleVisualModelHelper {
  /** Returns the most suitable or default VehicleVisualModel for provided VehicleSpec. */
  public static async getSuggestedModel(
    spec: VehicleSpecDto | VehicleSpecInputDto | null | undefined,
  ): Promise<VehicleVisualModelDto | null> {
    const spec2 = spec as VehicleSpecDto | undefined;
    const specInput = spec as VehicleSpecInputDto | undefined;

    const response =
      await apiClient.vehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGet(
        {
          vehicleType: spec?.type || undefined,
          bodyType: spec?.bodyType || undefined,
          makeId: spec2?.make?.id || specInput?.makeId || undefined,
          modelId: spec2?.model?.id || specInput?.modelId || undefined,
          generationId: spec2?.generation?.id || specInput?.generationId || undefined,
          modificationId: spec2?.modification?.id || specInput?.modificationId || undefined,
        },
      );
    return response.data;
  }

  public static async fetchSvgContent(svgUrl: string): Promise<string> {
    try {
      const response = await fetch(svgUrl, {
        // use standard HTTP caching logic,
        // but as Azure Blob SAS URL might include new signature query param every time the browser won't cache the image
        cache: "default",
      });
      return await response.text();
    } catch (err) {
      console.error(`Error while fetching SVG by URL.`, { svgUrl }, err);
      throw err;
    }
  }

  public static removeWidthAndHeightAttributesFromSvgContent(svg: string): string {
    const newSvg = svg.replace(/(?<svgTagInfo><svg(\s*[a-zA-Z:]+=".*")+>)/, (svgTagInfo) => {
      const tagFixed = svgTagInfo.replace(/(width|height)+=".*"/g, "");
      return tagFixed;
    });
    return newSvg;
  }

  public static getMetadataFromSvgPathEl(
    pathEl: Element,
  ): VehicleVisualModelSvgPathMetadataDto | null {
    if (pathEl.nodeName !== "path") {
      return null;
    }
    const descEl = pathEl.querySelector("desc");
    const metadataJson = descEl?.innerHTML;
    if (!metadataJson) {
      return null;
    }
    let metadata: VehicleVisualModelSvgPathMetadataDto = {
      vehicleProjection: undefined,
      vehicleArea: undefined,
    };
    try {
      metadata = JSON.parse(metadataJson);
    } catch (err) {
      console.error(`Error while parsing SVG <path/> metadata.`, err);
      return null;
    }
    return metadata;
  }
}
