import {
  ApprovalResponseType,
  ConsensusType,
  NegotiationAllowedActionsDto,
  NegotiationDto,
  NegotiationProposalDto,
  NegotiationProposalResponseDto,
  NegotiationStatus,
  PartyDto,
  ProposalStatus,
  UserPartiesMembershipDto,
} from "@/core/api/generated";

import { AuthorizationInfo } from "../../hooks/auth/useAuthorizationInfo";
import { enumService } from "../../services/enum";
import { DatetimeHelper } from "../datetime";
import { PartyHelper } from "./party";

export interface ICanIDo {
  value: boolean;
  reason?: string;
}

export class NegotiationHelper {
  /** Checks if negotiation can be edited now. */
  public static canEditNegotiation(
    negotiation?: NegotiationDto,
    allowedActions?: NegotiationAllowedActionsDto,
  ): ICanIDo {
    if (!negotiation || !allowedActions) {
      return { value: false, reason: undefined };
    }
    if (negotiation.status === NegotiationStatus.Resolved) {
      return { value: false, reason: "You can't edit this negotiation. It's already resolved." };
    }
    if (!allowedActions.canManageNegotiation) {
      return { value: false, reason: "You are not allowed to edit this negotiation. Only view." };
    }
    return { value: true, reason: undefined };
  }

  /** Checks if negotiation deadline can be edit now. */
  public static canEditNegotiationDeadline(negotiation?: NegotiationDto): ICanIDo {
    if (!negotiation) {
      return { value: false, reason: undefined };
    }
    if (negotiation.settings?.deadlineHandledAt) {
      return {
        value: false,
        reason: `Deadline can't be edited as it was already handled at ${DatetimeHelper.formatDatetime(
          negotiation.settings.deadlineHandledAt,
        )} (${DatetimeHelper.humanizeDateRangeDurationFromNow(
          negotiation.settings.deadlineHandledAt,
          { isSuffix: true },
        )})`,
      };
    }
    return { value: true, reason: undefined };
  }

  /** Checks if proposal can be created now. */
  public static canCreateProposal(
    negotiation?: NegotiationDto | null,
    allowedActions?: NegotiationAllowedActionsDto,
  ): ICanIDo {
    if (!negotiation || !allowedActions) {
      return { value: false, reason: undefined };
    }
    if (!allowedActions.canCreateNewProposal) {
      return { value: false, reason: "You can't create new proposals in this negotiation." };
    }
    if (negotiation.status === NegotiationStatus.Resolved) {
      return { value: false, reason: undefined };
    }
    return { value: true, reason: undefined };
  }

  /** Checks if proposal can be edited now. */
  public static canEditProposal(
    proposal?: NegotiationProposalDto,
    allowedActions?: NegotiationAllowedActionsDto,
  ): ICanIDo {
    if (!proposal || !allowedActions) {
      return { value: false, reason: undefined };
    }
    if (proposal.status !== ProposalStatus.Open) {
      return { value: false, reason: "You can't edit this proposal. It's already closed." };
    }
    if (!allowedActions.canManageProposal) {
      return { value: false, reason: "You are not allowed to edit this proposal. Only view." };
    }
    return { value: true, reason: undefined };
  }

  /** Returns map that indicates what response the party gave. */
  public static getProposalResponseMap(
    proposal?: NegotiationProposalDto,
    partiesMembership?: UserPartiesMembershipDto | null,
  ): Record<ApprovalResponseType, boolean> {
    return Object.values(ApprovalResponseType).reduce(
      (accum, responseType) => (
        (accum[responseType] =
          proposal?.responses?.some(
            (r) =>
              partiesMembership?.membership?.some((m) => m.party?.id === r.party?.id) &&
              r.responseType === responseType,
          ) || false),
        accum
      ),
      {} as Record<ApprovalResponseType, boolean>,
    );
  }

  /** Returns map that indicates which parties already responded. */
  public static getProposalPartiesResponseMap(
    negotiation?: NegotiationDto,
    proposal?: NegotiationProposalDto,
  ): Record<string, NegotiationProposalResponseDto | null | undefined> {
    return (this.getNegotiationParties(negotiation) || []).reduce(
      (accum, party) => (
        (accum[party.id!] = proposal?.responses?.find((r) => r.party?.id === party.id)), accum
      ),
      {} as Record<string, NegotiationProposalResponseDto | null | undefined>,
    );
  }

  /** Checks if member responded on the proposal. */
  public static isRespondedOnProposal(
    proposal?: NegotiationProposalDto,
    partiesMembership?: UserPartiesMembershipDto | null,
  ): boolean {
    if (!proposal || !partiesMembership) {
      return false;
    }
    const responseMap = this.getProposalResponseMap(proposal, partiesMembership);
    return Object.values(ApprovalResponseType).some((responseType) => responseMap[responseType]);
  }

  /** Checks if other party (not my party) responded on the proposal. */
  public static isOtherPartyRespondedOnProposal(
    proposal?: NegotiationProposalDto,
    partiesMembership?: UserPartiesMembershipDto | null,
  ): boolean {
    if (!proposal || !partiesMembership) {
      return false;
    }
    return proposal.responses!.some((x) => !partiesMembership.membershipByPartyMap![x.party!.id!]);
  }

  /** Checks if given party responded on the proposal. */
  public static isPartyRespondedOnProposal(
    proposal?: NegotiationProposalDto | null,
    party?: PartyDto | null,
  ): boolean {
    if (!proposal || !party) {
      return false;
    }
    return proposal.responses?.some((x) => x.party?.id === party.id) || false;
  }

  /** Checks if member can respond on the proposal. */
  public static canRespondOnProposal({
    proposal,
    allowedActions,
    partiesMembership,
  }: {
    proposal?: NegotiationProposalDto;
    allowedActions?: NegotiationAllowedActionsDto;
    partiesMembership?: UserPartiesMembershipDto | null;
  }): boolean {
    if (!proposal || !allowedActions || !partiesMembership) {
      return false;
    }

    const isResponded = this.isRespondedOnProposal(proposal, partiesMembership);
    const canChangeResponse = this.canChangeProposalResponse({
      proposal,
      allowedActions,
      partiesMembership,
    });
    const canRespond =
      (allowedActions?.canRespondOnProposal &&
        (!isResponded || canChangeResponse) &&
        proposal.status === ProposalStatus.Open) ||
      false;

    return canRespond;
  }

  /** Checks if participant can change response given previously. */
  public static canChangeProposalResponse({
    proposal,
    allowedActions,
    partiesMembership,
  }: {
    proposal?: NegotiationProposalDto;
    allowedActions?: NegotiationAllowedActionsDto;
    partiesMembership?: UserPartiesMembershipDto | null;
  }): boolean {
    if (!proposal || !allowedActions || !partiesMembership) {
      return false;
    }

    const isResponded = this.isRespondedOnProposal(proposal, partiesMembership);
    const canChangeResponse =
      (proposal.settings?.allowChangeProposalResponse &&
        isResponded &&
        proposal.status === ProposalStatus.Open) ||
      false;

    return canChangeResponse;
  }

  /** Checks if participant can see proposal responses. */
  public static canSeeProposalResponses(
    proposal?: NegotiationProposalDto,
    partiesMembership?: UserPartiesMembershipDto | null,
  ): boolean {
    if (!proposal || !partiesMembership) {
      return false;
    }

    const isResponded = this.isRespondedOnProposal(proposal, partiesMembership);
    const canChangeResponse = proposal.status === ProposalStatus.Open ? isResponded : true;

    return canChangeResponse;
  }

  public static getNegotiationParties(
    negotiation?: NegotiationDto,
    options: { includeModerators: boolean } = { includeModerators: false },
  ): PartyDto[] | null {
    if (!negotiation) {
      return null;
    }
    return [
      negotiation.initiatorParty,
      negotiation.targetParty,
      ...(options?.includeModerators ? negotiation.moderatorParties || [] : []),
    ]
      .filter((x) => !!x)
      .map((x) => x!);
  }

  public static getMyNegotiationParty(
    negotiation?: NegotiationDto,
    partiesMembership?: UserPartiesMembershipDto | null,
    options: { includeModerators: boolean } = { includeModerators: false },
  ): PartyDto | null {
    if (!negotiation || !partiesMembership) {
      return null;
    }
    const parties =
      this.getNegotiationParties(negotiation, options)?.filter(
        (x) => partiesMembership.membershipByPartyMap![x.id!] === true,
      ) || [];
    return parties[0] || null;
  }

  public static getOtherNegotiationParties(
    negotiation?: NegotiationDto,
    partiesMembership?: UserPartiesMembershipDto | null,
    options: { includeModerators: boolean } = { includeModerators: false },
  ): PartyDto[] | null {
    if (!negotiation || !partiesMembership) {
      return null;
    }
    return this.getNegotiationParties(negotiation, options)!.filter(
      (x) => !partiesMembership.membershipByPartyMap![x.id!],
    );
  }

  /** Returns computed proposal status used by FE app. */
  public static getProposalComputedStatus(
    proposal: NegotiationProposalDto,
    partiesMembership?: UserPartiesMembershipDto | null,
  ) {
    const isOpen = proposal.status === ProposalStatus.Open;
    const isNoResponses = proposal.responses!.length === 0;
    const isMyPartyResponded = NegotiationHelper.isRespondedOnProposal(proposal, partiesMembership);
    const isOtherPartyResponded = NegotiationHelper.isOtherPartyRespondedOnProposal(
      proposal,
      partiesMembership,
    );
    const isMyPartyApprovedOrNotResponded =
      proposal.responses!.some(
        (x) =>
          PartyHelper.isMyParty(partiesMembership, x.party!.id!) &&
          x.responseType === ApprovalResponseType.Approve,
      ) || isMyPartyResponded === false;
    const isOtherPartyDeclined = proposal.responses!.some(
      (x) =>
        !PartyHelper.isMyParty(partiesMembership, x.party!.id!) &&
        x.responseType === ApprovalResponseType.Decline,
    );

    return {
      isNoResponses: isOpen && isNoResponses,
      isMyPartyRespondedAndWaitsForOtherPartyResponse:
        isOpen && isMyPartyResponded && !isOtherPartyResponded,
      isOtherPartyRespondedAndWaitsForMyPartyResponse:
        isOpen && !isMyPartyResponded && isOtherPartyResponded,
      isMyPartyApprovedOrNotRespondedAndOtherPartyDeclined:
        isOpen && isMyPartyApprovedOrNotResponded && isOtherPartyDeclined,
      isConsensus: !isOpen && proposal.consensusType === ConsensusType.Consensus,
      isForcedConsensus: !isOpen && proposal.consensusType === ConsensusType.ForcedConsensus,
    };
  }

  /** Returns response of the given party member. */
  public static getMyPartyProposalResponse(
    proposal?: NegotiationProposalDto,
    partiesMembership?: UserPartiesMembershipDto | null,
  ): NegotiationProposalResponseDto | null {
    if (!proposal || !partiesMembership) {
      return null;
    }
    return (
      proposal.responses?.find(
        (x) => partiesMembership.membershipByPartyMap![x.party!.id!] === true,
      ) || null
    );
  }

  /** Returns responses of parties other than the given party. */
  public static getOtherPartiesProposalResponse(
    proposal?: NegotiationProposalDto,
    partiesMembership?: UserPartiesMembershipDto | null,
  ): NegotiationProposalResponseDto[] | null {
    if (!proposal || !partiesMembership) {
      return null;
    }
    return (
      proposal.responses?.filter((x) => !partiesMembership.membershipByPartyMap![x.party!.id!]) ||
      null
    );
  }

  /** Returns appropriate text for ApprovalResponseType based on type of user.  */
  public static getApprovalResponseTypeText(
    responseType: ApprovalResponseType | null | undefined,
    authorizationInfo: AuthorizationInfo,
  ): string {
    if (!responseType) {
      return "";
    }
    if (authorizationInfo.hasFleetAppAccess) {
      return enumService.getEnumValueName("ApprovalResponseType", responseType);
    } else if (authorizationInfo.hasFleetCustomerAppAccess) {
      return (
        {
          [ApprovalResponseType.None]: "None",
          [ApprovalResponseType.Approve]: "Approve",
          [ApprovalResponseType.Decline]: "Disapprove",
          [ApprovalResponseType.ForceApprove]: "Force approve",
          [ApprovalResponseType.ForceDecline]: "Force disapprove",
        }[responseType] || enumService.getEnumValueName("ApprovalResponseType", responseType)
      );
    } else {
      return enumService.getEnumValueName("ApprovalResponseType", responseType);
    }
  }

  /** Returns appropriate text for ApprovalResponseType based on type of user.  */
  public static getApprovalResponseTypeForManyText(
    responseType: ApprovalResponseType | null | undefined,
    authorizationInfo: AuthorizationInfo,
  ): string {
    if (!responseType) {
      return "";
    }
    let text = this.getApprovalResponseTypeText(responseType, authorizationInfo);
    if (text) {
      if (authorizationInfo.hasFleetAppAccess) {
        text = `${text} all`;
      } else if (authorizationInfo.hasFleetCustomerAppAccess) {
        text = `${text} with all`;
      }
    }
    return text;
  }
}
