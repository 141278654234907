import * as React from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";

interface Props {
  isLoading: boolean;
  items: Array<any>;
  hasNextPage: boolean;
  loadMore: () => void;
  renderItem: (item: any) => React.ReactNode;
}

function InfiniteReverseScroll(
  { isLoading, items, hasNextPage, loadMore, renderItem }: Props,
  scrollableRootRef: any,
) {
  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: loadMore,
    rootMargin: "300px 0px 0px 0px",
  });

  const lastScrollDistanceToBottomRef = React.useRef<number>(null);

  // We keep the scroll position when new items are added etc.
  React.useEffect(() => {
    const scrollableRoot = scrollableRootRef.current;
    const lastScrollDistanceToBottom = lastScrollDistanceToBottomRef.current ?? 0;
    if (scrollableRoot) {
      scrollableRoot.scrollTop = scrollableRoot.scrollHeight - lastScrollDistanceToBottom;
    }
  }, [items, rootRef]);

  React.useEffect(() => {
    rootRef(scrollableRootRef.current);
  }, [scrollableRootRef]);

  const handleRootScroll = React.useCallback(() => {
    const rootNode = scrollableRootRef.current;
    if (rootNode) {
      const scrollDistanceToBottom = rootNode.scrollHeight - rootNode.scrollTop;
      lastScrollDistanceToBottomRef.current = scrollDistanceToBottom;
    }
  }, [scrollableRootRef]);

  React.useEffect(() => {
    const rootNode = scrollableRootRef.current;
    if (rootNode) {
      const listener = rootNode.addEventListener("scroll", () => {
        handleRootScroll();
      });

      return () => {
        rootNode.removeEventListener("scroll", listener);
      };
    }
  }, [handleRootScroll]);

  return (
    <>
      {hasNextPage && <div ref={infiniteRef}></div>}

      {items.map((item) => renderItem(item))}
    </>
  );
}

export default React.forwardRef(InfiniteReverseScroll);
