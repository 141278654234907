import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";

import { ArrayHelper } from "@/common/helpers/array";
import { apiClient } from "@/core/api/ApiClient";
import {
  ChatAcknowledgeResultDto,
  ChatAcknowledgedDto,
  ChatEventReceivedDto,
  ChatHistoryItemCreatedDto,
  ChatHistoryItemDeletedDto,
  ChatHistoryItemDto,
  ChatHistoryItemPinStatusChangedDto,
  ChatHistoryItemUpdatedDto,
  ChatMessageAcknowledgedDto,
  ChatMessageDeletedDto,
  ChatMessageDto,
  ChatMessageSentDto,
  ChatMessageUpdatedDto,
  ChatMessagesReadDto,
  MarkChatMessagesAsReadResultDto,
  PaginationDtoOfChatHistoryItemDto,
} from "@/core/api/generated";
import { AppThunk } from "@/store";

function getNewHistoryItem(data: {
  message?: ChatHistoryItemDto["message"];
  event?: ChatHistoryItemDto["event"];
  negotiationProposal?: ChatHistoryItemDto["negotiationProposal"];
}): ChatHistoryItemDto {
  const list = Object.values(data).filter((x) => !!x);

  return {
    id: list[0]?.id || undefined,
    // chatId: list[0]?.chatId || undefined,
    participantId: list[0]?.participantId || undefined,
    createdAt: list[0]?.createdAt || undefined,
    ...data,
  };
}

function isItemsEqual(a?: ChatHistoryItemDto | null, b?: ChatHistoryItemDto | null) {
  // some newly created items might not have id yet, so compare also by specific ids
  const idPairs = [
    { aId: a?.id, bId: b?.id },
    { aId: a?.message?.id, bId: b?.message?.id },
    { aId: a?.event?.id, bId: b?.event?.id },
    { aId: a?.negotiationProposal?.id, bId: b?.negotiationProposal?.id },
  ].filter((x) => !_.isNil(x.aId) && !_.isNil(x.bId));

  return a === b || idPairs.some((pair) => pair.aId === pair.bId);
}

function addItem(
  paginated?: PaginationDtoOfChatHistoryItemDto | null,
  item?: ChatHistoryItemDto | null,
) {
  if (!paginated || !item) {
    return;
  }
  const index = paginated.items!.findIndex((x) => isItemsEqual(x, item));

  if (index === -1) {
    paginated.items!.push(item);
    ArrayHelper.sortByDatetime(paginated.items, "createdAt", "asc");
  }
}

function updateItemWhere(
  paginated?: PaginationDtoOfChatHistoryItemDto | null,
  predicate?: (item: ChatHistoryItemDto) => boolean,
  update?: (item: ChatHistoryItemDto) => void,
) {
  if (!paginated || !predicate || !update) {
    return;
  }
  const item = paginated.items!.find((x) => predicate(x));
  if (item) {
    update(item);
  }
}

function updateAllItemsWhere(
  paginated?: PaginationDtoOfChatHistoryItemDto | null,
  predicate?: (item: ChatHistoryItemDto) => boolean,
  update?: (item: ChatHistoryItemDto) => void,
) {
  if (!paginated || !predicate || !update) {
    return;
  }
  const items = paginated.items!.filter((x) => predicate(x));
  items.forEach((x) => update(x));
}

function replaceItem(
  paginated?: PaginationDtoOfChatHistoryItemDto | null,
  item?: ChatHistoryItemDto | null,
) {
  if (!paginated || !item) {
    return;
  }
  const index = paginated.items!.findIndex((x) => isItemsEqual(x, item));
  if (index !== -1) {
    paginated.items!.splice(index, 1, item);
  }
}

function deleteItem(paginated?: PaginationDtoOfChatHistoryItemDto | null, itemId?: string | null) {
  if (!paginated) {
    return;
  }
  const index = paginated.items!.findIndex((x) => x.id === itemId);
  if (index !== -1) {
    paginated.items!.splice(index, 1);
  }
}

function deleteItemWhere(
  paginated?: PaginationDtoOfChatHistoryItemDto | null,
  predicate?: (item: ChatHistoryItemDto) => boolean,
) {
  if (!paginated || !predicate) {
    return;
  }
  const index = paginated.items!.findIndex((x) => predicate(x));
  if (index !== -1) {
    paginated.items!.splice(index, 1);
  }
}

export interface ChatHistoryState {
  loading: {
    getPaginatedHistory?: Record<string, boolean>;
    getPinnedHistoryItems?: Record<string, boolean>;
    pinItem?: Record<string, boolean>;
    unpinItem?: Record<string, boolean>;
  };
  /** {[chatId]: data} */
  paginatedChatHistory: Record<string, PaginationDtoOfChatHistoryItemDto | undefined>;
  /** {[chatId]: data} */
  pinnedChatHistoryItems: Record<string, PaginationDtoOfChatHistoryItemDto | undefined>;
}

const initialState: ChatHistoryState = {
  loading: {
    getPaginatedHistory: {},
    getPinnedHistoryItems: {},
    pinItem: {},
    unpinItem: {},
  },
  paginatedChatHistory: {},
  pinnedChatHistoryItems: {},
};

const chatHistorySlice = createSlice({
  name: "chatHistory",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<ChatHistoryState["loading"]>) => {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
    resetChatHistory: (state, action: PayloadAction<{ chatId?: string | null }>) => {
      state.paginatedChatHistory[action.payload.chatId || ""] = undefined;
      state.pinnedChatHistoryItems[action.payload.chatId || ""] = undefined;
    },
    _chatHistoryFetched: (
      state,
      action: PayloadAction<{ chatId: string; history: PaginationDtoOfChatHistoryItemDto }>,
    ) => {
      state.paginatedChatHistory[action.payload.chatId] = action.payload.history;
    },
    _pinnedChatHistoryItemsFetched: (
      state,
      action: PayloadAction<{ chatId: string; history: PaginationDtoOfChatHistoryItemDto }>,
    ) => {
      state.pinnedChatHistoryItems[action.payload.chatId] = action.payload.history;
    },
    _chatHistoryItemPinned: (state, action: PayloadAction<ChatHistoryItemDto>) => {
      const chatHistory = state.paginatedChatHistory[action.payload.chatId!];
      addItem(chatHistory, action.payload);
      replaceItem(chatHistory, action.payload);

      const pinnedHistory = state.pinnedChatHistoryItems[action.payload.chatId!];
      addItem(pinnedHistory, action.payload);
      replaceItem(pinnedHistory, action.payload);
    },
    _chatHistoryItemUnpinned: (
      state,
      action: PayloadAction<{ chatId: string; itemId: string }>,
    ) => {
      const chatHistory = state.paginatedChatHistory[action.payload.chatId!];
      updateItemWhere(
        chatHistory,
        (x) => x.id === action.payload.itemId,
        (x) => (x.isPinned = false),
      );

      const pinnedHistory = state.pinnedChatHistoryItems[action.payload.chatId!];
      deleteItem(pinnedHistory, action.payload.itemId);
    },
    chatHistoryItemCreated: (state, action: PayloadAction<ChatHistoryItemCreatedDto>) => {
      const chatHistory = state.paginatedChatHistory[action.payload.chatId!];
      replaceItem(chatHistory, action.payload.item); // in case already added
      addItem(chatHistory, action.payload.item);
    },
    chatHistoryItemUpdated: (state, action: PayloadAction<ChatHistoryItemUpdatedDto>) => {
      const chatHistory = state.paginatedChatHistory[action.payload.chatId!];
      const pinnedHistory = state.pinnedChatHistoryItems[action.payload.chatId!];

      [chatHistory, pinnedHistory].forEach((history) => {
        replaceItem(history, action.payload.item);
      });
    },
    chatHistoryItemDeleted: (state, action: PayloadAction<ChatHistoryItemDeletedDto>) => {
      const chatHistory = state.paginatedChatHistory[action.payload.chatId!];
      const pinnedHistory = state.pinnedChatHistoryItems[action.payload.chatId!];

      [chatHistory, pinnedHistory].forEach((history) => {
        deleteItem(history, action.payload.itemId);
      });
    },
    chatHistoryItemPinStatusChanged: (
      state,
      action: PayloadAction<ChatHistoryItemPinStatusChangedDto>,
    ) => {
      const chatHistory = state.paginatedChatHistory[action.payload.chatId!];
      const pinnedHistory = state.pinnedChatHistoryItems[action.payload.chatId!];

      [chatHistory, pinnedHistory].forEach((history) => {
        replaceItem(history, action.payload.item);
      });

      if (pinnedHistory) {
        action.payload.item!.isPinned
          ? addItem(pinnedHistory, action.payload.item)
          : deleteItem(pinnedHistory, action.payload.item!.id);
      }
    },
    chatMessageSent: (state, action: PayloadAction<ChatMessageDto>) => {
      const chatHistory = state.paginatedChatHistory[action.payload.chatId!];
      addItem(chatHistory, getNewHistoryItem({ message: action.payload }));
    },
    newChatMessageReceived: (state, action: PayloadAction<ChatMessageSentDto>) => {
      const chatHistory = state.paginatedChatHistory[action.payload.chatId!];
      addItem(chatHistory, getNewHistoryItem({ message: action.payload.message }));
    },
    chatMessageUpdated: (state, action: PayloadAction<ChatMessageUpdatedDto>) => {
      const chatHistory = state.paginatedChatHistory[action.payload.chatId!];
      const pinnedHistory = state.pinnedChatHistoryItems[action.payload.chatId!];

      [chatHistory, pinnedHistory].forEach((history) => {
        replaceItem(history, getNewHistoryItem({ message: action.payload.message }));
      });
    },
    chatMessageDeleted: (state, action: PayloadAction<ChatMessageDeletedDto>) => {
      const chatHistory = state.paginatedChatHistory[action.payload.chatId!];
      const pinnedHistory = state.pinnedChatHistoryItems[action.payload.chatId!];

      [chatHistory, pinnedHistory].forEach((history) => {
        deleteItemWhere(history, (x) => x.message?.id === action.payload.messageId);
      });
    },
    chatMessageAcknowledged: (state, action: PayloadAction<ChatMessageAcknowledgedDto>) => {
      const chatHistory = state.paginatedChatHistory[action.payload.chatId!];
      const pinnedHistory = state.pinnedChatHistoryItems[action.payload.chatId!];

      [chatHistory, pinnedHistory].forEach((history) => {
        updateItemWhere(
          history,
          (x) => x.message?.id === action.payload.messageId,
          (x) => x.message?.acknowledgements?.push(action.payload.acknowledgement!),
        );
      });
    },
    chatMessagesRead: (
      state,
      action: PayloadAction<MarkChatMessagesAsReadResultDto | ChatMessagesReadDto>,
    ) => {
      const chatHistory = state.paginatedChatHistory[action.payload.chatId!];
      const pinnedHistory = state.pinnedChatHistoryItems[action.payload.chatId!];

      [chatHistory, pinnedHistory].forEach((history) => {
        updateAllItemsWhere(
          history,
          (x) =>
            !!x.message &&
            action.payload.messageIds!.includes(x.message!.id!) &&
            !x.message!.readByInfo!.some(
              (r) => r.computedId === action.payload.readByInfo!.computedId,
            ),
          (x) => x.message!.readByInfo!.push(action.payload.readByInfo!),
        );
      });
    },

    chatAcknowledgedByMe: (state, action: PayloadAction<ChatAcknowledgeResultDto>) => {
      // acknowledge all messages
      const chatHistory = state.paginatedChatHistory[action.payload.chat!.id!];
      const pinnedHistory = state.pinnedChatHistoryItems[action.payload.chat!.id!];

      [chatHistory, pinnedHistory].forEach((history) => {
        updateItemWhere(
          history,
          (x) =>
            !!x.message &&
            !x.message!.acknowledgements!.some(
              (a) => a.participantId === action.payload.acknowledgedByParticipant!.id,
            ),
          (x) =>
            x.message?.acknowledgements?.push({
              userId: action.payload.acknowledgedByParticipant!.userId,
              participantId: action.payload.acknowledgedByParticipant!.id,
              isAcknowledged: true,
              acknowledgedAt: moment().format(),
            }),
        );
      });
    },
    chatAcknowledged: (state, action: PayloadAction<ChatAcknowledgedDto>) => {
      // acknowledge all messages
      const chatHistory = state.paginatedChatHistory[action.payload.chatId!];
      const pinnedHistory = state.pinnedChatHistoryItems[action.payload.chatId!];

      [chatHistory, pinnedHistory].forEach((history) => {
        updateItemWhere(
          history,
          (x) =>
            !!x.message &&
            !x.message!.acknowledgements!.some(
              (a) => a.participantId === action.payload.participantId,
            ) &&
            moment(x.message.createdAt).isSameOrBefore(
              moment(action.payload.acknowledgement?.acknowledgedAt),
            ),
          (x) => x.message?.acknowledgements?.push(action.payload.acknowledgement!),
        );
      });
    },
    newChatEventReceived: (state, action: PayloadAction<ChatEventReceivedDto>) => {
      const chatHistory = state.paginatedChatHistory[action.payload.chatId!];
      addItem(chatHistory, getNewHistoryItem({ event: action.payload.event }));
    },
  },
});

export const {
  setLoading,
  resetChatHistory,
  _chatHistoryFetched,
  _pinnedChatHistoryItemsFetched,
  _chatHistoryItemPinned,
  _chatHistoryItemUnpinned,
  chatHistoryItemCreated,
  chatHistoryItemUpdated,
  chatHistoryItemDeleted,
  chatHistoryItemPinStatusChanged,
  chatMessageSent,
  newChatMessageReceived,
  chatMessageUpdated,
  chatMessageDeleted,
  chatMessageAcknowledged,
  chatMessagesRead,
  chatAcknowledgedByMe,
  chatAcknowledged,
  newChatEventReceived,
} = chatHistorySlice.actions;

export const chatHistoryReducer = chatHistorySlice.reducer;

export const getChatHistory =
  (
    ...arg: Parameters<typeof apiClient.chatHistoryApi.apiV1ChatsChatIdHistoryGetPost>
  ): AppThunk<Promise<PaginationDtoOfChatHistoryItemDto>> =>
  async (dispatch, getState) => {
    const chatId = arg[0].chatId;

    dispatch(
      setLoading({
        getPaginatedHistory: {
          ...(getState().communication.chatHistory.loading.getPaginatedHistory || {}),
          [chatId]: true,
        },
      }),
    );

    try {
      const response = await apiClient.chatHistoryApi.apiV1ChatsChatIdHistoryGetPost(...arg);
      await dispatch(
        _chatHistoryFetched({
          chatId,
          history: response.data,
        }),
      );
      return response.data;
    } finally {
      dispatch(
        setLoading({
          getPaginatedHistory: {
            ...(getState().communication.chatHistory.loading.getPaginatedHistory || {}),
            [chatId]: false,
          },
        }),
      );
    }
  };

export const getPinnedChatHistoryItems =
  (
    ...arg: Parameters<typeof apiClient.chatHistoryApi.apiV1ChatsChatIdHistoryGetPinnedPost>
  ): AppThunk<Promise<PaginationDtoOfChatHistoryItemDto>> =>
  async (dispatch, getState) => {
    const chatId = arg[0].chatId;

    dispatch(
      setLoading({
        getPinnedHistoryItems: {
          ...(getState().communication.chatHistory.loading.getPinnedHistoryItems || {}),
          [chatId]: true,
        },
      }),
    );

    try {
      const response = await apiClient.chatHistoryApi.apiV1ChatsChatIdHistoryGetPinnedPost(...arg);
      await dispatch(
        _pinnedChatHistoryItemsFetched({
          chatId,
          history: response.data,
        }),
      );
      return response.data;
    } finally {
      dispatch(
        setLoading({
          getPinnedHistoryItems: {
            ...(getState().communication.chatHistory.loading.getPinnedHistoryItems || {}),
            [chatId]: false,
          },
        }),
      );
    }
  };

export const pinChatHistoryItem =
  (
    ...arg: Parameters<typeof apiClient.chatHistoryApi.apiV1ChatsChatIdHistoryItemsItemIdPinPost>
  ): AppThunk<Promise<ChatHistoryItemDto>> =>
  async (dispatch, getState) => {
    const itemId = arg[0].itemId;

    dispatch(
      setLoading({
        pinItem: {
          ...(getState().communication.chatHistory.loading.pinItem || {}),
          [itemId]: true,
        },
      }),
    );

    try {
      const response = await apiClient.chatHistoryApi.apiV1ChatsChatIdHistoryItemsItemIdPinPost(
        ...arg,
      );
      await dispatch(_chatHistoryItemPinned(response.data));
      return response.data;
    } finally {
      dispatch(
        setLoading({
          pinItem: {
            ...(getState().communication.chatHistory.loading.pinItem || {}),
            [itemId]: false,
          },
        }),
      );
    }
  };

export const unpinChatHistoryItem =
  (
    ...arg: Parameters<typeof apiClient.chatHistoryApi.apiV1ChatsChatIdHistoryItemsItemIdUnpinPost>
  ): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const itemId = arg[0].itemId;

    dispatch(
      setLoading({
        unpinItem: {
          ...(getState().communication.chatHistory.loading.unpinItem || {}),
          [itemId]: true,
        },
      }),
    );

    try {
      await apiClient.chatHistoryApi.apiV1ChatsChatIdHistoryItemsItemIdUnpinPost(...arg);
      await dispatch(
        _chatHistoryItemUnpinned({
          chatId: arg[0].chatId,
          itemId,
        }),
      );
    } finally {
      dispatch(
        setLoading({
          unpinItem: {
            ...(getState().communication.chatHistory.loading.unpinItem || {}),
            [itemId]: false,
          },
        }),
      );
    }
  };
