import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { apiClient } from "@/core/api/ApiClient";
import {
  ChatDto,
  ChatParticipantDto,
  ChatParticipantRemovedDto,
  ChatParticipantsAddedDto,
  PaginationDtoOfChatParticipantCandidateDto,
  PaginationDtoOfChatParticipantDto,
  ParticipantOnlineStatus,
} from "@/core/api/generated";
import { AppThunk } from "@/store";

import * as chatsSlice from "./chatsSlice";

interface StoreParticipantStatus {
  onlineStatus?: ParticipantOnlineStatus;
}

export interface ChatParticipantsState {
  loading: {
    getPaginated?: Record<string, boolean>;
    getChatParticipantCandidates?: boolean;
    joinChat?: boolean;
    leaveChat?: boolean;
    addChatParticipants?: Record<string, boolean>;
    removeChatParticipant?: Record<string, boolean>;
  };
  paginatedChatParticipantMap: Record<string, PaginationDtoOfChatParticipantDto | undefined>; // chatId:participant
  paginatedChatParticipantCandidates?: PaginationDtoOfChatParticipantCandidateDto;
  participantStatusMap: Record<string, Record<string, StoreParticipantStatus | undefined>>; // chatId:{participantId:status}

  // computed
}

const initialState: ChatParticipantsState = {
  loading: {
    getPaginated: {},
  },
  paginatedChatParticipantMap: {},
  paginatedChatParticipantCandidates: undefined,
  participantStatusMap: {},
};

const tenantsSlice = createSlice({
  name: "chatParticipants",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<ChatParticipantsState["loading"]>) => {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
    resetChatParticipants: (state, action: PayloadAction<{ chatId?: string | null }>) => {
      if (action.payload.chatId) {
        state.paginatedChatParticipantMap[action.payload.chatId] = undefined;
        state.participantStatusMap[action.payload.chatId] = {};
      }
    },
    _chatParticipantsFetched: (
      state,
      action: PayloadAction<{ chatId: string; data: PaginationDtoOfChatParticipantDto }>,
    ) => {
      state.paginatedChatParticipantMap[action.payload.chatId] = action.payload.data;
    },
    _chatParticipantCandidatesFetched: (
      state,
      action: PayloadAction<PaginationDtoOfChatParticipantCandidateDto>,
    ) => {
      state.paginatedChatParticipantCandidates = action.payload;
    },
    _chatParticipantJoined: (state, action: PayloadAction<{ chat: ChatDto }>) => {},
    chatParticipantJoined: (
      state,
      action: PayloadAction<{ chatId: string; participant: ChatParticipantDto }>,
    ) => {
      const paginated = state.paginatedChatParticipantMap[action.payload.chatId];
      if (paginated && !paginated.items!.some((x) => x.id === action.payload.participant.id)) {
        paginated.items!.push(action.payload.participant);
      }
    },
    _chatParticipantLeft: (
      state,
      action: PayloadAction<{ chatId: string; participant: ChatParticipantDto }>,
    ) => {
      const paginated = state.paginatedChatParticipantMap[action.payload.chatId];
      if (paginated) {
        const index = paginated.items!.findIndex((x) => x.id === action.payload.participant.id);
        if (index !== -1) {
          paginated.items!.splice(index, 1);
        }
      }
    },
    chatParticipantLeft: (
      state,
      action: PayloadAction<{ chatId: string; participant: ChatParticipantDto }>,
    ) => {
      const paginated = state.paginatedChatParticipantMap[action.payload.chatId];
      if (paginated) {
        const index = paginated.items!.findIndex((x) => x.id === action.payload.participant.id);
        if (index !== -1) {
          paginated.items!.splice(index, 1);
        }
      }
    },
    _chatParticipantsAdded: (
      state,
      action: PayloadAction<{ chatId: string; data: ChatParticipantDto[] }>,
    ) => {
      const paginated = state.paginatedChatParticipantMap[action.payload.chatId];
      if (paginated) {
        action.payload.data.forEach((x) => paginated.items!.push(x));
      }
    },
    chatParticipantsAdded: (state, action: PayloadAction<ChatParticipantsAddedDto>) => {
      const paginated = state.paginatedChatParticipantMap[action.payload.chatId!];
      if (paginated) {
        action.payload.participants?.forEach((x) => paginated.items!.push(x));
      }
    },
    _chatParticipantRemoved: (
      state,
      action: PayloadAction<{ chatId: string; participantId: string }>,
    ) => {
      const paginated = state.paginatedChatParticipantMap[action.payload.chatId];
      if (paginated) {
        const index = paginated.items!.findIndex((x) => x.id === action.payload.participantId);
        if (index !== -1) {
          paginated.items!.splice(index, 1);
        }
      }
    },
    chatParticipantRemoved: (state, action: PayloadAction<ChatParticipantRemovedDto>) => {
      const paginated = state.paginatedChatParticipantMap[action.payload.chatId!];
      if (paginated) {
        const index = paginated.items!.findIndex((x) => x.id === action.payload.participant?.id);
        if (index !== -1) {
          paginated.items!.splice(index, 1);
        }
      }
    },
    updateParticipantStatus: (
      state,
      action: PayloadAction<{
        chatId?: string | null;
        participantId?: string | null;
        status: StoreParticipantStatus;
      }>,
    ) => {
      if (action.payload.chatId && action.payload.participantId) {
        state.participantStatusMap[action.payload.chatId] =
          state.participantStatusMap[action.payload.chatId] || {};
        state.participantStatusMap[action.payload.chatId][action.payload.participantId] =
          state.participantStatusMap[action.payload.chatId][action.payload.participantId] || {};
        state.participantStatusMap[action.payload.chatId][action.payload.participantId] = {
          ...state.participantStatusMap[action.payload.chatId][action.payload.participantId],
          ...action.payload.status,
        };
      }
    },
    /** Updates status for all participants in all chats. */
    updateAllParticipantsStatusInAllChats: (
      state,
      action: PayloadAction<{
        status: StoreParticipantStatus;
      }>,
    ) => {
      for (const chatId in state.participantStatusMap) {
        state.participantStatusMap[chatId] = state.participantStatusMap[chatId] || {};
        for (const participantId in state.participantStatusMap[chatId]) {
          state.participantStatusMap[chatId][participantId] =
            state.participantStatusMap[chatId][participantId] || {};
          state.participantStatusMap[chatId][participantId] = {
            ...state.participantStatusMap[chatId][participantId],
            ...action.payload.status,
          };
        }
      }
    },
    /** Updates status for the participant in all chats. */
    updateParticipantStatusInAllChats: (
      state,
      action: PayloadAction<{
        participantId?: string | null;
        status: StoreParticipantStatus;
      }>,
    ) => {
      if (action.payload.participantId) {
        for (const chatId in state.participantStatusMap) {
          state.participantStatusMap[chatId] = state.participantStatusMap[chatId] || {};
          state.participantStatusMap[chatId][action.payload.participantId] =
            state.participantStatusMap[chatId][action.payload.participantId] || {};
          state.participantStatusMap[chatId][action.payload.participantId] = {
            ...state.participantStatusMap[chatId][action.payload.participantId],
            ...action.payload.status,
          };
        }
      }
    },
  },
});

export const {
  setLoading,
  resetChatParticipants,
  _chatParticipantsFetched,
  _chatParticipantCandidatesFetched,
  _chatParticipantJoined,
  chatParticipantJoined,
  _chatParticipantLeft,
  chatParticipantLeft,
  _chatParticipantsAdded,
  chatParticipantsAdded,
  _chatParticipantRemoved,
  chatParticipantRemoved,
  updateParticipantStatus,
  updateAllParticipantsStatusInAllChats,
  updateParticipantStatusInAllChats,
} = tenantsSlice.actions;

export const chatParticipantsReducer = tenantsSlice.reducer;

export const getChatParticipants = (
  ...arg: Parameters<typeof apiClient.chatParticipantsApi.apiV1ChatsChatIdParticipantsGetPost>
): AppThunk<Promise<PaginationDtoOfChatParticipantDto>> => {
  const chatId = arg[0].chatId;

  return async (dispatch) => {
    dispatch(
      setLoading({
        getPaginated: {
          [chatId]: true,
        },
      }),
    );

    try {
      const response = await apiClient.chatParticipantsApi.apiV1ChatsChatIdParticipantsGetPost(
        ...arg,
      );
      await dispatch(
        _chatParticipantsFetched({
          chatId,
          data: response.data,
        }),
      );
      return response.data;
    } finally {
      dispatch(
        setLoading({
          getPaginated: {
            [chatId]: false,
          },
        }),
      );
    }
  };
};

export const getChatParticipantCandidates = (
  ...arg: Parameters<typeof apiClient.chatParticipantsApi.apiV1ChatsChatIdParticipantsCandidatesGet>
): AppThunk<Promise<PaginationDtoOfChatParticipantCandidateDto>> => {
  return async (dispatch) => {
    dispatch(
      setLoading({
        getChatParticipantCandidates: true,
      }),
    );

    try {
      const response =
        await apiClient.chatParticipantsApi.apiV1ChatsChatIdParticipantsCandidatesGet(...arg);
      await dispatch(_chatParticipantCandidatesFetched(response.data));
      return response.data;
    } finally {
      dispatch(
        setLoading({
          getChatParticipantCandidates: false,
        }),
      );
    }
  };
};

export const joinChat = (
  ...arg: Parameters<typeof apiClient.chatParticipantsApi.apiV1ChatsChatIdParticipantsJoinPost>
): AppThunk<Promise<ChatDto>> => {
  return async (dispatch) => {
    dispatch(
      setLoading({
        joinChat: true,
      }),
    );

    try {
      const response = await apiClient.chatParticipantsApi.apiV1ChatsChatIdParticipantsJoinPost(
        ...arg,
      );
      await dispatch(chatsSlice.joinedChat(response.data));
      await dispatch(
        _chatParticipantJoined({
          chat: response.data,
        }),
      );
      return response.data;
    } finally {
      dispatch(
        setLoading({
          joinChat: false,
        }),
      );
    }
  };
};

export const leaveChat = (
  ...arg: Parameters<typeof apiClient.chatParticipantsApi.apiV1ChatsChatIdParticipantsLeavePost>
): AppThunk<Promise<ChatParticipantDto>> => {
  return async (dispatch) => {
    dispatch(
      setLoading({
        leaveChat: true,
      }),
    );

    try {
      const response = await apiClient.chatParticipantsApi.apiV1ChatsChatIdParticipantsLeavePost(
        ...arg,
      );
      await dispatch(
        _chatParticipantLeft({
          chatId: arg[0].chatId,
          participant: response.data,
        }),
      );
      return response.data;
    } finally {
      dispatch(
        setLoading({
          leaveChat: false,
        }),
      );
    }
  };
};

export const addChatParticipants = (
  ...arg: Parameters<typeof apiClient.chatParticipantsApi.apiV1ChatsChatIdParticipantsPost>
): AppThunk<Promise<ChatParticipantDto[]>> => {
  const chatId = arg[0].chatId;

  return async (dispatch) => {
    dispatch(
      setLoading({
        addChatParticipants: {
          [chatId]: true,
        },
      }),
    );

    try {
      const response = await apiClient.chatParticipantsApi.apiV1ChatsChatIdParticipantsPost(...arg);
      await dispatch(
        _chatParticipantsAdded({
          chatId,
          data: response.data,
        }),
      );
      return response.data;
    } finally {
      dispatch(
        setLoading({
          addChatParticipants: {
            [chatId]: false,
          },
        }),
      );
    }
  };
};

export const removeChatParticipant = (
  ...arg: Parameters<
    typeof apiClient.chatParticipantsApi.apiV1ChatsChatIdParticipantsParticipantIdDelete
  >
): AppThunk<Promise<void>> => {
  const chatId = arg[0].chatId;
  const participantId = arg[0].participantId;

  return async (dispatch) => {
    dispatch(
      setLoading({
        removeChatParticipant: {
          [participantId]: true,
        },
      }),
    );

    try {
      const response =
        await apiClient.chatParticipantsApi.apiV1ChatsChatIdParticipantsParticipantIdDelete(...arg);
      await dispatch(
        _chatParticipantRemoved({
          chatId,
          participantId,
        }),
      );
      return response.data;
    } finally {
      dispatch(
        setLoading({
          removeChatParticipant: {
            [participantId]: false,
          },
        }),
      );
    }
  };
};
