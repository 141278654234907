import { Box, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  title: ReactNode | undefined;
  subtitle?: ReactNode;
  actions?: ReactNode;
  primaryActions?: ReactNode;
  secondaryActions?: ReactNode;
}

export default function SimpleViewPageHeader({
  title,
  subtitle,
  actions,
  primaryActions,
  secondaryActions,
}: Props) {
  return (
    <Stack
      direction='row'
      spacing={1}
      sx={{
        alignItems: "center",
        justifyContent: "flex-start",
        backgroundColor: "transparent",
      }}
    >
      <Box sx={{ flex: 1, overflow: "hidden" }}>
        <Typography variant='h1' sx={{ wordBreak: "break-word" }}>
          {title}
        </Typography>
        {subtitle && (
          <Typography component='div' variant='subtitle1' color='text.secondary'>
            {subtitle}
          </Typography>
        )}
      </Box>

      {(actions || primaryActions || secondaryActions) && (
        <Stack direction='row' spacing={1} sx={{ justifyContent: "flex-end" }}>
          <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
            {primaryActions}
            {actions}
            {secondaryActions}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
