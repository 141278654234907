import { Card, CardHeader, Typography } from "@mui/material";

import { ChatActivityDto, ChatParticipantDto } from "@/core/api/generated";

import Datetime from "../../Datetime/Datetime";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import InlineChatParticipant from "../ChatParticipant/InlineChatParticipant";

interface Props {
  activityItem: ChatActivityDto;
  participant?: ChatParticipantDto | null;
}

export default function ChatActivityListItem({ activityItem, participant }: Props) {
  return (
    <Card>
      <CardHeader
        sx={{ py: 1 }}
        title={
          <>
            <Typography component='div' variant='subtitle2'>
              Category:{" "}
              <InlineApiEnumValue type='ChatActivityCategory' value={activityItem.category} />,
            </Typography>
            <Typography component='div' variant='subtitle2'>
              Type: <InlineApiEnumValue type='ChatActivityType' value={activityItem.type} />
            </Typography>
          </>
        }
        subheader={
          <Typography component='span' variant='body2' color='text.secondary'>
            <Datetime datetime={activityItem.performedAt} withDurationFromNow />
            {participant && (
              <>
                {" "}
                performed by{" "}
                <InlineChatParticipant
                  participant={participant}
                  withAvatar
                  avatarProps={{ size: 20 }}
                />
              </>
            )}
          </Typography>
        }
      />
      {/* <CardContent sx={{ pt: 0 }}>
      </CardContent> */}
    </Card>
  );
}
