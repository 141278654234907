import { TypographyProps } from "@mui/material";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import VehicleViewPageHeader from "@/common/components/Entity/Vehicle/View/Header/VehicleViewPageHeader";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { useAppHistory } from "@/common/hooks/useAppHistory";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  TagEntityType,
  WheelOperationDto,
} from "@/core/api/generated";

import GeneralAttachedTagsDisplay from "../../General/GeneralTag/GeneralAttachedTagsDisplay";
import BaseEntityView from "../../components/BaseEntityView";
import WheelOperationMenu from "../WheelOperationMenu";
import CommentsTabContent from "./Tabs/CommentsTabContent";
import GeneralHistoryTabContent from "./Tabs/GeneralHistoryTabContent";
import OverviewTabContent from "./Tabs/OverviewTabContent";

export enum WheelOperationViewPageTabs {
  Overview = "Overview",
  History = "History",
  Comments = "Comments",
}

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};
const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};
interface Props {
  wheelOperationId: string | null | undefined;
  wheelOperation: WheelOperationDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

export default function WheelOperationView({
  wheelOperationId,
  wheelOperation,
  displayProps,
  headerProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const history = useAppHistory();
  const currentTenant = useCurrentTenant();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.WheelOperation),
      viewVariant: displayProps?.viewVariant,
    },
  });

  const wheelOperationRequest = useApiRequest(
    apiClient.wheelOperationsApi.apiV1WheelOperationsWheelOperationIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      wheelOperationId: wheelOperationId!,
    },
    {
      deps: [wheelOperationId],
      skip: !wheelOperationId,
    },
  );
  wheelOperation = wheelOperation || wheelOperationRequest?.data;

  useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(
        currentTenant?.id,
        EntityType.WheelOperation,
        wheelOperationId || "",
      ),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      wheelOperationRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: wheelOperation && {
      idValue: wheelOperation?.id || "",
      newTitle: wheelOperation?.localNumber || "",
    },
  });

  return (
    <BaseEntityView
      entityType={EntityType.WheelOperation}
      entityId={wheelOperationId}
      entity={wheelOperation}
      entityRequest={wheelOperationRequest}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          <SimpleViewPageHeader
            title={
              wheelOperation?.vehicle && (
                <VehicleViewPageHeader
                  vehicleId={wheelOperation?.vehicle?.id || ""}
                  vehicle={wheelOperation?.vehicle || undefined}
                  withLink
                  tags={
                    <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                      <GeneralAttachedTagsDisplay
                        tags={wheelOperation?.tags}
                        defaultIsFolded={false}
                        edit={{
                          entityType: TagEntityType.WheelOperation,
                          entityId: wheelOperation?.id,
                          onSaved: (newValue) => {
                            wheelOperationRequest.updateData((x) => {
                              x.tags = newValue || undefined;
                            });
                          },
                        }}
                      />
                    </AuthorizedElement>
                  }
                />
              )
            }
            primaryActions={
              wheelOperation && (
                <WheelOperationMenu
                  onDelete={() => history.goBack()}
                  onUpdate={(newValue) =>
                    newValue
                      ? wheelOperationRequest.replaceData(newValue)
                      : wheelOperationRequest.refetch()
                  }
                  entity={wheelOperation}
                />
              )
            }
          />
        }
      >
        <PageTabs
          tabIdsDefinition={WheelOperationViewPageTabs}
          defaultTabId={WheelOperationViewPageTabs.Overview}
          commonViewParams={commonViewParams}
          tabs={[
            { label: "Overview", value: WheelOperationViewPageTabs.Overview, isHideable: false },
            { label: "History", value: WheelOperationViewPageTabs.History, isHideable: false },
            { label: "Comments", value: WheelOperationViewPageTabs.Comments, isHideable: false },
          ]}
        >
          {({ activeTabId: activeTab }) =>
            wheelOperation && (
              <>
                {activeTab === WheelOperationViewPageTabs.Overview && (
                  <OverviewTabContent
                    onUpdate={() => wheelOperationRequest.refetch()}
                    wheelOperation={wheelOperation}
                  />
                )}
                {activeTab === WheelOperationViewPageTabs.History && (
                  <GeneralHistoryTabContent wheelOperation={wheelOperation} />
                )}
                {activeTab === WheelOperationViewPageTabs.Comments && (
                  <CommentsTabContent wheelOperation={wheelOperation} />
                )}
              </>
            )
          }
        </PageTabs>
      </ViewLayout>
    </BaseEntityView>
  );
}
