import { Stack, SxProps, Theme } from "@mui/material";
import { ReactElement, ReactNode } from "react";

import InfoTile from "../Form/Display/InfoTile";
import AppTypography from "../Text/AppTypography";

export interface ContactCardData {
  name?: string | null | (React.ReactNode & ReactNode);
  email?: string | null | (React.ReactNode & ReactNode);
  phoneNumber?: string | null | (React.ReactNode & ReactNode);
}

export interface ContactCardProps {
  contact: ContactCardData;
  action?: React.ReactNode & ReactNode;
  sx?: SxProps<Theme>;
  children?: ReactElement;
}

export default function ContactCard({
  contact,
  action,
  sx,
  children,
}: ContactCardProps): React.ReactNode {
  return (
    <InfoTile label={contact.name} iconOf='person' sx={sx}>
      <Stack>
        {contact.email && (
          <AppTypography ellipsing={{ enabled: true }}>{contact.email}</AppTypography>
        )}
        {contact.phoneNumber && (
          <AppTypography ellipsing={{ enabled: true }}>{contact.phoneNumber}</AppTypography>
        )}
        {children}
      </Stack>
    </InfoTile>
  );
}

// OLD VERSION:

// <BaseContactCard sx={sx}>
//   <Stack direction='row' spacing={1}>
//     <Stack direction='column'>
//       <AppIcon of='person' fontSize='small' sx={{ mt: 0.5 }} />
//     </Stack>
//     <Stack direction='column' spacing={0.5} sx={{ minWidth: 0 }}>
//       {contact?.name && (
//         <Typography component='div' variant='subtitle2'>
//           {contact?.name}
//         </Typography>
//       )}
//       {contact?.email && (
//         <AppTypography ellipsing={{ enabled: true }} component='div' variant='body2'>
//           {contact?.email}
//         </AppTypography>
//       )}
//       {contact?.phoneNumber && (
//         <Typography component='div' variant='body2'>
//           {contact?.phoneNumber}
//         </Typography>
//       )}
//       {children && children}
//     </Stack>
//     <Stack direction='column' sx={{ justifyContent: "flex-start" }}>
//       {action && action}
//     </Stack>
//   </Stack>
// </BaseContactCard>
