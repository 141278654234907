import { LocalStorageKey } from "@/common/constants/localStorage";
import {
  CreateRepairSpecDto,
  RepairMaterialDto,
  RepairSpecDataDto,
  RepairSpecDto,
  RepairSpecSparePartDto,
  RepairWorkDto,
  UpdateRepairSpecDto,
} from "@/core/api/generated";

import { LocalStorageHelper } from "../localStorage";

export interface RepairSpecSettingsInLocalStorage {
  /** Tenant to be selected by default when accessing cross-tenant repair specs. */
  defaultTenantIdForCrossTenant?: Nil<string>;
}

export class RepairSpecHelper {
  /** Checks if there is any currency mismatch between RepairSpec and RepairMaterial/RepairWork. */
  public static isCurrencyMismatch({
    repairSpec,
    repairMaterial,
    repairWork,
    repairSparePart,
  }: {
    repairSpec: RepairSpecDto | CreateRepairSpecDto | UpdateRepairSpecDto | null | undefined;
    repairMaterial?: RepairMaterialDto | null;
    repairWork?: RepairWorkDto | null;
    repairSparePart?: RepairSpecSparePartDto | null;
  }): boolean {
    const currency1 = repairSpec?.currency;
    const currency2 = repairMaterial?.currency || repairWork?.currency || repairSparePart?.currency;

    return (currency1 && currency2 && currency1.code !== currency2.code) ?? false;
  }

  public static isExternal(params: {
    tenantId: Nil<string>;
    repairSpec: Nil<RepairSpecDto | RepairSpecDataDto>;
  }): boolean {
    return (
      !!params.tenantId && !!params.repairSpec && params.tenantId !== params.repairSpec.tenantId
    );
  }

  public static isSourceExternal(params: {
    tenantId: Nil<string>;
    repairSpec: Nil<RepairSpecDto | RepairSpecDataDto>;
  }): boolean {
    return (
      !!params.tenantId &&
      !!params.repairSpec?.sourceTenantId &&
      params.tenantId !== params.repairSpec.sourceTenantId
    );
  }

  public static getLocalSettings(): Nil<RepairSpecSettingsInLocalStorage> {
    return LocalStorageHelper.getTypedJsonItem<RepairSpecSettingsInLocalStorage>(
      LocalStorageKey.repairSpecSettings,
    );
  }

  public static saveLocalSettings(settings: Nil<RepairSpecSettingsInLocalStorage>): void {
    LocalStorageHelper.setJsonItem(LocalStorageKey.repairSpecSettings, settings);
  }
}
