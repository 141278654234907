import { Box, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { StringHelper } from "@/common/helpers/string";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { VehicleDamageDto, VehicleDamageSearchPaginatedDto } from "@/core/api/generated";

import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import DamageDetectionInline from "../DamageDetection/DamageDetectionInline";
import VehicleInline from "../Vehicle/VehicleInline";
import VehicleDamageViewModal from "./View/VehicleDamageViewModal";

export interface VehicleDamageAutocompleteOption extends BaseAutocompleteOption<VehicleDamageDto> {
  subtitle?: string;
}

export const getVehicleDamageOptionTitle = (data: VehicleDamageDto): string =>
  StringHelper.joinIntoString(
    [
      data.partType && data.damageType
        ? `${data.partType?.name} - ${data.damageType?.name}`
        : data.localNumber,
    ],
    ", ",
    {
      skipEmpty: true,
    },
  );

export const vehicleDamageToOption = (data: VehicleDamageDto): VehicleDamageAutocompleteOption => ({
  id: data.id!,
  title: getVehicleDamageOptionTitle(data),
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface VehicleDamageAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<VehicleDamageDto> {
  searchFilters: {
    vehicleId: string;
  } & Partial<Omit<VehicleDamageSearchPaginatedDto, "search" | "includeIds">>;
}

export default function VehicleDamageAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: VehicleDamageAutocompleteProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={vehicleDamageToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.vehicleDamagesApi.apiV1VehiclesVehicleIdDamagesSearchPost,
        limit: 25,
        parameters: {
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          vehicleId: searchFilters.vehicleId,
          vehicleDamageSearchPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          vehicleDamageSearchPaginatedDto: {
            ...params.vehicleDamageSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Vehicle damage'
      placeholder='Search for vehicle damage...'
      renderOption={(props, option: VehicleDamageAutocompleteOption) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='vehicleDamage' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography
                    component='div'
                    variant={
                      option.optionType === AutocompleteOptionType.DynamicCreateNew
                        ? "subtitle1"
                        : "body1"
                    }
                  >
                    {option.title}
                  </Typography>

                  {option.data && (
                    <Typography component='div' variant='body2'>
                      <Box>
                        {option.data.localNumber},{" "}
                        <InlineApiEnumValue type='VehicleDamageState' value={option.data.state} />,{" "}
                        <InlineApiEnumValue type='VehicleArea' value={option.data.area} />,{" "}
                        <InlineApiEnumValue
                          type='VehicleProjection'
                          value={option.data.projection}
                        />
                      </Box>

                      <Box>
                        <VehicleInline
                          entity={option.data.vehicle}
                          withIcon={false}
                          withTooltip={false}
                        />
                      </Box>

                      {option.data?.damageDetection && (
                        <Box>
                          <DamageDetectionInline
                            entity={option.data?.damageDetection}
                            withIcon={false}
                            withTooltip={false}
                          />
                        </Box>
                      )}
                    </Typography>
                  )}
                </>
              }
            />
          </ListItem>
        );
      }}
      renderEntityViewModal={(params) => (
        <VehicleDamageViewModal
          {...params.dialogProps}
          viewProps={{
            vehicleId: undefined,
            vehicleDamageId: undefined,
            vehicleDamage: params.entity,
          }}
        />
      )}
      {...otherProps}
    />
  );
}
