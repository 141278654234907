import { Box, BoxProps } from "@mui/material";
import { useRef } from "react";

import { EnvHelper } from "@/common/helpers/env";

import InfoTile from "../Form/Display/InfoTile";
import AppTypography from "../Text/AppTypography";

interface Props {
  isPositionFixed?: boolean;
  description?: string;
  sx?: BoxProps["sx"];
}

/** Tracks and displays number of renders. */
export default function DevRenderCount({ isPositionFixed, description, sx }: Props) {
  const renderCountRef = useRef(0);
  renderCountRef.current += 1;

  if (!EnvHelper.isLocalhostAny) {
    return null;
  }

  return (
    <Box
      sx={{
        width: "fit-content",
        ...(isPositionFixed
          ? {
              position: "absolute",
              top: 100,
              right: 60,
              zIndex: (th) => th.zIndex.tooltip,
              boxShadow: (th) => th.shadows[6],
            }
          : {}),
        ...sx,
      }}
    >
      <InfoTile sx={{ backgroundColor: (th) => th.palette.highlight.main }}>
        <Box>
          <AppTypography variant='subtitle1'>Render count: {renderCountRef.current}</AppTypography>
          {description && <AppTypography variant='body2'>{description}</AppTypography>}
        </Box>
      </InfoTile>
    </Box>
  );
}
