import { DependencyList, EffectCallback, useEffect } from "react";

import { useValueMemoWithDeepCompare } from "../memo/useValueMemoWithDeepCompare";

/** useEffect with deep compare for deps array.
 *  Source: https://stackoverflow.com/a/54096391/5168794
 */
export function useEffectWithDeepCompare(effect: EffectCallback, deps?: DependencyList): void {
  // useEffect(effect, deps?.map(useValueMemoWithDeepCompare));
  const depsMemorized = useValueMemoWithDeepCompare(deps);
  useEffect(effect, depsMemorized);
}
