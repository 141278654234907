import { Chip } from "@mui/material";
import { Moment } from "moment";
import { useMemo } from "react";

import { DatetimeHelper } from "@/common/helpers/datetime";
import { ChatHistoryItemDto } from "@/core/api/generated";

export interface ChatDateNavigationItem {
  afterHistoryItem: ChatHistoryItemDto;
}

interface ChatDateNavigationChipProps {
  date: Moment | string | undefined;
}

export default function ChatDateNavigationChip({ date }: ChatDateNavigationChipProps) {
  const label = useMemo(() => {
    return DatetimeHelper.humanizeDateDayFromNow(date);
  }, [date]);

  return <Chip label={label} variant='outlined' color='primary' />;
}
