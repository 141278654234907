import { Tooltip } from "@mui/material";
import { forwardRef } from "react";

import { ChatHistoryItemDto } from "@/core/api/generated";

import AppIcon, { AppIconProps } from "./AppIcon";

type AppIconPropsLocal = Omit<AppIconProps, "of">;

interface Props extends AppIconPropsLocal {
  item?: ChatHistoryItemDto | null;
  withTooltip?: boolean;
}

/** DIsplays icon based on chat history item type. */
export default forwardRef<SVGSVGElement, Props>(function ChatHistoryItemTypeIcon(
  { item, withTooltip, ...other }: Props,
  ref,
) {
  let iconEl: React.ReactNode | null = null;
  let title = "item";
  if (item?.message) {
    iconEl = <AppIcon of='chatMessage' ref={ref} {...other} />;
    title = "Message";
  } else if (item?.event) {
    iconEl = <AppIcon of='chatEvent' ref={ref} {...other} />;
    title = "Event";
  } else if (item?.negotiationProposal) {
    iconEl = <AppIcon of='negotiationProposal' ref={ref} {...other} />;
    title = "Proposal";
  }

  if (!iconEl) {
    return null;
  }

  return withTooltip && item ? <Tooltip title={title}>{iconEl}</Tooltip> : iconEl;
});
