import { DependencyList, useCallback, useEffect } from "react";

import { tenantToTenantEventEmitter } from "@/common/eventEmmiters/tenantToTenantEventEmitter";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import {
  DataUpdatesHubClientMethodName,
  EntityType,
  TenantToTenantCountersDto,
} from "@/core/api/generated";
import * as tenantToTenantSlice from "@/store/tenantToTenant/tenantToTenantSlice";

import { useRealtimeDataUpdates } from "../../realtime/useRealtimeDataUpdates";
import { useAppSelector, useAppThunkDispatch } from "../../redux";
import { useCurrentTenant } from "../tenant/useCurrentTenant";

export const useTenantToTenantCounters = (props?: {
  deps?: DependencyList;
}): TenantToTenantCountersDto | undefined => {
  const currentTenant = useCurrentTenant();
  const thunkDispatch = useAppThunkDispatch();

  const counters = useAppSelector((x) => x.tenantToTenant.counters);

  const refetchCounters = useCallback(() => {
    thunkDispatch(tenantToTenantSlice.getCountersThrottle());
  }, []);

  useEffect(() => {
    if (!counters) {
      refetchCounters();
    }
  }, [counters, ...(props?.deps || [])]);

  // refetch on local changes
  useEffect(() => {
    const subs = [
      tenantToTenantEventEmitter.on2("tenantConnectionRequestCreated", (params) => {
        refetchCounters();
      }),
      tenantToTenantEventEmitter.on2("tenantConnectionRequestUpdated", (params) => {
        refetchCounters();
      }),
      tenantToTenantEventEmitter.on2("tenantConnectionRequestDeleted", (params) => {
        refetchCounters();
      }),
    ];
    return () => {
      subs.forEach((x) => x.off());
    };
  }, []);

  // refetch on remote changes
  useRealtimeDataUpdates({
    enabled: !!currentTenant?.id,
    channelNames: [
      DataUpdatesChannelName.Entities(undefined, EntityType.TenantConnectionRequest),
      DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.TenantConnectionRequest),
      DataUpdatesChannelName.Entities(undefined, EntityType.TenantConnection),
      DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.TenantConnection),
      DataUpdatesChannelName.Entities(undefined, EntityType.TenantRequest),
      DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.TenantRequest),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      refetchCounters();
    },
  });

  return counters;
};
