import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { LocationDto, LocationReferenceDto } from "@/core/api/generated";

import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getLocationOptionTitle } from "./LocationAutocomplete";

export interface LocationInlineProps extends BaseEntityInlineInheritableProps {
  entity?: LocationDto | LocationReferenceDto | null | undefined;
  entityId?: string | null;
}

/** Inline brief information about the entity. */
export default function LocationInline({ entity, entityId, ...otherProps }: LocationInlineProps) {
  const request = useApiRequest(
    apiClient.locationsApi.apiV1LocationsLocationIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      locationId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );

  entity = request?.data || entity;

  const full = entity as LocationDto;
  // const ref = entity as LocationReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='location' inText />}
      content={<>{full && getLocationOptionTitle(full)}</>}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Department"}
    />
  );
}
