import { Box, IconButton, Typography } from "@mui/material";

import BaseHeader from "@/App/BaseApp/Header/BaseHeader";
import AppIcon from "@/common/components/Icons/AppIcon";
import NexusIcon from "@/common/components/Icons/Svg/NexusIcon";
import AppTypography from "@/common/components/Text/AppTypography";
import { NavDisplayVariants, useNavDisplay } from "@/common/contexts/useNavDisplay";

function Header() {
  const { navDisplay, navToggle, navDisplayWidth } = useNavDisplay();
  return (
    <BaseHeader
      mainContent={
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              py: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: navDisplayWidth.expanded,
                mr: 3,
              }}
            >
              <Box
                sx={{
                  width: navDisplayWidth.collapsed,
                  pl: 3.5,
                  mr: 0.5,
                }}
              >
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    navToggle && navToggle({ variant: "button" });
                  }}
                  title={`${
                    navDisplay === NavDisplayVariants.collapsed ? "Expand" : "Collapse"
                  } navigation`}
                >
                  <AppIcon of='menu' />
                </IconButton>
              </Box>
              <NexusIcon width='27' height='27' viewBox='0 0 27 27' />
              <Typography
                component='div'
                variant='h5'
                sx={{ color: (t) => t.palette.background.logo?.text, pl: 1, fontWeight: 500 }} // unknown color
              >
                Nexus
              </Typography>
              <Typography
                component='div'
                variant='h5'
                sx={{ color: (t) => t.palette.background.logo?.text, fontWeight: 400 }} // unknown color
              >
                Fleet
              </Typography>
            </Box>
            <AppTypography
              component='div'
              variant='h6'
              sx={{ display: "flex", alignItems: "center" }}
            >
              Admin portal
            </AppTypography>
          </Box>
        </>
      }
    />
  );
}

export default Header;
