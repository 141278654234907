import "@/common/extensions/index";
import { CustomTypeExtensionsManager } from "@/common/extensions/index";
import { appCommonConfig } from "@/config/config";
import { featureManager } from "@/config/features";

export class AppHelper {
  public static defineCustomTypeExtensions(): void {
    CustomTypeExtensionsManager.define();
  }

  public static logAppInfoAtStartup(): void {
    console.log("App info:", {
      appName: appCommonConfig.appName,
      version: appCommonConfig.version,
      customEnv: appCommonConfig.customEnv,
      apiUrl: appCommonConfig.apiUrl,
      featureFlags: featureManager.getFeatureFlags(),
    });
  }
}
