import { getIn } from "formik";
import _ from "lodash";
import { ComponentType, memo } from "react";

import { ComparisonHelper } from "./comparison";
import { EnvHelper } from "./env";
import { TypeHelper } from "./type";
import { getTypedPath } from "./typedPath";

/** Function passed to React.memo.
 *  Docs: https://react.dev/reference/react/memo
 */
export type ArePropsEqualFunc<TProps = any> = (
  prevProps: Readonly<TProps>,
  nextProps: Readonly<TProps>,
) => boolean;

export type ArePropsEqualFuncFactory<TProps = any> = () => ArePropsEqualFunc<TProps>;

/** React.memo with changed argument order. Improves code readability in some cases. */
export function memoWithReversedArgs<T extends ComponentType<any>>(
  propsAreEqual: Parameters<typeof memo<T>>[1],
  Component: Parameters<typeof memo<T>>[0],
) {
  return memo(Component, propsAreEqual);
}

/** Helpers for React.memo are arePropsEqual function. */
class ArePropsEqualHelper {
  /** Default implementation used by React.memo. Uses shallow comparison.
   *  Docs: https://react.dev/reference/react/memo
   */
  public static default(prevProps: Readonly<any>, nextProps: Readonly<any>): boolean {
    if (Object.is(prevProps, nextProps)) {
      return true;
    }

    const prevKeys = Object.keys(prevProps);
    const nextKeys = Object.keys(nextProps);
    if (prevKeys.length !== nextKeys.length) {
      return false;
    }

    for (const key of prevKeys) {
      if (!Object.is(prevProps[key], nextProps[key])) {
        return false;
      }
    }

    return true;
  }

  public static shallowAndIgnoreFunctions(
    prevProps: Readonly<any>,
    nextProps: Readonly<any>,
  ): boolean {
    if (Object.is(prevProps, nextProps)) {
      return true;
    }

    const prevKeys = Object.keys(prevProps);
    const nextKeys = Object.keys(nextProps);
    if (prevKeys.length !== nextKeys.length) {
      return false;
    }

    for (const key of prevKeys) {
      const isFunction =
        TypeHelper.isFunction(prevProps[key]) && TypeHelper.isFunction(nextProps[key]);
      if (isFunction) {
        continue;
      }

      if (!Object.is(prevProps[key], nextProps[key])) {
        return false;
      }
    }

    return true;
  }

  /** Creates arePropsEqual function which by default uses shallow comparison and compares all props, but also supports configuration.
   * NB1: Props to compare = (onlyProps || allProps) - (isIgnoreFunctions ? functionProps : []) - excludeProps + includeProps.
   * NB2: Props path can be either: root object key (e.g. value, checked, etc.) or deep object key (e.g. address.street, contact.name.firstName, etc.).
   */
  public static factory<P>(params: {
    /** Set to see computation logs. */
    debugKey?: string;
    /** Ignore all props that are functions. */
    isExcludeFunctionProps?: boolean;
    /** Exact props to compare. */
    onlyProps?: (typedPathWrapper: ReturnType<typeof getTypedPath<P>>) => string[];
    /** Props to ignore during comparison. */
    excludeProps?: (typedPathWrapper: ReturnType<typeof getTypedPath<P>>) => string[];
    /** Additional props to compare. */
    includeProps?: (typedPathWrapper: ReturnType<typeof getTypedPath<P>>) => string[];
    /** Props to compare using deep comparison. */
    deepCompareProps?: (typedPathWrapper: ReturnType<typeof getTypedPath<P>>) => string[];
  }): ArePropsEqualFunc {
    // this scope is closed over once and isn't evaluated during render

    const typedPathWrapper = getTypedPath<P>();

    const onlyProps = params.onlyProps ? params.onlyProps(typedPathWrapper) : [];
    const onlyPropsMap = _.chain(onlyProps)
      .mapKeys((x) => x)
      .mapValues((x) => true)
      .value();
    const isCheckOnlyProps = !TypeHelper.isEmptyObject(onlyPropsMap);

    const excludeProps = params.excludeProps ? params.excludeProps(typedPathWrapper) : [];
    const excludePropsPropsMap = _.chain(excludeProps)
      .mapKeys((x) => x)
      .mapValues((x) => true)
      .value();
    const isCheckExcludeProps = !TypeHelper.isEmptyObject(excludePropsPropsMap);

    const includeProps = params.includeProps ? params.includeProps(typedPathWrapper) : [];
    const includePropsPropsMap = _.chain(includeProps)
      .mapKeys((x) => x)
      .mapValues((x) => true)
      .value();
    const isCheckIncludeProps = !TypeHelper.isEmptyObject(includePropsPropsMap);

    const deepCompareProps = params.deepCompareProps
      ? params.deepCompareProps(typedPathWrapper)
      : [];
    const deepComparePropsMap = _.chain(deepCompareProps)
      .mapKeys((x) => x)
      .mapValues((x) => true)
      .value();

    function arePropValuesEqual<T1, T2>(key: string, value1: T1, value2: T2): boolean {
      const isUseDeepCompare = deepComparePropsMap[key];
      // if (params.debugKey && key === "value") {
      //   console.log(0, params.debugKey, {
      //     key,
      //     value1,
      //     value2,
      //     equal: isUseDeepCompare
      //       ? ComparisonHelper.isDeepEqual(value1, value2)
      //       : Object.is(value1, value2),
      //     isUseDeepCompare,
      //   });
      // }

      return isUseDeepCompare
        ? ComparisonHelper.isDeepEqual(value1, value2)
        : Object.is(value1, value2);
    }

    return (prevProps, nextProps) => {
      // this scope evaluated on every render!

      if (Object.is(prevProps, nextProps)) {
        return true;
      }

      let prevKeys = Object.keys(prevProps);
      let nextKeys = Object.keys(nextProps);
      if (isCheckOnlyProps) {
        prevKeys = [...onlyProps];
        nextKeys = [...onlyProps];
      }
      if (isCheckExcludeProps) {
        prevKeys = prevKeys.filter((key) => !excludePropsPropsMap[key]);
        nextKeys = nextKeys.filter((key) => !excludePropsPropsMap[key]);
      }
      if (isCheckIncludeProps) {
        prevKeys = prevKeys.concat(includeProps);
        nextKeys = nextKeys.concat(includeProps);
      }

      if (prevKeys.length !== nextKeys.length) {
        return false;
      }

      function getChangedProps(options?: { isComputeAll?: boolean }) {
        const result = {
          isAnyChanged: false,
          changed: [] as Array<{ key: string; prevValue: any; nextValue: any }>,
        };

        for (const key of prevKeys) {
          const prevValue = getIn(prevProps, key);
          const nextValue = getIn(nextProps, key);

          const isFunction = TypeHelper.isFunction(prevValue) && TypeHelper.isFunction(nextValue);
          if (isFunction && params?.isExcludeFunctionProps) {
            continue;
          }

          const isEqual = arePropValuesEqual(key, prevValue, nextValue);
          if (!isEqual) {
            result.isAnyChanged = true;
            result.changed.push({ key, prevValue: prevValue, nextValue: nextValue });
            if (!options?.isComputeAll) {
              break;
            }
          }
        }

        return result;
      }

      // for debug compute and log all changed props
      const isForDebug = !!params.debugKey && EnvHelper.isDevelopmentLocalhost;
      const changed = getChangedProps({ isComputeAll: isForDebug });
      if (changed.isAnyChanged) {
        if (isForDebug) {
          console.log(`${params.debugKey}. Props aren't equal. Changed props:`, {
            prevProps,
            nextProps,
            isAnyChanged: changed.isAnyChanged,
            changed: changed.changed,
            prevKeys,
            nextKeys,
            onlyPropsMap,
            ignorePropsMap: excludePropsPropsMap,
            deepComparePropsMap,
          });
        }

        return false;
      }

      return true;
    };
  }
}

/** Helpers for React.memo */
export class MemoHelper {
  public static readonly arePropsEqual = ArePropsEqualHelper;
}
