import { Collapse, CollapseProps, Stack, SxProps, Theme, Typography } from "@mui/material";
import { ReactNode } from "react";

import AppIcon, { AppIconOf } from "../Icons/AppIcon";

interface Props extends CollapseProps {
  iconOf?: AppIconOf;
  label?: string;
  actions?: ReactNode;
  children: ReactNode;
  collapseContent?: ReactNode;
  sx?: SxProps<Theme>;
}

export default function CollapseItem({
  iconOf,
  label,
  actions,
  children,
  collapseContent,
  sx,
  onClick,
  ...collapseProps
}: Props) {
  return (
    <Stack
      direction='column'
      onClick={onClick}
      sx={{
        p: 2,
        borderRadius: (th) => th.shapeCustom.borderRadiusCard,
        boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
        cursor: !collapseProps.in ? "pointer" : "default",
        background: (t) => t.palette.background.paper,
        ...sx,
      }}
    >
      <Stack direction='row' sx={{ justifyContent: "space-between" }}>
        <Stack spacing={2} direction='row' sx={{ alignItems: "center" }}>
          {iconOf && (
            <AppIcon
              of={iconOf}
              fontSize='medium'
              sx={{
                fill: (th) =>
                  collapseProps.in ? th.palette.primary.main : th.palette.secondary.main,
              }}
            />
          )}
          {label && <Typography variant='body1'>{label}</Typography>}
        </Stack>
        <AppIcon of={collapseProps.in ? "expandLess" : "expandMore"} fontSize='medium' />
      </Stack>
      {!collapseProps.in && collapseContent}
      <Collapse {...collapseProps}>{children}</Collapse>
    </Stack>
  );
}
