import { Button, Stack, SxProps, Theme } from "@mui/material";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIconButton from "@/common/components/Button/AppIconButton";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import EntityTagsInfoDisplay from "@/common/components/EntityInfo/EntityTagsInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import TableCellContentOfAttachments from "@/common/components/Table/TableCell/TableCellContentOfAttachments";
import TableCellContentOfCount from "@/common/components/Table/TableCell/TableCellContentOfCount";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { EntityHelper } from "@/common/helpers/entity";
import { TagStaticFilterInputDto, TagsHelper } from "@/common/helpers/entity/tags";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ApiEnumName } from "@/common/services/enum";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  FilterDefinitionDto,
  GeneralAttachedTagsInputDto,
  MaintenanceDto,
  RepairOperationDto,
  RepairOperationGetPaginatedDto,
  TagEntityType,
} from "@/core/api/generated";

import AffiliationInfoDisplay from "../../EntityAffiliation/AffiliationInfoDisplay";
import GeneralByWhoDisplay from "../../General/Display/GeneralByWhoDisplay";
import GeneralAttachedTagsOfEntitiesEditModal from "../../General/GeneralTag/GeneralAttachedTagsOfEntitiesEditModal";
import TenantStructureMembershipOfEntityEditModal from "../../Membership/TenantStructureMembershipOfEntityEditModal";
import SupplierInline from "../../Supplier/SupplierInline";
import VehicleInline from "../../Vehicle/VehicleInline";
import RepairOperationMenu from "../RepairOperationMenu";
import RepairOperationsDeleteModal from "../RepairOperationsDeleteModal";

enum BulkActionFlags {
  UpdateTenantStructureMembership = "UpdateTenantStructureMembership",
  UpdateTags = "UpdateTags",
  Delete = "Delete",
}
const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  filters: true,
  create: true,
  edit: true,
  delete: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultPropagatedDeps = {
  refetch: {},
};
export type RepairOperationPaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;
type RepairOperationGetPaginatedInputDto = Omit<RepairOperationGetPaginatedDto, "tags"> & {
  tags?: TagStaticFilterInputDto;
};

export interface RepairOperationPaginatedListOwnProps {
  displayProps?: Partial<typeof defaultDisplayProps>;
  initialValues?: {
    filterDefinitionDto?: Nil<FilterDefinitionDto>;
  };
  defaultValues?: {
    limit?: number;
    vehicleId?: string | null;
    contractId?: string | null;
    customerId?: string | null;
    anyVehicleDamageIds?: string[] | null;
  };
  propagatedDeps?: PropagatedDeps<RepairOperationPaginatedListPropagatedDepsMap>;
  sx?: SxProps<Theme>;
}

export type RepairOperationPaginatedListProps = RepairOperationPaginatedListOwnProps;

export default function RepairOperationPaginatedList({
  displayProps,
  initialValues,
  defaultValues,
  propagatedDeps,
  sx,
}: RepairOperationPaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<RepairOperationGetPaginatedInputDto>({
    statePersistence: {
      persistenceKey: EntityType.RepairOperation,
    },
    initialValues: {
      filterDefinitionDto: initialValues?.filterDefinitionDto || undefined,
    },
    defaultValues: {
      limit: defaultValues?.limit,
      params: {
        ...defaultValues,
      },
    },
    initParams: (filterDefinition) => {
      const tagFilter = filterDefinition.filterDefinition?.items.find(
        (x) => x.field === getTypedPath<RepairOperationDto>().tags.$path,
      );

      return {
        tags: {
          operator: tagFilter?.operator,
          query: tagFilter?.value as GeneralAttachedTagsInputDto,
        } as TagStaticFilterInputDto,
      };
    },
  });

  const paginatedRepairOperationsRequest = useApiRequest(
    apiClient.repairOperationsApi.apiV1RepairOperationsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      repairOperationGetPaginatedDto: {
        ..._.omit(commonRequestParams.params, "tags"),
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        tags:
          commonRequestParams.filterDefinition?.items
            .filter((x) => x.field === getTypedPath<RepairOperationDto>().tags.$path)
            .map((x) => TagsHelper.mapFromFilter(x))[0] || undefined,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [
        ...commonRequestParams.deps,
        commonRequestParams.filterDefinition,
        propagatedDeps?.depsMap["refetch"],
      ],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedRepairOperations = paginatedRepairOperationsRequest?.data;

  useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.RepairOperation)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedRepairOperationsRequest.handleEntityChanged(data);
    },
  });

  return (
    <ViewLayoutV2
      displayProps={displayProps}
      header={
        displayProps?.header && (
          <SimpleViewPageHeader
            title='Repair operations'
            primaryActions={
              displayProps?.create && (
                <AuthorizedElement permissionsAny={[AppPermission.RepairOperationManage]}>
                  <Button
                    variant='contained'
                    color='primary'
                    startIcon={<AppIcon of='add' />}
                    component={RouterLink}
                    to={ROUTE_PATH.REPAIR_OPERATION_CREATE({
                      vehicleId: commonRequestParams?.params?.vehicleId,
                      contractId: commonRequestParams?.params?.contractId,
                    })}
                  >
                    Create new repair operation
                  </Button>
                </AuthorizedElement>
              )
            }
          />
        )
      }
    >
      <DataTabular
        columns={[
          {
            field: getTypedPath<RepairOperationDto>().id.$path,
            title: "ID",
            width: 250,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.id,
            filters: {
              fieldType: FilterFieldType.Id,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairOperationDto>().localNumber.$path,
            title: "Number",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <Stack direction='row' spacing={0.5}>
                <span>{item.localNumber}</span>
              </Stack>
            ),
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: "Info badges",
            title: "Info badges",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => <EntityChipList entity={item} variant='compact' />,
          },
          {
            field: getTypedPath<RepairOperationDto>().stageHistory.stage.$path,
            title: "Stage",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <InlineApiEnumValue type='RepairOperationStage' value={item.stageHistory!.stage} />
            ),
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: true,
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.RepairOperationStage,
                },
              },
            },
          },
          {
            field: getTypedPath<RepairOperationDto>().vehicle.id.$path,
            title: "Vehicle",
            width: 300,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => <VehicleInline entity={item.vehicle} />,
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfVehicle(),
            },
          },
          {
            field: getTypedPath<MaintenanceDto>().supplier.id.$path,
            title: "Supplier",
            width: 300,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => (item.supplier ? <SupplierInline entity={item.supplier} /> : "-"),
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfSupplier(),
            },
          },
          {
            field: getTypedPath<RepairOperationDto>().name.$path,
            title: "Name",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.name || "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairOperationDto>().description.$path,
            title: "Description",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => item.description || "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairOperationDto>().date.$path,
            title: "Date",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.createdAt ? <Datetime datetime={item.date} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairOperationDto>().responsibleUser.$path,
            title: "Responsible user",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.responsibleUser ? (
                <GeneralByWhoDisplay who={item.responsibleUser} size='full' />
              ) : (
                "-"
              ),
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<RepairOperationDto>().notes.$path,
            title: "Notes",
            width: 100,
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => item.notes || "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairOperationDto>().draftAt.$path,
            title: "Draft at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.draftAt ? <Datetime datetime={item.draftAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairOperationDto>().inProgressAt.$path,
            title: "In progress at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.inProgressAt ? (
                <Datetime datetime={item.inProgressAt} withDurationFromNow />
              ) : (
                "-"
              ),
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairOperationDto>().completedAt.$path,
            title: "Completed at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.completedAt ? <Datetime datetime={item.completedAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairOperationDto>().createdAt.$path,
            title: "Created at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairOperationDto>().updatedAt.$path,
            title: "Updated at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairOperationDto>().createdBy.$path,
            title: "Created by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<RepairOperationDto>().updatedBy.$path,
            title: "Updated by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<RepairOperationDto>().items.$path,
            title: "Items",
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            isToDisabled: true,
            renderCell: (item) => <TableCellContentOfCount count={item.items?.length} />,
          },
          {
            field: getTypedPath<RepairOperationDto>().attachments.$path,
            title: "Attachments",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            isToDisabled: true,
            renderCell: (item) => <TableCellContentOfAttachments attachments={item.attachments} />,
          },
          {
            field: "affiliation",
            title: "Affiliation",
            description: "Affiliation to departments & locations",
            width: 120,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            isToDisabled: true,
            renderCell: (item) => (
              <AffiliationInfoDisplay
                variant='compact'
                tenantId={item.tenantId}
                departmentIds={item.departmentIds}
                locationIds={item.locationIds}
              />
            ),
          },
          {
            field: getTypedPath<RepairOperationDto>().tags.$path,
            title: "Tags",
            flex: 0,
            renderCell: (item) => (
              <EntityTagsInfoDisplay
                entityType={TagEntityType.RepairOperation}
                entity={item}
                noDataPlaceholder='-'
                edit={{
                  onSaved: (newValue) =>
                    paginatedRepairOperationsRequest.updateData((data) => {
                      data.items?.forEach((item2) => {
                        if (item2.id === item.id) {
                          item2.tags = newValue || undefined;
                        }
                      });
                    }),
                }}
              />
            ),
            filters: {
              fieldType: FilterFieldType.Arbitrary,
              isUseSingleFilter: true,
              operators: FilterCatalog.getOperatorsForTagsField(),
            },
            isSortable: false,
            isColumnMenuDisabled: true,
            isToDisabled: true,
            isFilterable: true,
          },
        ]}
        rows={paginatedRepairOperations?.items}
        isLoading={paginatedRepairOperationsRequest.isLoading}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.REPAIR_OPERATION_VIEW(item.id)}
        renderRowAction={({ item }) => (
          <RepairOperationMenu
            entity={item}
            onDelete={() => paginatedRepairOperationsRequest.refetch()}
            onUpdate={() => paginatedRepairOperationsRequest.refetch()}
            displayProps={{
              actions: {
                edit: displayProps?.edit || false,
                delete: displayProps?.delete || false,
              },
            }}
          />
        )}
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
        refetch={commonRequestParams.dataTabularProps.refetch}
        bulkActions={{
          enabled: true,
          definition: BulkActionFlags,
          actionTriggers: ({ currentAction, startAction, selectedIds }) => (
            <>
              <AppIconButton
                tooltipProps={{ title: "Update affiliation" }}
                onClick={() => startAction(BulkActionFlags.UpdateTenantStructureMembership)}
              >
                <AppIcon of='department' />
              </AppIconButton>
              <AppIconButton
                tooltipProps={{ title: "Update tags" }}
                onClick={() => startAction(BulkActionFlags.UpdateTags)}
              >
                <AppIcon of='tag' />
              </AppIconButton>

              <AppIconButton
                tooltipProps={{ title: "Delete" }}
                onClick={() => startAction(BulkActionFlags.Delete)}
              >
                <AppIcon of='delete' />
              </AppIconButton>
            </>
          ),
          actionHandlers: ({ selectedIds, currentAction, cancelAction, completeAction }) => {
            const selectedEntities = EntityHelper.filterEntitiesByIds(
              paginatedRepairOperations?.items || [],
              selectedIds as string[],
            );
            return (
              <>
                <RepairOperationsDeleteModal
                  entities={selectedEntities}
                  open={currentAction === BulkActionFlags.Delete}
                  onClose={() => cancelAction()}
                  onDelete={() => {
                    completeAction();
                    paginatedRepairOperationsRequest.refetch();
                  }}
                />
                <TenantStructureMembershipOfEntityEditModal
                  open={currentAction === BulkActionFlags.UpdateTenantStructureMembership}
                  onClose={() => cancelAction()}
                  tenantStructureMembershipOfEntityEditProps={{
                    entityType: EntityType.RepairOperation,
                    entities: selectedEntities,
                    onSave: () => {
                      completeAction();
                      paginatedRepairOperationsRequest.refetch();
                    },
                  }}
                />
                <GeneralAttachedTagsOfEntitiesEditModal
                  tagEntityType={TagEntityType.RepairOperation}
                  entities={selectedEntities}
                  open={currentAction === BulkActionFlags.UpdateTags}
                  onClose={() => cancelAction()}
                  onSaved={() => {
                    completeAction();
                    paginatedRepairOperationsRequest.refetch();
                  }}
                />
              </>
            );
          },
        }}
      />
    </ViewLayoutV2>
  );
}
