import { ListItemIcon, ListItemText, Stack } from "@mui/material";
import { AxiosResponse } from "axios";
import { ReactNode } from "react";

import { ROUTE_PATH } from "@/common/constants/routing";
import { AppPermission, RepairMaterialDto } from "@/core/api/generated";

import AuthorizedMenuItem from "../../Auth/AuthorizedMenuItem";
import EntityMenu from "../../EntityMenu/EntityMenu";
import AppIcon from "../../Icons/AppIcon";
import AppLink from "../../Link/AppLink";
import DeleteRepairMaterialModal from "./DeleteRepairMaterialModal";

enum ActionFlags {
  Edit = "Edit",
  Delete = "Delete",
}
const defaultDisplayProps = {
  actions: {
    edit: true,
    delete: true,
  },
};

interface TriggersProps {
  entity?: RepairMaterialDto | null;
  definition: typeof ActionFlags;
  handleClose: () => void;
  startAction: (props: ActionFlags) => void;
}

interface Props {
  entity?: RepairMaterialDto | null;
  disabled?: boolean;
  onUpdate: ((newValue: RepairMaterialDto | null | undefined) => void) | undefined;
  onDelete?: (() => void) | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  deleteFunc?: (params: { repairMaterialId: string }) => Promise<AxiosResponse<unknown, unknown>>;
  customActions?: (props: TriggersProps) => ReactNode;
}

export default function RepairMaterialMenu({
  entity,
  disabled,
  customActions,
  deleteFunc,
  onUpdate,
  onDelete,
  displayProps = defaultDisplayProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  return (
    <EntityMenu
      definition={ActionFlags}
      entity={entity}
      disabled={disabled}
      actionTriggers={({ handleClose, startAction }) => (
        <>
          {customActions &&
            customActions({
              entity,
              definition: ActionFlags,
              handleClose,
              startAction,
            })}
          {displayProps?.actions?.edit && (
            <AuthorizedMenuItem
              permissions={[AppPermission.RepairCatalogManage]}
              component={AppLink}
              to={ROUTE_PATH.REPAIR_MATERIAL_EDIT(entity?.id)}
            >
              <ListItemIcon>
                <AppIcon of='edit' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </AuthorizedMenuItem>
          )}
          {displayProps?.actions?.delete && (
            <AuthorizedMenuItem
              permissions={[AppPermission.RepairCatalogManage]}
              onClick={() => startAction(ActionFlags.Delete)}
            >
              <ListItemIcon>
                <AppIcon of='delete' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </AuthorizedMenuItem>
          )}
        </>
      )}
      actionHandlers={({ currentAction, cancelAction, completeAction }) => (
        <Stack>
          {/* Delete entity */}
          {entity && (
            <DeleteRepairMaterialModal
              open={currentAction === ActionFlags.Delete}
              onClose={() => cancelAction()}
              deleteFunc={deleteFunc}
              onDelete={() => {
                onDelete && onDelete();
                completeAction();
              }}
              repairMaterial={entity}
            />
          )}
        </Stack>
      )}
    />
  );
}
