import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid2,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { getIn, useFormikContext } from "formik";
import { useCallback } from "react";

import PhoneInput from "@/common/components/Form/Input/PhoneInput";
import AppIcon from "@/common/components/Icons/AppIcon";
import { ArrayHelper } from "@/common/helpers/array";

import { CreateNotificationFormValues } from "../CreateForm";

const CreateNotificationFormSmsInputs = () => {
  const { values, errors, touched, setFieldValue } =
    useFormikContext<CreateNotificationFormValues>();

  const onPhoneChange = useCallback(
    (
      _e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      index: number,
      phone: string | undefined,
      _country: string,
    ) => {
      setFieldValue(`sms.users[${index}].to`, phone);
    },
    [],
  );
  const onMessageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFieldValue(`sms.body`, e.target.value);
    },
    [],
  );

  return (
    <>
      <Stack spacing={1}>
        <Typography component='div' variant='subtitle1'>
          Phone numbers
        </Typography>
        <Stack direction='column' spacing={1}>
          {values.sms?.users?.map((item, index) => {
            return (
              <Card key={index} sx={{ overflow: "unset" }}>
                <CardContent>
                  <FormControl fullWidth margin='dense'>
                    <PhoneInput
                      error={Boolean(
                        getIn(touched, `sms.users[${index}].to`) &&
                          getIn(errors, `sms.users[${index}].to`),
                      )}
                      fullWidth
                      helperText={
                        Boolean(
                          getIn(touched, `sms.users[${index}].to`) &&
                            getIn(errors, `sms.users[${index}].to`),
                        ) && getIn(errors, "sms.users.to")
                      }
                      label='Phone number'
                      value={item?.to || ""}
                      variant='outlined'
                      color='primary'
                      placeholder='Phone number'
                      onChange={(e, phone, country) => onPhoneChange(e, index, phone, country)}
                    />
                    <FormHelperText
                      error={Boolean(
                        getIn(touched, `sms.users[${index}].to`) &&
                          getIn(errors, `sms.users[${index}].to`),
                      )}
                    >
                      {getIn(errors, `sms.users[${index}].to`)}
                    </FormHelperText>
                  </FormControl>
                  <Grid2 size={{ xxs: 12, sm: 12, md: 12, lg: 12 }}>
                    {/* Controls */}
                    <Stack
                      direction='row'
                      sx={{
                        flex: 1,
                        ml: "auto",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Tooltip title='Delete'>
                        <IconButton
                          onClick={() =>
                            setFieldValue(
                              "users",
                              ArrayHelper.removeByIndex(values.sms?.users, index),
                            )
                          }
                        >
                          <AppIcon of='delete' />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Grid2>
                </CardContent>
              </Card>
            );
          })}
        </Stack>

        {/* Add item */}
        <Button
          sx={{ flex: 0, alignSelf: "flex-start" }}
          variant='outlined'
          color='text'
          size='small'
          startIcon={<AppIcon of='add' />}
          onClick={() => {
            setFieldValue("sms.users", [...(values.sms?.users || []), {}]);
          }}
        >
          Add phone
        </Button>
      </Stack>

      <FormControl fullWidth margin='dense' sx={{ mt: 2 }}>
        <TextField
          error={Boolean(getIn(touched, "sms.body") && getIn(errors, "sms.body"))}
          multiline
          variant='outlined'
          fullWidth
          label='Message'
          value={values?.sms?.body || ""}
          onChange={onMessageChange}
        />
        <FormHelperText error={Boolean(getIn(touched, "sms.body") && getIn(errors, "sms.body"))}>
          {getIn(errors, "sms.body")}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default CreateNotificationFormSmsInputs;
