import NavigationIcon from "@mui/icons-material/Navigation";
import { Box, Fab, Stack, Typography } from "@mui/material";

import InlineCode from "@/common/components/Code/InlineCode";
import AppIcon from "@/common/components/Icons/AppIcon";

export default function DevFloatingActionButtons() {
  return (
    <Box>
      <Typography component='div' variant='h4' sx={{ my: 2 }}>
        Floating Action Buttons
      </Typography>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>MUI Floating Action Buttons</InlineCode>
      </Typography>
      <Stack direction='column' spacing={1}>
        <Stack direction='row' spacing={1}>
          <Fab color='primary' aria-label='add'>
            <AppIcon of='add' />
          </Fab>
          <Fab color='primary' aria-label='edit'>
            <AppIcon of='edit' />
          </Fab>
          <Fab variant='extended'>
            <NavigationIcon sx={{ mr: 1 }} />
            Navigate
          </Fab>
          <Fab disabled aria-label='like'>
            <AppIcon of='favorite' />
          </Fab>
        </Stack>
        <Stack direction='row' spacing={1}>
          <Fab size='small' color='primary' aria-label='add'>
            <AppIcon of='add' />
          </Fab>
          <Fab size='medium' color='primary' aria-label='add'>
            <AppIcon of='add' />
          </Fab>
          <Fab color='primary' aria-label='add'>
            <AppIcon of='add' />
          </Fab>
        </Stack>
        <Stack direction='row' spacing={1}>
          <Fab variant='extended' size='small' color='primary' aria-label='add'>
            <NavigationIcon sx={{ mr: 1 }} />
            Extended
          </Fab>
          <Fab variant='extended' size='medium' color='primary' aria-label='add'>
            <NavigationIcon sx={{ mr: 1 }} />
            Extended
          </Fab>
          <Fab variant='extended' color='primary' aria-label='add'>
            <NavigationIcon sx={{ mr: 1 }} />
            Extended
          </Fab>
        </Stack>
      </Stack>
    </Box>
  );
}
