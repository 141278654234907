import { Box, Grid2, Stack, Typography } from "@mui/material";

import AppAvatar from "@/common/components/Avatar/AppAvatar";
import InlineCode from "@/common/components/Code/InlineCode";
import ConnectionStatusIndicator from "@/common/components/Connection/ConnectionStatusIndicator";
import AppIcon, { AppIconOf } from "@/common/components/Icons/AppIcon";
import CompoundIcon from "@/common/components/Icons/CompoundIcon";

const allIconsOf = Object.keys(AppIconOf);

export default function DevDataDisplay() {
  return (
    <Box>
      <Typography component='div' variant='h4' sx={{ my: 2 }}>
        Icons
      </Typography>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>AppIcon</InlineCode>
      </Typography>
      <Box>
        <Stack direction='row' spacing={2} flexWrap='wrap'>
          <Grid2 container rowSpacing={2}>
            {allIconsOf.map((iconOf) => (
              <Grid2 size={{ xxs: 2 }} key={iconOf}>
                <Stack spacing={0.5} alignItems='center'>
                  <AppIcon of={iconOf as AppIconOf} fontSize='medium' />
                  <Typography component='div' variant='caption' color='text.secondary'>
                    {iconOf}
                  </Typography>
                </Stack>
              </Grid2>
            ))}
          </Grid2>
        </Stack>
      </Box>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>CompoundIcon</InlineCode>
      </Typography>
      <Box>
        <Typography component='div' variant='subtitle1' sx={{ my: 2 }}>
          Badge icon
        </Typography>
        <Stack direction='row' spacing={2}>
          <CompoundIcon
            mainIcon={<AppIcon of='notes' fontSize='medium' />}
            badgeIcon={<AppIcon of='remove' />}
          />
          <CompoundIcon
            mainIcon={<AppIcon of='notes' fontSize='medium' />}
            badgeIcon={<AppIcon of='add' />}
          />
          <CompoundIcon
            mainIcon={<AppIcon of='notes' fontSize='medium' />}
            badgeIcon={<AppIcon of='delete' />}
          />
          <CompoundIcon
            mainIcon={<AppIcon of='vehicle' fontSize='medium' />}
            badgeIcon={<AppIcon of='info' />}
          />
        </Stack>
      </Box>
      <Typography variant='h5' sx={{ my: 2 }}>
        <InlineCode>AppAvatar</InlineCode>
      </Typography>
      <Box mt={1}>
        <Typography variant='h5'>small</Typography>
        <AppAvatar size={"small"} />
      </Box>
      <Box mt={1}>
        <Typography variant='h5'>medium</Typography>
        <AppAvatar size={"medium"} />
      </Box>
      <Box mt={1}>
        <Typography variant='h5'>large</Typography>
        <AppAvatar size={"large"} />
      </Box>

      <Box>
        <Typography variant='h5' sx={{ my: 2 }}>
          <InlineCode>ApiConnectionStatusIndicator variant = icon</InlineCode>
        </Typography>
        <Box>
          <Typography variant='h6' sx={{ my: 2 }}>
            connected = false
          </Typography>
          <ConnectionStatusIndicator alwaysVisible={true} connected={false} variant='icon' />
        </Box>
        <Box>
          <Typography variant='h6' sx={{ my: 2 }}>
            connected = true
          </Typography>
          <ConnectionStatusIndicator alwaysVisible={true} connected={true} variant='icon' />
        </Box>
      </Box>
    </Box>
  );
}
