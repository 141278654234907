import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { WheelOperationDto, WheelOperationReferenceDto } from "@/core/api/generated";

import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getWheelOperationOptionTitle } from "./WheelOperationAutocomplete";

export interface WheelOperationInlineProps extends BaseEntityInlineInheritableProps {
  entity: WheelOperationDto | WheelOperationReferenceDto | null | undefined;
  entityId?: string | null;
}

export default function WheelOperationInline({
  entity,
  entityId,
  ...otherProps
}: WheelOperationInlineProps) {
  const request = useApiRequest(
    apiClient.wheelOperationsApi.apiV1WheelOperationsWheelOperationIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      wheelOperationId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as WheelOperationDto;
  // const ref = entity as WheelOperationReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='wheelOperation' inText />}
      content={<>{full && getWheelOperationOptionTitle(full)}</>}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Wheel operation"}
    />
  );
}
