import { UnitOfTime } from "@/core/api/generated";

export const DurationRepresentation = {
  /** https://en.wikipedia.org/wiki/ISO_8601#Time_intervals */
  ISO8601: "ISO8601",

  /** https://learn.microsoft.com/en-us/dotnet/api/system.timespan */
  DotNetTimeSpan: "DotNetTimeSpan",
} as const;
export type DurationRepresentation =
  (typeof DurationRepresentation)[keyof typeof DurationRepresentation];

export type DurationParts = Partial<Record<UnitOfTime, number | undefined>>;
