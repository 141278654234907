import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik, getIn } from "formik";
import _ from "lodash";
import * as Yup from "yup";

import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useUserAffiliation } from "@/common/hooks/useUserAffiliation";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  EntityType,
  PoolCreateDto,
  PoolDto,
  PoolItemType,
  PoolStructureType,
  PoolUpdateDto,
} from "@/core/api/generated";

import ApiEnumAutocomplete from "../../Enum/ApiEnumAutocomplete";
import ApiEnumsAutocomplete from "../../Enum/ApiEnumsAutocomplete";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FormActions from "../../Form/FormActions";
import AppTextArea from "../../Form/Input/AppTextArea";
import EntityAffiliationInput from "../EntityAffiliation/EntityAffiliationInput";
import GeneralEntitySubTypeInput from "../General/Input/GeneralEntitySubTypeInput";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";

type DefaultValues = {
  name?: PoolCreateDto["name"];
  structureType?: PoolCreateDto["structureType"];
  itemType?: PoolCreateDto["itemType"];
  itemEntityType?: PoolCreateDto["itemEntityType"];
  itemEntityTypes?: PoolCreateDto["itemEntityTypes"];
  itemEntitySubType?: PoolCreateDto["itemEntitySubType"];
};

export interface PoolCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<PoolDto, DefaultValues> {
  poolId?: string;
}

export type PoolCreateUpdateProps = PoolCreateUpdateOwnProps;

export default function PoolCreateUpdate({
  poolId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: PoolCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const isCreate = !poolId;
  const isEdit = !!poolId;

  const poolRequest = useApiRequest(
    apiClient.poolsApi.apiV1PoolsPoolIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      poolId: poolId!,
    },
    {
      skip: !poolId,
    },
  );
  const pool = poolRequest?.data;

  const { departments, locations } = useUserAffiliation();

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: pool && {
      idValue: pool.id!,
      newTitle: pool.localNumber || "",
    },
  });

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.Pool}
      entityId={poolId}
      entity={pool}
      entityRequest={poolRequest}
    >
      <Formik<PoolCreateDto & PoolUpdateDto & BaseFormikValues>
        enableReinitialize={!isCreate}
        initialValues={{
          name: defaultValues?.name || pool?.name || undefined,
          description: pool?.description || undefined,
          structureType:
            defaultValues?.structureType || pool?.structureType || PoolStructureType.Uniform,
          itemType: defaultValues?.itemType || pool?.itemType || PoolItemType.Specific,
          itemEntityType: defaultValues?.itemEntityType || pool?.itemEntityType || undefined,
          itemEntityTypes: defaultValues?.itemEntityTypes || pool?.itemEntityTypes || undefined,
          itemEntitySubType:
            defaultValues?.itemEntitySubType || pool?.itemEntitySubType || undefined,
          settings: pool?.settings || undefined,
          departmentId:
            pool?.departmentIds?.at(0) ||
            (departments && departments[0] && departments[0].id) ||
            undefined,
          locationId:
            pool?.locationIds?.at(0) || (locations && locations[0] && locations[0].id) || undefined,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // vehicleId: Yup.string().required("Vehicle is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            if (isCreate) {
              const response = await apiClient.poolsApi.apiV1PoolsPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                poolCreateDto: {
                  ...values,
                },
              });
              enqueueSnackbar("Pool created.", { variant: "success" });
              onCreate && onCreate(response.data);
              onSave && onSave(response.data);
            } else {
              const response = await apiClient.poolsApi.apiV1PoolsPoolIdPut({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                poolId,
                poolUpdateDto: {
                  ...values,
                },
              });
              enqueueSnackbar("Pool updated.", { variant: "success" });
              onUpdate && onUpdate(response.data);
              onSave && onSave(response.data);
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Box>
                  <FormControl margin='dense' fullWidth>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      required
                      fullWidth
                      helperText={touched.name && errors.name}
                      label='Name'
                      margin='dense'
                      name='name'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='text'
                      value={values.name || ""}
                      variant='outlined'
                    />
                  </FormControl>

                  <FormControl margin='dense' fullWidth>
                    <AppTextArea
                      error={Boolean(touched.description && errors.description)}
                      fullWidth
                      helperText={touched.description && errors.description}
                      mode='description'
                      margin='dense'
                      name='description'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description || ""}
                      variant='outlined'
                    />
                  </FormControl>

                  {/* Entity affiliation */}
                  <EntityAffiliationInput
                    department={{
                      departmentId: values.departmentId,
                      onChange: (d) => {
                        setFieldValue("departmentId", d?.id);
                        setFieldValue("locationId", undefined);
                      },
                      error: errors.departmentId,
                    }}
                    location={{
                      locationId: values.locationId,
                      onChange: (l) => {
                        setFieldValue("locationId", l?.id);
                      },
                      searchFilters: { departmentId: values.departmentId },
                      createUpdateProps: { defaultValues: { departmentId: values.departmentId } },
                      error: errors.locationId,
                      disabled: !values.departmentId,
                    }}
                  />

                  <FormControl margin='dense' fullWidth>
                    <ApiEnumAutocomplete
                      type='PoolStructureType'
                      value={values.structureType}
                      onChange={(newValue) => {
                        setFieldValue("structureType", newValue);
                        setFieldValue("itemEntityType", undefined);
                        setFieldValue("itemEntityTypes", undefined);
                        setFieldValue("itemEntitySubType", undefined);
                      }}
                      label='Structure type'
                      required
                      disabled={isEdit}
                      textFieldProps={{
                        error: Boolean(errors.structureType),
                        helperText: ValidationHelper.getErrorsAsString(errors.structureType),
                      }}
                    />
                  </FormControl>

                  <FormControl margin='dense' fullWidth>
                    <ApiEnumAutocomplete
                      type='PoolItemType'
                      value={values.itemType}
                      onChange={(newValue) => setFieldValue("itemType", newValue)}
                      label='Item type'
                      required
                      disabled={isEdit}
                      textFieldProps={{
                        error: Boolean(errors.itemType),
                        helperText: ValidationHelper.getErrorsAsString(errors.itemType),
                      }}
                    />
                  </FormControl>

                  {/* Uniform - select itemEntityType */}
                  {values.structureType === PoolStructureType.Uniform && (
                    <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: 2 }}>
                      <FormControl margin='dense' fullWidth>
                        <ApiEnumAutocomplete
                          type='PoolItemEntityType'
                          value={values.itemEntityType}
                          onChange={(newValue) => {
                            setFieldValue("itemEntityType", newValue);
                            setFieldValue("itemEntityTypes", undefined);
                            setFieldValue("itemEntitySubType", undefined);
                          }}
                          label='Item entity type'
                          required={values.structureType === PoolStructureType.Uniform}
                          disabled={isEdit || values.structureType !== PoolStructureType.Uniform}
                          textFieldProps={{
                            error: Boolean(errors.itemEntityType),
                            helperText: ValidationHelper.getErrorsAsString(errors.itemEntityType),
                          }}
                        />
                      </FormControl>

                      {values.itemEntityType && (
                        <FormControl margin='dense' fullWidth>
                          <GeneralEntitySubTypeInput
                            entityType={values.itemEntityType || EntityType.None}
                            value={values.itemEntitySubType}
                            isIgnoreIfSubTypeIsMissing
                            onChange={(newValue) => {
                              setFieldValue(`itemEntitySubType`, newValue);
                            }}
                            autocompleteProps={{
                              disabled:
                                _.isNil(values.itemEntityType) ||
                                (isEdit && !_.isNil(values.itemEntitySubType)),
                              textFieldProps: {
                                error: Boolean(errors.itemEntitySubType),
                                helperText: ValidationHelper.getFormikErrorsAsString(
                                  errors.itemEntitySubType,
                                ),
                              },
                            }}
                          />
                        </FormControl>
                      )}
                    </Box>
                  )}

                  {/* Diverse - select itemEntityTypes */}
                  {values.structureType === PoolStructureType.Diverse && (
                    <FormControl margin='dense' fullWidth>
                      <ApiEnumsAutocomplete
                        type='PoolItemEntityType'
                        values={values.itemEntityTypes}
                        onChange={(newValues) => {
                          setFieldValue("itemEntityType", undefined);
                          setFieldValue("itemEntityTypes", newValues);
                          setFieldValue("itemEntitySubType", undefined);
                        }}
                        label='Item entity types'
                        required={values.structureType === PoolStructureType.Diverse}
                        disabled={isEdit || values.structureType !== PoolStructureType.Diverse}
                        textFieldProps={{
                          error: Boolean(errors.itemEntityTypes),
                          helperText: ValidationHelper.getErrorsAsString(errors.itemEntityTypes),
                        }}
                      />
                    </FormControl>
                  )}

                  {/* Settings */}
                  <FormControl margin='dense' fullWidth>
                    <Stack spacing={1}>
                      <FormLabel required={false}>
                        <Typography component='span' variant='subtitle1'>
                          Settings
                        </Typography>
                      </FormLabel>

                      <Stack direction={{ xxs: "column", md: "row" }} spacing={{ xxs: 0, md: 1 }}>
                        <Box sx={{ flex: 1 }}>
                          <TextField
                            error={Boolean(getIn(errors, "settings.minItems"))}
                            helperText={getIn(errors, "settings.minItems")}
                            required={false}
                            fullWidth
                            label='Min items'
                            margin='dense'
                            name='settings.minItems'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputMode='numeric'
                            value={values.settings?.minItems ?? ""}
                            variant='outlined'
                          />
                        </Box>

                        <Box sx={{ flex: 1 }}>
                          <TextField
                            error={Boolean(getIn(errors, "settings.maxItems"))}
                            helperText={getIn(errors, "settings.maxItems")}
                            required={false}
                            fullWidth
                            label='Max items'
                            margin='dense'
                            name='settings.maxItems'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputMode='numeric'
                            value={values.settings?.maxItems ?? ""}
                            variant='outlined'
                          />
                        </Box>
                      </Stack>

                      <FormControl margin='dense'>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.settings?.isEnsureEntityBelongToSinglePool || false}
                              onBlur={handleBlur}
                              onChange={(e) =>
                                setFieldValue(
                                  "settings.isEnsureEntityBelongToSinglePool",
                                  e.target.checked,
                                )
                              }
                            />
                          }
                          label='Ensure entity belongs to a single pool'
                        />
                        <FormHelperText>
                          When checked, ensures that single entity, for instance, Vehicle or Asset,
                          can be added max to one pool.
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                  </FormControl>
                </Box>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <FormActions>
                  <Button
                    color='primary'
                    loading={isSubmitting}
                    fullWidth
                    type='submit'
                    variant='contained'
                  >
                    Save
                  </Button>
                </FormActions>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
