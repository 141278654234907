import { ReactNode } from "react";

import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission } from "@/core/api/generated";

import NoPermissionsAlert from "./NoPermissionsAlert";

export interface AuthorizedElementProps {
  /** List of permissions user must have (all) */
  permissions?: AppPermission[];
  /** List of permissions user must have (at least one from the list) */
  permissionsAny?: AppPermission[];
  /** Show alert if user doesn't have required permissions. */
  withAlert?: boolean;
  // children: JsxElement | ReactElement | React.ComponentType | ReactNode;
  children: ReactNode;
}

/** Renders children only if user has required permissions */
export default function AuthorizedElement({
  permissions,
  permissionsAny,
  withAlert,
  children,
}: AuthorizedElementProps): ReactNode {
  const authorizationInfo = useAuthorizationInfo();

  const hasPermissions = authorizationInfo.hasPermissionsAllAndAny({
    permissions,
    permissionsAny,
  });

  if (!hasPermissions) {
    if (withAlert) {
      return <NoPermissionsAlert sx={{ my: 1 }} />;
    }
    return null;
  }

  return children;
}
