import AppIcon from "@/common/components/Icons/AppIcon";
import { StringHelper } from "@/common/helpers/string";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { enumService } from "@/common/services/enum";
import { apiClient } from "@/core/api/ApiClient";
import { RepairSpecDto, RepairSpecReferenceDto } from "@/core/api/generated";

import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getDetalizationInlineText } from "./RepairSpecDetalizationInline";

export function getRepairSpecInlineText(data: RepairSpecDto | RepairSpecReferenceDto) {
  const full = data as RepairSpecDto;

  return StringHelper.joinIntoString(
    [
      full.name,
      full.partType && full.damageType && `${full.partType?.name} - ${full.damageType?.name}`,
      full.detalization && getDetalizationInlineText(full.detalization),
      `${enumService.getEnumValueName("RepairType", full.repairType)} repair`,
    ],
    ", ",
    {
      skipEmpty: true,
    },
  );
}

export interface RepairSpecInlineProps extends BaseEntityInlineInheritableProps {
  entity: RepairSpecDto | RepairSpecReferenceDto | null | undefined;
  entityId?: string | null;
}

/** Inline brief information about the entity. */
export default function RepairSpecInline({
  entity,
  entityId,
  ...otherProps
}: RepairSpecInlineProps) {
  const request = useApiRequest(
    apiClient.repairSpecsApi.apiV1RepairSpecsRepairSpecIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      repairSpecId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  // const full = entity as RepairSpecDto;
  // const ref = entity as RepairSpecReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='repairSpec' inText />}
      content={entity && getRepairSpecInlineText(entity)}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Repair spec"}
    />
  );
}
