import { Stack, TypographyProps } from "@mui/material";
import { useHistory } from "react-router";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import { DataUpdatesHubClientMethodName, EntityType } from "@/core/api/generated";
import { TeslaConnectionDto } from "@/core/api/generated/v0.1-demo";

import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import TeslaConnectionMenu from "../TeslaConnectionMenu";
import OverviewTabContent from "./Tabs/OverviewTabContent";
import VehiclesTabContent from "./Tabs/VehiclesTabContent";

export enum TeslaConnectionViewPageTabs {
  Overview = "Overview",
  Vehicles = "Vehicles",
}

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

interface OwnProps extends BaseEntityViewInheritableProps<TeslaConnectionDto> {
  teslaConnectionId: string | null | undefined;
  teslaConnection: TeslaConnectionDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps;

export default function TeslaConnectionView({
  teslaConnectionId,
  teslaConnection,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const currentTenant = useCurrentTenant();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.TeslaConnection),
      viewVariant: displayProps.viewVariant,
    },
  });

  const history = useHistory();

  const teslaConnectionRequest = useApiRequest(
    apiClient.teslaConnectionsApi.apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      teslaConnectionId: teslaConnectionId!,
    },
    {
      deps: [teslaConnectionId],
      skip: !teslaConnectionId || !!teslaConnection,
    },
  );
  teslaConnection = teslaConnection || teslaConnectionRequest?.data;

  useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(
        currentTenant?.id,
        EntityType.TeslaConnection,
        teslaConnectionId || "",
      ),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      teslaConnectionRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        teslaConnection && {
          idValue: teslaConnection.id!,
          newTitle: teslaConnection.userEmail || "",
        }) ||
      undefined,
  });

  return (
    <BaseEntityView
      entityType={EntityType.TeslaConnection}
      entityId={teslaConnectionId}
      entity={teslaConnection}
      entityRequest={teslaConnectionRequest}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          displayProps?.header && (
            <>
              <DetailedViewPageHeader
                image={undefined}
                title={`Tesla connection `}
                titleProps={{
                  to: headerProps?.withLink
                    ? ROUTE_PATH.MANAGEMENT_INTEGRATION_TESLA_CONNECTION_VIEW(teslaConnection?.id)
                    : undefined,
                  typographyProps: headerProps?.typographyProps,
                }}
                secondaryActions={
                  displayProps.actions &&
                  teslaConnection && (
                    <TeslaConnectionMenu
                      onDelete={() => history.goBack()}
                      onUpdate={(newValue) =>
                        newValue
                          ? teslaConnectionRequest.replaceData(newValue)
                          : teslaConnectionRequest.refetch()
                      }
                      entity={teslaConnection}
                    />
                  )
                }
              />
            </>
          )
        }
      >
        <Stack spacing={2}>
          <PageTabs
            tabIdsDefinition={TeslaConnectionViewPageTabs}
            defaultTabId={TeslaConnectionViewPageTabs.Overview}
            viewVariant={displayProps?.viewVariant}
            commonViewParams={commonViewParams}
            tabs={[
              { label: "Overview", value: TeslaConnectionViewPageTabs.Overview, isHideable: false },
              { label: "Tesla account vehicles", value: TeslaConnectionViewPageTabs.Vehicles },
            ]}
          >
            {({ activeTabId: activeTab }) =>
              teslaConnection && (
                <>
                  {activeTab === TeslaConnectionViewPageTabs.Overview && (
                    <OverviewTabContent
                      teslaConnection={teslaConnection}
                      onUpdate={(newValue) =>
                        newValue
                          ? teslaConnectionRequest.replaceData(newValue)
                          : teslaConnectionRequest.refetch()
                      }
                    />
                  )}
                  {activeTab === TeslaConnectionViewPageTabs.Vehicles && (
                    <VehiclesTabContent teslaConnection={teslaConnection} />
                  )}
                </>
              )
            }
          </PageTabs>
        </Stack>
      </ViewLayout>
    </BaseEntityView>
  );
}
