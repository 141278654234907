import { Box, Button, Popover, Stack, Typography } from "@mui/material";
import { useState } from "react";

import AppLink from "@/common/components/Link/AppLink";
import AppPopover, { AppPopoverHoverBehaviorProps } from "@/common/components/Popover/AppPopover";
import AppPopoverContent from "@/common/components/Popover/AppPopoverContent";

export default function DevTooltips() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [anchorEl2, setAnchorEl2] = useState<HTMLElement | null>(null);
  const [anchorEl3, setAnchorEl3] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  return (
    <Stack spacing={4}>
      <Typography component='div' variant='h1'>
        Popovers
      </Typography>

      <Stack spacing={2}>
        <Typography component='div' variant='h2'>
          MUI Popover
        </Typography>
        <Stack spacing={2}>
          <AppLink to='https://mui.com/material-ui/react-popover'>MUI docs</AppLink>

          <Stack spacing={2}>
            <Typography component='div' variant='h3'>
              Basic Popover
            </Typography>

            <Box>
              <Button variant='contained' onClick={(e) => setAnchorEl(e.currentTarget)}>
                Open Popover
              </Button>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
              </Popover>
            </Box>
          </Stack>

          <Stack spacing={2}>
            <Typography component='div' variant='h3'>
              Mouse over interaction
            </Typography>

            <Box>
              <Button
                variant='contained'
                onMouseEnter={(e) => setAnchorEl2(e.currentTarget)}
                onMouseLeave={() => setAnchorEl2(null)}
              >
                Open Popover
              </Button>
              <Popover
                sx={{
                  pointerEvents: "none",
                }}
                open={open2}
                anchorEl={anchorEl2}
                onClose={() => setAnchorEl2(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
              </Popover>
            </Box>
          </Stack>
        </Stack>
      </Stack>

      <Stack spacing={2}>
        <Typography component='div' variant='h2'>
          AppPopover (based on MUI Popover)
        </Typography>

        <Stack spacing={2}>
          <Typography component='div' variant='h3'>
            Behaviors
          </Typography>

          <Stack spacing={2}>
            <Typography component='div' variant='h4'>
              Click
            </Typography>

            <Box>
              <AppPopover
                clickBehavior={{}}
                trigger={
                  <Button variant='contained' color='primary' size='medium'>
                    Open Popover
                  </Button>
                }
              >
                <AppPopoverContent>
                  <Box>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi inventore,
                    provident sed reiciendis, ratione nostrum, molestias doloribus eaque fugit
                    eveniet dicta! Impedit distinctio magnam numquam cupiditate provident
                    voluptatibus adipisci ducimus!
                  </Box>
                </AppPopoverContent>
              </AppPopover>
            </Box>
          </Stack>

          <Stack spacing={2}>
            <Typography component='div' variant='h4'>
              Hover
            </Typography>

            {(
              [
                "onTriggerMouseLeave",
                "onContentMouseLeave",
                "onTriggerOrContentMouseLeave",
              ] as Array<AppPopoverHoverBehaviorProps["closeBehavior"]>
            ).map((closeBehavior, i) => (
              <Stack key={i} spacing={2}>
                <Typography component='div' variant='h6'>
                  closeBehavior={closeBehavior}
                </Typography>

                <Box>
                  <AppPopover
                    hoverBehavior={{
                      closeBehavior: closeBehavior,
                    }}
                    trigger={
                      <Button variant='contained' color='primary' size='medium'>
                        Open Popover
                      </Button>
                    }
                  >
                    <AppPopoverContent sx={{ width: 400 }}>
                      <Box>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi inventore,
                        provident sed reiciendis, ratione nostrum, molestias doloribus eaque fugit
                        eveniet dicta! Impedit distinctio magnam numquam cupiditate provident
                        voluptatibus adipisci ducimus!
                      </Box>
                    </AppPopoverContent>
                  </AppPopover>
                </Box>
              </Stack>
            ))}
          </Stack>

          <Stack spacing={2}>
            <Typography component='div' variant='h4'>
              Test edge cases
            </Typography>

            <Stack spacing={2}>
              <Typography component='div' variant='h6'>
                When trigger parent has hover background effects, on trigger hover parent background
                starts blinking if AppPopover does not handle pointer events correctly.
              </Typography>

              <Box
                sx={{
                  p: 5,
                  background: "orange",
                  "&:hover": {
                    background: "red",
                  },
                }}
              >
                <AppPopover
                  enabled
                  trigger={<Box>Trigger (AppPopover)</Box>}
                  hoverBehavior={{}}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: -10, // show popover at some distance from the anchor
                    horizontal: "left",
                  }}
                  disableRestoreFocus
                  contentSx={{
                    maxWidth: 400,
                  }}
                >
                  <AppPopoverContent>Cccccccc cccccccccccc</AppPopoverContent>
                </AppPopover>

                <Typography
                  sx={{ mt: 2 }}
                  onMouseEnter={(event: React.MouseEvent<HTMLElement>) => {
                    setAnchorEl3(event.currentTarget);
                  }}
                  onMouseLeave={() => {
                    setAnchorEl3(null);
                  }}
                >
                  Trigger (Popover)
                </Typography>
                <Popover
                  sx={{
                    pointerEvents: "none",
                  }}
                  open={Boolean(anchorEl3)}
                  anchorEl={anchorEl3}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  onClose={() => {
                    setAnchorEl3(null);
                  }}
                  disableRestoreFocus
                >
                  <Typography sx={{ p: 1 }}>Lorea sasd as das </Typography>
                </Popover>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
