import { useParams } from "react-router";

import AssetSubscriptionPlanView from "@/common/components/Entity/AssetSubscriptionPlan/View/AssetSubscriptionPlanView";

export default function AssetSubscriptionPlanViewPage() {
  const { assetSubscriptionPlanId } = useParams<{ assetSubscriptionPlanId?: string }>();

  return (
    <>
      <AssetSubscriptionPlanView
        assetSubscriptionPlanId={assetSubscriptionPlanId}
        assetSubscriptionPlan={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
