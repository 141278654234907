import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function EditIcon(props: SvgIconProps, ref) {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <path d='M6.97987 20.8004V22.3064C6.97987 23.1485 6.29971 23.8124 5.47387 23.8124C4.64803 23.8124 3.96787 23.1485 3.96787 22.3064V20.8004H2.44556V15.8774C2.44556 14.7925 3.17428 13.9017 4.16212 13.6265L5.92724 9.4322C6.18637 8.81683 6.78553 8.37958 7.49803 8.37958H16.5181C17.2306 8.37958 17.8298 8.81683 18.0889 9.4322L19.8379 13.6265C20.8257 13.9018 21.5544 14.7925 21.5544 15.8774V20.8004H20.016V22.3064C20.016 23.1485 19.3358 23.8124 18.51 23.8124C17.6679 23.8124 17.004 23.1485 17.004 22.3064V20.8004H6.97987ZM5.57099 18.096C6.41306 18.096 7.09321 17.4159 7.09321 16.5738C7.09321 15.7317 6.41306 15.0516 5.57099 15.0516C4.72893 15.0516 4.04878 15.7317 4.04878 16.5738C4.04878 17.4159 4.72884 18.096 5.57099 18.096ZM19.8541 16.5738C19.8541 15.7317 19.1739 15.0516 18.3319 15.0516C17.4898 15.0516 16.8097 15.7317 16.8097 16.5738C16.8097 17.4159 17.4898 18.096 18.3319 18.096C19.1739 18.096 19.8541 17.4159 19.8541 16.5738ZM18.3319 13.5455L16.8259 9.91801C16.7611 9.73989 16.583 9.61033 16.3724 9.61033H7.62759C7.43324 9.61033 7.25512 9.73989 7.19034 9.91801L5.68434 13.5455H18.3319Z' />
      <path d='M12.4429 3.11013H11.9986V2.66583C11.9986 2.54799 11.9518 2.43498 11.8685 2.35166C11.7852 2.26834 11.6722 2.22153 11.5543 2.22153C11.4365 2.22153 11.3235 2.26834 11.2402 2.35166C11.1568 2.43498 11.11 2.54799 11.11 2.66583V3.11013H10.6657C10.5479 3.11013 10.4349 3.15694 10.3516 3.24026C10.2682 3.32359 10.2214 3.43659 10.2214 3.55443C10.2214 3.67227 10.2682 3.78528 10.3516 3.8686C10.4349 3.95192 10.5479 3.99873 10.6657 3.99873H11.11V4.44303C11.11 4.56087 11.1568 4.67388 11.2402 4.7572C11.3235 4.84052 11.4365 4.88733 11.5543 4.88733C11.6722 4.88733 11.7852 4.84052 11.8685 4.7572C11.9518 4.67388 11.9986 4.56087 11.9986 4.44303V3.99873H12.4429C12.5608 3.99873 12.6738 3.95192 12.7571 3.8686C12.8404 3.78528 12.8872 3.67227 12.8872 3.55443C12.8872 3.43659 12.8404 3.32359 12.7571 3.24026C12.6738 3.15694 12.5608 3.11013 12.4429 3.11013Z' />
      <path d='M15.8686 7.23767L14.358 5.73149C14.8453 5.11056 15.1098 4.34378 15.1088 3.55441C15.1088 2.85142 14.9004 2.16421 14.5098 1.57969C14.1192 0.995167 13.5641 0.53959 12.9146 0.270565C12.2651 0.00154006 11.5505 -0.0688491 10.861 0.0682986C10.1715 0.205446 9.53816 0.543971 9.04106 1.04106C8.54397 1.53816 8.20545 2.17149 8.0683 2.86098C7.93115 3.55047 8.00154 4.26514 8.27056 4.91463C8.53959 5.56411 8.99517 6.11923 9.57969 6.50979C10.1642 6.90036 10.8514 7.10882 11.5544 7.10882C12.3438 7.1098 13.1106 6.84534 13.7315 6.35795L15.2377 7.86858C15.279 7.91022 15.3281 7.94327 15.3823 7.96583C15.4364 7.98839 15.4945 8 15.5531 8C15.6118 8 15.6698 7.98839 15.724 7.96583C15.7781 7.94327 15.8273 7.91022 15.8686 7.86858C15.9102 7.82727 15.9433 7.77813 15.9658 7.72399C15.9884 7.66985 16 7.61178 16 7.55312C16 7.49447 15.9884 7.4364 15.9658 7.38225C15.9433 7.32811 15.9102 7.27897 15.8686 7.23767ZM8.8886 3.55441C8.8886 3.02716 9.04495 2.51176 9.33787 2.07337C9.6308 1.63498 10.0471 1.2933 10.5343 1.09153C11.0214 0.889758 11.5574 0.836966 12.0745 0.939827C12.5916 1.04269 13.0666 1.29658 13.4394 1.6694C13.8122 2.04222 14.0661 2.51722 14.169 3.03434C14.2719 3.55145 14.2191 4.08746 14.0173 4.57457C13.8155 5.06168 13.4738 5.47803 13.0355 5.77095C12.5971 6.06387 12.0817 6.22022 11.5544 6.22022C10.8474 6.22022 10.1693 5.93936 9.6694 5.43942C9.16947 4.93949 8.8886 4.26143 8.8886 3.55441Z' />
    </SvgIcon>
  );
});
