import { Box } from "@mui/material";

import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission, DocumentDto, DocumentReferenceDto } from "@/core/api/generated";

import { AppEntityLinkProps } from "../../Link/types";

interface Props extends AppEntityLinkProps {
  document?: DocumentDto | DocumentReferenceDto;
}

export default function DocumentLink({
  document,
  withIcon = true,
  withTooltip = true,
  ...otherProps
}: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  return (
    <AppLink
      enabled={hasPermissions([AppPermission.DocumentRead])}
      to={ROUTE_PATH.DOCUMENT_VIEW(document?.id || "")}
      tooltipTitle='Document'
      withTooltip={withTooltip}
      {...otherProps}
    >
      {withIcon && <AppIcon of='document' fontSize='inherit' color='inherit' sx={{ mr: 1 }} />}
      <Box component='span'>{document?.localNumber}</Box>
    </AppLink>
  );
}
