import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import CreateUpdateRepairWork, { CreateUpdateRepairWorkProps } from "./CreateUpdateRepairWork";

export interface OwnProps {
  createUpdateRepairWorkProps: CreateUpdateRepairWorkProps;
}

type Props = OwnProps & DialogProps;

export default function CreateUpdateRepairWorkModal({
  createUpdateRepairWorkProps,
  ...dialogProps
}: Props) {
  const isCreate = !createUpdateRepairWorkProps.repairWorkId;

  return (
    <Dialog fullWidth maxWidth='lg' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        {isCreate ? "Create new" : "Edit"} repair work
      </AppModalTitle>
      <DialogContent>
        <Box sx={{ py: 1 }}>
          <CreateUpdateRepairWork {...createUpdateRepairWorkProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
