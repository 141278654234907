import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import { useState } from "react";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AppIconButton from "@/common/components/Button/AppIconButton";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FileListView from "@/common/components/Files/FileListView";
import InfoTile from "@/common/components/Form/Display/InfoTile";
import GeneralStageHistory from "@/common/components/GeneralStageHistory";
import AppIcon from "@/common/components/Icons/AppIcon";
import BaseEditNotesModal from "@/common/components/Modals/common/BaseEditNotesModal";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import { FileItem } from "@/common/fileItem";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { AppPermission, EntityType, MaintenanceDto } from "@/core/api/generated";

import DepartmentLink from "../../../Department/DepartmentLink";
import AffiliationInfoDisplay from "../../../EntityAffiliation/AffiliationInfoDisplay";
import UpdateEntityAffiliationCascadeModal from "../../../EntityAffiliation/UpdateEntityAffiliationCascadeModal";
import GeneralByWhoDisplay from "../../../General/Display/GeneralByWhoDisplay";
import LocationLink from "../../../Location/LocationLink";
import SupplierLink from "../../../Supplier/SupplierLink";
import VehicleLink from "../../../Vehicle/VehicleLink";
import VehicleMileageInline from "../../../Vehicle/VehicleMileageInline";

interface Props {
  maintenance: MaintenanceDto;
  onUpdate: (newValue?: MaintenanceDto) => void;
}

export default function OverviewTabContent({ maintenance, onUpdate }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();
  const { hasPermissions } = useAuthorizationInfo();

  const [isUpdateAffiliationModalOpened, setIsUpdateAffiliationModalOpened] = useState(false);
  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={maintenance} />}
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <Stack spacing={1}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              <InfoTile
                label='Number'
                iconOf='personId'
                isEmpty={_.isNil(maintenance?.localNumber)}
              >
                {maintenance?.localNumber}
              </InfoTile>

              <InfoTile label='Name' iconOf='info' isEmpty={_.isNil(maintenance?.name)}>
                {maintenance?.name}
              </InfoTile>

              <InfoTile
                label='Department'
                iconOf='department'
                isEmpty={_.isNil((maintenance?.departmentIds || [])[0])}
              >
                <DepartmentLink
                  entity={undefined}
                  entityId={(maintenance?.departmentIds || [])[0]}
                />
              </InfoTile>

              <InfoTile
                label='Location'
                iconOf='location'
                isEmpty={_.isNil((maintenance?.locationIds || [])[0])}
              >
                <LocationLink entity={undefined} entityId={(maintenance?.locationIds || [])[0]} />
              </InfoTile>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              <InfoTile label='Vehicle' iconOf='vehicle' isEmpty={_.isNil(maintenance?.vehicle)}>
                <VehicleLink entity={maintenance?.vehicle} />
              </InfoTile>

              <InfoTile label='Supplier' iconOf='supplier' isEmpty={_.isNil(maintenance?.supplier)}>
                <SupplierLink entity={maintenance?.supplier} />
              </InfoTile>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              <InfoTile
                label='Schedule type'
                iconOf='maintenanceScheduleType'
                isEmpty={_.isNil(maintenance?.scheduleType)}
              >
                <InlineApiEnumValue
                  type='MaintenanceScheduleType'
                  value={maintenance.scheduleType}
                />
              </InfoTile>

              <InfoTile
                label='Scheduled At'
                iconOf='date'
                isEmpty={_.isNil(maintenance?.scheduledAt)}
              >
                <Datetime datetime={maintenance?.scheduledAt} />
              </InfoTile>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              <InfoTile
                label='Responsible user'
                iconOf='inspector'
                isEmpty={_.isNil(maintenance?.responsibleUser)}
              >
                <GeneralByWhoDisplay who={maintenance.responsibleUser} />
              </InfoTile>

              <InfoTile label='Notes' iconOf='notes'>
                {maintenance.notes ? maintenance.notes : "-"}{" "}
                <BaseEditNotesModal
                  initialValues={{ notes: maintenance?.notes }}
                  onConfirm={async (notes) => {
                    await apiClient.maintenancesApi.apiV1MaintenancesMaintenanceIdNotesPut({
                      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                      maintenanceId: maintenance.id || "",
                      generalNotesInputDto: { notes },
                    });
                    onUpdate();
                  }}
                />
              </InfoTile>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              <InfoTile label='Mileage' iconOf='gauge' isEmpty={_.isNil(maintenance?.mileage)}>
                <VehicleMileageInline value={maintenance.mileage} />
              </InfoTile>

              <InfoTile label='Fuel level' iconOf='fuel' isEmpty={_.isNil(maintenance?.fuelLevel)}>
                {maintenance?.fuelLevel}
              </InfoTile>
            </Box>
          </Stack>

          {/* Stages */}
          <Box>
            <Typography variant='h2'>Stages</Typography>
            <GeneralStageHistory
              stageEnumType='MaintenanceStage'
              stageHistory={maintenance.stageHistory}
              withUpdateButtons={hasPermissions([AppPermission.MaintenanceManage])}
              onUpdateStage={async (newStage) => {
                try {
                  const response =
                    await apiClient.maintenancesApi.apiV1MaintenancesMaintenanceIdStagePut({
                      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                      maintenanceId: maintenance.id || "",
                      generalStageUpdateDtoOfMaintenanceStage: {
                        newStage: newStage,
                      },
                    });
                  onUpdate(response.data);
                } catch (err) {
                  const validation2 = ValidationHelper.handleApiErrorResponse(err);
                  enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
                }
              }}
            />
          </Box>

          {/* Summary */}
          <Stack direction='row' justifyContent='flex-end'>
            <GeneralPriceSummaryDisplay
              sx={{ minWidth: { xxs: "100%", md: "300px" } }}
              summary={{
                currency: maintenance.currency,
                subTotal: maintenance.subTotal,
                subTotalIncDiscount: maintenance.subTotalIncDiscount,
                discount: maintenance.discount,
                tax: maintenance.tax,
                total: maintenance.total,
              }}
            />
          </Stack>

          {/* Affiliation */}
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography variant='subtitle1'>
              Affiliation{" "}
              <AppIconButton
                tooltipProps={{ title: "Edit entity affiliation" }}
                onClick={() => setIsUpdateAffiliationModalOpened(true)}
              >
                <AppIcon of='edit' fontSize='small' />
              </AppIconButton>
            </Typography>
            <AffiliationInfoDisplay
              tenantId={maintenance.tenantId}
              departmentIds={maintenance.departmentIds}
              locationIds={maintenance.locationIds}
              sx={{ mt: 1 }}
            />
            <UpdateEntityAffiliationCascadeModal
              open={isUpdateAffiliationModalOpened}
              onClose={() => setIsUpdateAffiliationModalOpened(false)}
              entityType={EntityType.Maintenance}
              entityId={maintenance.id}
            />
          </Stack>

          {!_.isEmpty(maintenance?.attachments) && (
            <Box>
              <FileListView
                files={FileItem.createManyFrom(maintenance?.attachments)}
                maxVisibleFiles={10}
                itemWidth={45}
              />
            </Box>
          )}
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}
