import { Box, BoxProps } from "@mui/material";
import _, { DebounceSettings } from "lodash";
import { useCallback, useEffect, useRef } from "react";

import { useValueMemoWithDeepCompare } from "../hooks/memo/useValueMemoWithDeepCompare";
import { useInViewport } from "../hooks/viewport/useInViewport";

// v1: we used https://www.npmjs.com/package/react-visibility-sensor but it generates this error:
// Warning: findDOMNode is deprecated and will be removed in the next major release.
// Instead, add a ref directly to the element you want to reference. Learn more about using refs safely here: https://reactjs.org/link/strict-mode-find-node
//
// v2: use custom useInViewport based on sources of Motion useInView https://motion.dev/docs/react-use-in-view
//
// All options:
// - https://www.npmjs.com/package/react-visibility-sensor
// - https://www.npmjs.com/package/react-in-viewport
// - https://motion.dev/docs/react-use-in-view

const defaultObservableProps: BoxProps = {
  sx: {
    width: "1px",
    height: "1px",
    visibility: "hidden",
    background: "none",
    color: "transparent",
  },
};

interface Props {
  debounceParams?: {
    waitMs?: number;
    options?: DebounceSettings;
    /** Do not call on change callback with isVisible=true if element was visible. but then got invisible after waitMs. */
    isCancelWhenInvisible?: boolean;
  };
  onChange?: (isVisible: boolean) => void;
  /** Observable element (of which visibility is observed).
   *  Must be non empty element with non zero width and height.
   *  Use render function as children when you want to use default empty observable element. */
  children?: React.ReactNode | ((params: { observableProps: BoxProps }) => React.ReactNode);
}

/** Facade for custom or lib implementation.
 *  Sensor component that notifies when it goes in or out of the window viewport.
 *  https://www.npmjs.com/package/react-visibility-sensor
 */
export default function AppVisibilitySensor({ debounceParams, onChange, children }: Props) {
  const debounceParamsMemoized = useValueMemoWithDeepCompare(debounceParams);
  const isDebounced = !!debounceParamsMemoized;

  const isVisibleRef = useRef(false);
  const observableRef = useRef<HTMLDivElement>(null);
  const isInViewport = useInViewport(observableRef, {
    amount: "some",
  });

  const onChangeDebounced = useCallback(
    _.debounce(
      (isVisible: boolean) => {
        if (isVisible && !isVisibleRef.current && debounceParamsMemoized?.isCancelWhenInvisible) {
          onChange && onChange(isVisibleRef.current);
          return;
        }
        onChange && onChange(isVisible);
      },
      debounceParamsMemoized?.waitMs,
      debounceParamsMemoized?.options,
    ),
    [debounceParamsMemoized, onChange],
  );

  const handleChange = useCallback(
    (isVisible: boolean) => {
      isVisibleRef.current = isVisible;

      if (isDebounced) {
        onChangeDebounced(isVisible);
      } else {
        onChange && onChange(isVisible);
      }
    },
    [isDebounced, onChange],
  );

  useEffect(() => {
    // console.log("Element is in view: ", isInViewport, observableRef.current);
    handleChange(isInViewport);
  }, [isInViewport, handleChange]);

  return (
    <Box ref={observableRef}>
      {(children && !_.isFunction(children) && children) ||
        (children &&
          _.isFunction(children) &&
          children({ observableProps: defaultObservableProps }))}
    </Box>
  );
}
