import { Grid2 } from "@mui/material";

import { VehiclePriceDto } from "@/core/api/generated";

import InfoTile from "../../Form/Display/InfoTile";
import VehiclePriceDisplay from "./VehiclePriceDisplay";

interface Props {
  make: string | null | undefined;
  model: string | null | undefined;
  plateNo: string | null | undefined;
  purchasePrice: VehiclePriceDto | null | undefined;
}

export default function VehicleShortSpecDisplay({ make, model, plateNo, purchasePrice }: Props) {
  return (
    <Grid2 container spacing={2}>
      <Grid2 size={{ xs: 6 }}>
        <InfoTile label='Plate No' iconOf='plateNo' isEmpty={!plateNo}>
          {plateNo}
        </InfoTile>
      </Grid2>
      <Grid2 size={{ xs: 6, md: 6 }}>
        <InfoTile label='Make' iconOf='vehicleMake' isEmpty={!make}>
          {make}
        </InfoTile>
      </Grid2>
      <Grid2 size={{ xs: 6 }}>
        <InfoTile label='Model' iconOf='vehicleModel' isEmpty={!model}>
          {model}
        </InfoTile>
      </Grid2>

      <Grid2 size={{ xs: 6 }}>
        <InfoTile label='Purchase price' iconOf='money' isEmpty={!purchasePrice}>
          <VehiclePriceDisplay price={purchasePrice} />
        </InfoTile>
      </Grid2>
    </Grid2>
  );
}
