import { Alert, Box, Checkbox, DialogProps, FormControlLabel, Stack } from "@mui/material";
import _ from "lodash";
import { ReactNode, useCallback, useMemo, useState } from "react";

import { ROUTE_PATH } from "@/common/constants/routing";
import { EntityHelper } from "@/common/helpers/entity";
import { renderIf } from "@/common/helpers/render/renderIf";
import { TextHelper } from "@/common/helpers/text";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { EntityMeta } from "@/common/ts/entity";
import { ValidationHelper } from "@/common/validation";
import { DataGrantPermission, EntityType, IBaseEntityDto, TenantDto } from "@/core/api/generated";

import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import AppLink from "../../Link/AppLink";
import ConfirmationModal, { ConfirmationModalProps } from "../../Modals/ConfirmationModal";
import PoolLink from "../Pool/PoolLink";
import TenantLink from "../Tenant/TenantLink";
import TenantRequestLink from "../TenantRequest/TenantRequestLink";

interface BaseEntityDeleteFuncResult<TEntity extends IBaseEntityDto> {
  newEntity?: Nil<TEntity>;
}

export interface BaseEntityDeleteModalOwnProps<TEntity extends IBaseEntityDto> {
  entityType: EntityType;
  entity: TEntity;
  entityId: string | null | undefined;
  deleteFunc: (params: {
    entityType: EntityType;
    entityId: string;
    entity: TEntity;
    isDeleteCascade?: boolean;
  }) =>
    | void
    | Nil<BaseEntityDeleteFuncResult<TEntity>>
    | Promise<void>
    | Promise<Nil<BaseEntityDeleteFuncResult<TEntity>>>;
  title?: ReactNode;
  body?: ReactNode;
  bodyBefore?: ReactNode;
  bodyAfter?: ReactNode;
  cascadeActionDisplay?: boolean;
  onDelete?: (params: { entityType: EntityType; entityId: string; entity: TEntity }) => void;
}

export type BaseEntityDeleteModalProps<TEntity extends IBaseEntityDto> =
  BaseEntityDeleteModalOwnProps<TEntity> & ConfirmationModalProps;

/** Interface to be used by wrapper components that utilize this component. */
export interface BaseEntityDeleteModalInheritableProps<
  TEntity extends IBaseEntityDto = IBaseEntityDto,
> extends DialogProps {
  onDelete?: BaseEntityDeleteModalOwnProps<TEntity>["onDelete"];
}
interface IsCanDeleteEntityPros {
  entityMeta: EntityMeta | null;
  currentTenant?: TenantDto;
}

export const isCanDeleteEntity = ({ entityMeta, currentTenant }: IsCanDeleteEntityPros) =>
  !entityMeta?.grantsMeta ||
  entityMeta.grantsMeta.isIssuedByMe ||
  (entityMeta.grantsMeta.isConsumedByMe &&
    !!currentTenant?.id &&
    !!entityMeta.grantsMeta.tenantPermissionsMap &&
    !!entityMeta.grantsMeta.tenantPermissionsMap[currentTenant.id] &&
    !!entityMeta.grantsMeta.tenantPermissionsMap[currentTenant.id][DataGrantPermission.Delete]);

export default function BaseEntityDeleteModal<TEntity extends IBaseEntityDto = IBaseEntityDto>({
  entityType,
  entity,
  entityId,
  deleteFunc,
  title,
  body,
  bodyBefore,
  bodyAfter,
  cascadeActionDisplay = false,
  onDelete,
  ...dialogProps
}: BaseEntityDeleteModalProps<TEntity>) {
  const [isDeleteCascade, setIsDeleteCascade] = useState(false);

  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();

  const entityMeta = useMemo(() => EntityHelper.getEntityMeta(entity), [entity]);
  const canDelete = useMemo(() => isCanDeleteEntity({ entityMeta, currentTenant }), [entityMeta]);

  const handleDeleteConfirmed = useCallback(async () => {
    if (!entityId) {
      return;
    }
    try {
      const result = await deleteFunc({ entityType, entityId, entity, isDeleteCascade });
      enqueueSnackbar("Entity was successfully deleted.", { variant: "success" });
      onDelete && onDelete({ entityType, entityId, entity: result?.newEntity || entity });
      dialogProps?.onClose && dialogProps.onClose({}, "escapeKeyDown");
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [entityType, entityId, isDeleteCascade, deleteFunc]);

  return (
    <ConfirmationModal
      isConfirmEnabled={canDelete}
      title={
        title || (
          <Stack spacing={1}>
            <Box>
              Delete the{" "}
              <InlineApiEnumValue type='EntityType' value={entityType} withDescription={false} />
              {(entityMeta?.localNumber || entityMeta?.globalNumber) && (
                <>
                  {" "}
                  <strong>{entityMeta?.localNumber || entityMeta?.globalNumber}</strong>
                </>
              )}
              ?
            </Box>
          </Stack>
        )
      }
      body={
        <Stack spacing={1}>
          {bodyBefore && <Box>{bodyBefore}</Box>}

          {body || (
            <Stack spacing={1}>
              <Box component='div'>
                {`You're`} going to delete the{" "}
                <InlineApiEnumValue type='EntityType' value={entityType} withDescription={false} />
                {(entityMeta?.localNumber || entityMeta?.globalNumber) && (
                  <>
                    {" "}
                    <strong>{entityMeta?.localNumber || entityMeta?.globalNumber}</strong>
                  </>
                )}
                .{` This action can't be undone.`}
              </Box>

              {entityMeta?.grantsMeta?.isConsumedByMe && (
                <Alert severity='warning'>
                  This entity is shared to you via{" "}
                  <AppLink
                    to={ROUTE_PATH.DATA_GRANTS({
                      entityType,
                      entityId: entity?.id || entityId,
                    })}
                  >
                    data grant
                  </AppLink>{" "}
                  by{" "}
                  <TenantLink
                    entity={undefined}
                    entityId={entityMeta.grantsMeta.issuerTenantId}
                  ></TenantLink>
                  .
                </Alert>
              )}

              {renderIf()
                .if(canDelete)
                .then(
                  <>
                    {entityMeta?.grantsMeta && (
                      <Alert severity='warning'>
                        All <AppLink to={ROUTE_PATH.DATA_GRANTS()}>data grants</AppLink> for this
                        entity will be deleted.
                      </Alert>
                    )}

                    {entityMeta?.tenantRequestsMeta?.tenantRequestIds &&
                      !_.isEmpty(entityMeta?.tenantRequestsMeta?.tenantRequestIds) && (
                        <Alert severity='warning'>
                          <Box>
                            This entity is linked to{" "}
                            {entityMeta?.tenantRequestsMeta?.tenantRequestIds.length} tenant{" "}
                            {TextHelper.pluralizeManual(
                              "request",
                              entityMeta?.tenantRequestsMeta?.tenantRequestIds.length,
                              "requests",
                            )}{" "}
                            (deletion may be forbidden).
                          </Box>
                          <Stack>
                            {entityMeta.tenantRequestsMeta.tenantRequestIds.map((id, i) => (
                              <TenantRequestLink key={i} entity={undefined} entityId={id} />
                            ))}
                          </Stack>
                        </Alert>
                      )}

                    {entityMeta?.poolsMeta?.poolIds &&
                      !_.isEmpty(entityMeta?.poolsMeta?.poolIds) && (
                        <Alert severity='warning'>
                          <Box>
                            This entity belongs to {entityMeta.poolsMeta.poolIds.length}{" "}
                            {TextHelper.pluralizeManual(
                              "pool",
                              entityMeta.poolsMeta.poolIds.length,
                              "pools",
                            )}{" "}
                            (deletion may be forbidden).
                          </Box>
                          <Stack>
                            {entityMeta.poolsMeta.poolIds.map((id, i) => (
                              <PoolLink key={i} entity={undefined} entityId={id} />
                            ))}
                          </Stack>
                        </Alert>
                      )}
                  </>,
                )
                // if entity shared to me
                .else(
                  <>
                    <Alert severity='error'>You are not allowed to delete this entity.</Alert>
                  </>,
                )
                .render()}
            </Stack>
          )}

          {cascadeActionDisplay && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={isDeleteCascade}
                  onChange={(e) => setIsDeleteCascade(e.target.checked)}
                />
              }
              label='Is delete cascade'
            />
          )}

          {bodyAfter && <Box>{bodyAfter}</Box>}
        </Stack>
      }
      onCancel={() => dialogProps?.onClose && dialogProps.onClose({}, "escapeKeyDown")}
      onConfirm={handleDeleteConfirmed}
      {...dialogProps}
    />
  );
}
