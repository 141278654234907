import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import * as Yup from "yup";

import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { AssetSubscriptionPlanDto } from "@/core/api/generated";

import GeneralValidationError from "../../Error/GeneralValidationError";

export interface AssetSubscriptionPlanToggleEnabledOwnProps {
  assetSubscriptionPlan: AssetSubscriptionPlanDto;
  onSave?: (newValue?: AssetSubscriptionPlanDto) => void;
}

export type AssetSubscriptionPlanToggleEnabledProps = AssetSubscriptionPlanToggleEnabledOwnProps;

export default function AssetSubscriptionPlanToggleEnabled({
  assetSubscriptionPlan,
  onSave,
}: AssetSubscriptionPlanToggleEnabledProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();

  return (
    <Box>
      <Formik<BaseFormikValues>
        enableReinitialize
        initialValues={{
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // vehicleId: Yup.string().required("Vehicle is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            const response =
              await apiClient.assetSubscriptionPlansApi.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdEnabledTogglePost(
                {
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  assetSubscriptionPlanId: assetSubscriptionPlan.id || "",
                },
              );
            enqueueSnackbar(response?.data?.isEnabled ? "Enabled" : "Disabled", {
              variant: "success",
            });
            onSave && onSave(response.data);

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {(formikProps) => {
          const { errors, handleSubmit, isSubmitting } = formikProps;

          return (
            <form noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Typography variant='body1'>
                  <Box>
                    {assetSubscriptionPlan?.isEnabled
                      ? "By disabling the plan you make it invisible to end fleet customers and disable new subscription reservations."
                      : "By enabling the plan you make it visible to end fleet customers and enable new subscription reservations."}
                  </Box>
                  <Box>Carefully review your action before proceeding.</Box>
                </Typography>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <Button
                  sx={{ mt: { xs: "auto", md: 2 } }}
                  color='primary'
                  loading={isSubmitting}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Yes, {assetSubscriptionPlan?.isEnabled ? "disable" : "enable"}!
                </Button>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
}
