import {
  BoxProps,
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputProps,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FormikHandlers } from "formik";
import { ReactElement, ReactNode } from "react";

import { MemoHelper, memoWithReversedArgs } from "@/common/helpers/memo";
import { ValidationErrors } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { GeneralNationalIdentityNumberInputDto } from "@/core/api/generated";

import GeneralNationalIdentityNumberInput from "../Entity/General/Input/GeneralNationalIdentityNumberInput";
import PhoneInput from "../Form/Input/PhoneInput";
import AppIcon from "../Icons/AppIcon";
import BaseContactCard from "./BaseContactCard";

const defaultDisplayProps = {
  firstName: true,
  lastName: true,
  email: true,
  phoneNumber: true,
  nationalIdentityNumber: false,
  isPrimary: false,
};

export interface ContactData {
  id?: string;
  tempId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  nationalIdentityNumber?: GeneralNationalIdentityNumberInputDto;
  isPrimary?: boolean;
}

interface Props {
  value?: ContactData;
  action?: React.ReactNode & ReactNode;
  errors?: ValidationErrors<ContactData>;
  inputsProps?: {
    /** Applied to all inputs */
    all?: {
      size?: InputProps["size"];
      onBlur?: InputProps["onBlur"] & CheckboxProps["onBlur"];
    };
  } & Partial<
    Record<
      keyof ContactData,
      {
        name?: InputProps["name"];
        required?: InputProps["required"];
        // onChange?: InputProps["onChange"] & CheckboxProps["onChange"];
        onBlur?: InputProps["onBlur"] & CheckboxProps["onBlur"];
      }
    >
  >;

  displayProps?: Partial<typeof defaultDisplayProps>;
  sx?: BoxProps["sx"];
  children?: ReactElement;
  onChange: (newValue: ContactData) => void | FormikHandlers["handleChange"];
}

export default memoWithReversedArgs(
  MemoHelper.arePropsEqual.factory<Props>({
    isExcludeFunctionProps: true,
    excludeProps: (t) => [t.action.$path, t.inputsProps.$path, t.displayProps.$path, t.sx.$path],
    deepCompareProps: (t) => [t.value.$path],
  }),
  function ContactInput({
    value,
    action,
    errors,
    inputsProps,
    displayProps,
    sx,
    children,
    onChange,
  }: Props) {
    displayProps = {
      ...defaultDisplayProps,
      ...displayProps,
    };

    return (
      <BaseContactCard sx={sx}>
        <Stack direction='row' spacing={1}>
          <Stack direction='column'>
            <AppIcon of='person' fontSize='small' sx={{ mt: 0.5 }} />
          </Stack>

          <Stack direction='column' spacing={2} sx={{ flexGrow: 1 }}>
            {displayProps?.firstName && (
              <TextField
                error={Boolean(errors?.firstName)}
                fullWidth
                helperText={
                  errors?.firstName && ValidationHelper.getErrorsAsString(errors?.firstName)
                }
                label='First name'
                InputLabelProps={{
                  sx: {
                    background: "inherit",
                  },
                }}
                margin='none'
                size={inputsProps?.all?.size || "small"}
                onBlur={inputsProps?.all?.onBlur}
                onChange={(e) =>
                  onChange &&
                  onChange({
                    ...value,
                    firstName: e.target.value,
                  })
                }
                type='text'
                value={value?.firstName || ""}
                variant='outlined'
                {...inputsProps?.firstName}
              />
            )}

            {displayProps?.lastName && (
              <TextField
                error={Boolean(errors?.lastName)}
                fullWidth
                helperText={
                  errors?.lastName && ValidationHelper.getErrorsAsString(errors?.lastName)
                }
                label='Last Name'
                InputLabelProps={{
                  sx: {
                    background: "inherit",
                  },
                }}
                margin='none'
                size={inputsProps?.all?.size || "small"}
                onBlur={inputsProps?.all?.onBlur}
                onChange={(e) =>
                  onChange &&
                  onChange({
                    ...value,
                    lastName: e.target.value,
                  })
                }
                type='text'
                value={value?.lastName || ""}
                variant='outlined'
                {...inputsProps?.lastName}
              />
            )}

            {displayProps?.email && (
              <TextField
                error={Boolean(errors?.email)}
                fullWidth
                helperText={errors?.email && ValidationHelper.getErrorsAsString(errors?.email)}
                label='Email'
                InputLabelProps={{
                  sx: {
                    background: "inherit",
                  },
                }}
                margin='none'
                size={inputsProps?.all?.size || "small"}
                onBlur={inputsProps?.all?.onBlur}
                onChange={(e) =>
                  onChange &&
                  onChange({
                    ...value,
                    email: e.target.value,
                  })
                }
                type='email'
                value={value?.email || ""}
                variant='outlined'
                {...inputsProps?.email}
              />
            )}

            {displayProps?.phoneNumber && (
              <PhoneInput
                error={Boolean(errors?.phoneNumber)}
                fullWidth
                helperText={
                  errors?.phoneNumber && ValidationHelper.getErrorsAsString(errors?.phoneNumber)
                }
                label='Phone number'
                InputLabelProps={{
                  sx: {
                    background: "inherit",
                  },
                }}
                margin='none'
                value={value?.phoneNumber || ""}
                variant='outlined'
                color='primary'
                placeholder='Phone number'
                size={inputsProps?.all?.size || "small"}
                onBlur={inputsProps?.all?.onBlur}
                onChange={(e, phone, iso) => {
                  onChange &&
                    onChange({
                      ...value,
                      phoneNumber: phone,
                    });
                }}
                {...inputsProps?.phoneNumber}
              />
            )}

            {displayProps?.nationalIdentityNumber && (
              <GeneralNationalIdentityNumberInput
                value={value?.nationalIdentityNumber}
                margin='none'
                size={inputsProps?.all?.size || "small"}
                fullWidth
                error={Boolean(errors?.nationalIdentityNumber)}
                helperText={
                  errors?.nationalIdentityNumber &&
                  ValidationHelper.getErrorsAsString(errors?.nationalIdentityNumber)
                }
                InputLabelProps={{
                  sx: {
                    background: "inherit",
                  },
                }}
                onChange={(newValue) => {
                  onChange &&
                    onChange({
                      ...value,
                      nationalIdentityNumber: newValue || undefined,
                    });
                }}
                onBlur={inputsProps?.all?.onBlur}
                {...inputsProps?.nationalIdentityNumber}
              />
            )}

            {displayProps?.isPrimary && (
              <FormControl margin='dense' fullWidth error={Boolean(errors?.isPrimary)}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value?.isPrimary ?? false}
                      size={inputsProps?.all?.size || "small"}
                      onChange={(e) => {
                        onChange &&
                          onChange({
                            ...value,
                            isPrimary: e.target.checked,
                          });
                      }}
                      onBlur={inputsProps?.all?.onBlur}
                      {...inputsProps?.isPrimary}
                    />
                  }
                  label={
                    <Typography component='span' sx={{ display: "flex" }}>
                      Primary contact
                    </Typography>
                  }
                />
                {errors?.isPrimary && (
                  <FormHelperText error>
                    {ValidationHelper.getErrorsAsString(errors?.isPrimary)}
                  </FormHelperText>
                )}
              </FormControl>
            )}

            {children && children}
          </Stack>

          <Stack direction='column' sx={{ justifyContent: "flex-start" }}>
            {action && action}
          </Stack>
        </Stack>
      </BaseContactCard>
    );
  },
);
