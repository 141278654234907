import { useRef } from "react";

import { ComparisonHelper } from "@/common/helpers/comparison";

/** Returns the newest memorized value (uses deep comparison).
 *  Analog of useMemo for single value.
 *  Source: https://stackoverflow.com/a/54096391/5168794
 */
export function useValueMemoWithDeepCompare<TValue>(value: TValue): TValue {
  const ref = useRef<TValue>(value);

  if (!ComparisonHelper.isDeepEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}
