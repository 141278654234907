import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import WashCreateUpdate from "@/common/components/Entity/Wash/WashCreateUpdate";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";

export interface WashCreateUpdateQueryParams extends GeneralQueryParams {
  vehicleId?: string | null;
}

export default function WashCreateUpdatePage() {
  const { washId } = useParams<{ washId?: string }>();
  const { vehicleId } = useQueryParams<WashCreateUpdateQueryParams>();
  const history = useHistory();
  const isCreate = !washId;
  // const isEdit = !!washId;

  return (
    <CreateUpdatePageLayout
      header={<SimpleViewPageHeader title={isCreate ? "Create new wash" : "Edit wash"} />}
    >
      <WashCreateUpdate
        washId={washId}
        defaultValues={{
          vehicleId: vehicleId || undefined,
        }}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.WASH_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}
