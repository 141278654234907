import { Box, Chip, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { StringHelper } from "@/common/helpers/string";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import {
  VehicleArea,
  VehiclePartTypeDto,
  VehiclePartTypeSearchPaginatedDto,
  VehicleProjection,
} from "@/core/api/generated";

export type VehiclePartTypeAutocompleteOption = BaseAutocompleteOption<VehiclePartTypeDto>;

const generalGroupName = "General";
const isProVehiclePartTypeGroup = (groupName: string) => groupName !== generalGroupName;

export const vehiclePartTypeToOption = (
  data: VehiclePartTypeDto,
): VehiclePartTypeAutocompleteOption => ({
  id: data.id!,
  title: StringHelper.joinIntoString([data.name], ", ", {
    skipEmpty: true,
  }),
  optionType: AutocompleteOptionType.Normal,
  groupBy: data.isCategoryRoot ? generalGroupName : data.category,
  data: data,
});

export interface VehiclePartTypeAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<VehiclePartTypeDto> {
  searchFilters?: Partial<Omit<VehiclePartTypeSearchPaginatedDto, "search" | "includeIds">>;
  /** Used for better display of results */
  metadata?: {
    area?: VehicleArea;
    projection?: VehicleProjection;
  };
}

export default function VehiclePartTypeAutocompleteV2({
  searchFilters,
  metadata,

  isPreload = true,
  ...otherProps
}: VehiclePartTypeAutocompleteProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={vehiclePartTypeToOption}
      sortBy={(option) => option.groupBy || ""}
      sortOrder='asc'
      groupBy={(option) => option.groupBy || ""}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.vehiclePartTypesApi.apiV1ReferenceDataVehiclesPartTypesSearchPost,
        limit: 25,
        parameters: {
          vehiclePartTypeSearchPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          vehiclePartTypeSearchPaginatedDto: {
            ...params.vehiclePartTypeSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Part type'
      placeholder='Search...'
      renderGroupTitle={(params) => (
        <Box {...params.props}>
          <span>{params.group}</span>
          {isProVehiclePartTypeGroup(params.group) && (
            <Chip size='small' color='primary' variant='outlined' label='Pro' sx={{ ml: 1 }} />
          )}
        </Box>
      )}
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='vehiclePartType' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant={
                    option.optionType === AutocompleteOptionType.DynamicCreateNew
                      ? "subtitle1"
                      : "body1"
                  }
                  component='div'
                >
                  {option.title}
                </Typography>
              }
              secondary={
                <>
                  <Typography variant='body2' color='text'>
                    {option.data?.description}
                  </Typography>
                  <Box>
                    {option.data?.areas?.map((area, i) => (
                      <Chip
                        key={i}
                        size='small'
                        color='default'
                        variant='outlined'
                        label={<InlineApiEnumValue type='VehicleArea' value={area} />}
                        sx={{ mr: 1 }}
                      />
                    ))}

                    {metadata?.projection && metadata.projection !== VehicleProjection.None && (
                      <Chip
                        size='small'
                        color='default'
                        variant='outlined'
                        label={
                          <InlineApiEnumValue
                            type='VehicleProjection'
                            value={metadata.projection}
                            sx={{ fontWeight: "bold" }}
                          />
                        }
                        sx={{ mr: 1 }}
                      />
                    )}
                  </Box>
                </>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}
