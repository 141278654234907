import { TimeSpan } from "./ts/timespan";

/**
 * Custom implementation that is based on source-code of .NET System.Diagnostics.Stopwatch.
 * Allows to measure execution time at runtime.
 */
export class Stopwatch {
  // private static ticksPerMillisecond = 10000;
  // private static ticksPerSecond = this.ticksPerMillisecond * 1000;

  private _elapsed = 0;
  private _startTimeStamp = 0;
  private _isRunning = false;

  constructor() {
    this.reset();
  }

  public static startNew(): Stopwatch {
    const s = new Stopwatch();
    s.start();
    return s;
  }

  public start(): void {
    // Calling start on a running Stopwatch is a no-op.
    if (!this._isRunning) {
      this._startTimeStamp = Stopwatch.getTimestamp();
      this._isRunning = true;
    }
  }

  public stop(): void {
    // Calling stop on a stopped Stopwatch is a no-op.
    if (this._isRunning) {
      const endTimeStamp = Stopwatch.getTimestamp();
      const elapsedThisPeriod = endTimeStamp - this._startTimeStamp;
      this._elapsed += elapsedThisPeriod;
      this._isRunning = false;

      if (this._elapsed < 0) {
        // When measuring small time periods the Stopwatch.Elapsed*
        // properties can return negative values.  This is due to
        // bugs in the basic input/output system (BIOS) or the hardware
        // abstraction layer (HAL) on machines with variable-speed CPUs
        // (e.g. Intel SpeedStep).

        this._elapsed = 0;
      }
    }
  }

  public reset(): void {
    this._elapsed = 0;
    this._isRunning = false;
    this._startTimeStamp = 0;
  }

  // Convenience method for replacing {sw.Reset(); sw.Start();} with a single sw.Restart()
  public restart(): void {
    this._elapsed = 0;
    this._startTimeStamp = Stopwatch.getTimestamp();
    this._isRunning = true;
  }

  public get isRunning(): boolean {
    return this._isRunning;
  }

  public get elapsed(): TimeSpan {
    return new TimeSpan(this._elapsed);
  }

  public get elapsedMilliseconds(): number {
    return this._elapsed;
  }

  public get elapsedSeconds(): number {
    return this._elapsed / 1000;
  }

  public static getTimestamp(): number {
    return performance.now();
  }

  public static getElapsedTime(startingTimestamp: number): TimeSpan {
    return this.getElapsedTime2(startingTimestamp, Stopwatch.getTimestamp());
  }

  public static getElapsedTime2(startingTimestamp: number, endingTimestamp: number): TimeSpan {
    return new TimeSpan(endingTimestamp - startingTimestamp);
  }
}
