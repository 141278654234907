import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function EditIcon(props: SvgIconProps, ref) {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <path d='M18 3C18 2.44772 17.5523 2 17 2H7C6.44771 2 6 2.44772 6 3C6 3.55228 6.44771 4 7 4H17C17.5523 4 18 3.55228 18 3ZM4 7C4 6.44771 4.44772 6 5 6H19C19.5523 6 20 6.44771 20 7C20 7.55228 19.5523 8 19 8H5C4.44772 8 4 7.55228 4 7ZM2 12C2 10.8954 2.89543 10 4 10H20C21.1046 10 22 10.8954 22 12V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V12ZM20 20V12H4V20H20Z' />
      <path
        d='M8.7775 15.7909L8.69229 15.623L8.54769 15.7436C8.11846 16.1015 7.85 16.5502 7.85 17.0625C7.85 17.7771 8.36881 18.3642 9.1284 18.7641C9.89256 19.1664 10.9334 19.4 12.0625 19.4C13.1916 19.4 14.2324 19.1664 14.9966 18.7641C15.7562 18.3642 16.275 17.7771 16.275 17.0625C16.275 16.5531 16.0094 16.1056 15.5832 15.7488L15.4401 15.629L15.3539 15.7944L15.0564 16.365L15.0051 16.4633L15.084 16.5411C15.2663 16.7211 15.35 16.8988 15.35 17.0625C15.35 17.2139 15.2787 17.3772 15.1234 17.5433C14.9681 17.7094 14.7361 17.8696 14.4378 18.0093C13.8418 18.2886 13.0048 18.475 12.0625 18.475C11.1202 18.475 10.2832 18.2886 9.68716 18.0093C9.38891 17.8696 9.15695 17.7094 9.00158 17.5433C8.84626 17.3772 8.775 17.2139 8.775 17.0625C8.775 16.8992 8.85832 16.7209 9.04102 16.5405L9.11896 16.4635L9.06938 16.3659L8.7775 15.7909Z'
        stroke='currentColor'
        strokeWidth='0.3'
      />
      <path
        d='M7.85 15.1875C7.85 15.9021 8.36881 16.4892 9.1284 16.8891C9.89256 17.2914 10.9334 17.525 12.0625 17.525C13.1916 17.525 14.2324 17.2914 14.9966 16.8891C15.7562 16.4892 16.275 15.9021 16.275 15.1875C16.275 14.4729 15.7562 13.8858 14.9966 13.4859C14.2324 13.0836 13.1916 12.85 12.0625 12.85C10.9334 12.85 9.89256 13.0836 9.1284 13.4859C8.36881 13.8858 7.85 14.4729 7.85 15.1875ZM15.35 15.1875C15.35 15.3389 15.2787 15.5022 15.1234 15.6683C14.9681 15.8344 14.7361 15.9946 14.4378 16.1343C13.8418 16.4136 13.0048 16.6 12.0625 16.6C11.1202 16.6 10.2832 16.4136 9.68716 16.1343C9.38891 15.9946 9.15695 15.8344 9.00158 15.6683C8.84626 15.5022 8.775 15.3389 8.775 15.1875C8.775 15.0361 8.84626 14.8728 9.00158 14.7067C9.15695 14.5406 9.38891 14.3804 9.68716 14.2407C10.2832 13.9614 11.1202 13.775 12.0625 13.775C13.0048 13.775 13.8418 13.9614 14.4378 14.2407C14.7361 14.3804 14.9681 14.5406 15.1234 14.7067C15.2787 14.8728 15.35 15.0361 15.35 15.1875Z'
        stroke='currentColor'
        strokeWidth='0.3'
      />
    </SvgIcon>
  );
});
