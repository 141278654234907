import { Box, Typography } from "@mui/material";

import GoBackButton from "@/common/components/Button/GoBackButton";

export default function NotFound() {
  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography component='div' variant='h4'>
        Not found
      </Typography>
      <Box>{`The requested resource can't be found.`}.</Box>
      <Box>
        <GoBackButton />
      </Box>
    </Box>
  );
}
