import { DialogProps } from "@mui/material";

import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { VehicleDamageDto } from "@/core/api/generated";

import { ExtendedDamageType } from "../Entity/DamageDetection/DamageDetectionCreateUpdate";
import VehicleDamageViewModal from "../Entity/VehicleDamage/View/VehicleDamageViewModal";

interface OwnProps {
  damagePointInfo: ExtendedDamageType | null | undefined;
}

type Props = OwnProps & DialogProps;

export const DamagePointInfoModal = ({ damagePointInfo, ...dialogProps }: Props) => {
  return (
    <VehicleDamageViewModal
      {...dialogProps}
      viewProps={{
        vehicleId: damagePointInfo?.vehicle?.id || undefined,
        vehicleDamageId: (damagePointInfo as VehicleDamageDto)?.id,
        vehicleDamage: (damagePointInfo as VehicleDamageDto) || undefined, // intentional use of 'as'
        headerProps: {
          title: "",
        },
        displayProps: {
          breadcrumbs: false,
          header: true,
          damageDetection: false,
          visualModel: true,
          viewVariant: ViewLayoutVariant.Tab,
        },
      }}
    />
  );
};
