import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid2,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { FormikErrors, useFormikContext } from "formik";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ReactZoomPanPinchRef } from "react-zoom-pan-pinch";

import { FileItem } from "@/common/fileItem";
import { ArrayHelper } from "@/common/helpers/array";
import { CloneHelper } from "@/common/helpers/clone";
import { DamageDetectionFormPermissions } from "@/common/helpers/entity/damageDetection";
import { StringHelper } from "@/common/helpers/string";
import { TextHelper } from "@/common/helpers/text";
import { useLatestRef } from "@/common/hooks/ref/useLatestRef";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { EntityFormInfo } from "@/common/ts/entity";
import {
  VehicleArea,
  VehiclePartTypeDto,
  VehicleProjection,
  VehicleVisualModelDto,
  VehicleVisualModelImageDto,
} from "@/core/api/generated";

import NoDataAlert from "../AppAlerts/NoDataAlert";
import AppTooltip from "../AppTooltip";
import AppButton from "../Button/AppButton";
import AppIconButton from "../Button/AppIconButton";
import AppCard from "../Card/AppCard";
import AppCardContent from "../Card/AppCardContent";
import DataTabular from "../DataTabular/DataTabular";
import {
  CreateUpdateDDFormikType,
  ExtendedDamageType,
  VisualModelPointClickedInfo,
} from "../Entity/DamageDetection/DamageDetectionCreateUpdate";
import DamageTypeAutocompleteV2 from "../Entity/ReferenceData/DamageType/DamageTypeAutocompleteV2";
import { VehiclePartTypeAutocompleteProps } from "../Entity/ReferenceData/VehiclePartType/VehiclePartTypeAutocomplete";
import VehiclePartTypeAutocompleteV2 from "../Entity/ReferenceData/VehiclePartType/VehiclePartTypeAutocompleteV2";
import VehicleDamageLink from "../Entity/VehicleDamage/VehicleDamageLink";
import InlineApiEnumValue from "../Enum/InlineApiEnumValue";
import FileListView from "../Files/FileListView";
import FileUploader from "../Files/FileUploader";
import AppTextArea from "../Form/Input/AppTextArea";
import AppIcon from "../Icons/AppIcon";
import CompoundIcon from "../Icons/CompoundIcon";
import MenuWithTrigger from "../Menu/MenuWithTrigger";
import ConfirmationModal from "../Modals/ConfirmationModal";
import AppPopover from "../Popover/AppPopover";
import AppTypography from "../Text/AppTypography";
import { DamagePointInfoModal } from "./DamagePointInfoModal";
import DamagePointsByVisualModelViewerModal from "./DamagePointsByVisualModelViewerModal";
import DamagePointsVisualizer from "./DamagePointsVisualizer";
import ZoomableBlock from "./ZoomableBlock";

interface Props {
  containerSx?: SxProps<Theme>;
  formInfo: EntityFormInfo;
  formPerms: DamageDetectionFormPermissions;
  damages: Array<ExtendedDamageType>;
  oldDamages: Array<ExtendedDamageType>;
  isCreateModalConfirmEnabled?: boolean;
  isEditingModalConfirmEnabled?: boolean;
  isDisableDelete?: boolean;
  isDisablePartTypeEdit?: boolean;
  isDisableDamageTypeEdit?: boolean;
  isPreload?: boolean;
  updateDamageItems: (items: Array<ExtendedDamageType>) => void;
  visualModel: VehicleVisualModelDto | undefined;
  errors: FormikErrors<CreateUpdateDDFormikType>;
}

type AdditionalPartTypesFilteringInfoType = VehiclePartTypeAutocompleteProps["searchFilters"];

export const DamagePointsEditor = ({
  containerSx,
  formInfo,
  formPerms,
  damages,
  oldDamages,
  isCreateModalConfirmEnabled,
  isEditingModalConfirmEnabled,
  isDisableDelete,
  isDisablePartTypeEdit,
  isDisableDamageTypeEdit,
  isPreload,
  updateDamageItems,
  visualModel,
  errors,
}: Props) => {
  const { enqueueSnackbar } = useAppSnackbar();
  const { setFieldValue } = useFormikContext<CreateUpdateDDFormikType>();

  const damagesRef = useLatestRef(damages);

  const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);
  const [isViewingModalOpened, setIsViewingModalOpened] = useState(false);
  const [newPointInfo, setNewPointInfo] = useState<VisualModelPointClickedInfo | null>(null);
  const [viewingPointInfo, setViewingPointInfo] = useState<ExtendedDamageType | null>(null);
  const [__, setEditingPointInfo] = useState<ExtendedDamageType | null>(null);
  const [isAttachmentFilesUploading, setIsAttachmentFilesUploading] = useState(false);
  const [isAttachmentFilesHaveErrors, setIsAttachmentFilesHaveErrors] = useState(false);
  const [additionalPartTypesFilteringInfo, setAdditionalPartTypesFilteringInfo] = useState<
    AdditionalPartTypesFilteringInfoType | undefined
  >(undefined);
  const [editingDamageItem, setEditingDamageItem] = useState<ExtendedDamageType | null>(null);
  const [newDamageItem, setNewDamageItem] = useState<ExtendedDamageType | null>(null);
  const [damageItemToDelete, setDamageItemToDelete] = useState<ExtendedDamageType | null>(null);
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [isOtherVisualModelsModalOpen, setIsOtherVisualModelsModalOpen] = useState<boolean>(false);
  const [damageOnOtherVisualModel, setDamageOnOtherVisualModel] = useState<
    Array<ExtendedDamageType>
  >([]);
  const [selectedPerspective, setSelectedPerspective] = useState<
    VehicleVisualModelImageDto | undefined | null
  >(null);
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);

  // const [isSplitItemActive, setIsSplitItemActive] = useState(false);
  const [isSplitItemActive, setIsSplitItemActive] = useState(false);
  const [damageItemsToSplitFromSource, setDamageItemsToSplitFromSource] = useState<
    ExtendedDamageType[] | null
  >(null);

  const [isMergeItemsActive, setIsMergeItemsActive] = useState(false);
  const [isMergeItemsIsInPreview, setIsMergeItemsIsInPreview] = useState(false);
  const [damageItemsToMergeIntoTarget, setDamageItemsToMergeIntoTarget] = useState<
    ExtendedDamageType[] | null
  >(null);

  const areaComputed =
    additionalPartTypesFilteringInfo?.area ||
    editingDamageItem?.area ||
    newPointInfo?.area ||
    selectedPerspective?.area;
  const projectionComputed =
    (areaComputed === VehicleArea.Exterior
      ? editingDamageItem?.projection || selectedPerspective?.projection
      : undefined) ||
    (areaComputed === VehicleArea.Interior && undefined) ||
    undefined;

  const editingDamageItemVisualModelImage = useMemo<VehicleVisualModelImageDto | undefined>(
    () =>
      visualModel && editingDamageItem
        ? visualModel?.images?.find(
            (x) =>
              x.area === editingDamageItem.area && x.projection === editingDamageItem.projection,
          )
        : undefined,
    [visualModel, editingDamageItem],
  );

  const onCreatePartTypeChanged = useCallback(
    (newValue: VehiclePartTypeDto | null | undefined) => {
      setNewDamageItem({
        ...newDamageItem,
        partTypeId: newValue?.id,
        savedPartType: newValue,
        damageTypeId: undefined,
        savedDamageType: undefined,
      });
    },
    [newDamageItem],
  );

  useEffect(() => {
    setSelectedPerspective(visualModel?.images && visualModel?.images[0]);
  }, [visualModel]);

  const imageUrl = selectedPerspective?.file?.url;

  useEffect(() => {
    if (oldDamages.length && visualModel?.id) {
      setDamageOnOtherVisualModel(oldDamages.filter((d) => d.visualModel?.id !== visualModel!.id!));
    }
  }, [oldDamages]);

  const createNewSplitTargetItem = (): ExtendedDamageType => {
    return {
      ...CloneHelper.cloneDeep(editingDamageItem),
      id: _.uniqueId("frontend-temp-id-"),
      localNumber: undefined,
      damage: undefined,
    };
  };

  const isAllSplitTargetItemsValid = (): boolean => {
    return (
      ArrayHelper.isNotEmpty(damageItemsToSplitFromSource) &&
      damageItemsToSplitFromSource.length > 1 &&
      damageItemsToSplitFromSource.every((x) => !!x.partTypeId && !!x.damageTypeId)
    );
  };

  const isAllMergeSourceItemsValid = (): boolean => {
    return ArrayHelper.isNotEmpty(damageItemsToMergeIntoTarget);
  };

  return (
    <Stack spacing={1}>
      <Stack direction='row' spacing={1} sx={{ mb: 1 }}>
        <Box>
          <Button
            variant='outlined'
            color='text'
            size='small'
            startIcon={<AppIcon of='add' />}
            onClick={() => {
              setNewPointInfo(null);
              setNewDamageItem(null);
              setAdditionalPartTypesFilteringInfo({
                area: VehicleArea.Interior,
                projection: undefined,
              });
              setIsCreateModalOpened(true);
            }}
          >
            Add interior damage
          </Button>
        </Box>

        <Box>
          <Button
            variant='outlined'
            color='text'
            size='small'
            startIcon={<AppIcon of='add' />}
            onClick={() => {
              setNewPointInfo(null);
              setNewDamageItem(null);
              setAdditionalPartTypesFilteringInfo({
                area: VehicleArea.Exterior,
                projection: VehicleProjection.Top,
              });
              setIsCreateModalOpened(true);
            }}
          >
            Add damage
          </Button>
        </Box>
      </Stack>

      {/* Selected visual model image */}
      <Stack direction='row' spacing={1}>
        <ZoomableBlock wrapperRef={transformComponentRef}>
          <DamagePointsVisualizer
            pointsInfo={[
              ...oldDamages.filter((d) => d.visualModel?.id === visualModel?.id),
              ...damages,
            ].filter((item) => item.projection === selectedPerspective?.projection)}
            imageUrl={imageUrl}
            containerSx={containerSx}
            onNewPointClicked={(pointInfo) => {
              console.log("onNewPointClicked.", pointInfo);
              setNewPointInfo(pointInfo);
              setNewDamageItem(pointInfo);
              setAdditionalPartTypesFilteringInfo({
                category: pointInfo.svgPathMetadata?.vehiclePartCategory,
                types: pointInfo.svgPathMetadata?.vehiclePartTypes || undefined,
                descriptors: pointInfo.svgPathMetadata?.vehiclePartDescriptors || undefined,
              });
              setIsCreateModalOpened(true);
            }}
            onPointClicked={(pointInfo) => {
              if (pointInfo.isOld) {
                setViewingPointInfo(pointInfo);
                setIsViewingModalOpened(true);
              } else {
                setEditingPointInfo(pointInfo);
                const item =
                  damages.find((el) => {
                    return pointInfo?.id === el?.id;
                  }) || {};
                setEditingDamageItem(item);
                setAdditionalPartTypesFilteringInfo({
                  category: pointInfo?.svgPathMetadata?.vehiclePartCategory,
                  types: pointInfo?.svgPathMetadata?.vehiclePartTypes || undefined,
                  descriptors: pointInfo?.svgPathMetadata?.vehiclePartDescriptors || undefined,
                });
                setIsEditModalOpened(true);
              }
            }}
            onPointMoved={(pointInfo) => {
              const oldPartType =
                pointInfo.initialPointInfo.savedPartType?.type ||
                pointInfo.initialPointInfo.partType?.type ||
                pointInfo.initialPointInfo.svgPathMetadata?.vehiclePartTypes?.at(0);
              const oldPartDescriptor =
                pointInfo.initialPointInfo.savedPartType?.descriptor ||
                pointInfo.initialPointInfo.svgPathMetadata?.vehiclePartDescriptors?.at(0);

              const newPartType = pointInfo.svgPathMetadata.vehiclePartTypes?.at(0);
              const newPartDescriptor = pointInfo.svgPathMetadata.vehiclePartDescriptors?.at(0);

              const isPartTypeMatch = oldPartType && newPartType && oldPartType === newPartType;
              const isPartDescriptorMatch =
                oldPartDescriptor && newPartDescriptor && oldPartDescriptor === newPartDescriptor;
              const isPointOnSamePartType = isPartDescriptorMatch || isPartTypeMatch;
              console.log("onPointMoved", {
                pointInfo,
                isPartTypeMatch,
                isPartDescriptorMatch,
                isPointOnSamePartType,
              });

              if (isPointOnSamePartType) {
                const newDamages = damages.map((el) => {
                  if (pointInfo.id === el.id) {
                    el.point = pointInfo.point;
                  }
                  return el;
                });
                setFieldValue("items", newDamages);
                return;
              }

              // if dragged point to different vehicle part - show modal which asks for new part details
              const newDamages = damages.filter((el) => pointInfo.id !== el.id && el);
              setFieldValue("items", newDamages);
              setNewPointInfo(pointInfo);
              setNewDamageItem(pointInfo);
              setAdditionalPartTypesFilteringInfo({
                category: pointInfo?.svgPathMetadata?.vehiclePartCategory,
                types: pointInfo?.svgPathMetadata?.vehiclePartTypes || undefined,
                descriptors: pointInfo?.svgPathMetadata?.vehiclePartDescriptors || undefined,
              });
              setIsCreateModalOpened(true);
              enqueueSnackbar(
                "You moved point to different vehicle part. Please confirm new part.",
                {
                  variant: "info",
                },
              );
            }}
          />
        </ZoomableBlock>
      </Stack>

      {/* Visual model images */}
      <Stack direction='row' flexWrap={"wrap"} spacing={1} sx={{ mb: 1 }}>
        {visualModel?.images?.map((img, index) => {
          const file = FileItem.createFrom(img);
          return (
            <Box
              sx={{
                cursor: "pointer",
                "& svg": {
                  pointerEvents: "none",
                },
                display: "flex",
              }}
              key={index}
              onClick={() => {
                transformComponentRef?.current?.resetTransform();
                setSelectedPerspective(img);
              }}
            >
              <DamagePointsVisualizer
                pointCircleRadius={2}
                pointsInfo={damages
                  .filter((item) => item.projection === img?.projection)
                  .map((item) => item as ExtendedDamageType)}
                containerSx={{
                  width: { xxs: "100px", md: "150px" },
                  height: { xxs: "100px", md: "150px" },
                  overflow: "hidden",
                }}
                imageUrl={file?.file?.url}
              />
            </Box>
          );
        })}
      </Stack>

      <FormControl margin='dense' fullWidth error={Boolean(errors.items)}>
        <FormHelperText>{errors.items?.toString()}</FormHelperText>
      </FormControl>

      {damageOnOtherVisualModel.length ? (
        <Box>
          <Button
            variant='outlined'
            color='text'
            size='small'
            onClick={() => setIsOtherVisualModelsModalOpen(true)}
          >
            View {damageOnOtherVisualModel.length} other{" "}
            {TextHelper.pluralize("damage", damageOnOtherVisualModel.length)} that were marked on
            different visual models.
          </Button>
        </Box>
      ) : (
        <></>
      )}

      {/* List of added damages */}
      <DataTabular
        columns={[
          {
            field: "partType",
            title: "Part type",
            flex: 1,
            renderCell: (item) => (
              <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
                <Box>{item.savedPartType?.name}</Box>

                <Typography component='div' variant='body2' color='text.secondary'>
                  (<InlineApiEnumValue type='VehicleArea' value={item.area} /> /{" "}
                  {(item.projection && (
                    <InlineApiEnumValue type='VehicleProjection' value={item.projection} />
                  )) ||
                    "-"}
                  )
                </Typography>
              </Stack>
            ),
          },
          {
            field: "damageType",
            title: "Damage type",
            flex: 1,
            renderCell: (item) => <>{item?.savedDamageType?.name}</>,
          },
          {
            field: "damage.id",
            title: "Damage",
            flex: 1,
            renderCell: (item) =>
              item.damage ? (
                <VehicleDamageLink
                  vehicleId={item.damage?.vehicleId}
                  entity={item.damage}
                  withIcon
                />
              ) : (
                "-"
              ),
          },
          {
            field: "notes",
            title: "Notes",
            width: 200,
            renderCell: (item) => (
              <AppTooltip title={item.notes || ""}>
                <AppTypography ellipsing={{ enabled: true }}>{item.notes}</AppTypography>
              </AppTooltip>
            ),
          },
          {
            field: "attachments",
            title: "Attachments",
            width: 150,
            renderCell: (item) => (
              <>
                {item.attachments && item.attachments?.length !== 0 && (
                  <FileListView
                    files={FileItem.createManyFrom(item.attachments)}
                    maxVisibleFiles={2}
                    itemWidth={50}
                    itemHeight={30}
                    itemActions={() => ({
                      enabled: true,
                      click: true,
                      download: true,
                    })}
                    fileItemViewProps={{
                      sizeVariant: "compact",
                    }}
                  />
                )}
              </>
            ),
          },
        ]}
        rows={damages}
        getRowId={(item) => item.id!}
        borderVariant='bordered'
        rowHeight={65}
        isColumnSelectorEnabled={false}
        isColumnMenuEnabled={false}
        isPaginationEnabled={false}
        renderRowAction={({ item }) => (
          <MenuWithTrigger
            trigger={
              <IconButton>
                <AppIcon of='moreVert' />
              </IconButton>
            }
          >
            {({ handleClose }) => [
              <MenuItem
                key='edit'
                onClick={() => {
                  setEditingDamageItem(item);
                  setIsEditModalOpened(true);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <AppIcon of='edit' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>,

              <MenuItem
                key='delete'
                onClick={() => {
                  setDamageItemToDelete(item);
                  setIsDeleteModalOpened(true);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <AppIcon of='delete' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </MenuItem>,
            ]}
          </MenuWithTrigger>
        )}
      />

      {/* Add damage modal */}
      <ConfirmationModal
        keepMounted={false}
        title={
          <Box>
            Add damage{" "}
            <AppPopover
              hoverBehavior={{ closeBehavior: "onTriggerOrContentMouseLeave" }}
              trigger={
                <AppIconButton size='small' color='info'>
                  <AppIcon of='info' />
                </AppIconButton>
              }
            >
              <Alert severity='info' sx={{ maxWidth: { xxs: "90vw", md: 400 } }}>
                The corresponding damage will be auto-created in the vehicle history after
                submitting the form..
              </Alert>
            </AppPopover>
          </Box>
        }
        fullWidth
        maxWidth='md'
        open={isCreateModalOpened}
        onClose={() => {
          setIsCreateModalOpened(false);
        }}
        onCancel={() => {
          setNewDamageItem(null);
          setIsCreateModalOpened(false);
        }}
        onConfirm={() => {
          const newItem = {
            ...newDamageItem,
            id: newPointInfo?.id || _.uniqueId("frontend-temp-id-"),
            area: areaComputed,
            projection: projectionComputed,
            point: newPointInfo?.point,
            isDraggable: true,
          };
          updateDamageItems([...(damagesRef.current || []), newItem]);
          setNewDamageItem(null);
          setIsCreateModalOpened(false);
        }}
        isConfirmEnabled={
          !!newDamageItem?.partTypeId &&
          !!newDamageItem?.damageTypeId &&
          !isAttachmentFilesUploading &&
          !isAttachmentFilesHaveErrors &&
          isCreateModalConfirmEnabled
        }
        body={
          <Box sx={{ pt: 1 }}>
            <Grid2 container rowSpacing={1} columnSpacing={1}>
              <Grid2 size={{ xxs: 12, md: 5 }}>
                <FormControl margin='none' fullWidth>
                  <VehiclePartTypeAutocompleteV2
                    entityId={newDamageItem?.partTypeId}
                    isPreload={isPreload}
                    isAutoSelectSingleOption
                    isAutoSelectFirstOption
                    size='small'
                    required
                    metadata={{
                      area: areaComputed,
                      projection: projectionComputed,
                    }}
                    searchFilters={{
                      area: areaComputed,
                      projection: projectionComputed,
                      category: additionalPartTypesFilteringInfo?.category,
                      types: additionalPartTypesFilteringInfo?.types,
                      descriptors: additionalPartTypesFilteringInfo?.descriptors,
                      isEnabledForDamageDetection: true,
                    }}
                    onChange={onCreatePartTypeChanged}
                    onPaginatedLoaded={(paginated) => {
                      if (paginated?.pagination?.totalCount === 0) {
                        setAdditionalPartTypesFilteringInfo(undefined);
                      }
                    }}
                  />
                </FormControl>
              </Grid2>

              <Grid2 size={{ xxs: 12, md: 5 }}>
                <FormControl margin='none' fullWidth>
                  <DamageTypeAutocompleteV2
                    entityId={newDamageItem?.damageTypeId}
                    isPreload={isPreload && !!newDamageItem?.partTypeId}
                    isAutoSelectSingleOption
                    isAutoSelectFirstOption
                    size='small'
                    required
                    disabled={!newDamageItem?.partTypeId}
                    searchFilters={{
                      vehiclePartTypeId: newDamageItem?.partTypeId || undefined,
                    }}
                    onChange={async (newValue) => {
                      setNewDamageItem({
                        ...newDamageItem,
                        damageTypeId: newValue?.id,
                        savedDamageType: newValue,
                      });
                    }}
                  />
                </FormControl>
              </Grid2>

              <Grid2 size={{ xxs: 12, md: 2 }}>
                {/* Controls */}
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='flex-end'
                  spacing={0.5}
                  sx={{ width: "100%" }}
                >
                  {newDamageItem?.notes === undefined && (
                    <Tooltip title='Add notes'>
                      <IconButton
                        onClick={() =>
                          setNewDamageItem({
                            ...newDamageItem,
                            notes: "",
                          })
                        }
                      >
                        <CompoundIcon
                          mainIcon={<AppIcon of='notes' />}
                          badgeIcon={<AppIcon of='add' />}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                  {newDamageItem?.notes !== undefined && (
                    <Tooltip title='Remove notes'>
                      <IconButton
                        onClick={() =>
                          setNewDamageItem({
                            ...newDamageItem,
                            notes: undefined,
                          })
                        }
                      >
                        <CompoundIcon
                          mainIcon={<AppIcon of='notes' />}
                          badgeIcon={<AppIcon of='remove' />}
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                  {newDamageItem?.attachments === undefined && (
                    <Tooltip title='Add attachments'>
                      <IconButton
                        onClick={() =>
                          setNewDamageItem({
                            ...newDamageItem,
                            attachments: [],
                          })
                        }
                      >
                        <CompoundIcon
                          mainIcon={<AppIcon of='attachments' />}
                          badgeIcon={<AppIcon of='add' />}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                  {newDamageItem?.attachments !== undefined && (
                    <Tooltip title='Remove attachments'>
                      <IconButton
                        onClick={() =>
                          setNewDamageItem({
                            ...newDamageItem,
                            attachments: undefined,
                          })
                        }
                      >
                        <CompoundIcon
                          mainIcon={<AppIcon of='attachments' />}
                          badgeIcon={<AppIcon of='remove' />}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              </Grid2>
            </Grid2>

            {newDamageItem?.notes !== undefined && (
              <AppTextArea
                fullWidth
                mode='notes'
                margin='normal'
                onChange={(e) => {
                  setNewDamageItem({
                    ...newDamageItem,
                    notes: e.target.value,
                  });
                }}
                value={newDamageItem?.notes}
                variant='outlined'
              />
            )}

            {newDamageItem?.attachments !== undefined && (
              <FormControl fullWidth margin='dense'>
                {/* <FormLabel component='legend'>Attachments</FormLabel> */}
                <FileUploader
                  multiple
                  onChange={(newFiles) => {
                    setNewDamageItem({
                      ...newDamageItem,
                      attachments: FileItem.toManyGeneralAttachmentInputDto(newFiles) || undefined,
                      initialAttachments:
                        FileItem.toManyGeneralAttachmentDto(newFiles) || undefined,
                    });
                  }}
                  onUploadStarted={() => {
                    setIsAttachmentFilesUploading(true);
                  }}
                  onUploadFinished={() => {
                    setIsAttachmentFilesUploading(false);
                  }}
                  onValidationStatusChange={(filesValidationStatus) => {
                    if (filesValidationStatus)
                      setIsAttachmentFilesHaveErrors(
                        Object.values(filesValidationStatus).some((x) => x === false),
                      );
                  }}
                />
              </FormControl>
            )}
          </Box>
        }
      />

      {/* Edit damage modal */}
      <ConfirmationModal
        keepMounted={false}
        fullWidth
        maxWidth='md'
        open={isEditModalOpened}
        isAutoCloseOnCancel={false}
        isAutoCloseOnConfirm={false}
        isConfirmEnabled={
          !!editingDamageItem?.partTypeId &&
          !!editingDamageItem?.damageTypeId &&
          !isAttachmentFilesUploading &&
          !isAttachmentFilesHaveErrors &&
          isEditingModalConfirmEnabled
        }
        onClose={() => {
          setIsEditModalOpened(false);
          setEditingDamageItem(null);

          setIsSplitItemActive(false);
          setDamageItemsToSplitFromSource(null);

          setIsMergeItemsActive(false);
          setIsMergeItemsIsInPreview(false);
          setDamageItemsToMergeIntoTarget(null);
        }}
        onCancel={(params) => {
          if (isSplitItemActive) {
            //cancel split
            setIsSplitItemActive(false);
            setDamageItemsToSplitFromSource(null);
          } else if (isMergeItemsActive) {
            //cancel merge
            setIsMergeItemsActive(false);
            setDamageItemsToMergeIntoTarget(null);
          } else {
            // cancel update
            params.closeModal();
          }
        }}
        onConfirm={(params) => {
          if (!editingDamageItem) {
            return;
          }

          if (isSplitItemActive) {
            // perform split and close the modal

            // remove source item
            let newItems = damagesRef.current.filter((x) => x.id !== editingDamageItem?.id);

            // add target items
            if (damageItemsToSplitFromSource) {
              newItems = newItems.concat(damageItemsToSplitFromSource);
            }

            updateDamageItems(newItems);
            params.closeModal();
          } else if (isMergeItemsActive) {
            // preview merge and keep the modal open
            const newEditingDamageItem = {
              ...editingDamageItem,
            };

            // concat notes
            newEditingDamageItem.notes = StringHelper.joinIntoString(
              [newEditingDamageItem.notes].extensions.concat(
                damageItemsToMergeIntoTarget?.map((x) => x.notes) || [],
              ),
              "\n",
              { skipEmpty: true },
            );

            // concat attachments
            const newAttachments = (newEditingDamageItem.attachments || []).extensions.concat(
              _.flatten(damageItemsToMergeIntoTarget?.map((x) => x.attachments ?? []) || []),
            );
            newEditingDamageItem.attachments = [...newAttachments];

            setEditingDamageItem(newEditingDamageItem);
            setIsMergeItemsActive(false);
            setIsMergeItemsIsInPreview(true);
          } else {
            // update item
            let newItems = damagesRef.current.map((x) =>
              x?.id === editingDamageItem.id ? editingDamageItem : x,
            );

            // perform merge: remove source items
            if (isMergeItemsIsInPreview) {
              const itemIdsToRemove = damageItemsToMergeIntoTarget?.map((x) => x.id) || [];
              newItems = newItems.filter((newItem) => !itemIdsToRemove.includes(newItem.id));
            }

            updateDamageItems(newItems);
            params.closeModal();
          }
        }}
        cancelButtonProps={{
          children: isSplitItemActive
            ? "Cancel split"
            : isMergeItemsActive || isMergeItemsIsInPreview
              ? "Cancel merge"
              : "Cancel",
        }}
        confirmButtonProps={{
          disabled: isSplitItemActive
            ? !isAllSplitTargetItemsValid()
            : isMergeItemsActive
              ? !isAllMergeSourceItemsValid()
              : undefined,
          startIcon: isSplitItemActive ? (
            <AppIcon of='split' />
          ) : isMergeItemsActive ? (
            <AppIcon of='preview' />
          ) : isMergeItemsIsInPreview ? (
            <AppIcon of='merge' />
          ) : undefined,
          children: isSplitItemActive
            ? "Perform split"
            : isMergeItemsActive
              ? "Preview merge"
              : isMergeItemsIsInPreview
                ? "Perform merge"
                : "Save",
        }}
        title={
          <Box>
            Edit damage{" "}
            {formInfo.isUpdate && editingDamageItem?.damage && (
              <AppPopover
                hoverBehavior={{ closeBehavior: "onTriggerOrContentMouseLeave" }}
                trigger={
                  <AppIconButton size='small' color='info'>
                    <AppIcon of='info' />
                  </AppIconButton>
                }
              >
                <Alert severity='info' sx={{ maxWidth: { xxs: "90vw", md: 400 } }}>
                  The corresponding damage{" "}
                  <VehicleDamageLink
                    vehicleId={editingDamageItem?.damage?.vehicleId}
                    entity={editingDamageItem?.damage}
                    entityId={undefined}
                  />{" "}
                  will be auto-updated with the new details in the vehicle history after submitting
                  the form.
                </Alert>
              </AppPopover>
            )}
          </Box>
        }
        body={
          <>
            {!editingDamageItem && <></>}
            {editingDamageItem && (
              <Box sx={{ pt: 1 }}>
                <Stack spacing={2}>
                  {/* Split item */}
                  {isSplitItemActive && (
                    <>
                      <Alert severity='info'>
                        <AlertTitle>How to split damage:</AlertTitle>

                        <Box component='ol' sx={{ m: 0 }}>
                          <li>TODO</li>
                          <li>
                            Add multiple new target damages that the source damage will be split on.
                          </li>
                          <li>
                            Click <strong>Preview split</strong> and review the split or{" "}
                            <strong>Cancel</strong> to abort the split.
                          </li>
                          <li>
                            Click <strong>Save</strong> to perform the split.
                          </li>
                          <li>As a result, the source damage is split on the target damages.</li>
                        </Box>
                      </Alert>

                      <AppTypography variant='h3'>Split source damage</AppTypography>
                    </>
                  )}

                  {/* Merge items */}
                  {isMergeItemsActive && (
                    <>
                      <Alert severity='info'>
                        <AlertTitle>How to merge damages:</AlertTitle>

                        <Box component='ol' sx={{ m: 0 }}>
                          <li>
                            Select one or multiple source damages that need to be merged into target
                            damage.
                          </li>
                          <li>
                            Click <strong>Preview merge</strong> and review the merge or{" "}
                            <strong>Cancel</strong> to abort the merge.
                          </li>
                          <li>
                            Click <strong>Save</strong> to perform the merge.
                          </li>
                          <li>
                            As a result, the source damages are merged into the target damage.
                          </li>
                        </Box>
                      </Alert>
                    </>
                  )}

                  {isMergeItemsActive && (
                    <Stack spacing={2} sx={{ mt: 2 }}>
                      <AppTypography variant='h3'>Merge source damages</AppTypography>

                      <Stack spacing={2}>
                        <Autocomplete<ExtendedDamageType, true>
                          multiple
                          disableCloseOnSelect
                          options={damagesRef.current.filter((x) => x.id !== editingDamageItem?.id)}
                          getOptionLabel={(option) =>
                            `${option.partType?.name} - ${option.damageType?.name}`
                          }
                          // defaultValue={[top100Films[13]]}
                          value={damageItemsToMergeIntoTarget || []}
                          onChange={(e, newValue) => {
                            setDamageItemsToMergeIntoTarget(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              variant='outlined'
                              label='Source damages to merge into the target damage'
                              placeholder='Select 1 or many'
                            />
                          )}
                          renderOption={(props, option) => (
                            <ListItem {...props}>
                              <ListItemIcon>
                                <AppIcon of='damage' />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography variant='body1' component='div'>
                                    {`${option.partType?.name} - ${option.damageType?.name}`}
                                  </Typography>
                                }
                                secondary={
                                  <Typography component='div' variant='body2'>
                                    <Box>{option.notes}</Box>

                                    <FileListView
                                      files={FileItem.createManyFrom(option.attachments)}
                                      maxVisibleFiles={10}
                                      itemHeight={50}
                                      isShowNoFilesAlert={false}
                                    />
                                  </Typography>
                                }
                              />
                            </ListItem>
                          )}
                        />
                      </Stack>

                      <Divider />
                    </Stack>
                  )}

                  {isMergeItemsActive && (
                    <AppTypography variant='h3'>Merge target damage</AppTypography>
                  )}

                  <Box>
                    <Grid2 container rowSpacing={1} columnSpacing={1}>
                      <Grid2 size={{ xxs: 12, md: 5 }} sx={{ order: 0 }}>
                        <FormControl margin='none' fullWidth>
                          <VehiclePartTypeAutocompleteV2
                            entityId={editingDamageItem.partTypeId}
                            isPreload={isPreload}
                            isAutoSelectSingleOption
                            size='small'
                            required
                            disabled={isDisablePartTypeEdit}
                            metadata={{
                              area: areaComputed,
                              projection: projectionComputed,
                            }}
                            searchFilters={{
                              area: areaComputed,
                              projection: projectionComputed,
                              category: editingDamageItem?.savedPartType?.category,
                              type: editingDamageItem?.savedPartType?.type || undefined,
                              descriptor: editingDamageItem?.savedPartType?.descriptor || undefined,
                              isEnabledForDamageDetection: true,
                            }}
                            onChange={async (newValue) => {
                              setEditingDamageItem({
                                ...editingDamageItem,
                                partTypeId: newValue?.id,
                                savedPartType: newValue,
                              });
                            }}
                            onPaginatedLoaded={(paginated) => {
                              if (paginated?.pagination?.totalCount === 0) {
                                setAdditionalPartTypesFilteringInfo(undefined);
                              }
                            }}
                          />
                        </FormControl>
                      </Grid2>

                      <Grid2 size={{ xxs: 12, md: 5 }}>
                        <FormControl margin='none' fullWidth>
                          <DamageTypeAutocompleteV2
                            entityId={editingDamageItem.damageTypeId}
                            isPreload={isPreload}
                            isAutoSelectSingleOption
                            size='small'
                            required
                            disabled={!editingDamageItem.partTypeId || isDisableDamageTypeEdit}
                            searchFilters={{
                              vehiclePartTypeId: editingDamageItem.partTypeId || undefined,
                            }}
                            onChange={async (newValue) => {
                              setEditingDamageItem({
                                ...editingDamageItem,
                                damageTypeId: newValue?.id,
                                savedDamageType: newValue,
                              });
                            }}
                          />
                        </FormControl>
                      </Grid2>

                      <Grid2 size={{ xxs: 12, md: 2 }}>
                        {/* Controls */}
                        <Stack
                          direction='row'
                          alignItems='center'
                          justifyContent='flex-end'
                          spacing={0.5}
                          sx={{ width: "100%" }}
                        >
                          {editingDamageItem.notes === undefined && (
                            <Tooltip title='Add notes'>
                              <IconButton
                                onClick={() => {
                                  setEditingDamageItem({
                                    ...editingDamageItem,
                                    notes: "",
                                  });
                                }}
                              >
                                <CompoundIcon
                                  mainIcon={<AppIcon of='notes' />}
                                  badgeIcon={<AppIcon of='add' />}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                          {editingDamageItem.notes !== undefined && (
                            <Tooltip title='Remove notes'>
                              <IconButton
                                onClick={() => {
                                  setEditingDamageItem({
                                    ...editingDamageItem,
                                    notes: undefined,
                                  });
                                }}
                              >
                                <CompoundIcon
                                  mainIcon={<AppIcon of='notes' />}
                                  badgeIcon={<AppIcon of='remove' />}
                                />
                              </IconButton>
                            </Tooltip>
                          )}

                          {editingDamageItem.attachments === undefined && (
                            <Tooltip title='Add attachments'>
                              <IconButton
                                onClick={() => {
                                  setEditingDamageItem({
                                    ...editingDamageItem,
                                    attachments: [],
                                  });
                                }}
                              >
                                <CompoundIcon
                                  mainIcon={<AppIcon of='attachments' />}
                                  badgeIcon={<AppIcon of='add' />}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                          {editingDamageItem.attachments !== undefined && (
                            <Tooltip title='Remove attachments'>
                              <IconButton
                                onClick={() => {
                                  setEditingDamageItem({
                                    ...editingDamageItem,
                                    attachments: undefined,
                                  });
                                }}
                              >
                                <CompoundIcon
                                  mainIcon={<AppIcon of='attachments' />}
                                  badgeIcon={<AppIcon of='remove' />}
                                />
                              </IconButton>
                            </Tooltip>
                          )}

                          {(formPerms.canSplitItem || formPerms.canMergeItems || isPreload) && (
                            <MenuWithTrigger
                              withAuthCloseOnClick
                              trigger={
                                <IconButton>
                                  <AppIcon of='moreVert' />
                                </IconButton>
                              }
                            >
                              {formPerms.canSplitItem &&
                                !isSplitItemActive &&
                                !isMergeItemsActive && (
                                  <MenuItem
                                    onClick={() => {
                                      setDamageItemsToSplitFromSource([createNewSplitTargetItem()]);
                                      setIsSplitItemActive(true);
                                    }}
                                  >
                                    <ListItemIcon>
                                      <AppIcon of='split' fontSize='small' />
                                    </ListItemIcon>
                                    <ListItemText>Split damage</ListItemText>
                                  </MenuItem>
                                )}

                              {formPerms.canMergeItems &&
                                !isSplitItemActive &&
                                !isMergeItemsActive && (
                                  <MenuItem
                                    onClick={() => {
                                      setIsMergeItemsActive(true);
                                    }}
                                  >
                                    <ListItemIcon>
                                      <AppIcon of='merge' fontSize='small' />
                                    </ListItemIcon>
                                    <ListItemText>Merge damages</ListItemText>
                                  </MenuItem>
                                )}

                              {isPreload && (
                                <MenuItem
                                  onClick={(e) => {
                                    setDamageItemToDelete(editingDamageItem);
                                    setIsDeleteModalOpened(true);
                                  }}
                                >
                                  <ListItemIcon>
                                    <AppIcon of='delete' fontSize='small' />
                                  </ListItemIcon>
                                  <ListItemText>Delete damage</ListItemText>
                                </MenuItem>
                              )}
                            </MenuWithTrigger>
                          )}

                          {/* {isPreload && (
                        <Tooltip title='Delete'>
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              setDamageItemToDelete(editingDamageItem);
                              setIsDeleteModalOpened(true);
                            }}
                          >
                            <AppIcon of='delete' />
                          </IconButton>
                        </Tooltip>
                      )}  */}
                        </Stack>
                      </Grid2>
                    </Grid2>
                  </Box>

                  {editingDamageItem.notes !== undefined && (
                    <AppTextArea
                      fullWidth
                      mode='notes'
                      margin='normal'
                      onChange={(ev) => {
                        setEditingDamageItem({
                          ...editingDamageItem,
                          notes: ev.target.value,
                        });
                      }}
                      type='text'
                      value={editingDamageItem.notes}
                      variant='outlined'
                    />
                  )}

                  {editingDamageItem.attachments !== undefined && (
                    <FormControl fullWidth margin='dense'>
                      {/* <FormLabel component='legend'>Attachments</FormLabel> */}
                      <FileUploader
                        multiple
                        // defaultFiles={FileItem.createManyFrom(
                        //   damagesRef.current[
                        //     damagesRef.current.findIndex((el) => editingDamageItem?.id === el?.id)
                        //   ]?.initialAttachments ?? [],
                        // )}
                        defaultFiles={FileItem.createManyFrom(editingDamageItem?.attachments)}
                        onChange={(newFiles) => {
                          setEditingDamageItem({
                            ...editingDamageItem,
                            attachments:
                              FileItem.toManyGeneralAttachmentInputDtoOrDto(newFiles) || undefined,
                            // initialAttachments:
                            //   FileItem.toManyGeneralAttachmentDto(newFiles) || undefined,
                          });
                        }}
                        onUploadStarted={() => {
                          setIsAttachmentFilesUploading(true);
                        }}
                        onUploadFinished={() => {
                          setIsAttachmentFilesUploading(false);
                        }}
                        onValidationStatusChange={(filesValidationStatus) => {
                          if (filesValidationStatus)
                            setIsAttachmentFilesHaveErrors(
                              Object.values(filesValidationStatus).some((x) => x === false),
                            );
                        }}
                      />
                    </FormControl>
                  )}

                  {/* Split item */}
                  {isSplitItemActive && (
                    <Stack spacing={2} sx={{ mt: 2 }}>
                      <AppTypography variant='h3'>Split target damages</AppTypography>

                      <Stack spacing={2}>
                        <Stack spacing={1}>
                          {ArrayHelper.isEmpty(damageItemsToSplitFromSource) && (
                            <NoDataAlert title='No target damages added' />
                          )}

                          {damageItemsToSplitFromSource?.map((newItem, i) => {
                            const updateNewItem = (newValue: typeof newItem) => {
                              setDamageItemsToSplitFromSource(
                                damageItemsToSplitFromSource?.map((x) =>
                                  x.id === newValue.id ? newValue : x,
                                ),
                              );
                            };

                            const removeNewItem = (newValue: typeof newItem) => {
                              setDamageItemsToSplitFromSource(
                                damageItemsToSplitFromSource?.filter((x) => x.id !== newValue.id),
                              );
                            };

                            return (
                              <AppCard key={i}>
                                <AppCardContent>
                                  <Stack spacing={2}>
                                    <Box
                                      sx={{
                                        display: "grid",
                                        gap: 1,
                                        gridTemplateColumns: "0fr 1fr 1fr 0fr",
                                      }}
                                    >
                                      <AppTypography variant='h4' sx={{ alignSelf: "center" }}>
                                        #{i + 1}
                                      </AppTypography>

                                      <FormControl margin='none' fullWidth>
                                        <VehiclePartTypeAutocompleteV2
                                          entityId={newItem.partTypeId}
                                          isPreload={isPreload}
                                          isAutoSelectSingleOption
                                          size='small'
                                          required
                                          disabled={isDisablePartTypeEdit}
                                          metadata={{
                                            area: areaComputed,
                                            projection: projectionComputed,
                                          }}
                                          searchFilters={{
                                            area: areaComputed,
                                            projection: projectionComputed,
                                            category: newItem?.savedPartType?.category,
                                            type: newItem?.savedPartType?.type || undefined,
                                            descriptor:
                                              newItem?.savedPartType?.descriptor || undefined,
                                            isEnabledForDamageDetection: true,
                                          }}
                                          onChange={async (newValue) => {
                                            updateNewItem({
                                              ...newItem,
                                              partTypeId: newValue?.id,
                                              savedPartType: newValue,
                                            });
                                          }}
                                          onPaginatedLoaded={(paginated) => {
                                            // if (paginated?.pagination?.totalCount === 0) {
                                            //   setAdditionalPartTypesFilteringInfo(undefined);
                                            // }
                                          }}
                                        />
                                      </FormControl>

                                      <FormControl margin='none' fullWidth>
                                        <DamageTypeAutocompleteV2
                                          entityId={newItem.damageTypeId}
                                          isPreload={isPreload}
                                          isAutoSelectSingleOption
                                          size='small'
                                          required
                                          disabled={!newItem.partTypeId || isDisableDamageTypeEdit}
                                          searchFilters={{
                                            vehiclePartTypeId: newItem.partTypeId || undefined,
                                          }}
                                          onChange={async (newValue) => {
                                            updateNewItem({
                                              ...newItem,
                                              damageTypeId: newValue?.id,
                                              savedDamageType: newValue,
                                            });
                                          }}
                                        />
                                      </FormControl>

                                      <Stack direction='row'>
                                        <Tooltip title='Remove'>
                                          <IconButton
                                            onClick={(e) => {
                                              removeNewItem(newItem);
                                            }}
                                          >
                                            <AppIcon of='delete' />
                                          </IconButton>
                                        </Tooltip>
                                      </Stack>
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "grid",
                                        gap: 1,
                                        gridTemplateColumns: "1fr 1fr",
                                      }}
                                    >
                                      <Stack spacing={1}>
                                        <AppTypography variant='subtitle1'>
                                          Old location
                                        </AppTypography>

                                        {editingDamageItemVisualModelImage && (
                                          <Box>
                                            <ZoomableBlock>
                                              <DamagePointsVisualizer
                                                containerSx={{
                                                  width: `360px`,
                                                  height: `300px`,
                                                  cursor: "crosshair",
                                                }}
                                                pointsInfo={[
                                                  { ...editingDamageItem, isDraggable: false },
                                                ]}
                                                imageUrl={
                                                  editingDamageItemVisualModelImage.file?.url
                                                }
                                              />
                                            </ZoomableBlock>
                                          </Box>
                                        )}
                                      </Stack>

                                      <Stack spacing={1}>
                                        <AppTypography variant='subtitle1'>
                                          New location
                                        </AppTypography>

                                        {editingDamageItemVisualModelImage && (
                                          <Box>
                                            <ZoomableBlock>
                                              <DamagePointsVisualizer
                                                containerSx={{
                                                  width: `360px`,
                                                  height: `300px`,
                                                  cursor: "crosshair",
                                                }}
                                                pointsInfo={[{ ...newItem, isDraggable: true }]}
                                                imageUrl={
                                                  editingDamageItemVisualModelImage.file?.url
                                                }
                                                onNewPointClicked={(pointInfo) => {}}
                                                onPointMoved={(pointInfo) => {
                                                  updateNewItem({
                                                    ...newItem,
                                                    point: pointInfo.point,
                                                  });
                                                }}
                                              />
                                            </ZoomableBlock>
                                          </Box>
                                        )}
                                      </Stack>
                                    </Box>

                                    <AppTextArea
                                      fullWidth
                                      mode='notes'
                                      margin='normal'
                                      onChange={(ev) => {
                                        updateNewItem({
                                          ...newItem,
                                          notes: ev.target.value,
                                        });
                                      }}
                                      type='text'
                                      value={newItem.notes}
                                      variant='outlined'
                                    />

                                    <FormControl fullWidth margin='dense'>
                                      <FileUploader
                                        multiple
                                        defaultFiles={FileItem.createManyFrom(newItem?.attachments)}
                                        onChange={(newFiles) => {
                                          updateNewItem({
                                            ...newItem,
                                            attachments:
                                              FileItem.toManyGeneralAttachmentInputDtoOrDto(
                                                newFiles,
                                              ) || undefined,
                                          });
                                        }}
                                        onUploadStarted={() => {
                                          setIsAttachmentFilesUploading(true);
                                        }}
                                        onUploadFinished={() => {
                                          setIsAttachmentFilesUploading(false);
                                        }}
                                        onValidationStatusChange={(filesValidationStatus) => {
                                          if (filesValidationStatus)
                                            setIsAttachmentFilesHaveErrors(
                                              Object.values(filesValidationStatus).some(
                                                (x) => x === false,
                                              ),
                                            );
                                        }}
                                      />
                                    </FormControl>
                                  </Stack>
                                </AppCardContent>
                              </AppCard>
                            );
                          })}

                          <Stack direction='row' sx={{ justifyContent: "flex-end" }}>
                            <AppButton
                              variant='outlined'
                              color='text'
                              size='small'
                              startIcon={<AppIcon of='add' />}
                              onClick={() => {
                                setDamageItemsToSplitFromSource([
                                  ...(damageItemsToSplitFromSource || []),
                                  createNewSplitTargetItem(),
                                ]);
                              }}
                            >
                              Add target damage
                            </AppButton>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              </Box>
            )}
          </>
        }
      />

      {/* Delete damage modal */}
      <ConfirmationModal
        keepMounted={false}
        title='Delete damage?'
        open={isDeleteModalOpened}
        onClose={() => {
          setIsDeleteModalOpened(false);
          setDamageItemToDelete(null);
        }}
        onCancel={() => setIsDeleteModalOpened(false)}
        onConfirm={() => {
          updateDamageItems(
            damagesRef.current.filter((x) => {
              return x.id !== damageItemToDelete?.id;
            }),
          );

          setIsDeleteModalOpened(false);
          setDamageItemToDelete(null);

          setIsEditModalOpened(false);
          setEditingDamageItem(null);
        }}
        body={
          <Stack spacing={1}>
            <Box>{`You're going to delete the damage.`}</Box>
            {damageItemToDelete?.damage && formInfo.isUpdate && (
              <Box>
                {/* <strong>
                  {`NB: This damage won't be automatically deleted from the vehicle history.`} If
                  you wish to do so, you should do it manually on the damage page{" "}
                  <VehicleDamageLink
                    vehicleId={damageItemToDelete.damage.vehicleId}
                    entity={damageItemToDelete.damage}
                    entityId={undefined}
                  />
                  .
                </strong> */}

                <Alert severity='warning'>
                  <AlertTitle>Keep in mind:</AlertTitle>

                  <Box component='ol' sx={{ m: 0 }}>
                    <li>
                      The corresponding damage{" "}
                      <VehicleDamageLink
                        vehicleId={damageItemToDelete.damage.vehicleId}
                        entity={damageItemToDelete.damage}
                        entityId={undefined}
                      />{" "}
                      will be deleted from the vehicle history after submitting the form.
                    </li>
                    <li>
                      There might be existing operations already referencing the damage and its
                      deletion may cause data inconsistencies. You may need to revise those
                      operations first.
                    </li>
                  </Box>
                </Alert>
              </Box>
            )}
          </Stack>
        }
      />

      <DamagePointInfoModal
        damagePointInfo={viewingPointInfo}
        open={isViewingModalOpened}
        onClose={() => setIsViewingModalOpened(false)}
      />

      <DamagePointsByVisualModelViewerModal
        damages={damageOnOtherVisualModel}
        open={isOtherVisualModelsModalOpen}
        onClose={() => setIsOtherVisualModelsModalOpen(false)}
      />
    </Stack>
  );
};
