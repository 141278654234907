import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AccessoryCheckLink from "@/common/components/Entity/AccessoryCheck/AccessoryCheckLink";
import AssessmentFlowLink from "@/common/components/Entity/AssessmentFlow/AssessmentFlowLink";
import AssetLink from "@/common/components/Entity/Asset/AssetLink";
import AssetSubscriptionLink from "@/common/components/Entity/AssetSubscription/AssetSubscriptionLink";
import AssetSubscriptionPlanLink from "@/common/components/Entity/AssetSubscriptionPlan/AssetSubscriptionPlanLink";
import ContractLink from "@/common/components/Entity/Contract/ContractLink";
import CustomerLink from "@/common/components/Entity/Customer/CustomerLink";
import DamageCostEvaluationLink from "@/common/components/Entity/DamageCostEvaluation/DamageCostEvaluationLink";
import DamageCostEvaluationAggregateLink from "@/common/components/Entity/DamageCostEvaluationAggregate/DamageCostEvaluationAggregateLink";
import DamageDetectionLink from "@/common/components/Entity/DamageDetection/DamageDetectionLink";
import DamageDetectionAggregateLink from "@/common/components/Entity/DamageDetectionAggregate/DamageDetectionAggregateLink";
import DepartmentLink from "@/common/components/Entity/Department/DepartmentLink";
import LocationLink from "@/common/components/Entity/Location/LocationLink";
import PoolLink from "@/common/components/Entity/Pool/PoolLink";
import ProductLocationLink from "@/common/components/Entity/ProductLocation/ProductLocationLink";
import RepairOperationLink from "@/common/components/Entity/RepairOperation/RepairOperationLink";
import SpotLink from "@/common/components/Entity/Spot/SpotLink";
import TenantRequestLink from "@/common/components/Entity/TenantRequest/TenantRequestLink";
import VehicleLink from "@/common/components/Entity/Vehicle/VehicleLink";
import VisualInspectionLink from "@/common/components/Entity/VisualInspection/VisualInspectionLink";
import AppIcon from "@/common/components/Icons/AppIcon";
import InvoiceLink from "@/common/components/Link/InvoiceLink";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { useOnOutsideClick } from "@/common/hooks/useOnOutsideClick";
import { enumService } from "@/common/services/enum";
import { tenantService } from "@/common/services/tenant";
import { apiClient } from "@/core/api/ApiClient";
import { AppPermission, GlobalSearchCategory } from "@/core/api/generated";

import CategoryBlock from "./CategoryBlock";

export default function GlobalSearch() {
  const tenantInfo = tenantService.resolveTenant();
  const { hasPermissions } = useAuthorizationInfo();

  const [searchValue, setSearchValue] = useState("");
  const minSearchLength = 3;
  const [searchCategory, setSearchCategory] = useState<GlobalSearchCategory>(
    GlobalSearchCategory.All,
  );
  const [panelOpened, setPanelOpened] = useState(false);

  const limitPerCategory = 10;
  const initialLimitAndIncreaseStep = 25;
  const [categoryLimit, setCategoryLimit] = useState<number>(initialLimitAndIncreaseStep);

  const request = useApiRequest(
    apiClient.globalSearchApi.apiV1SearchGlobalGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      offset: 0,
      limit: categoryLimit,
      limitPerCategory: limitPerCategory,
      search: searchValue,
      category: searchCategory,
    },
    {
      skip: true,
      deps: [categoryLimit], // if searchCategory added: request faster then limit change
      debouncedDeps: {
        skip: searchValue.length < minSearchLength,
        deps: [categoryLimit, searchValue],
        wait: 500,
        options: { leading: false, trailing: true },
      },
    },
  );

  const searchResult = useMemo(
    () => (searchValue.length < minSearchLength ? null : request?.data),
    [request?.data, searchValue],
  );

  //searchCategory change handle
  useEffect(() => {
    if (searchValue.length >= minSearchLength) {
      setCategoryLimit((prevState) => {
        prevState === initialLimitAndIncreaseStep && request.refetch();
        return initialLimitAndIncreaseStep;
      });
    }
  }, [searchCategory]);

  const closePanel = useCallback(() => setPanelOpened(false), [setPanelOpened]);

  const panelRef = useRef<HTMLElement>(null!);
  useOnOutsideClick(panelRef, () => {
    closePanel();
  });

  const searchCategoryItems = [
    {
      category: GlobalSearchCategory.All,
      name: enumService.getEnumValueName("GlobalSearchCategory", GlobalSearchCategory.All),
    },
    ...(hasPermissions([AppPermission.VehicleRead])
      ? [
          {
            category: GlobalSearchCategory.Vehicles,
            name: enumService.getEnumValueName(
              "GlobalSearchCategory",
              GlobalSearchCategory.Vehicles,
            ),
          },
        ]
      : []),
    ...(hasPermissions([AppPermission.ContractRead])
      ? [
          {
            category: GlobalSearchCategory.Contracts,
            name: enumService.getEnumValueName(
              "GlobalSearchCategory",
              GlobalSearchCategory.Contracts,
            ),
          },
        ]
      : []),
    // NB: temporary disabled
    // ...(hasPermissions([AppPermission.DocumentRead])
    //   ? [
    //       {
    //         category: GlobalSearchCategory.Documents,
    //         name: enumService.getEnumValueName("GlobalSearchCategory", GlobalSearchCategory.Documents),
    //       },
    //     ]
    //   : []),
    ...(hasPermissions([AppPermission.VisualInspectionRead])
      ? [
          {
            category: GlobalSearchCategory.VisualInspections,
            name: enumService.getEnumValueName(
              "GlobalSearchCategory",
              GlobalSearchCategory.VisualInspections,
            ),
          },
        ]
      : []),
    ...(hasPermissions([AppPermission.DamageDetectionRead])
      ? [
          {
            category: GlobalSearchCategory.DamageDetections,
            name: enumService.getEnumValueName(
              "GlobalSearchCategory",
              GlobalSearchCategory.DamageDetections,
            ),
          },
        ]
      : []),
    ...(hasPermissions([AppPermission.DamageDetectionRead])
      ? [
          {
            category: GlobalSearchCategory.DamageDetectionAggregates,
            name: enumService.getEnumValueName(
              "GlobalSearchCategory",
              GlobalSearchCategory.DamageDetectionAggregates,
            ),
          },
        ]
      : []),
    ...(hasPermissions([AppPermission.InvoiceRead])
      ? [
          {
            category: GlobalSearchCategory.Invoices,
            name: enumService.getEnumValueName(
              "GlobalSearchCategory",
              GlobalSearchCategory.Invoices,
            ),
          },
        ]
      : []),
    ...(hasPermissions([AppPermission.AccessoryCheckRead])
      ? [
          {
            category: GlobalSearchCategory.AccessoryChecks,
            name: enumService.getEnumValueName(
              "GlobalSearchCategory",
              GlobalSearchCategory.AccessoryChecks,
            ),
          },
        ]
      : []),
    ...(hasPermissions([AppPermission.AssetRead])
      ? [
          {
            category: GlobalSearchCategory.Assets,
            name: enumService.getEnumValueName("GlobalSearchCategory", GlobalSearchCategory.Assets),
          },
        ]
      : []),
    ...(hasPermissions([AppPermission.CustomerRead])
      ? [
          {
            category: GlobalSearchCategory.Customers,
            name: enumService.getEnumValueName(
              "GlobalSearchCategory",
              GlobalSearchCategory.Customers,
            ),
          },
        ]
      : []),
    ...(hasPermissions([AppPermission.FlowRead])
      ? [
          {
            category: GlobalSearchCategory.AssessmentFlows,
            name: enumService.getEnumValueName(
              "GlobalSearchCategory",
              GlobalSearchCategory.AssessmentFlows,
            ),
          },
        ]
      : []),
    ...(hasPermissions([AppPermission.DamageCostEvaluationRead])
      ? [
          {
            category: GlobalSearchCategory.DamageCostEvaluations,
            name: enumService.getEnumValueName(
              "GlobalSearchCategory",
              GlobalSearchCategory.DamageCostEvaluations,
            ),
          },
        ]
      : []),
    ...(hasPermissions([AppPermission.DamageCostEvaluationRead])
      ? [
          {
            category: GlobalSearchCategory.DamageCostEvaluationAggregates,
            name: enumService.getEnumValueName(
              "GlobalSearchCategory",
              GlobalSearchCategory.DamageCostEvaluationAggregates,
            ),
          },
        ]
      : []),
    ...(hasPermissions([AppPermission.RepairOperationRead])
      ? [
          {
            category: GlobalSearchCategory.RepairOperations,
            name: enumService.getEnumValueName(
              "GlobalSearchCategory",
              GlobalSearchCategory.RepairOperations,
            ),
          },
        ]
      : []),
    ...(hasPermissions([AppPermission.DepartmentRead])
      ? [
          {
            category: GlobalSearchCategory.Departments,
            name: enumService.getEnumValueName(
              "GlobalSearchCategory",
              GlobalSearchCategory.Departments,
            ),
          },
        ]
      : []),
    ...(hasPermissions([AppPermission.LocationRead])
      ? [
          {
            category: GlobalSearchCategory.Locations,
            name: enumService.getEnumValueName(
              "GlobalSearchCategory",
              GlobalSearchCategory.Locations,
            ),
          },
        ]
      : []),
    ...(hasPermissions([AppPermission.SpotRead])
      ? [
          {
            category: GlobalSearchCategory.Spots,
            name: enumService.getEnumValueName("GlobalSearchCategory", GlobalSearchCategory.Spots),
          },
        ]
      : []),
    ...(hasPermissions([AppPermission.ProductLocationRead])
      ? [
          {
            category: GlobalSearchCategory.ProductLocations,
            name: enumService.getEnumValueName(
              "GlobalSearchCategory",
              GlobalSearchCategory.ProductLocations,
            ),
          },
        ]
      : []),
    ...(hasPermissions([AppPermission.PoolRead])
      ? [
          {
            category: GlobalSearchCategory.Pools,
            name: enumService.getEnumValueName("GlobalSearchCategory", GlobalSearchCategory.Pools),
          },
        ]
      : []),
    ...(hasPermissions([AppPermission.AssetSubscriptionPlanRead])
      ? [
          {
            category: GlobalSearchCategory.AssetSubscriptionPlans,
            name: enumService.getEnumValueName(
              "GlobalSearchCategory",
              GlobalSearchCategory.AssetSubscriptionPlans,
            ),
          },
        ]
      : []),
    ...(hasPermissions([AppPermission.AssetSubscriptionRead])
      ? [
          {
            category: GlobalSearchCategory.AssetSubscriptions,
            name: enumService.getEnumValueName(
              "GlobalSearchCategory",
              GlobalSearchCategory.AssetSubscriptions,
            ),
          },
        ]
      : []),
    ...(hasPermissions([AppPermission.TenantRequestRead])
      ? [
          {
            category: GlobalSearchCategory.TenantRequests,
            name: enumService.getEnumValueName(
              "GlobalSearchCategory",
              GlobalSearchCategory.TenantRequests,
            ),
          },
        ]
      : []),
  ];

  const selectResCountInfoByCategory = (category: GlobalSearchCategory) => {
    if (!searchResult) {
      return {
        count: 0,
        totalCount: 0,
      };
    }
    switch (category) {
      case GlobalSearchCategory.All:
        return {
          count: searchResult.totalReturnedCount || 0,
          totalCount: searchResult.totalReturnedCount || 0,
        };
      case GlobalSearchCategory.Vehicles:
        return {
          count: searchResult.vehicles?.items?.length || 0,
          totalCount: searchResult.vehicles?.pagination?.totalCount || 0,
        };
      case GlobalSearchCategory.Contracts:
        return {
          count: searchResult.contracts?.items?.length || 0,
          totalCount: searchResult.contracts?.pagination?.totalCount || 0,
        };
      case GlobalSearchCategory.Documents:
        return {
          count: searchResult.documents?.items?.length || 0,
          totalCount: searchResult.documents?.pagination?.totalCount || 0,
        };
      case GlobalSearchCategory.VisualInspections:
        return {
          count: searchResult.visualInspections?.items?.length || 0,
          totalCount: searchResult.visualInspections?.pagination?.totalCount || 0,
        };
      case GlobalSearchCategory.DamageDetections:
        return {
          count: searchResult.damageDetections?.items?.length || 0,
          totalCount: searchResult.damageDetections?.pagination?.totalCount || 0,
        };
      case GlobalSearchCategory.DamageDetectionAggregates:
        return {
          count: searchResult.damageDetectionAggregates?.items?.length || 0,
          totalCount: searchResult.damageDetectionAggregates?.pagination?.totalCount || 0,
        };
      case GlobalSearchCategory.Invoices:
        return {
          count: searchResult.invoices?.items?.length || 0,
          totalCount: searchResult.invoices?.pagination?.totalCount || 0,
        };
      case GlobalSearchCategory.AccessoryChecks:
        return {
          count: searchResult.accessoryChecks?.items?.length || 0,
          totalCount: searchResult.accessoryChecks?.pagination?.totalCount || 0,
        };
      case GlobalSearchCategory.Assets:
        return {
          count: searchResult.assets?.items?.length || 0,
          totalCount: searchResult.assets?.pagination?.totalCount || 0,
        };
      case GlobalSearchCategory.Customers:
        return {
          count: searchResult.customers?.items?.length || 0,
          totalCount: searchResult.customers?.pagination?.totalCount || 0,
        };
      case GlobalSearchCategory.AssessmentFlows:
        return {
          count: searchResult.assessmentFlows?.items?.length || 0,
          totalCount: searchResult.assessmentFlows?.pagination?.totalCount || 0,
        };
      case GlobalSearchCategory.DamageCostEvaluations:
        return {
          count: searchResult.damageCostEvaluations?.items?.length || 0,
          totalCount: searchResult.damageCostEvaluations?.pagination?.totalCount || 0,
        };
      case GlobalSearchCategory.DamageCostEvaluationAggregates:
        return {
          count: searchResult.damageCostEvaluationAggregates?.items?.length || 0,
          totalCount: searchResult.damageCostEvaluationAggregates?.pagination?.totalCount || 0,
        };
      case GlobalSearchCategory.RepairOperations:
        return {
          count: searchResult.repairOperations?.items?.length || 0,
          totalCount: searchResult.repairOperations?.pagination?.totalCount || 0,
        };
      case GlobalSearchCategory.Departments:
        return {
          count: searchResult.departments?.items?.length || 0,
          totalCount: searchResult.departments?.pagination?.totalCount || 0,
        };
      case GlobalSearchCategory.Locations:
        return {
          count: searchResult.locations?.items?.length || 0,
          totalCount: searchResult.locations?.pagination?.totalCount || 0,
        };
      case GlobalSearchCategory.Spots:
        return {
          count: searchResult.spots?.items?.length || 0,
          totalCount: searchResult.spots?.pagination?.totalCount || 0,
        };
      case GlobalSearchCategory.ProductLocations:
        return {
          count: searchResult.productLocations?.items?.length || 0,
          totalCount: searchResult.productLocations?.pagination?.totalCount || 0,
        };
      case GlobalSearchCategory.Pools:
        return {
          count: searchResult.pools?.items?.length || 0,
          totalCount: searchResult.pools?.pagination?.totalCount || 0,
        };
      case GlobalSearchCategory.AssetSubscriptionPlans:
        return {
          count: searchResult.assetSubscriptionPlans?.items?.length || 0,
          totalCount: searchResult.assetSubscriptionPlans?.pagination?.totalCount || 0,
        };
      case GlobalSearchCategory.AssetSubscriptions:
        return {
          count: searchResult.assetSubscriptions?.items?.length || 0,
          totalCount: searchResult.assetSubscriptions?.pagination?.totalCount || 0,
        };
      case GlobalSearchCategory.TenantRequests:
        return {
          count: searchResult.tenantRequests?.items?.length || 0,
          totalCount: searchResult.tenantRequests?.pagination?.totalCount || 0,
        };
      default:
        return {
          count: 0,
          totalCount: 0,
        };
    }
  };

  const shouldShowCategoryResult = (category: GlobalSearchCategory) => {
    return searchCategory === GlobalSearchCategory.All ? true : searchCategory === category;
  };

  if (!tenantInfo) {
    return null;
  }

  const handleLoadMore = (category?: GlobalSearchCategory) => {
    if (!request.isLoading) {
      if (searchCategory === GlobalSearchCategory.All) setSearchCategory(category!);
      else setCategoryLimit(categoryLimit + initialLimitAndIncreaseStep);
    }
  };

  const loadMoreButton = (category: GlobalSearchCategory) => {
    const currentLimit =
      searchCategory === GlobalSearchCategory.All ? limitPerCategory : categoryLimit;
    const { totalCount, count } = selectResCountInfoByCategory(category) || {};
    const remainingCount = totalCount ? totalCount - count : 0;

    return (
      currentLimit < (totalCount || 0) && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant='text' sx={{ pl: 2 }} onClick={() => handleLoadMore(category)}>
            More ({remainingCount})
          </Button>
        </Box>
      )
    );
  };

  return (
    <Box
      ref={panelRef}
      sx={{
        position: panelOpened ? { xs: "absolute", sm: "relative" } : "relative",
        top: 0,
        left: 0,
        zIndex: (th) => (panelOpened ? th.zIndex.modal : "auto"),
        width: "100%",
        ml: !panelOpened ? "auto" : "",
        backgroundColor: "background.default",
        borderRadius: (th) => th.shapeCustom.borderRadiusCard,
      }}
    >
      <TextField
        fullWidth={panelOpened}
        size='small'
        placeholder={`Search... (type at least ${minSearchLength} symbols)`}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        InputLabelProps={{ disabled: !panelOpened }}
        InputProps={{
          startAdornment: !request.isLoading ? (
            <SearchIcon sx={{ width: "25px", height: "25px", pr: 1 }} />
          ) : (
            <CircularProgress
              variant='indeterminate'
              sx={{ animation: "none", width: "25px !important", height: "25px !important", pr: 1 }}
            />
          ),
          endAdornment: panelOpened ? (
            <IconButton
              size='small'
              onClick={() => {
                setPanelOpened(false);
                setSearchValue("");
                setSearchCategory(GlobalSearchCategory.All);
              }}
            >
              <AppIcon of='close' />
            </IconButton>
          ) : null,
        }}
        sx={{
          mb: 0,
          width: "100%",
        }}
        onFocus={() => setPanelOpened(true)}
      />

      {panelOpened && (
        <Box
          sx={{
            position: "absolute",
            left: { xs: 0, sm: "unset" },
            height: "auto",
            maxHeight: "70vh",
            width: "100%",
            overflowY: "auto",
            backgroundColor: "background.default",
            border: (theme) => `2px solid ${theme.palette.background.gray}`,
            borderRadius: (th) => th.shapeCustom.borderRadiusCard,
            boxShadow: "2px 2px 5px 0px rgba(0, 0, 0, 0.2)",
            zIndex: 100,
            p: 2,
            pt: 0,
          }}
        >
          {/* Categories */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              position: "sticky",
              top: 0,
              zIndex: 1,
              backgroundColor: "background.default",
              py: 1,
              borderRadius: (th) => th.shape,
            }}
          >
            {searchCategoryItems.map((item) => (
              <Button
                key={item.category}
                sx={{ m: 0.2, fontWeight: searchCategory === item.category ? "bold" : "normal" }}
                size='small'
                onClick={() => setSearchCategory(item.category)}
              >
                {item.name}
                {searchResult ? `(${selectResCountInfoByCategory(item.category).totalCount})` : ""}
              </Button>
            ))}
          </Box>

          {!searchResult && request.isLoading && (
            <Stack spacing={1}>
              {Array.from({ length: 3 }).map((x, i) => (
                <Stack spacing={0.5} key={i}>
                  <Skeleton variant='rectangular' width={"25%"} height={14} />
                  <Skeleton variant='rectangular' width={"100%"} height={40} />
                </Stack>
              ))}
            </Stack>
          )}

          {searchResult && (
            <Stack direction='column' spacing={2} sx={{ p: 1 }}>
              <AuthorizedElement permissions={[AppPermission.VehicleRead]}>
                {searchResult?.vehicles &&
                  searchResult?.vehicles?.items?.length !== 0 &&
                  shouldShowCategoryResult(GlobalSearchCategory.Vehicles) && (
                    <CategoryBlock
                      actionAsItem={loadMoreButton(GlobalSearchCategory.Vehicles) || undefined}
                      name='Vehicles'
                      items={searchResult?.vehicles?.items || []}
                    >
                      {(item) => (
                        <VehicleLink
                          entity={item}
                          withIcon
                          withTooltip={false}
                          onClick={closePanel}
                        />
                      )}
                    </CategoryBlock>
                  )}
              </AuthorizedElement>

              <AuthorizedElement permissions={[AppPermission.ContractRead]}>
                {searchResult?.contracts &&
                  searchResult?.contracts?.items?.length !== 0 &&
                  shouldShowCategoryResult(GlobalSearchCategory.Contracts) && (
                    <CategoryBlock
                      actionAsItem={loadMoreButton(GlobalSearchCategory.Contracts) || undefined}
                      name='Contracts'
                      items={searchResult?.contracts?.items || []}
                    >
                      {(item) => (
                        <ContractLink
                          entity={item}
                          withIcon
                          withTooltip={false}
                          onClick={closePanel}
                        />
                      )}
                    </CategoryBlock>
                  )}
              </AuthorizedElement>

              {/* NB: temporary disabled */}
              {/* <AuthorizedElement permissions={[AppPermission.DocumentRead]}>
                {searchResult?.documents  &&
                  searchResult?.documents?.items?.length !== 0 &&
                  shouldShowCategoryResult(GlobalSearchCategory.Documents) && (
                    <CategoryBlock
                      actionAsItem={loadMoreButton(GlobalSearchCategory.Documents) || undefined}
                      name='Documents'
                      items={searchResult?.documents?.items || []}
                    >
                      {(item) => (
                        <DocumentLink
                          document={item}
                          withIcon
                          withTooltip={false}
                          onClick={closePanel}
                        />
                      )}
                    </CategoryBlock>
                  )}
              </AuthorizedElement> */}

              <AuthorizedElement permissions={[AppPermission.VisualInspectionRead]}>
                {searchResult?.visualInspections &&
                  searchResult?.visualInspections?.items?.length !== 0 &&
                  shouldShowCategoryResult(GlobalSearchCategory.VisualInspections) && (
                    <CategoryBlock
                      actionAsItem={
                        loadMoreButton(GlobalSearchCategory.VisualInspections) || undefined
                      }
                      name='Visual inspections'
                      items={searchResult?.visualInspections?.items || []}
                    >
                      {(item) => (
                        <VisualInspectionLink
                          entity={item}
                          withIcon
                          withTooltip={false}
                          onClick={closePanel}
                        />
                      )}
                    </CategoryBlock>
                  )}
              </AuthorizedElement>

              <AuthorizedElement permissions={[AppPermission.DamageDetectionRead]}>
                {searchResult?.damageDetections &&
                  searchResult?.damageDetections?.items?.length !== 0 &&
                  shouldShowCategoryResult(GlobalSearchCategory.DamageDetections) && (
                    <CategoryBlock
                      actionAsItem={
                        loadMoreButton(GlobalSearchCategory.DamageDetections) || undefined
                      }
                      name='Damage detections'
                      items={searchResult?.damageDetections?.items || []}
                    >
                      {(item) => (
                        <DamageDetectionLink
                          entity={item}
                          withIcon
                          withTooltip={false}
                          onClick={closePanel}
                        />
                      )}
                    </CategoryBlock>
                  )}
              </AuthorizedElement>

              <AuthorizedElement permissions={[AppPermission.DamageDetectionRead]}>
                {searchResult?.damageDetectionAggregates &&
                  searchResult?.damageDetectionAggregates?.items?.length !== 0 &&
                  shouldShowCategoryResult(GlobalSearchCategory.DamageDetectionAggregates) && (
                    <CategoryBlock
                      actionAsItem={
                        loadMoreButton(GlobalSearchCategory.DamageDetectionAggregates) || undefined
                      }
                      name='Damage detections (aggregates)'
                      items={searchResult?.damageDetectionAggregates?.items || []}
                    >
                      {(item) => (
                        <DamageDetectionAggregateLink
                          entity={item}
                          withIcon
                          withTooltip={false}
                          onClick={closePanel}
                        />
                      )}
                    </CategoryBlock>
                  )}
              </AuthorizedElement>

              <AuthorizedElement permissions={[AppPermission.InvoiceRead]}>
                {searchResult?.invoices &&
                  searchResult?.invoices?.items?.length !== 0 &&
                  shouldShowCategoryResult(GlobalSearchCategory.Invoices) && (
                    <CategoryBlock
                      actionAsItem={loadMoreButton(GlobalSearchCategory.Invoices) || undefined}
                      name='Invoices'
                      items={searchResult?.invoices?.items || []}
                    >
                      {(item) => (
                        <InvoiceLink
                          invoice={item}
                          withIcon
                          withTooltip={false}
                          onClick={closePanel}
                        />
                      )}
                    </CategoryBlock>
                  )}
              </AuthorizedElement>

              <AuthorizedElement permissions={[AppPermission.AccessoryCheckRead]}>
                {searchResult?.accessoryChecks &&
                  searchResult?.accessoryChecks?.items?.length !== 0 &&
                  shouldShowCategoryResult(GlobalSearchCategory.AccessoryChecks) && (
                    <CategoryBlock
                      actionAsItem={
                        loadMoreButton(GlobalSearchCategory.AccessoryChecks) || undefined
                      }
                      name='Accessory checks'
                      items={searchResult?.accessoryChecks?.items || []}
                    >
                      {(item) => (
                        <AccessoryCheckLink
                          entity={item}
                          withIcon
                          withTooltip={false}
                          onClick={closePanel}
                        />
                      )}
                    </CategoryBlock>
                  )}
              </AuthorizedElement>

              <AuthorizedElement permissions={[AppPermission.AssetRead]}>
                {searchResult?.assets &&
                  searchResult?.assets?.items?.length !== 0 &&
                  shouldShowCategoryResult(GlobalSearchCategory.Assets) && (
                    <CategoryBlock
                      actionAsItem={loadMoreButton(GlobalSearchCategory.Assets) || undefined}
                      name='Assets'
                      items={searchResult?.assets?.items || []}
                    >
                      {(item) => (
                        <AssetLink
                          entity={item}
                          withIcon
                          withTooltip={false}
                          onClick={closePanel}
                        />
                      )}
                    </CategoryBlock>
                  )}
              </AuthorizedElement>

              <AuthorizedElement permissions={[AppPermission.CustomerRead]}>
                {searchResult?.customers &&
                  searchResult?.customers?.items?.length !== 0 &&
                  shouldShowCategoryResult(GlobalSearchCategory.Customers) && (
                    <CategoryBlock
                      actionAsItem={loadMoreButton(GlobalSearchCategory.Customers) || undefined}
                      name='Customers'
                      items={searchResult?.customers?.items || []}
                    >
                      {(item) => (
                        <CustomerLink
                          entity={item}
                          withIcon
                          withTooltip={false}
                          onClick={closePanel}
                        />
                      )}
                    </CategoryBlock>
                  )}
              </AuthorizedElement>

              <AuthorizedElement permissions={[AppPermission.FlowRead]}>
                {searchResult?.assessmentFlows &&
                  searchResult?.assessmentFlows?.items?.length !== 0 &&
                  shouldShowCategoryResult(GlobalSearchCategory.AssessmentFlows) && (
                    <CategoryBlock
                      actionAsItem={
                        loadMoreButton(GlobalSearchCategory.AssessmentFlows) || undefined
                      }
                      name='Assessments'
                      items={searchResult?.assessmentFlows?.items || []}
                    >
                      {(item) => (
                        <AssessmentFlowLink
                          entity={item}
                          withIcon
                          withTooltip={false}
                          onClick={closePanel}
                        />
                      )}
                    </CategoryBlock>
                  )}
              </AuthorizedElement>

              <AuthorizedElement permissions={[AppPermission.DamageCostEvaluationRead]}>
                {searchResult?.damageCostEvaluations &&
                  searchResult?.damageCostEvaluations?.items?.length !== 0 &&
                  shouldShowCategoryResult(GlobalSearchCategory.DamageCostEvaluations) && (
                    <CategoryBlock
                      actionAsItem={
                        loadMoreButton(GlobalSearchCategory.DamageCostEvaluations) || undefined
                      }
                      name='Damage cost evaluations'
                      items={searchResult?.damageCostEvaluations?.items || []}
                    >
                      {(item) => (
                        <DamageCostEvaluationLink
                          entity={item}
                          withIcon
                          withTooltip={false}
                          onClick={closePanel}
                        />
                      )}
                    </CategoryBlock>
                  )}
              </AuthorizedElement>

              <AuthorizedElement permissions={[AppPermission.DamageCostEvaluationRead]}>
                {searchResult?.damageCostEvaluationAggregates &&
                  searchResult?.damageCostEvaluationAggregates?.items?.length !== 0 &&
                  shouldShowCategoryResult(GlobalSearchCategory.DamageCostEvaluationAggregates) && (
                    <CategoryBlock
                      actionAsItem={
                        loadMoreButton(GlobalSearchCategory.DamageCostEvaluationAggregates) ||
                        undefined
                      }
                      name='Damage cost evaluation (aggregates)'
                      items={searchResult?.damageCostEvaluationAggregates?.items || []}
                    >
                      {(item) => (
                        <DamageCostEvaluationAggregateLink
                          entity={item}
                          withIcon
                          withTooltip={false}
                          onClick={closePanel}
                        />
                      )}
                    </CategoryBlock>
                  )}
              </AuthorizedElement>

              <AuthorizedElement permissions={[AppPermission.RepairOperationRead]}>
                {searchResult?.repairOperations &&
                  searchResult?.repairOperations?.items?.length !== 0 &&
                  shouldShowCategoryResult(GlobalSearchCategory.RepairOperations) && (
                    <CategoryBlock
                      actionAsItem={
                        loadMoreButton(GlobalSearchCategory.RepairOperations) || undefined
                      }
                      name='Repair operations'
                      items={searchResult?.repairOperations?.items || []}
                    >
                      {(item) => (
                        <RepairOperationLink
                          entity={item}
                          withIcon
                          withTooltip={false}
                          onClick={closePanel}
                        />
                      )}
                    </CategoryBlock>
                  )}
              </AuthorizedElement>

              <AuthorizedElement permissions={[AppPermission.DepartmentRead]}>
                {searchResult?.departments &&
                  searchResult?.departments?.items?.length !== 0 &&
                  shouldShowCategoryResult(GlobalSearchCategory.Departments) && (
                    <CategoryBlock
                      actionAsItem={loadMoreButton(GlobalSearchCategory.Departments) || undefined}
                      name='Departments'
                      items={searchResult?.departments?.items || []}
                    >
                      {(item) => (
                        <DepartmentLink
                          entity={item}
                          withIcon
                          withTooltip={false}
                          onClick={closePanel}
                        />
                      )}
                    </CategoryBlock>
                  )}
              </AuthorizedElement>

              <AuthorizedElement permissions={[AppPermission.LocationRead]}>
                {searchResult?.locations &&
                  searchResult?.locations?.items?.length !== 0 &&
                  shouldShowCategoryResult(GlobalSearchCategory.Locations) && (
                    <CategoryBlock
                      actionAsItem={loadMoreButton(GlobalSearchCategory.Locations) || undefined}
                      name='Locations'
                      items={searchResult?.locations?.items || []}
                    >
                      {(item) => (
                        <LocationLink
                          entity={item}
                          withIcon
                          withTooltip={false}
                          onClick={closePanel}
                        />
                      )}
                    </CategoryBlock>
                  )}
              </AuthorizedElement>

              <AuthorizedElement permissions={[AppPermission.SpotRead]}>
                {searchResult?.spots &&
                  searchResult?.spots?.items?.length !== 0 &&
                  shouldShowCategoryResult(GlobalSearchCategory.Spots) && (
                    <CategoryBlock
                      actionAsItem={loadMoreButton(GlobalSearchCategory.Spots) || undefined}
                      name='Spots'
                      items={searchResult?.spots?.items || []}
                    >
                      {(item) => (
                        <SpotLink entity={item} withIcon withTooltip={false} onClick={closePanel} />
                      )}
                    </CategoryBlock>
                  )}
              </AuthorizedElement>

              <AuthorizedElement permissions={[AppPermission.ProductLocationRead]}>
                {searchResult?.productLocations &&
                  searchResult?.productLocations?.items?.length !== 0 &&
                  shouldShowCategoryResult(GlobalSearchCategory.ProductLocations) && (
                    <CategoryBlock
                      actionAsItem={
                        loadMoreButton(GlobalSearchCategory.ProductLocations) || undefined
                      }
                      name='Sales locations'
                      items={searchResult?.productLocations?.items || []}
                    >
                      {(item) => (
                        <ProductLocationLink
                          entity={item}
                          withIcon
                          withTooltip={false}
                          onClick={closePanel}
                        />
                      )}
                    </CategoryBlock>
                  )}
              </AuthorizedElement>

              <AuthorizedElement permissions={[AppPermission.PoolRead]}>
                {searchResult?.pools &&
                  searchResult?.pools?.items?.length !== 0 &&
                  shouldShowCategoryResult(GlobalSearchCategory.Pools) && (
                    <CategoryBlock
                      actionAsItem={loadMoreButton(GlobalSearchCategory.Pools) || undefined}
                      name='Pools'
                      items={searchResult?.pools?.items || []}
                    >
                      {(item) => (
                        <PoolLink entity={item} withIcon withTooltip={false} onClick={closePanel} />
                      )}
                    </CategoryBlock>
                  )}
              </AuthorizedElement>

              <AuthorizedElement permissions={[AppPermission.AssetSubscriptionPlanRead]}>
                {searchResult?.assetSubscriptionPlans &&
                  searchResult?.assetSubscriptionPlans?.items?.length !== 0 &&
                  shouldShowCategoryResult(GlobalSearchCategory.AssetSubscriptionPlans) && (
                    <CategoryBlock
                      actionAsItem={
                        loadMoreButton(GlobalSearchCategory.AssetSubscriptionPlans) || undefined
                      }
                      name='Asset subscription plans'
                      items={searchResult?.assetSubscriptionPlans?.items || []}
                    >
                      {(item) => (
                        <AssetSubscriptionPlanLink
                          entity={item}
                          withIcon
                          withTooltip={false}
                          onClick={closePanel}
                        />
                      )}
                    </CategoryBlock>
                  )}
              </AuthorizedElement>

              <AuthorizedElement permissions={[AppPermission.AssetSubscriptionRead]}>
                {searchResult?.assetSubscriptions &&
                  searchResult?.assetSubscriptions?.items?.length !== 0 &&
                  shouldShowCategoryResult(GlobalSearchCategory.AssetSubscriptions) && (
                    <CategoryBlock
                      actionAsItem={
                        loadMoreButton(GlobalSearchCategory.AssetSubscriptions) || undefined
                      }
                      name='Asset subscriptions'
                      items={searchResult?.assetSubscriptions?.items || []}
                    >
                      {(item) => (
                        <AssetSubscriptionLink
                          entity={item}
                          withIcon
                          withTooltip={false}
                          onClick={closePanel}
                        />
                      )}
                    </CategoryBlock>
                  )}
              </AuthorizedElement>

              <AuthorizedElement permissions={[AppPermission.TenantRequestRead]}>
                {searchResult?.tenantRequests &&
                  searchResult?.tenantRequests?.items?.length !== 0 &&
                  shouldShowCategoryResult(GlobalSearchCategory.TenantRequests) && (
                    <CategoryBlock
                      actionAsItem={
                        loadMoreButton(GlobalSearchCategory.TenantRequests) || undefined
                      }
                      name='Company requests'
                      items={searchResult?.tenantRequests?.items || []}
                    >
                      {(item) => (
                        <TenantRequestLink
                          entity={item}
                          withIcon
                          withTooltip={false}
                          onClick={closePanel}
                        />
                      )}
                    </CategoryBlock>
                  )}
              </AuthorizedElement>

              {/* {!shouldShowCategoryResult(GlobalSearchCategory.All) && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button onClick={handleLoadMore}>More</Button>
                </Box>
              )} */}
            </Stack>
          )}
          {!searchResult && !request.isLoading && (
            <NoDataAlert
              title='Type at least 3 symbols to start searching across the system.'
              description='You can select a specific search category to get more accurate results.'
            />
          )}
        </Box>
      )}
    </Box>
  );
}
