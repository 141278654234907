import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import MaintenanceCreateUpdate from "@/common/components/Entity/Maintenance/MaintenanceCreateUpdate";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";

export interface MaintenanceCreateUpdateQueryParams extends GeneralQueryParams {
  vehicleId?: string | null;
}

export default function MaintenanceCreateUpdatePage() {
  const { maintenanceId } = useParams<{ maintenanceId?: string }>();
  const { vehicleId } = useQueryParams<MaintenanceCreateUpdateQueryParams>();
  const history = useHistory();
  const isCreate = !maintenanceId;
  // const isEdit = !!maintenanceId;

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader title={isCreate ? "Create new maintenance" : "Edit maintenance"} />
      }
    >
      <MaintenanceCreateUpdate
        maintenanceId={maintenanceId}
        defaultValues={{
          vehicleId: vehicleId || undefined,
        }}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.MAINTENANCE_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}
