import { Button, IconButton, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { useCallback, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import { ROUTE_PATH } from "@/common/constants/routing";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  RoleDto,
} from "@/core/api/generated";
import * as tenantAccountSlice from "@/store/management/tenantAccount/slice";

const defaultDisplayProps = {
  viewVariant: ViewLayoutVariant.Page,
};
interface Props {
  displayProps?: Partial<typeof defaultDisplayProps>;
}

export default function Roles({ displayProps }: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const { enqueueSnackbar } = useAppSnackbar();
  const thunkDispatch = useAppThunkDispatch();
  const currentTenant = useCurrentTenant();

  const [roleToDelete, setRoleToDelete] = useState<RoleDto | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const paginatedRolesRequest = useApiRequest(
    apiClient.tenantAccountApi.apiV1TenantAccountRolesGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
    {
      deps: [],
    },
  );

  const paginatedRoles = paginatedRolesRequest.data;

  useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.Role)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedRolesRequest.handleEntityChanged(data);
    },
  });
  const handleDeleteConfirmed = useCallback(async () => {
    if (!roleToDelete) {
      return;
    }
    try {
      await thunkDispatch(
        tenantAccountSlice.deleteTenantRole({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          roleId: roleToDelete.id!,
        }),
      );
      enqueueSnackbar("Item was successfully deleted.", { variant: "success" });
      paginatedRolesRequest.refetch();
      setIsDeleteModalOpen(false);
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [roleToDelete]);

  return (
    <ViewLayoutV2
      displayProps={displayProps}
      header={
        <SimpleViewPageHeader
          title='Roles'
          primaryActions={
            <AuthorizedElement permissions={[AppPermission.TenantRoleManage]}>
              <Button
                variant='contained'
                color='primary'
                startIcon={<AppIcon of='add' />}
                component={RouterLink}
                to={ROUTE_PATH.TENANT_ROLE_CREATE}
              >
                Create new role
              </Button>
            </AuthorizedElement>
          }
        />
      }
    >
      <DataTabular
        columns={[
          {
            field: getTypedPath<RoleDto>().name.$path,
            title: "Name",
            width: 300,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.name,
          },
          {
            field: getTypedPath<RoleDto>().description.$path,
            title: "Description",
            width: 300,
            isHideable: true,
            isSortable: false,
            isFilterable: true,

            renderCell: (item) => item.description,
          },
          {
            field: getTypedPath<RoleDto>().isBuiltIn.$path,
            title: "Built-in",
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => <BooleanValue value={item.isBuiltIn} />,
          },
          {
            field: "permissions",
            title: "Permissions",
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => <>{item.permissions!.length}</>,
          },
        ]}
        rows={paginatedRoles}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.TENANT_ROLE_VIEW(item.id)}
        renderRowAction={({ item }) => (
          <MenuWithTrigger
            trigger={
              <IconButton>
                <AppIcon of='moreVert' />
              </IconButton>
            }
          >
            {({ handleClose }) => [
              <AuthorizedElement key={0} permissions={[AppPermission.TenantRoleManage]}>
                <MenuItem
                  disabled={item.isBuiltIn}
                  component={RouterLink}
                  to={ROUTE_PATH.TENANT_ROLE_EDIT(item.id)}
                >
                  <ListItemIcon>
                    <AppIcon of='edit' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Edit</ListItemText>
                </MenuItem>
              </AuthorizedElement>,
              <AuthorizedElement key={1} permissions={[AppPermission.TenantRoleManage]}>
                <MenuItem
                  disabled={item.isBuiltIn}
                  onClick={() => {
                    setRoleToDelete(item);
                    setIsDeleteModalOpen(true);
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <AppIcon of='delete' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Delete</ListItemText>
                </MenuItem>
              </AuthorizedElement>,
            ]}
          </MenuWithTrigger>
        )}
        isLoading={paginatedRolesRequest.isLoading}
      />

      {/* Delete confirmation */}
      {roleToDelete && (
        <ConfirmationModal
          title='Delete the accessory?'
          body={
            <>
              {`You're going to delete the accessory`} <strong>{roleToDelete.name}</strong>.{" "}
              {`This action can't be undone.`}
            </>
          }
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onCancel={() => setIsDeleteModalOpen(false)}
          onConfirm={handleDeleteConfirmed}
        />
      )}
    </ViewLayoutV2>
  );
}
