import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import CreateUpdateRepairWork from "@/common/components/Entity/RepairWork/CreateUpdateRepairWork";
import { ROUTE_PATH } from "@/common/constants/routing";

export default function CreateUpdateRepairWorkPage() {
  const { repairWorkId } = useParams<{ repairWorkId?: string }>();
  const isCreate = !repairWorkId;
  const history = useHistory();

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader title={isCreate ? "Create new repair work" : "Edit repair work"} />
      }
    >
      <CreateUpdateRepairWork
        repairWorkId={repairWorkId}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.REPAIR_WORK_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}
