import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import {
  AppPermission,
  AssetSubscriptionPlanDto,
  AssetSubscriptionPlanReferenceDto,
} from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import AssetSubscriptionPlanInline, {
  AssetSubscriptionPlanInlineProps,
} from "./AssetSubscriptionPlanInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity?: AssetSubscriptionPlanDto | AssetSubscriptionPlanReferenceDto;
  entityId?: string | null;
  inlineProps?: Partial<AssetSubscriptionPlanInlineProps>;
}

export default function AssetSubscriptionPlanLink({
  entity,
  entityId,
  inlineProps,
  ...otherProps
}: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  if (!entity && !entityId) {
    return null;
  }

  // const full = entity as AssetSubscriptionPlanDto;
  // const reference = entity as AssetSubscriptionPlanReferenceDto;

  return (
    <BaseEntityLink
      enabled={hasPermissions([
        AppPermission.FleetAppAccess,
        AppPermission.AssetSubscriptionPlanRead,
      ])}
      to={ROUTE_PATH.ASSET_SUBSCRIPTION_PLAN_VIEW(entity?.id || "")}
      icon={undefined}
      content={
        <AssetSubscriptionPlanInline
          entity={entity}
          entityId={entityId}
          {...inlineProps}
          {...otherProps}
        />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Asset"}
    />
  );
}
