import {
  Box,
  Button,
  Grid2,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
} from "@mui/material";
import { useCallback, useState } from "react";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import ViewContentBlockHeader from "@/App/Layouts/ViewContentBlockHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import AppTooltip from "@/common/components/AppTooltip";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIconButton from "@/common/components/Button/AppIconButton";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import GeneralStageHistory from "@/common/components/GeneralStageHistory";
import AllocationStatusIcon from "@/common/components/Icons/AllocationStatusIcon";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import BaseEditNotesModal from "@/common/components/Modals/common/BaseEditNotesModal";
import { DatetimeHelper } from "@/common/helpers/datetime";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  AssetSubscriptionDto,
  ContractDto,
  ContractStage,
  ContractType,
  EntityType,
} from "@/core/api/generated";

import AccessoryGrid from "../../../Accessory/AccessoryGrid";
import AssetSubscriptionLink from "../../../AssetSubscription/AssetSubscriptionLink";
import CustomerLink from "../../../Customer/CustomerLink";
import AffiliationInfoDisplay from "../../../EntityAffiliation/AffiliationInfoDisplay";
import UpdateEntityAffiliationCascadeModal from "../../../EntityAffiliation/UpdateEntityAffiliationCascadeModal";
import VehicleLink from "../../../Vehicle/VehicleLink";
import ContractCustomerCommunicationInfo from "../../ContractCustomerCommunicationInfo";
import ContractCustomerCommunicationInfoModal from "../../ContractCustomerCommunicationInfoModal";
import ContractReminderSettingsDisplay from "../../ContractReminderSettingsDisplay";
import ContractSettingsDisplay from "../../ContractSettingsDisplay";
import ContractSpotInfoDisplay from "../../ContractSpotInfoDisplay";
import ContractUpdateSpotInfoModal from "../../ContractUpdateSpotInfoModal";
import CreateContractCustomerCommunicationEntryModal from "../../CreateContractCustomerCommunicationEntryModal";

interface Props {
  contract: ContractDto;
  assetSubscription: AssetSubscriptionDto | null | undefined;
  onUpdate: (newValue?: ContractDto) => void;
  onReallocateVehicle: () => void;
  viewVariant?: ViewLayoutVariant;
}

export default function OverviewTabContent({
  contract,
  assetSubscription,
  viewVariant = ViewLayoutVariant.Tab,
  onUpdate,
  onReallocateVehicle,
}: Props) {
  const { enqueueSnackbar } = useAppSnackbar();

  const [isUpdateAffiliationModalOpened, setIsUpdateAffiliationModalOpened] = useState(false);
  const [isReallocateVehicleModalOpenLoading] = useState(false);
  const [isCommunicationInfoModalOpen, setIsCommunicationInfoModalOpen] = useState(false);
  const [isCreateCommunicationEntryModalOpen, setIsCreateCommunicationEntryModalOpen] =
    useState(false);
  const [isEditCheckOutSpotInfoModalOpen, setIsEditCheckOutSpotInfoModalOpen] = useState(false);
  const [isEditCheckInSpotInfoModalOpen, setIsEditCheckInSpotInfoModalOpen] = useState(false);

  const handleUpdateStage = useCallback(
    async (newStage: ContractStage) => {
      try {
        const response = await apiClient.contractsApi.apiV1ContractsContractIdStagePut({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          contractId: contract.id!,
          updateContractStageDto: {
            newStage,
          },
        });
        onUpdate(response.data);
      } catch (err) {
        const validation2 = ValidationHelper.handleApiErrorResponse(err);
        validation2.hasErrors &&
          enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
      }
    },
    [contract],
  );

  return (
    <PageTabContent>
      <ViewLayoutV2
        displayProps={{ viewVariant }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={contract} />}
          />
        }
      >
        <ViewContentBlock>
          <Box>
            <Grid2 container columnSpacing={2} rowSpacing={1} sx={{ mb: 1 }}>
              <Grid2 size={{ xxs: 12 }} minWidth={200}>
                <Stack direction='column' spacing={1}>
                  <FieldValue label='Type'>
                    <InlineApiEnumValue type='ContractType' value={contract.type} />
                  </FieldValue>

                  <FieldValue label='Vehicle' isEmpty={!contract?.vehicle}>
                    <Stack>
                      <VehicleLink entity={contract?.vehicle} />
                      <Box>
                        <AllocationStatusIcon status={contract.allocationStatus} />{" "}
                        <InlineApiEnumValue
                          type='AllocationStatus'
                          value={contract.allocationStatus}
                          withHelperTooltip
                        />{" "}
                        {contract?.canReallocate && (
                          <AppTooltip title='Reallocate vehicle' isInline>
                            <AppIconButton
                              size='small'
                              loading={isReallocateVehicleModalOpenLoading}
                              onClick={() => onReallocateVehicle && onReallocateVehicle()}
                            >
                              <AppIcon of='edit' />
                            </AppIconButton>
                          </AppTooltip>
                        )}
                      </Box>
                    </Stack>
                  </FieldValue>

                  <FieldValue label='Customer' isEmpty={!contract?.customer}>
                    <CustomerLink entity={contract?.customer} />
                  </FieldValue>

                  {contract.type === ContractType.Subscription && (
                    <FieldValue label='Asset subscription' isEmpty={!contract?.assetSubscription}>
                      <AssetSubscriptionLink entity={contract?.assetSubscription} />
                    </FieldValue>
                  )}

                  <FieldValue label='Starts at' isEmpty={!contract.startsAt}>
                    <Datetime datetime={contract.startsAt} withDurationFromNow />
                  </FieldValue>

                  <FieldValue label='Ends at' isEmpty={!contract.endsAt}>
                    <Datetime datetime={contract.endsAt} withDurationFromNow />
                  </FieldValue>

                  <FieldValue
                    variant='filled'
                    direction='column'
                    label='Duration'
                    isEmpty={!contract.startsAt && !contract.endsAt}
                  >
                    {DatetimeHelper.humanizeDateRangeDuration(contract.startsAt, contract.endsAt, {
                      isSuffix: false,
                    })}
                  </FieldValue>
                </Stack>
              </Grid2>
              <Grid2 size={{ xxs: 12 }} minWidth={200}>
                <Stack direction='column' spacing={1}>
                  <FieldValue label='Local number' isEmpty={!contract.localNumber}>
                    {contract.localNumber}
                  </FieldValue>

                  <FieldValue label='External number' isEmpty={!contract.externalNumber}>
                    {contract.externalNumber}
                  </FieldValue>

                  <FieldValue
                    variant='filled'
                    direction='column'
                    label='Notes'
                    isEmpty={!contract.notes}
                    forceValueRenderEvenIfEmpty
                  >
                    {contract.notes ? contract.notes : "-"}{" "}
                    <BaseEditNotesModal
                      initialValues={{ notes: contract.notes }}
                      onConfirm={async (notes) => {
                        await apiClient.contractsApi.apiV1ContractsContractIdNotesPut({
                          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                          contractId: contract?.id || "",
                          generalNotesInputDto: { notes },
                        });
                        onUpdate();
                      }}
                    />
                  </FieldValue>
                </Stack>
              </Grid2>
            </Grid2>
          </Box>
        </ViewContentBlock>

        {/* Affiliation */}
        <ViewContentBlock>
          <ViewContentBlockHeader>
            Affiliation{" "}
            <AppIconButton
              tooltipProps={{ title: "Edit entity affiliation" }}
              onClick={() => setIsUpdateAffiliationModalOpened(true)}
            >
              <AppIcon of='edit' fontSize='small' />
            </AppIconButton>
          </ViewContentBlockHeader>
          <AffiliationInfoDisplay
            withoutTenant
            departmentIds={contract.departmentIds}
            locationIds={contract.locationIds}
            sx={{ mt: 1 }}
          />
          <UpdateEntityAffiliationCascadeModal
            open={isUpdateAffiliationModalOpened}
            onClose={() => setIsUpdateAffiliationModalOpened(false)}
            entityType={EntityType.Contract}
            entityId={contract.id}
            onUpdate={onUpdate}
          />
        </ViewContentBlock>

        {/* Stages */}
        <ViewContentBlock spacing={2}>
          <Box sx={{ my: 2 }}>
            <ViewContentBlockHeader>Stages</ViewContentBlockHeader>

            <GeneralStageHistory
              stageEnumType='ContractStage'
              stageHistory={contract.stageHistory}
              withUpdateButtons
              onUpdateStage={async (newStage) => await handleUpdateStage(newStage)}
            />
          </Box>
        </ViewContentBlock>
        {contract.settings?.isCheckEnabled && (
          <>
            {/* Check-out spot info */}
            <ViewContentBlock>
              <ContractSpotInfoDisplay
                contract={contract}
                spotInfoType='CheckOut'
                action={
                  <Button
                    variant='outlined'
                    size='small'
                    color='text'
                    startIcon={<AppIcon of='edit' />}
                    onClick={() => setIsEditCheckOutSpotInfoModalOpen(true)}
                  >
                    Edit
                  </Button>
                }
              />
            </ViewContentBlock>

            {/* Check-in spot info */}
            <ViewContentBlock>
              <ContractSpotInfoDisplay
                contract={contract}
                spotInfoType='CheckIn'
                action={
                  <Button
                    variant='outlined'
                    size='small'
                    color='text'
                    startIcon={<AppIcon of='edit' />}
                    onClick={() => setIsEditCheckInSpotInfoModalOpen(true)}
                  >
                    Edit
                  </Button>
                }
              />
            </ViewContentBlock>
          </>
        )}

        {/* Accessories */}
        <ViewContentBlock>
          <ViewContentBlockHeader>Accessories</ViewContentBlockHeader>

          {(!contract?.accessories || contract?.accessories?.length === 0) && <NoDataAlert />}

          <AccessoryGrid accessories={contract?.accessories} sx={{ pl: 2 }} />
        </ViewContentBlock>

        {/* Customer communication */}
        <ViewContentBlock>
          <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
            <Box>
              <Grid2 container rowSpacing={1}>
                <Grid2 size={{ xxs: 12, md: 12 }}>
                  <Stack direction='row' justifyContent='space-between'>
                    <ViewContentBlockHeader>Customer communication</ViewContentBlockHeader>

                    <Stack direction='row' spacing={1}>
                      <Button
                        variant='outlined'
                        color='text'
                        size='small'
                        onClick={() => setIsCreateCommunicationEntryModalOpen(true)}
                      >
                        Customer contacted
                      </Button>

                      <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                        <MenuWithTrigger
                          withAuthCloseOnClick
                          trigger={
                            <IconButton sx={{ ml: "auto" }}>
                              <AppIcon of='moreVert' />
                            </IconButton>
                          }
                        >
                          <MenuItem onClick={() => setIsCommunicationInfoModalOpen(true)}>
                            <ListItemIcon>
                              <AppIcon of='history' fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>View communication entries</ListItemText>
                          </MenuItem>
                        </MenuWithTrigger>
                      </AuthorizedElement>
                    </Stack>
                  </Stack>
                </Grid2>
                <Grid2 size={{ xxs: 12, md: 12 }}>
                  {contract && (
                    <ContractCustomerCommunicationInfo
                      contract={contract}
                      displayProps={{ overview: true, createNewEntry: false, entries: false }}
                    />
                  )}
                </Grid2>
              </Grid2>
            </Box>
          </AuthorizedElement>
        </ViewContentBlock>

        {/* Reminders */}
        <ViewContentBlock>
          <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
            <Grid2 container rowSpacing={1}>
              <Grid2 size={{ xxs: 12, md: 12 }}>
                <ViewContentBlockHeader>Reminders</ViewContentBlockHeader>
              </Grid2>
              <Grid2 size={{ xxs: 12, md: 12 }}>
                {contract && (
                  <ContractReminderSettingsDisplay
                    contract={contract}
                    onUpdate={(newValue) => {
                      onUpdate(newValue.contract);
                    }}
                  />
                )}
              </Grid2>
            </Grid2>
          </AuthorizedElement>
        </ViewContentBlock>

        {/* Settings */}
        <ViewContentBlock>
          <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
            <Stack spacing={2}>
              <ViewContentBlockHeader>Settings</ViewContentBlockHeader>

              {contract && <ContractSettingsDisplay contract={contract} />}
            </Stack>
          </AuthorizedElement>
        </ViewContentBlock>
      </ViewLayoutV2>

      {/* Update check-out/check-in spot info */}
      {contract && (
        <ContractUpdateSpotInfoModal
          open={isEditCheckOutSpotInfoModalOpen}
          onClose={() => setIsEditCheckOutSpotInfoModalOpen(false)}
          subProps={{
            contract: contract,
            spotInfo: contract.checkOutSpotInfo,
            spotInfoType: "CheckOut",
            onSave: (newValue) => {
              setIsEditCheckOutSpotInfoModalOpen(false);
              onUpdate(newValue);
            },
          }}
        />
      )}
      {contract && (
        <ContractUpdateSpotInfoModal
          open={isEditCheckInSpotInfoModalOpen}
          onClose={() => setIsEditCheckInSpotInfoModalOpen(false)}
          subProps={{
            contract: contract,
            spotInfo: contract.checkInSpotInfo,
            spotInfoType: "CheckIn",
            onSave: (newValue) => {
              setIsEditCheckInSpotInfoModalOpen(false);
              onUpdate(newValue);
            },
          }}
        />
      )}

      {/* Customer contacted */}
      {contract && (
        <CreateContractCustomerCommunicationEntryModal
          createEntryProps={{
            contractId: contract.id!,
            onSave: () => setIsCreateCommunicationEntryModalOpen(false),
          }}
          open={isCreateCommunicationEntryModalOpen}
          onClose={() => setIsCreateCommunicationEntryModalOpen(false)}
        />
      )}

      {/* Communication info */}
      {contract && (
        <ContractCustomerCommunicationInfoModal
          contractCustomerCommunicationInfoProps={{
            contract: contract,
          }}
          open={isCommunicationInfoModalOpen}
          onClose={() => setIsCommunicationInfoModalOpen(false)}
        />
      )}
    </PageTabContent>
  );
}
