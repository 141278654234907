import { Box, FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import { useState } from "react";

import BlockCode from "@/common/components/Code/BlockCode";
import InlineCode from "@/common/components/Code/InlineCode";
import { renderIf } from "@/common/helpers/render/renderIf";

export default function DevRenderHelpers() {
  const [condition1, setCondition1] = useState(false);
  const [condition2, setCondition2] = useState(false);
  const [condition3, setCondition3] = useState(false);

  return (
    <Box>
      <Typography component='div' variant='h4' sx={{ my: 2 }}>
        Render helpers
      </Typography>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>renderIf</InlineCode>
      </Typography>
      <Stack spacing={2}>
        <Box>
          <Stack direction='row' spacing={2}>
            <FormControlLabel
              control={
                <Switch checked={condition1} onChange={(e) => setCondition1(e.target.checked)} />
              }
              label='condition1'
            />
            <FormControlLabel
              control={
                <Switch checked={condition2} onChange={(e) => setCondition2(e.target.checked)} />
              }
              label='condition2'
            />
            <FormControlLabel
              control={
                <Switch checked={condition3} onChange={(e) => setCondition3(e.target.checked)} />
              }
              label='condition3'
            />
          </Stack>
        </Box>

        <Stack direction='row' spacing={2}>
          <BlockCode>if(condition1) then content1</BlockCode>
          <Box>
            {renderIf()
              .if(condition1)
              .then(<Typography component='div'>condition1 content</Typography>)
              .render()}
          </Box>
        </Stack>
        <Stack direction='row' spacing={2}>
          <BlockCode>if(condition1) then content1 else elseContent</BlockCode>
          <Box>
            {renderIf()
              .if(condition1)
              .then(<Typography component='div'>condition1 content</Typography>)
              .else(<Typography component='div'>else content</Typography>)
              .render()}
          </Box>
        </Stack>
        <Stack direction='row' spacing={2}>
          <BlockCode>
            if(condition1) then content1 else if(condition2) then content2 else elseContent
          </BlockCode>
          <Box>
            {renderIf()
              .if(condition1)
              .then(<Typography component='div'>condition1 content</Typography>)
              .elseif(condition2)
              .then(<Typography component='div'>condition2 content</Typography>)
              .else(<Typography component='div'>else content</Typography>)
              .render()}
          </Box>
        </Stack>
        <Stack direction='row' spacing={2}>
          <BlockCode>
            if(condition1) then content1 else if(condition2) then content2 else if(condition3) then
            content3 else elseContent
          </BlockCode>
          <Box>
            {renderIf()
              .if(condition1)
              .then(<Typography component='div'>condition1 content</Typography>)
              .elseif(condition2)
              .then(<Typography component='div'>condition2 content</Typography>)
              .elseif(condition3)
              .then(<Typography component='div'>condition3 content</Typography>)
              .else(<Typography component='div'>else content</Typography>)
              .render()}
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}
