import { Box, Tooltip } from "@mui/material";
import { useMemo } from "react";

import { NegotiationHelper } from "@/common/helpers/entity/negotiation";
import { renderIf } from "@/common/helpers/render/renderIf";
import {
  NegotiationAllowedActionsDto,
  NegotiationDto,
  NegotiationProposalDto,
  UserPartiesMembershipDto,
} from "@/core/api/generated";

import AppIcon, { AppIconProps } from "../../../Icons/AppIcon";

interface OwnProps {
  negotiation: NegotiationDto;
  proposal: NegotiationProposalDto;
  allowedActions: NegotiationAllowedActionsDto;
  partiesMembership?: UserPartiesMembershipDto | null;
  iconProps?: Partial<AppIconProps>;
}

type Props = OwnProps;

export default function NegotiationProposalComputedStatusIcon({
  negotiation,
  proposal,
  allowedActions,
  partiesMembership,
  iconProps,
}: Props) {
  const computedStatus = useMemo(
    () => NegotiationHelper.getProposalComputedStatus(proposal, partiesMembership),
    [proposal, partiesMembership],
  );

  return (
    <>
      {renderIf()
        .if(computedStatus.isNoResponses)
        .then(
          <Tooltip
            title={
              <Box component='div'>
                <Box component='div'>
                  Please click Approve if you agree with the proposal, and Decline if you are not.
                </Box>
                <Box component='div'>(No party has answered on this proposal yet.)</Box>
              </Box>
            }
          >
            <AppIcon
              {...iconProps}
              of='waiting'
              sx={{ color: (th) => th.palette.text.secondary }}
            />
          </Tooltip>,
        )
        .elseif(computedStatus.isMyPartyRespondedAndWaitsForOtherPartyResponse)
        .then(
          <Tooltip
            title={
              <Box component='div'>
                <Box component='div'>Please wait for other party answer.</Box>
                <Box component='div'>
                  ({`Your party has answered, but the other party hasn't yet`})
                </Box>
              </Box>
            }
          >
            <AppIcon
              {...iconProps}
              of='waiting'
              sx={{ color: (th) => th.palette.text.secondary }}
            />
          </Tooltip>,
        )
        .elseif(computedStatus.isOtherPartyRespondedAndWaitsForMyPartyResponse)
        .then(
          <Tooltip
            title={
              <Box component='div'>
                <Box component='div'>
                  The other party has Approved the proposal and awaits for your answer. Please click
                  Approve if you agree with the proposal, and Decline if you are not.
                </Box>
                {allowedActions?.canForceRespondOnProposal && (
                  <Box component='div'>
                    You can close the case and click Force Approve or Force Decline according to
                    your policy.
                  </Box>
                )}
                <Box component='div'>
                  (The other party has already answered and awaits for your party answer)
                </Box>
              </Box>
            }
          >
            <AppIcon {...iconProps} of='waiting' color='warning' />
          </Tooltip>,
        )
        .elseif(computedStatus.isMyPartyApprovedOrNotRespondedAndOtherPartyDeclined)
        .then(
          <Tooltip
            title={
              <Box component='div'>
                <Box component='div'>
                  The other party has Declined the proposal and awaits for your answer. Please click
                  Approve if you agree with the proposal, and Decline if you are not.
                </Box>
                {allowedActions?.canForceRespondOnProposal && (
                  <Box component='div'>
                    You can close the case and click Force Approve or Force Decline according to
                    your policy.
                  </Box>
                )}
                <Box component='div'>({`Your party and other party answers don't match`})</Box>
              </Box>
            }
          >
            <AppIcon {...iconProps} of='warning' color='error' />
          </Tooltip>,
        )
        .elseif(computedStatus.isConsensus)
        .then(
          <Tooltip
            title={
              <Box component='div'>
                <Box component='div'>This item has been agreed by both parties.</Box>
                <Box component='div'>(Consensus is reached on this proposal)</Box>
              </Box>
            }
          >
            <AppIcon {...iconProps} of='consensus' color='primary' />
          </Tooltip>,
        )
        .elseif(computedStatus.isForcedConsensus)
        .then(
          <Tooltip
            title={
              <Box component='div'>
                <Box component='div'>This item has been force resolved by the competent party.</Box>
                <Box component='div'>(Forced consensus is reached on this proposal)</Box>
              </Box>
            }
          >
            <AppIcon {...iconProps} of='forcedConsensus' color='primary' />
          </Tooltip>,
        )
        .else(
          <Tooltip title={<Box component='div'>Please wait for the other party response.</Box>}>
            <AppIcon {...iconProps} of='waiting' color='text' />
          </Tooltip>,
        )
        // .else(<PlaceholderAppIcon />)
        .render()}
    </>
  );
}
