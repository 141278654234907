import { MutableRefObject, useRef } from "react";

/** Holds ref to the latest value. */
export function useLatestRef<T>(value: T): MutableRefObject<T> {
  const ref = useRef<T>(null as T);
  if (ref.current !== value) {
    ref.current = value;
  }
  return ref;
}
