import { useMediaQuery, useTheme } from "@mui/material";
import { useCallback } from "react";

import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppNavIcon from "@/common/components/Icons/AppNavIcon";
import AppNav from "@/common/components/Nav/AppNav";
import AppNavItem from "@/common/components/Nav/AppNavItem";
import { ROUTE_PATH } from "@/common/constants/routing";
import { EnvHelper } from "@/common/helpers/env";
import { UrlHelper } from "@/common/helpers/url";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import { tenantService } from "@/common/services/tenant";
import { AppPermission } from "@/core/api/generated";
import * as appCommonSlice from "@/store/appCommon/slice";

function Nav() {
  const theme = useTheme();
  const thunkDispatch = useAppThunkDispatch();

  // const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const isNavOpened = useAppSelector((x) => x.app.isNavOpened);

  const tenantInfo = tenantService.resolveTenant();
  const isTenantSelected = !!tenantInfo?.identifier;

  const handleNavItemClick = useCallback(
    (props: { to?: string }) => {
      // scroll top top
      // topAnchorRef.current?.scrollIntoView({ behavior: "smooth" });

      // on mobile auto-close nav after clicking link
      if (props.to && isMobile && isNavOpened) {
        thunkDispatch(appCommonSlice.toggleNav());
      }
    },
    [isMobile, isNavOpened],
  );

  // const fleetAppUrl = UrlHelper.addUrlPathname(
  //   tenantService.getDefaultTenantUrl(false),
  //   ROUTE_PATH.APP_AREA_FLEET_APP,
  // );
  // const fleetCustomerAppUrl = UrlHelper.addUrlPathname(
  //   tenantService.getDefaultTenantUrl(false),
  //   ROUTE_PATH.APP_AREA_FLEET_CUSTOMER,
  // );
  const adminAppUrl = UrlHelper.addUrlPathname(
    tenantService.getDefaultTenantUrl(false),
    ROUTE_PATH.APP_AREA_ADMIN,
  );

  return (
    <AppNav
      isNavOpened={isNavOpened}
      toggleNav={() => thunkDispatch(appCommonSlice.toggleNav())}
      titleTo='/'
      items={
        <>
          {EnvHelper.isLocalhostAny && (
            <AppNavItem
              type='item'
              item={{
                startIcon: <AppNavIcon of='code' />,
                content: "Styleguide (dev only)",
                to: ROUTE_PATH.DEV_STYLEGUIDE,
                onClick: handleNavItemClick,
              }}
            />
          )}

          {/* {isTenantSelected && (
              <>
                <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                  <AppNavItem
                    type='item'
                    item={{
                      startIcon: <AppNavIcon of='webApp' />,
                      content: "Fleet app",
                      to: ROUTE_PATH.APP_AREA_FLEET_APP,
                      onClick: handleNavItemClick,
                    }}
                  />
                </AuthorizedElement>
                <AuthorizedElement permissions={[AppPermission.FleetCustomerAppAccess]}>
                  <AppNavItem
                    type='item'
                    item={{
                      startIcon: <AppNavIcon of='webApp' />,
                      content: "Fleet customer app",
                      to: ROUTE_PATH.APP_AREA_FLEET_CUSTOMER,
                      onClick: handleNavItemClick,
                    }}
                  />
                </AuthorizedElement>
              </>
            )} */}

          <AuthorizedElement permissions={[AppPermission.AdminAppAccess]}>
            <AppNavItem
              type='item'
              item={{
                startIcon: <AppNavIcon of='webApp' />,
                content: "Admin app",
                to: adminAppUrl,
                onClick: handleNavItemClick,
              }}
            />
          </AuthorizedElement>

          {!isTenantSelected && (
            <AppNavItem
              type='item'
              item={{
                startIcon: <AppNavIcon of='switchAccount' />,
                content: "Switch company",
                to: ROUTE_PATH.SELECT_TENANT,
                onClick: handleNavItemClick,
              }}
            />
          )}
        </>
      }
    />
  );
}

export default Nav;
