import { Button, FormControl, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Formik, getIn } from "formik";
import * as Yup from "yup";

import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ColorSpaceType, RgbColorType } from "@/common/ts/color";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  CustomTagCreateDto,
  CustomTagDto,
  CustomTagUpdateDto,
  EntityType,
} from "@/core/api/generated";

import ColorInput from "../../Color/ColorInput";
import FoldableBlock from "../../Display/FoldableBlock";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FormActions from "../../Form/FormActions";
import AppTextArea from "../../Form/Input/AppTextArea";
import TagDisplay from "../General/GeneralTag/TagDisplay";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";

type DefaultValues = {
  name?: CustomTagCreateDto["name"];
  description?: CustomTagCreateDto["description"];
};

export interface CustomTagCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<CustomTagDto, DefaultValues> {
  customTagId?: string;
}

export type CustomTagCreateUpdateProps = CustomTagCreateUpdateOwnProps;

export default function CustomTagCreateUpdate({
  customTagId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: CustomTagCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const isCreate = !customTagId;

  const customTagRequest = useApiRequest(
    apiClient.customTagsApi.apiV1TagsCustomCustomTagIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      customTagId: customTagId!,
    },
    {
      skip: !customTagId,
    },
  );
  const customTag = customTagRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: customTag && {
      idValue: customTag.id!,
      newTitle: customTag.name || "",
    },
  });

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.CustomTag}
      entityId={customTagId}
      entity={customTag}
      entityRequest={customTagRequest}
    >
      <Formik<CustomTagCreateDto & CustomTagUpdateDto & BaseFormikValues>
        enableReinitialize={!isCreate}
        initialValues={{
          name: defaultValues?.name || customTag?.name || undefined,
          description: customTag?.description || undefined,
          styles: customTag?.styles || undefined,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // vehicleId: Yup.string().required("Vehicle is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            if (isCreate) {
              const response = await apiClient.customTagsApi.apiV1TagsCustomPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                customTagCreateDto: {
                  ...values,
                },
              });
              enqueueSnackbar("Custom tag created.", { variant: "success" });
              onCreate && onCreate(response.data);
              onSave && onSave(response.data);
            } else {
              const response = await apiClient.customTagsApi.apiV1TagsCustomCustomTagIdPut({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                customTagId,
                customTagUpdateDto: {
                  ...values,
                },
              });
              enqueueSnackbar("Custom tag updated.", { variant: "success" });
              onUpdate && onUpdate(response.data);
              onSave && onSave(response.data);
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Box>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    required
                    fullWidth
                    helperText={touched.name && errors.name}
                    label='Name'
                    margin='dense'
                    name='name'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='text'
                    value={values.name || ""}
                    variant='outlined'
                  />

                  <AppTextArea
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    helperText={touched.description && errors.description}
                    mode='description'
                    margin='dense'
                    name='description'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description || ""}
                    variant='outlined'
                  />
                </Box>

                {/* Styles */}
                <FoldableBlock
                  defaultIsFolded
                  trigger={{
                    label: <Typography variant='h6'>Styles</Typography>,
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: { xxs: "1fr", md: "1fr 1fr" },
                        gap: 2,
                      }}
                    >
                      <Box>
                        <FormControl margin='dense'>
                          <ColorInput
                            colorSpaceType={ColorSpaceType.RGB}
                            rgbColorType={RgbColorType.HEX}
                            value={values.styles?.backgroundColor || ""}
                            label='Background color'
                            error={Boolean(getIn(errors, "styles.backgroundColor"))}
                            helperText={ValidationHelper.getFormikErrorsAsString(
                              getIn(errors, "styles.backgroundColor"),
                            )}
                            onChange={(newValue) => {
                              setFieldValue("styles.backgroundColor", newValue);
                            }}
                          />
                        </FormControl>
                      </Box>

                      <Box>
                        <Stack spacing={1}>
                          <Typography variant='subtitle1'>Preview:</Typography>

                          <Stack direction='row' spacing={1}>
                            <TagDisplay
                              label='Test label'
                              backgroundColor={values?.styles?.backgroundColor || undefined}
                            />

                            <TagDisplay
                              label='Test label'
                              backgroundColor={values?.styles?.backgroundColor || undefined}
                              onDelete={() => {}}
                            />
                          </Stack>
                        </Stack>
                      </Box>
                    </Box>
                  </Box>
                </FoldableBlock>

                <GeneralValidationError errors={errors} />

                <FormActions>
                  <Button
                    color='primary'
                    loading={isSubmitting}
                    fullWidth
                    type='submit'
                    variant='contained'
                  >
                    Save
                  </Button>
                </FormActions>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
