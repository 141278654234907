import _ from "lodash";

import { RgbColor, RgbaColor, rgbAndRgbaHexRegex, rgbAndRgbaRegex } from "../helpers/color";
import { ColorSpaceType } from "../ts/color";

export class CustomColor {
  /** Color space. */
  colorSpaceType: ColorSpaceType = ColorSpaceType.RGB;
  /** Main color channel values (without alpha channel). Usually there are 3 channels. */
  values: number[] = [];
  /** Alpha channel [0, 1]. 1 by default if not specified in color explicitly. */
  alpha: number = 1;
  /** If RGB or RGBA color */
  rgb?: RgbColor;
  /** If RGBA color */
  rgba?: RgbaColor;

  constructor(params?: {
    rgb?: { r: number; g: number; b: number; a: number };
    other?: CustomColor;
  }) {
    if (params?.rgb) {
      this.colorSpaceType = ColorSpaceType.RGB;
      this.values = [params.rgb.r, params.rgb.g, params.rgb.b];
      this.alpha = params.rgb.a;
      this.rgb = {
        r: params.rgb.r,
        g: params.rgb.g,
        b: params.rgb.b,
      };
      this.rgba = {
        r: params.rgb.r,
        g: params.rgb.g,
        b: params.rgb.b,
        a: params.rgb.a,
      };
    }
    if (params?.other) {
      this.colorSpaceType = params.other.colorSpaceType;
      this.values = _.cloneDeep(params.other.values);
      this.alpha = params.other.alpha;
      this.rgb = _.cloneDeep(params.other.rgb);
      this.rgba = _.cloneDeep(params.other.rgba);
    }
  }

  // #region Static

  /**
   * Parses CSS color string.
   * Supported CSS color syntaxes:
   * Named colors: -
   * RGB Hexadecimal: #RGB, #RRGGBB
   * RGBA Hexadecimal: #RGBA, #RRGGBBAA
   * RGB: rgb(255 0 153), rgb(160, 34, 42)
   * RGBA: rgba(255 0 153 / 50%), rgba(160, 34, 42, 0.5)
   * HSL: -
   * HWB: -
   * LAB: -
   * LCH: -
   * Oklab: -
   * Oklch : -
   * @param cssColor Color used in CSS - https://developer.mozilla.org/en-US/docs/Web/CSS/color_value
   */
  public static parseCssColor(cssColor: string): CustomColor {
    cssColor = cssColor.trim().toLowerCase();

    const rgbHexMatch = cssColor.match(rgbAndRgbaHexRegex);
    if (rgbHexMatch && rgbHexMatch.groups) {
      const red = rgbHexMatch.groups["red"] || rgbHexMatch.groups["red2"] || "0";
      const green = rgbHexMatch.groups["green"] || rgbHexMatch.groups["green2"] || "0";
      const blue = rgbHexMatch.groups["blue"] || rgbHexMatch.groups["blue2"] || "0";
      const alpha = rgbHexMatch.groups["alpha"] || rgbHexMatch.groups["alpha2"] || undefined;

      return this.createRgbCustomColor({ red, green, blue, alpha, isHex: true });
    }

    const rgbMatch = cssColor.match(rgbAndRgbaRegex);
    if (rgbMatch && rgbMatch.groups) {
      const red = rgbMatch.groups["red"] || "0";
      const green = rgbMatch.groups["green"] || "0";
      const blue = rgbMatch.groups["blue"] || "0";
      const alpha = rgbMatch.groups["alpha"] || undefined;

      return this.createRgbCustomColor({ red, green, blue, alpha });
    }

    throw new Error(`Can't parse CSS color '${cssColor}'.`);
  }

  private static createRgbCustomColor(parsed: {
    red: string;
    green: string;
    blue: string;
    alpha?: string;
    isHex?: boolean;
  }): CustomColor {
    const radix = parsed.isHex ? 16 : 10;

    // F (15) - 1 (100%)
    // FF (255) - 1 (100%)
    let hexNumberCount: number | undefined = undefined;

    // ensure HEX numbers start with 0x
    if (parsed.isHex) {
      hexNumberCount = parsed.red.replace("0x", "").length;
      if (hexNumberCount !== 1 && hexNumberCount !== 2) {
        throw new Error(`CSS color in HEX must be either in #RGB or #RRGGBB format.`);
      }

      parsed.alpha ||= "F".repeat(hexNumberCount);

      parsed.red = parsed.red.startsWith("0x") ? parsed.red : `0x${parsed.red}`;
      parsed.green = parsed.green.startsWith("0x") ? parsed.green : `0x${parsed.green}`;
      parsed.blue = parsed.blue.startsWith("0x") ? parsed.blue : `0x${parsed.blue}`;
      parsed.alpha = parsed.alpha.startsWith("0x") ? parsed.alpha : `0x${parsed.alpha}`;
    } else {
      parsed.alpha ||= "1";
    }

    const red = parseInt(parsed.red || "0", radix);
    const green = parseInt(parsed.green || "0", radix);
    const blue = parseInt(parsed.blue || "0", radix);

    // can be number or percent
    let alpha = 1;
    if (parsed.isHex) {
      alpha = parseInt(parsed.alpha, radix);
      // F (15) - 1 (100%)
      // FF (255) - 1 (100%)
      const normalizationDivider =
        (hexNumberCount === 1 && 15) || (hexNumberCount === 2 && 255) || 1;
      alpha = alpha / normalizationDivider;
    } else {
      const alphaRegex = /^(?<alpha>\d+(\.\d+)?) ?%?$/;
      const alphaValue = (parsed.alpha.match(alphaRegex)?.groups || {})["alpha"] || "1";
      alpha = parseFloat(alphaValue);
      if (alpha > 1) {
        // normalize to [0, 1]
        alpha = alpha / 100;
      }
    }

    return new CustomColor({
      rgb: {
        r: red,
        g: green,
        b: blue,
        a: alpha,
      },
    });
  }

  // #endregion

  // #region Methods

  /** Returns deep copy of current object. */
  public clone(): CustomColor {
    return new CustomColor({ other: this });
  }

  /** Set alpha channel value in range [0, 1]. */
  public setAlpha(newAlpha: number): CustomColor {
    this.alpha = newAlpha;
    if (this.rgba) {
      this.rgba.a = newAlpha;
    }
    return this;
  }

  /** Formats color to CSS RGB format rgb(R, G, B). */
  public toCssRgb(): string {
    if (this.colorSpaceType !== ColorSpaceType.RGB || !this.rgb) {
      throw new Error("Must be RGB color.");
    }
    return `rgb(${this.rgb.r}, ${this.rgb.g}, ${this.rgb.b})`;
  }

  /** Formats color to CSS RGBA format rgb(R, G, B, A). */
  public toCssRgba(): string {
    if (this.colorSpaceType !== ColorSpaceType.RGB || !this.rgba) {
      throw new Error("Must be RGBA color.");
    }
    return `rgb(${this.rgba.r}, ${this.rgba.g}, ${this.rgba.b}, ${this.rgba.a})`;
  }

  // #endregion
}
