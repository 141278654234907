import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function EditIcon(props: SvgIconProps, ref) {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 20V4C3 3.46957 3.21071 2.96086 3.58579 2.58579C3.96086 2.21071 4.46957 2 5 2H11.59C12.1196 2.00223 12.6266 2.21441 13 2.59L20.74 10.29C20.8327 10.3834 20.906 10.4943 20.9558 10.6161C21.0055 10.7379 21.0308 10.8684 21.03 11V15.11C21.0348 15.5953 20.8629 16.0658 20.5465 16.4338C20.23 16.8017 19.7906 17.0421 19.31 17.11L17.25 17.41C16.2403 17.5516 15.2961 17.992 14.5388 18.6745C13.7814 19.357 13.2455 20.2505 13 21.24C12.9452 21.4619 12.8162 21.6583 12.6342 21.7966C12.4523 21.9349 12.2284 22.0067 12 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20ZM11.59 4H5V10H17.59L11.59 4ZM9 14H6C5.73478 14 5.48043 13.8946 5.29289 13.7071C5.10536 13.5196 5 13.2652 5 13C5 12.7348 5.10536 12.4804 5.29289 12.2929C5.48043 12.1054 5.73478 12 6 12H9C9.26522 12 9.51957 12.1054 9.70711 12.2929C9.89464 12.4804 10 12.7348 10 13C10 13.2652 9.89464 13.5196 9.70711 13.7071C9.51957 13.8946 9.26522 14 9 14Z'
      />
    </SvgIcon>
  );
});
