import { Tooltip } from "@mui/material";
import React, { ForwardedRef, forwardRef } from "react";

import { ChatType } from "@/core/api/generated";

import AppIcon, { AppIconProps } from "./AppIcon";

type AppIconPropsLocal = Omit<AppIconProps, "of">;

interface Props extends AppIconPropsLocal {
  chatType?: ChatType | null;
  withTooltip?: boolean;
}

const iconMap: Record<
  ChatType,
  (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => React.ReactNode | null
> = {
  None: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
    <AppIcon of='chat' ref={ref} {...props} />
  ),
  [ChatType.Personal]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
    <AppIcon of='chatPersonal' ref={ref} {...props} />
  ),
  [ChatType.Group]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
    <AppIcon of='chatGroup' ref={ref} {...props} />
  ),
  [ChatType.Comment]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
    <AppIcon of='chatComment' ref={ref} {...props} />
  ),
  [ChatType.Negotiation]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
    <AppIcon of='chatNegotiation' ref={ref} {...props} />
  ),
  [ChatType.Assistant]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
    <AppIcon of='ai' ref={ref} {...props} />
  ),
};

export default forwardRef<SVGSVGElement, Props>(function ChatTypeIcon(
  { chatType, withTooltip, ...other }: Props,
  ref,
) {
  const renderIcon = chatType ? iconMap[chatType] : iconMap[ChatType.None];
  const iconEl = renderIcon(other, ref);
  if (!React.isValidElement(iconEl)) {
    return null;
  }

  return withTooltip && chatType ? <Tooltip title={`${chatType} chat`}>{iconEl}</Tooltip> : iconEl;
});
