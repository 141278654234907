import { Box, CardActionArea, CardActionAreaProps } from "@mui/material";
import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";

type InheritedProps = CardActionAreaProps & Pick<CardActionAreaProps, "component">;
interface Props extends InheritedProps {
  /** If true - children are wrapped, false - children are rendered directly. */
  enabled?: boolean;
  to?: string;
  children?: ReactNode;
}

export default function CardActionAreaFacade({
  enabled,
  to,
  component,
  children,
  ...originalProps
}: Props) {
  if (!enabled) {
    return children ? <>{children}</> : null;
  }
  return (
    <CardActionArea
      component={component || (to ? RouterLink : Box)}
      to={to || ""}
      {...originalProps}
    >
      {children}
    </CardActionArea>
  );
}
