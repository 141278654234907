import type {} from "@mui/material/Box/index";
import {
  ThemeOptions,
  alpha,
  createTheme,
  darken,
  lighten,
  responsiveFontSizes,
} from "@mui/material/styles";
// import type {} from "@mui/x-charts-pro/themeAugmentation";
import type {} from "@mui/x-charts/themeAugmentation";
import type {} from "@mui/x-data-grid/themeAugmentation";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import _ from "lodash";

import { THEMES } from "@/common/constants/common";

import { chartsColorPaletteDefault } from "./charts";
import { darkShadows, lightShadows } from "./shadows";
import { CustomShape } from "./themeAugmentation";

const fontSize = 14; // the default font size of the Material Specification.
const htmlFontSize = 16; // default html font size is 16px
// pxToRem function override (with the same logic), just to be able to use it in the theme configs before theme is created
// Another way is to use theme.typography.pxToRem AFTER the theme is created
// const theme = createTheme({});
// theme.typography.h1 = { fontSize: theme.typography.pxToRem(96) };
export function pxToRemCustom(sizeInPx: number) {
  const coef = fontSize / 14;
  return `${(sizeInPx / htmlFontSize) * coef}rem`;
}

const shapeCustom: CustomShape = {
  borderRadius: "5px", // must match MUI's shape
  borderRadiusButton: "10px",
  borderRadiusIconButton: "50%",
  borderRadiusRoundAppIconButton: "50%",
  borderRadiusSquareAppIconButton: "10px",
  borderRadiusInput: "5px",
  borderRadiusAlert: "10px",
  borderRadiusCard: "10px",
  borderRadiusTab: "12px",
  borderRadiusChip: "5px",
  borderRadiusAvatar: "10px",
  borderRadiusFile: "10px",
  borderRadiusImage: "10px",
  borderBarChartBar: 5,
};

const baseOptions: ThemeOptions = {
  breakpoints: {
    values: {
      // default
      xs: 321, // phone
      sm: 600, // tablets
      md: 900, // small laptop
      lg: 1200, // desktop
      xl: 1536, // large screens

      // custom
      xxs: 0, // small phone
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
    },
  },
  direction: "ltr",
  shape: {
    borderRadius: 5, // MUI default is 4
  },
  shapeCustom: shapeCustom,
  boxShadowCustom: {
    headerShadow: "0px 0px 10px 0px #07173D1A",
    mobileNavShadow: "12px 0px 14px 0px rgba(184,184,184,0.79)",
  },
  scrollBarCustom: {
    navBar: (theme) => ({
      "&::-webkit-scrollbar": {
        width: "10px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#E5EAF4",
        borderRadius: "6px",
        borderImage: `${theme.palette.background.navbar} 1`,
        borderWidth: "3px",
        borderStyle: "solid",
      },
    }),
  },
  typography: {
    pxToRem: pxToRemCustom,
    // default html font size is 16px
    fontSize,

    fontFamily: [
      // custom
      "FixelDisplay",

      // from MUI docs
      // https://mui.com/material-ui/customization/typography/
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),

    // default variants
    // https://mui.com/material-ui/react-typography/
    h1: {
      fontWeight: 700,
      fontSize: pxToRemCustom(28),
      lineHeight: 1.167,
    },
    h2: {
      fontWeight: 700,
      fontSize: pxToRemCustom(26),
      lineHeight: 1.2,
    },
    h3: {
      fontWeight: 700,
      fontSize: pxToRemCustom(24),
      lineHeight: 1.167,
    },
    h4: {
      fontWeight: 700,
      fontSize: pxToRemCustom(22),
      lineHeight: 1.235,
    },
    h5: {
      fontWeight: 700,
      fontSize: pxToRemCustom(20),
      lineHeight: 1.234,
    },
    h6: {
      fontWeight: 700,
      fontSize: pxToRemCustom(18),
      lineHeight: 1.3,
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: pxToRemCustom(16),
      lineHeight: 1.3,
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: pxToRemCustom(14),
      lineHeight: 1.4,
    },
    body1: {
      fontWeight: 400,
      fontSize: pxToRemCustom(14),
      lineHeight: 1.42,
    },
    body2: {
      fontWeight: 300,
      fontSize: pxToRemCustom(14),
      lineHeight: 1.43,
    },
    button: {
      fontSize: pxToRemCustom(16),
      fontWeight: 400,
      lineHeight: 1.7,
      textTransform: "none",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    button2: {
      fontSize: pxToRemCustom(14),
      fontWeight: 600,
      lineHeight: 1.7,
      textTransform: "none",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    caption: {
      fontWeight: 200,
      fontSize: pxToRemCustom(14),
    },
    caption2: {
      fontWeight: 200,
      fontSize: pxToRemCustom(12),
    },
    overline: {
      fontWeight: 300,
      fontSize: pxToRemCustom(14),
      lineHeight: 1.5,
    },
  },
  /** https://mui.com/material-ui/customization/z-index/ */
  zIndex: {
    badge: 100,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        "*": {
          boxSizing: "border-box",
        },
        html: {
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
          height: "100%",
          width: "100%",
        },
        body: {
          height: "100%",
          width: "100%",
        },
        "#root": {
          height: "100%",
          width: "100%",
          "&&": {
            // also a root selector but with higher CSS specificity (.#-root.#-root)
            // background: theme.palette.warning.light,
          },
        },
        "#nprogress .bar": {
          zIndex: "2000 !important",
        },
        ".AppPageTabContent": {
          "& .ViewContentBlock:first-of-type": {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
        },
      }),
    },
    MuiStack: {
      styleOverrides: {
        root: {
          // TODO: uncomment when demo on dev will be shown already(monday)
          // minWidth: 0,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        component: "div", // by default <p></p>, set to div to avoid error with nested div in p.
        // sx: { background: (t) => t.palette.background.paper },
      },
    },
    MuiAvatar: {
      defaultProps: {
        // sx: { background: (t) => t.palette.background.gray, color: (t) => t.palette.text.main },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.shapeCustom.borderRadiusAvatar,
        }),
        fallback: {
          height: "75%",
          width: "75%",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: (props) => {
          const borderColor =
            props.ownerState.color === "inherit" || props.ownerState.variant === "text"
              ? "transparent"
              : props.theme.palette[props.ownerState.color || "primary"].main;

          return {
            borderRadius: props.theme.shapeCustom.borderRadiusButton,
            fontWeight: 400,
            border: `1px solid ${borderColor}`,
          };
        },
      },
      variants: [
        {
          props: {
            variant: "text",
          },
          style: {
            fontWeight: 500,
          },
        },
        {
          props: {
            variant: "contained",
          },
          style: {
            "&.Mui-disabled": {
              borderColor: "transparent",
            },
          },
        },
        {
          props: {
            size: "extraSmall",
          },
          style: (props) => ({
            padding: `0 ${props.theme.spacing(1)}`,
            fontSize: props.theme.typography.pxToRem(12),
            lineHeight: 2,
          }),
        },
        {
          props: {
            size: "small",
          },
          style: (props) => ({
            padding: `${props.theme.spacing(0.5)} ${props.theme.spacing(2)}`,
            fontSize: props.theme.typography.pxToRem(13),
            lineHeight: 1.7,
          }),
        },
        {
          props: {
            size: "medium",
          },
          style: (props) => ({
            padding: `${props.theme.spacing(0.89)} ${props.theme.spacing(2)}`,
            fontSize: props.theme.typography.pxToRem(14),
            lineHeight: 1.7,
          }),
        },
        {
          props: {
            size: "large",
          },
          style: (props) => ({
            padding: `${props.theme.spacing(1.676)} ${props.theme.spacing(2.5)}`,
            fontSize: props.theme.typography.pxToRem(16),
            lineHeight: 1.7,
          }),
        },
      ],
    },
    MuiListItemText: {
      styleOverrides: {
        root: (props) => ({
          fontSize: props.theme.typography.pxToRem(14),
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState: { variant, color, shape }, theme }) => ({
          borderRadius: theme.shapeCustom.borderRadiusIconButton,
          ...(variant === "text"
            ? {
                fontSize: "inherit",
                lineHeight: "inherit",
                padding: theme.spacing(0.25),
                background: "transparent",
              }
            : {}),

          ...(variant === "default"
            ? {
                background: "transparent",
              }
            : {}),

          ...(variant === "contained"
            ? {
                // styles are based on MUI Button.js, IconButton.js source code
                color:
                  !color || color === "inherit"
                    ? undefined
                    : color === "default"
                      ? theme.palette.text.main
                      : theme.palette[color].contrastText,
                backgroundColor:
                  !color || color === "inherit"
                    ? undefined
                    : color === "default"
                      ? theme.palette.background.paper
                      : alpha(theme.palette[color].main, 1),
                "&:hover": {
                  backgroundColor:
                    !color || color === "inherit"
                      ? undefined
                      : color === "default"
                        ? theme.palette.background.paper
                        : alpha(theme.palette[color].dark, 1),
                },
                "&:disabled": {
                  color: theme.palette.action.disabled,
                  backgroundColor: theme.palette.action.disabledBackground,
                  borderColor: theme.palette.action.disabled,
                },
              }
            : {}),

          ...(variant === "outlined"
            ? {
                // styles are based on MUI Button.js, IconButton.js source code
                borderWidth: 1,
                borderStyle: "solid",
                background: "transparent",
                borderColor:
                  !color || color === "inherit"
                    ? undefined
                    : color === "default"
                      ? theme.palette.text.main
                      : alpha(theme.palette[color].main, 0.5),
                "&:hover": {
                  borderColor:
                    !color || color === "inherit"
                      ? undefined
                      : color === "default"
                        ? theme.palette.text.main
                        : alpha(theme.palette[color].main, 1),
                },
                "&:disabled": {
                  color: theme.palette.action.disabled,
                  backgroundColor: "transparent",
                  borderColor: theme.palette.action.disabled,
                },
              }
            : {}),

          ...(shape === "round"
            ? {
                borderRadius: theme.shapeCustom.borderRadiusRoundAppIconButton,
              }
            : {}),
          ...(shape === "square"
            ? {
                borderRadius: theme.shapeCustom.borderRadiusSquareAppIconButton,
              }
            : {}),
        }),
      },
      variants: [
        {
          props: {
            size: "extraSmall",
          },
          style: (props) => ({
            padding: `${props.theme.spacing(0.25)}`,
            fontSize: props.theme.typography.pxToRem(18),
          }),
        },
        {
          props: {
            size: "small",
          },
          style: (props) => ({
            padding: props.theme.spacing(0.375),
            fontSize: props.theme.typography.pxToRem(22),
          }),
        },
        {
          props: {
            size: "medium",
          },
          style: (props) => ({
            padding: props.theme.spacing(0.75),
            fontSize: props.theme.typography.pxToRem(26),
          }),
        },
        {
          props: {
            size: "large",
          },
          style: (props) => ({
            padding: props.theme.spacing(1.5),
            fontSize: props.theme.typography.pxToRem(30),
          }),
        },
      ],
    },
    MuiButtonGroup: {
      defaultProps: {
        color: "secondary",
      },
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiCard: {
      defaultProps: {
        // MuiCard must inherit colors from MuiPaper to properly handle variants
        // sx: { background: (t) => t.palette.background.paper },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          overflow: "visible", // allow dropdowns in card
          borderRadius: theme.shapeCustom.borderRadiusCard,
          "& .MuiCardContent-root": {
            borderRadius: "inherit",
          },
        }),
      },
      variants: [],
    },
    MuiCardHeader: {
      defaultProps: {
        // MuiCard must inherit colors from MuiPaper to properly handle variants
        // sx: { background: (t) => t.palette.background.paper },
        sx: { backgroundColor: "inherit" },
        titleTypographyProps: {
          variant: "h6",
        },
      },
    },
    MuiCardContent: {
      defaultProps: {
        // MuiCard must inherit colors from MuiPaper to properly handle variants
        // sx: { background: (t) => t.palette.background.paper },
        sx: { backgroundColor: "inherit" },
      },
      styleOverrides: {
        root: {
          overflow: "visible", // allow dropdowns in card
          "&:last-child": {
            paddingBottom: 16,
          },
        },
      },
    },
    MuiLinearProgress: {
      defaultProps: {
        color: "secondary",
      },
      styleOverrides: {
        root: (props) => ({
          borderRadius: props.theme.shape.borderRadius,
          overflow: "hidden",
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "initial",
          marginRight: "8px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "& .MuiListItemIcon-root": {
            minWidth: "initial",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
      variants: [
        {
          props: {
            variant: "contained",
          },
          style: (props) => ({
            color: props.theme.palette.text.main,
            background: props.theme.palette.background.default,
          }),
        },
      ],
    },
    MuiGrid: {
      styleOverrides: {
        root: (props) => ({
          width: "100%",
          background: "transparent",
        }),
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: ({ ownerState, theme: th }) => ({
          background: th.palette.background.paper,
          fontSize: "inherit",
          borderColor: `${th.palette.divider}`,
          borderRadius: "inherit",
          // border: "none",
          // borderTop: `1px solid ${th.palette.divider}`,
          "& .MuiDataGrid-withBorderColor": {
            borderColor: `${th.palette.divider}`,
          },
          "& .MuiDataGrid-toolbarContainer": {
            // borderTop: "none",
          },
          "& .MuiDataGrid-footerContainer": {
            // borderTop: "none",
          },
          "--DataGrid-rowBorderColor": `${th.palette.divider}`,
        }),
        toolbarContainer: ({ ownerState, theme: th }) => ({
          paddingTop: th.spacing(1),
          paddingBottom: th.spacing(1),
          paddingLeft: th.spacing(1),
          paddingRight: th.spacing(1),
          border: "none",
          borderBottom: `1px solid ${th.palette.divider}`,
        }),
        footerContainer: ({ ownerState, theme: th }) => ({
          border: "none",
          borderTop: `1px solid ${th.palette.divider}`,
        }),
        columnHeaders: ({ ownerState, theme: th }) => ({
          background: th.palette.background.paper,

          // MuiDataGrid-columnHeaderRow
          "& [role=row]": {
            background: `${th.palette.background.paper} !important`,
          },
        }),
        columnHeader: ({ ownerState, theme: th }) => ({
          "&:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: 600,
            color: th.palette.text.primary,
          },
          "&.MuiDataGrid-columnHeader--sorted": {
            background: th.palette.primary.light,
          },
          "& .MuiDataGrid-columnSeparator": {
            color: th.palette.divider,
          },
        }),
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: (props) => ({
          background: "none",
          "&.MuiTextField-root": {
            background: "none",
          },
        }),
      },
    },
    MuiInputBase: {
      defaultProps: {
        color: "secondary",
      },
      styleOverrides: {
        root: (props) => ({
          background: props.theme.palette.background.paper, // ????
          borderRadius: props.theme.shapeCustom.borderRadiusInput,
        }),
      },
    },
    MuiInput: {
      styleOverrides: {
        root: (props) => ({
          background: props.theme.palette.background.paper,
          borderRadius: props.theme.shapeCustom.borderRadiusInput,
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: (props) => ({
          background: props.theme.palette.background.paper,
          borderRadius: props.theme.shapeCustom.borderRadiusInput,
        }),
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: (props) => ({
          background: props.theme.palette.background.paper,
          borderRadius: props.theme.shapeCustom.borderRadiusInput,
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: (props) => ({
          background: props.theme.palette.background.paper,
          borderRadius: props.theme.shapeCustom.borderRadiusInput,
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: (props) => ({
          background: "transparent",
          paddingLeft: 5,
          paddingRight: 5,
          "&.MuiInputLabel-shrink.Mui-focused": {
            color: props.theme.palette.secondary.main,
          },
        }),
      },
    },
    MuiDatePicker: {
      // styleOverrides: {
      //   root: {
      //     background: "red",
      //   },
      // },
    },
    MuiModal: {
      styleOverrides: {
        root: {},
      },
    },
    MuiPopper: {
      defaultProps: {
        sx: {
          // zIndex: (th) => th.zIndex.modal,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {},
        paper: (props) => ({
          borderRadius: props.theme.shape.borderRadius,
          boxShadow: props.theme.shadows[1],
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          color: "text.secondary",
          fontWeight: 600,
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: "secondary",
        textColor: "secondary",
      },
      styleOverrides: {
        root: (props) => ({
          width: "100%",
          // commented tabs shadow setup attempts
          // flex: 1,
          // paddingTop: "16px !important",
          // marginTop: "-16px !important",
          // paddingLeft: "16px !important",
          // marginLeft: "-16px !important",
          // paddingRight: "16px !important",
          // marginRight: "-16px !important",
          // position: "relative",

          "& .MuiTabs-scroller": {
            // paddingTop: "16px",
            // marginTop: "-16px",
            // paddingLeft: "16px !important",
            // marginLeft: "-16px !important",
            // paddingRight: "16px !important",
            // marginRight: "-16px !important",

            // "& .MuiTabs-flexContainer": {
            //   width: "max-content",
            //   marginRight: "16px !important",
            // },

            "& .MuiTab-root": {
              fontSize: props.theme.typography.button2.fontSize,
              fontWeight: props.theme.typography.button2.fontWeight,
              // borderTopLeftRadius: props.theme.shapeCustom.borderRadiusTab,
              // borderTopRightRadius: props.theme.shapeCustom.borderRadiusTab,

              "&.Mui-selected": {
                color: props.theme.palette.text.primary,
              },
            },
          },
          "& .MuiTabScrollButton-root:first-of-type": {
            // position: "absolute",
            // height: "calc(100% - 16px)",
            // bottom: 0,
            // left: 0,
            // zIndex: 1,
            // opacity: 1,
            // backgroundColor: props.theme.palette.background.paper,
            "&.Mui-disabled": {
              display: "none",
            },
          },
          "& .MuiTabScrollButton-root:last-of-type": {
            // position: "absolute",
            // height: "calc(100% - 16px)",
            // bottom: 0,
            // right: 0,
            // zIndex: 1,
            // opacity: 1,
            // backgroundColor: props.theme.palette.background.paper,
            "&.Mui-disabled": {
              display: "none",
            },
          },
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: (props) => ({
          borderRadius: props.theme.shapeCustom.borderRadiusChip,
        }),
      },
      variants: [
        {
          props: {
            size: "extraSmall",
          },
          style: (props) => ({
            height: 20,
            fontSize: props.theme.typography.caption.fontSize,
            lineHeight: props.theme.typography.caption.lineHeight,
            "& .MuiChip-label": {
              padding: "0px 6px",
            },
          }),
        },
      ],
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          const color = ownerState.severity || ownerState.color;
          return {
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
            borderRadius: theme.shapeCustom.borderRadiusAlert,
            borderLeftStyle: "solid",
            borderLeftWidth: "2px",
            borderTopLeftRadius: "2px",
            borderBottomLeftRadius: "2px",

            "& .MuiAlert-icon": {
              padding: 0,
              marginRight: theme.spacing(1.25),
              fontSize: theme.typography.pxToRem(24),
            },

            "& .MuiAlertTitle-root": {
              ...theme.typography.subtitle2,
              fontSize: theme.typography.pxToRem(14),
              fontWeight: 700,
              color: color ? theme.palette[color].main : undefined,
            },

            "& .MuiAlert-message": {
              flex: 1,
              padding: 0,
              paddingTop: "2px",
              ...theme.typography.body1,
            },
          };
        },
        standard: ({ theme, ownerState }) => {
          const color = ownerState.severity || ownerState.color;
          return {
            color: color ? theme.palette.text.main : undefined,
            background: color ? theme.palette[color].light : undefined,
            borderLeftColor: color ? theme.palette[color].main : undefined,
          };
        },
        filled: ({ theme, ownerState }) => {
          const color = ownerState.severity || ownerState.color;
          return {
            color: color ? theme.palette[color].contrastText : undefined,
            "& .MuiAlertTitle-root": {
              color: color ? theme.palette[color].contrastText : undefined,
            },
            background: color ? theme.palette[color].main : undefined,
            borderLeftColor: color ? theme.palette[color].dark : undefined,
          };
        },
        outlined: ({ theme, ownerState }) => {
          const color = ownerState.severity || ownerState.color;
          return {
            color: color ? theme.palette.text.main : undefined,
            background: "transparent",
            borderColor: color ? theme.palette[color].main : undefined,
          };
        },
      },
    },
    MuiBadge: {
      defaultProps: {
        color: "secondary",
      },
      styleOverrides: {
        root: (props) => ({
          "& .MuiBadge-badge": {
            zIndex: props.theme.zIndex.badge, // For cases where the count badge is not fully displayed.
            padding: "5px",
          },
        }),
      },
      variants: [
        {
          props: { color: "default" },
          style: (props) => ({
            "& .MuiBadge-badge": {
              backgroundColor: props.theme.palette.secondary?.dark,
              color: props.theme.palette.primary.contrastText,
            },
          }),
        },
      ],
    },
    MuiDialogTitle: {
      defaultProps: {
        variant: "h1",
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiRadio: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: (props) => ({
          "& .MuiPaginationItem-text": {
            fontSize: props.theme.typography.button2.fontSize,
            fontWeight: props.theme.typography.button2.fontWeight,
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: (props) => ({
          fontWeight: 400,
        }),
      },
      defaultProps: { component: "div" },
    },

    // #region @mui/x-charts

    MuiChartsAxis: {
      styleOverrides: {
        // tick: {
        //   stroke: "#006BD6",
        // },
      },
    },
    MuiBarChart: {
      defaultProps: {
        colors: chartsColorPaletteDefault,
        borderRadius: shapeCustom.borderBarChartBar,
      },
    },
    MuiLineChart: {
      defaultProps: {
        colors: chartsColorPaletteDefault,
      },
    },
    MuiBarElement: {
      styleOverrides: {
        root: {},
      },
    },

    // #endregion
  },
};

const themesOptions: { [key in THEMES]: ThemeOptions } = {
  [THEMES.LIGHT]: {
    components: {
      MuiLink: {
        defaultProps: {
          color: "primary",
        },
      },
      MuiIconButton: {
        variants: [
          {
            props: ({ ownerState }) => {
              return (
                ownerState.color === "default" &&
                ownerState.variant === "contained" &&
                !ownerState.active
              );
            },
            style: ({ theme }) => ({
              "&:hover": {
                boxShadow: theme.shadows[5],
              },
            }),
          },
          {
            props: ({ ownerState }) => {
              return (
                ownerState.color === "default" &&
                ownerState.variant === "contained" &&
                !!ownerState.active
              );
            },
            style: ({ theme }) => ({
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.background.activeItemBackground,
              border: `1px solid ${theme.palette.secondary.main}`,
              "&:hover": {
                backgroundColor: "#F2FAFF",
                boxShadow: theme.shadows[5],
              },
            }),
          },
          {
            props: ({ ownerState }) => {
              return (
                ownerState.color === "default" &&
                ownerState.variant === "outlined" &&
                !!ownerState.active
              );
            },
            style: ({ theme }) => ({
              color: theme.palette.text.primary,
              backgroundColor: "#F2FAFF",
              border: `1px solid ${theme.palette.secondary.main}`,
              "&:hover": {
                backgroundColor: "#F2FAFF",
                border: `1px solid ${theme.palette.secondary.main}`,
              },
            }),
          },
        ],
      },
      MuiChip: {
        variants: [
          {
            props: {
              color: "default",
              variant: "filled",
            },
            style: (props) => ({
              background: "#E3EDF4",
            }),
          },
        ],
      },
      // MuiTabs: {
      //   styleOverrides: {
      //     scroller: {
      //       "& .MuiTab-root": {
      //         "&.Mui-selected": {
      //           boxShadow: "0 0 16px 0 rgba(7, 23, 61, 0.1)",
      //         },
      //       },
      //     },
      //   },
      // },
      MuiDialog: {
        styleOverrides: {
          root: ({ theme }) => {
            return {
              "& .MuiBackdrop-root": {
                backgroundColor: theme.palette.background.blur,
                backdropFilter: "blur(4px)",
              },
            };
          },
          paper: ({ theme }) => ({
            borderRadius: theme.shapeCustom.borderRadiusCard,
          }),
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: (props) => ({
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: props.theme.palette.divider,
            },
            "&:hover:not(.Mui-disabled)": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#737373",
              },
            },
            "&.Mui-focused": {
              boxShadow: `0 0 50px 0 rgba(79, 192, 255, 0.12)`,
              "&:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: props.theme.palette.secondary.main,
                },
              },
            },
          }),
        },
      },
    },
    palette: {
      mode: "light",
      primary: {
        contrastText: "#ffffff",
        main: "#4670D4",
        light: "#EFF7FF",
        dark: "#3B62BE",
      },
      secondary: {
        contrastText: "#ffffff",
        main: "#4FC0FF",
        light: "#F2FAFF",
        dark: "#42B5F5",
      },
      error: {
        contrastText: "#ffffff",
        main: "#B64177",
        dark: "#CE6B99",
        light: "#FFF6FA",
      },
      warning: {
        contrastText: "#ffffff",
        main: "#E8BC6A",
        dark: "#E4AE4B",
        light: "#FFFAF0",
      },
      info: {
        contrastText: "#ffffff",
        main: "#0E9AB9",
        dark: "#096B81", // FIXME: empty in figma
        light: "#F6FDFF",
      },
      infoDetails: {
        contrastText: "#ffffff",
        main: "#9228FC",
        dark: "#6B0EC7",
        light: "#F1E5FE",
      },
      success: {
        contrastText: "#ffffff",
        main: "#8FD290",
        // dark: "#6FC370",
        light: "#EEFDEE",
      },
      text: {
        contrastText: "#FAFAFA",
        main: "#363636",
        dark: darken("#363636", 0.5),
        light: lighten("#363636", 0.5),

        primary: "#363636",
        secondary: "#737373",
        disabled: "#C4C4C4",
        disabledLight: "#DDDDDD",
      },
      divider: "#DADADA",
      background: {
        default: "#FAFAFA",
        paper: "#FFFFFF",
        gray: "#FAFAFA",
        navbar: "linear-gradient(180deg, #F5FBFF 0%, #E8EFFF 100%)",
        appHeader: "#FFFFFF",
        pageHeader: "#FFFFFF",
        logo: {
          whiteWave: "#FFFFFF",
          blueWave: "rgba(173, 223, 255, 0.18)",
          text: "#464646",
        },
        activeItemBackground: "#F2FAFF",
        blur: "#939CA14D",
      },
      /** For text highlight */
      highlight: {
        main: "#F2FAFF",
        dark: "#F7FAFC",
        light: "#FCFCFC",
      },
    },
    shadows: lightShadows,
  },
  [THEMES.DARK]: {
    components: {
      MuiLink: {
        defaultProps: {
          color: "secondary",
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          standard: ({ theme, ownerState }) => {
            const color = ownerState.severity || ownerState.color;
            return {
              color: color ? theme.palette[color].contrastText : undefined,
              background: color ? theme.palette[color].light : undefined,
              borderColor: color ? theme.palette[color].main : undefined,
            };
          },
          outlined: ({ theme, ownerState }) => {
            const color = ownerState.severity || ownerState.color;
            return {
              color: color ? theme.palette[color].contrastText : undefined,
              background: "transparent",
              borderColor: color ? theme.palette[color].main : undefined,
            };
          },
        },
      },
      MuiIconButton: {
        variants: [
          {
            props: ({ ownerState }) => {
              return (
                ownerState.color === "default" &&
                ownerState.variant === "contained" &&
                !ownerState.active
              );
            },
            style: ({ theme }) => ({
              "&:hover": {
                backgroundColor: "#26333D",
              },
            }),
          },
          {
            props: ({ ownerState }) => {
              return (
                ownerState.color === "default" &&
                ownerState.variant === "contained" &&
                !!ownerState.active
              );
            },
            style: ({ theme }) => ({
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.background.activeItemBackground,
              "&:hover": {
                backgroundColor: "#164566",
              },
            }),
          },
        ],
      },
      MuiChip: {
        variants: [
          {
            props: {
              color: "default",
              variant: "filled",
            },
            style: (props) => ({
              background: props.theme.palette.highlight.main,
            }),
          },
        ],
      },
      // MuiTabs: {
      //   styleOverrides: {
      //     scroller: {
      //       "& .MuiTab-root": {
      //         "&.Mui-selected": {
      //           boxShadow: "0 0 16px 0 #072228",
      //         },
      //       },
      //     },
      //   },
      // },
      MuiDialog: {
        styleOverrides: {
          root: ({ theme }) => {
            return {
              "& .MuiBackdrop-root": {
                backgroundColor: theme.palette.background.blur,
                backdropFilter: "blur(4px)",
              },
            };
          },
          paper: ({ theme }) => ({
            borderRadius: theme.shapeCustom.borderRadiusCard,
            boxShadow: "0 0 1px 0 #111A24, 0 24px 36px -8px #111A24",
          }),
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: (props) => ({
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: props.theme.palette.divider,
            },
            // hover and not .Mui-disabled
            "&:hover:not(.Mui-disabled)": {
              background: "#26333D",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: props.theme.palette.divider,
              },
            },
            "&.Mui-focused": {
              background: "#1E3B50",
              boxShadow: `0 0 50px 0 rgba(79, 192, 255, 0.12)`,
              "&:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: props.theme.palette.secondary.main,
                },
              },
            },
          }),
        },
      },
    },
    palette: {
      mode: "dark",
      primary: {
        contrastText: "#ffffff",
        main: "#2D669B",
        dark: "#225D94",
        light: alpha("#2D669B", 0.2), // FIXME: empty in figma
      },
      secondary: {
        contrastText: "#ffffff",
        main: "#088AC2",
        dark: darken("#088AC2", 0.1), // FIXME: same as main in figma
        light: "#0874A2",
      },
      error: {
        contrastText: "#ffffff",
        main: "#C51769",
        dark: "#6E0E3B",
        light: "#411535",
      },
      warning: {
        contrastText: "#ffffff",
        main: "#A76201",
        dark: "#955B09",
        light: "#2F291D",
      },
      info: {
        contrastText: "#ffffff",
        main: "#0E9AB9",
        dark: darken("#0E9AB9", 0.1), // FIXME: empty in figma
        light: "#113442",
      },
      infoDetails: {
        contrastText: "#ffffff",
        main: "#9228FC",
        dark: "#6B0EC7",
        light: "#F1E5FE",
      },
      success: {
        contrastText: "#ffffff",
        main: "#2F6830",
        dark: "#245F25",
        light: "#1A3127",
      },
      text: {
        contrastText: "#111A24",
        main: "#ffffff",
        dark: darken("#ffffff", 0.06),
        light: lighten("#ffffff", 0.5),

        primary: "#ffffff",
        secondary: "#E7E7E7",
        disabled: "#7F7F7F",
      },
      divider: "#424F5A",
      background: {
        default: "#111A24",
        paper: "#212B33",
        gray: "#111A24",
        navbar: "#111A24",
        appHeader: "#111A24",
        pageHeader: "#111A24",
        logo: {
          whiteWave: "#5A5A5A",
          blueWave: "#ADDFFF",
          text: "#FFFFFF",
        },
        activeItemBackground: "#245679",
        blur: "#111A244D",
      },
      /** For text highlight */
      highlight: {
        main: "#1E3B50",
        dark: "#26333D",
        light: "#253139",
      },
    },
    // NOTE: update dark shadows in figma?
    shadows: darkShadows,
  },
};

export const createCustomTheme = (config = { theme: THEMES.LIGHT, responsiveFontSizes: false }) => {
  let themedOptions = themesOptions[config.theme];

  if (!themedOptions) {
    console.error(new Error(`The theme ${config.theme} is not valid`));
    themedOptions = themesOptions[THEMES.LIGHT];
  }

  // WARNING: default lodash merge corrupts some data merging theme options. maybe make sense to create
  // custom deep merge function specifically for merging theme options
  const mergedOptions = _.mergeWith({}, baseOptions, themedOptions, (objValue, srcValue) => {
    if (Array.isArray(objValue)) {
      return objValue.concat(srcValue);
    }
    return undefined;
  });
  let theme = createTheme(mergedOptions);

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
