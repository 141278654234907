import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function DocIcon(props: SvgIconProps, ref) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icon/Outline/file-text'>
        <path
          id='Mask'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12 14H9.00001C8.44801 14 8.00001 13.552 8.00001 13C8.00001 12.448 8.44801 12 9.00001 12H12C12.552 12 13 12.448 13 13C13 13.552 12.552 14 12 14ZM9.00001 16H15C15.553 16 16 16.448 16 17C16 17.552 15.553 18 15 18H9.00001C8.44801 18 8.00001 17.552 8.00001 17C8.00001 16.448 8.44801 16 9.00001 16ZM17.4443 20H6.55531C6.24931 20 6.00031 19.776 6.00031 19.5V4.5C6.00031 4.224 6.24931 4 6.55531 4H12.0003V7.15C12.0003 8.722 13.2173 10 14.7143 10H18.0003V19.5C18.0003 19.776 17.7513 20 17.4443 20ZM14.0003 4.978L16.7423 8H14.7143C14.3203 8 14.0003 7.619 14.0003 7.15V4.978ZM19.7403 8.328L14.2963 2.328C14.1063 2.119 13.8383 2 13.5553 2H6.55531C5.14631 2 4.00031 3.122 4.00031 4.5V19.5C4.00031 20.878 5.14631 22 6.55531 22H17.4443C18.8533 22 20.0003 20.878 20.0003 19.5V9C20.0003 8.751 19.9073 8.512 19.7403 8.328Z'
        />
        <mask id='mask0_281_9859' maskUnits='userSpaceOnUse' x='4' y='2' width='17' height='20'>
          <path
            id='Mask_2'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12 14H9.00001C8.44801 14 8.00001 13.552 8.00001 13C8.00001 12.448 8.44801 12 9.00001 12H12C12.552 12 13 12.448 13 13C13 13.552 12.552 14 12 14ZM9.00001 16H15C15.553 16 16 16.448 16 17C16 17.552 15.553 18 15 18H9.00001C8.44801 18 8.00001 17.552 8.00001 17C8.00001 16.448 8.44801 16 9.00001 16ZM17.4443 20H6.55531C6.24931 20 6.00031 19.776 6.00031 19.5V4.5C6.00031 4.224 6.24931 4 6.55531 4H12.0003V7.15C12.0003 8.722 13.2173 10 14.7143 10H18.0003V19.5C18.0003 19.776 17.7513 20 17.4443 20ZM14.0003 4.978L16.7423 8H14.7143C14.3203 8 14.0003 7.619 14.0003 7.15V4.978ZM19.7403 8.328L14.2963 2.328C14.1063 2.119 13.8383 2 13.5553 2H6.55531C5.14631 2 4.00031 3.122 4.00031 4.5V19.5C4.00031 20.878 5.14631 22 6.55531 22H17.4443C18.8533 22 20.0003 20.878 20.0003 19.5V9C20.0003 8.751 19.9073 8.512 19.7403 8.328Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_281_9859)'>
          <g id='&#240;&#159;&#142;&#168; Color'>
            <rect id='Base' width='24' height='24' />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
});
