import { FormControl, Stack, SxProps, TextField, Theme } from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { getIn } from "formik";
import moment from "moment";

import ApiEnumAutocomplete from "@/common/components/Enum/ApiEnumAutocomplete";
import NumberInput from "@/common/components/Form/Input/NumberInput";
import { DATETIME_FORMATS } from "@/common/constants/common";
import { CustomFormikSubProps } from "@/common/helpers/formik";
import { VehicleBatteryStateDto, VehicleBatteryStateInputDto } from "@/core/api/generated";

export function vehicleBatteryStateToInputDto(
  batteryState: VehicleBatteryStateDto | VehicleBatteryStateInputDto | null | undefined,
): VehicleBatteryStateInputDto | undefined {
  if (!batteryState) {
    return undefined;
  }

  // const dto = batteryState as VehicleBatteryStateDto;
  // const inputDto = batteryState as VehicleBatteryStateInputDto;

  return {
    ...batteryState,
  };
}

interface Props {
  formikProps: CustomFormikSubProps<VehicleBatteryStateInputDto | null | undefined>;
  sx?: SxProps<Theme>;
}

export default function VehicleBatteryStateInput({ formikProps, sx }: Props) {
  const { values, errors, setFieldValue } = formikProps;

  return (
    <Stack spacing={0} sx={sx}>
      <NumberInput
        fullWidth
        error={Boolean(getIn(errors, "currentCapacityPct"))}
        helperText={getIn(errors, "currentCapacityPct")}
        label='Current capacity pct'
        margin='dense'
        onChange={(e) => setFieldValue("currentCapacityPct", e.target.value)}
        inputMode='numeric'
        value={values?.currentCapacityPct || ""}
        variant='outlined'
      />

      <FormControl margin='dense' fullWidth>
        <ApiEnumAutocomplete
          type='VehicleBatteryHealthStatus'
          value={values?.healthStatus}
          allowNone={false}
          label='Health status'
          textFieldProps={{
            error: Boolean(getIn(errors, "type")),
            helperText: getIn(errors, "type"),
            margin: "none",
          }}
          onChange={(newValue) => {
            setFieldValue("healthStatus", newValue);
          }}
        />
      </FormControl>
      <FormControl margin='dense' fullWidth>
        <MobileDateTimePicker
          ampm={false}
          label='Last service at'
          value={(values?.lastServiceAt && moment(values?.lastServiceAt)) || null}
          format={DATETIME_FORMATS.DISPLAY_DATETIME}
          onChange={(newValue) => {
            setFieldValue("lastServiceAt", newValue?.format() || null);
          }}
          slots={{
            textField: (params) => (
              <TextField
                {...params}
                error={Boolean(getIn(errors, "lastServiceAt"))}
                helperText={getIn(errors, "lastServiceAt")}
              />
            ),
          }}
        />
      </FormControl>
      <FormControl margin='dense' fullWidth>
        <MobileDateTimePicker
          ampm={false}
          label='Last replacement at'
          value={(values?.lastReplacementAt && moment(values?.lastReplacementAt)) || null}
          format={DATETIME_FORMATS.DISPLAY_DATETIME}
          onChange={(newValue) => {
            setFieldValue("lastReplacementAt", newValue?.format() || null);
          }}
          slots={{
            textField: (params) => (
              <TextField
                {...params}
                error={Boolean(getIn(errors, "lastReplacementAt"))}
                helperText={getIn(errors, "lastReplacementAt")}
              />
            ),
          }}
        />
      </FormControl>
    </Stack>
  );
}
