import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid2,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { getIn, useFormikContext } from "formik";
import { useCallback } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import { ArrayHelper } from "@/common/helpers/array";
import { AdminTenantDto, AdminUserDto } from "@/core/api/generated";

import TenantAutocomplete from "../../components/Entity/Tenant/TenantAutocomplete";
import AdminUserAutocomplete from "../../components/Entity/User/UserAutocomplete";
import { CreateNotificationFormValues } from "../CreateForm";

const CreateNotificationFormPushInputs = () => {
  const { values, errors, touched, setFieldValue } =
    useFormikContext<CreateNotificationFormValues>();

  const onUserChanged = useCallback((index: number, user?: AdminUserDto) => {
    setFieldValue(`push.users[${index}].userId`, user?.id);
    setFieldValue(`push.users[${index}].tenantId`, "");
  }, []);
  const onTenantChanged = useCallback((index: number, tenant?: AdminTenantDto) => {
    setFieldValue(`push.users[${index}].tenantId`, tenant?.id);
  }, []);
  const onTitleChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFieldValue(`push.title`, e.target.value);
    },
    [],
  );
  const onBodyChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFieldValue(`push.body`, e.target.value);
    },
    [],
  );
  const onImageUrlChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFieldValue(`push.imageUrl`, e.target.value);
    },
    [],
  );
  const onChannelChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFieldValue(`push.channel`, e.target.value);
    },
    [],
  );

  return (
    <>
      <Stack spacing={1}>
        <Typography component='div' variant='subtitle1'>
          Users
        </Typography>
        <Stack direction='column' spacing={1}>
          {values.push?.users?.map((item, index) => {
            return (
              <Card key={index} sx={{ overflow: "unset" }}>
                <CardContent>
                  <FormControl
                    fullWidth
                    margin='dense'
                    error={Boolean(
                      getIn(touched, `push.users[${index}].userId`) &&
                        getIn(errors, `push.users[${index}].userId`),
                    )}
                  >
                    <AdminUserAutocomplete
                      fullWidth
                      userId={item.userId}
                      onChange={(user) => onUserChanged(index, user)}
                    />
                    <FormHelperText
                      error={Boolean(
                        getIn(touched, `push.users[${index}].userId`) &&
                          getIn(errors, `push.users[${index}].userId`),
                      )}
                    >
                      {getIn(errors, `push.users[${index}].userId`)}
                    </FormHelperText>
                  </FormControl>

                  <FormControl
                    fullWidth
                    margin='dense'
                    error={Boolean(
                      getIn(touched, `push.users[${index}].tenantId`) &&
                        getIn(errors, `push.users[${index}].tenantId`),
                    )}
                  >
                    <TenantAutocomplete
                      entityId={item.tenantId}
                      searchFilters={{
                        userId: item.userId || undefined,
                      }}
                      disabled={!item.userId}
                      fullWidth
                      onChange={(tenant) => onTenantChanged(index, tenant)}
                    />
                    <FormHelperText
                      error={Boolean(
                        getIn(touched, `push.users[${index}].tenantId`) &&
                          getIn(errors, `push.users[${index}].tenantId`),
                      )}
                    >
                      {getIn(errors, `push.users[${index}].tenantId`)}
                    </FormHelperText>
                  </FormControl>

                  <Grid2 size={{ xxs: 12, sm: 12, md: 12, lg: 12 }}>
                    {/* Controls */}
                    <Stack
                      direction='row'
                      sx={{
                        flex: 1,
                        ml: "auto",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Tooltip title='Delete'>
                        <IconButton
                          onClick={() =>
                            setFieldValue(
                              "users",
                              ArrayHelper.removeByIndex(values.push?.users, index),
                            )
                          }
                        >
                          <AppIcon of='delete' />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Grid2>
                </CardContent>
              </Card>
            );
          })}
        </Stack>

        {/* Add item */}
        <Button
          sx={{ flex: 0, alignSelf: "flex-start" }}
          variant='outlined'
          color='text'
          size='small'
          startIcon={<AppIcon of='add' />}
          onClick={() => {
            setFieldValue("push.users", [...(values.push?.users || []), {}]);
          }}
        >
          Add user
        </Button>
      </Stack>

      <FormControl fullWidth margin='dense' sx={{ mt: 2 }}>
        <TextField
          error={Boolean(getIn(touched, "push.title") && getIn(errors, "push.title"))}
          variant='outlined'
          fullWidth
          label='Title'
          value={values?.push?.title || ""}
          onChange={onTitleChanged}
        />
        <FormHelperText
          error={Boolean(getIn(touched, "push.title") && getIn(errors, "push.title"))}
        >
          {getIn(errors, "push.title")}
        </FormHelperText>
      </FormControl>

      <FormControl fullWidth margin='dense'>
        <TextField
          error={Boolean(getIn(touched, "push.body") && getIn(errors, "push.body"))}
          variant='outlined'
          fullWidth
          label='Body'
          value={values?.push?.body || ""}
          onChange={onBodyChanged}
        />
        <FormHelperText error={Boolean(getIn(touched, "push.body") && getIn(errors, "push.body"))}>
          {getIn(errors, "push.body")}
        </FormHelperText>
      </FormControl>

      <FormControl fullWidth margin='dense'>
        <TextField
          error={Boolean(getIn(touched, "push.imageUrl") && getIn(errors, "push.imageUrl"))}
          variant='outlined'
          fullWidth
          label='Image URL'
          value={values?.push?.imageUrl || ""}
          onChange={onImageUrlChanged}
        />
        <FormHelperText
          error={Boolean(getIn(touched, "push.imageUrl") && getIn(errors, "push.imageUrl"))}
        >
          {getIn(errors, "push.imageUrl")}
        </FormHelperText>
      </FormControl>

      <FormControl fullWidth margin='dense'>
        <TextField
          error={Boolean(getIn(touched, "push.channel") && getIn(errors, "push.channel"))}
          variant='outlined'
          fullWidth
          label='Channel(android)'
          value={values?.push?.channel || ""}
          onChange={onChannelChanged}
        />
        <FormHelperText
          error={Boolean(getIn(touched, "push.channel") && getIn(errors, "push.channel"))}
        >
          {getIn(errors, "push.channel")}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default CreateNotificationFormPushInputs;
