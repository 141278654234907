import { ChangeEvent, useCallback, useEffect, useState } from "react";

import { apiClient } from "@/core/api/ApiClient";
import { UserSecretInputDto } from "@/core/api/generated";

import TextFieldForSecret, { TextFieldForSecretProps } from "./TextFieldForSecret";

interface Props extends Omit<TextFieldForSecretProps, "onChange" | "type" | "value"> {
  value: UserSecretInputDto | null | undefined;
  onChange: (newValue: UserSecretInputDto | undefined) => void;
}

export default function UserSecretInput({ value, onChange, ...otherProps }: Props) {
  const userSecretId = value?.id;

  const [__, setIsLoading] = useState(false);

  useEffect(() => {
    if (userSecretId) {
      setIsLoading(true);
      apiClient.userSecretsApi
        .apiV1UserSecretsUserSecretIdValueGet({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          userSecretId: userSecretId,
        })
        .then((response) => {
          onChange({ id: userSecretId, value: response.data.value });
        })
        .finally(() => setIsLoading(false));
    }
  }, [userSecretId]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange(e.target.value ? { id: userSecretId, value: e.target.value } : undefined);
    },
    [userSecretId, onChange],
  );

  return (
    <TextFieldForSecret
      autoComplete='new-password'
      autoFocus={false}
      value={value?.value || ""}
      onChange={(e) => handleChange(e)}
      {...otherProps}
    />
  );
}
