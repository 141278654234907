import { DependencyList, EffectCallback, useEffect } from "react";

import { useValueMemoWithDeepCompareIf } from "../memo/useValueMemoWithDeepCompareIf";

/** useEffect with deep compare for deps array. Skips deps deep comparison until condition is `true`. */
export function useEffectWithDeepCompareIf(
  condition: boolean,
  effect: EffectCallback,
  deps?: DependencyList,
): void {
  // avoid deep compare if not needed
  const depsMemorized = useValueMemoWithDeepCompareIf(condition, deps, undefined);

  useEffect(() => {
    if (condition) {
      return effect();
    }
  }, [condition, depsMemorized]);
}
