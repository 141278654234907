import { useFormikContext } from "formik";
import _, { DebounceSettings, ThrottleSettings } from "lodash";
import { DependencyList, useCallback } from "react";

import { useEffectWithDeepCompareIf } from "@/common/hooks/effect/useEffectWithDeepCompareIf";
import { useLatestRef } from "@/common/hooks/ref/useLatestRef";

type DebounceOptions = { wait: number | undefined; options?: DebounceSettings };
type ThrottleOptions = { wait: number | undefined; options?: ThrottleSettings };

export interface FormikComputedFieldProps<Values, TComputed> {
  /** If `false`, compute func won't be called.
    @default true
 */
  isEnabled?: boolean;
  /** If not set, formik context is used. */
  values?: Values;
  /** List of dependencies which trigger recalculation of the computed field value.
   * NB: don't include callbacks in deps as they can be new functions every time.
   */
  deps?: DependencyList;
  debouncedDeps?: {
    deps: DependencyList;
  } & DebounceOptions;
  throttledDeps?: {
    deps: DependencyList;
  } & ThrottleOptions;
  /** Function that calculates the new value. Memorized once. */
  compute: (values: Values) => TComputed | Promise<TComputed>;
  /** Handle new computed value. Memorized once.*/
  onComputed?: (computed: TComputed) => void;
  onComputed2?: (params: { values: Values; computed: TComputed }) => void;
}

/** Allows to recalculate computed/dynamic field in Formik form values. */
export default function FormikComputedField<Values, TComputed>({
  isEnabled = true,
  values,
  deps,
  debouncedDeps,
  throttledDeps,
  compute,
  onComputed,
  onComputed2,
}: FormikComputedFieldProps<Values, TComputed>) {
  const formikContext = useFormikContext<Values>();
  const _values = values || formikContext.values;

  const isEnabledRef = useLatestRef<typeof isEnabled>(isEnabled);
  const computeRef = useLatestRef<typeof compute>(compute);
  const onComputedRef = useLatestRef<typeof onComputed>(onComputed);
  const onComputed2Ref = useLatestRef<typeof onComputed2>(onComputed2);

  // memorize callbacks once
  const handleCompute = useCallback(async (values2: Values) => {
    if (isEnabledRef.current) {
      const computed = await computeRef.current(values2);
      onComputedRef.current && onComputedRef.current(computed);
      onComputed2Ref.current && onComputed2Ref.current({ values: values2, computed });
    }
  }, []);
  const handleComputeDebounced = useCallback(
    _.debounce(handleCompute, debouncedDeps?.wait, debouncedDeps?.options),
    [handleCompute],
  );
  const handleComputeThrottled = useCallback(
    _.throttle(handleCompute, throttledDeps?.wait, throttledDeps?.options),
    [handleCompute],
  );

  useEffectWithDeepCompareIf(
    !!deps,
    () => {
      handleCompute(_values);
    },
    [handleCompute, ...(deps || [])],
  );

  useEffectWithDeepCompareIf(
    !!debouncedDeps,
    () => {
      handleComputeDebounced(_values);
    },
    [handleComputeDebounced, ...(debouncedDeps?.deps || [])],
  );

  useEffectWithDeepCompareIf(
    !!throttledDeps,
    () => {
      handleComputeThrottled(_values);
    },
    [handleComputeThrottled, ...(throttledDeps?.deps || [])],
  );

  return null;
}
