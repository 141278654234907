import AppIcon from "@/common/components/Icons/AppIcon";
import { StringHelper } from "@/common/helpers/string";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import {
  TenantConnectionRequestDto,
  TenantConnectionRequestReferenceDto,
} from "@/core/api/generated";

import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";

export interface TenantConnectionRequestInlineProps extends BaseEntityInlineInheritableProps {
  entity: TenantConnectionRequestDto | TenantConnectionRequestReferenceDto | null | undefined;
  entityId?: string | null;
}

/** Inline brief information about the entity. */
export default function TenantConnectionRequestInline({
  entity,
  entityId,
  ...otherProps
}: TenantConnectionRequestInlineProps) {
  const request = useApiRequest(
    apiClient.tenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      tenantRequestId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as TenantConnectionRequestDto;
  // const ref = entity as TenantConnectionRequestReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      icon={<AppIcon of='company' inText />}
      content={<>{StringHelper.joinIntoString([full?.globalNumber], ", ", { skipEmpty: true })}</>}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Company connection request"}
    />
  );
}
