import { FeatureNames } from "../featureManagement/types";

export const FeatureName = {
  RealtimeDataUpdates: "RealtimeDataUpdates",
  ChatOnlineStatus: "ChatOnlineStatus",
  ChatActivity: "ChatActivity",
  ChatTypingIndicatorEnabled: "ChatTypingIndicatorEnabled",
  ChatMessageReadStatus: "ChatMessageReadStatus",
  RepairSpecCustomItemAdd: "RepairSpecCustomItemAdd",
  CrossTenantRepairSpecAccess: "CrossTenantRepairSpecAccess",
} as const;
export type FeatureName = (typeof FeatureName)[keyof typeof FeatureName];

export const featureNames: FeatureNames = FeatureName;
