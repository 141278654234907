import { Button, Stack, SxProps, Theme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIconButton from "@/common/components/Button/AppIconButton";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import AppPopover from "@/common/components/Popover/AppPopover";
import AppPopoverContent from "@/common/components/Popover/AppPopoverContent";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import { BooleanStatusDisplay } from "@/common/components/StatusDisplay/BooleanStatusDisplay";
import TableCellContentOfAttachments from "@/common/components/Table/TableCell/TableCellContentOfAttachments";
import TableCellContentOfCount from "@/common/components/Table/TableCell/TableCellContentOfCount";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { EntityHelper } from "@/common/helpers/entity";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ApiEnumName } from "@/common/services/enum";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  AssetSubscriptionPlanDto,
  AssetSubscriptionPlanGetPaginatedDto,
  DataUpdatesHubClientMethodName,
  EntityType,
} from "@/core/api/generated";

import DataTabular, { TabularProps } from "../../../DataTabular/DataTabular";
import InlineApiEnumValue from "../../../Enum/InlineApiEnumValue";
import AppIcon from "../../../Icons/AppIcon";
import AffiliationInfoDisplay from "../../EntityAffiliation/AffiliationInfoDisplay";
import GeneralCountryDisplay from "../../General/Display/GeneralCountryDisplay";
import GeneralCurrencyDisplay from "../../General/Display/GeneralCurrencyDisplay";
import TenantStructureMembershipOfEntityEditModal from "../../Membership/TenantStructureMembershipOfEntityEditModal";
import PoolLink from "../../Pool/PoolLink";
import ProductLocationInline from "../../ProductLocation/ProductLocationInline";
import AssetSubscriptionPlanAvailabilityDisplay from "../AssetSubscriptionPlanAvailabilityDisplay";
import AssetSubscriptionPlanMenu from "../AssetSubscriptionPlanMenu";
import AssetSubscriptionPlansDeleteModal from "../AssetSubscriptionPlansDeleteModal";

enum BulkActionFlags {
  UpdateTenantStructureMembership = "UpdateTenantStructureMembership",
  Delete = "Delete",
}
const defaultDisplayProps = {
  breadcrumbs: true,
  filters: true,
  create: true,
  edit: true,
  delete: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultPropagatedDeps = {
  refetch: {},
};
export type AssetSubscriptionPlanPaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;

export interface AssetSubscriptionPlanPaginatedListOwnProps {
  displayProps?: Partial<typeof defaultDisplayProps>;
  defaultValues?: {
    limit?: number;
  };
  propagatedDeps?: PropagatedDeps<AssetSubscriptionPlanPaginatedListPropagatedDepsMap>;
  dataTabularProps?: Partial<TabularProps<AssetSubscriptionPlanDto>>;
  sx?: SxProps<Theme>;
}

export type AssetSubscriptionPlanPaginatedListProps = AssetSubscriptionPlanPaginatedListOwnProps;

export default function AssetSubscriptionPlanPaginatedList({
  displayProps,
  defaultValues,
  propagatedDeps,
  dataTabularProps,
  sx,
}: AssetSubscriptionPlanPaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<AssetSubscriptionPlanGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.AssetSubscriptionPlan,
    },
    defaultValues: {
      limit: defaultValues?.limit,
      params: {
        ...defaultValues,
      },
    },
  });

  const paginatedAssetSubscriptionPlansRequest = useApiRequest(
    apiClient.assetSubscriptionPlansApi.apiV1AssetSubscriptionPlansGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assetSubscriptionPlanGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps, propagatedDeps?.depsMap["refetch"]],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );

  const paginatedAssetSubscriptionPlans = paginatedAssetSubscriptionPlansRequest.data;

  useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.AssetSubscriptionPlan),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedAssetSubscriptionPlansRequest.handleEntityChanged(data);
    },
  });

  return (
    <ViewLayoutV2
      displayProps={displayProps}
      header={
        <SimpleViewPageHeader
          title='Asset subscription plans'
          primaryActions={
            displayProps?.create && (
              <AuthorizedElement permissionsAny={[AppPermission.AssetSubscriptionPlanManage]}>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<AppIcon of='add' />}
                  component={RouterLink}
                  to={ROUTE_PATH.ASSET_SUBSCRIPTION_PLAN_CREATE}
                >
                  Create new asset subscription plan
                </Button>
              </AuthorizedElement>
            )
          }
        />
      }
    >
      <DataTabular
        {...dataTabularProps}
        columns={[
          {
            field: getTypedPath<AssetSubscriptionPlanDto>().id.$path,
            title: "ID",
            width: 250,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.id,
            filters: {
              fieldType: FilterFieldType.Id,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AssetSubscriptionPlanDto>().localNumber.$path,
            title: "Number",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <Stack direction='row' spacing={0.5}>
                <span>{item.localNumber}</span>
              </Stack>
            ),
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: "Info badges",
            title: "Info badges",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => <EntityChipList entity={item} variant='compact' />,
          },
          {
            field: getTypedPath<AssetSubscriptionPlanDto>().isEnabled.$path,
            title: "Enabled",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <BooleanStatusDisplay
                value={item.isEnabled}
                statusProps={{
                  variant: "compact",
                  title: item.isEnabled ? "Enabled" : "Disabled",
                  size: "medium",
                }}
              />
            ),
          },
          {
            field: getTypedPath<AssetSubscriptionPlanDto>().name.$path,
            title: "Name",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.name || "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AssetSubscriptionPlanDto>().description.$path,
            title: "Description",
            width: 200,
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => item.description || "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AssetSubscriptionPlanDto>().assetSpec.entityType.$path,
            title: "Asset type",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <InlineApiEnumValue type='AssetEntityType' value={item.assetSpec?.entityType} />
            ),
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: true,
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.AssetEntityType,
                },
              },
            },
          },
          {
            field: getTypedPath<AssetSubscriptionPlanDto>().country.name.$path,
            title: "Country",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.country ? <GeneralCountryDisplay country={item.country} /> : "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AssetSubscriptionPlanDto>().currency.code.$path,
            title: "Currency",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.currency ? <GeneralCurrencyDisplay currency={item.currency} /> : "-",
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: true,
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.CurrencyCode,
                },
              },
            },
          },
          {
            field: getTypedPath<AssetSubscriptionPlanDto>().basePrice.total.$path,
            title: "Base price",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <AppPopover
                hoverBehavior={{}}
                trigger={
                  <AppTypography decoration={{ variant: "helpText" }}>
                    <CurrencyValue value={item.basePrice?.total} currency={item.currency} />
                  </AppTypography>
                }
              >
                <AppPopoverContent>
                  <GeneralPriceSummaryDisplay
                    direction='column'
                    summary={{
                      currency: item.currency,
                      subTotal: item.basePrice?.subTotal,
                      subTotalIncDiscount: item.basePrice?.subTotalIncDiscount,
                      discount: item.basePrice?.discount || undefined,
                      tax: item.basePrice?.tax || undefined,
                      insurance: item.basePrice?.insurance || undefined,
                      total: item.basePrice?.total || 0,
                    }}
                    displayProps={{
                      subTotal: true,
                      subTotalIncDiscount: true,
                      discount: true,
                      tax: true,
                      insurance: true,
                      total: true,
                    }}
                  />
                </AppPopoverContent>
              </AppPopover>
            ),
            filters: {
              fieldType: FilterFieldType.Number,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AssetSubscriptionPlanDto>().attachments.$path,
            title: "Attachments",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => (
              <TableCellContentOfAttachments attachments={item.allAttachments} />
            ),
          },
          {
            field: getTypedPath<AssetSubscriptionPlanDto>().image.$path,
            title: "Image",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => (
              <TableCellContentOfAttachments attachments={item.image ? [item.image] : undefined} />
            ),
          },
          {
            field: getTypedPath<AssetSubscriptionPlanDto>().images.$path,
            title: "Images",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => <TableCellContentOfAttachments attachments={item.images} />,
          },
          {
            field: getTypedPath<AssetSubscriptionPlanDto>().productLocations.$path,
            title: "Sales locations",
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => (
              <TableCellContentOfCount
                count={item.productLocations?.length}
                popoverContent={
                  <Stack>
                    {item.productLocations?.map((x, i) => (
                      <ProductLocationInline key={i} entity={x.productLocation} />
                    ))}
                  </Stack>
                }
              />
            ),
          },
          {
            field: getTypedPath<AssetSubscriptionPlanDto>().poolIds.$path,
            title: "Pools",
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => (
              <TableCellContentOfCount
                count={item.poolIds?.length}
                popoverContent={
                  <Stack>
                    {item.poolIds?.map((id, i) => (
                      <PoolLink key={i} entity={undefined} entityId={id} />
                    ))}
                  </Stack>
                }
              />
            ),
          },
          {
            field: getTypedPath<AssetSubscriptionPlanDto>().prices.$path,
            title: "Prices",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => <TableCellContentOfCount count={item.prices?.length} />,
          },
          {
            field: getTypedPath<AssetSubscriptionPlanDto>().durations.$path,
            title: "Durations",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => <TableCellContentOfCount count={item.durations?.length} />,
          },
          {
            field: getTypedPath<AssetSubscriptionPlanDto>().includedOptions.$path,
            title: "Included options",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => <TableCellContentOfCount count={item.includedOptions?.length} />,
          },
          {
            field: getTypedPath<AssetSubscriptionPlanDto>().extraOptions.$path,
            title: "Extra options",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => <TableCellContentOfCount count={item.extraOptions?.length} />,
          },
          {
            field: "availability",
            title: "Availability",
            width: 300,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => (
              <AssetSubscriptionPlanAvailabilityDisplay
                plan={item}
                availability={undefined}
                isLoad
                variant='compact'
              />
            ),
          },
          {
            field: getTypedPath<AssetSubscriptionPlanDto>().createdAt.$path,
            title: "Created at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AssetSubscriptionPlanDto>().updatedAt.$path,
            title: "Updated at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AssetSubscriptionPlanDto>().createdBy.$path,
            title: "Created by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<AssetSubscriptionPlanDto>().updatedBy.$path,
            title: "Updated by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: "affiliation",
            title: "Affiliation",
            description: "Affiliation to departments & locations",
            width: 120,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            isToDisabled: true,
            renderCell: (item) => (
              <AffiliationInfoDisplay
                variant='compact'
                tenantId={item.tenantId}
                departmentIds={item.departmentIds}
                locationIds={item.locationIds}
              />
            ),
          },
        ]}
        isLoading={paginatedAssetSubscriptionPlansRequest.isLoading}
        rows={paginatedAssetSubscriptionPlans?.items}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.ASSET_SUBSCRIPTION_PLAN_VIEW(item.id)}
        renderRowAction={({ item }) => (
          <AssetSubscriptionPlanMenu
            entity={item}
            onDelete={() => paginatedAssetSubscriptionPlansRequest.refetch()}
            onUpdate={() => paginatedAssetSubscriptionPlansRequest.refetch()}
            displayProps={{
              actions: {
                edit: displayProps?.edit || false,
                delete: displayProps?.delete || false,
                createAssetSubscription: true,
              },
            }}
          />
        )}
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
        refetch={commonRequestParams.dataTabularProps.refetch}
        bulkActions={{
          enabled: true,
          definition: BulkActionFlags,
          actionTriggers: ({ currentAction, startAction, selectedIds }) => (
            <>
              <AppIconButton
                tooltipProps={{ title: "Update affiliation" }}
                onClick={() => startAction(BulkActionFlags.UpdateTenantStructureMembership)}
              >
                <AppIcon of='department' />
              </AppIconButton>

              <AppIconButton
                tooltipProps={{ title: "Delete" }}
                onClick={() => startAction(BulkActionFlags.Delete)}
              >
                <AppIcon of='delete' />
              </AppIconButton>
            </>
          ),
          actionHandlers: ({ selectedIds, currentAction, cancelAction, completeAction }) => (
            <>
              <AssetSubscriptionPlansDeleteModal
                entities={
                  EntityHelper.filterEntitiesByIds(
                    paginatedAssetSubscriptionPlans?.items || [],
                    selectedIds as string[],
                  ) as AssetSubscriptionPlanDto[]
                }
                open={currentAction === BulkActionFlags.Delete}
                onClose={() => cancelAction()}
                onDelete={() => {
                  completeAction();
                  paginatedAssetSubscriptionPlansRequest.refetch();
                }}
              />
              <TenantStructureMembershipOfEntityEditModal
                open={currentAction === BulkActionFlags.UpdateTenantStructureMembership}
                onClose={() => cancelAction()}
                tenantStructureMembershipOfEntityEditProps={{
                  entityType: EntityType.AssetSubscriptionPlan,
                  entities: EntityHelper.filterEntitiesByIds(
                    paginatedAssetSubscriptionPlans?.items || [],
                    selectedIds as string[],
                  ),
                  onSave: () => {
                    completeAction();
                    paginatedAssetSubscriptionPlansRequest.refetch();
                  },
                }}
              />
            </>
          ),
        }}
      />
    </ViewLayoutV2>
  );
}
