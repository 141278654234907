import { EntityFormInfo } from "@/common/ts/entity";
import { DamageDetectionDto } from "@/core/api/generated";

export interface DamageDetectionFormPermissions {
  canEdit: boolean;
  canEditVehicle: boolean;
  canEditContract: boolean;
  canSplitItem: boolean;
  canMergeItems: boolean;
}

export class DamageDetectionHelper {
  public static getEntityFormPermissions(params: {
    formInfo: EntityFormInfo;
    damageDetection: Nil<DamageDetectionDto>;
  }): DamageDetectionFormPermissions {
    return {
      canEdit: true, // params.formInfo.isCreate,
      canEditVehicle: params.formInfo.isCreate,
      canEditContract: false,
      canSplitItem: params.formInfo.isUpdate,
      canMergeItems: params.formInfo.isUpdate,
    };
  }
}
