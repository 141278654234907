import { Stack } from "@mui/material";
import { useEffect } from "react";

import { DamageCostEvaluationListPageLocationState } from "@/App/MainAppView/DamageCostEvaluations/DamageCostEvaluationsPage";
import { MaintenanceListPageLocationState } from "@/App/MainAppView/Maintenances/MaintenancesPage";
import { RepairOperationListPageLocationState } from "@/App/MainAppView/RepairOperations/RepairOperationsPage";
import { TenantRequestListPageLocationState } from "@/App/MainAppView/TenantToTenant/TenantRequest/TenantRequestsPage";
import { WashListPageLocationState } from "@/App/MainAppView/Washes/WashesPage";
import { WheelOperationListPageLocationState } from "@/App/MainAppView/WheelOperation/WheelOperationsPage";
import AppIcon from "@/common/components/Icons/AppIcon";
import { ROUTE_PATH } from "@/common/constants/routing";
import { WidgetHelper } from "@/common/helpers/widget";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { cast } from "@/common/ts/conversions";
import { apiClient } from "@/core/api/ApiClient";

import AppTypography from "../../Text/AppTypography";
import BaseWidget from "../Base/BaseWidget";
import WidgetContent from "../Base/WidgetContent";
import WidgetHeader from "../Base/WidgetHeader";
import WidgetTitle from "../Base/WidgetTitle";
import WidgetTitleDefaultActions from "../Base/WidgetTitleDefaultActions";
import CounterWidgetCard from "../General/Counter/CounterWidgetCard";
import CounterWidgetCardGrid from "../General/Counter/CounterWidgetCardGrid";
import CounterWidgetContent from "../General/Counter/CounterWidgetContent";

export default function ActiveOperationCountWidget() {
  const dataRequest = useApiRequest(
    apiClient.widgetDataApi.apiV1WidgetDataActiveOperationSummaryGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
    {
      deps: [],
    },
  );
  const data = dataRequest?.data;

  useEffect(() => {
    return WidgetHelper.handleRefetchAndRecomputeDataRequestInEffect((requestParams) => {
      dataRequest.refetchWithNewParams({
        widgetDataGetBaseDto: {
          ...requestParams,
        },
      });
    });
  }, [dataRequest]);

  return (
    <BaseWidget>
      <WidgetHeader>
        <WidgetTitle
          icon={<AppIcon of='dataConnected' />}
          actions={
            <WidgetTitleDefaultActions
              data={data}
              onRecompute={async (requestParams) => {
                await dataRequest.refetchWithNewParams({
                  widgetDataGetBaseDto: {
                    ...requestParams,
                  },
                });
              }}
            />
          }
        >
          Active operations
        </WidgetTitle>
      </WidgetHeader>

      <WidgetContent>
        <Stack>
          <CounterWidgetCardGrid>
            <CounterWidgetCard>
              <CounterWidgetContent
                title={
                  <AppTypography
                    isInheritFontStyle
                    decoration={{ variant: "helpText" }}
                    tooltipProps={{ title: "Damage cost evaluations." }}
                  >
                    Damage costs
                  </AppTypography>
                }
                icon={<AppIcon of='damageCostEvaluation' />}
                isLoading={dataRequest.isLoading}
                counter={data?.damageCostEvaluation?.count}
                counterLinkProps={{
                  to: ROUTE_PATH.DAMAGE_COST_EVALUATIONS,
                  state: cast<DamageCostEvaluationListPageLocationState>({
                    initialValues: {
                      filterDefinitionDto: data?.damageCostEvaluation?.filterDefinition,
                    },
                  }),
                }}
              />
            </CounterWidgetCard>

            <CounterWidgetCard>
              <CounterWidgetContent
                title='Repairs'
                icon={<AppIcon of='repairOperation' />}
                isLoading={dataRequest.isLoading}
                counter={data?.repairOperation?.count}
                counterLinkProps={{
                  to: ROUTE_PATH.REPAIR_OPERATIONS,
                  state: cast<RepairOperationListPageLocationState>({
                    initialValues: { filterDefinitionDto: data?.repairOperation?.filterDefinition },
                  }),
                }}
              />
            </CounterWidgetCard>

            <CounterWidgetCard>
              <CounterWidgetContent
                title='Maintenance'
                icon={<AppIcon of='maintenance' />}
                isLoading={dataRequest.isLoading}
                counter={data?.maintenance?.count}
                counterLinkProps={{
                  to: ROUTE_PATH.MAINTENANCES,
                  state: cast<MaintenanceListPageLocationState>({
                    initialValues: { filterDefinitionDto: data?.maintenance?.filterDefinition },
                  }),
                }}
              />
            </CounterWidgetCard>

            <CounterWidgetCard>
              <CounterWidgetContent
                title='Wash'
                icon={<AppIcon of='wash' />}
                isLoading={dataRequest.isLoading}
                counter={data?.wash?.count}
                counterLinkProps={{
                  to: ROUTE_PATH.WASHES,
                  state: cast<WashListPageLocationState>({
                    initialValues: { filterDefinitionDto: data?.wash?.filterDefinition },
                  }),
                }}
              />
            </CounterWidgetCard>

            <CounterWidgetCard>
              <CounterWidgetContent
                title='Wheels & tires'
                icon={<AppIcon of='wheelOperation' />}
                isLoading={dataRequest.isLoading}
                counter={data?.wheelOperation?.count}
                counterLinkProps={{
                  to: ROUTE_PATH.WHEEL_OPERATIONS,
                  state: cast<WheelOperationListPageLocationState>({
                    initialValues: { filterDefinitionDto: data?.wheelOperation?.filterDefinition },
                  }),
                }}
              />
            </CounterWidgetCard>

            <CounterWidgetCard>
              <CounterWidgetContent
                title={
                  <AppTypography
                    isInheritFontStyle
                    decoration={{ variant: "helpText" }}
                    tooltipProps={{ title: "Company-to-company requests." }}
                  >
                    C2C requests
                  </AppTypography>
                }
                icon={<AppIcon of='tenantRequest' />}
                isLoading={dataRequest.isLoading}
                counter={data?.tenantRequest?.count}
                counterLinkProps={{
                  to: ROUTE_PATH.TENANT_REQUESTS,
                  state: cast<TenantRequestListPageLocationState>({
                    initialValues: { filterDefinitionDto: data?.tenantRequest?.filterDefinition },
                  }),
                }}
              />
            </CounterWidgetCard>
          </CounterWidgetCardGrid>
        </Stack>
      </WidgetContent>
    </BaseWidget>
  );
}
