import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

// Figma name: Icon/Fill/close-circle
export default forwardRef<SVGSVGElement, SvgIconProps>(function ArrowDownLeft(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icon/Outline/corner-down-left'>
        <path
          id='Mask'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M20.4705 5.996C20.4685 5.445 20.0205 5 19.4705 5H19.4665C18.9145 5.002 18.4685 5.452 18.4705 6.004L18.4915 11.004C18.4915 11.271 18.3895 11.523 18.2015 11.712C18.0125 11.902 17.7625 12.007 17.4955 12.008L8.57651 12.045L11.2625 8.659C11.6055 8.226 11.5325 7.597 11.1005 7.254C10.6685 6.911 10.0395 6.985 9.69551 7.416L5.71651 12.432C5.42551 12.799 5.42851 13.317 5.72151 13.682L9.74151 18.665C9.93951 18.91 10.2285 19.037 10.5205 19.037C10.7415 19.037 10.9635 18.964 11.1485 18.815C11.5775 18.469 11.6455 17.839 11.2985 17.409L8.58451 14.045L17.5035 14.008C18.3045 14.005 19.0565 13.69 19.6215 13.121C20.1855 12.552 20.4945 11.797 20.4915 10.996L20.4705 5.996Z'
        />
        <mask id='mask0_3195_22401' maskUnits='userSpaceOnUse' x='5' y='5' width='16' height='15'>
          <path
            id='Mask_2'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M20.4705 5.996C20.4685 5.445 20.0205 5 19.4705 5H19.4665C18.9145 5.002 18.4685 5.452 18.4705 6.004L18.4915 11.004C18.4915 11.271 18.3895 11.523 18.2015 11.712C18.0125 11.902 17.7625 12.007 17.4955 12.008L8.57651 12.045L11.2625 8.659C11.6055 8.226 11.5325 7.597 11.1005 7.254C10.6685 6.911 10.0395 6.985 9.69551 7.416L5.71651 12.432C5.42551 12.799 5.42851 13.317 5.72151 13.682L9.74151 18.665C9.93951 18.91 10.2285 19.037 10.5205 19.037C10.7415 19.037 10.9635 18.964 11.1485 18.815C11.5775 18.469 11.6455 17.839 11.2985 17.409L8.58451 14.045L17.5035 14.008C18.3045 14.005 19.0565 13.69 19.6215 13.121C20.1855 12.552 20.4945 11.797 20.4915 10.996L20.4705 5.996Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_3195_22401)'>
          <g id='&#240;&#159;&#142;&#168; Color'>
            <rect id='Base' x='0.5' width='24' height='24' />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
});
