export class VehicleHelper {
  /**
   * Returns a list of country codes (ISO 3166-1 alpha-2) for which auto-prefill is supported.
   * @returns {string[]} List of country codes.
   */
  public static getAllowedCountriesAlpha2CodesForPrefill(): string[] {
    return ["NO"];
  }

  /**
   * Validates a plate number format for a given country.
   * @param {string} countryCode - The ISO 3166-1 alpha-2 country code.
   * @param {string} plateNo - The plate number to validate.
   * @returns {boolean} True if the plate number format is valid, false otherwise.
   */
  public static isPlateNoFormatValid(countryCode: string, plateNo: string): boolean {
    if (!plateNo) return false;

    if (countryCode === "NO") {
      // Regex explanation:
      //  - Start of string
      //  - 2 uppercase letters
      //  - 3 to 5 digits
      //  - End of string
      const norRegEx = /^[A-Z]{2}\d{3,5}$/i;
      return norRegEx.test(plateNo.trim());
    }

    return true;
  }

  /**
   * Validates an identification number format.
   * @param {string} idNumber - The identification number to validate.
   * @returns {boolean} True if the identification number format is valid, false otherwise.
   */
  public static isIdentificationNumberValid(idNumber: string): boolean {
    if (!idNumber) return false;

    // Regex explanation:
    //  - Start of string
    //  - At least 5 alphanumeric characters (uppercase letters and digits)
    //  - End of string
    const idNumberRegEx = /^[A-Z0-9]{5,}$/i;
    return idNumberRegEx.test(idNumber.trim());
  }
}
