import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

// Figma name: Icon/Fill/close-circle
export default forwardRef<SVGSVGElement, SvgIconProps>(function ExpandCircleUp(
  props: SvgIconProps,
  ref,
) {
  return (
    <ExpandCircleDownIcon ref={ref} {...props} sx={{ transform: "rotate(180deg)", ...props.sx }} />
  );
});
