import { Box, CircularProgress, LinearProgress, Stack } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

import EmbeddedChat from "@/App/MainAppView/Chats/components/EmbeddedChat";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import { apiClient } from "@/core/api/ApiClient";
import { AssistantSubjectType, ChatType } from "@/core/api/generated";
import * as appCommonSlice from "@/store/appCommon/slice";

import AppTooltip from "../../AppTooltip";
import AppIconButton from "../../Button/AppIconButton";
import AppIcon from "../../Icons/AppIcon";

export default function WebAppAssistantSideBar() {
  const dispatch = useAppThunkDispatch();

  const isAssistantOpened = useAppSelector((x) => x.app.isAssistantOpened);
  const [isAssistantAnswerLoading, setIsAssistantAnswerLoading] = useState(false);

  const sessionRequest = useApiRequest(
    apiClient.assistantSessionsApi.apiV1AssistantSessionsGetOrInitPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      subject: AssistantSubjectType.WebApp,
    },
    {
      skip: !isAssistantOpened,
      deps: [isAssistantOpened],
    },
  );
  const session = useMemo(() => sessionRequest.data, [sessionRequest.data]);

  const startNew = useCallback(async () => {
    if (sessionRequest.isLoading) {
      return;
    }
    if (!session) {
      return;
    }
    // Close current session
    await apiClient.assistantSessionsApi.apiV1AssistantSessionsSessionIdDelete({
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      sessionId: session!.id || "",
    });
    // Start new session
    sessionRequest.refetch();
  }, [session, sessionRequest.isLoading]);

  const onClose = useCallback(() => {
    dispatch(appCommonSlice.hideAssistantBlock());
  }, [dispatch, appCommonSlice.hideAssistantBlock]);

  const requestAssistant = useCallback(async () => {
    // request assistant to answer
    try {
      setIsAssistantAnswerLoading(true);
      await apiClient.assistantSessionsApi.apiV1AssistantSessionsSessionIdCompletionPost({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
        sessionId: session!.id || "",
      });
    } finally {
      setIsAssistantAnswerLoading(false);
    }
  }, [session, isAssistantAnswerLoading]);

  const isLoading = !isAssistantOpened || !session || sessionRequest.isLoading;

  if (!isAssistantOpened) {
    return null;
  }

  return (
    <Box
      sx={{
        bottom: 50,
        right: 50,
        width: "400px",
        height: "fill-available",
        backgroundColor: "white",
      }}
    >
      {isLoading && <LinearProgress />}
      {!isLoading && (
        <Stack direction='column' sx={{ height: "100%", p: 2 }}>
          {/* Actions */}
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            {isAssistantAnswerLoading && <CircularProgress size={24} />}
            {!isAssistantAnswerLoading && (
              <AppTooltip title='Start new chat'>
                <AppIconButton onClick={startNew}>
                  <AppIcon of='refresh' />
                </AppIconButton>
              </AppTooltip>
            )}
            <AppTooltip title='Close assistant'>
              <AppIconButton onClick={onClose}>
                <AppIcon of='close' />
              </AppIconButton>
            </AppTooltip>
          </Stack>
          {/* Chat */}
          <EmbeddedChat
            chatType={ChatType.Assistant}
            chatId={session.chat?.chatId}
            chatProps={{
              size: "medium",
              allowLeave: false,
              allowAttachments: false,
              fullWidth: false,
              fullHeight: false,
              onSendMessage: requestAssistant,
              disabled: isAssistantAnswerLoading,
            }}
          />
        </Stack>
      )}
    </Box>
  );
}
