import AssessmentFlowPaginatedList from "@/common/components/Entity/AssessmentFlow/ListView/AssessmentFlowPaginatedList";
import { useLocationState } from "@/common/hooks/useLocationParams";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import { ContractAssessmentFlowStateFilterType, FilterDefinitionDto } from "@/core/api/generated";

export interface AssessmentFlowsPageQueryParams extends GeneralQueryParams {
  vehicleId?: string;
  contractId?: string;
  customerId?: string;
}

export interface AssessmentFlowListPageLocationState {
  initialValues?: {
    filterDefinitionDto?: Nil<FilterDefinitionDto>;
    isResetFilterDefinition?: boolean;
    filterType?: ContractAssessmentFlowStateFilterType;
  };
}

export default function AssessmentFlowsPage() {
  const queryParams = useQueryParams<AssessmentFlowsPageQueryParams>();
  const locationState = useLocationState<AssessmentFlowListPageLocationState>();

  return (
    <>
      <AssessmentFlowPaginatedList
        initialValues={locationState?.initialValues}
        defaultValues={{ ...queryParams }}
      />
    </>
  );
}
