import {
  Box,
  Chip,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import _ from "lodash";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { StringHelper } from "@/common/helpers/string";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { RepairSparePartDto, RepairSparePartSearchPaginatedDto } from "@/core/api/generated";

import AppTypography from "../../Text/AppTypography";
import RepairSparePartViewModal from "./View/RepairSparePartViewModal";

export type RepairSparePartAutocompleteOption = BaseAutocompleteOption<RepairSparePartDto>;

export const getRepairSparePartOptionTitle = (data: RepairSparePartDto): string =>
  StringHelper.joinIntoString([data.name || data.localNumber], ", ", {
    skipEmpty: true,
  });

export const repairSparePartToOption = (
  data: RepairSparePartDto,
): RepairSparePartAutocompleteOption => ({
  id: data.id!,
  title: getRepairSparePartOptionTitle(data),
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface RepairSparePartAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<RepairSparePartDto> {
  searchFilters?: Partial<Omit<RepairSparePartSearchPaginatedDto, "search" | "includeIds">>;
}

export default function RepairSparePartAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: RepairSparePartAutocompleteProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={repairSparePartToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.repairSparePartApi.apiV1RepairSparePartsSearchPost,
        limit: 25,
        parameters: {
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          repairSparePartSearchPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          repairSparePartSearchPaginatedDto: {
            ...params.repairSparePartSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Repair spare part'
      placeholder='Search for repair spare part...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='repairSparePart' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant={
                    option.optionType === AutocompleteOptionType.DynamicCreateNew
                      ? "subtitle1"
                      : "body1"
                  }
                  component='div'
                >
                  <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Box>{option.data?.name || option.title}</Box>

                    {!_.isNil(option.data?.matchMeta?.score) && (
                      <Box>
                        <Tooltip title='The bigger the match score the better the result suits your request.'>
                          <Typography variant='caption'>
                            <Chip
                              size='extraSmall'
                              variant='filled'
                              label={<>Match score: {option.data?.matchMeta?.score} / 100</>}
                            />
                          </Typography>
                        </Tooltip>
                      </Box>
                    )}
                  </Stack>
                </Typography>
              }
              secondary={
                option.data && (
                  <Box>
                    <AppTypography ellipsing={{ enabled: true }} component='div' variant='body2'>
                      {option.data?.partType?.name}
                    </AppTypography>
                  </Box>
                )
              }
            />
          </ListItem>
        );
      }}
      renderEntityViewModal={(params) => (
        <RepairSparePartViewModal
          {...params.dialogProps}
          viewProps={{
            repairSparePart: params.entity,
          }}
        />
      )}
      {...otherProps}
    />
  );
}
