import {
  Alert,
  Box,
  Card,
  CardContent,
  FormHelperText,
  LinearProgress,
  Stack,
  TextField,
} from "@mui/material";
import { getIn } from "formik";
import { useMemo } from "react";

import ImageUploader from "@/common/components/Files/ImageUploader";
import { FileItem } from "@/common/fileItem";
import { CustomFormikSubProps } from "@/common/helpers/formik";
import { MimeTypeHelper } from "@/common/helpers/mimeType";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { GeneralBrandingInputDto } from "@/core/api/generated";

interface Props {
  formikProps: CustomFormikSubProps<GeneralBrandingInputDto | null | undefined>;
  onUploadStarted?: () => void;
  onUploadFinished?: () => void;
}

export default function GeneralBrandingInputs({
  formikProps,
  onUploadStarted,
  onUploadFinished,
}: Props) {
  const { values, errors, setFieldValue } = formikProps;

  const brandingInputMetaRequest = useApiRequest(
    apiClient.generalBrandingApi.apiV1GeneralBrandingInputMetaGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
    {
      deps: [],
    },
  );
  const brandingInputMeta = brandingInputMetaRequest.data;

  const isLogoInputReady = !brandingInputMetaRequest.isLoading && brandingInputMeta;
  const fileInputAccept = useMemo(
    () =>
      brandingInputMeta
        ? MimeTypeHelper.buildFileInputAccept({
            fileExtensions: brandingInputMeta.logo?.allowedFileExtensions,
            mimeTypes: brandingInputMeta.logo?.allowedContentTypes,
          })
        : "image/png,image/jpeg,image/jpg",
    [brandingInputMeta],
  );

  return (
    <Card>
      <CardContent>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2} alignItems='flex-start'>
          <Stack direction='column' spacing={0} sx={{ flex: 1 }}>
            <TextField
              sx={{ flexGrow: 1 }}
              error={Boolean(getIn(errors, "fullLegalName"))}
              helperText={getIn(errors, "fullLegalName")}
              label='Full legal name'
              margin='dense'
              name='fullLegalName'
              size='small'
              onChange={(e) => setFieldValue("fullLegalName", e.target.value)}
              type='text'
              value={getIn(values, "fullLegalName") || ""}
              variant='outlined'
            />

            <TextField
              sx={{ flexGrow: 1 }}
              error={Boolean(getIn(errors, "shortName"))}
              helperText={getIn(errors, "shortName")}
              label='Short name'
              margin='dense'
              name='shortName'
              size='small'
              onChange={(e) => setFieldValue("shortName", e.target.value)}
              type='text'
              value={getIn(values, "shortName") || ""}
              variant='outlined'
            />

            <TextField
              sx={{ flexGrow: 1 }}
              error={Boolean(getIn(errors, "disclaimer"))}
              helperText={getIn(errors, "disclaimer")}
              label='Disclaimer'
              margin='dense'
              name='disclaimer'
              size='small'
              onChange={(e) => setFieldValue("disclaimer", e.target.value)}
              type='text'
              value={getIn(values, "disclaimer") || ""}
              variant='outlined'
            />
          </Stack>

          <Stack spacing={1} sx={{ flex: 1, pt: 1 }}>
            {!isLogoInputReady && <LinearProgress />}

            {isLogoInputReady && brandingInputMeta?.logo && (
              <>
                <Alert severity='info'>
                  <Stack spacing={1}>
                    {(brandingInputMeta?.logo?.minSize || brandingInputMeta?.logo?.maxSize) && (
                      <Box>
                        <Box>Limits:</Box>
                        {brandingInputMeta?.logo?.minSize && (
                          <Box>
                            Min size - {brandingInputMeta?.logo?.minSize?.width}x
                            {brandingInputMeta?.logo?.minSize?.height} px
                          </Box>
                        )}
                        {brandingInputMeta?.logo?.maxSize && (
                          <Box>
                            Max size - {brandingInputMeta?.logo?.maxSize?.width}x
                            {brandingInputMeta?.logo?.maxSize?.height} px
                          </Box>
                        )}
                      </Box>
                    )}

                    <Box>
                      You can crop your logo before saving (click edit button on an uploaded file).
                    </Box>
                  </Stack>
                </Alert>

                <ImageUploader
                  sx={{ flex: 1 }}
                  disabled={!isLogoInputReady}
                  accept={fileInputAccept}
                  onUploadStarted={onUploadStarted}
                  onUploadFinished={onUploadFinished}
                  multiple={false}
                  maxFiles={1}
                  fileUploadAreaProps={{
                    title: "Upload logo",
                  }}
                  defaultFiles={
                    (getIn(values, "logo") && FileItem.createManyFrom([getIn(values, "logo")])) ||
                    undefined
                  }
                  withImageEditor
                  imageEditorProps={{
                    cropProps: {
                      initialSize: brandingInputMeta?.logo?.maxSize,
                      minSize: brandingInputMeta?.logo?.minSize,
                      maxSize: brandingInputMeta?.logo?.maxSize,
                      isLocked: false,
                    },
                  }}
                  onChange={(newFiles) => {
                    newFiles && newFiles[0]
                      ? setFieldValue("logo", FileItem.toGeneralLogoInputDto(newFiles[0]))
                      : setFieldValue("logo", undefined);
                  }}
                />
              </>
            )}

            <FormHelperText error={Boolean(getIn(errors, "logo"))}>
              {getIn(errors, "logo") &&
                ValidationHelper.getFormikErrorsAsString(getIn(errors, "logo"))}
            </FormHelperText>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
