import { FormHelperText, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";

import useAppSnackbar from "@/common/hooks/useAppSnackbar";

import AppIconButton from "../../Button/AppIconButton";
import AppIcon from "../../Icons/AppIcon";
import ConfirmationModalWithTrigger, {
  ConfirmationModalWithTriggerProps,
} from "../ConfirmationModalWithTrigger";

interface Props
  extends Omit<ConfirmationModalWithTriggerProps, "trigger" | "body" | "title" | "onConfirm"> {
  trigger?: ConfirmationModalWithTriggerProps["trigger"];
  onConfirm?: (notes: string) => Promise<void>;
  initialValues?: { notes?: string | null | undefined };
}

const BaseEditNotesModal = ({ trigger, onConfirm, initialValues, ...otherProps }: Props) => {
  const { enqueueSnackbar } = useAppSnackbar();
  const [notes, setNotes] = useState(initialValues?.notes || "");

  return (
    <>
      <ConfirmationModalWithTrigger
        trigger={
          trigger || (
            <AppIconButton size='small'>
              <AppIcon of='edit' fontSize='small' />
            </AppIconButton>
          )
        }
        title='Edit notes'
        body={({ error }) => {
          return (
            <Stack>
              <Typography>To update notes enter new value and confirm the action.</Typography>

              <TextField
                autoFocus
                multiline
                rows={4}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />

              {error?.hasErrors && (
                <FormHelperText error>{error.getErrorsAsString()}</FormHelperText>
              )}
            </Stack>
          );
        }}
        onConfirm={async () => {
          await onConfirm?.(notes);
          enqueueSnackbar("Notes updated.", { variant: "success" });
        }}
        {...otherProps}
      />
    </>
  );
};

export default BaseEditNotesModal;
