import { TypographyProps } from "@mui/material";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import VehicleViewPageHeader from "@/common/components/Entity/Vehicle/View/Header/VehicleViewPageHeader";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { useAppHistory } from "@/common/hooks/useAppHistory";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  TagEntityType,
  WashDto,
} from "@/core/api/generated";

import GeneralAttachedTagsDisplay from "../../General/GeneralTag/GeneralAttachedTagsDisplay";
import BaseEntityView from "../../components/BaseEntityView";
import WashMenu from "../WashMenu";
import CommentsTabContent from "./Tabs/CommentsTabContent";
import GeneralHistoryTabContent from "./Tabs/GeneralHistoryTabContent";
import OverviewTabContent from "./Tabs/OverviewTabContent";
import VehicleTabContent from "./Tabs/VehicleTabContent";

export enum WashViewPageTabs {
  Overview = "Overview",
  Vehicle = "Vehicle",
  GeneralHistory = "GeneralHistory",
  Comments = "Comments",
}

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};
const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};
interface Props {
  washId: string | null | undefined;
  wash: WashDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

export default function WashView({ washId, wash, displayProps, headerProps }: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const history = useAppHistory();
  const currentTenant = useCurrentTenant();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.Wash),
      viewVariant: displayProps?.viewVariant,
    },
  });

  const washRequest = useApiRequest(
    apiClient.washesApi.apiV1WashesWashIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      washId: washId!,
    },
    {
      deps: [washId],
      skip: !washId,
    },
  );
  wash = wash || washRequest?.data;

  useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entity(currentTenant?.id, EntityType.Wash, washId || "")],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      washRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: wash && {
      idValue: wash?.id || "",
      newTitle: wash?.localNumber || "",
    },
  });

  return (
    <BaseEntityView
      entityType={EntityType.Wash}
      entityId={washId}
      entity={wash}
      entityRequest={washRequest}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          <SimpleViewPageHeader
            title={
              wash?.vehicle && (
                <VehicleViewPageHeader
                  vehicleId={wash?.vehicle?.id || ""}
                  vehicle={wash?.vehicle || undefined}
                  withLink
                  tags={
                    <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                      <GeneralAttachedTagsDisplay
                        tags={wash?.tags}
                        defaultIsFolded={false}
                        edit={{
                          entityType: TagEntityType.Wash,
                          entityId: wash?.id,
                          onSaved: (newValue) => {
                            washRequest.updateData((x) => {
                              x.tags = newValue || undefined;
                            });
                          },
                        }}
                      />
                    </AuthorizedElement>
                  }
                />
              )
            }
            primaryActions={
              wash && (
                <WashMenu
                  onDelete={() => history.goBack()}
                  onUpdate={(newValue) =>
                    newValue ? washRequest.replaceData(newValue) : washRequest.refetch()
                  }
                  entity={wash}
                />
              )
            }
          />
        }
      >
        <PageTabs
          tabIdsDefinition={WashViewPageTabs}
          defaultTabId={WashViewPageTabs.Overview}
          commonViewParams={commonViewParams}
          tabs={[
            { label: "Overview", value: WashViewPageTabs.Overview, isHideable: false },
            { label: "Vehicle", value: WashViewPageTabs.Vehicle },
            { label: "History", value: WashViewPageTabs.GeneralHistory },
            { label: "Comments", value: WashViewPageTabs.Comments },
          ]}
        >
          {({ activeTabId: activeTab }) =>
            wash && (
              <>
                {activeTab === WashViewPageTabs.Overview && (
                  <OverviewTabContent onUpdate={() => washRequest.refetch()} wash={wash} />
                )}
                {activeTab === WashViewPageTabs.Vehicle && <VehicleTabContent wash={wash} />}
                {activeTab === WashViewPageTabs.GeneralHistory && (
                  <GeneralHistoryTabContent wash={wash} />
                )}
                {activeTab === WashViewPageTabs.Comments && <CommentsTabContent wash={wash} />}
              </>
            )
          }
        </PageTabs>
      </ViewLayout>
    </BaseEntityView>
  );
}
