import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { ROUTE_PATH } from "@/common/constants/routing";

/** Handles navigation to root route '/'. */
export default function RootRouteEntry() {
  // const generalQueryParams = useQueryParams<GeneralQueryParams>();
  const history = useHistory();

  useEffect(() => {
    // redirect to dashboard by default
    history.push(ROUTE_PATH.DASHBOARD);
  }, []);

  return null;
}
