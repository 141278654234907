import {
  Button,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
  SxProps,
  Theme,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import EntityTagsInfoDisplay from "@/common/components/EntityInfo/EntityTagsInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import InlineApiEnumValueList from "@/common/components/Enum/InlineApiEnumValueList";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { usePageTabs } from "@/common/hooks/layout/usePageTabs";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataGrantDto,
  DataGrantGetPaginatedDto,
  DataUpdatesHubClientMethodName,
  EntityType,
  TagEntityType,
} from "@/core/api/generated";

import TenantLink from "../../Tenant/TenantLink";
import DataGrantDeleteModal from "../DataGrantDeleteModal";
import DataGrantEntityInline from "../DataGrantEntityInline";

export enum DataGrantPaginatedListTabs {
  All = "All",
  Incoming = "Incoming",
  Outcoming = "Outcoming",
}

const defaultDisplayProps = {
  breadcrumbs: true,
  counters: true,
  filters: true,
  create: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultPropagatedDeps = {
  refetch: {},
};
export type DataGrantPaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;

export interface DataGrantPaginatedListOwnProps {
  displayProps?: Partial<typeof defaultDisplayProps>;
  defaultValues?: Partial<DataGrantGetPaginatedDto>;
  propagatedDeps?: PropagatedDeps<DataGrantPaginatedListPropagatedDepsMap>;
  sx?: SxProps<Theme>;
}

export type DataGrantPaginatedListProps = DataGrantPaginatedListOwnProps;

export default function DataGrantPaginatedList({
  displayProps,
  defaultValues,
  propagatedDeps,
  sx,
}: DataGrantPaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const currentTenant = useCurrentTenant();
  const pageTabs = usePageTabs<DataGrantPaginatedListTabs>({
    tabIdsDefinition: DataGrantPaginatedListTabs,
    defaultTabId: DataGrantPaginatedListTabs.All,
    tabs: [],
  });
  const commonRequestParams = useCommonRequestParams<DataGrantGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.DataGrant,
    },
    defaultValues: {
      limit: defaultValues?.limit,
      params: {
        ...defaultValues,
      },
    },
  });

  const isCountersVisible = displayProps?.counters && !commonRequestParams.isAnyFilters;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [toDelete, setToDelete] = useState<DataGrantDto | undefined>(undefined);

  const countersRequest = useApiRequest(
    apiClient.dataGrantsApi.apiV1DataGrantsCountersGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
    { skip: !displayProps?.counters },
  );
  const counters = countersRequest?.data;

  const paginatedDataGrantsRequest = useApiRequest(
    apiClient.dataGrantsApi.apiV1DataGrantsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      dataGrantGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
        issuerTenantId:
          pageTabs.activeTabId === DataGrantPaginatedListTabs.Outcoming
            ? currentTenant?.id || undefined
            : undefined,
        consumerTenantId:
          pageTabs.activeTabId === DataGrantPaginatedListTabs.Incoming
            ? currentTenant?.id || undefined
            : undefined,
      },
    },
    {
      deps: [...commonRequestParams.deps, pageTabs.activeTabId, propagatedDeps?.depsMap["refetch"]],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedDataGrants = paginatedDataGrantsRequest?.data;

  useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entities(undefined, EntityType.DataGrant),
      DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.DataGrant),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      countersRequest.refetch();
      paginatedDataGrantsRequest.handleEntityChanged(data);
    },
  });

  const isIssuedByMe = (item: DataGrantDto) => currentTenant?.id === item?.issuerTenant?.id;
  // const isConsumedByMe = (item: DataGrantDto) => currentTenant?.id === item?.consumerTenant?.id;

  return (
    <ViewLayoutV2
      displayProps={displayProps}
      header={
        <SimpleViewPageHeader
          title='Data grants'
          primaryActions={
            displayProps?.create && (
              <AuthorizedElement permissionsAny={[AppPermission.DataGrantManage]}>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<AppIcon of='add' />}
                  component={RouterLink}
                  to={ROUTE_PATH.DATA_GRANT_CREATE({
                    tenantRequestId:
                      defaultValues?.tenantRequestsMeta?.tenantRequestId ||
                      defaultValues?.tenantRequestsMeta?.tenantRequestIds?.at(0) ||
                      undefined,
                  })}
                >
                  Create new data grant
                </Button>
              </AuthorizedElement>
            )
          }
        />
      }
    >
      <DataTabular<DataGrantDto>
        tabs={{
          value: pageTabs.activeTabId,
          onChange: (e, val) => pageTabs.setActiveTab(val),
          tabs: [
            {
              label: (
                <Box>
                  {DataGrantPaginatedListTabs.All}
                  {isCountersVisible && counters && (
                    <Tooltip title='Total'>
                      <Chip
                        sx={{ ml: 1 }}
                        size='small'
                        color='default'
                        variant='outlined'
                        label={counters?.totalCount ?? "..."}
                      />
                    </Tooltip>
                  )}
                </Box>
              ),
              value: DataGrantPaginatedListTabs.All,
            },
            {
              label: (
                <Box>
                  {DataGrantPaginatedListTabs.Incoming}
                  {isCountersVisible && counters && (
                    <Tooltip title='Total'>
                      <Chip
                        sx={{ ml: 1 }}
                        size='small'
                        color='default'
                        variant='outlined'
                        label={counters?.incoming?.totalCount ?? "..."}
                      />
                    </Tooltip>
                  )}
                </Box>
              ),
              value: DataGrantPaginatedListTabs.Incoming,
            },
            {
              label: (
                <Box>
                  {DataGrantPaginatedListTabs.Outcoming}
                  {isCountersVisible && counters && (
                    <Tooltip title='Total'>
                      <Chip
                        sx={{ ml: 1 }}
                        size='small'
                        color='default'
                        variant='outlined'
                        label={counters?.outcoming?.totalCount ?? "..."}
                      />
                    </Tooltip>
                  )}
                </Box>
              ),
              value: DataGrantPaginatedListTabs.Outcoming,
            },
          ],
        }}
        columns={[
          {
            field: "type",
            title: "Type",
            flex: 1,
            renderCell: (item) => (
              <Stack direction='row' spacing={0.5}>
                <InlineApiEnumValue type='DataGrantType' value={item.type} />
                {item.isAuto && (
                  <Tooltip title='This data grant is managed automatically.'>
                    <Chip variant='outlined' size='small' color='default' label='Auto' />
                  </Tooltip>
                )}
              </Stack>
            ),
          },
          {
            field: "entityId",
            title: "Entity",
            flex: 2,
            renderCell: (item) => (
              <DataGrantEntityInline
                value={item}
                inlineProps={{ withIcon: true, withTooltip: true }}
              />
            ),
          },
          {
            field: "issuerTenantId",
            title: "Issuer company",
            flex: 1,
            renderCell: (item) => (
              <Box>
                <TenantLink entity={item?.issuerTenant} entityId={undefined} />{" "}
                {currentTenant?.id === item?.issuerTenant?.id && (
                  <Chip size='small' variant='outlined' color='default' label='You' />
                )}
              </Box>
            ),
          },
          {
            field: "consumerTenantId",
            title: "Consumer company",
            flex: 1,
            renderCell: (item) => (
              <Box>
                <TenantLink entity={item?.consumerTenant} entityId={undefined} />{" "}
                {currentTenant?.id === item?.consumerTenant?.id && (
                  <Chip size='small' variant='outlined' color='default' label='You' />
                )}
              </Box>
            ),
          },
          {
            field: "permissions",
            title: "Permissions",
            flex: 0,
            renderCell: (item) => (
              <InlineApiEnumValueList
                type='DataGrantPermission'
                direction='row'
                values={item?.permissions}
                valueProps={{
                  withDescription: false,
                }}
              />
            ),
          },
          {
            field: "tags",
            title: "Tags",
            flex: 0,
            renderCell: (item) =>
              isIssuedByMe(item) ? (
                <EntityTagsInfoDisplay
                  entityType={TagEntityType.DataGrant}
                  entity={item}
                  noDataPlaceholder='-'
                  edit={{
                    onSaved: (newValue) =>
                      paginatedDataGrantsRequest.updateData((data) => {
                        data.items?.forEach((item2) => {
                          if (item2.id === item.id) {
                            item2.tags = newValue || undefined;
                          }
                        });
                      }),
                  }}
                />
              ) : (
                "-"
              ),
            isSortable: false,
            isColumnMenuDisabled: true,
            isToDisabled: true,
          },
        ]}
        rows={paginatedDataGrants?.items}
        isLoading={paginatedDataGrantsRequest.isLoading}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.DATA_GRANT_VIEW(item.id)}
        renderRowAction={({ item }) => (
          <MenuWithTrigger
            withAuthCloseOnClick
            trigger={
              <IconButton>
                <AppIcon of='moreVert' />
              </IconButton>
            }
          >
            {isIssuedByMe(item) && (
              <AuthorizedMenuItem
                permissions={[AppPermission.DataGrantManage]}
                component={RouterLink}
                to={ROUTE_PATH.DATA_GRANT_EDIT(item.id)}
                disabled={item.isAuto}
              >
                <ListItemIcon>
                  <AppIcon of='edit' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </AuthorizedMenuItem>
            )}
            {isIssuedByMe(item) && (
              <AuthorizedMenuItem
                permissions={[AppPermission.DataGrantManage]}
                disabled={item.isAuto}
                onClick={() => {
                  setToDelete(item);
                  setIsDeleteModalOpen(true);
                }}
              >
                <ListItemIcon>
                  <AppIcon of='delete' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </AuthorizedMenuItem>
            )}
          </MenuWithTrigger>
        )}
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
        refetch={commonRequestParams.dataTabularProps.refetch}
      />

      {/* Delete */}
      {toDelete && (
        <DataGrantDeleteModal
          dataGrant={toDelete}
          open={isDeleteModalOpen}
          onClose={() => {
            setIsDeleteModalOpen(false);
            setToDelete(undefined);
          }}
          onDelete={() => {
            paginatedDataGrantsRequest.refetch();
          }}
        />
      )}
    </ViewLayoutV2>
  );
}
