import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { MaintenanceDto, MaintenanceReferenceDto } from "@/core/api/generated";

import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getMaintenanceOptionTitle } from "./MaintenanceAutocomplete";

export interface MaintenanceInlineProps extends BaseEntityInlineInheritableProps {
  entity: MaintenanceDto | MaintenanceReferenceDto | null | undefined;
  entityId?: string | null;
}

export default function MaintenanceInline({
  entity,
  entityId,
  ...otherProps
}: MaintenanceInlineProps) {
  const request = useApiRequest(
    apiClient.maintenancesApi.apiV1MaintenancesMaintenanceIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      maintenanceId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as MaintenanceDto;
  // const ref = entity as MaintenanceReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='maintenance' inText />}
      content={<>{full && getMaintenanceOptionTitle(full)}</>}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Maintenance"}
    />
  );
}
