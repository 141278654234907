import { Box, FormControl, Stack, SxProps, TextField, Theme } from "@mui/material";

import { CustomFormikSubProps } from "@/common/helpers/formik";
import { GeneralHistoryEventOfArbitraryInputDto } from "@/core/api/generated";

import AppTextArea from "../../Form/Input/AppTextArea";

interface Props {
  formikProps: CustomFormikSubProps<GeneralHistoryEventOfArbitraryInputDto | null | undefined>;
  sx?: SxProps<Theme>;
}

export default function GeneralHistoryEventOfArbitraryInput({ formikProps, sx }: Props) {
  const { values, errors, setFieldValue } = formikProps;

  return (
    <Box sx={sx}>
      <Stack spacing={2}>
        <FormControl margin='none' fullWidth>
          <TextField
            fullWidth
            required
            type='text'
            variant='outlined'
            label='Name'
            value={values?.name || ""}
            error={Boolean(errors?.name)}
            helperText={errors?.name}
            onChange={(e) => {
              setFieldValue("name", e.target.value);
            }}
          />
        </FormControl>

        <FormControl margin='none' fullWidth>
          <AppTextArea
            fullWidth
            variant='outlined'
            rows={4}
            mode='description'
            value={values?.description || ""}
            error={Boolean(errors?.description)}
            helperText={errors?.description}
            onChange={(e) => {
              setFieldValue("description", e.target.value);
            }}
          />
        </FormControl>
      </Stack>
    </Box>
  );
}
