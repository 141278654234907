import { RefObject, useEffect, useState } from "react";

import { InViewOptions, inView } from "@/sources/motion/framer-motion/src/render/dom/viewport";

export interface UseInViewOptions extends Omit<InViewOptions, "root" | "amount"> {
  root?: RefObject<Element | null>;
  once?: boolean;
  amount?: "some" | "all" | number;
}

/** Detects when the provided element is within the viewport.
 * Source:
 * https://motion.dev/docs/react-use-in-view
 * https://github.com/motiondivision/motion/blob/main/packages/framer-motion/src/utils/use-in-view.ts
 */
export function useInViewport(
  ref: RefObject<Element | null>,
  { root, margin, amount, once = false }: UseInViewOptions = {},
) {
  const [isInView, setInView] = useState(false);

  useEffect(() => {
    if (!ref.current || (once && isInView)) return;

    const onEnter = () => {
      setInView(true);

      return once ? undefined : () => setInView(false);
    };

    const options: InViewOptions = {
      root: (root && root.current) || undefined,
      margin,
      amount,
    };

    return inView(ref.current, onEnter, options);
  }, [root, ref, margin, once, amount]);

  return isInView;
}
