import { createSlice } from "@reduxjs/toolkit";

import { InviteDto, TenantInviteDto } from "@/core/api/generated";

import { AppThunk } from "..";
import { apiClient } from "../../core/api/ApiClient";

export interface InvitesState {
  invites: InviteDto;
  customerInvites: TenantInviteDto;
}

const initialState: InvitesState = {
  invites: {},
  customerInvites: {},
};

export const invitesSlice = createSlice({
  name: "invites",
  initialState,
  reducers: {},
});

export const {} = invitesSlice.actions;

export const invitesReducer = invitesSlice.reducer;

export const registerInviteUser =
  (...args: Parameters<typeof apiClient.invitesApi.apiV1InvitesInviteIdRegisterPost>): AppThunk =>
  async (dispatch) => {
    await apiClient.invitesApi.apiV1InvitesInviteIdRegisterPost(...args);
  };

export const acceptInvite =
  (...args: Parameters<typeof apiClient.invitesApi.apiV1InvitesInviteIdAcceptPost>): AppThunk =>
  async (dispatch) => {
    await apiClient.invitesApi.apiV1InvitesInviteIdAcceptPost(...args);
  };

export const rejectInvite =
  (...args: Parameters<typeof apiClient.invitesApi.apiV1InvitesInviteIdRejectPost>): AppThunk =>
  async (dispatch) => {
    await apiClient.invitesApi.apiV1InvitesInviteIdRejectPost(...args);
  };
