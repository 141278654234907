import { Box, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  sx?: SxProps<Theme>;
  children?: ReactNode;
}

/** Element for displaying program code */
export default function BlockCode({ sx, children }: Props): React.ReactNode {
  return (
    <Box sx={{ display: "flex", overflow: "auto", width: "100%" }}>
      <Box
        component='code'
        sx={{
          display: "block",
          backgroundColor: (t) => t.palette.secondary.light,
          color: (theme) => theme.palette.secondary.main,
          boxShadow: (theme) => (theme.shadows as string[])[1],
          paddingY: 1,
          paddingX: 2,
          borderRadius: "2px",
          whiteSpace: "pre",
          ...sx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
