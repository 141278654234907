import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  ModalProps,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";

import ContractAutocompleteOrCreate from "@/common/components/Entity/Contract/ContractAutocompleteOrCreate";
import ContractLink from "@/common/components/Entity/Contract/ContractLink";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { ContractDto, VisualInspectionDto } from "@/core/api/generated";

import GeneralValidationError from "../../Error/GeneralValidationError";

type Props = Pick<ModalProps, "open" | "onClose"> & {
  visualInspection: VisualInspectionDto;
  onSaved?: (contract: ContractDto) => void;
};

export default function VisualInspectionAddToContractModal({
  open,
  onClose,
  visualInspection,
  onSaved,
}: Props) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const [isCreatingContract, setIsCreatingContract] = useState(false);
  const [contract, setContract] = useState<ContractDto | undefined>(undefined);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
      <AppModalTitle onCloseClicked={() => onClose && onClose({}, "escapeKeyDown")}>
        Add visual inspection to a contract
      </AppModalTitle>
      <DialogContent>
        {visualInspection.contract && (
          <Alert severity='info' sx={{ mb: 1 }}>
            This visual inspection is already added to the contract{" "}
            <ContractLink entity={visualInspection.contract} />
          </Alert>
        )}

        <Box>
          <Formik<
            BaseFormikValues & {
              contractId?: string;
              isSendToCustomer: boolean;
            }
          >
            enableReinitialize
            initialValues={{
              contractId: visualInspection.contract?.id || undefined,
              isSendToCustomer: true,
              submit: "",
            }}
            validationSchema={Yup.object().shape({
              // contractId: Yup.string().required("Required"),
            })}
            onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
              try {
                await apiClient.visualInspectionsApi.apiV1VisualInspectionsVisualInspectionIdAddToContractPost(
                  {
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    visualInspectionId: visualInspection.id!,
                    visualInspectionAddToContractDto: {
                      contractId: values.contractId,
                    },
                  },
                );
                enqueueSnackbar("Successfully added.", {
                  variant: "success",
                });

                if (mounted.current) {
                  setStatus({ success: true });
                  setSubmitting(false);
                }

                onSaved && onSaved(contract!);
                onClose && onClose({}, "escapeKeyDown");
              } catch (err) {
                if (mounted.current) {
                  ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
                  setStatus({ success: false });
                  setSubmitting(false);
                }
              }

              setSubmitting(false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setErrors,
              setFieldValue,
              setValues,
            }) => {
              return (
                // Don't use form here as we using nested form for contract create
                <Box>
                  <FormControl margin='dense' fullWidth error={Boolean(errors.contractId)}>
                    <ContractAutocompleteOrCreate
                      autocompleteProps={{
                        entityId: values.contractId,
                        searchFilters: {
                          vehicleId: visualInspection?.vehicle?.id || undefined,
                        },
                        isPreload: true,
                        onChange: (newValue) => {
                          setFieldValue(`contractId`, newValue?.id);
                          setContract(newValue);
                        },
                      }}
                      createUpdateProps={{
                        defaultValues: {
                          vehicleId: visualInspection?.vehicle?.id || undefined,
                        },
                      }}
                      createFormPlacement='page'
                      onCreateStart={() => setIsCreatingContract(true)}
                      onCreate={(newValue) => {
                        setFieldValue(`contractId`, newValue?.id);
                        setContract(newValue);
                        setIsCreatingContract(false);
                      }}
                    />
                    <FormHelperText>{errors.contractId}</FormHelperText>
                  </FormControl>

                  <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                  {!isCreatingContract && (
                    <Button
                      sx={{ flex: 1, mt: 1 }}
                      fullWidth
                      color='primary'
                      disabled={!values.contractId}
                      loading={isSubmitting}
                      type='button'
                      variant='contained'
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </Button>
                  )}
                </Box>
              );
            }}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
