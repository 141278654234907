import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { PoolItemHelper } from "@/common/helpers/entity/poolItem";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ApiEnumName } from "@/common/services/enum";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  PoolDto,
  PoolItemDto,
  PoolItemGetPaginatedDto,
} from "@/core/api/generated";

import AppTooltip from "../../AppTooltip";
import AuthorizedElement from "../../Auth/AuthorizedElement";
import AuthorizedMenuItem from "../../Auth/AuthorizedMenuItem";
import DataTabular from "../../DataTabular/DataTabular";
import Datetime from "../../Datetime/Datetime";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import BooleanValue from "../../Form/Display/BooleanValue";
import ApiEnumIcon from "../../Icons/ApiEnumIcon";
import AppIcon from "../../Icons/AppIcon";
import MenuWithTrigger from "../../Menu/MenuWithTrigger";
import AffiliationInfoDisplay from "../EntityAffiliation/AffiliationInfoDisplay";
import GeneralEntitySubTypeInline from "../General/Display/GeneralEntitySubTypeInline";
import GeneralStrictEntityRelationInline from "../General/Display/GeneralStrictEntityRelationInline";
import GeneralEntitySpecDisplay from "../General/GeneralEntitySpecInline";
import PoolItemDeleteModal from "./PoolItemDeleteModal";
import PoolItemUpdateStatusModal from "./PoolItemUpdateStatusModal";

const defaultDisplayProps = {
  viewVariant: ViewLayoutVariant.Page,
  breadcrumbs: true,
  header: true,
  filters: true,
  create: true,
  edit: true,
  editStatus: true,
  delete: true,
};

const defaultPropagatedDeps = {
  refetch: {},
};
export type PoolItemPaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;

export interface PoolItemPaginatedListOwnProps {
  poolId: string | null | undefined;
  pool: PoolDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  defaultValues?: {
    limit?: number;
  };
  propagatedDeps?: PropagatedDeps<PoolItemPaginatedListPropagatedDepsMap>;
  onChange?: () => void;
  sx?: SxProps<Theme>;
}

export type PoolItemPaginatedListProps = PoolItemPaginatedListOwnProps;

export default function PoolItemPaginatedList({
  poolId,
  pool,
  displayProps,
  defaultValues,
  propagatedDeps,
  onChange,
  sx,
}: PoolItemPaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<PoolItemGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.PoolItem,
    },
    defaultValues: {
      limit: defaultValues?.limit,
    },
  });

  const [poolItem, setPoolItem] = useState<PoolItemDto | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUpdateStatusModalOpen, setIsUpdateStatusModalOpen] = useState(false);

  const poolRequest = useApiRequest(
    apiClient.poolsApi.apiV1PoolsPoolIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      poolId: poolId!,
    },
    {
      skip: !!pool || !poolId,
    },
  );
  const poolComputed = poolRequest?.data || pool;
  const poolIdComputed = poolComputed?.id || poolId;

  const paginatedPoolItemsRequest = useApiRequest(
    apiClient.poolItemsApi.apiV1PoolsPoolIdItemsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      poolId: poolIdComputed || "",
      poolItemGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [poolId, ...commonRequestParams.deps, propagatedDeps?.depsMap["refetch"]],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedPoolItems = paginatedPoolItemsRequest.data;

  useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.PoolItem)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedPoolItemsRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    isEnabled: displayProps?.breadcrumbs,
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        pool && {
          idValue: pool.id!,
          newTitle: pool.localNumber || "",
        }) ||
      undefined,
  });

  return (
    <Box>
      <ViewLayout
        displayProps={displayProps}
        header={
          displayProps?.header && (
            <SimpleViewPageHeader
              title={undefined}
              primaryActions={
                displayProps?.create && (
                  <AuthorizedElement permissionsAny={[AppPermission.PoolManage]}>
                    <Button
                      variant='contained'
                      color='primary'
                      startIcon={<AppIcon of='add' />}
                      component={RouterLink}
                      to={ROUTE_PATH.POOL_ITEM_CREATE(poolIdComputed)}
                    >
                      Create new pool item
                    </Button>
                  </AuthorizedElement>
                )
              }
            />
          )
        }
      >
        <Stack direction='column' spacing={1}>
          <DataTabular
            columns={[
              {
                field: getTypedPath<PoolItemDto>().id.$path,
                title: "ID",
                width: 250,
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.id,
                filters: {
                  fieldType: FilterFieldType.Id,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<PoolItemDto>().entityType.$path,
                title: "Entity type",
                width: 200,
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <Box>
                    <ApiEnumIcon type='EntityType' value={item.entityType} inText />{" "}
                    <InlineApiEnumValue type='PoolItemEntityType' value={item.entityType} />
                    {item.entitySubType && (
                      <>
                        {" "}
                        (
                        <Typography component='span' variant='body2'>
                          <GeneralEntitySubTypeInline value={item.entitySubType} withIcon />
                        </Typography>
                        )
                      </>
                    )}
                  </Box>
                ),
                filters: {
                  fieldType: FilterFieldType.Enum,
                  isUseDefaultOperators: true,
                  fieldTypeMeta: {
                    enum: {
                      enumName: ApiEnumName.PoolItemEntityType,
                    },
                  },
                },
              },
              {
                field: getTypedPath<PoolItemDto>().entitySubType.entityType.$path,
                title: "Entity sub type",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.entitySubType ? (
                    <InlineApiEnumValue
                      type='EntityType'
                      value={item.entitySubType?.entitySubType}
                    />
                  ) : (
                    "-"
                  ),
                filters: {
                  fieldType: FilterFieldType.Enum,
                  isUseDefaultOperators: true,
                  fieldTypeMeta: {
                    enum: {
                      enumName: ApiEnumName.PoolItemEntityType,
                    },
                  },
                },
              },
              {
                field: getTypedPath<PoolItemDto>().entity.entityId.$path,
                title: "Entity",
                width: 300,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (item) =>
                  item.entity ? <GeneralStrictEntityRelationInline value={item.entity} /> : "-",
              },
              {
                field: getTypedPath<PoolItemDto>().usedByEntity.entityId.$path,
                title: "Used by entity",
                width: 300,
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (item) =>
                  item.usedByEntity ? (
                    <GeneralStrictEntityRelationInline value={item.usedByEntity} />
                  ) : (
                    "-"
                  ),
              },
              {
                field: getTypedPath<PoolItemDto>().entity.$path,
                title: "Spec",
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (item) => <GeneralEntitySpecDisplay value={item.entity} />,
              },
              {
                field: getTypedPath<PoolItemDto>().type.$path,
                title: "Type",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.type ? <InlineApiEnumValue type='PoolItemType' value={item.type} /> : "-",
                filters: {
                  fieldType: FilterFieldType.Enum,
                  isUseDefaultOperators: true,
                  fieldTypeMeta: {
                    enum: {
                      enumName: ApiEnumName.PoolItemType,
                    },
                  },
                },
              },
              {
                field: getTypedPath<PoolItemDto>().status.$path,
                title: "Status",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.type ? (
                    <InlineApiEnumValue
                      type='PoolItemStatus'
                      value={item.status}
                      withHelperTooltip
                    />
                  ) : (
                    "-"
                  ),
                filters: {
                  fieldType: FilterFieldType.Enum,
                  isUseDefaultOperators: true,
                  fieldTypeMeta: {
                    enum: {
                      enumName: ApiEnumName.PoolItemType,
                    },
                  },
                },
              },
              {
                field: getTypedPath<PoolItemDto>().statusReason.$path,
                title: "Status reason",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.statusReason || "-",
                filters: {
                  fieldType: FilterFieldType.String,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<PoolItemDto>().settings.isEnsureEntityBelongToSinglePool.$path,
                title: "Belongs to single pool",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <AppTooltip
                    variant='helpText'
                    title={
                      <Stack>
                        <Box>
                          In pool:{" "}
                          <BooleanValue
                            value={poolComputed?.settings?.isEnsureEntityBelongToSinglePool}
                          />
                        </Box>
                        <Box>
                          In pool item:{" "}
                          <BooleanValue value={item.settings?.isEnsureEntityBelongToSinglePool} />
                        </Box>
                      </Stack>
                    }
                  >
                    <Box>
                      <BooleanValue
                        value={poolComputed?.settings?.isEnsureEntityBelongToSinglePool}
                      />
                      , <BooleanValue value={item.settings?.isEnsureEntityBelongToSinglePool} />
                    </Box>
                  </AppTooltip>
                ),
                filters: {
                  fieldType: FilterFieldType.Boolean,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<PoolItemDto>().createdAt.$path,
                title: "Created at",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
                filters: {
                  fieldType: FilterFieldType.Date,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<PoolItemDto>().updatedAt.$path,
                title: "Updated at",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
                filters: {
                  fieldType: FilterFieldType.Date,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<PoolItemDto>().createdBy.$path,
                title: "Created by",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) =>
                  item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
                },
              },
              {
                field: getTypedPath<PoolItemDto>().updatedBy.$path,
                title: "Updated by",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) =>
                  item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
                },
              },
              {
                field: "affiliation",
                title: "Affiliation",
                description: "Affiliation to departments & locations",
                width: 120,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                isToDisabled: true,
                renderCell: (item) => (
                  <AffiliationInfoDisplay
                    variant='compact'
                    tenantId={item.tenantId}
                    departmentIds={item.departmentIds}
                    locationIds={item.locationIds}
                  />
                ),
              },
            ]}
            isLoading={paginatedPoolItemsRequest.isLoading}
            rows={paginatedPoolItems?.items}
            getRowId={(item) => item.id!}
            rowTo={(item) => ROUTE_PATH.POOL_ITEM_VIEW(poolIdComputed, item.id)}
            statePersistence={commonRequestParams.dataTabularProps.statePersistence}
            pagination={commonRequestParams.dataTabularProps.pagination}
            sort={commonRequestParams.dataTabularProps.sort}
            quickFilter={commonRequestParams.dataTabularProps.quickFilter}
            filters={commonRequestParams.dataTabularProps.filters}
            refetch={commonRequestParams.dataTabularProps.refetch}
            renderRowAction={(actionParams) => (
              <MenuWithTrigger
                withAuthCloseOnClick
                trigger={
                  <IconButton>
                    <AppIcon of='moreVert' />
                  </IconButton>
                }
              >
                {displayProps?.edit && (
                  <AuthorizedMenuItem
                    permissions={[AppPermission.PoolManage]}
                    component={RouterLink}
                    to={ROUTE_PATH.POOL_ITEM_EDIT(poolIdComputed, actionParams.item.id)}
                    disabled={!PoolItemHelper.canUpdate(actionParams.item)}
                  >
                    <ListItemIcon>
                      <AppIcon of='edit' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                  </AuthorizedMenuItem>
                )}

                {displayProps?.editStatus && (
                  <AuthorizedMenuItem
                    permissions={[AppPermission.PoolManage]}
                    onClick={() => {
                      setPoolItem(actionParams.item);
                      setIsUpdateStatusModalOpen(true);
                    }}
                    disabled={!PoolItemHelper.canUpdateStatus(actionParams.item)}
                  >
                    <ListItemIcon>
                      <AppIcon of='edit' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Edit status</ListItemText>
                  </AuthorizedMenuItem>
                )}

                {displayProps?.delete && (
                  <AuthorizedMenuItem
                    permissions={[AppPermission.PoolManage]}
                    onClick={() => {
                      setPoolItem(actionParams.item);
                      setIsDeleteModalOpen(true);
                    }}
                    disabled={!PoolItemHelper.canDelete(actionParams.item)}
                  >
                    <ListItemIcon>
                      <AppIcon of='delete' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </AuthorizedMenuItem>
                )}
              </MenuWithTrigger>
            )}
          />

          {/* Delete */}
          {poolItem && (
            <PoolItemDeleteModal
              entity={poolItem}
              open={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
              onDelete={() => {
                paginatedPoolItemsRequest.refetch();
                onChange && onChange();
              }}
            />
          )}

          {/* Update status */}
          {poolItem && (
            <PoolItemUpdateStatusModal
              open={isUpdateStatusModalOpen}
              onClose={() => setIsUpdateStatusModalOpen(false)}
              poolId={poolItem.poolId!}
              poolItemId={undefined}
              poolItem={poolItem}
              onSave={() => {
                setIsUpdateStatusModalOpen(false);
                paginatedPoolItemsRequest.refetch();
                onChange && onChange();
              }}
            />
          )}
        </Stack>
      </ViewLayout>
    </Box>
  );
}
