import { Box, IconButton, LinearProgress, Stack, Typography } from "@mui/material";
import _ from "lodash";
import { useMemo, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import AppIconButton from "@/common/components/Button/AppIconButton";
import SplitDropdownButton from "@/common/components/Button/SplitDropdownButton";
import DataExportModal from "@/common/components/DataExport/DataExportModal";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import EntityPoolsInfoDisplay from "@/common/components/EntityInfo/EntityPoolsInfoDisplay";
import EntityTagsInfoDisplayInline from "@/common/components/EntityInfo/EntityTagsInfoDisplayInline";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import TableCellContentOfAttachments from "@/common/components/Table/TableCell/TableCellContentOfAttachments";
import TableCellContentOfCount from "@/common/components/Table/TableCell/TableCellContentOfCount";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { FilterDefinition } from "@/common/filters/filterDefinition";
import { EntityHelper } from "@/common/helpers/entity";
import { TagStaticFilterInputDto, TagsHelper } from "@/common/helpers/entity/tags";
import { StringHelper } from "@/common/helpers/string";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ApiEnumName, enumService } from "@/common/services/enum";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  ExportEntityType,
  FilterDefinitionDto,
  GeneralAttachedTagsInputDto,
  ImportEntityType,
  PoolItemEntityType,
  TagEntityType,
  VehicleDto,
  VehicleGetPaginatedDto,
} from "@/core/api/generated";

import AssetInline from "../../Asset/AssetInline";
import ContractInline from "../../Contract/ContractInline";
import AffiliationInfoDisplay from "../../EntityAffiliation/AffiliationInfoDisplay";
import GeneralPlateNoDisplay from "../../General/Display/GeneralPlateNoDisplay";
import GeneralAttachedTagsOfEntitiesEditModal from "../../General/GeneralTag/GeneralAttachedTagsOfEntitiesEditModal";
import GeneralStatusMetaDisplayAndInputOfVehicle from "../../GeneralStatus/common/GeneralStatusMetaDisplayAndInputOfVehicle";
import GeneralStatusMetaDisplayOfAvailabilityStatus from "../../GeneralStatus/common/GeneralStatusMetaDisplayOfAvailabilityStatus";
import GeneralStatusMetaDisplayOfOperabilityStatus from "../../GeneralStatus/common/GeneralStatusMetaDisplayOfOperabilityStatus";
import GeneralStatusMetaDisplayOfUtilizationStatus from "../../GeneralStatus/common/GeneralStatusMetaDisplayOfUtilizationStatus";
import TenantStructureMembershipOfEntityEditModal from "../../Membership/TenantStructureMembershipOfEntityEditModal";
import PoolItemCreateManyFromEntitiesModal from "../../PoolItem/PoolItemCreateManyFromEntitiesModal";
import VehicleMenu from "../VehicleMenu";
import VehicleMileageInline from "../VehicleMileageInline";

enum BulkActionFlags {
  AddToPool = "AddToPool",
  UpdateTenantStructureMembership = "UpdateTenantStructureMembership",
  UpdateTags = "UpdateTags",
  Export = "Export",
}
const defaultDisplayProps = {
  filters: true,
  create: true,
  edit: true,
  delete: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultPropagatedDeps = {
  refetch: {},
};

export type VehiclePaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;
type VehicleGetPaginatedInputDto = Omit<VehicleGetPaginatedDto, "tags"> & {
  tags?: TagStaticFilterInputDto;
};

export interface VehiclePaginatedListOwnProps {
  displayProps?: Partial<typeof defaultDisplayProps>;
  initialValues?: {
    filterDefinition?: Nil<FilterDefinition>;
    filterDefinitionDto?: Nil<FilterDefinitionDto>;
  };
  defaultValues?: {
    limit?: number;
    vehicleId?: string;
    customerId?: string;
  };
  propagatedDeps?: PropagatedDeps<VehiclePaginatedListPropagatedDepsMap>;
}
export type VehiclePaginatedListProps = VehiclePaginatedListOwnProps;

export default function VehiclePaginatedList({
  displayProps,
  initialValues,
  defaultValues,
  propagatedDeps,
}: VehiclePaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const history = useHistory();
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<VehicleGetPaginatedInputDto>({
    statePersistence: {
      persistenceKey: EntityType.Vehicle,
    },
    initialValues: {
      filterDefinition: initialValues?.filterDefinition || undefined,
      filterDefinitionDto: initialValues?.filterDefinitionDto || undefined,
    },
    defaultValues: {
      limit: defaultValues?.limit,
      params: {
        ...defaultValues,
      },
    },
    initParams: (filterDefinition) => {
      const tagFilter = filterDefinition.filterDefinition?.items.find(
        (x) => x.field === getTypedPath<VehicleDto>().tags.$path,
      );

      return {
        tags: {
          operator: tagFilter?.operator,
          query: tagFilter?.value as GeneralAttachedTagsInputDto,
        } as TagStaticFilterInputDto,
      };
    },
  });

  const [isDataExportModalOpened, setIsDataExportModalOpened] = useState<boolean>(false);

  const paginatedVehiclesRequest = useApiRequest(
    apiClient.vehiclesApi.apiV1VehiclesGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleGetPaginatedDto: {
        ..._.omit(commonRequestParams.params, "tags"),
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        tags:
          commonRequestParams.filterDefinition?.items
            .filter((x) => x.field === getTypedPath<VehicleDto>().tags.$path)
            .map((x) => TagsHelper.mapFromFilter(x))[0] || undefined,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [
        ...commonRequestParams.deps,
        commonRequestParams.filterDefinition,
        propagatedDeps?.depsMap["refetch"],
      ],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );

  const paginatedVehicles = useMemo(
    () => paginatedVehiclesRequest?.data,
    [paginatedVehiclesRequest.data],
  );
  const vehicleIds = useMemo(
    () => paginatedVehicles?.items?.map((item) => item.id!) || [],
    [paginatedVehicles?.items],
  );

  useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.Vehicle)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedVehiclesRequest.handleEntityChanged(data);
    },
  });

  return (
    <ViewLayoutV2
      displayProps={displayProps}
      header={
        <SimpleViewPageHeader
          title='Vehicles'
          primaryActions={
            displayProps?.create && (
              <AuthorizedElement permissions={[AppPermission.VehicleManage]}>
                <SplitDropdownButton
                  isSelectAndTriggerAction
                  buttonProps={{
                    variant: "contained",
                    color: "primary",
                    startIcon: <AppIcon of='add' />,
                  }}
                  options={[
                    {
                      content: "Create new vehicle",
                      onClick: () => history.push(ROUTE_PATH.VEHICLE_CREATE),
                    },
                    {
                      content: "Create many similar vehicles",
                      onClick: () => history.push(ROUTE_PATH.VEHICLE_CREATE_MANY_SIMILAR),
                    },
                  ]}
                />
              </AuthorizedElement>
            )
          }
          secondaryActions={
            <AuthorizedElement
              permissionsAny={[AppPermission.ImportManage, AppPermission.ExportManage]}
            >
              <MenuWithTrigger
                withAuthCloseOnClick
                trigger={
                  <IconButton>
                    <AppIcon of='moreVert' />
                  </IconButton>
                }
              >
                <AuthorizedMenuItem
                  permissions={[AppPermission.ExportManage]}
                  onClick={() => setIsDataExportModalOpened(true)}
                >
                  <AppIcon of='export' fontSize='small' sx={{ mr: 1 }} />
                  <Typography>Export vehicles</Typography>
                </AuthorizedMenuItem>

                <AuthorizedMenuItem
                  permissions={[AppPermission.ImportManage]}
                  component={RouterLink}
                  to={ROUTE_PATH.MANAGEMENT_IMPORTS_CREATE({
                    entityType: ImportEntityType.Vehicle,
                  })}
                >
                  <AppIcon of='download' fontSize='small' sx={{ mr: 1 }} />
                  <Typography>Import vehicles</Typography>
                </AuthorizedMenuItem>
              </MenuWithTrigger>
            </AuthorizedElement>
          }
        />
      }
    >
      <Box
        sx={{
          width: "100%",
          flex: 1,
          backgroundColor: "background.default",
          borderRadius: (th) => th.shapeCustom.borderRadiusCard,
        }}
      >
        {paginatedVehiclesRequest.isLoading && <LinearProgress sx={{ my: 1 }} />}

        <DataTabular<VehicleDto>
          columns={[
            {
              field: getTypedPath<VehicleDto>().id.$path,
              title: "ID",
              width: 250,
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.id,
              filters: {
                fieldType: FilterFieldType.Id,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<VehicleDto>().localNumber.$path,
              title: "Number",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.localNumber,
              filters: {
                fieldType: FilterFieldType.String,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<VehicleDto>().plateNo.plateNo.$path,
              title: "Plate No",
              width: 200,
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.plateNo ? (
                  <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
                    <GeneralPlateNoDisplay
                      plateNo={item.plateNo}
                      withIcon={false}
                      withCountryCode
                      withCountryIcon={false}
                    />
                  </Stack>
                ) : (
                  "-"
                ),
              filters: {
                fieldType: FilterFieldType.String,
                isUseDefaultOperators: true,
              },
            },
            {
              field: "Info badges",
              title: "Info badges",
              width: 200,
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              renderCell: (item) => <EntityChipList entity={item} variant='compact' />,
            },
            {
              field: getTypedPath<VehicleDto>().identificationNumber.$path,
              title: "VIN",
              width: 200,
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => <>{item.identificationNumber || "-"}</>,
              filters: {
                fieldType: FilterFieldType.String,
                isUseDefaultOperators: true,
              },
            },
            {
              field: "spec",
              title: "Spec",
              width: 400,
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              renderCell: (item) => (
                <>
                  {StringHelper.joinIntoString(
                    [
                      item.spec?.make?.name,
                      item.spec?.model?.name,
                      item.spec?.generation?.name,
                      item.spec?.modification?.name,
                      item.spec?.manufactureYear?.toString(),
                      // enumService.getEnumValueName("VehicleType", item.spec?.type),
                      enumService.getEnumValueName("VehicleBodyType", item.spec?.bodyType),
                      enumService.getEnumValueName("VehicleFuelType", item.spec?.fuelType),
                      enumService.getEnumValueName("VehicleGearboxType", item.spec?.gearboxType),
                      enumService.getEnumValueName(
                        "VehicleDrivetrainType",
                        item.spec?.drivetrainType,
                      ),
                    ],
                    ", ",
                    { skipEmpty: true },
                  )}
                </>
              ),
              // renderCellDetails: (item) => <VehicleSpecDisplay spec={item.spec} columnCount={2} />,
            },
            {
              field: getTypedPath<VehicleDto>().spec.make.id.$path,
              title: "Make",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.spec?.make?.name ?? "-",
              filters: {
                fieldType: FilterFieldType.Id,
                operators: FilterCatalog.getOperatorsForIdFieldOfVehicleMake(),
              },
            },
            {
              field: getTypedPath<VehicleDto>().spec.model.id.$path,
              title: "Model",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.spec?.model?.name ?? "-",
              filters: {
                fieldType: FilterFieldType.Id,
                operators: FilterCatalog.getOperatorsForIdFieldOfVehicleModel(),
              },
            },
            {
              field: getTypedPath<VehicleDto>().spec.generation.id.$path,
              title: "Generation",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.spec?.generation?.name ?? "-",
              filters: {
                fieldType: FilterFieldType.Id,
                operators: FilterCatalog.getOperatorsForIdFieldOfVehicleGeneration(),
              },
            },
            {
              field: getTypedPath<VehicleDto>().spec.modification.id.$path,
              title: "Modification",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.spec?.modification?.name ?? "-",
              filters: {
                fieldType: FilterFieldType.Id,
                operators: FilterCatalog.getOperatorsForIdFieldOfVehicleModification(),
              },
            },
            {
              field: getTypedPath<VehicleDto>().status.status.$path,
              title: "Status",
              width: 140,
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              isToDisabled: true,
              renderCell: (item) =>
                item.status?.status ? (
                  <GeneralStatusMetaDisplayAndInputOfVehicle
                    vehicle={item}
                    onVehicleUpdated={(newValue) => {
                      paginatedVehiclesRequest.updateData((data) => {
                        data.items = data.items?.map((x) => (x.id === item.id ? newValue : x));
                      });
                    }}
                  />
                ) : (
                  "-"
                ),
              filters: {
                fieldType: FilterFieldType.Enum,
                isUseDefaultOperators: true,
                fieldTypeMeta: {
                  enum: {
                    enumName: ApiEnumName.VehicleStatus,
                  },
                },
              },
            },
            {
              field: getTypedPath<VehicleDto>().availabilityStatus.status.$path,
              title: "Availability status",
              width: 140,
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              isToDisabled: true,
              renderCell: (item) =>
                item.status?.status ? (
                  <GeneralStatusMetaDisplayOfAvailabilityStatus
                    value={item.availabilityStatus}
                    subjectEntity={item}
                    onMetaUpdated={(newValue) => {
                      paginatedVehiclesRequest.updateData((data) => {
                        data.items = data.items?.map((x) =>
                          x.id === item.id ? { ...item, availabilityStatus: newValue } : x,
                        );
                      });
                    }}
                  />
                ) : (
                  "-"
                ),
              filters: {
                fieldType: FilterFieldType.Enum,
                isUseDefaultOperators: true,
                fieldTypeMeta: {
                  enum: {
                    enumName: ApiEnumName.AvailabilityStatus,
                  },
                },
              },
            },
            {
              field: getTypedPath<VehicleDto>().utilizationStatus.status.$path,
              title: "Utilization status",
              width: 140,
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              isToDisabled: true,
              renderCell: (item) =>
                item.status?.status ? (
                  <GeneralStatusMetaDisplayOfUtilizationStatus
                    value={item.utilizationStatus}
                    subjectEntity={item}
                    onMetaUpdated={(newValue) => {
                      paginatedVehiclesRequest.updateData((data) => {
                        data.items = data.items?.map((x) =>
                          x.id === item.id ? { ...item, utilizationStatus: newValue } : x,
                        );
                      });
                    }}
                  />
                ) : (
                  "-"
                ),
              filters: {
                fieldType: FilterFieldType.Enum,
                isUseDefaultOperators: true,
                fieldTypeMeta: {
                  enum: {
                    enumName: ApiEnumName.UtilizationStatus,
                  },
                },
              },
            },
            {
              field: getTypedPath<VehicleDto>().operabilityStatus.status.$path,
              title: "Operability status",
              width: 140,
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              isToDisabled: true,
              renderCell: (item) =>
                item.status?.status ? (
                  <GeneralStatusMetaDisplayOfOperabilityStatus
                    value={item.operabilityStatus}
                    subjectEntity={item}
                    onMetaUpdated={(newValue) => {
                      paginatedVehiclesRequest.updateData((data) => {
                        data.items = data.items?.map((x) =>
                          x.id === item.id ? { ...item, operabilityStatus: newValue } : x,
                        );
                      });
                    }}
                  />
                ) : (
                  "-"
                ),
              filters: {
                fieldType: FilterFieldType.Enum,
                isUseDefaultOperators: true,
                fieldTypeMeta: {
                  enum: {
                    enumName: ApiEnumName.OperabilityStatus,
                  },
                },
              },
            },
            {
              field: getTypedPath<VehicleDto>().spec.bodyType.$path,
              title: "Body type",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.spec?.bodyType ? (
                  <InlineApiEnumValue type='VehicleBodyType' value={item.spec?.bodyType} />
                ) : (
                  "-"
                ),
              filters: {
                fieldType: FilterFieldType.Enum,
                isUseDefaultOperators: true,
                fieldTypeMeta: {
                  enum: {
                    enumName: ApiEnumName.VehicleBodyType,
                  },
                },
              },
            },
            {
              field: getTypedPath<VehicleDto>().spec.fuelType.$path,
              title: "Fuel type",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.spec?.bodyType ? (
                  <InlineApiEnumValue type='VehicleFuelType' value={item.spec?.fuelType} />
                ) : (
                  "-"
                ),
              filters: {
                fieldType: FilterFieldType.Enum,
                isUseDefaultOperators: true,
                fieldTypeMeta: {
                  enum: {
                    enumName: ApiEnumName.VehicleFuelType,
                  },
                },
              },
            },
            {
              field: getTypedPath<VehicleDto>().spec.gearboxType.$path,
              title: "Gearbox type",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.spec?.bodyType ? (
                  <InlineApiEnumValue type='VehicleGearboxType' value={item.spec?.gearboxType} />
                ) : (
                  "-"
                ),
              filters: {
                fieldType: FilterFieldType.Enum,
                isUseDefaultOperators: true,
                fieldTypeMeta: {
                  enum: {
                    enumName: ApiEnumName.VehicleGearboxType,
                  },
                },
              },
            },
            {
              field: getTypedPath<VehicleDto>().spec.drivetrainType.$path,
              title: "Drivetrain type",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.spec?.bodyType ? (
                  <InlineApiEnumValue
                    type='VehicleDrivetrainType'
                    value={item.spec?.drivetrainType}
                  />
                ) : (
                  "-"
                ),
              filters: {
                fieldType: FilterFieldType.Enum,
                isUseDefaultOperators: true,
                fieldTypeMeta: {
                  enum: {
                    enumName: ApiEnumName.VehicleDrivetrainType,
                  },
                },
              },
            },
            {
              field: getTypedPath<VehicleDto>().spec.manufactureYear.$path,
              title: "Manufacture year",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.spec?.manufactureYear ?? "-",
              filters: {
                fieldType: FilterFieldType.Number,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<VehicleDto>().lastContract.id.$path,
              title: "Last contract",
              width: 300,
              isVisible: false,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) =>
                item?.lastContract ? <ContractInline entity={item?.lastContract} /> : "-",
            },
            {
              field: getTypedPath<VehicleDto>().assetMeta.assetId.$path,
              title: "Asset",
              width: 300,
              isVisible: false,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) => (
                <AssetInline entity={undefined} entityId={item.assetMeta?.assetId} />
              ),
              filters: {
                fieldType: FilterFieldType.Id,
                operators: FilterCatalog.getOperatorsForIdFieldOfAsset(),
              },
            },
            {
              field: getTypedPath<VehicleDto>().defaultVisualModel.id.$path,
              title: "Default visual model",
              width: 300,
              isVisible: false,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) => (
                <AssetInline entity={undefined} entityId={item.assetMeta?.assetId} />
              ),
              filters: {
                fieldType: FilterFieldType.Id,
                operators: FilterCatalog.getOperatorsForIdFieldOfVehicleVisualModel(),
              },
            },
            {
              field: getTypedPath<VehicleDto>().mileage.$path,
              title: "Mileage",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => <VehicleMileageInline value={item.mileage} />,
              filters: {
                fieldType: FilterFieldType.Number,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<VehicleDto>().notes.$path,
              title: "Notes",
              width: 100,
              isVisible: false,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) => item.notes || "-",
              filters: {
                fieldType: FilterFieldType.String,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<VehicleDto>().totalDamagesCount.$path,
              title: "Total damages #",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.totalDamagesCount ?? "-",
              filters: {
                fieldType: FilterFieldType.Number,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<VehicleDto>().actualDamagesCount.$path,
              title: "Actual damages #",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.actualDamagesCount ?? "-",
              filters: {
                fieldType: FilterFieldType.Number,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<VehicleDto>().repairedDamagesCount.$path,
              title: "Repaired damages #",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.repairedDamagesCount ?? "-",
              filters: {
                fieldType: FilterFieldType.Number,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<VehicleDto>().lastVisualInspectionAt.$path,
              title: "Last visual inspection at",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.lastVisualInspectionAt ? (
                  <Datetime datetime={item.lastVisualInspectionAt} withDurationFromNow />
                ) : (
                  "-"
                ),
              filters: {
                fieldType: FilterFieldType.Date,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<VehicleDto>().lastDamageDetectionAt.$path,
              title: "Last damage detection at",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.lastDamageDetectionAt ? (
                  <Datetime datetime={item.lastDamageDetectionAt} withDurationFromNow />
                ) : (
                  "-"
                ),
              filters: {
                fieldType: FilterFieldType.Date,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<VehicleDto>().lastAccessoryCheckAt.$path,
              title: "Last accessory check at",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.lastAccessoryCheckAt ? (
                  <Datetime datetime={item.lastAccessoryCheckAt} withDurationFromNow />
                ) : (
                  "-"
                ),
              filters: {
                fieldType: FilterFieldType.Date,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<VehicleDto>().lastMileageUpdatedAt.$path,
              title: "Last mileage update at",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.lastMileageUpdatedAt ? (
                  <Datetime datetime={item.lastMileageUpdatedAt} withDurationFromNow />
                ) : (
                  "-"
                ),
              filters: {
                fieldType: FilterFieldType.Date,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<VehicleDto>().createdAt.$path,
              title: "Created at",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
              filters: {
                fieldType: FilterFieldType.Date,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<VehicleDto>().updatedAt.$path,
              title: "Updated at",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
              filters: {
                fieldType: FilterFieldType.Date,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<VehicleDto>().createdBy.$path,
              title: "Created by",
              isVisible: false,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) =>
                item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
              filters: {
                fieldType: FilterFieldType.Id,
                operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
              },
            },
            {
              field: getTypedPath<VehicleDto>().updatedBy.$path,
              title: "Updated by",
              isVisible: false,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) =>
                item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
              filters: {
                fieldType: FilterFieldType.Id,
                operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
              },
            },
            {
              field: getTypedPath<VehicleDto>().accessories.$path,
              title: "Accessories",
              isVisible: false,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              renderCell: (item) => <TableCellContentOfCount count={item.accessories?.length} />,
            },
            {
              field: getTypedPath<VehicleDto>().attachments.$path,
              title: "Attachments",
              isVisible: false,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              renderCell: (item) => (
                <TableCellContentOfAttachments attachments={item.attachments} />
              ),
            },
            {
              field: getTypedPath<VehicleDto>().poolsMeta.$path,
              title: "Pools",
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              renderCell: (item) => (
                <EntityPoolsInfoDisplay
                  entityType={PoolItemEntityType.Vehicle}
                  entity={item}
                  isIncludeAssetPools
                  noDataPlaceholder='-'
                />
              ),
            },
            {
              field: "affiliation",
              title: "Affiliation",
              description: "Affiliation to departments & locations",
              width: 120,
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              isToDisabled: true,
              renderCell: (item) => (
                <AffiliationInfoDisplay
                  variant='compact'
                  tenantId={item.tenantId}
                  departmentIds={item.departmentIds}
                  locationIds={item.locationIds}
                />
              ),
            },
            {
              field: getTypedPath<VehicleDto>().tags.$path,
              title: "Tags",
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              isToDisabled: true,
              renderCell: (item) => (
                <EntityTagsInfoDisplayInline
                  entityType={TagEntityType.Vehicle}
                  entity={item}
                  noDataPlaceholder='-'
                  edit={{
                    onSaved: (newValue) =>
                      paginatedVehiclesRequest.updateData((data) => {
                        data.items?.forEach((item2) => {
                          if (item2.id === item.id) {
                            item2.tags = newValue || undefined;
                          }
                        });
                      }),
                  }}
                />
              ),
              filters: {
                fieldType: FilterFieldType.Arbitrary,
                isUseSingleFilter: true,
                operators: FilterCatalog.getOperatorsForTagsField(),
              },
            },
          ]}
          rows={paginatedVehicles?.items || []}
          rowTo={(item) => ROUTE_PATH.VEHICLE_VIEW(item.id)}
          getRowId={(item) => item.id || ""}
          renderRowAction={({ item }) => {
            return (
              <VehicleMenu
                entity={item}
                onDelete={() => paginatedVehiclesRequest.refetch()}
                onUpdate={() => paginatedVehiclesRequest.refetch()}
                displayProps={{
                  actions: {
                    edit: true,
                    createContract: true,
                    createAsset: true,
                    addToPool: true,
                    createVisualInspection: false,
                    createDamageDetection: false,
                    createDamageCostEvaluation: false,
                    createRepairOperation: false,
                    createAccessoryCheck: false,
                    createWheelOperation: false,
                    createWashOperation: false,
                    createMaintenanceOperation: false,
                  },
                }}
              />
            );
          }}
          statePersistence={commonRequestParams.dataTabularProps.statePersistence}
          pagination={commonRequestParams.dataTabularProps.pagination}
          sort={commonRequestParams.dataTabularProps.sort}
          quickFilter={commonRequestParams.dataTabularProps.quickFilter}
          filters={commonRequestParams.dataTabularProps.filters}
          refetch={commonRequestParams.dataTabularProps.refetch}
          bulkActions={{
            enabled: true,
            definition: BulkActionFlags,
            actionTriggers: ({ currentAction, startAction, selectedIds }) => (
              <>
                <AppIconButton
                  tooltipProps={{ title: "Add to pool" }}
                  onClick={() => startAction(BulkActionFlags.AddToPool)}
                >
                  <AppIcon of='pool' />
                </AppIconButton>
                <AppIconButton
                  tooltipProps={{ title: "Update affiliation" }}
                  onClick={() => startAction(BulkActionFlags.UpdateTenantStructureMembership)}
                >
                  <AppIcon of='department' />
                </AppIconButton>
                <AppIconButton
                  tooltipProps={{ title: "Update tags" }}
                  onClick={() => startAction(BulkActionFlags.UpdateTags)}
                >
                  <AppIcon of='tag' />
                </AppIconButton>
                <AppIconButton
                  tooltipProps={{ title: "Export" }}
                  onClick={() => startAction(BulkActionFlags.Export)}
                >
                  <AppIcon of='export' />
                </AppIconButton>
              </>
            ),
            actionHandlers: ({ selectedIds, currentAction, cancelAction, completeAction }) => {
              const selectedEntities = EntityHelper.filterEntitiesByIds(
                paginatedVehicles?.items || [],
                selectedIds as string[],
              );
              return (
                <>
                  <PoolItemCreateManyFromEntitiesModal
                    open={currentAction === BulkActionFlags.AddToPool}
                    onClose={() => cancelAction()}
                    createProps={{
                      entityType: PoolItemEntityType.Vehicle,
                      entities: selectedEntities,
                      onSave: (newValue) => {
                        completeAction();
                        paginatedVehiclesRequest.refetch();
                      },
                    }}
                  />
                  <TenantStructureMembershipOfEntityEditModal
                    open={currentAction === BulkActionFlags.UpdateTenantStructureMembership}
                    onClose={() => cancelAction()}
                    tenantStructureMembershipOfEntityEditProps={{
                      entityType: EntityType.Vehicle,
                      entities: selectedEntities,
                      onSave: () => {
                        completeAction();
                        paginatedVehiclesRequest.refetch();
                      },
                    }}
                  />
                  <GeneralAttachedTagsOfEntitiesEditModal
                    tagEntityType={TagEntityType.Vehicle}
                    entities={selectedEntities}
                    open={currentAction === BulkActionFlags.UpdateTags}
                    onClose={() => cancelAction()}
                    onSaved={() => {
                      completeAction();
                      paginatedVehiclesRequest.refetch();
                    }}
                  />
                  <DataExportModal
                    open={currentAction === BulkActionFlags.Export}
                    onClose={() => cancelAction()}
                    exportDataProps={{
                      entityType: ExportEntityType.Vehicle,
                      ids: selectedIds as string[],
                      onExportDone: completeAction,
                      displayProps: {
                        mode: false,
                      },
                    }}
                  />
                </>
              );
            },
          }}
        />
      </Box>

      {/* Export data modal */}
      <DataExportModal
        open={isDataExportModalOpened}
        onClose={() => {
          setIsDataExportModalOpened(false);
        }}
        exportDataProps={{
          entityType: ExportEntityType.Vehicle,
          ids: vehicleIds,
          onExportDone: () => {
            setIsDataExportModalOpened(false);
          },
        }}
      />
    </ViewLayoutV2>
  );
}
