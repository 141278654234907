import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function EditIcon(props: SvgIconProps, ref) {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <path d='M18.6559 11.6953C18.4292 11.6953 18.2119 11.736 18.0102 11.8103C17.6834 11.2291 17.0672 10.8361 16.3618 10.8361C16.1352 10.8361 15.9179 10.8768 15.7163 10.951C15.3894 10.3699 14.7731 9.97694 14.0678 9.97694C13.9316 9.97694 13.7989 9.99186 13.6708 10.0197V8.63069C13.6708 7.56936 12.8198 6.70581 11.7737 6.70581C10.7277 6.70581 9.87668 7.56936 9.87668 8.63069V14.7529C9.53716 14.6644 9.12046 14.6056 8.60971 14.597C7.18306 14.5722 6.46846 15.4259 6.40373 16.2289C6.33541 17.0759 6.93518 17.809 7.79881 17.9341C8.76721 18.0745 9.26146 18.8838 9.98768 20.1925C10.929 21.8889 12.1004 24 15.3223 24C17.4612 24 19.028 23.0574 19.8533 21.274C20.4803 19.9189 20.5529 18.3479 20.5529 17.2842V13.6201C20.5529 12.5588 19.7018 11.6953 18.6559 11.6953ZM19.0529 17.2842C19.0529 20.8914 17.9023 22.5 15.3223 22.5C12.9835 22.5 12.203 21.0934 11.2993 19.4647C10.5812 18.1706 9.76733 16.7038 8.01391 16.4497C7.93201 16.4378 7.89436 16.405 7.89893 16.3495C7.90771 16.2397 8.12611 16.0908 8.58421 16.0967C9.47551 16.1119 9.85966 16.3195 9.94928 16.412C10.0898 16.7082 10.4113 16.8826 10.7425 16.831C11.1076 16.7739 11.3767 16.4595 11.3767 16.09V8.63069C11.3767 8.39639 11.5548 8.20581 11.7737 8.20581C11.9927 8.20581 12.1708 8.39639 12.1708 8.63069V11.9017V15.4369C12.1708 15.8511 12.5066 16.1869 12.9208 16.1869C13.3349 16.1869 13.6708 15.8511 13.6708 15.4369V11.9017C13.6708 11.6675 13.8488 11.4769 14.0678 11.4769C14.2867 11.4769 14.4648 11.6675 14.4648 11.9017V12.7609V15.4369C14.4648 15.8511 14.8007 16.1869 15.2148 16.1869C15.629 16.1869 15.9648 15.8511 15.9648 15.4369V12.7609C15.9648 12.5267 16.1429 12.3361 16.3618 12.3361C16.5807 12.3361 16.7588 12.5267 16.7588 12.7609V13.5115C16.7588 13.5237 16.7588 13.5958 16.7588 13.6201V15.4369C16.7588 15.8511 17.0946 16.1869 17.5088 16.1869C17.9229 16.1869 18.2588 15.8511 18.2588 15.4369V13.6201C18.2588 13.3859 18.4369 13.1953 18.6559 13.1953C18.8748 13.1953 19.0529 13.3859 19.0529 13.6201V17.2842Z' />
      <path d='M5.59697 4.36777C6.7825 4.36777 7.74692 3.38805 7.74692 2.18393C7.74692 0.979725 6.7825 0 5.59697 0C4.41145 0 3.44702 0.979725 3.44702 2.18393C3.44702 3.38813 4.41145 4.36777 5.59697 4.36777ZM5.59697 1.5C5.95532 1.5 6.24692 1.80683 6.24692 2.18393C6.24692 2.56095 5.95532 2.86777 5.59697 2.86777C5.23862 2.86777 4.94702 2.56095 4.94702 2.18393C4.94702 1.80683 5.23862 1.5 5.59697 1.5Z' />
      <path d='M11.7518 4.36777C12.9374 4.36777 13.9018 3.38805 13.9018 2.18393C13.9018 0.979725 12.9374 0 11.7518 0C10.5663 0 9.60181 0.979725 9.60181 2.18393C9.60181 3.38813 10.5664 4.36777 11.7518 4.36777ZM11.7518 1.5C12.1102 1.5 12.4018 1.80683 12.4018 2.18393C12.4018 2.56095 12.1102 2.86777 11.7518 2.86777C11.3934 2.86777 11.1018 2.56095 11.1018 2.18393C11.1018 1.80683 11.3934 1.5 11.7518 1.5Z' />
      <path d='M17.9065 4.36777C19.0921 4.36777 20.0565 3.38805 20.0565 2.18393C20.0565 0.979725 19.0921 0 17.9065 0C16.721 0 15.7566 0.979725 15.7566 2.18393C15.7566 3.38813 16.721 4.36777 17.9065 4.36777ZM17.9065 1.5C18.2649 1.5 18.5565 1.80683 18.5565 2.18393C18.5565 2.56095 18.2649 2.86777 17.9065 2.86777C17.5482 2.86777 17.2566 2.56095 17.2566 2.18393C17.2566 1.80683 17.5482 1.5 17.9065 1.5Z' />
      <path d='M7.74692 8.48774C7.74692 7.28354 6.7825 6.30389 5.59697 6.30389C4.41145 6.30389 3.44702 7.28354 3.44702 8.48774C3.44702 9.69202 4.41145 10.6717 5.59697 10.6717C6.7825 10.6717 7.74692 9.69202 7.74692 8.48774ZM5.59697 9.17167C5.23862 9.17167 4.94702 8.86484 4.94702 8.48774C4.94702 8.11072 5.23862 7.80389 5.59697 7.80389C5.95532 7.80389 6.24692 8.11072 6.24692 8.48774C6.24692 8.86484 5.95532 9.17167 5.59697 9.17167Z' />
      <path d='M17.9065 10.6717C19.0921 10.6717 20.0565 9.69194 20.0565 8.48774C20.0565 7.28354 19.0921 6.30389 17.9065 6.30389C16.721 6.30389 15.7566 7.28362 15.7566 8.48774C15.7566 9.69202 16.721 10.6717 17.9065 10.6717ZM17.9065 7.80389C18.2649 7.80389 18.5565 8.11072 18.5565 8.48774C18.5565 8.86484 18.2649 9.17167 17.9065 9.17167C17.5482 9.17167 17.2566 8.86484 17.2566 8.48774C17.2566 8.11072 17.5482 7.80389 17.9065 7.80389Z' />
    </SvgIcon>
  );
});
