import Menu, { MenuProps } from "@mui/material/Menu";
import _ from "lodash";
import React, { ReactElement, useMemo, useState } from "react";

import { ReactHelper } from "@/common/helpers/react";

interface RenderChildrenProps {
  handleClose: () => void;
}

interface OwnProps {
  trigger: ((isMenuOpen: boolean) => ReactElement) | ReactElement;
  withAuthCloseOnClick?: boolean;
  children?: MenuProps["children"] | ((props: RenderChildrenProps) => MenuProps["children"]);
}

export type MenuWithTriggerProps = OwnProps &
  Omit<MenuProps, "anchorEl" | "open" | "onClose" | "children">;

/** Extends MUI Menu with custom logic.
 *  Additionally accepts trigger element to simplify open/close of menu. */
export default function MenuWithTrigger({
  trigger,
  withAuthCloseOnClick = false,
  children,
  ...menuProps
}: MenuWithTriggerProps) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  const triggerElement = useMemo(() => {
    const element: ReactElement = typeof trigger === "function" ? trigger(isMenuOpen) : trigger;
    return ReactHelper.cloneWithRef(element, {
      onClick: (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        handleOpen(e);
      },
    });
  }, [trigger, isMenuOpen, handleOpen]);

  const childrenComputed =
    (children && (_.isFunction(children) ? children({ handleClose }) : undefined)) ||
    (!_.isFunction(children) ? children : undefined) ||
    null;

  // TODO: the idea was to auto-detect if menu is empty and do not render it, but the approach below is not complete
  // const isEmptyChildren = ReactHelper.isEmptyNode(childrenComputed);
  // if (isEmptyChildren && isHideIfNoItems) {
  //   return null;
  // }

  return (
    <>
      {triggerElement}

      <Menu
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        {...menuProps}
        onClick={(e) => {
          if (withAuthCloseOnClick) {
            handleClose();
          }
          menuProps.onClick && menuProps.onClick(e);
        }}
      >
        {/* {isEmptyChildren && withNoItemsMessage && (
          <AppMenuItem>
            <ListItemIcon>
              <AppIcon of='nothing' fontSize='small' />
            </ListItemIcon>
            <ListItemText>Nothing here for you</ListItemText>
          </AppMenuItem>
        )} */}

        {childrenComputed}
      </Menu>
    </>
  );
}
