import {
  Alert,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { Formik } from "formik";
import _ from "lodash";
import moment, { Moment } from "moment";
import { useState } from "react";
import * as Yup from "yup";

import { DATETIME_FORMATS } from "@/common/constants/common";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FileItem } from "@/common/fileItem";
import { IdHelper } from "@/common/helpers/id";
import { MemoHelper } from "@/common/helpers/memo";
import { PriceHelper } from "@/common/helpers/price";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useTenantProfile } from "@/common/hooks/entity/tenant/useTenantProfile";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useCurrentCurrency } from "@/common/hooks/useCurrentCurrency";
import { useUserAffiliation } from "@/common/hooks/useUserAffiliation";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { cast } from "@/common/ts/conversions";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  ContractStage,
  DamageCostEvaluationCreateDto,
  DamageCostEvaluationDto,
  DamageCostEvaluationItemCreateDto,
  DamageCostEvaluationItemDamageInputDto,
  DamageCostEvaluationItemUpdateDto,
  DamageCostEvaluationStage,
  DamageCostEvaluationUpdateDto,
  EntityType,
  GeneralDiscountInputDto,
  GeneralStageUpdateDtoOfDamageCostEvaluationStage,
  GeneralTaxInputDto,
  RepairSpecDataDto,
  RepairSpecDataInputDto,
  VehicleDamageFullReferenceDto,
} from "@/core/api/generated";

import NoDataAlert from "../../AppAlerts/NoDataAlert";
import SplitDropdownButton from "../../Button/SplitDropdownButton";
import DevRenderCount from "../../Dev/DevRenderCount";
import FoldableBlock from "../../Display/FoldableBlock";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FileUploader from "../../Files/FileUploader";
import FormActions from "../../Form/FormActions";
import FormikComputedField from "../../Form/Formik/FormikComputedField";
import AppTextArea from "../../Form/Input/AppTextArea";
import AppIcon from "../../Icons/AppIcon";
import MemoWrapper from "../../Memo/MemoWrapper";
import GeneralPriceSummaryDisplay from "../../PriceSummary/GeneralPriceSummaryDisplay";
import AppTypography from "../../Text/AppTypography";
import ContractAutocompleteOrCreate from "../Contract/ContractAutocompleteOrCreate";
import EntityAffiliationInput from "../EntityAffiliation/EntityAffiliationInput";
import GeneralAttachedTagsInput from "../General/GeneralTag/GeneralAttachedTagsInput";
import CurrencyAutocomplete from "../ReferenceData/CurrencyAutocomplete";
import { mapRepairSpecDtoToDataInputDto } from "../RepairSpec/RepairSpecDataInputDtoFormInputs";
import VehicleAutocompleteOrCreate from "../Vehicle/VehicleAutocompleteOrCreate";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";
import DamageCostEvaluationItemInput from "./CreateUpdate/DamageCostEvaluationItemInput";

type DefaultValues = {
  vehicleId?: DamageCostEvaluationCreateDto["vehicleId"];
  contractId?: DamageCostEvaluationCreateDto["contractId"];
  vehicleDamageIds?: string[] | null;
  currency?: DamageCostEvaluationCreateDto["currency"];
  tenantRequestsMeta?: DamageCostEvaluationCreateDto["tenantRequestsMeta"];
};

export type DamageCostEvaluationValues = BaseFormikValues &
  Omit<DamageCostEvaluationCreateDto, "items"> &
  Omit<DamageCostEvaluationUpdateDto, "items"> & {
    vehicle?: DamageCostEvaluationDto["vehicle"];
    isAttachmentFilesHaveErrors: boolean;
    initialAttachments: DamageCostEvaluationDto["attachments"];
    items?: DamageCostEvaluationItemValues[];

    // computed
    tax?: GeneralTaxInputDto | null;
    discount?: GeneralDiscountInputDto | null;
    subTotal?: number | null;
    subTotalIncDiscount?: number | null;
    total?: number | null;
    selectedDamageIds?: string[];
  };

export type DamageCostEvaluationItemValues = Omit<DamageCostEvaluationItemCreateDto, "damages"> &
  Omit<DamageCostEvaluationItemUpdateDto, "damages"> & {
    tempId: string;
    damages?: Array<
      DamageCostEvaluationItemDamageInputDto & {
        damage?: VehicleDamageFullReferenceDto;
      }
    >;
    repairSpec?: RepairSpecDataDto & RepairSpecDataInputDto;
    initialAttachments?: DamageCostEvaluationDto["attachments"];
    isAttachmentFilesHaveErrors?: boolean;
    isNameEditedManually?: boolean;
    isInitiallyAutoSelectedBestMatchingRepairSpec?: boolean;

    // computed
    tax?: GeneralTaxInputDto | null;
    discount?: GeneralDiscountInputDto | null;
    subTotal?: number | null;
    subTotalIncDiscount?: number | null;
    total?: number | null;
  };

export interface DamageCostEvaluationCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<DamageCostEvaluationDto, DefaultValues> {
  damageCostEvaluationId?: string;
}

export type DamageCostEvaluationCreateUpdateProps = DamageCostEvaluationCreateUpdateOwnProps;

export default function DamageCostEvaluationCreateUpdate({
  damageCostEvaluationId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: DamageCostEvaluationCreateUpdateProps) {
  const [isAttachmentFilesUploading, setIsAttachmentFilesUploading] = useState(false);
  const isCreate = !damageCostEvaluationId;
  const isEdit = !!damageCostEvaluationId;

  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const profile = useUserProfile();
  const tenantProfile = useTenantProfile();
  const currentCurrency = useCurrentCurrency({ useDefault: true });

  const damageCostEvaluationRequest = useApiRequest(
    apiClient.damageCostEvaluationsApi.apiV1DamageCostEvaluationsDamageCostEvaluationIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      damageCostEvaluationId: damageCostEvaluationId!,
    },
    {
      deps: [damageCostEvaluationId],
      skip: !damageCostEvaluationId,
    },
  );
  const damageCostEvaluation = damageCostEvaluationRequest?.data;

  const defaultVehicleDamagesRequest = useApiRequest(
    apiClient.vehicleDamagesApi.apiV1VehiclesVehicleIdDamagesByIdsGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: defaultValues?.vehicleId || "",
      vehicleDamageIds: defaultValues?.vehicleDamageIds || undefined,
    },
    {
      deps: [defaultValues?.vehicleId, defaultValues?.vehicleDamageIds],
      skip: !defaultValues?.vehicleId && !defaultValues?.vehicleDamageIds,
    },
  );
  const defaultVehicleDamages = defaultVehicleDamagesRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: damageCostEvaluation && {
      idValue: damageCostEvaluation.id!,
      newTitle: damageCostEvaluation.localNumber || "",
    },
  });

  const canEdit =
    isCreate ||
    (isEdit && damageCostEvaluation?.stageHistory?.stage !== DamageCostEvaluationStage.Completed);
  const canEditDamages = canEdit;
  const canAddDamages = canEdit;
  const canRemoveDamages = canEdit;
  const canAddItems = canEdit;
  const canRemoveItems = canEdit;
  const canEditRepairSpec = canEdit;

  const { departments, locations } = useUserAffiliation();

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.DamageCostEvaluation}
      entityId={damageCostEvaluationId}
      entity={damageCostEvaluation}
      entityRequest={damageCostEvaluationRequest}
      isIniting={
        damageCostEvaluationRequest.isLoading ||
        defaultVehicleDamagesRequest.isLoading ||
        !tenantProfile ||
        !profile
      }
    >
      <Formik<DamageCostEvaluationValues>
        enableReinitialize={false}
        validateOnChange={false}
        validateOnBlur={true}
        validateOnMount={true}
        initialValues={{
          vehicleId: damageCostEvaluation?.vehicle?.id || defaultValues?.vehicleId || undefined,
          contractId: damageCostEvaluation?.contract?.id || defaultValues?.contractId || undefined,
          departmentId:
            (damageCostEvaluation?.departmentIds || []).at(0) ||
            (departments && departments[0] && departments[0].id) ||
            undefined,
          locationId:
            (damageCostEvaluation?.locationIds || []).at(0) ||
            (locations && locations[0] && locations[0].id) ||
            undefined,
          vehicle: damageCostEvaluation?.vehicle || undefined,
          currency:
            damageCostEvaluation?.currency ||
            defaultValues?.currency ||
            currentCurrency ||
            undefined,
          inspectedAt:
            damageCostEvaluation?.inspectedAt || moment().format(DATETIME_FORMATS.DISPLAY_DATETIME),
          inspector: {
            isCurrentUser: true,
            ...(damageCostEvaluation?.inspector || {}),
            phoneNumber: damageCostEvaluation?.inspector?.phoneNumber,
          },
          isUseSupplierBranding: damageCostEvaluation
            ? damageCostEvaluation.isUseSupplierBranding
            : true,
          notes: damageCostEvaluation?.notes || "",
          initialAttachments: damageCostEvaluation?.attachments || undefined,
          isAttachmentFilesHaveErrors: false,
          attachments:
            damageCostEvaluation?.attachments && damageCostEvaluation.attachments.length > 0
              ? damageCostEvaluation.attachments
              : undefined,
          items: damageCostEvaluation?.items?.map((x) => ({
            ...x,
            id: x.id,
            tempId: x.id || IdHelper.newId(),
            name: x.name || undefined,
            damages: x.damages?.map((y) => ({ ...y, damageId: y.damage?.id, damage: y.damage })),
            repairSpec: x.repairSpec
              ? ({
                  ...x.repairSpec,
                  ...mapRepairSpecDtoToDataInputDto(x.repairSpec),
                } as RepairSpecDataDto & RepairSpecDataInputDto)
              : undefined,
            repairSpecId: x.repairSpec?.id,
            notes: x.notes || undefined,
            initialAttachments: x?.attachments || undefined,
            isAttachmentFilesHaveErrors: false,
            attachments: x.attachments && x.attachments.length > 0 ? x.attachments : undefined,
            tax: x.tax,
            subTotal: x.subTotal,
            subTotalIncDiscount: x.subTotalIncDiscount,
            total: x.total,
            isNameEditedManually: !!x.name,
          })) ||
            defaultValues?.vehicleDamageIds?.map((damageId) => {
              const damage = defaultVehicleDamages?.find((x) => x.id === damageId);
              return {
                tempId: IdHelper.newId(),
                damages: [
                  {
                    damageId: damageId,
                    damage: damage,
                  },
                ],
                initialAttachments: damage?.attachments,
                attachments: damage?.attachments,
                notes: damage?.notes,
              };
            }) || [{ tempId: IdHelper.newId(), damages: [{}] }],

          isSendToCustomer: damageCostEvaluation?.isSendToCustomer || undefined,
          tenantRequestsMeta:
            damageCostEvaluation?.tenantRequestsMeta ||
            defaultValues?.tenantRequestsMeta ||
            undefined,
          tags: damageCostEvaluation?.tags || undefined,
          newStage: undefined,

          // computed
          selectedDamageIds:
            (damageCostEvaluation
              ? _.flatten(
                  damageCostEvaluation?.items
                    ?.map((x) => x.damages?.map((y) => y.damage?.id || "")?.filter(Boolean) ?? [])
                    ?.filter(Boolean) ?? [],
                )
              : undefined) ||
            defaultValues?.vehicleDamageIds ||
            undefined,

          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // type: Yup.string().required("Type is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            if (isCreate) {
              const response =
                await apiClient.damageCostEvaluationsApi.apiV1DamageCostEvaluationsPost({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  damageCostEvaluationCreateDto: {
                    ..._.omit(values, ["submit"]),
                    inspectedAt: values.inspectedAt
                      ? moment(values.inspectedAt).utc().format()
                      : undefined,
                    attachments: values.attachments,
                    items: values.items,
                  },
                });
              enqueueSnackbar("Damage cost evaluation created.", { variant: "success" });
              onCreate && (await onCreate(response.data));
              onSave && (await onSave(response.data));
            } else {
              const response =
                await apiClient.damageCostEvaluationsApi.apiV1DamageCostEvaluationsDamageCostEvaluationIdPut(
                  {
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    damageCostEvaluationId,
                    damageCostEvaluationUpdateDto: {
                      ..._.omit(values, ["submit"]),
                      inspectedAt: values.inspectedAt
                        ? moment(values.inspectedAt).utc().format()
                        : undefined,
                      attachments: values.attachments,
                      items: values.items,
                    },
                  },
                );
              enqueueSnackbar("Damage cost evaluation updated.", { variant: "success" });
              onUpdate && (await onUpdate(response.data));
              onSave && (await onSave(response.data));
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {(formikProps) => {
          const {
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
            submitForm,
          } = formikProps;
          return (
            <form noValidate onSubmit={handleSubmit}>
              <DevRenderCount isPositionFixed description='Form root' />

              <FormikComputedField<
                typeof values,
                Pick<typeof values, "departmentId" | "locationId">
              >
                debouncedDeps={{
                  deps: [values?.vehicleId],
                  wait: 500,
                }}
                compute={async (v) => {
                  const vehicleResponse = v.vehicleId
                    ? await apiClient.vehiclesApi.apiV1VehiclesVehicleIdGet({
                        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                        vehicleId: v.vehicleId!,
                      })
                    : null;
                  return {
                    departmentId: !values.departmentId
                      ? vehicleResponse?.data?.departmentIds?.at(0)
                      : undefined,
                    locationId: !values.locationId
                      ? vehicleResponse?.data?.locationIds?.at(0)
                      : undefined,
                  };
                }}
                onComputed={(computed) => {
                  setFieldValue("departmentId", computed.departmentId);
                  setFieldValue("locationId", computed.locationId);
                }}
              />

              {/* Computed fields (read-only) */}
              <FormikComputedField<
                typeof values,
                Pick<
                  typeof values,
                  "items" | "subTotal" | "subTotalIncDiscount" | "discount" | "tax" | "total"
                >
              >
                debouncedDeps={{
                  deps: [
                    {
                      items: values.items?.map((x) => x.repairSpec),
                    },
                  ],
                  wait: 1000,
                }}
                compute={(v) => {
                  const computedItems = (v.items || []).map((item) => ({
                    ...item,
                    subTotal: item.repairSpec?.subTotal,
                    subTotalIncDiscount: item.repairSpec?.subTotalIncDiscount,
                    discount: item.repairSpec?.discount,
                    tax: item.repairSpec?.tax,
                    total: item.repairSpec?.total,
                  }));
                  const computedSummary = PriceHelper.calcLineItemsSummary(computedItems);

                  return {
                    items: computedItems,
                    subTotal: computedSummary.subTotal,
                    subTotalIncDiscount: computedSummary.subTotalIncDiscount,
                    discount: computedSummary.discount,
                    tax: computedSummary.tax,
                    total: computedSummary.total,
                  };
                }}
                onComputed={(newValue) => {
                  // set only changed items to avoid re-renders
                  const changedItems = newValue.items?.filter((newItem) => {
                    const itemIndex =
                      values.items?.findIndex((x) => x.tempId == newItem.tempId) ?? -1;
                    const oldItem = values.items?.at(itemIndex);
                    if (!oldItem) {
                      return false;
                    }
                    const isChanged =
                      newItem.subTotal !== oldItem.subTotal ||
                      newItem.subTotalIncDiscount !== oldItem.subTotalIncDiscount ||
                      newItem.discount !== oldItem.discount ||
                      newItem.tax !== oldItem.tax ||
                      newItem.total !== oldItem.total;
                    if (isChanged) {
                      setFieldValue(`items[${itemIndex}]`, newItem);
                    }
                    return isChanged;
                  });

                  console.log("DCE computed", {
                    values: { ...values },
                    computed: newValue,
                    changedItems,
                  });
                  setFieldValue("subTotal", newValue.subTotal);
                  setFieldValue("subTotalIncDiscount", newValue.subTotalIncDiscount);
                  setFieldValue("discount", newValue.discount);
                  setFieldValue("tax", newValue.tax);
                  setFieldValue("total", newValue.total);
                }}
              />

              <FormikComputedField<typeof values, Pick<typeof values, "selectedDamageIds">>
                debouncedDeps={{
                  deps: [
                    {
                      damageIds: _.flatten(
                        values.items?.map((x) => x.damages?.map((y) => y.damageId)),
                      ),
                    },
                  ],
                  wait: 300,
                }}
                compute={(v) => {
                  const selectedDamageIds = _.flatten(
                    v.items
                      ?.map((x) => x.damages?.map((y) => y.damageId || "") ?? [])
                      ?.filter(Boolean) ?? [],
                  );
                  return {
                    selectedDamageIds,
                  };
                }}
                onComputed={(newValue) => {
                  setFieldValue("selectedDamageIds", newValue.selectedDamageIds);
                }}
              />

              <MemoWrapper
                props={{
                  isCreate,
                  canEdit,
                  damageCostEvaluation,
                  errors,
                  tags: values.tags,
                  vehicleId: values.vehicleId,
                  departmentId: values.departmentId,
                  locationId: values.locationId,
                  contractId: values.contractId,
                  currency: values.currency,
                  inspectedAt: values.inspectedAt,
                  isUseSupplierBranding: values.isUseSupplierBranding,
                }}
                arePropsEqualFactory={(params) =>
                  MemoHelper.arePropsEqual.factory<typeof params.props>({
                    isExcludeFunctionProps: true,
                  })
                }
              >
                {() => (
                  <Box>
                    <DevRenderCount sx={{ mb: 1 }} description='Form first section' />

                    {!canEdit && (
                      <Alert severity='info' sx={{ my: 2 }}>
                        {`This cost evaluation is completed and can't be edited.`}
                      </Alert>
                    )}

                    {/* Tags */}
                    <FormControl margin='dense' fullWidth>
                      <GeneralAttachedTagsInput
                        value={values.tags}
                        onChange={(newValue) => {
                          setFieldValue("tags", newValue);
                        }}
                      />
                      <FormHelperText error>
                        {ValidationHelper.getFormikErrorsAsString(errors.tags, {
                          isIncludeNested: false,
                        })}
                      </FormHelperText>
                    </FormControl>

                    {!isCreate && (
                      <TextField
                        fullWidth
                        disabled
                        label='Number'
                        margin='dense'
                        type='text'
                        value={damageCostEvaluation?.localNumber}
                        variant='outlined'
                      />
                    )}

                    <FormControl margin='dense' fullWidth error={Boolean(errors.vehicleId)}>
                      <VehicleAutocompleteOrCreate
                        autocompleteProps={{
                          required: true,
                          disabled: !isCreate,
                          entityId: values.vehicleId,
                          isPreload: isCreate,
                          onChange: (newValue) => {
                            setFieldValue(`vehicleId`, newValue?.id);
                            setFieldValue(`vehicle`, newValue || undefined);
                            // Reset items since a different vehicle implies different damage items:
                            setFieldValue(`items`, [{ damages: [{}] }]);
                          },
                        }}
                        createFormPlacement='modal'
                        onCreate={(newValue) => {
                          setFieldValue(`vehicleId`, newValue?.id);
                          setFieldValue(`vehicle`, newValue || undefined);
                          // Reset items since a different vehicle implies different damage items:
                          setFieldValue(`items`, [{ damages: [{}] }]);
                        }}
                      />
                      <FormHelperText>{errors.vehicleId}</FormHelperText>
                    </FormControl>

                    {/* Entity affiliation */}
                    <EntityAffiliationInput
                      disabled={!canEdit}
                      department={{
                        departmentId: values.departmentId,
                        onChange: (d) => {
                          setFieldValue("departmentId", d?.id);
                          setFieldValue("locationId", undefined);
                        },
                        error: errors.departmentId,
                      }}
                      location={{
                        locationId: values.locationId,
                        onChange: (l) => {
                          setFieldValue("locationId", l?.id);
                        },
                        searchFilters: { departmentId: values.departmentId },
                        createUpdateProps: { defaultValues: { departmentId: values.departmentId } },
                        error: errors.locationId,
                        disabled: !values.departmentId,
                      }}
                    />

                    {values.vehicleId && values.contractId && (
                      <FormControl margin='dense' fullWidth error={Boolean(errors.contractId)}>
                        <ContractAutocompleteOrCreate
                          autocompleteProps={{
                            disabled: true,
                            entityId: values.contractId,
                            isPreload: isCreate,
                            searchFilters: {
                              vehicleId: values.vehicleId || undefined,
                              excludeStage: ContractStage.Draft,
                            },
                            onChange: (newValue) => {
                              setFieldValue(`contractId`, newValue?.id);
                              setFieldValue(`contract`, newValue || undefined);
                            },
                          }}
                          createFormPlacement='modal'
                          onCreate={(newValue) => {
                            setFieldValue(`contractId`, newValue?.id);
                            setFieldValue(`contract`, newValue || undefined);
                          }}
                        />
                        <FormHelperText>{errors.contractId}</FormHelperText>
                      </FormControl>
                    )}

                    {values.vehicleId && (
                      <Box>
                        <FormControl
                          margin='dense'
                          fullWidth
                          required
                          error={Boolean(errors.currency)}
                        >
                          <CurrencyAutocomplete
                            required
                            disabled={!canEdit}
                            entity={values.currency}
                            onChange={(newValue) => setFieldValue("currency", newValue)}
                            textFieldProps={{
                              label: "Currency",
                              error: Boolean(errors.currency),
                              helperText: ValidationHelper.getFormikErrorsAsString(errors.currency),
                            }}
                          />
                          {/* <FormHelperText>Using currency from tenant settings</FormHelperText> */}
                        </FormControl>

                        <FormControl
                          margin='dense'
                          fullWidth
                          error={Boolean(touched.inspectedAt && errors.inspectedAt)}
                        >
                          <MobileDateTimePicker
                            disabled={!canEdit}
                            ampm={false}
                            label='Inspected at'
                            value={(values.inspectedAt && moment(values.inspectedAt)) || null}
                            format={DATETIME_FORMATS.DISPLAY_DATETIME}
                            onChange={(newValue: Moment | null) => {
                              setFieldValue("inspectedAt", newValue?.format() || null);
                            }}
                            slots={{ textField: (params) => <TextField {...params} /> }}
                          />
                          <FormHelperText>
                            {touched.inspectedAt && errors.inspectedAt}
                          </FormHelperText>
                        </FormControl>

                        {/* Include supplier branding */}
                        <FormControl margin='dense' fullWidth>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.isUseSupplierBranding}
                                disabled={!canEdit}
                                onChange={(e) =>
                                  setFieldValue(`isUseSupplierBranding`, e.target.checked)
                                }
                              />
                            }
                            label={
                              <Typography component='span' sx={{ display: "flex" }}>
                                Include supplier branding
                              </Typography>
                            }
                          />
                        </FormControl>
                      </Box>
                    )}
                  </Box>
                )}
              </MemoWrapper>

              <Box>
                {values.vehicleId && (
                  <Stack spacing={2}>
                    {/* Items */}
                    <FoldableBlock
                      defaultIsFolded={false}
                      trigger={{
                        label: <Typography variant='h2'>Items *</Typography>,
                      }}
                    >
                      <Stack spacing={2}>
                        {_.isEmpty(values.items) && <NoDataAlert title='No items yet' />}

                        <Stack direction='column' spacing={1}>
                          {values.vehicleId &&
                            values.items?.map((item, i) => (
                              <Box key={item.tempId}>
                                <Card>
                                  <CardContent>
                                    <DamageCostEvaluationItemInput
                                      formikProps={formikProps}
                                      isCreate={isCreate}
                                      isEdit={isEdit}
                                      canEdit={canEdit}
                                      canEditDamages={canEditDamages}
                                      canAddDamages={canAddDamages}
                                      canRemoveDamages={canRemoveDamages}
                                      canRemoveItems={canRemoveItems}
                                      canEditRepairSpec={canEditRepairSpec}
                                      vehicleId={values.vehicleId || ""}
                                      vehicle={values.vehicle}
                                      currency={values.currency}
                                      selectedDamageIds={values.selectedDamageIds}
                                      itemCount={values?.items?.length ?? 0}
                                      item={item}
                                      itemIndex={i}
                                      setIsAttachmentFilesUploading={setIsAttachmentFilesUploading}
                                    />
                                  </CardContent>
                                </Card>
                              </Box>
                            ))}
                        </Stack>

                        {errors.items && _.isString(errors.items) && (
                          <FormHelperText error>{errors.items}</FormHelperText>
                        )}

                        <Stack
                          direction='row'
                          spacing={2}
                          sx={{ justifyContent: "space-between", alignItems: "flex-start" }}
                        >
                          {/* Add item */}
                          <Button
                            disabled={!canAddItems}
                            variant='outlined'
                            size='small'
                            color='text'
                            startIcon={<AppIcon of='add' />}
                            onClick={() =>
                              setFieldValue("items", [
                                ...(values.items || []),
                                cast<DamageCostEvaluationItemValues>({
                                  tempId: IdHelper.newId(),
                                  damages: [{}],
                                }),
                              ])
                            }
                          >
                            Add item
                          </Button>

                          {/* Summary */}
                          <Box>
                            <Stack direction='row' justifyContent='flex-end'>
                              <GeneralPriceSummaryDisplay
                                sx={{ minWidth: { xxs: "100%", md: "300px" } }}
                                summary={{
                                  currency: values.currency,
                                  subTotal: values.subTotal || 0,
                                  subTotalIncDiscount: values.subTotalIncDiscount,
                                  discount: values.discount,
                                  tax: values.tax,
                                  total: values.total || 0,
                                }}
                              />
                            </Stack>
                          </Box>
                        </Stack>
                      </Stack>
                    </FoldableBlock>

                    <MemoWrapper
                      props={{
                        errors,
                        notes: values.notes,
                        contractId: values.contractId,
                        initialAttachments: values.initialAttachments,
                        attachments: values.attachments,
                        inspector: values.inspector,
                        isSendToCustomer: values.isSendToCustomer,
                      }}
                      arePropsEqualFactory={(params) =>
                        MemoHelper.arePropsEqual.factory<typeof params.props>({
                          isExcludeFunctionProps: true,
                        })
                      }
                    >
                      {() => (
                        <Stack spacing={2}>
                          <DevRenderCount description='Form last section' />

                          {/* Other */}
                          <FoldableBlock
                            defaultIsFolded={false}
                            trigger={{
                              label: <Typography variant='h2'>Other</Typography>,
                            }}
                          >
                            <AppTextArea
                              error={Boolean(touched.notes && errors.notes)}
                              fullWidth
                              helperText={touched.notes && errors.notes}
                              mode='notes'
                              margin='dense'
                              name='notes'
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.notes}
                              variant='outlined'
                            />

                            <FormControl fullWidth component='fieldset' margin='dense'>
                              <FileUploader
                                multiple
                                defaultFiles={FileItem.createManyFrom(values.initialAttachments)}
                                onChange={(newFiles) => {
                                  setFieldValue(
                                    `attachments`,
                                    FileItem.toManyGeneralAttachmentInputDto(newFiles),
                                  );
                                }}
                                onUploadStarted={() => {
                                  setIsAttachmentFilesUploading(true);
                                }}
                                onUploadFinished={() => {
                                  setIsAttachmentFilesUploading(false);
                                }}
                                onValidationStatusChange={(filesValidationStatus) => {
                                  if (filesValidationStatus)
                                    setFieldValue(
                                      "isAttachmentFilesHaveErrors",
                                      Object.values(filesValidationStatus).some((x) => x === false),
                                    );
                                }}
                              />
                            </FormControl>
                          </FoldableBlock>

                          <FormControl>
                            <FormControlLabel
                              control={
                                <Switch
                                  disabled
                                  checked={values.inspector?.isCurrentUser}
                                  onChange={handleChange}
                                  name='inspector.isCurrentUser'
                                />
                              }
                              label={
                                <AppTypography
                                  decoration={{ variant: "helpText" }}
                                  tooltipProps={{
                                    title: "Currently logged in user evaluated the damages",
                                  }}
                                >
                                  Inspected by{" "}
                                  {values.inspector?.personName
                                    ? `${values.inspector.personName?.firstName} ${values.inspector.personName?.lastName}`
                                    : profile?.personName?.name}
                                </AppTypography>
                              }
                            />
                          </FormControl>

                          {values.contractId && (
                            <FormControl margin='dense' fullWidth>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.isSendToCustomer ?? undefined}
                                    onChange={(e) =>
                                      setFieldValue("isSendToCustomer", e.target.checked)
                                    }
                                  />
                                }
                                label='Send to customer'
                              />
                            </FormControl>
                          )}
                        </Stack>
                      )}
                    </MemoWrapper>

                    <GeneralValidationError errors={errors} />

                    <FormActions>
                      <SplitDropdownButton
                        isSelectAndTriggerAction={false}
                        buttonProps={{
                          variant: "contained",
                          color: "primary",
                          type: "submit",
                          fullWidth: true,
                          loading: isSubmitting,
                          disabled:
                            isAttachmentFilesUploading ||
                            !canEdit ||
                            values.isAttachmentFilesHaveErrors ||
                            values.items?.some((x) => x.isAttachmentFilesHaveErrors === true),
                        }}
                        options={[
                          {
                            content: "Save",
                            onClick: () => {
                              submitForm();
                            },
                          },
                          {
                            content: "Save & Complete",
                            if: isCreate,
                            onClick: () => {
                              const newValue: GeneralStageUpdateDtoOfDamageCostEvaluationStage = {
                                newStage: DamageCostEvaluationStage.Completed,
                              };
                              setFieldValue("newStage", newValue);
                              submitForm();
                            },
                          },
                        ]}
                      />
                    </FormActions>
                  </Stack>
                )}
              </Box>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
