import { BoxProps, Stack, SxProps, Theme } from "@mui/material";
import _ from "lodash";
import { ReactNode } from "react";

import AppTypography from "../../Text/AppTypography";

interface Props {
  /** Of container. */
  sx?: SxProps<Theme>;
  label?: string | ReactNode;
  isEmpty?: boolean;
  onClick?: BoxProps["onClick"];
  children: ReactNode;
  value?: string;
}

export default function CountTile({ label, isEmpty, onClick, children, value, sx }: Props) {
  return (
    <Stack
      direction='column'
      sx={{
        alignItems: "center",
        borderRadius: "8px",
        backgroundColor: (th) => th.palette.background.gray,
        p: 1,
        cursor: onClick ? "pointer" : "default",
        ...sx,
      }}
      onClick={onClick}
    >
      <Stack direction='column' sx={{ alignItems: "center", width: "70%" }}>
        {label && (
          <AppTypography
            ellipsing={{ enabled: true, linesToStartEllipsing: 1 }}
            component='div'
            variant='subtitle1'
            color='text.secondary'
            title={_.isString(label) ? `${label}` : ""}
          >
            {label}
          </AppTypography>
        )}
        <AppTypography
          ellipsing={{ enabled: true, linesToStartEllipsing: 1 }}
          component='div'
          variant='subtitle1'
          color='primary'
          title={_.isString(children) ? `${children}` : ""}
        >
          {!isEmpty && !_.isNil(children) ? children : value || <>&nbsp;</>}
          {isEmpty && (value || "-")}
        </AppTypography>
      </Stack>
    </Stack>
  );
}
