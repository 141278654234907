import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

// Figma name: Icon/Fill/close-circle
export default forwardRef<SVGSVGElement, SvgIconProps>(function ArrowUpRight(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icon/Outline/corner-up-right'>
        <path
          id='Mask'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M19.7812 10.3754L15.7812 5.37545C15.4342 4.94445 14.8062 4.87345 14.3752 5.21945C13.9442 5.56345 13.8742 6.19345 14.2192 6.62445L16.9202 10.0004H8.00018C6.34518 10.0004 5.00018 11.3454 5.00018 13.0004V18.0004C5.00018 18.5524 5.44818 19.0004 6.00018 19.0004C6.55218 19.0004 7.00018 18.5524 7.00018 18.0004V13.0004C7.00018 12.4484 7.44818 12.0004 8.00018 12.0004H16.9202L14.2192 15.3754C13.8742 15.8064 13.9442 16.4364 14.3752 16.7804C14.5592 16.9284 14.7802 17.0004 14.9992 17.0004C15.2932 17.0004 15.5832 16.8714 15.7812 16.6244L19.7812 11.6244C20.0732 11.2594 20.0732 10.7404 19.7812 10.3754Z'
        />
        <mask id='mask0_3195_22396' maskUnits='userSpaceOnUse' x='5' y='4' width='16' height='16'>
          <path
            id='Mask_2'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M19.7812 10.3754L15.7812 5.37545C15.4342 4.94445 14.8062 4.87345 14.3752 5.21945C13.9442 5.56345 13.8742 6.19345 14.2192 6.62445L16.9202 10.0004H8.00018C6.34518 10.0004 5.00018 11.3454 5.00018 13.0004V18.0004C5.00018 18.5524 5.44818 19.0004 6.00018 19.0004C6.55218 19.0004 7.00018 18.5524 7.00018 18.0004V13.0004C7.00018 12.4484 7.44818 12.0004 8.00018 12.0004H16.9202L14.2192 15.3754C13.8742 15.8064 13.9442 16.4364 14.3752 16.7804C14.5592 16.9284 14.7802 17.0004 14.9992 17.0004C15.2932 17.0004 15.5832 16.8714 15.7812 16.6244L19.7812 11.6244C20.0732 11.2594 20.0732 10.7404 19.7812 10.3754Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_3195_22396)'>
          <g id='&#240;&#159;&#142;&#168; Color'>
            <rect id='Base' width='24' height='24' />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
});
