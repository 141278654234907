import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function ContractsIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_12064_25658)'>
        <path d='M13.3247 4.86216H16.1424L16.1426 8.14943L17.5711 5.93146V4.07225C17.5711 3.95869 17.5259 3.84979 17.4456 3.76912L13.8018 0.125452C13.7215 0.0452048 13.6125 0 13.4987 0H2.23233C1.5234 0 0.946533 0.576609 0.946533 1.28559V20.7144C0.946533 21.4233 1.52335 22 2.23233 22H16.2852C16.9941 22 17.5709 21.4233 17.5709 20.7144V15.1983L16.1428 17.3837L16.143 20.5713H2.37517V1.42864H12.7941V4.33143C12.7941 4.62471 13.0316 4.86216 13.3247 4.86216Z' />
        <path d='M19.1663 10.2207L18.5759 9.835C18.4795 9.77209 18.3505 9.79906 18.2875 9.89544L15.1086 14.7599L14.4973 14.3604L17.6762 9.49599C17.7392 9.39955 17.7122 9.27054 17.6158 9.20758L17.0254 8.82179C16.929 8.75883 16.7999 8.78586 16.737 8.88224L11.9772 16.166C11.957 16.1969 11.9454 16.2327 11.9435 16.2696L11.8191 18.7974C11.8155 18.8712 11.8513 18.9417 11.9132 18.982C11.9752 19.0225 12.054 19.027 12.1201 18.9941L14.3852 17.8651C14.4184 17.8487 14.4465 17.8236 14.4668 17.7926L19.2265 10.5089C19.2897 10.4128 19.2625 10.2837 19.1663 10.2207Z' />
        <path d='M21.1166 7.23616L18.9759 5.83717C18.8796 5.77426 18.7504 5.80129 18.6875 5.89762L17.2437 8.107C17.1808 8.20338 17.2077 8.33255 17.3041 8.39551L19.4449 9.7944C19.5412 9.85736 19.6704 9.83033 19.7333 9.73395L21.177 7.52463C21.2401 7.42824 21.213 7.29913 21.1166 7.23616Z' />
        <path d='M4.45594 4.58134H8.54165C8.83095 4.58134 9.06546 4.34678 9.06546 4.05753C9.06546 3.76828 8.83095 3.53372 8.54165 3.53372H4.45594C4.16664 3.53372 3.93213 3.76828 3.93213 4.05753C3.93213 4.34678 4.16674 4.58134 4.45594 4.58134Z' />
        <path d='M12.2083 6.67658C12.2083 6.38733 11.9737 6.15277 11.6845 6.15277H4.45594C4.16664 6.15277 3.93213 6.38733 3.93213 6.67658C3.93213 6.96583 4.16664 7.20039 4.45594 7.20039H11.6845C11.9738 7.20039 12.2083 6.96583 12.2083 6.67658Z' />
        <path d='M4.45594 9.81944H8.54165C8.83095 9.81944 9.06546 9.58488 9.06546 9.29563C9.06546 9.00638 8.83095 8.77182 8.54165 8.77182H4.45594C4.16664 8.77182 3.93213 9.00638 3.93213 9.29563C3.93213 9.58488 4.16674 9.81944 4.45594 9.81944Z' />
        <path d='M4.45594 12.4385H8.54165C8.83095 12.4385 9.06546 12.2039 9.06546 11.9147C9.06546 11.6255 8.83095 11.3909 8.54165 11.3909H4.45594C4.16664 11.3909 3.93213 11.6255 3.93213 11.9147C3.93213 12.2039 4.16674 12.4385 4.45594 12.4385Z' />
        <path d='M4.67764 19.2078C4.57566 19.3396 4.75962 19.5264 4.86286 19.3928C5.1883 18.9724 5.45707 18.5196 5.68152 18.0417C5.82159 18.1296 6.0055 18.112 6.15636 18.0524C6.37379 17.9667 6.55812 17.8012 6.73365 17.6514C6.83741 17.5629 6.9582 17.4167 7.10183 17.4027C7.21152 17.3922 7.35965 17.4805 7.46928 17.4993C7.75576 17.5481 8.09036 17.4652 8.36694 17.3969C8.62942 17.3321 8.93679 17.2296 9.09126 16.9919C9.18361 16.8498 8.95675 16.7187 8.86513 16.8599C8.74146 17.0501 8.43948 17.1094 8.23541 17.1598C7.99587 17.219 7.71794 17.2774 7.47106 17.2353C7.28501 17.2037 7.13452 17.0912 6.94322 17.1764C6.68697 17.2906 6.49123 17.5305 6.26494 17.6926C6.16369 17.765 6.04798 17.8347 5.92132 17.8445C5.8692 17.8485 5.82672 17.8294 5.79142 17.7976C5.81928 17.7329 5.84694 17.668 5.87329 17.6025C6.1287 16.9684 6.2442 16.3246 6.36274 15.6567C6.42297 15.3173 6.52318 14.7826 6.03677 14.7499C5.60698 14.7212 5.23555 15.3412 5.09821 15.6679C4.97537 15.9601 4.9617 16.2995 4.97108 16.6115C4.97689 16.8059 5.04687 17.0078 5.09847 17.2088C4.68602 17.275 4.3225 17.6916 4.38619 18.1045C4.44889 18.5109 4.95704 18.3011 5.14682 18.1507C5.3389 17.9985 5.40107 17.8084 5.40312 17.6034C5.44141 17.6665 5.47341 17.7422 5.50652 17.813C5.28521 18.3094 5.00775 18.7813 4.67764 19.2078ZM5.13671 17.7253C5.09795 17.8981 4.87936 18.0686 4.70724 18.0846C4.57183 18.0971 4.65444 17.8553 4.6812 17.8012C4.76522 17.6314 4.97338 17.5206 5.14839 17.4728C5.15656 17.558 5.15525 17.6425 5.13671 17.7253ZM5.35545 17.2182C5.31087 17.01 5.24901 16.8008 5.23298 16.6115C5.20197 16.2462 5.26687 15.8633 5.44806 15.5438C5.56126 15.3441 5.79199 15.0381 6.03677 15.0119C6.25536 14.9886 6.12205 15.5203 6.10314 15.6271C6.04463 15.9568 6.00288 16.284 5.92132 16.6099C5.84558 16.9125 5.74962 17.2123 5.63485 17.5053C5.57383 17.3535 5.49436 17.2563 5.35545 17.2182Z' />
      </g>
      <defs>
        <clipPath id='clip0_12064_25658'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
});
