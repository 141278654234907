import { useRef } from "react";

import { ComparisonHelper } from "@/common/helpers/comparison";

/** Returns the newest memorized value (uses deep comparison). Skips deep comparison until condition is `true`. */
export function useValueMemoWithDeepCompareIf<TValue>(
  condition: boolean,
  value: TValue,
  defaultValue?: TValue | undefined,
): TValue | undefined {
  const ref = useRef<TValue | undefined>(defaultValue || undefined);

  if (condition && !ComparisonHelper.isDeepEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}
