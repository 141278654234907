import {
  Alert,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import { useMemo } from "react";
import * as Yup from "yup";

import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  VehicleDamageDto,
  VehicleDamageMigrateToNewVisualModelDto,
  VehicleVisualModelDto,
  VehicleVisualModelImageDto,
} from "@/core/api/generated";

import NoDataAlert from "../../AppAlerts/NoDataAlert";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FormikComputedField from "../../Form/Formik/FormikComputedField";
import DamagePointsVisualizer from "../../Images/DamagePointsVisualizer";
import { VisualModelImagesList } from "../../Images/VisualModelImagesList";
import ZoomableBlock from "../../Images/ZoomableBlock";
import { ExtendedDamageType } from "../DamageDetection/DamageDetectionCreateUpdate";
import VehicleVisualModelAutocomplete from "../VehicleVisualModel/VehicleVisualModelAutocomplete";

interface OwnProps {
  vehicleDamage: VehicleDamageDto;
  onSave?: (newValue: VehicleDamageDto) => void;
}

export type VehicleDamageMigrateToNewVisualModelProps = OwnProps;

export default function VehicleDamageMigrateToNewVisualModel({
  vehicleDamage,
  onSave,
}: VehicleDamageMigrateToNewVisualModelProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  // const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);

  const vehicleVisualModelLatestOrDefaultRequest = useApiRequest(
    apiClient.vehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGet,
    {
      vehicleId: vehicleDamage.vehicle?.id || undefined,
      isIgnoreDefaultForVehicle: true,
    },
    {
      deps: [vehicleDamage.vehicle?.id],
    },
  );
  const vehicleVisualModelLatestOrDefault = vehicleVisualModelLatestOrDefaultRequest?.data;

  const oldVehicleVisualModelRequest = useApiRequest(
    apiClient.vehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsModelIdGet,
    {
      modelId: vehicleDamage.visualModel?.id || "",
    },
    {
      deps: [vehicleDamage.visualModel?.id],
    },
  );
  const oldVehicleVisualModel = oldVehicleVisualModelRequest?.data;

  const oldVehicleVisualModelImage = useMemo(
    () =>
      oldVehicleVisualModel?.images?.find(
        (x) => x.area === vehicleDamage?.area && x.projection === vehicleDamage?.projection,
      ),
    [vehicleDamage, oldVehicleVisualModel],
  );

  if (oldVehicleVisualModelRequest.isLoading && !oldVehicleVisualModelRequest.isFirstEnded) {
    return <LinearProgress />;
  }

  return (
    <Stack spacing={1}>
      <Alert severity='info'>
        To migrate the damage to new vehicle visual model, manually select new visual model and
        place damage point on correct position.
      </Alert>

      <Formik<
        VehicleDamageMigrateToNewVisualModelDto &
          BaseFormikValues & {
            newVehicleVisualModel?: VehicleVisualModelDto;
            newVehicleVisualModelImage?: VehicleVisualModelImageDto;
            newVehicleDamage?: ExtendedDamageType;
          }
      >
        enableReinitialize
        initialValues={{
          oldVehicleVisualModelId: oldVehicleVisualModel?.id,
          newVehicleVisualModelId:
            vehicleVisualModelLatestOrDefault &&
            vehicleVisualModelLatestOrDefault.id !== oldVehicleVisualModel?.id
              ? vehicleVisualModelLatestOrDefault.id
              : undefined,
          newVehicleVisualModel:
            vehicleVisualModelLatestOrDefault &&
            vehicleVisualModelLatestOrDefault.id !== oldVehicleVisualModel?.id
              ? vehicleVisualModelLatestOrDefault
              : undefined,
          oldPoint: vehicleDamage?.point,
          newPoint: undefined,
          isMigrateDamageDetection: true,
          newVehicleDamage: undefined,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // vehicleId: Yup.string().required("Vehicle is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            const response =
              await apiClient.vehicleDamagesApi.apiV1VehiclesVehicleIdDamagesVehicleDamageIdMigrateToNewVisualModelPost(
                {
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  vehicleId: vehicleDamage.vehicle?.id || "",
                  vehicleDamageId: vehicleDamage.id || "",
                  vehicleDamageMigrateToNewVisualModelDto: {
                    ...values,
                    newPoint: values.newVehicleDamage?.point,
                  },
                },
              );
            enqueueSnackbar("Saved.", {
              variant: "success",
            });
            onSave && onSave(response.data);

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              {/* Computed fields (read-only) */}
              <FormikComputedField<typeof values, Pick<typeof values, "newVehicleVisualModelImage">>
                deps={[values.newVehicleVisualModel]}
                compute={(v) => {
                  return {
                    newVehicleVisualModelImage: values.newVehicleVisualModel?.images?.find(
                      (x) =>
                        x.area === vehicleDamage?.area &&
                        x.projection === vehicleDamage?.projection,
                    ),
                  };
                }}
                onComputed={(newValue) => {
                  setFieldValue("newVehicleVisualModelImage", newValue.newVehicleVisualModelImage);
                }}
              />

              <Stack spacing={2}>
                <Stack spacing={2}>
                  <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: 4 }}>
                    {/* Old model */}
                    <Stack spacing={1}>
                      <Typography variant='h6'>Old visual model</Typography>

                      {(!oldVehicleVisualModel || !oldVehicleVisualModelImage) && (
                        <NoDataAlert title='Old visual model not found for this damage.' />
                      )}

                      <Box>
                        <FormControl fullWidth margin='dense'>
                          <VehicleVisualModelAutocomplete disabled entity={oldVehicleVisualModel} />
                        </FormControl>

                        {oldVehicleVisualModel && oldVehicleVisualModelImage && (
                          <Box>
                            <ZoomableBlock>
                              <DamagePointsVisualizer
                                containerSx={{
                                  width: `500px`,
                                  height: `500px`,
                                  cursor: "default",
                                }}
                                pointsInfo={[{ ...vehicleDamage, isDraggable: false }]}
                                imageUrl={oldVehicleVisualModelImage?.file?.url}
                              />
                            </ZoomableBlock>

                            <VisualModelImagesList
                              visualModel={oldVehicleVisualModel}
                              imageContainerSx={{ width: `100px`, height: `auto` }}
                            />
                          </Box>
                        )}
                      </Box>
                    </Stack>

                    {/* New model */}
                    <Stack spacing={1}>
                      <Typography variant='h6'>New visual model</Typography>

                      {(!oldVehicleVisualModel || !oldVehicleVisualModelImage) && (
                        <NoDataAlert title='New visual model not found for this damage.' />
                      )}

                      <Box>
                        <FormControl fullWidth margin='dense'>
                          <VehicleVisualModelAutocomplete
                            entity={undefined}
                            entityId={values.newVehicleVisualModelId}
                            searchFilters={{
                              excludeId: oldVehicleVisualModel?.id || undefined,
                            }}
                            onChange={(newValue) => {
                              setFieldValue("newVehicleVisualModelId", newValue?.id);
                              setFieldValue("newVehicleVisualModel", newValue);
                            }}
                          />
                        </FormControl>

                        {values.newVehicleVisualModel && values.newVehicleVisualModelImage && (
                          <Box>
                            <ZoomableBlock>
                              <DamagePointsVisualizer
                                containerSx={{
                                  width: `500px`,
                                  height: `500px`,
                                  cursor: "crosshair",
                                }}
                                pointsInfo={[{ ...values.newVehicleDamage, isDraggable: true }]}
                                imageUrl={values.newVehicleVisualModelImage?.file?.url}
                                onNewPointClicked={(pointInfo) => {
                                  setFieldValue("newVehicleDamage", { ...pointInfo });
                                }}
                              />
                            </ZoomableBlock>

                            <VisualModelImagesList
                              visualModel={values.newVehicleVisualModel}
                              imageContainerSx={{ width: `100px`, height: `auto` }}
                            />
                          </Box>
                        )}
                      </Box>
                    </Stack>
                  </Box>

                  <FormControl margin='dense' fullWidth>
                    <FormControlLabel
                      sx={{ margin: 0 }}
                      disableTypography
                      control={
                        <Checkbox
                          checked={values.isMigrateDamageDetection ?? false}
                          onChange={(e) => {
                            setFieldValue("isMigrateDamageDetection", e.target.checked);
                          }}
                        />
                      }
                      label={"Migrate damage detection"}
                    />
                    <FormHelperText>
                      Check to apply updates for this damage in the source damage detection (only if
                      the damage detection contains only this damage).
                    </FormHelperText>
                  </FormControl>
                </Stack>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <Button
                  sx={{ mt: { xs: "auto", md: 2 } }}
                  color='primary'
                  loading={isSubmitting}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Migrate
                </Button>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </Stack>
  );
}
