import _ from "lodash";

export class CloneHelper {
  public static cloneShallow<T = any>(value: T): T {
    return _.clone(value);
  }

  public static cloneDeep<T = any>(value: T): T {
    return _.cloneDeep(value);
  }
}
