import {
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";

import { ChatMessageNodeBuilder } from "@/common/builders/chatMessageNode";
import AppIconButton from "@/common/components/Button/AppIconButton";
import AppIcon from "@/common/components/Icons/AppIcon";
import { ChatContextEventsMap, useChatContext } from "@/common/contexts/chat";
import { ChatMessageHelper } from "@/common/helpers/entity/chatMessage";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper, ValidationInfo } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  ChatMessageDto,
  ChatMessageNodeDto,
  ChatMessageRootNodeDto,
} from "@/core/api/generated";

import { ChatSize } from "../../Chat/ChatDisplay";
import ChatMessageTagHandler from "./ChatMessageTagHandler";
import ChatMessageWebLinkHandler from "./ChatMessageWebLinkHandler";

export interface ChatMessageInputProps {
  chatId: string;
  initialValue?: ChatMessageDto;
  size?: ChatSize;
  placeholder?: string;
  actionText?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  maxLength?: number;
  validation?: ValidationInfo;
  hasUploadedAttachments?: boolean;
  secondaryActions?: React.ReactNode[];
  defaultEnableCancel?: boolean;
  renderAttachments?: () => React.ReactNode;
  onFocus?: TextFieldProps["onFocus"];
  onBlur?: TextFieldProps["onBlur"];
  onChange?: (data: { body: string; rootNode: ChatMessageRootNodeDto }) => void;
  onPasteFiles?: (files: File[]) => void;
  onAction?: (data: { body: string; rootNode: ChatMessageRootNodeDto }) => void | Promise<void>;
  onCancel?: () => void;
}

export default function ChatMessageInput({
  chatId,
  initialValue,
  size = "medium",
  placeholder = "Reply...",
  actionText = "Save",
  autoFocus = false,
  disabled = false,
  maxLength = 1000,
  validation,
  hasUploadedAttachments,
  secondaryActions,
  defaultEnableCancel = false,
  renderAttachments,
  onFocus,
  onBlur,
  onChange,
  onPasteFiles,
  onAction,
  onCancel,
}: ChatMessageInputProps) {
  const { enqueueSnackbar } = useAppSnackbar();
  const { hasPermissions } = useAuthorizationInfo();
  const chatContext = useChatContext();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [messageBody, setMessageBody] = useState("");
  const [rootNode, setRootNode] = useState<ChatMessageRootNodeDto>({
    nodes: [],
  });
  const currentSelectionRef = useRef<CustomCursorSelection>({
    startIndex: null,
    endIndex: null,
  });
  const showActions = true;
  const canPerformPrimaryAction = hasUploadedAttachments || (messageBody && messageBody.length > 0);
  const isNotEmptyMessageEntered = messageBody && messageBody.length > 0;

  const scrollToInput = useCallback(() => {
    inputRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [inputRef.current]);
  const focusInput = useCallback(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 500);
  }, [inputRef.current]);

  // handle context events
  useEffect(() => {
    const handleScrollToInputRequested = () => {
      console.log("handleScrollToInputRequested.");
      scrollToInput();
    };

    const handleScrollToInputAndFocusRequested = () => {
      console.log("handleScrollToInputAndFocusRequested.");
      scrollToInput();
      focusInput();
    };

    const handleCreateTagByScopeInMessageInputRequested = async (
      params: ChatContextEventsMap["createTagByScopeInMessageInputRequested"],
    ) => {
      console.log("handleCreateTagInMessageInputRequested.", { params });

      // find tag candidate by scope and add to message
      try {
        const response = await apiClient.generalTagsApi.apiV1TagsGeneralCandidatesGetByScopePost({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          generalTagCandidatesByScopeRequestDto: {
            offset: 0,
            limit: 100,
            scope: params.scope || undefined,
          },
        });
        const tagCandidate = _.first(response.data.items);
        const tagSubCandidate = _.first(tagCandidate?.subCandidates);

        const tagNodes: ChatMessageNodeDto[] = [];
        if (tagCandidate) {
          tagNodes.push(ChatMessageNodeBuilder.newTagNodeFromCandidate(tagCandidate).build());
        }
        if (tagSubCandidate) {
          tagNodes.push(ChatMessageNodeBuilder.newTagNodeFromCandidate(tagSubCandidate).build());
        }

        if (tagNodes.length !== 0) {
          const rootNode2 = _.cloneDeep(rootNode);

          // white space from entered text
          if (rootNode2.nodes!.length !== 0) {
            ChatMessageHelper.insertNodeAtTheEnd(
              rootNode2,
              ChatMessageNodeBuilder.newTextNodeForWhiteSpace().build(),
            );
          }

          tagNodes.forEach((node) => {
            ChatMessageHelper.insertNodeAtTheEnd(rootNode2, node);
            ChatMessageHelper.insertNodeAtTheEnd(
              rootNode2,
              ChatMessageNodeBuilder.newTextNodeForWhiteSpace().build(),
            );
          });

          setRootNode(rootNode2);
          setMessageBody(ChatMessageHelper.buildRootNodeFullText(rootNode2));
        }
      } catch (err) {
        const validation2 = ValidationHelper.handleApiErrorResponse(err);
        validation2.hasErrors &&
          enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
      }

      if (params.isScrollToInput) {
        scrollToInput();
      }
      if (params.isFocusInput) {
        focusInput();
      }
    };

    chatContext.on("scrollToMessageInputRequested", handleScrollToInputRequested);
    chatContext.on("scrollToMessageInputAndFocusRequested", handleScrollToInputAndFocusRequested);
    chatContext.on(
      "createTagByScopeInMessageInputRequested",
      handleCreateTagByScopeInMessageInputRequested,
    );

    return () => {
      chatContext.off("scrollToMessageInputRequested", handleScrollToInputRequested);
      chatContext.off(
        "scrollToMessageInputAndFocusRequested",
        handleScrollToInputAndFocusRequested,
      );
      chatContext.off(
        "createTagByScopeInMessageInputRequested",
        handleCreateTagByScopeInMessageInputRequested,
      );
    };
  }, [rootNode]);

  useEffect(() => {
    if (initialValue) {
      const initialRootNode = initialValue.rootNode
        ? _.cloneDeep(initialValue.rootNode)
        : {
            nodes: [],
          };
      setRootNode(initialRootNode);
      setMessageBody(initialValue.body || "");
    }
  }, [initialValue]);

  const reset = () => {
    setIsActionLoading(false);
    setRootNode({
      nodes: [],
    });
    setMessageBody("");
  };

  const handleAction = async () => {
    if (onAction) {
      if (messageBody.trim().length === 0) {
        setMessageBody("");
        return;
      }
      const finalRootNode = ChatMessageHelper.buildFinalRootNode(rootNode, { resetTempIds: true });
      setIsActionLoading(true);
      try {
        await onAction({
          body: messageBody,
          rootNode: finalRootNode,
        });
        reset();
        inputRef.current?.focus();
      } finally {
        setIsActionLoading(false);
      }
    } else {
      reset();
    }
  };

  const handleCancel = () => {
    setMessageBody("");
    setRootNode({ nodes: [] });
    onCancel && onCancel();
  };

  return (
    <Box>
      <ChatMessageTagHandler
        chatId={chatId}
        disabled={!hasPermissions([AppPermission.FleetAppAccess])}
        onMessageChange={(newValue) => {
          console.log("onMessageChange (tag)", {
            body: ChatMessageHelper.buildRootNodeFullText(rootNode),
            rootNode: newValue.rootNode,
            nodesText: rootNode.nodes!.map((x) => x.text?.text),
          });
          setRootNode(newValue.rootNode);
          setMessageBody(ChatMessageHelper.buildRootNodeFullText(rootNode));
        }}
      >
        {(tagParams) => (
          <ChatMessageWebLinkHandler
            onMessageChange={(newValue) => {
              // console.log("onMessageChange (weblink)", {
              //   body: ChatMessageHelper.buildRootNodeFullText(rootNode),
              //   rootNode: newValue.rootNode,
              //   nodesText: rootNode.nodes!.map((x) => x.text?.text),
              // });
              setRootNode(newValue.rootNode);
              setMessageBody(ChatMessageHelper.buildRootNodeFullText(rootNode));
            }}
          >
            {(webLinkParams) => (
              <Stack>
                <Box
                  sx={{
                    border: (t) =>
                      `1px solid ${isNotEmptyMessageEntered ? t.palette.secondary.main : t.palette.divider}`,
                    borderRadius: (t) => t.shapeCustom.borderRadiusCard,
                    pl: 1,
                    overflow: "hidden",
                  }}
                >
                  <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
                    {/* Secondary actions */}
                    {showActions && (
                      <Stack direction='row' spacing={1} sx={{ mr: "auto" }}>
                        {secondaryActions?.map((secondaryAction, i) => (
                          <Box key={i}>{secondaryAction}</Box>
                        ))}
                      </Stack>
                    )}

                    <TextField
                      sx={{
                        "& .MuiInputBase-root": {
                          backgroundColor: "transparent !important",
                          boxShadow: "none !important",

                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none !important",
                          },
                        },
                      }}
                      inputRef={inputRef}
                      InputProps={{
                        endAdornment: (defaultEnableCancel || isNotEmptyMessageEntered) && (
                          <InputAdornment position='end'>
                            <IconButton size='small' color='text' onClick={handleCancel}>
                              <AppIcon of='clear' />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      multiline
                      maxRows={6}
                      autoFocus={autoFocus}
                      disabled={disabled}
                      label=''
                      placeholder={placeholder}
                      variant='outlined'
                      size={size || "medium"}
                      value={messageBody}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        if (newValue.length > maxLength) {
                          throw new Error(`Max input value length of ${maxLength} exceeded.`);
                        }

                        const newSelection: CustomCursorSelection = {
                          startIndex: e.target.selectionStart,
                          endIndex: e.target.selectionEnd,
                        };

                        setMessageBody(newValue);

                        onChange && onChange({ body: newValue, rootNode: rootNode });
                        let handleResult = tagParams.handleMessageChange(
                          rootNode,
                          currentSelectionRef.current,
                          newSelection,
                          newValue,
                        );
                        if (!handleResult.isHandled) {
                          handleResult = webLinkParams.handleMessageChange(
                            rootNode,
                            currentSelectionRef.current,
                            newSelection,
                            newValue,
                          );
                        }

                        if (!handleResult.isHandled) {
                          ChatMessageHelper.handleTextChange({
                            rootNode,
                            oldSelection: currentSelectionRef.current,
                            newSelection,
                            change: {
                              value: newValue,
                            },
                          });
                          setRootNode(rootNode);
                          setMessageBody(ChatMessageHelper.buildRootNodeFullText(rootNode));
                        }

                        // console.log("onChange.", {
                        //   value: e.target.value,
                        //   body: ChatMessageHelper.buildRootNodeFullText(rootNode),
                        //   handleResult,
                        //   nodeIndexes: rootNode.nodes!.map((x) => ({
                        //     startIndex: x.startIndex,
                        //     endIndex: x.endIndex,
                        //   })),
                        //   currentSelection: currentSelectionRef.current,
                        //   newSelection,
                        //   rootNode,
                        //   nodesText: rootNode.nodes!.map((x) => x.text?.text),
                        // });
                      }}
                      onKeyDown={(e) => {
                        // const event = e as React.KeyboardEvent<
                        //   HTMLTextAreaElement | HTMLInputElement
                        // >;

                        let result = tagParams.handleKeyDown(e);
                        if (!result.isHandled) {
                          result = webLinkParams.handleKeyDown(e);
                        }

                        if (result.isHandled) {
                          e.preventDefault();
                          return;
                        }
                        if (e.code === "Enter" && e.ctrlKey) {
                          handleAction();
                        }
                      }}
                      // fires on text selection (via mouse or keyboard, after focus),
                      // fires after onChange
                      onSelect={(e) => {
                        type TargetType = HTMLTextAreaElement | HTMLInputElement;
                        const event = e as React.KeyboardEvent<TargetType>;
                        currentSelectionRef.current = {
                          startIndex: (event.target as TargetType).selectionStart,
                          endIndex: (event.target as TargetType).selectionEnd,
                        };
                      }}
                      onPaste={(e) => {
                        const files = Array.from(e.clipboardData.files || []);
                        if (files.length !== 0 && onPasteFiles) {
                          e.preventDefault();
                          onPasteFiles(files);
                        }
                      }}
                    />

                    {/* Primary actions */}
                    {showActions && (
                      <Stack direction='row' spacing={1} sx={{ justifyContent: "flex-end", mt: 1 }}>
                        <Tooltip
                          title={
                            <Stack>
                              <Box>{actionText}</Box>
                              <Typography component='div' variant='caption'>
                                CTRL + ENTER to submit
                              </Typography>
                            </Stack>
                          }
                        >
                          <Box sx={{ display: "inline-flex" }}>
                            <AppIconButton
                              sx={{
                                borderRadius: 0,
                                p: 1.5,
                                ...((!isNotEmptyMessageEntered && {
                                  backgroundColor: (th) => th.palette.background.paper,
                                }) || {
                                  backgroundColor: (t) => `${t.palette.secondary.main} !important`,
                                  color: (t) => t.palette.secondary.contrastText,
                                }),
                                "&:hover": {
                                  ...((!isNotEmptyMessageEntered && {
                                    backgroundColor: (th) => th.palette.background.paper,
                                  }) || {
                                    backgroundColor: (t) =>
                                      `${t.palette.secondary.main} !important`,
                                    color: (t) => t.palette.secondary.contrastText,
                                  }),
                                },
                              }}
                              size={
                                (size === "small" && "small") ||
                                (size === "medium" && "large") ||
                                "large"
                              }
                              // variant='contained'
                              color={isNotEmptyMessageEntered ? "primary" : "text"}
                              disabled={!canPerformPrimaryAction}
                              loading={isActionLoading}
                              onClick={handleAction}
                            >
                              <AppIcon of='send' color='inherit' />
                            </AppIconButton>
                          </Box>
                        </Tooltip>
                      </Stack>
                    )}
                  </Stack>
                </Box>

                {validation?.hasErrors && (
                  <FormHelperText error={validation?.hasErrors} component='div'>
                    {validation?.getErrorsAsList().map((errMessage, i) => (
                      <Typography component='div' key={i} fontSize='inherit'>
                        {errMessage}
                      </Typography>
                    ))}
                  </FormHelperText>
                )}
              </Stack>
            )}
          </ChatMessageWebLinkHandler>
        )}
      </ChatMessageTagHandler>

      {/* Attachments */}
      {renderAttachments && (
        <Box sx={{ maxHeight: "300px", overflowY: "auto" }}>{renderAttachments()}</Box>
      )}
    </Box>
  );
}
