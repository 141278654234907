import { useParams } from "react-router-dom";

import RepairSpecView from "@/common/components/Entity/RepairSpec/View/RepairSpecView";
import { apiClient } from "@/core/api/ApiClient";

export default function RepairSpecViewPage() {
  const { repairSpecId } = useParams<{ repairSpecId?: string }>();

  return (
    <RepairSpecView
      repairSpecId={repairSpecId}
      headerProps={{
        withGoBack: true,
        withLink: false,
      }}
      getFunc={async (params) =>
        await apiClient.adminRepairSpecsApi.apiV1AdminRepairSpecsRepairSpecIdGet({
          repairSpecId: params.repairSpecId,
        })
      }
      deleteFunc={async (params) =>
        await apiClient.adminRepairSpecsApi.apiV1AdminRepairSpecsRepairSpecIdDelete({
          repairSpecId: params.repairSpecId,
        })
      }
    />
  );
}
