import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { AssetSubscriptionDto, AssetSubscriptionReferenceDto } from "@/core/api/generated";

import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getAssetSubscriptionOptionTitle } from "./AssetSubscriptionAutocomplete";

interface Props extends BaseEntityInlineInheritableProps {
  entity: AssetSubscriptionDto | AssetSubscriptionReferenceDto | null | undefined;
  entityId?: string | null;
}

/** Inline brief information about the entity. */
export default function AssetSubscriptionInline({ entity, entityId, ...otherProps }: Props) {
  const request = useApiRequest(
    apiClient.assetSubscriptionsApi.apiV1AssetSubscriptionsAssetSubscriptionIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assetSubscriptionId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as AssetSubscriptionDto;
  // const ref = entity as AssetSubscriptionReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='entity' inText />}
      content={<>{full && getAssetSubscriptionOptionTitle(full)}</>}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Asset subscription"}
    />
  );
}
