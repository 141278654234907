import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid2,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AccessoryLink from "@/common/components/Entity/Accessory/AccessoryLink";
import VehicleLink from "@/common/components/Entity/Vehicle/VehicleLink";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  ImportEntityType,
  ImportRowResultStatus,
  ImportStatus,
} from "@/core/api/generated";

const defaultDisplayProps = {
  viewVariant: ViewLayoutVariant.Page,
};
interface Props {
  displayProps?: Partial<typeof defaultDisplayProps>;
  importId?: string;
}

export default function ImportViewPage({ importId, displayProps }: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const history = useHistory();
  const currentTenant = useCurrentTenant();
  const importRequest = useApiRequest(
    apiClient.dataImportApi.apiV1DataImportImportIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      importId: importId!,
    },
    {
      deps: [importId],
      skip: !importId,
    },
  );

  const importSpecRequest = useApiRequest(
    apiClient.dataImportApi.apiV1DataImportSpecGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      importEntityType: importRequest.data?.entityType,
    },
    {
      deps: [importRequest.data],
      skip: !importRequest.data || importRequest.data.entityType === ImportEntityType.None,
    },
  );

  useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(
        currentTenant?.id,
        EntityType.Import,
        importRequest.data?.id || "",
      ),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      importRequest.handleEntityChanged(data);
    },
  });
  if (!importRequest.data || !importSpecRequest.data) {
    return <LinearProgress />;
  }
  return (
    <ViewLayout
      displayProps={displayProps}
      header={
        <SimpleViewPageHeader
          title='Import'
          primaryActions={
            <AuthorizedElement permissions={[AppPermission.ImportManage]}>
              {importRequest.data &&
                importRequest.data.status !== ImportStatus.Completed &&
                importRequest.data.status !== ImportStatus.Processing && (
                  <Button
                    variant='contained'
                    onClick={() =>
                      history.push(ROUTE_PATH.MANAGEMENT_IMPORTS_EDIT(importRequest.data?.id || ""))
                    }
                  >
                    Edit import
                  </Button>
                )}
            </AuthorizedElement>
          }
        />
      }
    >
      <Stack direction='column' spacing={1}>
        <FieldValue label='Import entity type'>{importRequest.data?.entityType}</FieldValue>
        <FieldValue label='Status'>{importRequest.data?.status}</FieldValue>
        <FieldValue label='Source file'>
          {importRequest.data?.attachment?.file?.originalFileName}
        </FieldValue>
      </Stack>

      {importRequest.data.result && (
        <Stack direction='column' sx={{ m: 1 }}>
          <Typography variant='h6'>Results</Typography>
          <Stack direction='column' spacing={1}>
            {importRequest.data?.result?.rows?.map((rowResult, index) => (
              <Box key={index}>
                <Card>
                  <CardContent>
                    <Stack direction='row' spacing={1} sx={{ alignItems: "center", mb: 1 }}>
                      <Typography variant='subtitle1'>
                        Row number - {rowResult.index! + 1}
                      </Typography>
                      <Chip
                        label={
                          <InlineApiEnumValue
                            type='ImportRowResultStatus'
                            value={rowResult.status}
                          />
                        }
                        variant='outlined'
                        sx={{ height: "25px", ml: 1, mb: 0 }}
                        color={
                          ((rowResult.status === ImportRowResultStatus.Created ||
                            rowResult.status === ImportRowResultStatus.Updated) &&
                            "success") ||
                          (rowResult.status === ImportRowResultStatus.Failed && "error") ||
                          "secondary"
                        }
                      />
                    </Stack>
                    <Stack direction='column' spacing={1}>
                      <Stack direction='column'>
                        {rowResult.error && (
                          <Typography variant='caption' color='error'>
                            {rowResult.error}
                          </Typography>
                        )}
                        {rowResult.fieldErrors && rowResult.fieldErrors.length !== 0 && (
                          <Grid2 container rowSpacing={0} columnSpacing={1} sx={{}}>
                            {importSpecRequest.data?.fields?.map((field, idx) => {
                              const fieldError = rowResult?.fieldErrors?.find(
                                (x) => x.field === field.name,
                              );
                              return (
                                <Grid2 key={idx} sx={{ textAlign: "center" }}>
                                  <Stack direction='row'>
                                    <Typography variant='body2'>
                                      {field.name}
                                      {field.isRequired && "*"}
                                    </Typography>
                                    {(!fieldError || !fieldError?.error) && (
                                      <AppIcon
                                        of='successFilled'
                                        color='success'
                                        fontSize='small'
                                      />
                                    )}
                                    {fieldError?.error && (
                                      <Tooltip title={fieldError.error}>
                                        <AppIcon of='error' color='error' fontSize='small' />
                                      </Tooltip>
                                    )}
                                  </Stack>
                                </Grid2>
                              );
                            })}
                          </Grid2>
                        )}
                        <Box>
                          {rowResult.accessory && <AccessoryLink entity={rowResult.accessory} />}
                          {rowResult.vehicle && <VehicleLink entity={rowResult.vehicle} />}
                        </Box>
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              </Box>
            ))}
            {/* <ImportResults importId={importId!} /> */}
          </Stack>
        </Stack>
      )}
    </ViewLayout>
  );
}
