import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function SuppliersIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <path
        d='M21.6 15.15C20.775 15.3 20.1 15.15 19.8 14.625C19.5 14.1 19.725 13.35 20.25 12.75C19.575 12.075 18.825 11.625 17.925 11.4C17.7 12.15 17.175 12.75 16.575 12.75C15.975 12.75 15.45 12.225 15.225 11.4C14.325 11.625 13.5 12.075 12.9 12.75C13.425 13.35 13.65 14.1 13.35 14.625C13.05 15.15 12.3 15.3 11.55 15.15C11.325 15.6 11.25 16.05 11.25 16.5C11.25 16.95 11.325 17.4 11.4 17.85C12.225 17.7 12.9 17.85 13.2 18.375C13.5 18.9 13.275 19.65 12.75 20.25C13.425 20.925 14.175 21.375 15.075 21.6C15.3 20.85 15.825 20.25 16.425 20.25C17.025 20.25 17.55 20.775 17.775 21.6C18.675 21.375 19.5 20.925 20.1 20.25C19.575 19.65 19.35 18.9 19.65 18.375C19.95 17.85 20.7 17.7 21.45 17.85C21.6 17.4 21.6 16.95 21.6 16.5C21.6 16.05 21.675 15.6 21.6 15.15Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M16.5 18C17.3284 18 18 17.3284 18 16.5C18 15.6716 17.3284 15 16.5 15C15.6716 15 15 15.6716 15 16.5C15 17.3284 15.6716 18 16.5 18Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M3.75 9H15.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M7.8751 6.075L6.9751 2.925C8.7001 2.025 10.8001 2.025 12.5251 2.925L11.6251 6.075'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M12.375 3.67505C13.875 4.57505 14.85 6.22505 14.85 8.10005V9.00005'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M4.6499 9.00005V8.10005C4.6499 6.22505 5.6249 4.57505 7.1249 3.67505'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M6 9V10.5C6 12.075 6.675 13.5 7.875 14.25C7.875 15.825 6.9 17.175 5.475 17.55C3.825 18 2.625 19.5 2.25 21.3C2.25 21.525 2.4 21.75 2.625 21.75H9.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
});
