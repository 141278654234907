import {
  DialogProps,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";
import { ReactNode, useState } from "react";
import { useHistory } from "react-router";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  VehicleDamageDto,
} from "@/core/api/generated";

import AuthorizedElement from "../../../Auth/AuthorizedElement";
import AppIcon from "../../../Icons/AppIcon";
import AppLink from "../../../Link/AppLink";
import MenuWithTrigger from "../../../Menu/MenuWithTrigger";
import { RepairOperationAddVehicleDamagesModal } from "../../RepairOperation/RepairOperationAddVehicleDamagesModal";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import VehicleDamageDeleteModal from "../VehicleDamageDeleteModal";
import VehicleDamageMigrateToNewVisualModelModal from "../VehicleDamageMigrateToNewVisualModelModal";
import GeneralHistoryTabContent from "./Tabs/GeneralHistoryTabContent";
import OverviewTabContent from "./Tabs/OverviewTabContent";
import VehicleTabContent from "./Tabs/VehicleTabContent";

export enum VehicleDamageViewPageTabs {
  Overview = "Overview",
  Vehicle = "Vehicle",
  GeneralHistory = "GeneralHistory",
}

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as unknown as Partial<TypographyProps<"div">> | undefined,
  title: undefined as ReactNode | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  inspector: true,
  damageDetection: true,
  contract: true,
  visualModel: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<VehicleDamageDto> {
  vehicleId: string | undefined;
  vehicleDamageId: string | undefined;
  vehicleDamage?: VehicleDamageDto;
  headerProps?: Partial<typeof defaultHeaderProps>;
  displayProps?: Partial<typeof defaultDisplayProps>;
  dialogProps?: DialogProps;
}

export type VehicleDamageViewProps = OwnProps;

export default function VehicleDamageView({
  vehicleId,
  vehicleDamageId,
  vehicleDamage,
  headerProps = defaultHeaderProps,
  displayProps = defaultDisplayProps,
  dialogProps,
}: VehicleDamageViewProps) {
  displayProps = { ...defaultDisplayProps, ...displayProps };

  const history = useHistory();

  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.VehicleDamage),
      viewVariant: displayProps.viewVariant,
    },
  });

  const [isAddToRepairOperationModalOpen, setIsAddToRepairOperationModalOpen] = useState(false);
  const [isMigrateToNewVisualModelModalOpen, setIsMigrateToNewVisualModelModalOpen] =
    useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const request = useApiRequest(
    apiClient.vehicleDamagesApi.apiV1VehicleDamagesVehicleDamageIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleDamageId: vehicleDamageId!,
    },
    {
      skip: !!vehicleDamage || !vehicleDamageId,
    },
  );
  vehicleDamage = vehicleDamage || request?.data || undefined;

  useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(undefined, EntityType.VehicleDamage, vehicleDamageId || ""),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      request.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumbs:
      (displayProps?.breadcrumbs &&
        vehicleDamage && [
          {
            idValue: vehicleDamage?.id || "",
            newTitle: vehicleDamage?.localNumber || "",
          },
          {
            idValue: vehicleDamage?.vehicle?.id || "",
            newTitle:
              vehicleDamage?.vehicle?.plateNo?.plateNo ||
              vehicleDamage?.vehicle?.identificationNumber ||
              vehicleDamage?.vehicle?.localNumber ||
              "",
          },
        ]) ||
      undefined,
  });

  return (
    <BaseEntityView
      entityType={EntityType.VehicleDamage}
      entityId={vehicleDamageId}
      entity={vehicleDamage}
      entityRequest={request}
    >
      <ViewLayout
        displayProps={displayProps}
        dialogProps={dialogProps}
        header={
          displayProps.header &&
          vehicleDamage && (
            <SimpleViewPageHeader
              title={
                <Typography
                  component='div'
                  variant='h1'
                  sx={{ display: "flex", alignItems: "center" }}
                  {...headerProps?.typographyProps}
                >
                  {headerProps?.title || (
                    <>
                      <span>Vehicle damage </span>&nbsp;
                      <AppLink
                        enabled={headerProps?.withLink || false}
                        to={ROUTE_PATH.VEHICLE_DAMAGE_VIEW({
                          vehicleId: vehicleDamage?.vehicle?.id || undefined,
                          vehicleDamageId: vehicleDamage?.id || undefined,
                        })}
                      >
                        <span>{vehicleDamage?.localNumber}</span>
                      </AppLink>
                    </>
                  )}{" "}
                  <EntityChipList entity={vehicleDamage} variant='normal' sx={{ ml: 1 }} />
                </Typography>
              }
              primaryActions={
                displayProps.actions && (
                  <AuthorizedElement permissions={[AppPermission.RepairOperationManage]}>
                    <MenuWithTrigger
                      withAuthCloseOnClick
                      trigger={
                        <IconButton sx={{ ml: "auto" }}>
                          <AppIcon of='moreVert' />
                        </IconButton>
                      }
                    >
                      <AuthorizedMenuItem
                        permissions={[AppPermission.RepairOperationManage]}
                        disabled={!vehicleDamage?.id}
                        onClick={() => {
                          setIsAddToRepairOperationModalOpen(true);
                        }}
                      >
                        <ListItemIcon>
                          <AppIcon of='add' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Add to repair operation</ListItemText>
                      </AuthorizedMenuItem>
                      <AuthorizedMenuItem
                        permissions={[AppPermission.VehicleDamageManage]}
                        disabled={!vehicleDamage?.id}
                        onClick={() => {
                          setIsMigrateToNewVisualModelModalOpen(true);
                        }}
                      >
                        <ListItemIcon>
                          <AppIcon of='edit' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Migrate to new visual model</ListItemText>
                      </AuthorizedMenuItem>
                      <AuthorizedMenuItem
                        permissions={[AppPermission.VehicleDamageManage]}
                        disabled={!vehicleDamage?.id}
                        onClick={() => {
                          setIsDeleteModalOpen(true);
                        }}
                      >
                        <ListItemIcon>
                          <AppIcon of='delete' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                      </AuthorizedMenuItem>
                    </MenuWithTrigger>
                  </AuthorizedElement>
                )
              }
            />
          )
        }
      >
        <Stack spacing={2}>
          <PageTabs
            tabIdsDefinition={VehicleDamageViewPageTabs}
            defaultTabId={VehicleDamageViewPageTabs.Overview}
            viewVariant={displayProps?.viewVariant}
            commonViewParams={commonViewParams}
            tabs={[
              { label: "Overview", value: VehicleDamageViewPageTabs.Overview, isHideable: false },
              { label: "Vehicle", value: VehicleDamageViewPageTabs.Vehicle },
              { label: "History", value: VehicleDamageViewPageTabs.GeneralHistory },
            ]}
          >
            {({ activeTabId: activeTab }) =>
              vehicleDamage && (
                <>
                  {activeTab === VehicleDamageViewPageTabs.Overview && (
                    <OverviewTabContent vehicleDamage={vehicleDamage} displayProps={displayProps} />
                  )}
                  {activeTab === VehicleDamageViewPageTabs.Vehicle && (
                    <VehicleTabContent vehicleDamage={vehicleDamage} />
                  )}
                  {activeTab === VehicleDamageViewPageTabs.GeneralHistory && (
                    <GeneralHistoryTabContent vehicleDamage={vehicleDamage} />
                  )}
                </>
              )
            }
          </PageTabs>

          {/* Add to repair operation */}
          {vehicleDamage && (
            <RepairOperationAddVehicleDamagesModal
              open={isAddToRepairOperationModalOpen}
              onClose={() => setIsAddToRepairOperationModalOpen(false)}
              damages={[vehicleDamage]}
            />
          )}

          {/* Migrate to new visual model */}
          {vehicleDamage && (
            <VehicleDamageMigrateToNewVisualModelModal
              open={isMigrateToNewVisualModelModalOpen}
              onClose={() => setIsMigrateToNewVisualModelModalOpen(false)}
              migrateProps={{
                vehicleDamage: vehicleDamage,
                onSave: (newValue) => {
                  request.replaceData(newValue);
                  setIsMigrateToNewVisualModelModalOpen(false);
                },
              }}
            />
          )}

          {/* Delete modal */}
          {vehicleDamage && (
            <VehicleDamageDeleteModal
              open={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
              entity={vehicleDamage}
              onDelete={() => {
                setIsDeleteModalOpen(false);
                request.replaceData(null);
                history.goBack();
              }}
            />
          )}
        </Stack>
      </ViewLayout>
    </BaseEntityView>
  );
}
