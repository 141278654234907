import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function EditIcon(props: SvgIconProps, ref) {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <path d='M18.075 14.1469L16.4156 13.1531C15.8062 12.8109 15.075 12.7453 14.4141 12.9703L13.4719 13.2891C13.1437 13.4016 12.8016 13.4578 12.4594 13.4578H8.6625C8.31093 13.4578 8.02499 13.7437 8.02499 14.0953C8.02499 14.2453 8.07656 14.3812 8.16093 14.4891C8.24531 14.5969 8.37187 14.6812 8.5125 14.7141L12.6797 15.7125C12.8625 15.7547 12.9984 15.9 13.0312 16.0875C13.0359 16.1156 13.0406 16.1437 13.0406 16.1719C13.0406 16.4203 12.8391 16.6359 12.5812 16.6359L7.05937 16.7156C6.57187 16.7203 6.10312 16.5234 5.76562 16.1719C4.72968 15.0797 2.50781 12.7406 2.27812 12.5156C1.98281 12.2203 1.28906 12.4172 1.58437 13.1578C1.87968 13.8984 4.60781 18.2109 5.00156 18.5109C5.4 18.8062 10.8984 20.4937 14.6672 20.1937C15.6234 20.1187 17.1 19.6219 18.0797 19.2562V14.1469H18.075Z' />
      <path d='M21.9421 13.3594H19.3781C19.0734 13.3594 18.825 13.6078 18.825 13.9125V19.5C18.825 19.8047 19.0734 20.0531 19.3781 20.0531H21.9421C22.2468 20.0531 22.4953 19.8047 22.4953 19.5V13.9078C22.4906 13.6031 22.2468 13.3594 21.9421 13.3594ZM21.0328 15.0234H20.2875C20.0812 15.0234 19.9125 14.8547 19.9125 14.6484C19.9125 14.4422 20.0812 14.2734 20.2875 14.2734H21.0328C21.239 14.2734 21.4078 14.4422 21.4078 14.6484C21.4078 14.8547 21.239 15.0234 21.0328 15.0234Z' />
      <path d='M2.7723 3.88325C2.77979 3.9805 2.83946 4.06596 2.92821 4.10627L5.87222 5.44184L6.09713 7.73634L4.22268 9.07856L1.27862 7.74315C1.18975 7.70283 1.086 7.71421 1.00783 7.77266C0.929664 7.83112 0.889926 7.92748 0.903504 8.024C1.11054 9.49891 2.04425 10.8394 3.50108 11.5C5.76454 12.5268 8.43137 11.5241 9.45798 9.26079C9.52011 9.12401 9.65642 9.03614 9.80678 9.03614L21.4207 9.03609C22.3765 9.03614 23.1515 8.26112 23.1516 7.30523C23.1514 6.82721 22.9577 6.39458 22.6445 6.08133C22.3312 5.76803 21.8986 5.57448 21.4206 5.57427L9.7134 5.57427C9.56923 5.57427 9.43686 5.49306 9.37158 5.36445C8.92528 4.48385 8.1879 3.74322 7.21895 3.30368C5.76207 2.64295 4.13848 2.82334 2.89251 3.63899C2.81094 3.69247 2.76496 3.78605 2.7723 3.88325ZM21.889 6.8367C22.1478 7.09547 22.148 7.51499 21.8892 7.77377C21.6305 8.03249 21.2109 8.03233 20.9521 7.77356C20.6936 7.51504 20.6937 7.09568 20.9524 6.83696C21.2112 6.57818 21.6305 6.57818 21.889 6.8367Z' />
    </SvgIcon>
  );
});
