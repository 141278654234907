import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import { useState } from "react";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AppIconButton from "@/common/components/Button/AppIconButton";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import InlineApiEnumValueList from "@/common/components/Enum/InlineApiEnumValueList";
import FileListView from "@/common/components/Files/FileListView";
import InfoTile from "@/common/components/Form/Display/InfoTile";
import GeneralStageHistory from "@/common/components/GeneralStageHistory";
import AppIcon from "@/common/components/Icons/AppIcon";
import BaseEditNotesModal from "@/common/components/Modals/common/BaseEditNotesModal";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import { FileItem } from "@/common/fileItem";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { AppPermission, EntityType, WashDto } from "@/core/api/generated";

import DepartmentLink from "../../../Department/DepartmentLink";
import AffiliationInfoDisplay from "../../../EntityAffiliation/AffiliationInfoDisplay";
import UpdateEntityAffiliationCascadeModal from "../../../EntityAffiliation/UpdateEntityAffiliationCascadeModal";
import GeneralByWhoDisplay from "../../../General/Display/GeneralByWhoDisplay";
import LocationLink from "../../../Location/LocationLink";
import SupplierLink from "../../../Supplier/SupplierLink";
import VehicleLink from "../../../Vehicle/VehicleLink";
import VehicleMileageInline from "../../../Vehicle/VehicleMileageInline";

interface Props {
  wash: WashDto;
  onUpdate: (newValue?: WashDto) => void;
}

export default function OverviewTabContent({ wash, onUpdate }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();
  const { hasPermissions } = useAuthorizationInfo();

  const [isUpdateAffiliationModalOpened, setIsUpdateAffiliationModalOpened] = useState(false);
  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={wash} />}
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <Stack spacing={1}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              <InfoTile label='Number' iconOf='personId' isEmpty={_.isNil(wash?.localNumber)}>
                {wash?.localNumber}
              </InfoTile>

              <InfoTile
                label='Department'
                iconOf='department'
                isEmpty={_.isNil((wash?.departmentIds || [])[0])}
              >
                <DepartmentLink entity={undefined} entityId={(wash?.departmentIds || [])[0]} />
              </InfoTile>

              <InfoTile
                label='Location'
                iconOf='location'
                isEmpty={_.isNil((wash?.locationIds || [])[0])}
              >
                <LocationLink entity={undefined} entityId={(wash?.locationIds || [])[0]} />
              </InfoTile>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              <InfoTile label='Vehicle' iconOf='vehicle' isEmpty={_.isNil(wash?.vehicle)}>
                <VehicleLink entity={wash?.vehicle} />
              </InfoTile>

              <InfoTile label='Supplier' iconOf='supplier' isEmpty={_.isNil(wash?.supplier)}>
                <SupplierLink entity={wash?.supplier} />
              </InfoTile>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              <InfoTile label='Type' iconOf='washType' isEmpty={_.isNil((wash?.types || [])[0])}>
                <InlineApiEnumValueList type='WashType' values={wash.types} />
              </InfoTile>

              <InfoTile
                label='Schedule type'
                iconOf='washScheduleType'
                isEmpty={_.isNil(wash?.scheduleType)}
              >
                <InlineApiEnumValue type='WashScheduleType' value={wash.scheduleType} />
              </InfoTile>

              <InfoTile label='Scheduled At' iconOf='date' isEmpty={_.isNil(wash?.scheduledAt)}>
                <Datetime datetime={wash?.scheduledAt} />
              </InfoTile>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              <InfoTile
                label='Responsible user'
                iconOf='inspector'
                isEmpty={_.isNil(wash?.responsibleUser)}
              >
                <GeneralByWhoDisplay who={wash.responsibleUser} />
              </InfoTile>

              <InfoTile label='Notes' iconOf='notes'>
                {wash.notes ? wash.notes : "-"}{" "}
                <BaseEditNotesModal
                  initialValues={{ notes: wash.notes }}
                  onConfirm={async (notes) => {
                    await apiClient.washesApi.apiV1WashesWashIdNotesPut({
                      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                      washId: wash.id || "",
                      generalNotesInputDto: { notes },
                    });
                    onUpdate();
                  }}
                />
              </InfoTile>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              <InfoTile label='Mileage' iconOf='gauge' isEmpty={_.isNil(wash?.mileage)}>
                <VehicleMileageInline value={wash.mileage} />
              </InfoTile>

              <InfoTile label='Fuel level' iconOf='fuel' isEmpty={_.isNil(wash?.fuelLevel)}>
                {wash?.fuelLevel}
              </InfoTile>
            </Box>
          </Stack>

          {/* Stages */}
          <Box>
            <Typography variant='h2'>Stages</Typography>
            <GeneralStageHistory
              stageEnumType='WashStage'
              stageHistory={wash.stageHistory}
              withUpdateButtons={hasPermissions([AppPermission.WashManage])}
              onUpdateStage={async (newStage) => {
                try {
                  const response = await apiClient.washesApi.apiV1WashesWashIdStagePut({
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    washId: wash.id || "",
                    generalStageUpdateDtoOfWashStage: {
                      newStage: newStage,
                    },
                  });
                  onUpdate(response.data);
                } catch (err) {
                  const validation2 = ValidationHelper.handleApiErrorResponse(err);
                  enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
                }
              }}
            />
          </Box>

          {/* Summary */}
          <Stack direction='row' justifyContent='flex-end'>
            <GeneralPriceSummaryDisplay
              sx={{ minWidth: { xxs: "100%", md: "300px" } }}
              summary={{
                currency: wash.currency,
                subTotal: wash.subTotal,
                subTotalIncDiscount: wash.subTotalIncDiscount,
                discount: wash.discount,
                tax: wash.tax,
                total: wash.total,
              }}
            />
          </Stack>

          {/* Affiliation */}
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography variant='subtitle1'>
              Affiliation{" "}
              <AppIconButton
                tooltipProps={{ title: "Edit entity affiliation" }}
                onClick={() => setIsUpdateAffiliationModalOpened(true)}
              >
                <AppIcon of='edit' fontSize='small' />
              </AppIconButton>
            </Typography>
            <AffiliationInfoDisplay
              tenantId={wash.tenantId}
              departmentIds={wash.departmentIds}
              locationIds={wash.locationIds}
              sx={{ mt: 1 }}
            />
            <UpdateEntityAffiliationCascadeModal
              open={isUpdateAffiliationModalOpened}
              onClose={() => setIsUpdateAffiliationModalOpened(false)}
              entityType={EntityType.Wash}
              entityId={wash.id}
            />
          </Stack>

          {!_.isEmpty(wash?.attachments) && (
            <Box>
              <FileListView
                files={FileItem.createManyFrom(wash?.attachments)}
                maxVisibleFiles={10}
                itemWidth={45}
              />
            </Box>
          )}
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}
