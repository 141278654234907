import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function DataConnectedIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='24'
      height='24'
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17 6H14V3C14 2.448 14.448 2 15 2H17C17.552 2 18 2.448 18 3V5C18 5.552 17.552 6 17 6ZM18 14V17C18 17.552 17.552 18 17 18H15C14.448 18 14 17.552 14 17V14H18ZM8 12H12V8H8V12ZM5 18H3C2.448 18 2 17.552 2 17V15C2 14.448 2.448 14 3 14H6V17C6 17.552 5.552 18 5 18ZM2 5V3C2 2.448 2.448 2 3 2H5C5.552 2 6 2.448 6 3V6H3C2.448 6 2 5.552 2 5ZM18 0H14C12.895 0 12 0.895 12 2V6H8V2C8 0.895 7.105 0 6 0H2C0.895 0 0 0.895 0 2V6C0 7.105 0.895 8 2 8H6V12H2C0.895 12 0 12.895 0 14V18C0 19.105 0.895 20 2 20H6C7.105 20 8 19.105 8 18V14H12V18C12 19.105 12.895 20 14 20H18C19.105 20 20 19.105 20 18V14C20 12.895 19.105 12 18 12H14V8H18C19.105 8 20 7.105 20 6V2C20 0.895 19.105 0 18 0Z'
        fill='#363636'
      />
    </SvgIcon>
  );
});
