import { Box, IconButton, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useMemo, useState } from "react";

import { DatetimeHelper } from "@/common/helpers/datetime";
import {
  ChatEventCategory,
  ChatEventDto,
  ChatEventType,
  ChatParticipantDto,
} from "@/core/api/generated";

import AppIcon from "../../Icons/AppIcon";
import ChatHistoryDivider from "../ChatHistory/ChatHistoryDivider";
import UpdateChatUserSettingsModal from "../ChatUserSettings/UpdateChatUserSettingsModal";
import ChatEventsModal from "./ChatEventsModal";

const StyledEventContainer = styled(Box)(({ theme }) => ({
  "& :hover": {
    // color: "red",
    ".Nexus-EventControls-root": {
      opacity: 1,
      pointerEvents: "auto",
    },
  },
}));

const StyledEventControls = styled(Box)(({ theme }) => ({
  opacity: 0,
  pointerEvents: "none",
}));

type DisplayVariant = "default" | "divider";

interface Props {
  event: ChatEventDto;
  participant?: ChatParticipantDto | null;
  variant?: DisplayVariant;
  variantForCategories?: {
    variant: DisplayVariant;
    categories: Partial<Record<ChatEventCategory, boolean>>;
  };
  variantForTypes?: {
    variant: DisplayVariant;
    types: Partial<Record<ChatEventType, boolean>>;
  };
}

export default function ChatEvent({
  event,
  participant,
  variant = "default",
  variantForCategories,
  variantForTypes,
}: Props) {
  const [isChatEventsModalOpen, setIsChatEventsModalOpen] = useState(false);
  const [isChatUserSettingsModalOpen, setIsChatUserSettingsModalOpen] = useState(false);

  const calcedVariant: DisplayVariant = useMemo(() => {
    if (variantForTypes && variantForTypes.types[event.type!]) {
      return variantForTypes.variant;
    } else if (variantForCategories && variantForCategories.categories[event.category!]) {
      return variantForCategories.variant;
    } else {
      return variant;
    }
  }, [variant, variantForTypes, variantForCategories]);

  return (
    <StyledEventContainer>
      <Stack direction='column' spacing={0} sx={{ alignItems: "center" }}>
        <Stack direction='row' spacing={2} sx={{ alignItems: "flex-start" }}>
          <Box sx={{ cursor: "pointer" }} onClick={() => setIsChatEventsModalOpen(true)}>
            {calcedVariant === "default" && (
              <Typography component='span' variant='caption' color='text.secondary'>
                {event.body},{" "}
                {DatetimeHelper.humanizeDateRangeDurationFromNow(event.createdAt, {
                  isSuffix: true,
                })}
              </Typography>
            )}
            {calcedVariant === "divider" && (
              <ChatHistoryDivider>
                {event.body},{" "}
                {DatetimeHelper.humanizeDateRangeDurationFromNow(event.createdAt, {
                  isSuffix: true,
                })}
              </ChatHistoryDivider>
            )}
          </Box>

          {/* Controls */}
          <StyledEventControls className='Nexus-EventControls-root'>
            <IconButton
              sx={{ p: 0, fontSize: "0.8rem" }}
              onClick={() => setIsChatUserSettingsModalOpen(true)}
            >
              <AppIcon of='settings' />
            </IconButton>
          </StyledEventControls>
        </Stack>
      </Stack>

      {/* Chat event history */}
      {isChatEventsModalOpen && (
        <ChatEventsModal
          open={isChatEventsModalOpen}
          onClose={() => setIsChatEventsModalOpen(false)}
          chatId={event.chatId!}
        />
      )}

      {/* Update chat user settings */}
      {isChatUserSettingsModalOpen && (
        <UpdateChatUserSettingsModal
          open={isChatUserSettingsModalOpen}
          onClose={() => setIsChatUserSettingsModalOpen(false)}
          updateChatUserSettingsProps={{
            chatId: event.chatId!,
            onSave: () => setIsChatUserSettingsModalOpen(false),
          }}
        />
      )}
    </StyledEventContainer>
  );
}
