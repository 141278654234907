import {
  Autocomplete,
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import { apiClient } from "@/core/api/ApiClient";
import {
  AdminUserDto,
  AdminUserGetPaginatedDto,
  PaginationDtoOfUserDto,
} from "@/core/api/generated";

interface UserAutocompleteProps {
  userId?: string | null;
  user?: AdminUserDto;
  searchFilters?: Pick<AdminUserGetPaginatedDto, "tenantId" | "notForTenantId">;
  isPreload?: boolean;
  disabled?: boolean;
  required?: boolean;
  size?: "small" | "medium";
  fullWidth?: boolean;
  onChange?: (user?: AdminUserDto) => void;
  error?: boolean;
}

export default function AdminUserAutocomplete({
  userId,
  user,
  searchFilters,
  isPreload = true,
  disabled,
  required,
  size,
  fullWidth,
  onChange,
  error,
  ...otherProps
}: UserAutocompleteProps) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly AdminUserDto[]>([]);
  const [selectedOption, setSelectedOption] = useState<AdminUserDto | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [paginatedUsers, setPaginatedUsers] = useState<PaginationDtoOfUserDto | undefined>(
    undefined,
  );
  const [limit] = useState(25);
  const [inputValue, setInputValue] = useState("");

  const apiParams: Parameters<typeof apiClient.adminUsersApi.apiV1AdminUsersGetPost> = [
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      adminUserGetPaginatedDto: {
        limit,
        search: inputValue,
        tenantId: searchFilters?.tenantId || undefined,
      },
    },
  ];

  const searchUsers = useCallback(
    async (...args: Parameters<typeof apiClient.adminUsersApi.apiV1AdminUsersGetPost>) => {
      setIsLoading(true);
      try {
        const response = await apiClient.adminUsersApi.apiV1AdminUsersGetPost(...args);
        setPaginatedUsers(response.data);
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );
  const searchUsersThrottle = useCallback(
    _.throttle(searchUsers, 1000, { leading: true, trailing: false }),
    [searchUsers],
  );
  const searchUsersDebounce = useCallback(
    _.debounce(searchUsers, 500, { leading: false, trailing: true }),
    [searchUsers],
  );

  useEffect(() => {
    if (!isPreload && !open) return;
    searchUsersThrottle(...apiParams);
  }, [open, limit, searchFilters?.tenantId]);

  useEffect(() => {
    if (userId && !options.some((x) => x.id === userId)) {
      searchUsersThrottle(...apiParams);
    }
  }, [userId, options]);

  useEffect(() => {
    if (selectedOption) return;
    if (!isPreload && !open) return;
    searchUsersDebounce(...apiParams);
  }, [open, inputValue, selectedOption]);

  useEffect(() => {
    const newOptions = [
      ...(selectedOption ? [selectedOption] : []),
      ...(paginatedUsers?.items || []),
    ];
    setOptions(_.uniqBy(newOptions, (x) => x.id));

    if (userId && selectedOption?.id !== userId) {
      setSelectedOption(newOptions.find((x) => x.id === userId) || null);
    }
  }, [paginatedUsers, userId, selectedOption]);

  useEffect(() => {
    if (!user && !userId) {
      setSelectedOption(null);
    } else if (user && selectedOption?.id !== user.id) {
      setOptions(_.uniqBy([user, ...options], (x) => x.id));
      setSelectedOption(user);
    } else if (userId && selectedOption?.id !== userId) {
      setSelectedOption(options.find((x) => x.id === userId) || null);
    }
  }, [userId, user, selectedOption, options]);

  return (
    <Autocomplete
      sx={{ minWidth: 200, flex: 1 }}
      disabled={disabled}
      size={size}
      fullWidth={fullWidth}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.personName!.name!}
      filterOptions={(x) => x} // disable the built-in filtering
      options={options}
      loading={isLoading}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={selectedOption}
      onChange={(event, newValue) => {
        setSelectedOption(newValue);
        onChange && onChange(newValue || undefined);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label='User'
          placeholder='Search...'
          fullWidth
          required={required}
          error={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <ListItem {...props} key={option.id}>
            <ListItemIcon>
              <AppIcon sx={{ color: (t) => t.palette.text.main }} of='users' />
            </ListItemIcon>
            <ListItemText
              primary={option.personName!.name!}
              secondary={
                <Typography component='div' variant='body2'>
                  {option.email} / {option.phoneNumber}
                </Typography>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}
