import { AnyAction, Middleware } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { AppRootStateType } from "@/store";
import * as chatActivitySlice from "@/store/communication/chatActivityOverviewSlice";
import * as chatHistorySlice from "@/store/communication/chatHistorySlice";
import * as chatMessagesSlice from "@/store/communication/chatMessagesSlice";

import { GetTypedPayload } from "../ts";

export const communicationMiddleware: Middleware<
  any,
  AppRootStateType,
  ThunkDispatch<any, any, AnyAction>
> =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case chatMessagesSlice.newChatMessageReceived.type: {
        const payload = GetTypedPayload(action, chatMessagesSlice.newChatMessageReceived);

        await dispatch(
          chatActivitySlice.trackMyChatActivity({
            chatId: payload.chatId,
            message: payload.message,
          }),
        );

        break;
      }
      case chatHistorySlice.newChatEventReceived.type: {
        const payload = GetTypedPayload(action, chatHistorySlice.newChatEventReceived);

        await dispatch(
          chatActivitySlice.trackMyChatActivity({
            chatId: payload.chatId,
            event: payload.event,
          }),
        );

        break;
      }

      default:
        break;
    }

    return next(action);
  };
