import { useParams } from "react-router-dom";

import VisualInspectionView from "@/common/components/Entity/VisualInspection/View/VisualInspectionView";

export default function VisualInspectionViewPage() {
  const { visualInspectionId } = useParams<{ visualInspectionId?: string }>();

  return (
    <>
      <VisualInspectionView
        visualInspectionId={visualInspectionId}
        visualInspection={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
