import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { PaginationDtoOfUserDto, UserDto } from "@/core/api/generated";

import { AppThunk } from "../..";
import { apiClient } from "../../../core/api/ApiClient";

export interface UsersState {
  loading: {
    getPaginated?: boolean;
    get?: boolean;
    update?: boolean;
    getMany?: boolean;
  };
  paginatedUsers?: PaginationDtoOfUserDto;
  user?: UserDto;
  users?: UserDto[];
}

const initialState: UsersState = {
  loading: {},
  paginatedUsers: undefined,
  user: undefined,
  users: undefined,
};

export const invoicesSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<UsersState["loading"]>) => {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
    _userUpdated: (state, action: PayloadAction<UserDto>) => {
      if (state.paginatedUsers) {
        const index = state.paginatedUsers.items!.findIndex((x) => x.id === action.payload.id);
        if (index !== -1) {
          state.paginatedUsers.items!.splice(index, 1, action.payload);
        }
      }
      if (state.user?.id === action.payload.id) {
        state.user = action.payload;
      }
    },
    resetUser: (state, action: PayloadAction<undefined>) => {
      state.user = undefined;
    },
  },
});

export const { setLoading, _userUpdated, resetUser } = invoicesSlice.actions;

export const usersReducer = invoicesSlice.reducer;

export const updateUserInfo =
  (
    ...args: Parameters<typeof apiClient.usersApi.apiV1UsersUserIdPut>
  ): AppThunk<Promise<UserDto>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        update: true,
      }),
    );
    try {
      const response = await apiClient.usersApi.apiV1UsersUserIdPut(...args);
      await dispatch(_userUpdated(response.data));
      return response.data;
    } finally {
      dispatch(
        setLoading({
          update: false,
        }),
      );
    }
  };
