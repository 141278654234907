import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppNavIcon from "@/common/components/Icons/AppNavIcon";
import AppNavItem from "@/common/components/Nav/AppNavItem";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useTenantToTenantCounters } from "@/common/hooks/entity/tenantToTenant/useTenantToTenantCounters";
import { AppPermission } from "@/core/api/generated";

import { AppNavToNavItemParams } from "../Nav";

interface Props {
  navParams: AppNavToNavItemParams;
}

export default function TenantToTenantItemGroup({ navParams }: Props) {
  const counters = useTenantToTenantCounters();

  return (
    <AuthorizedElement
      permissions={[AppPermission.FleetAppAccess]}
      permissionsAny={[
        AppPermission.TenantConnectionRead,
        AppPermission.TenantRequestRead,
        AppPermission.DataGrantRead,
      ]}
    >
      <AppNavItem
        type='itemGroup'
        itemGroup={{
          startIcon: (fill) => <AppNavIcon sx={{ fill: fill }} of='tenantToTenant' />,
          content: "Company-to-Company",
          to: undefined,
          onClick: navParams.onItemClick,
          counters: [
            {
              title: "Company connection requests: Incoming & Pending",
              counter: counters?.connectionRequests?.incoming?.pendingCount,
            },
            {
              title: "Company requests: Incoming & Pending",
              counter: counters?.requests?.incoming?.pendingCount,
            },
            {
              title: "Company requests: Outcoming & Declined",
              counter: counters?.requests?.outcoming?.declinedCount,
            },
            {
              title: "Company requests: Outcoming & Reporting",
              counter: counters?.requests?.outcoming?.reportingCount,
            },
          ],
          items: (
            <>
              <AuthorizedElement permissions={[AppPermission.TenantConnectionRead]}>
                <AppNavItem
                  type='item'
                  item={{
                    isFromGroup: true,
                    startIcon: <AppNavIcon of='tenantConnection' />,
                    content: "Connections",
                    to: ROUTE_PATH.TENANT_CONNECTIONS_INDEX,
                    onClick: navParams.onItemClick,
                    counters: [
                      {
                        title: "Company connection requests: Incoming & Pending",
                        counter: counters?.connectionRequests?.incoming?.pendingCount,
                      },
                    ],
                  }}
                />
              </AuthorizedElement>

              <AuthorizedElement permissions={[AppPermission.TenantRequestRead]}>
                <AppNavItem
                  type='item'
                  item={{
                    isFromGroup: true,
                    startIcon: <AppNavIcon of='tenantRequest' />,
                    content: "Requests",
                    to: ROUTE_PATH.TENANT_REQUESTS,
                    onClick: navParams.onItemClick,
                    counters: [
                      {
                        title: "Company requests: Incoming & Pending",
                        counter: counters?.requests?.incoming?.pendingCount,
                      },
                      {
                        title: "Company requests: Outcoming & Declined",
                        counter: counters?.requests?.outcoming?.declinedCount,
                      },
                      {
                        title: "Company requests: Outcoming & Reporting",
                        counter: counters?.requests?.outcoming?.reportingCount,
                      },
                    ],
                  }}
                />
              </AuthorizedElement>

              <AuthorizedElement permissions={[AppPermission.DataGrantRead]}>
                <AppNavItem
                  type='item'
                  item={{
                    isFromGroup: true,
                    startIcon: <AppNavIcon of='dataGrant' />,
                    content: "Data grants",
                    to: ROUTE_PATH.DATA_GRANTS(),
                    onClick: navParams.onItemClick,
                  }}
                />
              </AuthorizedElement>
            </>
          ),
        }}
      />
    </AuthorizedElement>
  );
}
