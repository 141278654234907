import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { FileUploadSpecDto } from "../../core/api/generated";
import { apiClient } from "./../../core/api/ApiClient";
import { AppThunk } from "./../index";

interface FileState {
  loading: {
    getFileUploadSpec?: boolean;
  };
  fileUploadSpec?: FileUploadSpecDto;
}

const initialState: FileState = {
  loading: {},
  fileUploadSpec: undefined,
};

export const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<FileState["loading"]>) => {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
    _fileUploadSpecFetched: (state, action: PayloadAction<FileUploadSpecDto>) => {
      state.fileUploadSpec = action.payload;
    },
  },
});

export const { setLoading, _fileUploadSpecFetched } = filesSlice.actions;

export const filesReducer = filesSlice.reducer;

export const getFileUploadSpec =
  (): AppThunk<Promise<Nil<FileUploadSpecDto>>> => async (dispatch, getState) => {
    // enure fetched once
    const isLoading = getState().files.loading.getFileUploadSpec;
    const spec = getState().files.fileUploadSpec;
    if (isLoading || spec) {
      return spec;
    }

    dispatch(
      setLoading({
        getFileUploadSpec: true,
      }),
    );
    try {
      const response = await apiClient.filesApi.apiV1FilesUploadSpecGet({});
      await dispatch(_fileUploadSpecFetched(response.data));
      return response.data;
    } finally {
      dispatch(
        setLoading({
          getFileUploadSpec: false,
        }),
      );
    }
  };
