import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function SortIcon(props: SvgIconProps, ref) {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <path d='M9.00001 20L9.00001 7L10.2 8.6C10.2788 8.70506 10.3775 8.79357 10.4905 8.86047C10.6035 8.92738 10.7286 8.97138 10.8586 8.98995C10.9886 9.00852 11.121 9.00131 11.2482 8.96871C11.3754 8.93612 11.4949 8.87879 11.6 8.8C11.7242 8.70685 11.825 8.58607 11.8944 8.44721C11.9639 8.30836 12 8.15524 12 8C12 7.78363 11.9298 7.57309 11.8 7.4L8.80001 3.4C8.70668 3.27769 8.5864 3.17857 8.44851 3.11034C8.31062 3.0421 8.15885 3.00661 8.00501 3.00661C7.85116 3.00661 7.69939 3.0421 7.5615 3.11034C7.42361 3.17857 7.30333 3.27769 7.21001 3.4L4.21001 7.26C4.0469 7.46952 3.9737 7.73525 4.00652 7.99874C4.03934 8.26223 4.17548 8.50189 4.38501 8.665C4.59453 8.82811 4.86026 8.9013 5.12375 8.86849C5.38724 8.83567 5.6269 8.69952 5.79001 8.49L7.00001 6.92L7.00001 20C7.00001 20.2652 7.10536 20.5196 7.2929 20.7071C7.48044 20.8946 7.73479 21 8.00001 21C8.26522 21 8.51958 20.8946 8.70711 20.7071C8.89465 20.5196 9.00001 20.2652 9.00001 20Z' />
      <path d='M16 4.00002L16 17L14.8 15.4C14.6409 15.1878 14.404 15.0476 14.1414 15.0101C13.8789 14.9726 13.6122 15.0409 13.4 15.2C13.1878 15.3591 13.0476 15.596 13.0101 15.8586C12.9725 16.1211 13.0409 16.3878 13.2 16.6L16.2 20.6C16.2933 20.7223 16.4136 20.8214 16.5515 20.8897C16.6894 20.9579 16.8412 20.9934 16.995 20.9934C17.1488 20.9934 17.3006 20.9579 17.4385 20.8897C17.5764 20.8214 17.6967 20.7223 17.79 20.6L20.79 16.74C20.9255 16.5655 20.9993 16.351 21 16.13C20.9996 15.9773 20.9643 15.8267 20.8967 15.6898C20.8291 15.5528 20.731 15.4332 20.61 15.34C20.4015 15.1776 20.1373 15.1044 19.8749 15.1362C19.6126 15.1681 19.3735 15.3025 19.21 15.51L18 17.08L18 4.00002C18 3.7348 17.8946 3.48045 17.7071 3.29291C17.5196 3.10537 17.2652 3.00002 17 3.00002C16.7348 3.00002 16.4804 3.10537 16.2929 3.29291C16.1054 3.48045 16 3.7348 16 4.00002Z' />
    </SvgIcon>
  );
});
