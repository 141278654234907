import { FormikHelpers } from "formik";
import _ from "lodash";
import * as Yup from "yup";

import { ReactHelper } from "../helpers/react";
import {
  BaseFormikValues,
  BaseFormikValuesKey,
  ValidationErrors,
  generalErrorKeys,
} from "../ts/error";
import { ValidationInfo } from "./validationInfo";

export class ValidationHelper {
  public static get defaultValidationInfo(): ValidationInfo {
    return new ValidationInfo();
  }

  /** Do client-side validation. */
  public validate(schema: Yup.ObjectSchema<any>, obj: any): ValidationInfo {
    const validation = new ValidationInfo();
    validation.validate(schema, obj);
    return validation;
  }

  /** Handles validation response from API and returns info needed to render errors on the UI. */
  public static handleApiErrorResponse(err: any): ValidationInfo {
    const validation = new ValidationInfo();
    validation.handleApiErrorResponse(err);
    return validation;
  }

  /** Handles validation response from API and sets Formik errors. */
  public static handleApiErrorResponseFormik<
    FormValues extends BaseFormikValues = BaseFormikValues,
  >(err: any, setFieldError: FormikHelpers<FormValues>["setFieldError"]): ValidationInfo {
    const validation = this.handleApiErrorResponse(err);

    if (validation.hasErrors) {
      Object.keys(validation.errors).forEach((key) => {
        setFieldError(key, validation.getFieldError(key));
      });

      generalErrorKeys.forEach((field) => {
        setFieldError(field, validation.generalError);
      });

      // save ValidationInfo for later use.
      setFieldError(BaseFormikValuesKey.validationInfo, validation as unknown as string); // // intentionally cast to string to bypass type errors.
    }

    return validation;
  }

  /** Returns ValidationInfo previously saved to ValidationErrors. */
  public static getValidationInfoFromValidationErrors(
    errors: ValidationErrors<BaseFormikValues> | null | undefined,
  ): ValidationInfo | undefined {
    const validationInfo = errors?.validationInfo as unknown as ValidationInfo;
    return validationInfo &&
      (validationInfo instanceof ValidationInfo ||
        (validationInfo as ValidationInfo).isValidationInfo)
      ? validationInfo
      : undefined;
  }

  /** Checks whether provided value is valid errors (obj, string, etc).  */
  public static isValidErrors(errors: any): boolean {
    return (
      !ReactHelper.isElement(errors) &&
      (_.isString(errors) || _.isArray(errors) || _.isObject(errors))
    );
  }

  /** For errors object always returns all errors as string. */
  public static getGeneralErrorsAsString(errors: any): string {
    if (_.isEmpty(errors)) {
      return "";
    }

    const validationInfo = this.getValidationInfoFromValidationErrors(errors);
    return validationInfo
      ? validationInfo.generalError
      : _.uniq(generalErrorKeys.map((field) => errors[field]!).filter((x) => !_.isEmpty(x))).join(
          ", ",
        );
  }

  /** For errors object always returns all errors as string. */
  public static getErrorsAsString(
    errors: any,
    options: { isIncludeNested?: boolean } = { isIncludeNested: true },
  ): string {
    if (_.isNil(errors)) {
      return "";
    }
    if (!this.isValidErrors(errors)) {
      return "";
    }
    if (_.isString(errors)) {
      return errors;
    }
    if (_.isArray(errors)) {
      return errors.map((x) => this.getErrorsAsString(x)).join(", ");
    }
    if (_.isObject(errors) && options?.isIncludeNested) {
      return Object.values(errors)
        .map((x) => this.getErrorsAsString(x))
        .join(", ");
    }
    return "";
  }

  /** For Formik errors object always returns all errors as string. */
  public static getFormikErrorsAsString(
    errors: any,
    options: { isIncludeNested?: boolean } = { isIncludeNested: true },
  ): string {
    return this.getErrorsAsString(errors, options);
  }
}
