import { Box, Chip, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { StringHelper } from "@/common/helpers/string";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { DamageTypeDto, DamageTypeSearchPaginatedDto } from "@/core/api/generated";

export type DamageTypeAutocompleteOption = BaseAutocompleteOption<DamageTypeDto>;

const generalGroupName = "General";
const isProDamageTypeGroup = (groupName: string) => groupName !== generalGroupName;

export const damageTypeToOption = (data: DamageTypeDto): DamageTypeAutocompleteOption => ({
  id: data.id!,
  title: StringHelper.joinIntoString([data.name], ", ", {
    skipEmpty: true,
  }),
  optionType: AutocompleteOptionType.Normal,
  groupBy: data.isCategoryRoot ? generalGroupName : data.category,
  data: data,
});

export interface DamageTypeAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<DamageTypeDto> {
  searchFilters?: Partial<Omit<DamageTypeSearchPaginatedDto, "search" | "includeIds">>;
}

export default function DamageTypeAutocompleteV2({
  searchFilters,

  isPreload = true,
  ...otherProps
}: DamageTypeAutocompleteProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={damageTypeToOption}
      sortBy={(option) => option.groupBy || ""}
      sortOrder='asc'
      groupBy={(option) => option.groupBy || ""}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.damageTypesApi.apiV1ReferenceDataDamageTypesSearchPost,
        limit: 25,
        parameters: {
          damageTypeSearchPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          damageTypeSearchPaginatedDto: {
            ...params.damageTypeSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Damage type'
      placeholder='Search...'
      renderGroupTitle={(params) => (
        <Box {...params.props}>
          <span>{params.group}</span>
          {isProDamageTypeGroup(params.group) && (
            <Chip size='small' color='primary' variant='outlined' label='Pro' sx={{ ml: 1 }} />
          )}
        </Box>
      )}
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='damageType' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant={
                    option.optionType === AutocompleteOptionType.DynamicCreateNew
                      ? "subtitle1"
                      : "body1"
                  }
                  component='div'
                >
                  {option.title}
                </Typography>
              }
              secondary={
                option.data && (
                  <Typography component='div' variant='body2' color='text'>
                    {option.data?.description}
                  </Typography>
                )
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}
