import { Alert, Box, Stack, TextField } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import { useAuth0User } from "@/common/hooks/auth/useAuth0User";
import { useTenantProfile } from "@/common/hooks/entity/tenant/useTenantProfile";
import { ProfileDto } from "@/core/api/generated";

interface Props {
  profile: ProfileDto;
}

export default function DevInfoTabContent({ profile }: Props) {
  const tenantProfile = useTenantProfile();
  const auth0User = useAuth0User();

  return (
    <PageTabContent>
      <Box sx={{ mb: 2 }}>
        <PageTabHeader title='Dev Info' />
      </Box>
      <Alert severity='warning' sx={{ mb: 2 }}>
        This info is only for development and not displayed on production!
      </Alert>

      <Stack direction='column' spacing={0}>
        <TextField
          disabled
          fullWidth
          label='User id'
          margin='dense'
          type='text'
          value={profile?.id || ""}
          variant='outlined'
        />
        <TextField
          disabled
          fullWidth
          label='Auth0 user id'
          margin='dense'
          type='text'
          value={auth0User?.sub || ""}
          variant='outlined'
        />
        <TextField
          disabled
          fullWidth
          label='Tenant id'
          margin='dense'
          type='text'
          value={tenantProfile?.id || ""}
          variant='outlined'
        />
        <TextField
          disabled
          fullWidth
          label='Tenant identifier'
          margin='dense'
          type='text'
          value={tenantProfile?.identifier || ""}
          variant='outlined'
        />
      </Stack>
    </PageTabContent>
  );
}
