import { Button, Divider, LinearProgress, Stack, Typography } from "@mui/material";
import { Formik } from "formik";
import _ from "lodash";
import * as Yup from "yup";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import FoldableBlock from "@/common/components/Display/FoldableBlock";
import TenantEmailSettingsInput from "@/common/components/Entity/Tenant/EmailSettings/TenantEmailSettingsInput";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import FormActions from "@/common/components/Form/FormActions";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  EmailProviderType,
  NotificationChannel,
  TenantEmailSettingsInputDto,
  TenantSettingsDto,
} from "@/core/api/generated";

import NotificationSendTest from "../NotificationSendTest";

interface Props {
  tenantSettings: TenantSettingsDto;
}

export default function EmailSettingsTabContent({}: Props) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();

  const tenantEmailSettingsRequest = useApiRequest(
    apiClient.tenantProfileApi.apiV1TenantProfileSettingsEmailGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
  );
  const tenantEmailSettings = tenantEmailSettingsRequest?.data;

  return (
    <PageTabContent>
      {tenantEmailSettingsRequest.isLoading && <LinearProgress sx={{ mb: 2 }} />}

      <Stack spacing={2}>
        <Formik<TenantEmailSettingsInputDto & BaseFormikValues>
          enableReinitialize
          initialValues={{
            ...tenantEmailSettings,
            providerType: tenantEmailSettings?.providerType || EmailProviderType.Default,
            submit: "",
          }}
          validationSchema={Yup.object().shape({
            // name: Yup.string().required("This field is required"),
          })}
          onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
            try {
              await apiClient.tenantProfileApi.apiV1TenantProfileSettingsEmailPut({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                tenantEmailSettingsInputDto: {
                  ..._.omit(values, "submit"),
                },
              });
              enqueueSnackbar("Settings updated.", { variant: "success" });

              if (mounted.current) {
                setStatus({ success: true });
              }
            } catch (err) {
              if (mounted.current) {
                ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
                setStatus({ success: false });
              }
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {(formikProps) => {
            const { errors, handleSubmit, isSubmitting } = formikProps;

            return (
              <Stack component='form' spacing={1} noValidate onSubmit={handleSubmit}>
                <Stack direction='column' spacing={2}>
                  <TenantEmailSettingsInput formikProps={formikProps} />
                </Stack>

                <GeneralValidationError errors={errors} />

                <FormActions>
                  <Button
                    color='primary'
                    loading={isSubmitting}
                    fullWidth
                    type='submit'
                    variant='contained'
                  >
                    Save
                  </Button>
                </FormActions>
              </Stack>
            );
          }}
        </Formik>

        <Divider />

        <FoldableBlock
          defaultIsFolded
          trigger={{
            label: <Typography variant='h6'>Send test email</Typography>,
          }}
        >
          <NotificationSendTest channel={NotificationChannel.Email} disableChannelEdit />
        </FoldableBlock>
      </Stack>
    </PageTabContent>
  );
}
