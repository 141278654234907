import { ListItemIcon, ListItemText, Stack } from "@mui/material";
import { ReactNode } from "react";

import { ROUTE_PATH } from "@/common/constants/routing";
import { GeneralHistoryHelper } from "@/common/helpers/entity/generalHistory";
import { AppPermission, GeneralHistoryDto } from "@/core/api/generated";

import AuthorizedMenuItem from "../../Auth/AuthorizedMenuItem";
import EntityMenu from "../../EntityMenu/EntityMenu";
import AppIcon from "../../Icons/AppIcon";
import AppLink from "../../Link/AppLink";
import GeneralHistoryCreateUpdateModal from "./GeneralHistoryCreateUpdateModal";
import GeneralHistoryDeleteModal from "./GeneralHistoryDeleteModal";
import GeneralHistoryViewModal from "./View/GeneralHistoryViewModal";

enum ActionFlags {
  View = "View",
  Edit = "Edit",
  Delete = "Delete",
}

const defaultDisplayProps = {
  actions: {
    view: true,
    viewInNewTab: true,
    edit: true,
    delete: true,
  },
};

interface TriggersProps {
  entity: GeneralHistoryDto | null | undefined;
  definition: typeof ActionFlags;
  handleClose: () => void;
  startAction: (props: ActionFlags) => void;
}

interface Props {
  entity: GeneralHistoryDto | null | undefined;
  disabled?: boolean;
  displayProps?: Partial<typeof defaultDisplayProps>;
  triggers?: (props: TriggersProps) => ReactNode;
  onUpdated: (newValue: GeneralHistoryDto) => void;
  onDeleted: () => void;
}

export default function GeneralHistoryMenu({
  entity,
  disabled,
  triggers,
  displayProps = defaultDisplayProps,
  onUpdated,
  onDeleted,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const canView = true;
  const canEdit = GeneralHistoryHelper.canUpdate(entity);
  const canDelete = GeneralHistoryHelper.canDelete(entity);
  const isMenuEnabled = canView || canEdit || canDelete;

  return (
    <EntityMenu
      isEnabled={isMenuEnabled}
      definition={ActionFlags}
      entity={entity}
      disabled={disabled}
      actionTriggers={({ handleClose, startAction }) => (
        <>
          {triggers &&
            triggers({
              entity,
              definition: ActionFlags,
              handleClose,
              startAction,
            })}

          {displayProps?.actions?.view && (
            <AuthorizedMenuItem
              permissions={[AppPermission.FleetAppAccess, AppPermission.GeneralHistoryRead]}
              onClick={() => {
                startAction(ActionFlags.View);
                handleClose();
              }}
            >
              <ListItemIcon>
                <AppIcon of='view' fontSize='small' />
              </ListItemIcon>
              <ListItemText>View</ListItemText>
            </AuthorizedMenuItem>
          )}

          {displayProps?.actions?.viewInNewTab && (
            <AuthorizedMenuItem
              permissions={[AppPermission.FleetAppAccess, AppPermission.GeneralHistoryRead]}
              component={AppLink}
              to={ROUTE_PATH.GENERAL_HISTORY_VIEW(entity?.id)}
              target='_blank'
            >
              <ListItemIcon>
                <AppIcon of='openInNew' fontSize='small' />
              </ListItemIcon>
              <ListItemText>View in new tab</ListItemText>
            </AuthorizedMenuItem>
          )}

          {displayProps?.actions?.edit && (
            <AuthorizedMenuItem
              permissions={[AppPermission.FleetAppAccess, AppPermission.GeneralHistoryManage]}
              disabled={!canEdit}
              onClick={() => {
                startAction(ActionFlags.Edit);
                handleClose();
              }}
            >
              <ListItemIcon>
                <AppIcon of='edit' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </AuthorizedMenuItem>
          )}

          {displayProps?.actions?.delete && (
            <AuthorizedMenuItem
              permissions={[AppPermission.FleetAppAccess, AppPermission.GeneralHistoryManage]}
              disabled={!canDelete}
              onClick={() => {
                startAction(ActionFlags.Delete);
                handleClose();
              }}
            >
              <ListItemIcon>
                <AppIcon of='delete' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </AuthorizedMenuItem>
          )}
        </>
      )}
      actionHandlers={({ currentAction, cancelAction, completeAction }) => (
        <Stack>
          {/* View */}
          {entity && (
            <GeneralHistoryViewModal
              open={currentAction === ActionFlags.View}
              onClose={() => cancelAction()}
              viewProps={{
                generalHistoryId: entity.id,
                generalHistory: undefined,
                onUpdated: (newValue) => {
                  onUpdated(newValue);
                  completeAction();
                },
                onDeleted: () => {
                  onDeleted();
                  completeAction();
                },
              }}
            />
          )}

          {/* Edit */}
          {entity && (
            <GeneralHistoryCreateUpdateModal
              open={currentAction === ActionFlags.Edit}
              onClose={() => cancelAction()}
              createUpdateProps={{
                generalHistoryId: entity.id,
                onSave: (newValue) => {
                  onUpdated(newValue);
                  completeAction();
                },
              }}
            />
          )}

          {/* Delete */}
          {entity && (
            <GeneralHistoryDeleteModal
              entity={entity}
              open={currentAction === ActionFlags.Delete}
              onClose={() => cancelAction()}
              onDelete={() => {
                onDeleted();
                completeAction();
              }}
            />
          )}
        </Stack>
      )}
    />
  );
}
