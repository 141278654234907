import { Button, FormControl, FormHelperText, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { Formik } from "formik";
import moment, { Moment } from "moment";
import * as Yup from "yup";

import { DATETIME_FORMATS } from "@/common/constants/common";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  IntegrationApiClientDto,
  IntegrationApiClientKeyDto,
  IntegrationApiClientKeyInputDto,
} from "@/core/api/generated";

import GeneralValidationError from "../../Error/GeneralValidationError";
import FormActions from "../../Form/FormActions";

export interface IntegrationApiClientCreateUpdateKeyOwnProps {
  integrationApiClient: IntegrationApiClientDto;
  clientKey?: IntegrationApiClientKeyDto;
  onCreate?: (newValue: IntegrationApiClientDto) => void;
  onUpdate?: (newValue: IntegrationApiClientDto) => void;
  onSave?: (newValue: IntegrationApiClientDto) => void;
}

export type IntegrationApiClientCreateUpdateKeyProps = IntegrationApiClientCreateUpdateKeyOwnProps;

export default function IntegrationApiClientCreateUpdateKey({
  integrationApiClient,
  clientKey,
  onCreate,
  onUpdate,
  onSave,
}: IntegrationApiClientCreateUpdateKeyProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const isCreate = !clientKey?.id;

  return (
    <Formik<IntegrationApiClientKeyInputDto & BaseFormikValues>
      enableReinitialize={!isCreate}
      initialValues={{
        name: clientKey?.name || undefined,
        expiresAt: clientKey?.expiresAt || undefined,
        submit: "",
      }}
      validationSchema={Yup.object().shape({
        // vehicleId: Yup.string().required("Vehicle is required"),
      })}
      onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
        try {
          if (isCreate) {
            const response =
              await apiClient.integrationApiClientsApi.apiV1IntegrationApiClientsIntegrationApiClientIdKeysPost(
                {
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  integrationApiClientId: integrationApiClient.id!,
                  integrationApiClientKeyInputDto: {
                    ...values,
                    expiresAt: values.expiresAt
                      ? moment(values.expiresAt).utc().format()
                      : undefined,
                  },
                },
              );
            enqueueSnackbar("API Key created.", { variant: "success" });
            onCreate && onCreate(response.data);
            onSave && onSave(response.data);
          } else {
            const response =
              await apiClient.integrationApiClientsApi.apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut(
                {
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  integrationApiClientId: integrationApiClient.id!,
                  keyId: clientKey!.id!,
                  integrationApiClientKeyInputDto: {
                    ...values,
                    expiresAt: values.expiresAt
                      ? moment(values.expiresAt).utc().format()
                      : undefined,
                  },
                },
              );
            enqueueSnackbar("API Key updated.", { variant: "success" });
            onUpdate && onUpdate(response.data);
            onSave && onSave(response.data);
          }

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err: any) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
        setFieldValue,
        setValues,
      }) => {
        return (
          <form noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <Box>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  required
                  fullWidth
                  helperText={touched.name && errors.name}
                  label='Name'
                  margin='dense'
                  name='name'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.name || ""}
                  variant='outlined'
                />

                <FormControl margin='dense' fullWidth>
                  <MobileDateTimePicker
                    ampm={false}
                    label='Expires at'
                    value={(values.expiresAt && moment(values.expiresAt)) || null}
                    format={DATETIME_FORMATS.DISPLAY_DATETIME}
                    onChange={(newValue: Moment | null) => {
                      setFieldValue("expiresAt", newValue?.format() || null);
                    }}
                    slots={{ textField: (params) => <TextField {...params} fullWidth /> }}
                  />
                  {errors.expiresAt && <FormHelperText error>{errors.expiresAt}</FormHelperText>}
                </FormControl>
              </Box>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <FormActions>
                <Button
                  color='primary'
                  loading={isSubmitting}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Save
                </Button>
              </FormActions>
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
}
