import { Chip, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from "@mui/material";
import moment, { Moment } from "moment";

import { DATETIME_FORMATS } from "@/common/constants/common";
import { DatetimeHelper } from "@/common/helpers/datetime";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { ChatParticipantDto } from "@/core/api/generated";

import ChatParticipantAvatar, { ChatParticipantAvatarProps } from "./ChatParticipantAvatar";

export interface ParticipantListItemData {
  datetime?: string | Moment | null;
}

interface Props {
  participant?: ChatParticipantDto;
  /** Additional participant data from outside. */
  participantData?: ParticipantListItemData;
  participantAvatarProps?: ChatParticipantAvatarProps;
}

export default function ChatParticipantListItem({
  participant,
  participantData,
  participantAvatarProps,
}: Props) {
  const profile = useUserProfile();

  const isMe = participant?.userId === profile?.id;
  const participantName = participant?.personName?.name || participant?.email || "???";

  return (
    <ListItem>
      <ListItemAvatar>
        <ChatParticipantAvatar
          chatId={participant?.chatId}
          participant={participant}
          {...participantAvatarProps}
        />
      </ListItemAvatar>

      <ListItemText
        primary={
          <>
            {participantName}{" "}
            {isMe && (
              <Chip size='small' color='primary' variant='outlined' label='You' sx={{ ml: 1 }} />
            )}
          </>
        }
        secondary={
          <Stack direction='column' spacing={0} component='span'>
            <Typography component='span' variant='body2' color='text.secondary'>
              {participant?.email}
            </Typography>
            {participantData?.datetime && (
              <Typography component='span' variant='caption' color='text.secondary'>
                {DatetimeHelper.humanizeDateRangeDurationFromNow(participantData.datetime, {
                  isSuffix: true,
                })}{" "}
                ({moment(participantData.datetime).format(DATETIME_FORMATS.DISPLAY_DATETIME)})
              </Typography>
            )}
          </Stack>
        }
      />
    </ListItem>
  );
}
