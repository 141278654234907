import { BarChart, BarChartProps } from "@mui/x-charts";
import _ from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router";

import { VehicleListPageLocationState } from "@/App/MainAppView/Vehicles/VehiclesPage";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterDefinition } from "@/common/filters/filterDefinition";
import { FilterDefinitionItem } from "@/common/filters/filterDefinitionItem";
import { NumberHelper } from "@/common/helpers/number";
import { TextHelper } from "@/common/helpers/text";
import { getTypedPath } from "@/common/helpers/typedPath";
import { WidgetHelper } from "@/common/helpers/widget";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { ApiEnumName, enumService } from "@/common/services/enum";
import { cast } from "@/common/ts/conversions";
import { apiClient } from "@/core/api/ApiClient";
import {
  FilterOperator,
  FilterType,
  FilterValueType,
  VehicleDto,
  VehicleStatus,
} from "@/core/api/generated";

import AppIcon from "../../Icons/AppIcon";
import BaseWidget from "../Base/BaseWidget";
import WidgetContent from "../Base/WidgetContent";
import WidgetHeader from "../Base/WidgetHeader";
import WidgetTitle from "../Base/WidgetTitle";
import WidgetTitleDefaultActions from "../Base/WidgetTitleDefaultActions";

const axisTickLabelFontSizePx = 12;

export default function VehicleStatusSummaryWidget() {
  const history = useHistory();

  const dataRequest = useApiRequest(
    apiClient.widgetDataApi.apiV1WidgetDataVehicleStatusSummaryGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
    {
      deps: [],
    },
  );
  const data = dataRequest?.data;

  useEffect(() => {
    return WidgetHelper.handleRefetchAndRecomputeDataRequestInEffect((requestParams) => {
      dataRequest.refetchWithNewParams({
        widgetDataGetBaseDto: {
          ...requestParams,
        },
      });
    });
  }, [dataRequest]);

  const chartData = useMemo(
    () => ({
      series: cast<BarChartProps["series"]>([
        {
          id: data?.vehicleStatus?.dataSeriesId,
          data: data?.vehicleStatus?.points?.map((x) => x.value) ?? [],
          label: (location) =>
            location === "legend"
              ? `Total vehicles: ${data?.vehicleTotalCount ?? "-"}`
              : "Vehicles",
        },
      ]),
      yLabels:
        data?.vehicleStatus?.points?.map((x) => ({
          short: enumService.getEnumValueName(ApiEnumName.VehicleStatus, x.label),
          long: `${enumService.getEnumValueName(ApiEnumName.VehicleStatus, x.label)} (click to view the list)`,
          vehicleStatus: x.label,
        })) ?? [],
    }),
    [data],
  );

  const yAxisTickLabelEstimatedMaxWidthPx = useMemo(() => {
    const widths = chartData.yLabels.map((x) =>
      TextHelper.estimateTextWidthInPx(x.short, axisTickLabelFontSizePx),
    );
    return _.maxBy(widths, (x) => x.avg)?.avg ?? 100;
  }, [chartData]);

  const goToFilteredVehicles = useCallback((vehicleStatus: VehicleStatus) => {
    history.push(
      ROUTE_PATH.VEHICLES,
      cast<VehicleListPageLocationState>({
        initialValues: {
          filterDefinition: new FilterDefinition({
            items: [
              new FilterDefinitionItem({
                type: FilterType.Dynamic,
                field: getTypedPath<VehicleDto>().status.status.$path,
                operator: FilterOperator.Equal,
                valueType: FilterValueType.Enum,
                value: vehicleStatus,
              }),
            ],
          }),
        },
      }),
    );
  }, []);

  return (
    <BaseWidget>
      <WidgetHeader>
        <WidgetTitle
          icon={<AppIcon of='status' />}
          actions={
            <WidgetTitleDefaultActions
              data={data}
              onRecompute={async (requestParams) => {
                await dataRequest.refetchWithNewParams({
                  widgetDataGetBaseDto: {
                    ...requestParams,
                  },
                });
              }}
            />
          }
        >
          Vehicles by status
        </WidgetTitle>
      </WidgetHeader>

      <WidgetContent>
        <BarChart
          width={undefined} // undefined is responsive
          height={300}
          margin={{ top: 40, right: 10, bottom: 20, left: yAxisTickLabelEstimatedMaxWidthPx }}
          layout='horizontal'
          grid={{ vertical: true, horizontal: false }}
          loading={dataRequest.isLoading}
          series={chartData.series}
          // xAxis={[{ label: "Count" }]}
          xAxis={[
            {
              valueFormatter: (value) => NumberHelper.abbreviate(+value),
              tickLabelStyle: {
                fontSize: axisTickLabelFontSizePx,
              },
            },
          ]}
          yAxis={[
            {
              data: chartData.yLabels,
              scaleType: "band",
              tickPlacement: "middle",
              valueFormatter: (value, context) =>
                context.location === "tooltip" ? value.long : value.short,
              tickLabelStyle: {
                fontSize: axisTickLabelFontSizePx,
              },
            },
          ]}
          barLabel={(item, context) => {
            return _.isNil(item.value) || context.bar.width < 15 || context.bar.height < 15
              ? null
              : NumberHelper.abbreviate(item.value);
          }}
          slotProps={{
            legend: {
              hidden: false,
              direction: "row",
              position: { vertical: "top", horizontal: "right" },
              padding: 0,
            },
          }}
          onItemClick={(e, barItemIdentifier) => {
            const vehicleStatus = chartData.yLabels.at(barItemIdentifier.dataIndex)?.vehicleStatus;
            if (!vehicleStatus) {
              return;
            }
            goToFilteredVehicles(vehicleStatus);
          }}
          onAxisClick={(e, axisData) => {
            if (!axisData) {
              return;
            }
            const vehicleStatus = chartData.yLabels.at(axisData.dataIndex)?.vehicleStatus;
            if (!vehicleStatus) {
              return;
            }
            goToFilteredVehicles(vehicleStatus);
          }}
        ></BarChart>
      </WidgetContent>
    </BaseWidget>
  );
}
