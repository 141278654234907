import { Card, CardContent, CardHeader, Skeleton, SxProps, Theme } from "@mui/material";

import ChatHistorySkeleton, { ChatHistorySkeletonProps } from "./ChatHistorySkeleton";

interface Props {
  chatHistorySkeletonProps?: ChatHistorySkeletonProps;
  sx?: SxProps<Theme>;
}

export default function ChatSkeleton({ chatHistorySkeletonProps, sx }: Props) {
  return (
    <Card sx={{ width: "100%", ...sx }}>
      <CardHeader
        title={
          <>
            {/* Header */}
            <Skeleton variant='text' sx={{ fontSize: "1rem" }} />
          </>
        }
      ></CardHeader>
      <CardContent>
        {/* History */}
        <ChatHistorySkeleton {...chatHistorySkeletonProps} />
      </CardContent>
      <CardContent>
        {/* Message input */}
        <Skeleton variant='rectangular' width={"100%"} height={50} />
      </CardContent>
    </Card>
  );
}
