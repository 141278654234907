import { IconButton, IconButtonProps } from "@mui/material";
import { useCallback } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import * as appCommonSlice from "@/store/appCommon/slice";

interface Props extends IconButtonProps {}
export default function WebAppAssistantActivator({ ...props }: Props) {
  const dispatch = useAppThunkDispatch();

  const handleClick = useCallback(() => {
    dispatch(appCommonSlice.toggleAssistantBlock());
  }, [dispatch, appCommonSlice.toggleAssistantBlock]);

  return (
    <IconButton {...props} onClick={handleClick}>
      <AppIcon of='ai' />
    </IconButton>
  );
}
