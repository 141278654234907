import { ReactNode } from "react";
import { Redirect } from "react-router";

import { ROUTE_PARTS, ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission } from "@/core/api/generated";

interface AuthorizedGuardProps {
  /** List of permissions user must have (all) */
  permissions?: AppPermission[];
  /** List of permissions user must have (at least one from the list) */
  permissionsAny?: AppPermission[];
  children: ReactNode;
}

/** Renders children if user has required permissions, otherwise redirects to forbidden route */
export default function AuthorizedGuard({
  permissions,
  permissionsAny,
  children,
}: AuthorizedGuardProps) {
  const authorizationInfo = useAuthorizationInfo();

  const hasPermissions = authorizationInfo.hasPermissionsAllAndAny({
    permissions,
    permissionsAny,
  });

  if (!hasPermissions) {
    console.log(
      `Redirection to ${ROUTE_PARTS.FORBIDDEN} from location ${window.location.href}...`,
      {
        href: window.location.href,
        permissions,
        permissionsAny,
        userPermissions: authorizationInfo.userPermissions,
      },
    );
    return <Redirect to={ROUTE_PATH.AUTH_FORBIDDEN} />;
  }

  return <>{children}</>;
}
