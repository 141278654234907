import { ChatDto, ChatParticipantDto, GeneralScopeDto } from "@/core/api/generated";
import { AppRootStateType } from "@/store";

import {
  ChatsState,
  OpenChatInfo,
  getChatStoreKey,
  getChatStoreKeyById,
  getChatStoreKeyByScope,
  isOpenChatInfoEqual,
} from "./chatsSlice";

export const selectIsChatOpening = (state: AppRootStateType, chatId?: string | null): boolean => {
  return (
    (chatId &&
      state.communication.chats.loading.openChat &&
      state.communication.chats.loading.openChat[chatId]) ||
    false
  );
};

export const selectIsChatClosing = (state: AppRootStateType, chatId?: string | null): boolean => {
  return (
    (chatId &&
      state.communication.chats.loading.closeChat &&
      state.communication.chats.loading.closeChat[chatId]) ||
    false
  );
};

export const selectChat = (
  state: AppRootStateType,
  params: { chat?: ChatDto | null; chatId?: string | null; scope?: GeneralScopeDto | null },
) => {
  return (
    state.communication.chats.chatMap[getChatStoreKey(params.chat)] ||
    state.communication.chats.chatMap[getChatStoreKeyById(params.chatId)] ||
    state.communication.chats.chatMap[getChatStoreKeyByScope(params.scope)] ||
    undefined
  );
};

export const selectOpenedChatWithAnyPlacement = (
  state: AppRootStateType,
  openedChat?: OpenChatInfo | null,
): OpenChatInfo | undefined => {
  return (
    state.communication.chats.openedChats?.find((x) =>
      isOpenChatInfoEqual(x, openedChat, { comparePlacement: false }),
    ) || undefined
  );
};

export const selectOpenedChatsWithAnyPlacement = (
  state: AppRootStateType,
  openedChat?: OpenChatInfo | null,
): OpenChatInfo[] => {
  return (
    state.communication.chats.openedChats?.filter((x) =>
      isOpenChatInfoEqual(x, openedChat, { comparePlacement: false }),
    ) || []
  );
};

export const selectOpenedChatInChatsState = (
  state: ChatsState,
  openedChat?: OpenChatInfo | null,
): OpenChatInfo | undefined => {
  return state.openedChats?.find((x) => isOpenChatInfoEqual(x, openedChat)) || undefined;
};

export const selectOpenedChat = (
  state: AppRootStateType,
  openedChat?: OpenChatInfo | null,
): OpenChatInfo | undefined => {
  return selectOpenedChatInChatsState(state.communication.chats, openedChat);
};

export const selectChatsWithMeInParticipants = (state: AppRootStateType) => {
  const profile = state.profile.profile;
  const chats = Object.values(state.communication.chats.chatMap).filter((x) => !!x) as ChatDto[];

  return chats.filter((chat) => {
    const participants =
      state.communication.chatParticipants.paginatedChatParticipantMap[chat?.id || ""]?.items || [];
    return participants.some((p) => p.userId === profile?.id);
  });
};

export const selectChatCurrentParticipant = (
  state: AppRootStateType,
  chatId?: string | null,
  userId?: string | null,
) => {
  const userIdComputed = userId || state.profile.profile?.id;
  const chatParticipants =
    state.communication.chatParticipants.paginatedChatParticipantMap[chatId || ""]?.items;
  const currentParticipant = chatParticipants?.find((x) => x.userId === userIdComputed);
  return currentParticipant;
};

/** Returns all participants for currently authenticated user. */
export const selectCurrentUserChatParticipants = (state: AppRootStateType) => {
  const profile = state.profile.profile;
  const participants: ChatParticipantDto[] = [];

  const paginatedChatParticipantMap =
    state.communication.chatParticipants.paginatedChatParticipantMap || {};
  for (const chatId in paginatedChatParticipantMap) {
    const participant = paginatedChatParticipantMap[chatId]?.items?.find(
      (x) => x.userId === profile?.id,
    );
    if (participant) {
      participants.push(participant);
    }
  }
  return participants;
};

export const selectChatParticipantStatus = (
  state: AppRootStateType,
  chatId?: string | null,
  participantId?: string | null,
) => {
  const participantStatus =
    (state.communication.chatParticipants.participantStatusMap[chatId || ""] || {})[
      participantId || ""
    ] || {};
  return participantStatus;
};

export const selectChatParticipantOnlineStatus = (
  state: AppRootStateType,
  chatId?: string | null,
  participantId?: string | null,
) => {
  const participantStatus =
    (state.communication.chatParticipants.participantStatusMap[chatId || ""] || {})[
      participantId || ""
    ] || {};
  return participantStatus?.onlineStatus;
};

export const selectChatCurrentNegotiations = (state: AppRootStateType, chatId?: string | null) => {
  return state.communication.negotiations.currentNegotiationsMap[chatId || ""] || undefined;
};
