import {
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  TextField,
  TextFieldProps,
} from "@mui/material";
import _ from "lodash";
import { ChangeEvent, useCallback, useEffect } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import { VALIDATION_CONSTANTS } from "@/common/constants/validation";
import { ComparisonHelper } from "@/common/helpers/comparison";
import { NumberHelper } from "@/common/helpers/number";
import { PriceHelper } from "@/common/helpers/price";
import { useEffectWithDeepCompare } from "@/common/hooks/effect/useEffectWithDeepCompare";
import { enumService } from "@/common/services/enum";
import {
  DiscountType,
  DiscountValueType,
  GeneralCurrencyDto,
  GeneralCurrencyInputDto,
  GeneralDiscountDto,
  GeneralDiscountInputDto,
} from "@/core/api/generated";

import DropdownIconButton from "../../../Button/DropdownIconButton";
import InlineApiEnumValue from "../../../Enum/InlineApiEnumValue";

const defaultDisplayProps = {
  typeInput: false,
  valueTypeInput: true,
};

interface OwnProps {
  value: GeneralDiscountInputDto | GeneralDiscountDto | null | undefined;
  /** To which value tax is applied. */
  appliesTo:
    | {
        subTotal: number;
      }
    | undefined;
  defaultType?: DiscountType;
  defaultValueType?: DiscountValueType;
  currency?: GeneralCurrencyDto | GeneralCurrencyInputDto;
  allowTypeChange?: boolean;
  allowValueTypeChange?: boolean;
  disabled?: boolean;
  displayProps?: Partial<typeof defaultDisplayProps>;
  onChange?: (newValue?: GeneralDiscountInputDto | null) => void;
}

export type GeneralDiscountInputProps = OwnProps &
  Omit<TextFieldProps, "onChange" | "type" | "value" | "startAdornment">;

/** Complex component for entering discount value.
 *  Percents are displayed as [0; 100], but stored as [0;1].
 */
export default function GeneralDiscountInput({
  value,
  appliesTo,
  defaultType = DiscountType.Trade,
  defaultValueType = DiscountValueType.Percent,
  currency,
  allowTypeChange = true,
  allowValueTypeChange = true,
  disabled,
  required,
  displayProps = defaultDisplayProps,
  onChange,
  ...textFieldProps
}: GeneralDiscountInputProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const discountType = value?.type || defaultType;
  const discountValueType = value?.valueType || defaultValueType;
  const computedCurrency = currency || undefined;

  let inputValue: number | string = "";
  if (discountValueType === DiscountValueType.Percent && !_.isNil(value?.percent)) {
    inputValue = NumberHelper.normalizePercentToZeroHundred(value!.percent);
  } else if (discountValueType === DiscountValueType.Value && !_.isNil(value?.value)) {
    inputValue = value!.value;
  }

  // trigger change if required, but value is undefined
  useEffect(() => {
    if (_.isNil(value) && required) {
      onChange &&
        onChange({
          type: discountType,
          valueType: discountValueType,
          percent: discountValueType === DiscountValueType.Percent ? 0 : undefined,
          value: discountValueType === DiscountValueType.Value ? 0 : undefined,
        });
    }
  }, [value, required]);

  // trigger change when currency changes from outside
  useEffectWithDeepCompare(() => {
    const newCurrency = currency || undefined;
    if (value && !ComparisonHelper.isDeepEqual(value?.currency, newCurrency)) {
      onChange && onChange({ ...value, currency: newCurrency });
    }
  }, [currency]);

  const changeType = useCallback(
    (newType: DiscountType) =>
      onChange &&
      onChange({
        ...value,
        type: newType,
      }),
    [value, discountType, discountValueType, onChange],
  );

  const changeValueType = useCallback(
    (newValueType: DiscountValueType) => {
      let newValue: GeneralDiscountInputDto = {
        ...value,
        valueType: newValueType,
        percent: newValueType === DiscountValueType.Percent ? 0 : undefined,
        value: newValueType === DiscountValueType.Value ? 0 : undefined,
      };

      //auto-convert between value types
      if (value && appliesTo) {
        newValue = PriceHelper.convertDiscountTo(value, appliesTo.subTotal, newValueType);
      }

      onChange && onChange(newValue);
    },
    [value, discountValueType, onChange],
  );

  const _onChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newInputValue =
        _.isNil(e.target.value) || _.isEmpty(e.target.value) ? undefined : +e.target.value;

      if (_.isNil(newInputValue)) {
        onChange && onChange(undefined);
        return;
      }

      const newValue = {
        ...value,
        type: discountType,
        valueType: discountValueType,
        percent:
          discountValueType === DiscountValueType.Percent && !_.isNil(newInputValue)
            ? Math.min(
                NumberHelper.normalizePercentToZeroOne(newInputValue),
                VALIDATION_CONSTANTS.DiscountMaxValueForPercent,
              )
            : undefined,
        value:
          discountValueType === DiscountValueType.Value && !_.isNil(newInputValue)
            ? Math.min(newInputValue, VALIDATION_CONSTANTS.DiscountMaxValueForValue)
            : undefined,
        currency: computedCurrency,
      };
      onChange && onChange(newValue);
    },
    [value, discountType, discountValueType, onChange],
  );

  return (
    <TextField
      label='Discount'
      inputMode='decimal'
      value={_.isNil(inputValue) ? "" : inputValue}
      placeholder=''
      onChange={_onChange}
      disabled={disabled}
      required={required}
      InputProps={{
        inputProps: {
          ...(discountValueType === DiscountValueType.Percent && {
            min: VALIDATION_CONSTANTS.DiscountMinValueForPercent * 100,
            max: VALIDATION_CONSTANTS.DiscountMaxValueForPercent * 100,
            maxLength: VALIDATION_CONSTANTS.DiscountMaxLengthForPercent,
          }),
          ...(discountValueType === DiscountValueType.Value && {
            min: VALIDATION_CONSTANTS.DiscountMinValueForValue,
            max: VALIDATION_CONSTANTS.DiscountMaxValueForValue,
            maxLength: VALIDATION_CONSTANTS.DiscountMaxLengthForValue,
          }),
        },
        startAdornment: (
          <>
            {displayProps?.valueTypeInput && (
              <InputAdornment sx={{ mx: 0 }} position='start'>
                <DropdownIconButton
                  sx={{
                    fontWeight: "inherit",
                  }}
                  disabled={disabled || !allowValueTypeChange}
                  variant='default'
                  size='extraSmall'
                  color='text'
                  dropdownContent={
                    <MenuList>
                      {enumService
                        .getEnumDtos("DiscountValueType")
                        .filter((x) => x.value !== DiscountValueType.None)
                        .map((x, i) => (
                          <MenuItem
                            key={i}
                            onClick={() => {
                              changeValueType(x.value as DiscountValueType);
                            }}
                          >
                            <ListItemIcon>
                              {x.value === DiscountValueType.Value && <AppIcon of='money' />}
                              {x.value === DiscountValueType.Percent && <AppIcon of='percent' />}
                            </ListItemIcon>
                            <ListItemText>{x.name}</ListItemText>
                          </MenuItem>
                        ))}
                    </MenuList>
                  }
                >
                  {discountValueType === DiscountValueType.Value && (
                    <AppIcon of='money' fontWeight='inherit' />
                  )}
                  {discountValueType === DiscountValueType.Percent && (
                    <AppIcon of='percent' fontWeight='inherit' />
                  )}
                </DropdownIconButton>
              </InputAdornment>
            )}
          </>
        ),
        endAdornment: (
          <>
            {displayProps?.typeInput && allowTypeChange && (
              <InputAdornment sx={{ mx: 0 }} position='end'>
                <DropdownIconButton
                  disabled={disabled || !allowTypeChange}
                  variant='text'
                  size='extraSmall'
                  color='text'
                  dropdownContent={
                    <MenuList>
                      {enumService
                        .getEnumDtos("DiscountType")
                        .filter((x) => x.value !== DiscountType.None)
                        .map((x, i) => (
                          <MenuItem
                            key={i}
                            onClick={() => {
                              changeType(x.value as DiscountType);
                            }}
                          >
                            <ListItemText primary={x.name} secondary={x.description} />
                          </MenuItem>
                        ))}
                    </MenuList>
                  }
                >
                  <InlineApiEnumValue type='DiscountType' value={discountType} />
                </DropdownIconButton>
              </InputAdornment>
            )}
            {displayProps?.typeInput && !allowTypeChange && (
              <InputAdornment position='end'>
                <InlineApiEnumValue type='DiscountType' value={discountType} />
              </InputAdornment>
            )}
          </>
        ),
      }}
      {...textFieldProps}
    />
  );
}
