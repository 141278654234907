import { Box, Chip, Stack, Typography } from "@mui/material";
import _ from "lodash";
import { useState } from "react";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AppIconButton from "@/common/components/Button/AppIconButton";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FileListView from "@/common/components/Files/FileListView";
import InfoTile from "@/common/components/Form/Display/InfoTile";
import GeneralStageHistory from "@/common/components/GeneralStageHistory";
import AppIcon from "@/common/components/Icons/AppIcon";
import BaseEditNotesModal from "@/common/components/Modals/common/BaseEditNotesModal";
import AppTypography from "@/common/components/Text/AppTypography";
import { FileItem } from "@/common/fileItem";
import { enumService } from "@/common/services/enum";
import { apiClient } from "@/core/api/ApiClient";
import { EntityType, WheelOperationDto } from "@/core/api/generated";

import DepartmentLink from "../../../Department/DepartmentLink";
import AffiliationInfoDisplay from "../../../EntityAffiliation/AffiliationInfoDisplay";
import UpdateEntityAffiliationCascadeModal from "../../../EntityAffiliation/UpdateEntityAffiliationCascadeModal";
import GeneralByWhoDisplay from "../../../General/Display/GeneralByWhoDisplay";
import LocationLink from "../../../Location/LocationLink";
import SupplierLink from "../../../Supplier/SupplierLink";
import VehicleLink from "../../../Vehicle/VehicleLink";

interface Props {
  wheelOperation: WheelOperationDto;
  onUpdate: () => void;
}

export default function OverviewTabContent({ wheelOperation, onUpdate }: Props) {
  const [isUpdateAffiliationModalOpened, setIsUpdateAffiliationModalOpened] = useState(false);
  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={wheelOperation} />}
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <Stack spacing={1}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              <InfoTile
                label='Number'
                iconOf='personId'
                isEmpty={_.isNil(wheelOperation?.localNumber)}
              >
                {wheelOperation?.localNumber}
              </InfoTile>

              <InfoTile
                label='Department'
                iconOf='department'
                isEmpty={_.isNil((wheelOperation?.departmentIds || [])[0])}
              >
                <DepartmentLink
                  entity={undefined}
                  entityId={(wheelOperation?.departmentIds || [])[0]}
                />
              </InfoTile>

              <InfoTile
                label='Location'
                iconOf='location'
                isEmpty={_.isNil((wheelOperation?.locationIds || [])[0])}
              >
                <LocationLink
                  entity={undefined}
                  entityId={(wheelOperation?.locationIds || [])[0]}
                />
              </InfoTile>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              <InfoTile label='Vehicle' iconOf='vehicle' isEmpty={_.isNil(wheelOperation?.vehicle)}>
                <VehicleLink entity={wheelOperation?.vehicle} />
              </InfoTile>
              <InfoTile
                label='Supplier'
                iconOf='supplier'
                isEmpty={_.isNil(wheelOperation?.supplier)}
              >
                <SupplierLink entity={wheelOperation?.supplier} />
              </InfoTile>
              <InfoTile
                label='Responsible user'
                iconOf='inspector'
                isEmpty={_.isNil(wheelOperation?.responsibleUser)}
              >
                <GeneralByWhoDisplay who={wheelOperation.responsibleUser} />
              </InfoTile>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              <InfoTile
                label='Schedule type'
                iconOf='data'
                isEmpty={_.isNil(wheelOperation?.scheduleType)}
              >
                <InlineApiEnumValue
                  type='WheelOperationScheduleType'
                  value={wheelOperation.scheduleType}
                />
              </InfoTile>
              <InfoTile label='Scheduled At' iconOf='date' isEmpty={!wheelOperation.scheduledAt}>
                <Datetime datetime={wheelOperation?.scheduledAt} />
              </InfoTile>
              <InfoTile label='Notes' iconOf='notes'>
                {wheelOperation.notes ? wheelOperation.notes : "-"}{" "}
                <BaseEditNotesModal
                  initialValues={{ notes: wheelOperation.notes }}
                  onConfirm={async (notes) => {
                    await apiClient.wheelOperationsApi.apiV1WheelOperationsWheelOperationIdNotesPut(
                      {
                        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                        wheelOperationId: wheelOperation.id || "",
                        generalNotesInputDto: { notes },
                      },
                    );
                    onUpdate();
                  }}
                />
              </InfoTile>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              <InfoTile label='Mileage' iconOf='gauge' isEmpty={_.isNil(wheelOperation?.mileage)}>
                {wheelOperation?.mileage?.mileage}
              </InfoTile>

              <InfoTile
                label='Fuel level'
                iconOf='fuel'
                isEmpty={_.isNil(wheelOperation?.fuelLevel)}
              >
                {wheelOperation?.fuelLevel}
              </InfoTile>
            </Box>
          </Stack>

          {/* Service Types */}
          <Stack spacing={1}>
            <AppTypography variant='subtitle1'>Service types</AppTypography>
            <Stack spacing={1} direction='row'>
              {wheelOperation?.serviceTypes?.map((serviceType) => (
                <Chip
                  key={serviceType}
                  label={enumService.getEnumValueName("WheelServiceType", serviceType)}
                />
              ))}
            </Stack>
          </Stack>

          {/* Stage */}
          <Stack spacing={1}>
            <AppTypography variant='subtitle1'>Stage</AppTypography>
            <GeneralStageHistory
              stageEnumType='WheelOperationStage'
              stageHistory={wheelOperation.stageHistory}
              withUpdateButtons
              onUpdateStage={async (newStage) => {
                await apiClient.wheelOperationsApi.apiV1WheelOperationsWheelOperationIdStagePut({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  wheelOperationId: wheelOperation.id!,
                  generalStageUpdateDtoOfWheelOperationStage: {
                    newStage,
                  },
                });
                onUpdate && onUpdate();
              }}
            />
          </Stack>

          {/* Affiliation */}
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography variant='subtitle1'>
              Affiliation{" "}
              <AppIconButton
                tooltipProps={{ title: "Edit entity affiliation" }}
                onClick={() => setIsUpdateAffiliationModalOpened(true)}
              >
                <AppIcon of='edit' fontSize='small' />
              </AppIconButton>
            </Typography>
            <AffiliationInfoDisplay
              tenantId={wheelOperation.tenantId}
              departmentIds={wheelOperation.departmentIds}
              locationIds={wheelOperation.locationIds}
              sx={{ mt: 1 }}
            />
            <UpdateEntityAffiliationCascadeModal
              open={isUpdateAffiliationModalOpened}
              onClose={() => setIsUpdateAffiliationModalOpened(false)}
              entityType={EntityType.VisualInspection}
              entityId={wheelOperation.id}
            />
          </Stack>

          {!_.isEmpty(wheelOperation?.attachments) && (
            <Box>
              <FileListView
                files={FileItem.createManyFrom(wheelOperation?.attachments)}
                maxVisibleFiles={10}
                itemWidth={45}
              />
            </Box>
          )}
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}
