import { TabProps } from "@mui/material";
import _ from "lodash";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { TypeHelper } from "@/common/helpers/type";

import { UseCommonViewParamsHookResult } from "./useCommonViewParams";

export type TabIdsDefinition<TTabId extends string> = Record<TTabId, string>;

export type TabDefinition<TTabId extends string> = {
  label: ReactNode;
  value: TTabId;
  /** Whether to render the tab. */
  if?: boolean;
  /** Whether the tab is visible initially (can be overwritten by user configuration). */
  isVisible?: boolean;
  /** Whether the tab can be hidden/shown. */
  isHideable?: boolean;
  tabProps?: Partial<Omit<TabProps, "label" | "value">>;
};

export type PageTabDefinitionList<TTabId extends string> = Array<TabDefinition<TTabId>>;

/** { [TabId]: [IsVisible] } */
export type TabVisibilityMap<TTabId extends string> = Record<TTabId, boolean>;

interface Props<TTabId extends string = string> {
  /** Tab ids. */
  tabIdsDefinition: TabIdsDefinition<TTabId>;
  /** Tab that is active by default. */
  defaultTabId: TTabId;
  /** All tab definitions. */
  tabs: PageTabDefinitionList<TTabId>;
  /** Display these tabs. */
  onlyTabIds?: TTabId[];
  /** Don't display these tabs. */
  excludeTabIds?: TTabId[];
  /** If `true`, enables tab visibility management. By default enabled if tab persistence is enabled. */
  isTabManagementEnabled?: boolean;
  /** Integration with `CommonViewParams`. */
  commonViewParams?: UseCommonViewParamsHookResult;
}

export interface UsePageTabsHookResult<TTabId extends string = string> {
  /** Tab ids. */
  tabIdsDefinition: TabIdsDefinition<TTabId>;
  /** Tab that is active by default. */
  defaultTabId: TTabId;
  /** Currently active tab. */
  activeTabId: TTabId | undefined;
  /** All tab definitions. */
  tabs: PageTabDefinitionList<TTabId>;
  /** Manageable tab definitions (computed). */
  manageableTabs: PageTabDefinitionList<TTabId>;
  /** Currently visible tab definitions (computed). */
  visibleTabs: PageTabDefinitionList<TTabId>;
  /** If `true`, enables tab visibility management. By default enabled if tab persistence is enabled. */
  isTabManagementEnabled: boolean;
  initialTabVisibilityMap: TabVisibilityMap<TTabId>;
  tabVisibilityMap: TabVisibilityMap<TTabId>;
  /** Integration with `CommonViewParams`. */
  commonViewParams: UseCommonViewParamsHookResult | undefined;
  isTabVisible: (tabId: TTabId) => boolean;
  setActiveTab: (newTabId: TTabId | undefined) => void;
  setTabVisibilityMap: (newValue: TabVisibilityMap<TTabId>) => void;
}

/** Helps with managing tabs on a page. */
export function usePageTabs<TTabId extends string = string>({
  tabIdsDefinition: tabsDefinition,
  defaultTabId,
  tabs,
  onlyTabIds,
  excludeTabIds,
  isTabManagementEnabled,
  commonViewParams,
}: Props<TTabId>): UsePageTabsHookResult<TTabId> {
  // compute maps for quick access by key
  // const tabsMap = useMemo<Record<TTabId, TabDefinition<TTabId>>>(
  //   () =>
  //     _.chain(tabs)
  //       .keyBy((x) => x.value)
  //       .mapValues((x) => x)
  //       .value() as Record<TTabId, TabDefinition<TTabId>>,
  //   [tabs],
  // );
  const ifTabIdsMap = useMemo<Record<TTabId, boolean>>(
    () =>
      _.chain(tabs)
        .keyBy((x) => x.value)
        .mapValues((x) => x.if ?? true)
        .value() as Record<TTabId, boolean>,
    [tabs],
  );
  const isVisibleTabIdsMap = useMemo<Record<TTabId, boolean>>(
    () =>
      _.chain(tabs)
        .keyBy((x) => x.value)
        .mapValues((x) => x.isVisible ?? true)
        .value() as Record<TTabId, boolean>,
    [tabs],
  );
  const onlyTabIdsMap = useMemo<Partial<Record<TTabId, boolean>>>(
    () =>
      _.chain(onlyTabIds || {})
        .keyBy((x) => x)
        .mapValues((x) => true)
        .value() as Partial<Record<TTabId, boolean>>,
    [onlyTabIds],
  );
  const excludeTabIdsMap = useMemo<Partial<Record<TTabId, boolean>>>(
    () =>
      _.chain(excludeTabIds || {})
        .keyBy((x) => x)
        .mapValues((x) => true)
        .value() as Partial<Record<TTabId, boolean>>,
    [excludeTabIds],
  );

  const isTabManagementEnabledComputed = useMemo(
    () => isTabManagementEnabled ?? commonViewParams?.isTabManagementEnabled ?? false,
    [isTabManagementEnabled, commonViewParams],
  );

  const initialTabVisibilityMapComputed = useMemo<TabVisibilityMap<TTabId>>(
    () => (commonViewParams?.tabVisibilityMap as TabVisibilityMap<TTabId>) || isVisibleTabIdsMap,
    [],
  );

  // manage active tab and visibility here to be able to opt-in/out CommonViewParams at any moment without refactoring
  const [activeTabId, setActiveTabId] = useState<TTabId | undefined>(
    (commonViewParams?.activeTabId as TTabId | undefined) || defaultTabId || undefined,
  );
  const [tabVisibilityMap, setTabVisibilityMap] = useState<TabVisibilityMap<TTabId>>(
    initialTabVisibilityMapComputed,
  );

  const isTabManageable = useCallback(
    (tabId: TTabId) => {
      const condition1 =
        TypeHelper.isEmpty(ifTabIdsMap) || _.isNil(ifTabIdsMap[tabId]) || !!ifTabIdsMap[tabId];
      const condition2 = TypeHelper.isEmpty(onlyTabIdsMap) || !!onlyTabIdsMap[tabId];
      const condition3 =
        TypeHelper.isEmpty(excludeTabIdsMap) ||
        _.isNil(excludeTabIdsMap[tabId]) ||
        !excludeTabIdsMap[tabId];
      return condition1 && condition2 && condition3;
    },
    [ifTabIdsMap, onlyTabIdsMap, excludeTabIdsMap, tabVisibilityMap],
  );

  const isTabVisible = useCallback(
    (tabId: TTabId, newTabVisibilityMap?: TabVisibilityMap<TTabId>) => {
      const tempTabVisibilityMap = newTabVisibilityMap || tabVisibilityMap;
      const condition1 =
        TypeHelper.isEmpty(ifTabIdsMap) || _.isNil(ifTabIdsMap[tabId]) || !!ifTabIdsMap[tabId];
      const condition2 = TypeHelper.isEmpty(onlyTabIdsMap) || !!onlyTabIdsMap[tabId];
      const condition3 =
        TypeHelper.isEmpty(excludeTabIdsMap) ||
        _.isNil(excludeTabIdsMap[tabId]) ||
        !excludeTabIdsMap[tabId];
      const condition4 = _.isNil(tempTabVisibilityMap[tabId]) || tempTabVisibilityMap[tabId];
      return condition1 && condition2 && condition3 && condition4;
    },
    [ifTabIdsMap, onlyTabIdsMap, excludeTabIdsMap, tabVisibilityMap],
  );

  const manageableTabsComputed = useMemo<PageTabDefinitionList<TTabId>>(
    () => tabs.filter((x) => isTabManageable(x.value)),
    [tabs, isTabManageable],
  );

  const visibleTabsComputed = useMemo<PageTabDefinitionList<TTabId>>(
    () => tabs.filter((x) => isTabVisible(x.value)),
    [tabs, isTabVisible],
  );
  const visibleTabsMapComputed = useMemo<Record<TTabId, TabDefinition<TTabId>>>(
    () =>
      _.chain(visibleTabsComputed)
        .keyBy((x) => x.value)
        .mapValues((x) => x)
        .value() as Record<TTabId, TabDefinition<TTabId>>,
    [visibleTabsComputed],
  );

  const defaultTabIdComputed = useMemo(() => {
    const tempDefaultTabId = (commonViewParams?.activeTabId as TTabId) || defaultTabId;
    return visibleTabsMapComputed[tempDefaultTabId]
      ? tempDefaultTabId
      : _.first(visibleTabsComputed)?.value || ("" as TTabId);
  }, [defaultTabId, visibleTabsComputed, visibleTabsMapComputed]);

  // initially auto-select first visible tab if no active tab
  useEffect(() => {
    if (!activeTabId) {
      const tempDefaultTabId = (commonViewParams?.activeTabId as TTabId) || defaultTabId;
      const newActiveTab = visibleTabsMapComputed[tempDefaultTabId]
        ? tempDefaultTabId
        : _.first(visibleTabsComputed)?.value || undefined;
      setActiveTabId(newActiveTab);
    }
  }, []);

  const handleSetActiveTab = (newTabId: TTabId | undefined) => {
    setActiveTabId(newTabId);
    commonViewParams?.setActiveTab(newTabId);
  };

  const handleTabVisibilityMap = (newValue: TabVisibilityMap<TTabId>) => {
    setTabVisibilityMap(newValue);
    commonViewParams?.setTabVisibilityMap(newValue);

    // auto-select other tab if active tab have been just hidden
    const isActiveTabInvisible = activeTabId && newValue[activeTabId] === false;
    if (isActiveTabInvisible) {
      const newActiveTab = tabs.find((x) => isTabVisible(x.value, newValue))?.value;
      handleSetActiveTab(newActiveTab);
    }
  };

  // console.log("usePageTabs.", {
  //   tabs,
  //   visibleTabsComputed,
  //   manageableTabsComputed,
  //   ifTabIdsMap,
  //   isVisibleTabIdsMap,
  //   onlyTabIdsMap,
  //   excludeTabIdsMap,
  //   defaultTabId,
  //   defaultTabIdComputed,
  //   activeTabId,
  //   initialTabVisibilityMapComputed,
  //   tabVisibilityMap,
  //   commonViewParams,
  // });

  return {
    tabIdsDefinition: tabsDefinition,
    defaultTabId: defaultTabIdComputed,
    activeTabId: activeTabId,
    tabs,
    manageableTabs: manageableTabsComputed,
    visibleTabs: visibleTabsComputed,
    isTabManagementEnabled: isTabManagementEnabledComputed,
    initialTabVisibilityMap: initialTabVisibilityMapComputed,
    tabVisibilityMap,
    commonViewParams,
    isTabVisible: isTabVisible,
    setActiveTab: handleSetActiveTab,
    setTabVisibilityMap: handleTabVisibilityMap,
  };
}
