import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

// Figma name: Icon/Fill/checkmark-circle
export default forwardRef<SVGSVGElement, SvgIconProps>(function CheckCircleIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='24'
      height='24'
      viewBox='0 0 22 22'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.2954 9.60549L11.7274 15.6055C11.5394 15.8525 11.2484 15.9985 10.9384 16.0005H10.9314C10.6244 16.0005 10.3344 15.8585 10.1444 15.6165L7.71237 12.5095C7.37237 12.0755 7.44837 11.4465 7.88337 11.1065C8.31737 10.7655 8.94737 10.8415 9.28737 11.2775L10.9204 13.3635L14.7044 8.39449C15.0384 7.95549 15.6654 7.86949 16.1064 8.20449C16.5454 8.53949 16.6304 9.16649 16.2954 9.60549ZM12.0004 2.00049C6.47737 2.00049 2.00037 6.47749 2.00037 12.0005C2.00037 17.5225 6.47737 22.0005 12.0004 22.0005C17.5234 22.0005 22.0004 17.5225 22.0004 12.0005C22.0004 6.47749 17.5234 2.00049 12.0004 2.00049Z'
      />
      <mask
        id='mask0_1_705'
        // style='mask-type:luminance'
        maskUnits='userSpaceOnUse'
        x='2'
        y='2'
        width='21'
        height='21'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16.2954 9.60549L11.7274 15.6055C11.5394 15.8525 11.2484 15.9985 10.9384 16.0005H10.9314C10.6244 16.0005 10.3344 15.8585 10.1444 15.6165L7.71237 12.5095C7.37237 12.0755 7.44837 11.4465 7.88337 11.1065C8.31737 10.7655 8.94737 10.8415 9.28737 11.2775L10.9204 13.3635L14.7044 8.39449C15.0384 7.95549 15.6654 7.86949 16.1064 8.20449C16.5454 8.53949 16.6304 9.16649 16.2954 9.60549ZM12.0004 2.00049C6.47737 2.00049 2.00037 6.47749 2.00037 12.0005C2.00037 17.5225 6.47737 22.0005 12.0004 22.0005C17.5234 22.0005 22.0004 17.5225 22.0004 12.0005C22.0004 6.47749 17.5234 2.00049 12.0004 2.00049Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_1_705)'>
        <rect width='24' height='24' />
      </g>
    </SvgIcon>
  );
});
