import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import {
  DamageDetectionAggregateDto,
  DamageDetectionAggregateItemDto,
  DamageDetectionAggregateItemReferenceDto,
  DamageDetectionAggregateReferenceDto,
} from "@/core/api/generated";

import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getDamageDetectionAggregateTitle } from "./DamageDetectionAggregateAutocomplete";

export interface DamageDetectionAggregateInlineProps extends BaseEntityInlineInheritableProps {
  entity: DamageDetectionAggregateDto | DamageDetectionAggregateReferenceDto | null | undefined;
  entityId?: string | null;
  item?: DamageDetectionAggregateItemDto | DamageDetectionAggregateItemReferenceDto;
  itemId?: string | null;
}

export default function DamageDetectionAggregateInline({
  entity,
  entityId,
  item,
  itemId,
  ...otherProps
}: DamageDetectionAggregateInlineProps) {
  const request = useApiRequest(
    apiClient.damageDetectionAggregatesApi.apiV1DamageDetectionsAggregatesAggregateIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      aggregateId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as DamageDetectionAggregateDto;
  // const ref = entity as DamageDetectionAggregateReferenceDto;

  item = item || (itemId ? full?.items?.find((x) => x.id === itemId) : undefined);

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='damageDetectionAggregate' inText />}
      content={
        <>
          {full && getDamageDetectionAggregateTitle(full)}
          {item && <> (#{item.localNumber})</>}
        </>
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Damage detection aggregate"}
    />
  );
}
