import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import CreateUpdateRepairMaterial, {
  CreateUpdateRepairMaterialProps,
} from "./CreateUpdateRepairMaterial";

export interface OwnProps {
  createUpdateRepairMaterialProps: CreateUpdateRepairMaterialProps;
}

type Props = OwnProps & DialogProps;

export default function CreateUpdateRepairMaterialModal({
  createUpdateRepairMaterialProps,
  ...dialogProps
}: Props) {
  const isCreate = !createUpdateRepairMaterialProps.repairMaterialId;

  return (
    <Dialog fullWidth maxWidth='lg' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        {isCreate ? "Create new" : "Edit"} repair material
      </AppModalTitle>
      <DialogContent>
        <Box sx={{ py: 1 }}>
          <CreateUpdateRepairMaterial {...createUpdateRepairMaterialProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
