import { ListItemIcon, ListItemText, MenuItem, Stack } from "@mui/material";
import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";

import { ROUTE_PATH } from "@/common/constants/routing";
import { WheelOperationDto } from "@/core/api/generated";

import EntityMenu from "../../EntityMenu/EntityMenu";
import AppIcon from "../../Icons/AppIcon";
import WheelOperationDeleteModal from "./WheelOperationDeleteModal";

enum ActionFlags {
  Edit = "Edit",
  Delete = "Delete",
}
const defaultDisplayProps = {
  actions: {
    edit: true,
    delete: true,
  },
};

interface TriggersProps {
  entity?: WheelOperationDto | null;
  definition: typeof ActionFlags;
  handleClose: () => void;
  startAction: (props: ActionFlags) => void;
}

interface Props {
  entity?: WheelOperationDto | null;
  disabled?: boolean;
  onUpdate: ((newValue: WheelOperationDto | null | undefined) => void) | undefined;
  onDelete?: (() => void) | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  customActions?: (props: TriggersProps) => ReactNode;
}

export default function WheelOperationMenu({
  entity,
  disabled,
  customActions,
  onUpdate,
  onDelete,
  displayProps = defaultDisplayProps,
}: Props) {
  displayProps = { ...defaultDisplayProps, ...displayProps };
  return (
    <EntityMenu
      definition={ActionFlags}
      entity={entity}
      disabled={disabled}
      actionTriggers={({ handleClose, startAction }) => (
        <>
          {customActions &&
            customActions({
              entity,
              definition: ActionFlags,
              handleClose,
              startAction,
            })}
          {displayProps?.actions?.edit && (
            <MenuItem component={RouterLink} to={ROUTE_PATH.WHEEL_OPERATION_EDIT(entity?.id)}>
              <ListItemIcon>
                <AppIcon of='edit' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
          )}
          {displayProps?.actions?.delete && (
            //<AuthorizedElement permissions={[AppPermission.WheelOperationManage]}>
            <MenuItem onClick={() => startAction(ActionFlags.Delete)}>
              <ListItemIcon>
                <AppIcon of='delete' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
            //</AuthorizedElement>
          )}
        </>
      )}
      actionHandlers={({ currentAction, cancelAction, completeAction }) => (
        <Stack>
          {/* Delete */}
          {entity && (
            <WheelOperationDeleteModal
              entity={entity}
              open={currentAction === ActionFlags.Delete}
              onClose={() => cancelAction()}
              onDelete={() => {
                onDelete && onDelete();
                completeAction();
              }}
            />
          )}
        </Stack>
      )}
    />
  );
}
