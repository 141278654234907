import { MenuItemProps, MenuItemTypeMap } from "@mui/material";

import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission } from "@/core/api/generated";

import AppMenuItem from "../Menu/AppMenuItem";

type AuthorizedMenuItemProps<
  RootComponent extends React.ElementType = MenuItemTypeMap["defaultComponent"],
  AdditionalProps = any,
> = {
  /** List of permissions user must have (all) */
  permissions?: AppPermission[];
  /** List of permissions user must have (at least one from the list) */
  permissionsAny?: AppPermission[];
} & MenuItemProps<RootComponent, AdditionalProps>;

export default function AuthorizedMenuItem<
  RootComponent extends React.ElementType = MenuItemTypeMap["defaultComponent"],
  AdditionalProps = any,
>({
  permissions,
  permissionsAny,
  ...otherProps
}: AuthorizedMenuItemProps<RootComponent, AdditionalProps>) {
  const authorizationInfo = useAuthorizationInfo();

  const hasPermissions = authorizationInfo.hasPermissionsAllAndAny({
    permissions,
    permissionsAny,
  });

  if (!hasPermissions) {
    return null;
  }

  return <AppMenuItem {...otherProps} />;
}
