import { Box, Typography } from "@mui/material";

import InlineCode from "@/common/components/Code/InlineCode";
import ChatFileUploader from "@/common/components/Files/ChatFileUploader";
import ImageUploader from "@/common/components/Files/ImageUploader";

import FileUploader from "../../common/components/Files/FileUploader";

function DevFiles() {
  return (
    <Box>
      <Typography component='div' variant='h4' sx={{ my: 2 }}>
        Files
      </Typography>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        File upload
      </Typography>
      <Box>
        <Typography component='div' variant='h6' sx={{ my: 2 }}>
          <InlineCode>FileUploader</InlineCode>
        </Typography>
        <FileUploader multiple maxFiles={3} />

        <Typography component='div' variant='h6' sx={{ my: 2 }}>
          <InlineCode>ImageUploader</InlineCode>
        </Typography>
        <ImageUploader multiple />

        <Typography component='div' variant='h6' sx={{ my: 2 }}>
          <InlineCode>ChatFileUploader</InlineCode>
        </Typography>
        <ChatFileUploader chatId=''>
          {({ renderUploadButton, renderUploadList }) => (
            <Box sx={{ backgroundColor: (theme) => theme.palette.divider }}>
              <Box sx={{ px: 2, py: 6 }}>Chat area</Box>
              <Box sx={{ p: 2 }}>{renderUploadButton()}</Box>
              <Box sx={{ p: 2 }}>{renderUploadList()}</Box>
            </Box>
          )}
        </ChatFileUploader>
      </Box>
    </Box>
  );
}

export default DevFiles;
