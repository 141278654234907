import { Button, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import * as Yup from "yup";

import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "@/common/components/Entity/components/BaseEntityCreateUpdate";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FileItem } from "@/common/fileItem";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useUserAffiliation } from "@/common/hooks/useUserAffiliation";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  CreateVehicleDto,
  EntityType,
  GeneralStatusIdentifier,
  UpdateVehicleDto,
  VehicleDto,
  VehicleStatus,
  VehicleType,
} from "@/core/api/generated";

import GeneralValidationError from "../../Error/GeneralValidationError";
import FormActions from "../../Form/FormActions";
import VehicleGeneralInputs from "./Inputs/VehicleGeneralInputs";
import {
  vehicleSpecInputDtoFormatBeforePost,
  vehicleSpecToInputDto,
} from "./Inputs/VehicleSpecInput";

type DefaultValues = {
  spec?: Partial<CreateVehicleDto["spec"]>;
  plateNo?: CreateVehicleDto["plateNo"];
};

export type VehicleFormikValues = CreateVehicleDto &
  UpdateVehicleDto & {
    currentStatus: VehicleDto["status"];
    initialAttachments: VehicleDto["attachments"];
    uploadedAttachments?: FileItem[];
    isDefaultVisualModelSelectedManually: boolean;
    submit: string;
  };

export interface VehicleCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<VehicleDto, DefaultValues> {}

export type VehicleCreateUpdateProps = VehicleCreateUpdateOwnProps;

export default function VehicleCreateUpdate({
  entityId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: VehicleCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const isCreate = !entityId;

  const vehicleRequest = useApiRequest(
    apiClient.vehiclesApi.apiV1VehiclesVehicleIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: entityId!,
    },
    {
      skip: !entityId,
    },
  );
  const vehicle = vehicleRequest?.data;

  const { departments, locations } = useUserAffiliation();

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: vehicle && {
      idValue: vehicle.id!,
      newTitle: vehicle.localNumber || "",
    },
  });

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.Vehicle}
      entityId={entityId}
      entity={vehicle}
      entityRequest={vehicleRequest}
    >
      <Formik<
        VehicleFormikValues &
          BaseFormikValues & {
            isAttachmentFilesUploading: boolean;
            isAttachmentFilesHaveErrors: boolean;
          }
      >
        enableReinitialize={true}
        initialValues={{
          vehicleId: entityId || undefined,
          spec: (vehicle?.spec && vehicleSpecToInputDto(vehicle?.spec)) || {
            ...defaultValues?.spec,
            type: defaultValues?.spec?.type || vehicle?.spec?.type || VehicleType.Car,
            size:
              defaultValues?.spec?.size || vehicle?.spec?.size || vehicle?.spec?.model?.vehicleSize,
          },
          batteryState: vehicle?.batteryState || undefined,
          departmentId:
            (vehicle?.departmentIds && vehicle.departmentIds[0]) ||
            (departments && departments[0] && departments[0].id) ||
            undefined,
          locationId:
            (vehicle?.locationIds && vehicle.locationIds[0]) ||
            (locations && locations[0] && locations[0].id) ||
            undefined,
          defaultVisualModelId: vehicle?.defaultVisualModel?.id || undefined,
          accessoryIds: vehicle?.accessories?.map((x) => x.id!) || [],
          identificationNumber: vehicle?.identificationNumber || undefined,
          plateNo: defaultValues?.plateNo || vehicle?.plateNo || undefined,
          mileage: vehicle?.mileage || undefined,
          initialAttachments: vehicle?.attachments || undefined,
          attachments:
            vehicle?.attachments?.map((a) => ({
              caption: a.caption,
              notes: a.notes,
              file: {
                id: a.file?.id,
              },
            })) || undefined,
          notes: vehicle?.notes || undefined,
          car: vehicle?.car || {},
          tags: vehicle?.tags || undefined,
          currentStatus: vehicle?.status || undefined,
          status: vehicle?.status
            ? {
                ...vehicle.status,
                statusIdentifier: GeneralStatusIdentifier.VehicleStatus,
                status: vehicle.status.statusTyped,
              }
            : {
                statusIdentifier: GeneralStatusIdentifier.VehicleStatus,
                status: VehicleStatus.Available,
              },
          isDefaultVisualModelSelectedManually:
            !_.isEmpty(vehicle?.defaultVisualModel?.id) || false,
          isAttachmentFilesUploading: false,
          isAttachmentFilesHaveErrors: false,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // type: Yup.string().required("Type is required"),
          // fuelTypeId: Yup.string().required("Fuel type is required"),
          // makeId: Yup.string().required("Make is required"),
          // modelId: Yup.string().required("Model is required"),
          // bodyTypeId: Yup.string().required("Body is required"),
          // identificationNumber: Yup.string().required("Identification number is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          const newValues: typeof values = {
            ...values,
            plateNo: values.plateNo?.plateNo ? values.plateNo : undefined,
            spec: vehicleSpecInputDtoFormatBeforePost(values.spec),
            batteryState: values.batteryState && {
              ...values.batteryState,
              lastReplacementAt: values.batteryState?.lastReplacementAt
                ? moment(values.batteryState?.lastReplacementAt).utc().format()
                : undefined,
              lastServiceAt: values.batteryState?.lastServiceAt
                ? moment(values.batteryState?.lastServiceAt).utc().format()
                : undefined,
            },
          };

          try {
            if (isCreate) {
              const response = await apiClient.vehiclesApi.apiV1VehiclesPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                createVehicleDto: newValues,
              });
              enqueueSnackbar("Vehicle created.", { variant: "success" });
              onCreate && onCreate(response.data);
              onSave && onSave(response.data);
            } else {
              const response = await apiClient.vehiclesApi.apiV1VehiclesVehicleIdPut({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                vehicleId: entityId,
                updateVehicleDto: newValues,
              });
              enqueueSnackbar("Vehicle updated.", { variant: "success" });
              onUpdate && onUpdate(response.data);
              onSave && onSave(response.data);
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <Stack component='form' noValidate onSubmit={handleSubmit} spacing={1}>
              <Box>
                <VehicleGeneralInputs vehicleId={entityId} />

                {/* {values.spec?.type === VehicleType.Car && <VehicleCarInputs />} */}
              </Box>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <FormActions>
                <Button
                  color='primary'
                  disabled={values.isAttachmentFilesUploading || values.isAttachmentFilesHaveErrors}
                  loading={isSubmitting}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Save
                </Button>
              </FormActions>
            </Stack>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
