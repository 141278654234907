import { Box, FormControl, Stack, Typography } from "@mui/material";
import { useState } from "react";

import ColorInput from "@/common/components/Color/ColorInput";
import { ColorSpaceType, RgbColorType } from "@/common/ts/color";

export default function DevColorInput() {
  const [color, setColor] = useState<string | undefined>(undefined);

  return (
    <Stack spacing={2}>
      <Typography component='div' variant='h2'>
        ColorInput
      </Typography>

      <Stack spacing={2}>
        <Typography component='div' variant='h3'>
          Default
        </Typography>

        <Box>
          <FormControl margin='dense' fullWidth>
            <ColorInput
              colorSpaceType={ColorSpaceType.RGB}
              rgbColorType={RgbColorType.HEX}
              value={color || ""}
              label='Default'
              onChange={(newValue) => {
                setColor(newValue);
              }}
            />
          </FormControl>

          <FormControl margin='dense' fullWidth>
            <ColorInput
              colorSpaceType={ColorSpaceType.RGB}
              rgbColorType={RgbColorType.HEX}
              value={color || ""}
              label='With predefined colors'
              predefinedColors={["#f00", "#f50", "#f57", "#070", "#007"]}
              onChange={(newValue) => {
                setColor(newValue);
              }}
            />
          </FormControl>
        </Box>
      </Stack>

      <Stack spacing={2}>
        <Typography component='div' variant='h3'>
          RGB
        </Typography>

        <Box>
          <FormControl margin='dense' fullWidth>
            <ColorInput
              colorSpaceType={ColorSpaceType.RGB}
              rgbColorType={RgbColorType.HEX}
              value={color || ""}
              label='Color'
              onChange={(newValue) => {
                setColor(newValue);
              }}
            />
          </FormControl>
        </Box>
      </Stack>
    </Stack>
  );
}
