import { Button, Grid2, LinearProgress, Stack, SxProps, Theme, Typography } from "@mui/material";
import _ from "lodash";
import { useMemo, useState } from "react";

import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import Datetime from "@/common/components/Datetime/Datetime";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { ContractDto } from "@/core/api/generated";

import ContractCustomerCommunicationEntry from "./ContractCustomerCommunicationEntry";
import CreateContractCustomerCommunicationEntryModal from "./CreateContractCustomerCommunicationEntryModal";

const defaultDisplayProps = {
  overview: true,
  createNewEntry: true,
  entries: true,
};

export interface OwnProps {
  contract: ContractDto;
  displayProps?: Partial<typeof defaultDisplayProps>;
  sx?: SxProps<Theme>;
}

export type ContractCustomerCommunicationInfoProps = OwnProps;

export default function ContractCustomerCommunicationInfo({
  contract,
  displayProps = defaultDisplayProps,
  sx,
}: ContractCustomerCommunicationInfoProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const [isCreateCommunicationEntryModalOpen, setIsCreateCommunicationEntryModalOpen] =
    useState(false);

  const entries = useMemo(
    () => _.orderBy(contract.customerCommunication?.entries || [], (x) => x.contactedAt, "desc"),
    [contract],
  );

  const customerRequest = useApiRequest(
    apiClient.customersApi.apiV1CustomersByContractContractIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      contractId: contract.id!,
    },
    {
      skip: !contract?.id || !displayProps.entries,
    },
  );
  const customer = customerRequest?.data;

  return (
    <Stack spacing={2} sx={sx}>
      {customerRequest.isLoading && <LinearProgress />}

      {/* Overview */}
      {displayProps?.overview && (
        <Grid2 container columnGap={1} rowGap={1}>
          <Grid2 size={{ xxs: 12 }}>
            <Stack direction='column' spacing={1}>
              <FieldValue variant='filled' direction='column' label='Is contacted'>
                <BooleanValue value={contract!.customerCommunication?.isContacted} />{" "}
                <Typography
                  component='span'
                  variant='body2'
                  color='text.secondary'
                  fontSize='smaller'
                >
                  <Datetime
                    datetime={contract!.customerCommunication?.contactedAt}
                    withDurationFromNow
                  />
                </Typography>
              </FieldValue>
            </Stack>
          </Grid2>
          <Grid2 size={{ xxs: 12 }} minWidth={200}>
            <Stack direction='column' spacing={1}>
              <FieldValue variant='filled' direction='column' label='Is contacted on damage'>
                <BooleanValue
                  value={contract!.customerCommunication?.isContactedOnDamageDetection}
                />{" "}
                <Typography
                  component='span'
                  variant='body2'
                  color='text.secondary'
                  fontSize='smaller'
                >
                  <Datetime
                    datetime={contract!.customerCommunication?.contactedOnDamageDetectionAt}
                    withDurationFromNow
                  />
                </Typography>
              </FieldValue>
            </Stack>
          </Grid2>
          <Grid2 size={{ xxs: 12 }} minWidth={200}>
            <Stack direction='column' spacing={1}>
              <FieldValue variant='filled' direction='column' label='Is contacted on damage cost'>
                <BooleanValue
                  value={contract!.customerCommunication?.isContactedOnDamageCostEvaluation}
                />{" "}
                <Typography
                  component='span'
                  variant='body2'
                  color='text.secondary'
                  fontSize='smaller'
                >
                  <Datetime
                    datetime={contract!.customerCommunication?.contactedOnDamageCostEvaluationAt}
                    withDurationFromNow
                  />
                </Typography>
              </FieldValue>
            </Stack>
          </Grid2>
        </Grid2>
      )}

      {displayProps?.createNewEntry && (
        <Stack direction='row' spacing={1} justifyContent='flex-end'>
          <Button
            variant='outlined'
            color='text'
            size='small'
            onClick={() => setIsCreateCommunicationEntryModalOpen(true)}
          >
            Customer contacted
          </Button>
        </Stack>
      )}

      {/* Entries */}
      {displayProps?.entries && (
        <Stack spacing={1}>
          <Typography component='div' variant='subtitle1'>
            Entries
          </Typography>

          {entries.length === 0 && <EntityNotFoundAlert title='No entries yet' />}

          {customer && (
            <Stack spacing={1}>
              {entries?.map((entry, i) => (
                <ContractCustomerCommunicationEntry
                  key={i}
                  contract={contract}
                  customer={customer}
                  entry={entry}
                />
              ))}
            </Stack>
          )}
        </Stack>
      )}

      {/* Customer contacted */}
      <CreateContractCustomerCommunicationEntryModal
        createEntryProps={{
          contractId: contract.id!,
          onSave: () => setIsCreateCommunicationEntryModalOpen(false),
        }}
        open={isCreateCommunicationEntryModalOpen}
        onClose={() => setIsCreateCommunicationEntryModalOpen(false)}
      />
    </Stack>
  );
}
