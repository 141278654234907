import { Stack, Typography } from "@mui/material";
import { useEffect } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { UrlHelper } from "@/common/helpers/url";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";

export type InviteAlreadyAnsweredPageQueryParams = GeneralQueryParams;

export default function InviteAlreadyAnsweredPage() {
  // const { inviteId } = useParams<{ inviteId: string }>();
  const { ...generalQueryParams } = useQueryParams<InviteAlreadyAnsweredPageQueryParams>();

  // redirect to target URL
  useEffect(() => {
    if (generalQueryParams.redirectUrl) {
      UrlHelper.redirectToUrl(generalQueryParams.redirectUrl);
    }
  }, [generalQueryParams.redirectUrl]);

  return (
    <Stack spacing={2}>
      <Stack direction='column' spacing={1} sx={{ alignItems: "center", px: 4, py: 2 }}>
        <AppIcon of='info' fontSize='large' color='text' />

        <Typography component='div' variant='h6'>
          You have already answered on this invitation.
        </Typography>

        <AppLink to={"/"}>Go home</AppLink>
      </Stack>
    </Stack>
  );
}
