import { Box } from "@mui/material";
import { Redirect } from "react-router";

import { RoutePathBuilder } from "@/common/builders/routePath";
import AuthenticatedGuard from "@/common/components/Auth/AuthenticatedGuard";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AuthorizedGuard from "@/common/components/Auth/AuthorizedGuard";
import AuthorizedRoute from "@/common/components/Auth/AuthorizedRoute";
import AppRoute from "@/common/components/Route/AppRoute";
import { AppPermission } from "@/core/api/generated";

import { ROUTE_PATH } from "../../common/constants/routing";
import ApplicationEnumViewPage from "../AdminAppView/ApplicationEnums/ApplicationEnumViewPage";
import ApplicationEnumsPage from "../AdminAppView/ApplicationEnums/ApplicationEnumsPage";
import CompanyDashboardPage from "../AdminAppView/CompanyDashboard/CompanyDashboardPage";
import CustomSessionCreateUpdatePage from "../AdminAppView/CustomSessions/CustomSessionCreateUpdatePage";
import CustomSessionViewPage from "../AdminAppView/CustomSessions/CustomSessionViewPage";
import CustomSessionsPage from "../AdminAppView/CustomSessions/CustomSessionsPage";
import DamageDetectionViewPage from "../AdminAppView/DamageDetections/DamageDetectionViewPage";
import DamageDetections from "../AdminAppView/DamageDetections/DamageDetectionsPage";
import DashboardPage from "../AdminAppView/Dashboard/DashboardPage";
import DomainEventsPage from "../AdminAppView/DomainEvents/DomainEventsPage";
import FileViewPage from "../AdminAppView/Files/FileViewPage";
import FilesPage from "../AdminAppView/Files/FilesPage";
import IntegrationApiClientCreateUpdatePage from "../AdminAppView/Integration/IntegrationApiClients/IntegrationApiClientCreateUpdatePage";
import IntegrationApiClientViewPage from "../AdminAppView/Integration/IntegrationApiClients/IntegrationApiClientViewPage";
import IntegrationApiClientsPage from "../AdminAppView/Integration/IntegrationApiClients/IntegrationApiClientsPage";
import IntegrationPage from "../AdminAppView/Integration/IntegrationPage";
import CreateAnonymousInvitePage from "../AdminAppView/Invites/CreateAnonymousInvitePage";
import CreatePersonalInvitePage from "../AdminAppView/Invites/CreatePersonalInvitePage";
import InvitesPage from "../AdminAppView/Invites/InvitesPage";
import InvoiceViewPage from "../AdminAppView/Invoices/InvoiceViewPage";
import InvoicesPage from "../AdminAppView/Invoices/InvoicesPage";
import CreateNotificationPage from "../AdminAppView/Notifications/CreatePage";
import NotificationViewPage from "../AdminAppView/Notifications/NotificationViewPage";
import NotificationsPage from "../AdminAppView/Notifications/NotificationsPage";
import CountriesPage from "../AdminAppView/ReferenceData/Countries/CountriesPage";
import CurrenciesPage from "../AdminAppView/ReferenceData/Currencies/CurrenciesPage";
import DamageTypeCreateUpdatePage from "../AdminAppView/ReferenceData/DamageTypes/DamageTypeCreateUpdatePage";
import DamageTypesPage from "../AdminAppView/ReferenceData/DamageTypes/DamageTypesPage";
import VehicleBodyTypeCreateUpdatePage from "../AdminAppView/ReferenceData/VehicleBodyTypes/VehicleBodyTypeCreateUpdatePage";
import VehicleBodyTypesPage from "../AdminAppView/ReferenceData/VehicleBodyTypes/VehicleBodyTypesPage";
import VehicleFuelTypeCreateUpdatePage from "../AdminAppView/ReferenceData/VehicleFuelTypes/VehicleFuelTypeCreateUpdatePage";
import VehicleFuelTypesPage from "../AdminAppView/ReferenceData/VehicleFuelTypes/VehicleFuelTypesPage";
import VehicleGenerationCreateUpdatePage from "../AdminAppView/ReferenceData/VehicleGenerations/VehicleGenerationCreateUpdatePage";
import VehicleGenerationsPage from "../AdminAppView/ReferenceData/VehicleGenerations/VehicleGenerationsPage";
import VehicleMakeCreateUpdatePage from "../AdminAppView/ReferenceData/VehicleMakes/VehicleMakeCreateUpdatePage";
import VehicleMakesPage from "../AdminAppView/ReferenceData/VehicleMakes/VehicleMakesPage";
import VehicleModelCreateUpdatePage from "../AdminAppView/ReferenceData/VehicleModels/VehicleModelCreateUpdatePage";
import VehicleModelsPage from "../AdminAppView/ReferenceData/VehicleModels/VehicleModelsPage";
import VehicleModificationCreateUpdatePage from "../AdminAppView/ReferenceData/VehicleModifications/VehicleModificationCreateUpdatePage";
import VehicleModificationsPage from "../AdminAppView/ReferenceData/VehicleModifications/VehicleModificationsPage";
import VehiclePartTypeCreateUpdatePage from "../AdminAppView/ReferenceData/VehiclePartTypes/VehiclePartTypeCreateUpdatePage";
import VehiclePartTypesPage from "../AdminAppView/ReferenceData/VehiclePartTypes/VehiclePartTypesPage";
import VehicleTypesPage from "../AdminAppView/ReferenceData/VehicleTypes/VehicleTypesPage";
import VehicleVisualModelCreateUpdatePage from "../AdminAppView/ReferenceData/VehicleVisualModels/VehicleVisualModelCreateUpdatePage";
import VehicleVisualModelsPage from "../AdminAppView/ReferenceData/VehicleVisualModels/VehicleVisualModelsPage";
import CreateUpdateRepairMaterialPage from "../AdminAppView/Repair/RepairMaterial/CreateUpdateRepairMaterialPage";
import RepairMaterialViewPage from "../AdminAppView/Repair/RepairMaterial/RepairMaterialViewPage";
import RepairMaterialsPage from "../AdminAppView/Repair/RepairMaterial/RepairMaterialsPage";
import CreateUpdateRepairSparePartPage from "../AdminAppView/Repair/RepairSparePart/CreateUpdateRepairSparePartPage";
import RepairSparePartViewPage from "../AdminAppView/Repair/RepairSparePart/RepairSparePartViewPage";
import RepairSparePartsPage from "../AdminAppView/Repair/RepairSparePart/RepairSparePartsPage";
import CreateUpdateRepairSpecPage from "../AdminAppView/Repair/RepairSpec/CreateUpdateRepairSpecPage";
import RepairSpecViewPage from "../AdminAppView/Repair/RepairSpec/RepairSpecViewPage";
import RepairSpecsPage from "../AdminAppView/Repair/RepairSpec/RepairSpecsPage";
import CreateUpdateRepairWorkPage from "../AdminAppView/Repair/RepairWork/CreateUpdateRepairWorkPage";
import RepairWorkListPage from "../AdminAppView/Repair/RepairWork/RepairWorkListPage";
import RepairWorkViewPage from "../AdminAppView/Repair/RepairWork/RepairWorkViewPage";
import RoleCreateUpdatePage from "../AdminAppView/Roles/RoleCreateUpdatePage";
import RoleViewPage from "../AdminAppView/Roles/RoleViewPage";
import RolesPage from "../AdminAppView/Roles/RolesPage";
import RootRouteEntry from "../AdminAppView/RootRouteEntry";
import SubscriptionPlanCreateUpdatePage from "../AdminAppView/SubscriptionPlans/SubscriptionPlanCreateUpdatePage";
import SubscriptionPlansPage from "../AdminAppView/SubscriptionPlans/SubscriptionPlansPage";
import SystemCachePage from "../AdminAppView/SystemCache/SystemCachePage";
import TenantRequestsPage from "../AdminAppView/TenantRequest/TenantRequestsPage";
import TenantViewPage from "../AdminAppView/Tenants/TenantViewPage";
import TenantsPage from "../AdminAppView/Tenants/TenantsPage";
import UpdateUserRolesPage from "../AdminAppView/Users/UpdateUserRolesPage";
import UserEditPage from "../AdminAppView/Users/UserEditPage";
import UserViewPage from "../AdminAppView/Users/UserViewPage";
import UsersPage from "../AdminAppView/Users/UsersPage";
import GlobalAlertsForAllAppAreas from "../BaseApp/GlobalAlerts/GlobalAlertsForAllAppAreas";
import AdminAppLayout from "../Layouts/AdminAppLayout";

export default function AdminAppContent() {
  return (
    <AuthenticatedGuard>
      <AuthorizedGuard permissions={[AppPermission.AdminAppAccess]}>
        {/* Global alerts */}
        <Box sx={{ width: "100%" }}>
          <GlobalAlertsForAllAppAreas />
        </Box>

        <AdminAppLayout>
          <AppRoute
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_DASHBOARD)}
            render={() => <DashboardPage />}
          />
          <AppRoute
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_COMPANY_DASHBOARD)}
            render={() => <CompanyDashboardPage />}
          />
          <AppRoute
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_TENANTS)}
            render={() => <TenantsPage />}
          />
          <AppRoute
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_TENANT_VIEW())}
            render={() => <TenantViewPage />}
          />

          <AuthorizedRoute
            permissions={[]}
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_USERS)}
            render={() => <UsersPage />}
          />
          <AuthorizedRoute
            permissions={[AppPermission.AdminUserManage]}
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_USER_EDIT())}
            render={() => <UserEditPage />}
          />
          <AuthorizedRoute
            permissions={[AppPermission.RoleAssignmentManage]}
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_USER_ROLES_EDIT())}
            render={() => <UpdateUserRolesPage />}
          />
          <AuthorizedRoute
            permissions={[]}
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_USER_VIEW())}
            render={() => <UserViewPage />}
          />

          <AuthorizedRoute
            permissions={[]}
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_CUSTOM_SESSIONS)}
            render={() => <CustomSessionsPage />}
          />
          <AuthorizedRoute
            permissions={[]}
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_CUSTOM_SESSION_CREATE)}
            render={() => <CustomSessionCreateUpdatePage />}
          />
          <AuthorizedRoute
            permissions={[]}
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_CUSTOM_SESSION_EDIT())}
            render={() => <CustomSessionCreateUpdatePage />}
          />
          <AuthorizedRoute
            permissions={[]}
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_CUSTOM_SESSION_VIEW())}
            render={() => <CustomSessionViewPage />}
          />

          <AppRoute
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_NOTIFICATIONS)}
            render={() => <NotificationsPage />}
          />
          <AppRoute
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_NOTIFICATION_CREATE)}
            render={() => <CreateNotificationPage />}
          />
          <AppRoute
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_NOTIFICATIONS_VIEW())}
            render={({
              match: {
                params: { notificationId },
              },
            }) => {
              return <NotificationViewPage notificationId={notificationId} />;
            }}
          />

          <AuthorizedRoute
            permissions={[AppPermission.GlobalRoleRead]}
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_ROLES)}
            render={() => <RolesPage />}
          />
          <AuthorizedRoute
            permissions={[AppPermission.GlobalRoleManage]}
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_ROLE_CREATE)}
            render={() => <RoleCreateUpdatePage />}
          />
          <AuthorizedRoute
            permissions={[AppPermission.GlobalRoleManage]}
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_ROLE_EDIT())}
            render={() => <RoleCreateUpdatePage />}
          />
          <AuthorizedRoute
            permissions={[AppPermission.GlobalRoleRead]}
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_ROLE_VIEW())}
            render={() => <RoleViewPage />}
          />

          {/* Invites */}
          <AuthorizedRoute
            permissions={[AppPermission.AdminInviteRead]}
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_INVITES)}
            render={() => <InvitesPage />}
          />
          <AuthorizedRoute
            permissions={[AppPermission.AdminInviteManage]}
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_INVITES_CREATE_PERSONAL())}
            render={() => <CreatePersonalInvitePage />}
          />
          <AuthorizedRoute
            permissions={[AppPermission.AdminInviteManage]}
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_INVITES_CREATE_ANONYMOUS())}
            render={() => <CreateAnonymousInvitePage />}
          />

          <AppRoute
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_SUBSCRIPTION_PLANS)}
            render={() => <SubscriptionPlansPage />}
          />
          <AppRoute
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_SUBSCRIPTION_PLANS_EDIT())}
            render={() => <SubscriptionPlanCreateUpdatePage />}
          />

          <AppRoute
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_INVOICES)}
            render={() => <InvoicesPage />}
          />
          <AppRoute
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_INVOICE_VIEW())}
            render={() => <InvoiceViewPage />}
          />

          {/* ReferenceData */}
          <Box>
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_COUNTRIES)}
              render={() => <CountriesPage />}
            />

            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_CURRENCIES)}
              render={() => <CurrenciesPage />}
            />

            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_TYPES)}
              render={() => <VehicleTypesPage />}
            />

            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_FUEL_TYPES)}
              render={() => <VehicleFuelTypesPage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_FUEL_TYPE_CREATE)}
              render={() => <VehicleFuelTypeCreateUpdatePage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_FUEL_TYPES_EDIT())}
              render={() => <VehicleFuelTypeCreateUpdatePage />}
            />

            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_MAKES)}
              render={() => <VehicleMakesPage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_MAKE_CREATE)}
              render={() => <VehicleMakeCreateUpdatePage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_MAKES_EDIT())}
              render={() => <VehicleMakeCreateUpdatePage />}
            />

            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_MODELS)}
              render={() => <VehicleModelsPage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_MODEL_CREATE)}
              render={() => <VehicleModelCreateUpdatePage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_MODELS_EDIT())}
              render={() => <VehicleModelCreateUpdatePage />}
            />

            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_GENERATIONS)}
              render={() => <VehicleGenerationsPage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_GENERATION_CREATE)}
              render={() => <VehicleGenerationCreateUpdatePage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_GENERATION_EDIT())}
              render={() => <VehicleGenerationCreateUpdatePage />}
            />

            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_MODIFICATIONS)}
              render={() => <VehicleModificationsPage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_MODIFICATION_CREATE)}
              render={() => <VehicleModificationCreateUpdatePage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_MODIFICATION_EDIT())}
              render={() => <VehicleModificationCreateUpdatePage />}
            />

            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_BODY_TYPES)}
              render={() => <VehicleBodyTypesPage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_BODY_TYPE_CREATE)}
              render={() => <VehicleBodyTypeCreateUpdatePage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_BODY_TYPES_EDIT())}
              render={() => <VehicleBodyTypeCreateUpdatePage />}
            />

            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_DAMAGE_TYPES)}
              render={() => <DamageTypesPage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_DAMAGE_TYPE_CREATE)}
              render={() => <DamageTypeCreateUpdatePage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_DAMAGE_TYPES_EDIT())}
              render={() => <DamageTypeCreateUpdatePage />}
            />

            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_PART_TYPES)}
              render={() => <VehiclePartTypesPage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_PART_TYPE_CREATE)}
              render={() => <VehiclePartTypeCreateUpdatePage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_PART_TYPES_EDIT())}
              render={() => <VehiclePartTypeCreateUpdatePage />}
            />

            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_VISUAL_MODELS)}
              render={() => <VehicleVisualModelsPage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_VISUAL_MODEL_CREATE)}
              render={() => <VehicleVisualModelCreateUpdatePage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_VEHICLE_VISUAL_MODELS_EDIT())}
              render={() => <VehicleVisualModelCreateUpdatePage />}
            />
          </Box>

          {/* Repair */}
          <Box>
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_REPAIR_MATERIALS)}
              render={() => <RepairMaterialsPage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_REPAIR_MATERIAL_CREATE)}
              render={() => <CreateUpdateRepairMaterialPage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_REPAIR_MATERIAL_EDIT())}
              render={() => <CreateUpdateRepairMaterialPage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_REPAIR_MATERIAL_VIEW())}
              render={() => <RepairMaterialViewPage />}
            />

            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_REPAIR_WORK_LIST)}
              render={() => <RepairWorkListPage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_REPAIR_WORK_CREATE)}
              render={() => <CreateUpdateRepairWorkPage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_REPAIR_WORK_EDIT())}
              render={() => <CreateUpdateRepairWorkPage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_REPAIR_WORK_VIEW())}
              render={() => <RepairWorkViewPage />}
            />

            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_REPAIR_SPARE_PART_LIST)}
              render={() => <RepairSparePartsPage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_REPAIR_SPARE_PART_CREATE)}
              render={() => <CreateUpdateRepairSparePartPage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_REPAIR_SPARE_PART_EDIT())}
              render={() => <CreateUpdateRepairSparePartPage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_REPAIR_SPARE_PART_VIEW())}
              render={() => <RepairSparePartViewPage />}
            />

            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_REPAIR_SPECS)}
              render={() => <RepairSpecsPage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_REPAIR_SPEC_CREATE)}
              render={() => <CreateUpdateRepairSpecPage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_REPAIR_SPEC_EDIT())}
              render={() => <CreateUpdateRepairSpecPage />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_REPAIR_SPEC_VIEW())}
              render={() => <RepairSpecViewPage />}
            />
          </Box>

          {/* TenantRequest */}
          <AppRoute
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_TENANT_REQUESTS)}
            render={() => <TenantRequestsPage />}
          />

          {/* Operations */}
          <Box>
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_DAMAGE_DETECTIONS)}
              render={() => <DamageDetections />}
            />

            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_DAMAGE_DETECTION_VIEW())}
              render={() => <DamageDetectionViewPage />}
            />
          </Box>

          {/* Integration */}
          <AuthorizedElement permissions={[]}>
            <AuthorizedRoute
              permissions={[]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_INTEGRATION)}
              render={() => <IntegrationPage />}
            />

            {/* IntegrationApiClient */}
            <Box>
              <AuthorizedRoute
                permissions={[AppPermission.IntegrationApiClientRead]}
                exact
                path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_INTEGRATION_API_CLIENTS)}
                render={() => <IntegrationApiClientsPage />}
              />
              <AuthorizedRoute
                permissions={[AppPermission.IntegrationApiClientRead]}
                exact
                path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_INTEGRATION_API_CLIENT_VIEW())}
                render={() => <IntegrationApiClientViewPage />}
              />
              <AuthorizedRoute
                permissions={[AppPermission.IntegrationApiClientManage]}
                exact
                path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_INTEGRATION_API_CLIENT_CREATE)}
                render={() => <IntegrationApiClientCreateUpdatePage />}
              />
              <AuthorizedRoute
                permissions={[AppPermission.IntegrationApiClientManage]}
                exact
                path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_INTEGRATION_API_CLIENT_EDIT())}
                render={() => <IntegrationApiClientCreateUpdatePage />}
              />
            </Box>
          </AuthorizedElement>

          {/* File */}
          <Box>
            <AuthorizedRoute
              permissions={[AppPermission.AdminFileManage]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_FILES)}
              render={() => <FilesPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.AdminFileManage]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_FILE_VIEW())}
              render={() => <FileViewPage />}
            />
          </Box>

          <AppRoute
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_SYSTEM_APPLICATION_ENUMS)}
            render={() => <ApplicationEnumsPage />}
          />
          <AppRoute
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_SYSTEM_APPLICATION_ENUM_VIEW())}
            render={() => <ApplicationEnumViewPage />}
          />

          <AppRoute
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_SYSTEM_CACHE)}
            render={() => <SystemCachePage />}
          />

          <AppRoute
            exact={true}
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.APP_AREA_ADMIN)}
            render={() => {
              return <Redirect to={ROUTE_PATH.ADMIN_DASHBOARD} />;
            }}
          />

          <AppRoute
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.APP_AREA_ADMIN)}
            render={() => <RootRouteEntry />}
          />

          <AppRoute
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.ADMIN_DOMAIN_EVENTS)}
            render={() => <DomainEventsPage />}
          />
        </AdminAppLayout>
      </AuthorizedGuard>
    </AuthenticatedGuard>
  );
}
