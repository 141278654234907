import {
  Box,
  BoxProps,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  SxProps,
  Theme,
} from "@mui/material";
import _ from "lodash";
import { ReactNode, useCallback, useRef, useState } from "react";

import AppTooltip, { AppTooltipProps } from "../AppTooltip";
import AppIcon from "../Icons/AppIcon";
import AppButton, { AppButtonProps } from "./AppButton";
import AppIconButton, { AppIconButtonProps } from "./AppIconButton";

type BaseComponentProps = AppButtonProps | AppIconButtonProps;

export interface DropdownButtonProps {
  component?: "button" | "iconButton";
  /** dropdown - click opens dropdown (button click isn't triggered). */
  /** button - behaves as normal button, click doesn't open dropdown (button click is triggered). */
  behavior?: "dropdown" | "button";
  dropdownContent: ReactNode | ((params: { handleClose: () => void }) => ReactNode);
  dropdownContentWrapperProps?: Partial<BoxProps>;
  variant?: AppButtonProps["variant"] & AppIconButtonProps["variant"];
  size?: BaseComponentProps["size"];
  color?: BaseComponentProps["color"];
  isLoading?: boolean;
  disabled?: boolean;
  autoCloseOnClick?: boolean;
  expandIconVariant?: "main" | "outlined";
  buttonProps?: AppButtonProps;
  iconButtonProps?: AppIconButtonProps;
  tooltipProps?: Pick<AppTooltipProps, "enabled" | "title">;
  /** Primary button content.
   *  Optional for iconButton.
   */
  children?: ReactNode;
  sx?: SxProps<Theme>;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onOpen?: () => void;
  onClose?: () => void;
}

/** Inline element for displaying program code */
export default function DropdownButton({
  component = "button",
  behavior = "dropdown",
  dropdownContent,
  dropdownContentWrapperProps,
  variant,
  size,
  color,
  isLoading = false,
  disabled,
  autoCloseOnClick = true,
  expandIconVariant = "main",
  buttonProps,
  iconButtonProps,
  tooltipProps = { enabled: false, title: "" },
  children,
  sx,
  onClick,
  onOpen,
  onClose,
}: DropdownButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  // const open = useCallback(() => {
  //   setIsOpen(true);
  //   onOpen && onOpen();
  // }, [onOpen]);

  const close = useCallback(() => {
    setIsOpen(false);
    onClose && onClose();
  }, [onClose]);

  const toggle = useCallback(() => {
    setIsOpen((prevOpen) => !prevOpen);
    !isOpen && onOpen && onOpen();
    isOpen && onClose && onClose();
  }, [isOpen, onOpen, onClose]);

  const renderExpandMoreIcon = useCallback(
    (sx2?: SxProps<Theme>): ReactNode =>
      expandIconVariant === "outlined" ? (
        <AppIcon of='expandMoreOutline' sx={sx2} />
      ) : (
        <AppIcon of='expandMore' sx={sx2} />
      ),
    [expandIconVariant],
  );
  const renderExpandLessIcon = useCallback(
    (sx2?: SxProps<Theme>): ReactNode =>
      expandIconVariant === "outlined" ? (
        <AppIcon of='expandLessOutline' sx={sx2} />
      ) : (
        <AppIcon of='expandLess' sx={sx2} />
      ),
    [expandIconVariant],
  );

  return (
    <>
      {/* Button */}
      {component === "button" && (
        <AppTooltip
          enabled={tooltipProps?.enabled}
          title={tooltipProps?.title}
          wrapperProps={{ sx: sx }}
        >
          <AppButton
            sx={sx}
            ref={anchorRef}
            disabled={disabled}
            variant={variant}
            size={size}
            color={color as AppButtonProps["color"]}
            loading={isLoading}
            {...buttonProps}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              if (behavior === "dropdown") {
                toggle();
              }
              if (behavior === "button") {
                onClick && onClick(e);
                buttonProps?.onClick && buttonProps?.onClick(e);
              }
            }}
          >
            {children}
            {behavior === "dropdown" && isOpen && renderExpandLessIcon({ ml: 0.25 })}
            {behavior === "dropdown" && !isOpen && renderExpandMoreIcon({ ml: 0.25 })}
          </AppButton>
        </AppTooltip>
      )}

      {component === "iconButton" && (
        <AppTooltip
          enabled={tooltipProps?.enabled}
          title={tooltipProps?.title}
          wrapperProps={{ sx: sx }}
        >
          <AppIconButton
            sx={sx}
            ref={anchorRef}
            disabled={disabled}
            variant={variant}
            size={size}
            color={color}
            loading={isLoading}
            {...iconButtonProps}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              if (behavior === "dropdown") {
                toggle();
              }
              if (behavior === "button") {
                onClick && onClick(e);
                buttonProps?.onClick && buttonProps?.onClick(e);
              }
            }}
          >
            {children}
            {!children && (
              <>
                {behavior === "dropdown" && isOpen && renderExpandLessIcon({})}
                {behavior === "dropdown" && !isOpen && renderExpandMoreIcon({})}
                {behavior === "button" && <AppIcon of='expandMore' />}
              </>
            )}
          </AppIconButton>
        </AppTooltip>
      )}

      {/* Dropdown content */}
      <Popper
        sx={{
          zIndex: (th) => th.zIndex.modal, // ensure visible in modals
        }}
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        // disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              {/* ClickAwayListener must be wrapped in order to Grow work. */}
              <ClickAwayListener onClickAway={close}>
                <Box
                  {...dropdownContentWrapperProps}
                  sx={{ overflowY: "auto", ...dropdownContentWrapperProps }}
                  onClick={() => autoCloseOnClick && close()}
                >
                  {_.isFunction(dropdownContent) && dropdownContent({ handleClose: close })}
                  {!_.isFunction(dropdownContent) && dropdownContent}
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
