import { useParams } from "react-router";

import LocationView from "@/common/components/Entity/Location/View/LocationView";

export default function LocationViewPage() {
  const { locationId } = useParams<{ locationId?: string }>();

  return (
    <>
      <LocationView
        locationId={locationId}
        location={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
