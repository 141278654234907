import { Box, Chip } from "@mui/material";
import { ReactNode } from "react";

import { TypeHelper } from "@/common/helpers/type";

import AppPopover from "../../Popover/AppPopover";
import AppPopoverContent from "../../Popover/AppPopoverContent";

export interface Props {
  count: number | undefined | null;
  /** Detailed content that is shown in popover when counter is hovered. */
  popoverContent?: ReactNode;
}

export default function TableCellContentOfCount({ count, popoverContent }: Props) {
  return (
    <AppPopover
      enabled={!!popoverContent}
      hoverBehavior={{}}
      trigger={
        <Box>
          {TypeHelper.isNil(count) ? (
            "-"
          ) : (
            <Chip
              variant='filled'
              color='default'
              size='medium'
              sx={{ minWidth: "32px" }}
              label={count}
            />
          )}
        </Box>
      }
    >
      <AppPopoverContent>{popoverContent}</AppPopoverContent>
    </AppPopover>
  );
}
