import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import {
  Alert,
  Autocomplete,
  AutocompleteProps,
  Box,
  Chip,
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { StringHelper } from "@/common/helpers/string";
import { TypeHelper } from "@/common/helpers/type";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { RoleAssignmentRestrictionsDto, RoleDto } from "@/core/api/generated";

import RoleListItem from "./RoleListItem";

const defaultDisplayProps = {
  selectedRolesOverview: false,
};

export interface RolesSelectorProps {
  getRoles: () => Promise<RoleDto[]>;
  roleIds?: string[] | null;
  roles?: RoleDto[] | null;
  roleAssignmentRestrictions?: RoleAssignmentRestrictionsDto | null;
  disabled?: boolean;
  autocompleteProps?: {
    disabled?: AutocompleteProps<any, any, any, any>["disabled"];
    size?: AutocompleteProps<any, any, any, any>["size"];
    sx?: AutocompleteProps<any, any, any, any>["sx"];
  };
  textFieldProps?: TextFieldProps;
  displayProps?: Partial<typeof defaultDisplayProps>;
  onChange?: (newValue?: RoleDto[]) => void;
}

export default function RolesSelector({
  getRoles,
  roleIds,
  roles,
  roleAssignmentRestrictions,
  disabled,
  autocompleteProps,
  textFieldProps,
  displayProps = defaultDisplayProps,
  onChange,
}: RolesSelectorProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const { enqueueSnackbar } = useAppSnackbar();

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [roleList, setRoleList] = useState<readonly RoleDto[] | undefined>(undefined);
  const [options, setOptions] = useState<readonly RoleDto[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<RoleDto[]>([]);
  const [__, setInputValue] = useState("");

  const isRolesRestricted = useMemo(
    () => !_.isNil(roleAssignmentRestrictions),
    [roleAssignmentRestrictions],
  );
  const isAnyRolesDisabled = useMemo(
    () => isRolesRestricted && !TypeHelper.isEmpty(roleAssignmentRestrictions?.disallowedRoles),
    [roleAssignmentRestrictions, isRolesRestricted],
  );

  const apiParams: Parameters<typeof getRoles> = [];

  const getRolesThrottle = useCallback(
    _.throttle(async () => {
      try {
        setIsLoading(true);
        return await getRoles();
      } finally {
        setIsLoading(false);
      }
    }, 100),
    [getRoles],
  );

  useEffect(() => {
    getRoles().then((data) => setRoleList(data));
  }, [getRoles]);

  // initial load
  useEffect(() => {
    (async () => {
      if (!roleList) {
        const data = await getRolesThrottle(...apiParams);
        setRoleList(data);
      }
    })();
  }, []);

  useEffect(() => {
    const newOptions = [...selectedOptions, ...(roleList || [])];
    setOptions(_.uniqBy(newOptions, (x) => x.id));

    if (roleIds && roleIds.some((id) => !selectedOptions.some((y) => y.id === id))) {
      setSelectedOptions(newOptions.filter((x) => roleIds.includes(x.id!)));
    }
  }, [roleList, roleIds]);

  useEffect(() => {
    if (!roles && !roleIds) {
      setSelectedOptions([]);
    } else if (roles && roles.some((x) => !selectedOptions.some((y) => y.id === x.id))) {
      setOptions(_.uniqBy([...roles, ...options], (x) => x.id));
      setSelectedOptions(roles);
    } else if (roleIds && roleIds.some((id) => !selectedOptions.some((y) => y.id === id))) {
      setSelectedOptions(options.filter((x) => roleIds.includes(x.id!)) || null);
    }
  }, [roleIds, roles]);

  // automatically deselect unavailable roles
  useEffect(() => {
    if (roleAssignmentRestrictions && selectedOptions) {
      const unavailableSelectedRoles = selectedOptions.filter(
        (role1) =>
          roleAssignmentRestrictions.disallowedRoles?.some((role2) => role2.id === role1.id) ??
          false,
      );
      if (unavailableSelectedRoles.length !== 0) {
        const newOptions = selectedOptions.filter(
          (role1) => !unavailableSelectedRoles.some((role2) => role2.id === role1.id),
        );
        setSelectedOptions(newOptions);
        onChange && onChange(newOptions);
        enqueueSnackbar(
          `You are not allowed to select these roles: ${StringHelper.joinIntoString(
            unavailableSelectedRoles.map((x) => x.name),
            ", ",
          )}. Deselecting them.`,
          {
            variant: "warning",
          },
        );
      }
    }
  }, [roleAssignmentRestrictions, selectedOptions]);

  return (
    <Stack spacing={0}>
      {isAnyRolesDisabled && (
        <Alert severity='info'>
          <Box>{`Roles that you can't assign are disabled.`}</Box>
          <Box>{`If you can't assign any role, contact your admin to give you role assignment permission.`}</Box>
        </Alert>
      )}

      <Autocomplete
        multiple
        sx={{ minWidth: 200, flex: 1, ...autocompleteProps?.sx }}
        disabled={disabled}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name!}
        getOptionDisabled={(option) =>
          isRolesRestricted && !roleAssignmentRestrictions?.allowedRoleIdsMap![option.id!]
        }
        // filterOptions={(x) => x} // disable the built-in filtering
        options={options}
        loading={isLoading}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={selectedOptions}
        onChange={(event, newValues) => {
          setSelectedOptions(newValues);
          onChange && onChange(newValues || undefined);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Roles'
            placeholder='Select roles...'
            margin='normal'
            fullWidth
            {...textFieldProps}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option) => {
          return (
            <ListItem {...props} key={option.id}>
              <ListItemIcon>
                <AssignmentIndIcon sx={{ color: (t) => t.palette.text.main }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <>
                    <span>{option.name}</span>{" "}
                    <Chip
                      size='small'
                      color='default'
                      variant='outlined'
                      label={option.isBuiltIn ? "Built-in role" : "Custom role"}
                      sx={{ ml: 1 }}
                    />
                  </>
                }
                secondary={option.description}
              />
            </ListItem>
          );
        }}
        {...autocompleteProps}
      />

      {displayProps?.selectedRolesOverview && (
        <Box>
          <Typography component='div' variant='body2' sx={{ mb: 1 }}>
            Selected roles:
          </Typography>
          {selectedOptions.map((option) => (
            <RoleListItem
              key={option.id}
              role={option}
              withDetailsToggle
              isDetailsVisible={false}
            />
          ))}
        </Box>
      )}
    </Stack>
  );
}
