import { Box, SxProps, Theme, Typography } from "@mui/material";
import { useMemo } from "react";

import { renderIf } from "@/common/helpers/render/renderIf";
import { EntityType, IBaseEntityDto } from "@/core/api/generated";

import AccessoryInline from "../Accessory/AccessoryInline";
import AccessoryCheckInline from "../AccessoryCheck/AccessoryCheckInline";
import AssessmentFlowInline from "../AssessmentFlow/AssessmentFlowInline";
import AssetInline from "../Asset/AssetInline";
import AssetSubscriptionInline from "../AssetSubscription/AssetSubscriptionInline";
import AssetSubscriptionPlanInline from "../AssetSubscriptionPlan/AssetSubscriptionPlanInline";
import ContractInline from "../Contract/ContractInline";
import CustomerInline from "../Customer/CustomerInline";
import DamageCostEvaluationInline from "../DamageCostEvaluation/DamageCostEvaluationInline";
import DamageCostEvaluationAggregateInline from "../DamageCostEvaluationAggregate/DamageCostEvaluationAggregateInline";
import DamageDetectionInline from "../DamageDetection/DamageDetectionInline";
import DamageDetectionAggregateInline from "../DamageDetectionAggregate/DamageDetectionAggregateInline";
import MaintenanceInline from "../Maintenance/MaintenanceInline";
import RepairOperationInline from "../RepairOperation/RepairOperationInline";
import SupplierInline from "../Supplier/SupplierInline";
import TenantConnectionInline from "../TenantConnection/TenantConnectionInline";
import TenantConnectionRequestInline from "../TenantConnectionRequest/TenantConnectionRequestInline";
import TenantRequestInline from "../TenantRequest/TenantRequestInline";
import VehicleInline from "../Vehicle/VehicleInline";
import VehicleDamageInline from "../VehicleDamage/VehicleDamageInline";
import VisualInspectionInline from "../VisualInspection/VisualInspectionInline";
import WashInline from "../Wash/WashInline";
import WheelOperationInline from "../WheelOperation/WheelOperationInline";
import { BaseEntityInlineInheritableProps } from "./BaseEntityInline";

interface Props {
  entityType: EntityType | undefined;
  entityId: string | undefined;
  entity: IBaseEntityDto | undefined;
  inlineProps?: Partial<BaseEntityInlineInheritableProps>;
  sx?: SxProps<Theme>;
}

/** Based on entity type renders corresponding entity component. */
export default function EntityInline({ entityType, entityId, entity, inlineProps, sx }: Props) {
  const entityIdComputed = useMemo(() => entityId ?? entity?.id, [entityId, entity]);

  if (!entityType && !entityIdComputed) {
    return null;
  }

  return (
    <Box component='span' sx={sx}>
      {renderIf()
        .if(entityType === EntityType.Vehicle)
        .then(<VehicleInline entity={entity} entityId={entityId} {...inlineProps} />)
        .elseif(entityType === EntityType.VehicleDamage)
        .then(<VehicleDamageInline entity={entity} entityId={entityId} {...inlineProps} />)
        .elseif(entityType === EntityType.Contract)
        .then(<ContractInline entity={entity} entityId={entityId} {...inlineProps} />)
        .elseif(entityType === EntityType.AssessmentFlow)
        .then(<AssessmentFlowInline entity={entity} entityId={entityId} {...inlineProps} />)
        .elseif(entityType === EntityType.Accessory)
        .then(<AccessoryInline entity={entity} entityId={entityId} {...inlineProps} />)
        .elseif(entityType === EntityType.AccessoryCheck)
        .then(<AccessoryCheckInline entity={entity} entityId={entityId} {...inlineProps} />)
        .elseif(entityType === EntityType.VisualInspection)
        .then(<VisualInspectionInline entity={entity} entityId={entityId} {...inlineProps} />)
        .elseif(entityType === EntityType.DamageDetection)
        .then(<DamageDetectionInline entity={entity} entityId={entityId} {...inlineProps} />)
        .elseif(entityType === EntityType.DamageDetectionAggregate)
        .then(
          <DamageDetectionAggregateInline entity={entity} entityId={entityId} {...inlineProps} />,
        )
        .elseif(entityType === EntityType.DamageCostEvaluation)
        .then(<DamageCostEvaluationInline entity={entity} entityId={entityId} {...inlineProps} />)
        .elseif(entityType === EntityType.DamageCostEvaluationAggregate)
        .then(
          <DamageCostEvaluationAggregateInline
            entity={entity}
            entityId={entityId}
            {...inlineProps}
          />,
        )
        .elseif(entityType === EntityType.RepairOperation)
        .then(<RepairOperationInline entity={entity} entityId={entityId} {...inlineProps} />)
        .elseif(entityType === EntityType.WheelOperation)
        .then(<WheelOperationInline entity={entity} entityId={entityId} {...inlineProps} />)
        .elseif(entityType === EntityType.Wash)
        .then(<WashInline entity={entity} entityId={entityId} {...inlineProps} />)
        .elseif(entityType === EntityType.Maintenance)
        .then(<MaintenanceInline entity={entity} entityId={entityId} {...inlineProps} />)
        .elseif(entityType === EntityType.Asset)
        .then(<AssetInline entity={entity} entityId={entityId} {...inlineProps} />)
        .elseif(entityType === EntityType.AssetSubscriptionPlan)
        .then(<AssetSubscriptionPlanInline entity={entity} entityId={entityId} {...inlineProps} />)
        .elseif(entityType === EntityType.AssetSubscription)
        .then(<AssetSubscriptionInline entity={entity} entityId={entityId} {...inlineProps} />)
        .elseif(entityType === EntityType.TenantConnectionRequest)
        .then(
          <TenantConnectionRequestInline entity={entity} entityId={entityId} {...inlineProps} />,
        )
        .elseif(entityType === EntityType.TenantConnection)
        .then(<TenantConnectionInline entity={entity} entityId={entityId} {...inlineProps} />)
        .elseif(entityType === EntityType.TenantRequest)
        .then(<TenantRequestInline entity={entity} entityId={entityId} {...inlineProps} />)
        .elseif(entityType === EntityType.Customer)
        .then(<CustomerInline entity={entity} entityId={entityId} {...inlineProps} />)
        .elseif(entityType === EntityType.Supplier)
        .then(<SupplierInline entity={entity} entityId={entityId} {...inlineProps} />)
        // .elseif(entityType === EntityType.DataGrant)
        // .then(<DataGrantInline entity={entity} entityId={entityId} {...inlineProps} />)
        .else(
          <Typography component='span' color='error'>
            Unknown entity {entityType}
          </Typography>,
        )
        .render()}
    </Box>
  );
}
