import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function FuelIcon(props: SvgIconProps, ref) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M20.8 9.6001V18.4001C20.8 18.8419 20.4418 19.2001 20 19.2001C19.5582 19.2001 19.2 18.8419 19.2 18.4001V15.2001C19.2 13.8747 18.1254 12.8001 16.8 12.8001H14.4V3.2001C14.4 2.31642 13.6837 1.6001 12.8 1.6001H3.20001C2.31633 1.6001 1.60001 2.31642 1.60001 3.2001V20.8001C1.60001 21.6838 2.31633 22.4001 3.20001 22.4001H12.8C13.6837 22.4001 14.4 21.6838 14.4 20.8001V14.4001H16.8C17.2418 14.4001 17.6 14.7583 17.6 15.2001V18.4001C17.6 19.7255 18.6746 20.8001 20 20.8001C21.3254 20.8001 22.4 19.7255 22.4 18.4001V8.0001C22.4 7.11642 21.6837 6.4001 20.8 6.4001V3.9841C20.7914 3.54858 20.4357 3.19994 20 3.2001C19.5494 3.20762 19.1901 3.57882 19.1976 4.02954C19.1979 4.04634 19.1987 4.0633 19.2 4.0801V8.0001C19.2 8.88378 19.9163 9.6001 20.8 9.6001C21.6837 9.6001 22.4 8.88378 22.4 8.0001C22.4 7.11642 21.6837 6.4001 20.8 6.4001M12.8 10.4001C12.8 10.8419 12.4418 11.2001 12 11.2001H4.00001C3.55825 11.2001 3.20001 10.8419 3.20001 10.4001V5.6001C3.20001 5.15834 3.55825 4.8001 4.00001 4.8001H12C12.4418 4.8001 12.8 5.15834 12.8 5.6001V10.4001Z' />
    </SvgIcon>
  );
});
