import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";

import { DatetimeHelper } from "@/common/helpers/datetime";
import { WidgetDataBaseDto, WidgetDataGetBaseDto } from "@/core/api/generated";

import AppIconButton from "../../Button/AppIconButton";
import Datetime from "../../Datetime/Datetime";
import DurationDisplay from "../../Form/Display/DurationDisplay";
import AppIcon from "../../Icons/AppIcon";
import AppTypography from "../../Text/AppTypography";

const defaultDisplayProps = {
  lastComputedAt: true,
  refetch: false,
  recompute: true,
};

interface Props {
  data: Nil<WidgetDataBaseDto>;
  displayProps?: Partial<typeof defaultDisplayProps>;
  onRefetch?: (requestParams: Partial<WidgetDataGetBaseDto>) => void | Promise<void>;
  onRecompute?: (requestParams: Partial<WidgetDataGetBaseDto>) => void | Promise<void>;
}

export default function WidgetTitleDefaultActions({
  data,
  displayProps,
  onRefetch,
  onRecompute,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const [computedAtHumanized, setComputedAtHumanized] = useState(
    DatetimeHelper.humanizeDateRangeDurationFromNow(data?.computedAt, { isSuffix: true }),
  );

  useEffect(() => {
    setComputedAtHumanized(
      DatetimeHelper.humanizeDateRangeDurationFromNow(data?.computedAt, { isSuffix: true }),
    );

    const refreshLastComputedTextInterval = 30_000;
    const intervalId = setInterval(() => {
      setComputedAtHumanized(
        DatetimeHelper.humanizeDateRangeDurationFromNow(data?.computedAt, { isSuffix: true }),
      );
    }, refreshLastComputedTextInterval);

    return () => {
      clearInterval(intervalId);
    };
  }, [data?.computedAt]);

  return (
    <Stack direction='row' spacing={1} sx={{ justifyContent: "flex-start", alignItems: "center" }}>
      {displayProps?.lastComputedAt && data && (
        <AppTypography
          variant='caption2'
          decoration={{ variant: "helpText" }}
          tooltipProps={{
            title: (
              <Box>
                <Box>
                  Last computed at - <Datetime datetime={data.computedAt} />
                </Box>
                <Box>
                  Recompute frequency - once in{" "}
                  <DurationDisplay
                    value={data.recomputeFrequency}
                    humanizeOptions={{ isSuffix: false }}
                  />
                </Box>
              </Box>
            ),
          }}
        >
          Last computed {computedAtHumanized || "-"}
        </AppTypography>
      )}

      {displayProps?.refetch && (
        <AppIconButton
          variant='text'
          color='inherit'
          size='small'
          tooltipProps={{ title: "Refresh" }}
          isAutoTrackLoading={false}
          onClick={async () => {
            onRefetch &&
              (await onRefetch({
                isRecompute: false,
              }));
          }}
        >
          <AppIcon of='refresh' />
        </AppIconButton>
      )}

      {displayProps?.recompute && (
        <AppIconButton
          variant='text'
          color='inherit'
          size='small'
          tooltipProps={{ title: "Recompute to get actual data" }}
          isAutoTrackLoading={false}
          onClick={async () => {
            onRecompute &&
              (await onRecompute({
                isRecompute: true,
              }));
          }}
        >
          <AppIcon of='recompute' />
        </AppIconButton>
      )}
    </Stack>
  );
}
