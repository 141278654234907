import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function CalendarIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='17'
      height='16'
      viewBox='0 0 17 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.16667 10.6667C5.16667 10.3 5.46667 10 5.83333 10C6.2 10 6.5 10.3 6.5 10.6667C6.5 11.0334 6.2 11.3334 5.83333 11.3334C5.46667 11.3334 5.16667 11.0334 5.16667 10.6667ZM8.5 10H11.1667C11.5333 10 11.8333 10.3 11.8333 10.6667C11.8333 11.0334 11.5333 11.3334 11.1667 11.3334H8.5C8.13333 11.3334 7.83333 11.0334 7.83333 10.6667C7.83333 10.3 8.13333 10 8.5 10ZM12.5 13.3334H4.5C4.13267 13.3334 3.83333 13.034 3.83333 12.6667V8.66671H13.1667V12.6667C13.1667 13.034 12.8673 13.3334 12.5 13.3334ZM4.5 4.00004H5.16667V4.66671C5.16667 5.03337 5.46667 5.33337 5.83333 5.33337C6.2 5.33337 6.5 5.03337 6.5 4.66671V4.00004H10.5V4.66671C10.5 5.03337 10.8 5.33337 11.1667 5.33337C11.5333 5.33337 11.8333 5.03337 11.8333 4.66671V4.00004H12.5C12.8673 4.00004 13.1667 4.29937 13.1667 4.66671V7.33337H3.83333V4.66671C3.83333 4.29937 4.13267 4.00004 4.5 4.00004ZM12.5 2.66671H11.8333V2.00004C11.8333 1.63337 11.5333 1.33337 11.1667 1.33337C10.8 1.33337 10.5 1.63337 10.5 2.00004V2.66671H6.5V2.00004C6.5 1.63337 6.2 1.33337 5.83333 1.33337C5.46667 1.33337 5.16667 1.63337 5.16667 2.00004V2.66671H4.5C3.39733 2.66671 2.5 3.56404 2.5 4.66671V12.6667C2.5 13.7694 3.39733 14.6667 4.5 14.6667H12.5C13.6027 14.6667 14.5 13.7694 14.5 12.6667V4.66671C14.5 3.56404 13.6027 2.66671 12.5 2.66671Z'
      />
      <mask
        id='mask0_3266_23433'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='2'
        y='1'
        width='13'
        height='14'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.16667 10.6667C5.16667 10.3 5.46667 10 5.83333 10C6.2 10 6.5 10.3 6.5 10.6667C6.5 11.0334 6.2 11.3334 5.83333 11.3334C5.46667 11.3334 5.16667 11.0334 5.16667 10.6667ZM8.5 10H11.1667C11.5333 10 11.8333 10.3 11.8333 10.6667C11.8333 11.0334 11.5333 11.3334 11.1667 11.3334H8.5C8.13333 11.3334 7.83333 11.0334 7.83333 10.6667C7.83333 10.3 8.13333 10 8.5 10ZM12.5 13.3334H4.5C4.13267 13.3334 3.83333 13.034 3.83333 12.6667V8.66671H13.1667V12.6667C13.1667 13.034 12.8673 13.3334 12.5 13.3334ZM4.5 4.00004H5.16667V4.66671C5.16667 5.03337 5.46667 5.33337 5.83333 5.33337C6.2 5.33337 6.5 5.03337 6.5 4.66671V4.00004H10.5V4.66671C10.5 5.03337 10.8 5.33337 11.1667 5.33337C11.5333 5.33337 11.8333 5.03337 11.8333 4.66671V4.00004H12.5C12.8673 4.00004 13.1667 4.29937 13.1667 4.66671V7.33337H3.83333V4.66671C3.83333 4.29937 4.13267 4.00004 4.5 4.00004ZM12.5 2.66671H11.8333V2.00004C11.8333 1.63337 11.5333 1.33337 11.1667 1.33337C10.8 1.33337 10.5 1.63337 10.5 2.00004V2.66671H6.5V2.00004C6.5 1.63337 6.2 1.33337 5.83333 1.33337C5.46667 1.33337 5.16667 1.63337 5.16667 2.00004V2.66671H4.5C3.39733 2.66671 2.5 3.56404 2.5 4.66671V12.6667C2.5 13.7694 3.39733 14.6667 4.5 14.6667H12.5C13.6027 14.6667 14.5 13.7694 14.5 12.6667V4.66671C14.5 3.56404 13.6027 2.66671 12.5 2.66671Z'
        />
      </mask>
      <g mask='url(#mask0_3266_23433)'>
        <rect x='0.5' width='16' height='16' />
      </g>
    </SvgIcon>
  );
});
