import { Grid2, Stack, SxProps, Theme, Typography } from "@mui/material";
import { useCallback, useState } from "react";

import EntityDataBlock, {
  EntityDataBlockProps,
} from "@/common/components/EntityData/EntityDataBlock";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  RepairSpecDataDto,
  RepairSpecDto,
  RepairSpecReferenceDto,
} from "@/core/api/generated";

import CurrencyValue from "../../Form/Display/CurrencyValue";
import GeneralCurrencyDisplay from "../General/Display/GeneralCurrencyDisplay";
import GeneralTaxDisplay from "../General/GeneralTax/GeneralTaxDisplay";

interface OwnProps extends Pick<EntityDataBlockProps, "withDetailsToggle" | "isDetailsVisible"> {
  repairSpec: RepairSpecDto | RepairSpecReferenceDto | RepairSpecDataDto | null | undefined;
  withLink?: boolean;
  withDetailsFetch?: boolean;
  briefDataProps?: {
    tempPlaceholder?: boolean;
  };
  detailedDataProps?: {
    tempPlaceholder?: boolean;
  };
  sx?: SxProps<Theme>;
}

// type Props =
//   | ({ variant: "full"; repairSpec: RepairSpecDto | null | undefined } & OwnProps)
//   | ({ variant: "reference"; repairSpec: RepairSpecReferenceDto | null | undefined } & OwnProps)
//   | ({ variant: "data"; repairSpec: RepairSpecDataDto | null | undefined } & OwnProps);
type Props = OwnProps;

const defaultBriefDataProps: Props["briefDataProps"] = {
  tempPlaceholder: true,
};
const defaultDetailedDataProps: Props["detailedDataProps"] = {
  tempPlaceholder: true,
};

/** RepairSpec info as block element. */
export default function BlockRepairSpec({
  repairSpec,
  withLink,
  withDetailsFetch = true,
  briefDataProps = defaultBriefDataProps,
  detailedDataProps = defaultDetailedDataProps,
  sx,
  ...entityDataBlockProps
}: Props) {
  briefDataProps = { ...defaultBriefDataProps, ...briefDataProps };
  detailedDataProps = {
    ...defaultDetailedDataProps,
    ...detailedDataProps,
  };

  const { hasPermissions } = useAuthorizationInfo();

  const [fetchedRepairSpec, setFetchedRepairSpec] = useState<RepairSpecDto | null>(null);

  const fullDto = (fetchedRepairSpec || repairSpec) as RepairSpecDto | undefined;
  // const referenceDto = repairSpec as RepairSpecReferenceDto | undefined;
  const dataDto = repairSpec as RepairSpecDataDto | undefined;

  const fetchRepairSpec = useCallback(async () => {
    if (repairSpec && !fetchedRepairSpec) {
      try {
        const response = await apiClient.repairSpecsApi.apiV1RepairSpecsRepairSpecIdGet({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          repairSpecId: repairSpec.id!,
        });
        setFetchedRepairSpec(response.data);
      } catch (err) {
        console.error(err);
      }
    }
  }, [repairSpec, fetchedRepairSpec]);

  if (!repairSpec) {
    return null;
  }

  return (
    <EntityDataBlock
      sx={{
        flex: 1,
        ...sx,
      }}
      {...entityDataBlockProps}
      onDetailsVisibilityChange={(isVisible) => {
        if (isVisible) {
          fetchRepairSpec();
        }
      }}
      to={
        withLink && hasPermissions([AppPermission.FleetAppAccess, AppPermission.RepairCatalogRead])
          ? ROUTE_PATH.REPAIR_SPEC_VIEW(repairSpec.id)
          : undefined
      }
      title={
        <>
          <AppIcon of='repairSpec' inText /> Repair spec
        </>
      }
      briefContent={
        <>
          <Typography component='div' variant='body1'>
            {repairSpec?.name}
          </Typography>
          {(fullDto?.description || dataDto?.description) && (
            <Typography component='div' variant='body2'>
              {fullDto?.description || dataDto?.description}
            </Typography>
          )}
        </>
      }
      detailedContent={
        <Grid2 container columnSpacing={2} rowSpacing={1}>
          <Grid2 size={{ xxs: 12 }} minWidth={200}>
            <Stack direction='column' spacing={1}>
              {repairSpec.localNumber && (
                <FieldValue
                  labelIcon={<AppIcon of='item' inText />}
                  withBorder={false}
                  size='compact'
                  valueAlight={{ horizontal: "end" }}
                  ellipsing={{ enabled: true }}
                  label='Global number'
                >
                  {repairSpec.globalNumber}
                </FieldValue>
              )}
              {repairSpec.localNumber && (
                <FieldValue
                  labelIcon={<AppIcon of='item' inText />}
                  withBorder={false}
                  size='compact'
                  valueAlight={{ horizontal: "end" }}
                  ellipsing={{ enabled: true }}
                  label='Local number'
                >
                  {repairSpec.localNumber}
                </FieldValue>
              )}
              <FieldValue
                labelIcon={<AppIcon of='item' inText />}
                withBorder={false}
                size='compact'
                valueAlight={{ horizontal: "end" }}
                ellipsing={{ enabled: true }}
                label='Name'
              >
                {repairSpec.name}
              </FieldValue>
              <FieldValue
                labelIcon={<AppIcon of='item' inText />}
                withBorder={false}
                size='compact'
                valueAlight={{ horizontal: "end" }}
                ellipsing={{ enabled: true }}
                label='Description'
              >
                {fullDto?.description || dataDto?.description}
              </FieldValue>
              {(fullDto?.currency || dataDto?.currency) && (
                <FieldValue
                  labelIcon={<AppIcon of='currency' inText />}
                  withBorder={false}
                  size='compact'
                  valueAlight={{ horizontal: "end" }}
                  ellipsing={{ enabled: true }}
                  label='Currency'
                >
                  <GeneralCurrencyDisplay currency={fullDto?.currency || dataDto?.currency} />
                </FieldValue>
              )}
              <FieldValue
                labelIcon={<AppIcon of='item' inText />}
                withBorder={false}
                size='compact'
                valueAlight={{ horizontal: "end" }}
                ellipsing={{ enabled: true }}
                label='Vehicle type'
              >
                <InlineApiEnumValue
                  type='VehicleType'
                  value={fullDto?.vehicleType || dataDto?.vehicleType}
                  withDescription
                />
              </FieldValue>
              <FieldValue
                labelIcon={<AppIcon of='vehiclePartType' inText />}
                withBorder={false}
                size='compact'
                valueAlight={{ horizontal: "end" }}
                ellipsing={{ enabled: true }}
                label='Part type'
              >
                {fullDto?.partType?.name || dataDto?.partType?.name}
              </FieldValue>
              <FieldValue
                labelIcon={<AppIcon of='vehicleDamage' inText />}
                withBorder={false}
                size='compact'
                valueAlight={{ horizontal: "end" }}
                ellipsing={{ enabled: true }}
                label='Damage type'
              >
                {fullDto?.damageType?.name || dataDto?.damageType?.name}
              </FieldValue>
              <FieldValue
                labelIcon={<AppIcon of='item' inText />}
                withBorder={false}
                size='compact'
                valueAlight={{ horizontal: "end" }}
                ellipsing={{ enabled: true }}
                label='Items'
              >
                {fullDto?.items?.length || dataDto?.items?.length || 0}
              </FieldValue>
              <FieldValue
                labelIcon={<AppIcon of='item' inText />}
                withBorder={false}
                size='compact'
                valueAlight={{ horizontal: "end" }}
                ellipsing={{ enabled: true }}
                label='Sub total'
              >
                <CurrencyValue
                  value={fullDto?.subTotal || dataDto?.subTotal}
                  currency={fullDto?.currency || dataDto?.currency}
                />
              </FieldValue>
              <FieldValue
                labelIcon={<AppIcon of='item' inText />}
                withBorder={false}
                size='compact'
                valueAlight={{ horizontal: "end" }}
                ellipsing={{ enabled: true }}
                label='Sub total'
              >
                <GeneralTaxDisplay
                  tax={fullDto?.tax || dataDto?.tax}
                  currency={fullDto?.currency || dataDto?.currency}
                />
              </FieldValue>
              <FieldValue
                labelIcon={<AppIcon of='item' inText />}
                withBorder={false}
                size='compact'
                valueAlight={{ horizontal: "end" }}
                ellipsing={{ enabled: true }}
                label='Total'
              >
                <CurrencyValue
                  value={fullDto?.total || dataDto?.total}
                  currency={fullDto?.currency || dataDto?.currency}
                />
              </FieldValue>
            </Stack>
          </Grid2>
        </Grid2>
      }
    />
  );
}
