import { Dialog, DialogProps } from "@mui/material";

import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AppModalContent from "@/common/components/Modals/AppModalContent";

import RepairWorkView, { RepairWorkViewProps } from "./RepairWorkView";

export interface OwnProps {
  viewProps: RepairWorkViewProps;
}

type Props = OwnProps & DialogProps;

export default function RepairWorkViewModal({ viewProps, ...dialogProps }: Props) {
  return (
    <Dialog fullWidth maxWidth='lg' {...dialogProps}>
      <AppModalContent>
        <RepairWorkView
          onDeleted={() => {
            dialogProps.onClose && dialogProps.onClose({}, "escapeKeyDown");
          }}
          {...viewProps}
          displayProps={{
            viewVariant: ViewLayoutVariant.Tab, // TODO: must be Modal
            ...viewProps?.displayProps,
          }}
        />
      </AppModalContent>
    </Dialog>
  );
}
