import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ArrayHelper } from "@/common/helpers/array";
import { useChatCurrentParticipant } from "@/common/hooks/communication/useChatCurrentParticipant";
import { useChatParticipants } from "@/common/hooks/communication/useChatParticipants";
import { useChatUserSetting } from "@/common/hooks/communication/useChatUserSetting";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import {
  ChatDto,
  ChatEventCategory,
  ChatEventDto,
  ChatEventType,
  ChatHistoryItemDto,
  ChatParticipantDto,
} from "@/core/api/generated";
import * as chatHistorySlice from "@/store/communication/chatHistorySlice";

import NoDataAlert from "../../AppAlerts/NoDataAlert";
import AppIcon from "../../Icons/AppIcon";
import InfiniteReverseScroll from "../../InfiniteScroll/InfiniteReverseScroll";
import ScrollIntoView from "../../Scroll/ScrollIntoView";
import ChatEvent from "../ChatEvent/ChatEvent";
import ChatMessage from "../ChatMessage/ChatMessage";
import ChatDateNavigationChip, { ChatDateNavigationItem } from "./ChatDateNavigationChip";

export interface OwnProps {
  chat: ChatDto;
  participants?: ChatParticipantDto[];
  autoScrollToHistoryEnd?: boolean;
  filters?: {
    // result = (eventCategories + includeEventCategories) - excludeEventCategories
    eventCategories?: ChatEventCategory[];
    includeEventCategories?: ChatEventCategory[];
    excludeEventCategories?: ChatEventCategory[];

    eventTypes?: ChatEventType[];
    includeEventTypes?: ChatEventType[];
    excludeEventTypes?: ChatEventType[];
  };
}

export type ChatHistoryProps = Pick<OwnProps, "autoScrollToHistoryEnd">;

type Props = OwnProps;

function ChatHistory({
  chat,
  participants,
  autoScrollToHistoryEnd = true,
  filters = {
    eventCategories: [ChatEventCategory.Participant],
    includeEventTypes: [
      ChatEventType.ChatCreated,
      ChatEventType.ChatAcknowledged,
      ChatEventType.NegotiationReopened,
      ChatEventType.NegotiationResolved,
    ],
    excludeEventTypes: [],
  },
}: Props) {
  const thunkDispatch = useAppThunkDispatch();

  const { participantsMap } = useChatParticipants(chat.id!);
  const chatUserSettings = useChatUserSetting({ chatId: chat.id! });
  const currentParticipant = useChatCurrentParticipant(chat.id);

  const paginatedChatHistory = useAppSelector(
    (x) => x.communication.chatHistory.paginatedChatHistory[chat.id!],
  );
  const isPaginatedChatHistoryLoading = useAppSelector(
    (x) => x.communication.chatHistory.loading.getPaginatedHistory![chat.id!],
  );
  const openedChatInfo = useAppSelector((x) =>
    x.communication.chats.openedChats?.find((y) => y.chatId === chat.id!),
  );

  const isScrolledOnInit = useRef<boolean>(false);
  const scrollParentRef = useRef(null);

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(30);
  const limitIncreaseStep = 30;

  // const [shouldScrollToStart, setShouldScrollToStart] = useState(false);
  const [shouldScrollToEnd, setShouldScrollToEnd] = useState(false);

  const messages = useMemo(
    () => paginatedChatHistory?.items?.filter((x) => x.message) || null,
    [paginatedChatHistory],
  );
  const isAnyMessages = !!messages;

  const navigationDateAnchors = useMemo(() => {
    const accumulator = new Map<string, ChatDateNavigationItem>();
    if (paginatedChatHistory?.items) {
      for (let index = 0; index < paginatedChatHistory.items.length - 1; index++) {
        const current = paginatedChatHistory.items[index];
        const next = paginatedChatHistory.items[index + 1];

        if (
          moment(current.date).startOf("day").diff(moment(next.date).startOf("day"), "days") !== 0
        ) {
          accumulator.set(next.id!, { afterHistoryItem: next });
        }
      }
    }
    return accumulator;
  }, [paginatedChatHistory]);

  const getChatHistory = useCallback(
    (...args: Parameters<typeof chatHistorySlice.getChatHistory>) =>
      thunkDispatch(chatHistorySlice.getChatHistory(...args)),
    [],
  );
  const getChatHistoryThrottle = useCallback(
    _.throttle(getChatHistory, 1000, { leading: true, trailing: false }),
    [getChatHistory],
  );

  useEffect(() => {
    if (chat.id && limit !== 0) {
      getChatHistoryThrottle({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
        chatId: chat.id!,
        chatHistoryGetPaginatedDto: {
          offset,
          limit,
          event: {
            categories: filters.eventCategories,
            includeCategories: filters.includeEventCategories,
            excludeCategories: filters.excludeEventCategories,
            types: filters.eventTypes,
            includeTypes: filters.includeEventTypes,
            excludeTypes: filters.excludeEventTypes,
          },
        },
      });
    }
  }, [chat.id, offset, limit]);

  // auto-scroll to end on changes
  useEffect(() => {
    // - don't scroll if disabled
    // - always scroll if user enters message or message input is focused
    if (
      (autoScrollToHistoryEnd ||
        !isScrolledOnInit.current ||
        openedChatInfo?.isEnterMessageAreaFocused) &&
      paginatedChatHistory &&
      paginatedChatHistory.items!.length !== 0
    ) {
      setShouldScrollToEnd(true);
      isScrolledOnInit.current = true;
    }
  }, [autoScrollToHistoryEnd, paginatedChatHistory, openedChatInfo?.isEnterMessageAreaFocused]);

  const filtersMap = useMemo(() => {
    return {
      eventCategories: ArrayHelper.arrayToMap(filters.eventCategories),
      includeEventCategories: ArrayHelper.arrayToMap(filters.includeEventCategories),
      excludeEventCategories: ArrayHelper.arrayToMap(filters.excludeEventCategories),
      eventTypes: ArrayHelper.arrayToMap(filters.eventTypes),
      includeEventTypes: ArrayHelper.arrayToMap(filters.includeEventTypes),
      excludeEventTypes: ArrayHelper.arrayToMap(filters.excludeEventTypes),
    };
  }, [filters]);

  const shouldShowEvent = useCallback(
    (event: ChatEventDto) => {
      const main =
        (filtersMap.eventCategories ? filtersMap.eventCategories[event.category!] : true) &&
        (filtersMap.eventTypes ? filtersMap.eventTypes[event.type!] : true);

      const include =
        (filtersMap.includeEventCategories
          ? filtersMap.includeEventCategories[event.category!]
          : false) ||
        (filtersMap.includeEventTypes ? filtersMap.includeEventTypes[event.type!] : false);

      const exclude =
        (filtersMap.excludeEventCategories
          ? !filtersMap.excludeEventCategories[event.category!]
          : true) &&
        (filtersMap.excludeEventTypes ? !filtersMap.excludeEventTypes[event.type!] : true);

      const enabledLocal = ((main || include) && exclude) || false;
      const enabledInSettings = _.isNil(chatUserSettings?.settings)
        ? true
        : chatUserSettings.settings.event!.disabledCategoriesMap![event.category!] !== true &&
          chatUserSettings.settings.event!.disabledTypesMap![event.type!] !== true;

      return _.isNil(chatUserSettings?.settings) ? enabledLocal : enabledInSettings;
    },
    [chatUserSettings, filtersMap],
  );

  const handleLoadMore = useCallback(() => {
    if (!isPaginatedChatHistoryLoading) {
      setOffset(0);
      setLimit(limit + limitIncreaseStep);
    }
  }, [limit]);

  // mention: changed back to LinearProgress because it breaks chat infinite scroll logic, as component renders absolutely new
  // data (ChatHistorySkeleton instead of whole chat history)
  // if (isPaginatedChatHistoryLoading) {
  //   return <ChatHistorySkeleton itemCount={3} />;
  // }

  return (
    <Stack direction='column' sx={{ width: "100%", position: "relative" }}>
      {isPaginatedChatHistoryLoading && (
        <Box sx={{ mb: 1, position: "absolute", top: 0, width: "100%" }}>
          <LinearProgress color='primary' />
        </Box>
      )}
      <Stack
        direction='column'
        spacing={1}
        ref={scrollParentRef}
        sx={{ flex: 1, overflow: "auto" }}
      >
        {_.isEmpty(paginatedChatHistory?.items) && <NoDataAlert title='Nothing here yet.' />}

        {paginatedChatHistory && (
          <InfiniteReverseScroll
            ref={scrollParentRef}
            isLoading={!!isPaginatedChatHistoryLoading}
            items={paginatedChatHistory.items!.filter((item, i) => {
              const shouldBeRendered = item.event ? shouldShowEvent(item.event) : true;
              // temporary don't show proposals
              return shouldBeRendered && !item.negotiationProposal;
            })}
            hasNextPage={
              !paginatedChatHistory ||
              (paginatedChatHistory &&
                paginatedChatHistory.pagination!.limit! <
                  paginatedChatHistory.pagination!.totalCount!)
            }
            loadMore={handleLoadMore}
            renderItem={(item: ChatHistoryItemDto) => {
              const participantId =
                item.participantId || item.event?.participantId || item.message?.participantId;
              const participant = participantsMap[participantId || ""];
              const anchor = navigationDateAnchors.get(item.id!);

              return (
                <React.Fragment key={item.id}>
                  {anchor && (
                    <Box
                      key={`${item.id}:anchor`}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <ChatDateNavigationChip date={item.date} />
                    </Box>
                  )}
                  <Box key={item.id}>
                    {item.event && (
                      <ChatEvent
                        event={item.event}
                        participant={participant}
                        // variantForTypes={{
                        //   variant: "divider",
                        //   types: {
                        //     [ChatEventType.NegotiationOpened]: true,
                        //     [ChatEventType.NegotiationReopened]: true,
                        //     [ChatEventType.NegotiationResolved]: true,
                        //   },
                        // }}
                      />
                    )}

                    {item.message && (
                      <ChatMessage
                        chat={chat}
                        item={item}
                        chatSettings={chat.settings}
                        currentParticipant={currentParticipant}
                        participant={participant}
                        participants={participants}
                      />
                    )}

                    {/* {item.negotiationProposal && (
                    <ChatNegotiationProposalContainer
                      item={item}
                      chat={chat}
                      chatSettings={chat.settings!}
                    />
                  )} */}
                  </Box>
                </React.Fragment>
              );
            }}
          />
        )}

        <ScrollIntoView
          shouldScroll={shouldScrollToEnd}
          scrollOptions={{
            behavior: isScrolledOnInit.current ? "smooth" : "auto",
          }}
          scrollDelayMs={50}
          onScrollEnd={() => setShouldScrollToEnd(false)}
        />

        {!isAnyMessages && !isPaginatedChatHistoryLoading && (
          <Stack
            direction='column'
            color='text.secondary'
            sx={{ alignItems: "center", px: 4, py: 2 }}
          >
            <AppIcon of='message' />
            <Typography component='div'>No messages in this chat</Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

export default ChatHistory;
