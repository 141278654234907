import { Typography } from "@mui/material";

import InlineCode from "@/common/components/Code/InlineCode";
import AppTypography from "@/common/components/Text/AppTypography";
import HorizontalTimeline from "@/common/components/Timeline/HorizontalTimeline";
import VerticalTimeline from "@/common/components/Timeline/VerticalTimeline";

import ViewContentBlock from "../Layouts/ViewContentBlock";

const DevTimelines = () => {
  return (
    <>
      <Typography variant='h4' sx={{ my: 2 }}>
        Timelines
      </Typography>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>HorizontalTimeline</InlineCode>
      </Typography>

      <ViewContentBlock sx={{ mt: 2 }}>
        <HorizontalTimeline
          items={[
            {
              content: "Draft",
              active: true,
            },
            {
              content: "Pending",
              active: true,
            },
            {
              content: "Accepted",
            },
            {
              content: "In progress",
            },
            {
              content: "Completed",
            },
            {
              content: "Reporting",
            },
            {
              content: "Closed",
            },
          ]}
        />
      </ViewContentBlock>

      <ViewContentBlock sx={{ mt: 2 }}>
        <HorizontalTimeline
          items={[
            {
              content: "Draft",
            },
            {
              content: "Pending",
            },
            {
              content: "Accepted",
            },
            {
              content: "In progress",
            },
            {
              content: "Completed",
            },
            {
              content: "Reporting",
            },
            {
              content: "Closed",
            },
          ]}
        />
      </ViewContentBlock>

      <ViewContentBlock sx={{ mt: 2 }}>
        <HorizontalTimeline
          items={[
            {
              content: "Draft",
              active: true,
            },
            {
              content: "Pending",
              active: true,
            },
            {
              content: "Accepted",
              active: true,
            },
            {
              content: "In progress",
              active: true,
            },
            {
              content: "Completed",
              active: true,
            },
            {
              content: "Reporting",
              active: true,
            },
            {
              content: "Closed",
              active: true,
            },
          ]}
        />
      </ViewContentBlock>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>VerticalTimeline</InlineCode>
      </Typography>

      <ViewContentBlock>
        <VerticalTimeline
          items={[
            {
              title: "Draft",
              content: (
                <>
                  <ViewContentBlock
                    variant='one-sided'
                    direction='row'
                    justifyContent='space-between'
                  >
                    Status description
                    <AppTypography>13-12-2023 13:00</AppTypography>
                  </ViewContentBlock>

                  <AppTypography variant='body2' sx={{ px: 2, mt: 0.5 }}>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industrys standard dummy text ever since the 1500s, when an
                    unknown printer took a galley of type and scrambled
                  </AppTypography>
                </>
              ),
              active: true,
            },
            {
              title: "Pending",
              content: (
                <>
                  <ViewContentBlock variant='one-sided'>Status description</ViewContentBlock>
                </>
              ),
              active: true,
            },
            {
              title: "Accepted",
              content: (
                <>
                  <ViewContentBlock variant='one-sided'>Status description</ViewContentBlock>
                </>
              ),
            },
            {
              title: "In progress",
              content: (
                <>
                  <ViewContentBlock variant='one-sided'>Status description</ViewContentBlock>
                </>
              ),
            },
            {
              title: "Completed",
              content: (
                <>
                  <ViewContentBlock variant='one-sided'>Status description</ViewContentBlock>
                </>
              ),
            },
            {
              title: "Reporting",
              content: (
                <>
                  <ViewContentBlock variant='one-sided'>Status description</ViewContentBlock>
                </>
              ),
            },
            {
              title: "Closed",
              content: (
                <>
                  <ViewContentBlock variant='one-sided'>Status description</ViewContentBlock>
                </>
              ),
            },
          ]}
        />
      </ViewContentBlock>

      <ViewContentBlock sx={{ mt: 2 }}>
        <VerticalTimeline
          items={[
            {
              title: "Draft",
              content: (
                <>
                  <ViewContentBlock variant='one-sided'>Status description</ViewContentBlock>

                  <AppTypography variant='body2' sx={{ px: 2, mt: 0.5 }}>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industrys standard dummy text ever since the 1500s, when an
                    unknown printer took a galley of type and scrambled
                  </AppTypography>
                </>
              ),
            },
            {
              title: "Pending",
              content: (
                <>
                  <ViewContentBlock variant='one-sided'>Status description</ViewContentBlock>
                </>
              ),
            },
          ]}
        />
      </ViewContentBlock>

      <ViewContentBlock sx={{ mt: 2 }}>
        <VerticalTimeline
          items={[
            {
              title: "Draft",
              content: (
                <>
                  <ViewContentBlock
                    variant='one-sided'
                    direction='row'
                    justifyContent='space-between'
                  >
                    Status description
                    <AppTypography>13-12-2023 13:00</AppTypography>
                  </ViewContentBlock>

                  <AppTypography variant='body2' sx={{ px: 2, mt: 0.5 }}>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industrys standard dummy text ever since the 1500s, when an
                    unknown printer took a galley of type and scrambled
                  </AppTypography>
                </>
              ),
              active: true,
            },
            {
              title: "Pending",
              content: (
                <>
                  <ViewContentBlock
                    variant='one-sided'
                    direction='row'
                    justifyContent='space-between'
                  >
                    Status description
                    <AppTypography>13-12-2023 13:00</AppTypography>
                  </ViewContentBlock>
                </>
              ),
              active: true,
            },
          ]}
        />
      </ViewContentBlock>
    </>
  );
};

export default DevTimelines;
