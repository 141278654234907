export class CustomArrayExtensions<T> {
  private readonly thisRef: Array<T>;

  constructor(thisRef: Array<T>) {
    this.thisRef = thisRef;
  }

  public getTestMessage(message?: string): string {
    return `Test message: ${message ?? "-"}. Instance: ${this.thisRef}.`;
  }

  public filterIf(
    condition: boolean,
    predicate: (value: T, index: number, array: T[]) => boolean,
    thisArg?: any,
  ): Array<T> {
    return condition ? this.thisRef.filter(predicate, thisArg) : this.thisRef;
  }

  public concat(items: Array<T>): Array<T> {
    return this.thisRef.concat(items);
  }

  public concatIf(condition: boolean, items: Array<T>): Array<T> {
    return condition ? this.thisRef.concat(items) : this.thisRef;
  }

  public concatItemIf(condition: boolean, item: T): Array<T> {
    return condition ? this.thisRef.concat([item]) : this.thisRef;
  }
}
