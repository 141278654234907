import { Button, FormControl, Stack } from "@mui/material";
import { useFormik } from "formik";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import FileUploader from "@/common/components/Files/FileUploader";
import { FileItem } from "@/common/fileItem";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { AttachmentsUpdateDto, VehicleDto } from "@/core/api/generated";

interface Props {
  vehicle: VehicleDto;
  onVehicleUpdated: (vehicle?: VehicleDto) => any | void;
}
export default function AttachmentsTabContent({ vehicle, onVehicleUpdated }: Props) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();

  const formik = useFormik<
    AttachmentsUpdateDto & {
      initialAttachments: VehicleDto["attachments"];
      uploadedAttachments?: FileItem[];
      submit: string;
    }
  >({
    enableReinitialize: true,
    initialValues: {
      initialAttachments: vehicle?.attachments || undefined,
      attachments:
        vehicle?.attachments?.map((a) => ({
          caption: a.caption,
          notes: a.notes,
          file: {
            id: a.file?.id,
          },
        })) || undefined,
      submit: "",
    },
    onSubmit: async (values, { setSubmitting, setStatus, setFieldError }) => {
      try {
        const response = await apiClient.vehiclesApi.apiV1VehiclesVehicleIdAttachmentsPut({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          vehicleId: vehicle.id!,
          attachmentsUpdateDto: values,
        });
        onVehicleUpdated && onVehicleUpdated(response.data);
        enqueueSnackbar("Vehicle attachments updated", { variant: "success" });

        if (mounted.current) {
          setStatus({ success: true });
          setSubmitting(false);
        }
      } catch (err: any) {
        if (mounted.current) {
          ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
          setStatus({ success: false });
          setSubmitting(false);
        }
      }
    },
  });

  const { values, errors, isSubmitting, setFieldValue, handleSubmit } = formik;

  return (
    <PageTabContent>
      <ViewContentBlock>
        <PageTabHeader
          title={"Attachments"}
          subtitle2={<EntityCreatedByInfoDisplay entity={vehicle} />}
        />

        <FormControl fullWidth margin='dense'>
          <FileUploader
            multiple
            defaultFiles={FileItem.createManyFrom(
              values.uploadedAttachments || values.initialAttachments || values.attachments,
            )}
            onChange={(newFiles) => {
              setFieldValue("attachments", FileItem.toManyGeneralAttachmentInputDto(newFiles));
              setFieldValue("uploadedAttachments", newFiles);
            }}
            fileUploadListProps={{
              itemActions: (item) => ({
                enabled: true,
                setCaption: true,
                onSetCaption: (caption) => {
                  setFieldValue(
                    "attachments",
                    values.attachments?.map((a) =>
                      a.file?.id === item.id ? { ...a, caption } : a,
                    ),
                  );
                },
              }),
            }}
          />
        </FormControl>

        <GeneralValidationError sx={{ my: 1 }} errors={errors} />

        <Stack direction='row' justifyContent='end'>
          <Button
            variant='contained'
            loading={isSubmitting}
            onClick={() => handleSubmit()}
            sx={{ width: 150 }}
          >
            Save
          </Button>
        </Stack>
      </ViewContentBlock>
    </PageTabContent>
  );
}
