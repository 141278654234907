import { Box, Stack } from "@mui/material";

import { FileItem } from "@/common/fileItem";

import FileItemView, { FileItemViewActions } from "./FileItemView";

export interface FileUploadListProps {
  files: FileItem[];
  itemActions?: (file: FileItem) => FileItemViewActions;
}

/** List of uploaded files. */

export default function FileUploadList({ files, itemActions }: FileUploadListProps) {
  const itemWidth = 100;
  const itemHeight = 60;

  return (
    <Box>
      <Stack direction='row' gap={1} sx={{ alignItems: "center", flexWrap: "wrap" }}>
        {files?.map((file, i) => (
          <Box key={i}>
            <FileItemView
              fileItem={file}
              size={{
                width: itemWidth,
                height: itemHeight,
              }}
              actions={itemActions?.(file)}
              imageSx={{
                width: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        ))}
      </Stack>
    </Box>
  );
}
