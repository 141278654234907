import { Button, Checkbox, FormControl, FormControlLabel, Stack } from "@mui/material";
import { useMemo, useState } from "react";

import { RepairSpecHelper } from "@/common/helpers/entity/repairSpec";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { RepairSpecDto } from "@/core/api/generated";

import AppTooltip from "../../AppTooltip";
import AppIcon from "../../Icons/AppIcon";
import AppPopoverActions from "../../Popover/AppPopoverActions";
import AppTypography from "../../Text/AppTypography";
import TenantAutocomplete from "../Tenant/TenantAutocomplete";
import RepairSpecAutocomplete, { RepairSpecAutocompleteProps } from "./RepairSpecAutocomplete";

interface RepairSpecCrossTenantSelection {
  tenantId: string;
  repairSpecId: string;
  repairSpec: RepairSpecDto;
  isExternal: boolean;
}

interface OwnProps {
  repairSpecAutocompleteProps?: Partial<RepairSpecAutocompleteProps>;
  onSave: (newValue: Nil<RepairSpecCrossTenantSelection>) => void;
  onCancel: () => void;
}

export type RepairSpecCrossTenantSelectorProps = OwnProps;

export default function RepairSpecCrossTenantSelector({
  repairSpecAutocompleteProps,
  onSave,
  onCancel,
}: RepairSpecCrossTenantSelectorProps) {
  const currentTenant = useCurrentTenant();

  const repairSpecLocalSettings = useMemo(() => RepairSpecHelper.getLocalSettings(), []);

  const [defaultTenantId, setDefaultTenantId] = useState<string | undefined>(
    repairSpecLocalSettings?.defaultTenantIdForCrossTenant || undefined,
  );
  const isUseDefaultTenant = !!defaultTenantId && currentTenant?.id !== defaultTenantId;

  const [tenantId, setTenantId] = useState<string | undefined>(
    isUseDefaultTenant ? defaultTenantId || undefined : undefined,
  );
  const [isUseTheTenantByDefault, setIsUseTheTenantByDefault] =
    useState<boolean>(isUseDefaultTenant);
  const [repairSpecId, setRepairSpecId] = useState<string | undefined>(undefined);
  const [repairSpec, setRepairSpec] = useState<RepairSpecDto | undefined>(undefined);

  const handleSave = () => {
    const newRepairSpec = {
      ...repairSpec,
      tenantId: repairSpec?.tenantId || tenantId,
    };
    const newValue: Nil<RepairSpecCrossTenantSelection> =
      tenantId && repairSpecId && repairSpec
        ? {
            tenantId,
            repairSpecId,
            repairSpec: newRepairSpec,
            isExternal: RepairSpecHelper.isExternal({
              tenantId: currentTenant?.id,
              repairSpec: newRepairSpec,
            }),
          }
        : undefined;
    onSave(newValue);
  };

  if (!currentTenant?.id) {
    return null;
  }

  return (
    <Stack>
      <AppTooltip title='Find and use your repair specs across your companies.'>
        <AppTypography variant='subtitle1' decoration={{ variant: "helpText" }}>
          My cross-company repair specs
        </AppTypography>
      </AppTooltip>

      <FormControl margin='normal' fullWidth>
        <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
          <TenantAutocomplete
            sx={{ flex: 1 }}
            entityId={tenantId}
            searchFilters={{
              isMy: true,
              excludeIds: [currentTenant.id],
            }}
            required
            label='Company'
            size='small'
            onChange={(newValue) => {
              setTenantId(newValue?.id);
              setIsUseTheTenantByDefault(!!newValue && newValue?.id === defaultTenantId);
              setRepairSpecId(undefined);
              setRepairSpec(undefined);
            }}
          />

          <AppTooltip title='Use this company by default'>
            <FormControlLabel
              label=''
              disabled={!tenantId}
              control={
                <Checkbox
                  icon={<AppIcon of='bookmarkBorder' />}
                  checkedIcon={<AppIcon of='bookmark' />}
                  checked={isUseTheTenantByDefault}
                  onChange={(e) => {
                    const isUse = e.target.checked;
                    setIsUseTheTenantByDefault(isUse);

                    const newDefaultTenantId = isUse ? tenantId : undefined;
                    setDefaultTenantId(newDefaultTenantId);
                    RepairSpecHelper.saveLocalSettings({
                      ...repairSpecLocalSettings,
                      defaultTenantIdForCrossTenant: newDefaultTenantId,
                    });
                  }}
                />
              }
            />
          </AppTooltip>
        </Stack>
      </FormControl>

      <FormControl margin='normal' fullWidth>
        <RepairSpecAutocomplete
          {...repairSpecAutocompleteProps}
          entityId={repairSpecId}
          searchFilters={{
            ...repairSpecAutocompleteProps?.searchFilters,
            myTenantId: tenantId,
          }}
          requestConfig={{
            skip: !tenantId,
          }}
          required
          disabled={!tenantId}
          withCreate={false}
          label='Repair spec'
          size='small'
          onChange={(newValue) => {
            setRepairSpecId(newValue?.id);
            setRepairSpec(newValue);
          }}
        />
      </FormControl>

      <AppPopoverActions sx={{ mt: 2 }}>
        <Button color='inherit' variant='contained' onClick={onCancel}>
          Cancel
        </Button>

        <Button color='primary' variant='contained' onClick={handleSave}>
          Save
        </Button>
      </AppPopoverActions>
    </Stack>
  );
}
