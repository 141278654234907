import _ from "lodash";
import { useCallback, useEffect } from "react";

/** Runs provided callback once on mount. */
export const useRunOnceOnMount = (callback: () => void, options?: { delayMs?: number }): void => {
  const _callback = useCallback(
    _.debounce(
      () => {
        if (!_.isNil(options?.delayMs) && options!.delayMs! > 0) {
          setTimeout(callback, options?.delayMs);
        } else {
          callback();
        }
      },
      5000,
      { leading: true, trailing: false },
    ),
    [callback, options],
  );

  useEffect(() => {
    _callback();
  }, []);
};
