import { Box, Popover, PopoverProps, Stack } from "@mui/material";

import { EntityChangedByInfoFromDto } from "@/common/ts/dto";

import FieldValue from "../Form/Display/FieldValue";
import EntityCreatedByInfoDisplay from "./EntityCreatedByInfoDisplay";
import EntityUpdatedByInfoDisplay from "./EntityUpdatedByInfoDisplay";

type Entity = EntityChangedByInfoFromDto & Record<string, any>;

export interface OwnProps {
  entity: Entity | null | undefined;
}

type Props = OwnProps & PopoverProps;

/** Shows who changed the entity (created, updated, deleted). */
export default function EntityChangedByPopover({ entity, ...popoverProps }: Props) {
  return (
    <Popover
      sx={{
        pointerEvents: "none",
      }}
      disableRestoreFocus
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      {...popoverProps}
    >
      <Box sx={{ minWidth: "200px", maxWidth: { sm: "95vw", md: "400px" }, p: 2 }}>
        <Stack direction='column' spacing={1}>
          <FieldValue label='Created' isEmpty={!entity?.createdAt}>
            <EntityCreatedByInfoDisplay entity={entity} />
          </FieldValue>
          <FieldValue label='Updated' isEmpty={!entity?.updatedAt}>
            <EntityUpdatedByInfoDisplay entity={entity} />
          </FieldValue>
        </Stack>
      </Box>
    </Popover>
  );
}
