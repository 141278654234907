import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useHistory, useParams } from "react-router";

import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import IntegrationApiClientCreateUpdateKeyModal from "@/common/components/Entity/IntegrationApiClient/IntegrationApiClientCreateUpdateKeyModal";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import EntityUpdatedByInfoDisplay from "@/common/components/EntityInfo/EntityUpdatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import TextFieldForSecret from "@/common/components/Form/Input/TextFieldForSecret";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import AppMenuItem from "@/common/components/Menu/AppMenuItem";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  IntegrationApiClientKeyDto,
  IntegrationApiKeyStatus,
} from "@/core/api/generated";

export default function IntegrationApiClientViewPage() {
  const { integrationApiClientId } = useParams<{ integrationApiClientId?: string }>();
  const history = useHistory();
  const { enqueueSnackbar } = useAppSnackbar();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCreateUpdateKeyModalOpen, setIsCreateUpdateKeyModalOpen] = useState(false);
  const [isRevokeKeyModalOpen, setIsRevokeKeyModalOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState<IntegrationApiClientKeyDto | undefined>(undefined);

  const integrationApiClientRequest = useApiRequest(
    apiClient.adminIntegrationApiClientsApi
      .apiV1AdminIntegrationApiClientsIntegrationApiClientIdGet,
    {
      tenantId: undefined,
      integrationApiClientId: integrationApiClientId!,
    },
    {
      deps: [integrationApiClientId],
      skip: !integrationApiClientId,
    },
  );
  const integrationApiClient = integrationApiClientRequest?.data;

  const handleDeleteConfirmed = useCallback(async () => {
    if (!integrationApiClient) {
      return;
    }
    try {
      await apiClient.adminIntegrationApiClientsApi.apiV1AdminIntegrationApiClientsIntegrationApiClientIdDelete(
        {
          tenantId: integrationApiClient!.tenantId!,
          integrationApiClientId: integrationApiClient.id!,
        },
      );
      enqueueSnackbar("API client was deleted.", { variant: "success" });
      setIsDeleteModalOpen(false);
      history.goBack();
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [integrationApiClient]);

  const handleRevokeKeyConfirmed = useCallback(async () => {
    if (!integrationApiClient || !selectedKey) {
      return;
    }
    try {
      const response =
        await apiClient.adminIntegrationApiClientsApi.apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost(
          {
            tenantId: undefined,
            integrationApiClientId: integrationApiClient.id!,
            keyId: selectedKey.id!,
          },
        );
      enqueueSnackbar("API Key was revoked.", { variant: "success" });
      setIsRevokeKeyModalOpen(false);
      integrationApiClientRequest.replaceData(response.data);
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [integrationApiClient, selectedKey]);

  if (integrationApiClientRequest.isLoading) {
    return <LinearProgress />;
  }
  if (!integrationApiClient && integrationApiClientRequest.isFirstEnded) {
    return <EntityNotFoundAlert />;
  }

  return (
    <ViewPageLayout
      breadcrumbs={{
        replacements: {
          isWaitInfinitely: true,
          idBreadcrumb: integrationApiClient && {
            idValue: integrationApiClient.id!,
            newTitle: integrationApiClient.localNumber || "",
          },
        },
      }}
      header={
        <DetailedViewPageHeader
          image={undefined}
          title={`API Client ${integrationApiClient?.name} (${integrationApiClient?.localNumber})`}
          primaryActions={
            <AuthorizedElement permissions={[AppPermission.IntegrationApiClientManage]}>
              <Button
                component={AppLink}
                to={ROUTE_PATH.MANAGEMENT_INTEGRATION_API_CLIENT_EDIT(integrationApiClient?.id)}
                variant='outlined'
                color='text'
                size='medium'
                startIcon={<AppIcon of='edit' />}
              >
                Edit
              </Button>
            </AuthorizedElement>
          }
          secondaryActions={
            integrationApiClient && (
              <MenuWithTrigger
                withAuthCloseOnClick
                trigger={
                  <IconButton sx={{ ml: "auto" }}>
                    <AppIcon of='moreVert' />
                  </IconButton>
                }
              >
                <AppMenuItem
                  permissions={[AppPermission.IntegrationApiClientManage]}
                  onClick={() => {
                    setIsDeleteModalOpen(true);
                  }}
                >
                  <ListItemIcon>
                    <AppIcon of='delete' fontSize='small' color='error' />
                  </ListItemIcon>
                  <ListItemText>Delete</ListItemText>
                </AppMenuItem>
              </MenuWithTrigger>
            )
          }
        />
      }
    >
      {integrationApiClient && (
        <Stack spacing={2}>
          <Stack spacing={1}>
            <FieldValue label='Company' isEmpty={!integrationApiClient?.tenantId}>
              {integrationApiClient?.tenantId}
            </FieldValue>

            <FieldValue label='Scope' isEmpty={!integrationApiClient?.scope?.type}>
              <InlineApiEnumValue
                type='IntegrationApiScopeType'
                value={integrationApiClient.scope?.type}
                direction='column'
                withDescription
              />
            </FieldValue>

            <FieldValue label='Name' isEmpty={!integrationApiClient?.name}>
              {integrationApiClient?.name}
            </FieldValue>

            <FieldValue label='Description' isEmpty={!integrationApiClient?.description}>
              {integrationApiClient?.description}
            </FieldValue>

            <FieldValue label='Expires at' isEmpty={!integrationApiClient.expiresAt}>
              <Datetime datetime={integrationApiClient.expiresAt} withDurationFromNow />
            </FieldValue>

            <FieldValue label='Created' isEmpty={!integrationApiClient.createdAt}>
              <EntityCreatedByInfoDisplay entity={integrationApiClient} />
            </FieldValue>

            <FieldValue label='Updated' isEmpty={!integrationApiClient.updatedAt}>
              <EntityUpdatedByInfoDisplay entity={integrationApiClient} />
            </FieldValue>
          </Stack>

          {/* Keys */}
          <Stack spacing={1}>
            <Typography variant='h4'>API keys</Typography>

            <DataTabular
              columns={[
                {
                  field: "name",
                  title: "Name",
                  flex: 1,
                  renderCell: (item) => <>{item.name}</>,
                },
                {
                  field: "status",
                  title: "Status",
                  flex: 1,
                  renderCell: (item) => (
                    <InlineApiEnumValue type='IntegrationApiKeyStatus' value={item.status} />
                  ),
                },
                {
                  field: "expiresAt",
                  title: "Expires at",
                  flex: 1,
                  renderCell: (item) => <Datetime datetime={item.expiresAt} />,
                },
                {
                  field: "revokedAt",
                  title: "Revoked at",
                  flex: 1,
                  renderCell: (item) => <Datetime datetime={item.revokedAt} />,
                },
                {
                  field: "expiredAt",
                  title: "Expired at",
                  flex: 1,
                  renderCell: (item) => <Datetime datetime={item.expiredAt} />,
                },
                {
                  field: "value",
                  title: "Value",
                  flex: 1.5,
                  renderCell: (item) => (
                    <TextFieldForSecret
                      size='small'
                      value='API Key value placeholder'
                      onViewValueClick={async () => {
                        const response =
                          await apiClient.adminIntegrationApiClientsApi.apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet(
                            {
                              tenantId: integrationApiClient.tenantId || "",
                              integrationApiClientId: integrationApiClient.id!,
                              keyId: item.id!,
                            },
                          );
                        return { value: response.data.keyValue || "" };
                      }}
                    />
                  ),
                },
              ]}
              rows={integrationApiClient?.keys}
              getRowId={(item) => item.id!}
              renderRowAction={(actionParams) => (
                <MenuWithTrigger
                  withAuthCloseOnClick
                  trigger={
                    <IconButton>
                      <AppIcon of='moreVert' />
                    </IconButton>
                  }
                >
                  <AppMenuItem
                    permissions={[AppPermission.IntegrationApiClientManage]}
                    disabled={actionParams.item.status === IntegrationApiKeyStatus.Revoked}
                    onClick={() => {
                      setSelectedKey(actionParams.item);
                      setIsCreateUpdateKeyModalOpen(true);
                    }}
                  >
                    <ListItemIcon>
                      <AppIcon of='edit' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                  </AppMenuItem>

                  <AppMenuItem
                    permissions={[AppPermission.IntegrationApiClientManage]}
                    disabled={actionParams.item.status === IntegrationApiKeyStatus.Revoked}
                    onClick={() => {
                      setSelectedKey(actionParams.item);
                      setIsRevokeKeyModalOpen(true);
                    }}
                  >
                    <ListItemIcon>
                      <AppIcon of='cancel' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Revoke</ListItemText>
                  </AppMenuItem>
                </MenuWithTrigger>
              )}
            />

            <Box>
              <Button
                variant='outlined'
                color='primary'
                startIcon={<AppIcon of='add' />}
                onClick={() => {
                  setSelectedKey(undefined);
                  setIsCreateUpdateKeyModalOpen(true);
                }}
              >
                Create new API Key
              </Button>
            </Box>
          </Stack>
        </Stack>
      )}

      {/* Delete */}
      {integrationApiClient && (
        <ConfirmationModal
          title='Delete the API client?'
          body={
            <>
              {`You're going to delete the API client `}
              <strong>{integrationApiClient.name}</strong>.{` This action can't be undone.`}
            </>
          }
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onCancel={() => setIsDeleteModalOpen(false)}
          onConfirm={handleDeleteConfirmed}
        />
      )}

      {/* Edit key */}
      {integrationApiClient && (
        <IntegrationApiClientCreateUpdateKeyModal
          open={isCreateUpdateKeyModalOpen}
          onClose={() => setIsCreateUpdateKeyModalOpen(false)}
          createUpdateProps={{
            integrationApiClient: integrationApiClient,
            clientKey: selectedKey,
            onSave: (newValue) => {
              setIsCreateUpdateKeyModalOpen(false);
              integrationApiClientRequest.replaceData(newValue);
            },
          }}
        />
      )}

      {/* Revoke key */}
      {integrationApiClient && selectedKey && (
        <ConfirmationModal
          title='Revoke the API Key?'
          body={
            <>
              {`If you proceed this API key stop work and existing code clients using it won't be able to call the NexusOps API.`}
            </>
          }
          open={isRevokeKeyModalOpen}
          onClose={() => setIsRevokeKeyModalOpen(false)}
          onCancel={() => setIsRevokeKeyModalOpen(false)}
          onConfirm={handleRevokeKeyConfirmed}
        />
      )}
    </ViewPageLayout>
  );
}
