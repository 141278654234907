import { Checkbox, FormControl, FormControlLabel, Paper, Stack } from "@mui/material";
import _ from "lodash";
import { useCallback, useMemo } from "react";

import { ComparisonHelper } from "@/common/helpers/comparison";
import { TypeHelper } from "@/common/helpers/type";
import { PageTabDefinitionList, TabVisibilityMap } from "@/common/hooks/layout/usePageTabs";

import NoDataAlert from "../AppAlerts/NoDataAlert";
import AppButton from "../Button/AppButton";

interface Props<TTabId extends string> {
  manageableTabs: PageTabDefinitionList<TTabId>;
  initialTabVisibilityMap: TabVisibilityMap<TTabId>;
  visibilityMap: TabVisibilityMap<TTabId>;
  onVisibilityMapChange: (newValue: TabVisibilityMap<TTabId>) => void;
}

export default function PageTabsManagement<TTabId extends string>({
  manageableTabs,
  initialTabVisibilityMap,
  visibilityMap,
  onVisibilityMapChange,
}: Props<TTabId>) {
  const hideableTabs = useMemo(
    () => manageableTabs.filter((x) => _.isNil(x.isHideable) || x.isHideable),
    [manageableTabs],
  );

  const isVisibilityMapsAreTheSame = useMemo(
    () => ComparisonHelper.isDeepEqual(initialTabVisibilityMap, visibilityMap),
    [initialTabVisibilityMap, visibilityMap],
  );

  const isResetEnabled = useMemo(() => !isVisibilityMapsAreTheSame, [isVisibilityMapsAreTheSame]);

  const isAllHideableTabsVisible = useMemo(
    () => hideableTabs.every((x) => _.isNil(visibilityMap[x.value]) || visibilityMap[x.value]),
    [hideableTabs, visibilityMap],
  );

  const isAllHideableTabsHidden = useMemo(
    () => hideableTabs.every((x) => visibilityMap[x.value] === false),
    [hideableTabs, visibilityMap],
  );

  const toggleAllColumns = useCallback(
    (isVisible: boolean) => {
      const newVisibilityMap = { ...visibilityMap };

      hideableTabs.forEach((x) => {
        if (isVisible) {
          // delete the key from the model instead of setting it to `true`
          delete newVisibilityMap[x.value];
        } else {
          newVisibilityMap[x.value] = false;
        }
      });

      onVisibilityMapChange(newVisibilityMap);
    },
    [visibilityMap, hideableTabs, onVisibilityMapChange],
  );

  return (
    <Stack spacing={1}>
      {/* Tab visibility management */}
      <Stack sx={{ py: 1, px: 2 }}>
        {TypeHelper.isEmpty(manageableTabs) && <NoDataAlert title='No manageableTabs' />}

        {!TypeHelper.isEmpty(manageableTabs) &&
          manageableTabs.map((tab, i) => (
            <FormControl key={i}>
              <FormControlLabel
                label={tab.label || tab.value}
                control={
                  <Checkbox
                    sx={{ p: 0.5 }}
                    disabled={tab.isHideable === false}
                    checked={visibilityMap[tab.value] !== false}
                    onChange={(e) => {
                      onVisibilityMapChange({
                        ...visibilityMap,
                        [tab.value]: e.target.checked,
                      });
                    }}
                  />
                }
              />
            </FormControl>
          ))}
      </Stack>

      {/* <Divider /> */}

      {/* Footer */}
      <Paper
        sx={{
          position: "sticky",
          bottom: 0,
          py: 1,
          px: 2,
          borderTop: (th) => `1px solid ${th.palette.divider}`,
          borderRadius: 0,
          boxShadow: "none",
        }}
      >
        <Stack
          direction='row'
          spacing={1}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <FormControl>
            <FormControlLabel
              label='Show/hide all'
              control={
                <Checkbox
                  sx={{ p: 0.5 }}
                  disabled={manageableTabs.length === 0}
                  checked={isAllHideableTabsVisible}
                  indeterminate={!isAllHideableTabsVisible && !isAllHideableTabsHidden}
                  onClick={() => toggleAllColumns(!isAllHideableTabsVisible)}
                />
              }
            />
          </FormControl>

          <AppButton
            variant='text'
            size='small'
            color='text'
            disabled={!isResetEnabled}
            onClick={() => {
              onVisibilityMapChange(initialTabVisibilityMap);
            }}
          >
            Reset
          </AppButton>
        </Stack>
      </Paper>
    </Stack>
  );
}
