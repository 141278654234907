import { useRef } from "react";

import AppPopover, { AppPopoverHandle, AppPopoverProps } from "../../Popover/AppPopover";
import AppPopoverContent from "../../Popover/AppPopoverContent";
import RepairSpecCrossTenantSelector, {
  RepairSpecCrossTenantSelectorProps,
} from "./RepairSpecCrossTenantSelector";

interface FilterPopoverProps extends Omit<AppPopoverProps, "children"> {
  selectorProps: RepairSpecCrossTenantSelectorProps;
}

export default function RepairSpecCrossTenantSelectorPopover({
  selectorProps,
  ...popoverProps
}: FilterPopoverProps) {
  const appPopoverRef = useRef<AppPopoverHandle>(null);

  return (
    <AppPopover ref={appPopoverRef} {...popoverProps}>
      <AppPopoverContent sx={{ minWidth: 500 }}>
        <RepairSpecCrossTenantSelector
          {...selectorProps}
          onSave={(newValue) => {
            selectorProps.onSave(newValue);
            appPopoverRef.current?.close();
          }}
          onCancel={() => {
            selectorProps.onCancel();
            appPopoverRef.current?.close();
          }}
        />
      </AppPopoverContent>
    </AppPopover>
  );
}
