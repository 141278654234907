import { FormHelperText } from "@mui/material";
import { forwardRef } from "react";

import { renderIf } from "@/common/helpers/render/renderIf";
import { PoolItemDto, PoolItemEntityType } from "@/core/api/generated";

import AppIcon, { AppIconProps } from "../../Icons/AppIcon";

type AppIconPropsLocal = Omit<AppIconProps, "of">;

interface Props extends AppIconPropsLocal {
  entity: PoolItemDto | null | undefined;
}

export default forwardRef<SVGSVGElement, Props>(function PoolItemIcon(
  { entity, ...iconProps }: Props,
  ref,
) {
  if (!entity) {
    return null;
  }

  return (
    <>
      {renderIf()
        .if(entity.entityType === PoolItemEntityType.Asset)
        .then(<AppIcon ref={ref} of='asset' {...iconProps} />)
        .elseif(entity.entityType === PoolItemEntityType.Vehicle)
        .then(<AppIcon ref={ref} of='vehicle' {...iconProps} />)
        .elseif(entity.entityType === PoolItemEntityType.Accessory)
        .then(<AppIcon ref={ref} of='accessory' {...iconProps} />)
        .else(<FormHelperText error>Unknown asset entity {entity.entityType}</FormHelperText>)
        .render()}
    </>
  );
});
