export class TypeExtensionHelper {
  public static defineGetProperty(params: {
    prototype: any;
    name: string;
    get: (this: any) => any;
  }): void {
    Object.defineProperty(params.prototype, params.name, {
      get: params.get,
      enumerable: false, // custom properties must be non-enumerable
      configurable: true, // allow re-definition
    });
  }

  public static undefineProperty(params: { prototype: any; name: string }): void {
    Object.defineProperty(params.prototype, params.name, {
      value: undefined,
      enumerable: false, // custom properties must be non-enumerable
      configurable: true, // allow re-definition
    });
  }
}
