import { ReactNode, useMemo } from "react";

import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useUserProfile } from "@/common/hooks/useUserProfile";

import NotAuthorizedTenantAlert from "./NotAuthorizedTenantAlert";

export interface AuthorizedTenantElementProps {
  /** List of tenants user must belong to (all). */
  tenantIds?: Array<string | null | undefined> | null;
  /** List of tenants user must belong to (at least one from the list). */
  tenantIdsAny?: Array<string | null | undefined> | null;
  /** Show alert if user doesn't belong to required tenants. */
  withAlert?: boolean;
  alert?: ReactNode;
  fallback?: ReactNode;
  children: ReactNode;
}

/** Renders children only if user belong to required tenant. */
export default function AuthorizedTenantElement({
  tenantIds,
  tenantIdsAny,
  withAlert,
  alert,
  fallback,
  children,
}: AuthorizedTenantElementProps): ReactNode {
  const currentTenant = useCurrentTenant();
  const profile = useUserProfile();

  const userTenantIds = useMemo(
    () => [...(currentTenant ? [currentTenant.id!] : []), ...(profile?.tenantIds || [])],
    [currentTenant, profile],
  );

  const tenantIdsComputed = useMemo(
    () => tenantIds?.filter((x) => !!x).map((x) => x!),
    [tenantIds],
  );
  const tenantIdsAnyComputed = useMemo(
    () => tenantIdsAny?.filter((x) => !!x).map((x) => x!),
    [tenantIdsAny],
  );

  const hasAll = useMemo(
    () => !tenantIdsComputed || tenantIdsComputed.every((id) => userTenantIds.includes(id)),
    [tenantIdsComputed, userTenantIds],
  );
  const hasAny = useMemo(
    () => !tenantIdsAnyComputed || tenantIdsAnyComputed.some((id) => userTenantIds.includes(id)),
    [tenantIdsAnyComputed, userTenantIds],
  );
  const has = hasAll && hasAny;

  if (!has) {
    if (withAlert || alert) {
      return alert || <NotAuthorizedTenantAlert sx={{ my: 1 }} />;
    }
    return fallback || null;
  }

  return children;
}
