import ContractPaginatedList from "@/common/components/Entity/Contract/ListView/ContractPaginatedList";
import { useLocationState } from "@/common/hooks/useLocationParams";
import { ContractFilterType, FilterDefinitionDto } from "@/core/api/generated";

export interface ContractListPageLocationState {
  initialValues?: {
    filterDefinitionDto?: Nil<FilterDefinitionDto>;
    isResetFilterDefinition?: boolean;
    filterType?: ContractFilterType;
  };
}

export default function ContractsPage() {
  const locationState = useLocationState<ContractListPageLocationState>();

  return (
    <>
      <ContractPaginatedList initialValues={locationState?.initialValues} />
    </>
  );
}
