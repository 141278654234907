import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function EditIcon(props: SvgIconProps, ref) {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <g clipPath='url(#clip0_12064_26513)'>
        <path d='M6 0C3.5332 0 1.5 2.0332 1.5 4.5V19.5C1.5 21.9668 3.5332 24 6 24H10.5C11.3262 24 12 23.3262 12 22.5C12 21.6738 11.3262 21 10.5 21H6C5.14453 21 4.5 20.3555 4.5 19.5V4.5C4.5 3.64453 5.14453 3 6 3H14.3789L19.5 8.12109V18C19.5 18.8262 20.1738 19.5 21 19.5C21.8262 19.5 22.5 18.8262 22.5 18V7.5C22.5 7.10156 22.3418 6.7207 22.0605 6.43945L16.0605 0.439453C15.7793 0.158203 15.3984 0 15 0L6 0ZM12.9609 9.25195C12.6621 9.25781 12.3574 9.28125 12.0645 9.32227L13.8223 12.4336C14.1855 13.0723 13.7812 13.8047 13.0137 14.25L11.625 15.0469C10.8516 15.4805 9.99023 15.4219 9.62695 14.7832L7.86914 11.6719C7.49414 12.1348 7.1543 12.6445 6.94336 13.2012C5.92383 15.8438 7.37109 18.4043 10.1719 18.9082C10.752 19.0195 11.373 18.9902 11.959 18.9199C12.0059 18.9082 14.748 24 14.748 24L21 24.0059L16.2012 16.5703C16.5703 16.1074 16.8691 15.5918 17.0801 15.041C18.0996 12.3926 16.6523 9.83203 13.8574 9.32812C13.5645 9.26953 13.2598 9.25195 12.9609 9.25781V9.25195Z' />
      </g>
      <defs>
        <clipPath id='clip0_12064_26513'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
});
