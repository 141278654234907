import { Button, Stack, TypographyProps } from "@mui/material";
import { useHistory } from "react-router";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { GeneralHistoryHelper } from "@/common/helpers/entity/generalHistory";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  GeneralHistoryDto,
  TagEntityType,
} from "@/core/api/generated";

import GeneralAttachedTagsDisplay from "../../General/GeneralTag/GeneralAttachedTagsDisplay";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import GeneralHistoryMenu from "../GeneralHistoryMenu";
import OverviewTabContent from "./Tabs/OverviewTabContent";

export enum GeneralHistoryViewPageTabs {
  Overview = "Overview",
}

const defaultHeaderProps = {
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<GeneralHistoryDto> {
  generalHistoryId: string | null | undefined;
  generalHistory: GeneralHistoryDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
  onUpdated?: (newValue: GeneralHistoryDto) => void;
  onDeleted?: () => void;
}

export type GeneralHistoryViewProps = OwnProps;

export default function GeneralHistoryView({
  generalHistoryId,
  generalHistory,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
  onUpdated,
  onDeleted,
}: GeneralHistoryViewProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const history = useHistory();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.GeneralHistory),
      viewVariant: displayProps.viewVariant,
    },
  });

  const generalHistoryRequest = useApiRequest(
    apiClient.generalHistoryApi.apiV1GeneralHistoryGeneralHistoryIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      generalHistoryId: generalHistoryId!,
    },
    {
      deps: [generalHistoryId],
      skip: !generalHistoryId || !!generalHistory,
    },
  );
  generalHistory = generalHistory || generalHistoryRequest?.data;

  useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(undefined, EntityType.GeneralHistory, generalHistoryId || ""),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      generalHistoryRequest.handleEntityChanged(data);
    },
  });

  // useBreadcrumbReplacements({
  //   waitTimeout: 10_000,
  //   idBreadcrumb:
  //     (displayProps?.breadcrumbs &&
  //       generalHistory && {
  //         idValue: generalHistory.id!,
  //         newTitle: generalHistory.localNumber || "",
  //       }) ||
  //     undefined,
  // });

  const canEdit = GeneralHistoryHelper.canUpdate(generalHistory);
  // const canDelete = GeneralHistoryHelper.canDelete(generalHistory);

  return (
    <BaseEntityView
      entityType={EntityType.GeneralHistory}
      entityId={generalHistoryId}
      entity={generalHistory}
      entityRequest={generalHistoryRequest}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          displayProps.header &&
          generalHistory && (
            <SimpleViewPageHeader
              title={
                <>
                  History record <EntityChipList entity={generalHistory} variant='normal' />
                </>
              }
              subtitle={
                <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                  <GeneralAttachedTagsDisplay
                    tags={generalHistory?.tags}
                    defaultIsFolded={false}
                    edit={{
                      entityType: TagEntityType.GeneralHistory,
                      entityId: generalHistory?.id,
                      onSaved: (newValue) => {
                        generalHistoryRequest.updateData((x) => {
                          x.tags = newValue || undefined;
                        });
                      },
                    }}
                  />
                </AuthorizedElement>
              }
              primaryActions={
                displayProps.actions &&
                canEdit && (
                  <AuthorizedElement permissions={[AppPermission.GeneralHistoryManage]}>
                    <Button
                      component={AppLink}
                      to={ROUTE_PATH.VEHICLE_EDIT(generalHistory?.id)}
                      variant='outlined'
                      color='text'
                      size='medium'
                      startIcon={<AppIcon of='edit' />}
                    >
                      Edit
                    </Button>
                  </AuthorizedElement>
                )
              }
              secondaryActions={
                displayProps.actions && (
                  <GeneralHistoryMenu
                    entity={generalHistory}
                    displayProps={{
                      actions: {
                        view: false,
                        viewInNewTab: true,
                        edit: false,
                        delete: true,
                      },
                    }}
                    onUpdated={(newValue) => {
                      generalHistoryRequest.replaceData(newValue);
                      onUpdated && onUpdated(newValue);
                    }}
                    onDeleted={() => {
                      onDeleted && onDeleted();
                      history.goBack();
                    }}
                  />
                )
              }
            />
          )
        }
      >
        <Stack spacing={2}>
          <PageTabs
            tabIdsDefinition={GeneralHistoryViewPageTabs}
            defaultTabId={GeneralHistoryViewPageTabs.Overview}
            viewVariant={displayProps?.viewVariant}
            commonViewParams={commonViewParams}
            tabs={[
              { label: "Overview", value: GeneralHistoryViewPageTabs.Overview, isHideable: false },
            ]}
          >
            {({ activeTabId: activeTab }) =>
              generalHistory && (
                <>
                  {activeTab === GeneralHistoryViewPageTabs.Overview && (
                    <OverviewTabContent generalHistory={generalHistory} />
                  )}
                </>
              )
            }
          </PageTabs>
        </Stack>
      </ViewLayout>
    </BaseEntityView>
  );
}
