import PersonIcon from "@mui/icons-material/Person";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import _ from "lodash";
import { useEffect, useState } from "react";
import * as Yup from "yup";

import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AddChatParticipantsDto,
  ChatDto,
  ChatParticipantCandidateDto,
  ChatParticipantDto,
} from "@/core/api/generated";
import * as chatParticipantsSlice from "@/store/communication/chatParticipantsSlice";

import GeneralValidationError from "../../Error/GeneralValidationError";
import AppModalTitle from "../../Modals/AppModalTitle";

interface Props {
  open: boolean;
  onClose?: () => void;
  onUpdated?: (newValues?: ChatParticipantDto[]) => void;

  chat: ChatDto;
}

function AddChatParticipantsModal({ open, onClose, onUpdated, chat }: Props) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const thunkDispatch = useAppThunkDispatch();
  const { getChatParticipantCandidates: isGetChatParticipantCandidatesLoading } = useAppSelector(
    (x) => x.communication.chatParticipants.loading,
  );

  const [prevOpen, setPrevOpen] = useState(false);
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const [options, setOptions] = useState<readonly ChatParticipantCandidateDto[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<ChatParticipantCandidateDto[]>([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [inputValue, setInputValue] = useState("");

  const paginatedChatParticipantCandidatesRequest = useApiRequest(
    apiClient.chatParticipantsApi.apiV1ChatsChatIdParticipantsCandidatesGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      chatId: chat.id!,
      offset,
      limit,
      search: inputValue,
    },
    {
      deps: [chat.id!, offset, limit],
      skip: !open && prevOpen,
      debouncedDeps: {
        deps: [inputValue],
        wait: 500,
        options: { trailing: true },
      },
    },
  );
  const paginatedChatParticipantCandidates = paginatedChatParticipantCandidatesRequest.data;

  const reset = () => {
    setOptions([]);
    setSelectedOptions([]);
    setOffset(0);
    setLimit(50);
    setInputValue("");
  };

  useEffect(() => {
    if (prevOpen && !open) {
      reset();
    }
    setPrevOpen(open);
  }, [open]);

  useEffect(() => {
    const newOptions = [...selectedOptions, ...(paginatedChatParticipantCandidates?.items || [])];
    setOptions(_.uniqBy(newOptions, (x) => x.randomId));
  }, [paginatedChatParticipantCandidates]);

  return (
    <Box>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
        <AppModalTitle onCloseClicked={() => onClose && onClose()}>Add participants</AppModalTitle>
        <DialogContent>
          <Formik<AddChatParticipantsDto & BaseFormikValues>
            enableReinitialize
            initialValues={{
              candidates: [],
              submit: "",
            }}
            validationSchema={Yup.object().shape({})}
            onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
              try {
                const updatedChat = await thunkDispatch(
                  chatParticipantsSlice.addChatParticipants({
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    chatId: chat!.id!,
                    addChatParticipantsDto: {
                      ..._.omit(values, "submit"),
                    },
                  }),
                );
                enqueueSnackbar("Participants added.", { variant: "success" });
                if (mounted.current) {
                  setStatus({ success: true });
                  setSubmitting(false);
                }
                onUpdated && onUpdated(updatedChat);
              } catch (err: any) {
                if (mounted.current) {
                  ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
                  setStatus({ success: false });
                  setSubmitting(false);
                }
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setErrors,
              setFieldValue,
              setValues,
            }) => {
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <Box>
                    <FormControl margin='normal' fullWidth>
                      <Autocomplete
                        multiple
                        sx={{ minWidth: 200, flex: 1 }}
                        open={autocompleteOpen}
                        onOpen={() => {
                          setAutocompleteOpen(true);
                        }}
                        onClose={() => {
                          setAutocompleteOpen(false);
                        }}
                        isOptionEqualToValue={(option, value) => option.randomId === value.randomId}
                        getOptionLabel={(option) => option.personName?.name || option.email || ""}
                        filterOptions={(x) => x} // disable the built-in filtering
                        options={options}
                        loading={isGetChatParticipantCandidatesLoading}
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        value={selectedOptions}
                        onChange={(event, newValues) => {
                          setSelectedOptions(newValues);
                          setFieldValue("candidates", newValues);
                        }}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Participants'
                            placeholder='Search by name or email...'
                            fullWidth
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {isGetChatParticipantCandidatesLoading ? (
                                    <CircularProgress color='inherit' size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                        renderOption={(props, option) => {
                          return (
                            <ListItem {...props} key={option.randomId}>
                              <ListItemIcon>
                                <PersonIcon sx={{ color: (t) => t.palette.text.main }} />
                              </ListItemIcon>
                              <ListItemText
                                primary={option.personName?.name}
                                secondary={option.email}
                              />
                            </ListItem>
                          );
                        }}
                      />
                    </FormControl>
                  </Box>

                  <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                  <Stack direction='row' spacing={1} sx={{ justifyContent: "flex-end", mt: 1 }}>
                    <Button variant='outlined' color='text' onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      loading={isSubmitting}
                      disabled={values.candidates?.length === 0}
                      type='submit'
                    >
                      Add
                    </Button>
                  </Stack>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default AddChatParticipantsModal;
