import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import EntityCommentsChat from "@/App/MainAppView/Chats/components/EntityCommentsChat";
import { ContractDto, EntityType } from "@/core/api/generated";

interface Props {
  contract: ContractDto;
}

export default function CommentsTabContent({ contract }: Props) {
  return (
    <PageTabContent>
      <ViewContentBlock spacing={2}>
        <PageTabHeader title={"Comments"} />
        <EntityCommentsChat entityId={contract.id!} entityType={EntityType.Contract} />
      </ViewContentBlock>
    </PageTabContent>
  );
}
