import _ from "lodash";
import moment from "moment";

import { LocalStorageKey } from "../constants/localStorage";
import { FilterDefinition } from "../filters/filterDefinition";
import { SortDefinition } from "../sorting/sortDefinition";
import {
  CommonRequestParamsState,
  CommonRequestParamsStateAggregatedLocalStorageModel,
  CommonRequestParamsStatePersistenceStrategy,
} from "../ts/commonRequestParams";
import { DatetimeHelper } from "./datetime";
import { LocalStorageHelper } from "./localStorage";
import { StringHelper } from "./string";
import { TypeHelper } from "./type";

export class CommonRequestParamsHelper {
  // #region State

  public static maxItemSizeInLocalStorageInBytes = Math.min(
    100 * 1024,
    LocalStorageHelper.maxItemSizeInBytes,
  ); // 100 KiB. size per 1 entity is ~1KiB

  public static readonly defaultStatePersistStrategy =
    CommonRequestParamsStatePersistenceStrategy.LocalStorage;

  public static persistState(
    persistenceKey: string,
    state: CommonRequestParamsState,
    strategy: CommonRequestParamsStatePersistenceStrategy = this.defaultStatePersistStrategy,
  ): void {
    // clone to avoid modifying objects in use
    state.sort = state.sort?.clone();
    state.filter = state.filter?.clone();

    // format for persistence
    state.sort?.formatBeforeJsonSerialization();
    state.filter?.formatBeforeJsonSerialization();

    switch (strategy) {
      case CommonRequestParamsStatePersistenceStrategy.LocalStorage:
        this.persistStateToLocalStorage(persistenceKey, state);
        break;
      case CommonRequestParamsStatePersistenceStrategy.Url:
        this.persistStateTuUrl(persistenceKey, state);
        break;
      default:
        throw new Error(`Strategy '${strategy}' is not supported!`);
    }
  }

  public static getPersistedState(
    persistenceKey: string,
    strategy: CommonRequestParamsStatePersistenceStrategy = this.defaultStatePersistStrategy,
  ): CommonRequestParamsState | undefined {
    switch (strategy) {
      case CommonRequestParamsStatePersistenceStrategy.LocalStorage:
        return this.getPersistedStateFromLocalStorage(persistenceKey);
      case CommonRequestParamsStatePersistenceStrategy.Url:
        return this.getPersistedStateFromUrl(persistenceKey);
      default:
        throw new Error(`Strategy '${strategy}' is not supported!`);
    }
  }

  public static persistStateToLocalStorage(
    persistenceKey: string,
    state: CommonRequestParamsState,
  ): void {
    state.lastPersistedAt = moment().utc().format();

    const persisted =
      LocalStorageHelper.getTypedJsonItem<CommonRequestParamsStateAggregatedLocalStorageModel>(
        LocalStorageKey.requestParamsStateAggregated,
      ) || {};
    persisted.byKeyMap ??= {};
    persisted.byKeyMap[persistenceKey] = state;
    this.handleSizeLimitsForLocalStorage(persisted, { ignoreKeys: [persistenceKey] });
    LocalStorageHelper.setJsonItem(LocalStorageKey.requestParamsStateAggregated, persisted);
  }

  public static handleSizeLimitsForLocalStorage(
    model: CommonRequestParamsStateAggregatedLocalStorageModel,
    options?: { ignoreKeys?: string[] },
  ): void {
    const oldModel = _.cloneDeep(model);
    const ignoreKeysMap = _.chain(options?.ignoreKeys || [])
      .mapKeys((x) => x)
      .mapValues((x) => true)
      .value();
    const serialized = JSON.stringify(model);
    const maxSizeBytes = this.maxItemSizeInLocalStorageInBytes;
    const sizeBytes = StringHelper.getSizeInBytes(serialized);
    console.log("CommonRequestParamsHelper.handleSizeLimitsForLocalStorage.", {
      maxSizeBytes,
      sizeBytes,
      isLimitExceeded: sizeBytes > maxSizeBytes,
      model,
    });

    // cleanup old records to free space
    if (sizeBytes > maxSizeBytes) {
      console.log(
        "CommonRequestParamsHelper.handleSizeLimitsForLocalStorage. Size limit exceeded.",
        {
          maxSizeBytes,
          sizeBytes,
        },
      );
      model.byKeyMap ??= {};
      let byKeyMapEntries = Object.entries(model.byKeyMap)
        .map(([key, value]) => ({
          key,
          value,
        }))
        .filter((x) => !ignoreKeysMap[x.key])
        .filter((x) => Boolean(x.value));
      byKeyMapEntries = _.orderBy(
        byKeyMapEntries,
        (x) => x.value.lastPersistedAt || DatetimeHelper.getMinDateAsMoment().utc().format(),
        "asc",
      ); // from old to new
      // const byKeyMapEntriesToRemove = [] as typeof byKeyMapEntries;

      // remove N entries until the resulting object size satisfies the limit
      const removePerStepCount = 3;
      const removedByKeyMapEntries = [] as typeof byKeyMapEntries;
      let newSizeBytes = 0;
      while (true) {
        const byKeyMapEntriesToRemove = byKeyMapEntries.splice(0, removePerStepCount);
        byKeyMapEntriesToRemove.forEach((x) => {
          delete model.byKeyMap![x.key];
        });
        removedByKeyMapEntries.push(...byKeyMapEntriesToRemove);
        const newSerialized = JSON.stringify(model);
        newSizeBytes = StringHelper.getSizeInBytes(newSerialized);
        if (newSizeBytes <= maxSizeBytes || TypeHelper.isEmpty(model.byKeyMap)) {
          break;
        }
      }
      console.log("CommonRequestParamsHelper.handleSizeLimitsForLocalStorage. Cleanup completed.", {
        maxSizeBytes,
        oldSizeBytes: sizeBytes,
        newSizeBytes,
        removedByKeyMapEntries,
        oldModel,
        newModel: model,
      });
    }
  }

  public static persistStateTuUrl(persistenceKey: string, state: CommonRequestParamsState): void {
    throw new Error("Not implemented yet!");
  }

  public static getPersistedStateFromLocalStorage(
    persistenceKey: string,
  ): CommonRequestParamsState | undefined {
    const persisted =
      LocalStorageHelper.getTypedJsonItem<CommonRequestParamsStateAggregatedLocalStorageModel>(
        LocalStorageKey.requestParamsStateAggregated,
      );
    const rawState =
      persisted && persisted.byKeyMap ? persisted.byKeyMap[persistenceKey] : undefined;
    const state = rawState ? { ...rawState } : undefined;

    // restore class instances
    if (state) {
      state.sort = state.sort ? new SortDefinition(state.sort) : undefined;
      state.filter = state.filter ? new FilterDefinition(state.filter) : undefined;
    }

    return state;
  }

  public static getPersistedStateFromUrl(
    persistenceKey: string,
  ): CommonRequestParamsState | undefined {
    throw new Error("Not implemented yet!");
  }

  // #endregion
}
