import { Alert, AlertProps, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { ReactNode } from "react";

interface OwnProps {
  title: ReactNode;
  icon?: ReactNode;
  alertProps?: Partial<AlertProps>;
}

type Props = OwnProps;

export default function AlertMenuItem({ title, icon, alertProps }: Props) {
  return (
    <ListItem>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText
        sx={{ m: 0, p: 0 }}
        primary={
          <Alert sx={{ m: 0, px: 1, py: 0.25 }} severity='info' {...alertProps}>
            {title}
          </Alert>
        }
      />
    </ListItem>
  );
}
