import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
  TypographyProps,
} from "@mui/material";
import _ from "lodash";
import { useCallback, useMemo, useState } from "react";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import AppAvatar from "@/common/components/Avatar/AppAvatar";
import DropdownButton from "@/common/components/Button/DropdownButton";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import HeaderMenuItem from "@/common/components/Menu/HeaderMenuItem";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AdminUserCompanyDto,
  AdminUserDto,
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  UserDto,
  UserStatus,
} from "@/core/api/generated";

import TenantInline from "../../Tenant/TenantInline";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import SuspendUserModal from "../SuspendUserModal";
import UnsuspendUserModal from "../UnsuspendUserModal";
import UserActivateModal from "../UserActivateModal";
import OverviewTabContent from "./Tabs/OverviewTabContent";
import SuspensionInfoTabContent from "./Tabs/SuspensionInfoTabContent";

export enum UserViewPageTabs {
  Overview = "Overview",
  SuspensionInfo = "SuspensionInfo",
}

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
};

interface OwnProps extends BaseEntityViewInheritableProps<UserDto> {
  userId: string | null | undefined;
  user?: AdminUserDto | null;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps;

export default function AdminUserView({
  userId,
  user,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();

  const [tenantId, setTenantId] = useState<string | undefined>(undefined);
  const [isActivateModalOpen, setIsActivateModalOpen] = useState(false);
  const [isSuspendModalOpen, setIsSuspendModalOpen] = useState(false);
  const [isUnsuspendModalOpen, setIsUnsuspendModalOpen] = useState(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);

  const userRequest = useApiRequest(
    apiClient.adminUsersApi.apiV1AdminUsersUserIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      userId: userId!,
    },
    {
      deps: [userId],
      skip: !userId || !!user,
    },
  );
  user = user || userRequest?.data;

  const tenantIds = useMemo(() => user?.companies?.map((x) => x.id || ""), [user]);
  const selectedUserProfile = useMemo<AdminUserDto | AdminUserCompanyDto | undefined>(
    () =>
      tenantId ? user?.companies?.find((x) => x.id === tenantId) || undefined : user || undefined,
    [user, tenantId],
  );

  const paginatedTenantsRequest = useApiRequest(
    apiClient.adminTenantsApi.apiV1AdminTenantsGetPost,
    {
      adminTenantGetPaginatedDto: {
        offset: 0,
        limit: 25,
        ids: tenantIds,
      },
    },
    {
      deps: [tenantIds],
      skip: _.isEmpty(tenantIds),
    },
  );
  const paginatedTenants = paginatedTenantsRequest.data;

  useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(currentTenant?.id, EntityType.User, user?.id || ""),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      userRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        user && {
          idValue: user.id!,
          newTitle: user.personName?.name || "",
        }) ||
      undefined,
  });

  const handleDeleteConfirmed = useCallback(async () => {
    if (!user) {
      return;
    }
    try {
      await apiClient.usersApi.apiV1UsersUserIdDelete({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
        userId: user.id!,
      });
      enqueueSnackbar("User was successfully deleted.", { variant: "success" });
      setIsConfirmDeleteModalOpen(false);
      userRequest.refetch();
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [user]);

  return (
    <BaseEntityView
      entityType={EntityType.User}
      entityId={userId}
      entity={user}
      entityRequest={userRequest}
    >
      <Stack spacing={2}>
        {displayProps.header && (
          <DetailedViewPageHeader
            image={<AppAvatar user={user} size={100} />}
            title={
              <>
                {`User ${selectedUserProfile?.personName?.name}`}{" "}
                <EntityChipList entity={user} variant='normal' />
              </>
            }
            titleProps={{
              to: headerProps?.withLink ? ROUTE_PATH.USER_VIEW(user?.id) : undefined,
              typographyProps: headerProps?.typographyProps,
            }}
            primaryActions={
              displayProps.actions && (
                <Stack direction='row' spacing={0.5} alignItems='center'>
                  <DropdownButton
                    buttonProps={{
                      color: "secondary",
                      size: "medium",
                      variant: "outlined",
                      startIcon: <AppIcon of={tenantId ? "tenant" : "global"} />,
                    }}
                    dropdownContent={
                      <MenuList>
                        <HeaderMenuItem
                          primaryTitle='Select profile type'
                          secondaryTitle='(Global or tenant)'
                        />

                        <MenuItem
                          selected={!tenantId}
                          onClick={() => {
                            setTenantId(undefined);
                          }}
                        >
                          <ListItemIcon>
                            <AppIcon of='global' fontSize='small' />
                          </ListItemIcon>
                          <ListItemText>Global</ListItemText>
                        </MenuItem>

                        {paginatedTenants?.items?.map((tenant, i) => (
                          <MenuItem
                            key={i}
                            selected={tenantId === tenant.id}
                            onClick={() => {
                              setTenantId(tenant.id);
                            }}
                          >
                            <ListItemIcon>
                              <AppIcon of='tenant' fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>
                              <TenantInline entity={tenant} withIcon={false} />
                            </ListItemText>
                          </MenuItem>
                        ))}
                      </MenuList>
                    }
                  >
                    Profile type
                  </DropdownButton>

                  <AuthorizedElement permissions={[AppPermission.AdminUserManage]}>
                    <Button
                      component={AppLink}
                      to={ROUTE_PATH.ADMIN_USER_EDIT(user?.id, { tenantId })}
                      variant='outlined'
                      color='text'
                      size='medium'
                      startIcon={<AppIcon of='edit' />}
                    >
                      Edit
                    </Button>
                  </AuthorizedElement>
                </Stack>
              )
            }
            secondaryActions={
              displayProps.actions &&
              user && (
                <MenuWithTrigger
                  withAuthCloseOnClick
                  trigger={
                    <IconButton sx={{ ml: "auto" }}>
                      <AppIcon of='moreVert' />
                    </IconButton>
                  }
                >
                  <AuthorizedMenuItem
                    permissions={[AppPermission.RoleAssignmentManage]}
                    component={AppLink}
                    to={ROUTE_PATH.ADMIN_USER_ROLES_EDIT(user.id, { tenantId })}
                  >
                    <ListItemIcon>
                      <AppIcon of='edit' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Edit roles</ListItemText>
                  </AuthorizedMenuItem>
                  {selectedUserProfile?.status !== UserStatus.Active && (
                    <AuthorizedMenuItem
                      permissions={[AppPermission.AdminUserManage]}
                      onClick={() => {
                        setIsActivateModalOpen(true);
                      }}
                    >
                      <ListItemIcon>
                        <AppIcon of='activate' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>Activate</ListItemText>
                    </AuthorizedMenuItem>
                  )}
                  <AuthorizedElement permissions={[AppPermission.AdminUserManage]}>
                    {selectedUserProfile?.status !== UserStatus.Suspended && (
                      <MenuItem
                        onClick={() => {
                          setIsSuspendModalOpen(true);
                        }}
                      >
                        <ListItemIcon>
                          <AppIcon of='suspend' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Suspend</ListItemText>
                      </MenuItem>
                    )}
                    {selectedUserProfile?.status === UserStatus.Suspended && (
                      <MenuItem
                        onClick={() => {
                          setIsUnsuspendModalOpen(true);
                        }}
                      >
                        <ListItemIcon>
                          <AppIcon of='unsuspend' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Resume (unsuspend)</ListItemText>
                      </MenuItem>
                    )}
                  </AuthorizedElement>
                  <AuthorizedMenuItem
                    permissions={[AppPermission.AdminUserManage]}
                    onClick={() => {
                      setIsConfirmDeleteModalOpen(true);
                    }}
                  >
                    <ListItemIcon>
                      <AppIcon of='removePerson' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Remove</ListItemText>
                  </AuthorizedMenuItem>
                </MenuWithTrigger>
              )
            }
          />
        )}

        <PageTabs
          tabIdsDefinition={UserViewPageTabs}
          defaultTabId={UserViewPageTabs.Overview}
          tabs={[
            { label: "Overview", value: UserViewPageTabs.Overview },
            { label: "Suspension info", value: UserViewPageTabs.SuspensionInfo },
          ]}
        >
          {({ activeTabId: activeTab }) =>
            user &&
            selectedUserProfile && (
              <>
                {activeTab === UserViewPageTabs.Overview && (
                  <OverviewTabContent user={user} selectedUserProfile={selectedUserProfile} />
                )}

                {activeTab === UserViewPageTabs.SuspensionInfo && (
                  <SuspensionInfoTabContent user={user} selectedUserProfile={selectedUserProfile} />
                )}
              </>
            )
          }
        </PageTabs>

        {/* Activate */}
        {user && (
          <UserActivateModal
            open={isActivateModalOpen}
            onClose={() => {
              setIsActivateModalOpen(false);
            }}
            user={user}
            activateFunc={async (params) => {
              await apiClient.adminUsersApi.apiV1AdminUsersTargetUserIdActivatePost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                targetUserId: params.targetUserId,
                adminUserActivateDto: {
                  tenantId: tenantId,
                  ...params,
                },
              });
              await userRequest.refetch();
            }}
          />
        )}

        {/* Suspend */}
        {user && (
          <SuspendUserModal
            open={isSuspendModalOpen}
            onClose={() => {
              setIsSuspendModalOpen(false);
            }}
            user={user}
            suspendFunc={async (params) => {
              await apiClient.adminUsersApi.apiV1AdminUsersTargetUserIdSuspendPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                targetUserId: params.targetUserId,
                adminUserSuspendDto: {
                  tenantId: tenantId,
                  ...params,
                },
              });
              await userRequest.refetch();
            }}
          />
        )}

        {/* Unsuspend */}
        {user && (
          <UnsuspendUserModal
            open={isUnsuspendModalOpen}
            onClose={() => {
              setIsUnsuspendModalOpen(false);
            }}
            user={user}
            unsuspendFunc={async (params) => {
              await apiClient.adminUsersApi.apiV1AdminUsersTargetUserIdUnsuspendPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                targetUserId: params.targetUserId,
                adminUserUnsuspendDto: {
                  tenantId: tenantId,
                  ...params,
                },
              });
              await userRequest.refetch();
            }}
          />
        )}

        {/* Delete user confirmation */}
        {user && (
          <ConfirmationModal
            title='Remove the user?'
            body={
              <>
                {`You're going to remove the user`}{" "}
                <strong>{user.personName?.name || user.email}</strong> from{" "}
                <strong>{currentTenant?.name}</strong>. You can invite the user again later if
                needed.
              </>
            }
            open={isConfirmDeleteModalOpen}
            onClose={() => setIsConfirmDeleteModalOpen(false)}
            onCancel={() => setIsConfirmDeleteModalOpen(false)}
            onConfirm={handleDeleteConfirmed}
          />
        )}
      </Stack>
    </BaseEntityView>
  );
}
