import { Chip, Divider, IconButton, Stack, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIcon from "@/common/components/Icons/AppIcon";
import PermissionList from "@/common/components/Permission/PermissionList";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useAppDispatch } from "@/common/hooks/redux";
import { apiClient } from "@/core/api/ApiClient";
import { AppPermission } from "@/core/api/generated";
import { resetTenantRole } from "@/store/management/tenantAccount/slice";

const defaultDisplayProps = {
  viewVariant: ViewLayoutVariant.Page,
};
interface Props {
  displayProps?: Partial<typeof defaultDisplayProps>;
}
function RoleView({ displayProps }: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const { roleId } = useParams<{ roleId?: string }>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetTenantRole());
    };
  }, []);

  const getTenantRoleRequest = useApiRequest(
    apiClient.tenantAccountApi.apiV1TenantAccountRolesRoleIdGet,
    { nexusOpsTenant: EMPTY_TENANT_IDENTIFIER, roleId: roleId! },
  );
  const role = getTenantRoleRequest.data;

  const getTenantPermissionsRequest = useApiRequest(
    apiClient.tenantAccountApi.apiV1TenantAccountPermissionsGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
  );
  const permissions = getTenantPermissionsRequest?.data;

  const rolePermissions = useMemo(
    () => permissions?.filter((x) => role?.permissions?.includes(x.permission!)),
    [role, permissions],
  );

  // const groupedPermissions = useMemo(
  //   () => _.groupBy(permissions || [], (x) => x.groupName),
  //   [permissions],
  // );

  return (
    <ViewLayout
      displayProps={displayProps}
      breadcrumbs={{
        replacements: {
          isWaitInfinitely: true,
          idBreadcrumb: role && {
            idValue: role.id!,
            newTitle: role.name || "",
          },
        },
      }}
      header={
        <SimpleViewPageHeader
          title={
            <>
              <span>Role {role?.name}</span>
              {role?.isBuiltIn && (
                <Chip
                  size='small'
                  color='primary'
                  variant={"outlined"}
                  label={"Built-in"}
                  sx={{ ml: 1 }}
                />
              )}
              {!role?.isBuiltIn && (
                <AuthorizedElement permissions={[AppPermission.TenantRoleManage]}>
                  <IconButton
                    sx={{ ml: 1 }}
                    component={RouterLink}
                    to={ROUTE_PATH.TENANT_ROLE_EDIT(role?.id)}
                  >
                    <AppIcon of='edit' />
                  </IconButton>
                </AuthorizedElement>
              )}
            </>
          }
        />
      }
    >
      <Stack direction='column'>
        <Typography component='div' variant='body1' color='text.secondary'>
          {role?.description}
        </Typography>

        <Divider sx={{ mt: 1, mb: 1 }} />

        <Typography component='div' variant='h6'>
          Permissions
        </Typography>
        <PermissionList permissions={rolePermissions} />
      </Stack>
    </ViewLayout>
  );
}

export default RoleView;
