import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function EditIcon(props: SvgIconProps, ref) {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <g clipPath='url(#clip0_12064_26663)'>
        <path d='M23.4555 9.88346L21.4794 9.25196C21.2771 8.55231 20.9979 7.87876 20.6458 7.24047L21.5968 5.39628C21.7528 5.09366 21.6954 4.72494 21.4546 4.48424L19.5156 2.54526C19.275 2.30452 18.9061 2.24705 18.6036 2.40305L16.7594 3.35399C16.1211 3.00192 15.4475 2.72278 14.7479 2.52047L14.1164 0.54437C14.0129 0.220091 13.7115 6.10352e-05 13.371 6.10352e-05H10.6288C10.2884 6.10352e-05 9.98699 0.220138 9.8834 0.544464L9.2519 2.52051C8.55229 2.72283 7.87875 3.00197 7.24046 3.35404L5.39617 2.40309C5.0935 2.247 4.72479 2.30461 4.48418 2.54531L2.54525 4.48428C2.3045 4.72503 2.24703 5.0937 2.40303 5.39633L3.35398 7.24052C3.0019 7.87876 2.72272 8.55235 2.52041 9.25201L0.544309 9.8835C0.220124 9.9871 0 10.2885 0 10.6289V13.371C0 13.7115 0.220124 14.0129 0.544403 14.1165L2.52045 14.748C2.72277 15.4476 3.00195 16.1212 3.35403 16.7595L2.40308 18.6037C2.24708 18.9063 2.3045 19.275 2.5453 19.5157L4.48422 21.4547C4.72488 21.6954 5.09378 21.7529 5.39622 21.5969L7.2405 20.6459C7.87875 20.998 8.55234 21.2772 9.25195 21.4795L9.88344 23.4555C9.98708 23.7798 10.2885 23.9999 10.6289 23.9999H13.3711C13.7116 23.9999 14.013 23.7798 14.1166 23.4555L14.7481 21.4795C15.4477 21.2772 16.1212 20.998 16.7595 20.6459L18.6038 21.5969C18.9065 21.7529 19.2751 21.6953 19.5158 21.4547L21.4547 19.5157C21.6955 19.2749 21.7529 18.9063 21.5969 18.6037L20.646 16.7595C20.9981 16.1212 21.2772 15.4476 21.4795 14.748L23.4556 14.1165C23.7799 14.0128 24 13.7114 24 13.371V10.6289C23.9999 10.2885 23.7797 9.9871 23.4555 9.88346ZM11.9999 18.8395C8.22862 18.8395 5.16039 15.7713 5.16039 12C5.16039 8.22868 8.22862 5.16045 11.9999 5.16045C15.7712 5.16045 18.8395 8.22864 18.8395 12C18.8395 15.7713 15.7712 18.8395 11.9999 18.8395Z' />
        <path d='M13.8357 12.729L12.7825 11.6758V8.83792C12.7825 8.40573 12.4321 8.0553 11.9999 8.0553C11.5677 8.0553 11.2173 8.40569 11.2173 8.83792V11.9999C11.2173 12.2076 11.2997 12.4065 11.4465 12.5533L12.7289 13.8357C13.0345 14.1413 13.53 14.1414 13.8357 13.8357C14.1413 13.5301 14.1413 13.0346 13.8357 12.729Z' />
      </g>
      <defs>
        <clipPath id='clip0_12064_26663'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
});
