import { ReactNode } from "react";
import { Redirect, useLocation } from "react-router";

import { ROUTE_PATH } from "@/common/constants/routing";
import { UrlHelper } from "@/common/helpers/url";
import { useAuthenticationInfo } from "@/common/hooks/auth/useAuthenticationInfo";

interface AuthenticatedGuardProps {
  children: ReactNode;
}

/** Renders children if user is authenticated, otherwise redirects to login */
export default function AuthenticatedGuard({ children }: AuthenticatedGuardProps) {
  const { isAuthenticated } = useAuthenticationInfo();

  const pathname = useLocation().pathname;
  // const requestedLocation = useAppSelector((x) => x.redirect.requestedLocation);

  if (isAuthenticated === false) {
    // if (pathname !== requestedLocation) {
    //   dispatch(redirectSlice.setRequestedLocation(pathname));
    // }

    console.log("AuthenticatedGuard. Redirecting to sign in...", {
      isAuthenticated,
      pathname,
      // requestedLocation,
    });
    return (
      <Redirect
        to={ROUTE_PATH.AUTH_SIGN_IN({
          redirectUrl: UrlHelper.getCurrentUrl(),
        })}
      />
    );
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  // if (requestedLocation && pathname !== requestedLocation) {
  //   dispatch(redirectSlice.setRequestedLocation(null));
  //   return <Redirect to={requestedLocation} />;
  // }

  return <>{children}</>;
}
