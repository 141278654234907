import { Stack } from "@mui/material";
import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import CreateUpdateRepairSpec from "@/common/components/Entity/RepairSpec/CreateUpdateRepairSpec";
import { apiClient } from "@/core/api/ApiClient";

export default function CreateUpdateRepairSpecPage() {
  const { repairSpecId } = useParams<{ repairSpecId?: string }>();
  const isCreate = !repairSpecId;
  const history = useHistory();

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader title={isCreate ? "Create new repair spec" : "Edit repair spec"} />
      }
    >
      <Stack spacing={2}>
        <CreateUpdateRepairSpec
          repairSpecId={repairSpecId}
          onSave={(newValue) => {
            history.goBack();
          }}
          createFunc={async (params) => {
            return await apiClient.adminRepairSpecsApi.apiV1AdminRepairSpecsPost({
              createRepairSpecDto: params.dto,
            });
          }}
          updateFunc={async (params) => {
            return await apiClient.adminRepairSpecsApi.apiV1AdminRepairSpecsRepairSpecIdPut({
              repairSpecId: params.repairSpecId,
              updateRepairSpecDto: params.dto,
            });
          }}
        />
      </Stack>
    </CreateUpdatePageLayout>
  );
}
