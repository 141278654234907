import { useParams } from "react-router";

import DepartmentView from "@/common/components/Entity/Department/View/DepartmentView";

export default function DepartmentViewPage() {
  const { departmentId } = useParams<{ departmentId?: string }>();

  return (
    <>
      <DepartmentView
        departmentId={departmentId}
        department={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
