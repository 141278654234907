import { Dialog, DialogProps } from "@mui/material";

import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AppModalContent from "@/common/components/Modals/AppModalContent";

import RepairSpecView, { RepairSpecViewProps } from "./RepairSpecView";

export interface OwnProps {
  viewProps: RepairSpecViewProps;
}

type Props = OwnProps & DialogProps;

export default function RepairSpecViewModal({ viewProps, ...dialogProps }: Props) {
  return (
    <Dialog fullWidth maxWidth='lg' {...dialogProps}>
      <AppModalContent>
        <RepairSpecView
          onDeleted={() => {
            dialogProps.onClose && dialogProps.onClose({}, "escapeKeyDown");
          }}
          {...viewProps}
          displayProps={{
            viewVariant: ViewLayoutVariant.Tab, // TODO: must be Modal
            ...viewProps?.displayProps,
          }}
        />
      </AppModalContent>
    </Dialog>
  );
}
