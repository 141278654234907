import { Alert, Box, LinearProgress } from "@mui/material";
import { ReactNode, useMemo } from "react";

import { EntityHelper } from "@/common/helpers/entity";
import { UseApiRequestResult } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { DataGrantPermission, EntityType, IBaseEntityDto } from "@/core/api/generated";

import EntityForbiddenAlert from "../../AppAlerts/EntityForbiddenAlert";
import EntityNotFoundAlert from "../../AppAlerts/EntityNotFoundAlert";

export interface BaseEntityViewOwnProps<TEntity extends IBaseEntityDto> {
  entityType: EntityType;
  entityId: string | null | undefined;
  entity: TEntity | null | undefined;
  entityRequest: UseApiRequestResult<any, any, any, any, any> | null | undefined; // use any as we don't care about generic arguments
  children: ReactNode;
}

export type BaseEntityViewProps<TEntity extends IBaseEntityDto> = BaseEntityViewOwnProps<TEntity>;

/** Interface to be used by wrapper components that utilize this component. */
export interface BaseEntityViewInheritableProps<TEntity extends IBaseEntityDto> {
  // TODO: need to refactor all entity view comps to use this
  // entityId: string | null | undefined;
  // entity: TEntity | null | undefined;
  typePlaceholder?: string;
}

export default function BaseEntityView<TEntity extends IBaseEntityDto = IBaseEntityDto>({
  entityType,
  entityId,
  entity,
  entityRequest,
  children,
}: BaseEntityViewProps<TEntity>) {
  const currentTenant = useCurrentTenant();

  const entityMeta = useMemo(
    () => EntityHelper.getEntityMeta(entity, currentTenant),
    [entity, currentTenant],
  );
  const canView = useMemo(
    () =>
      !entityMeta?.grantsMeta ||
      entityMeta.grantsMeta.isIssuedByMe ||
      (entityMeta.grantsMeta.isConsumedByMe &&
        !!currentTenant?.id &&
        !!entityMeta.grantsMeta.tenantPermissionsMap &&
        !!entityMeta.grantsMeta.tenantPermissionsMap[currentTenant.id] &&
        !!entityMeta.grantsMeta.tenantPermissionsMap[currentTenant.id][DataGrantPermission.Read]),
    [entityMeta, currentTenant],
  );

  if (entityRequest) {
    if (entityRequest.isLoading && !entity) {
      return <LinearProgress />;
    }

    if (entityRequest.responseDetails?.isNotFound && !entity) {
      return <EntityNotFoundAlert />;
    }

    if (
      (entityRequest.responseDetails?.isUnauthorized ||
        entityRequest.responseDetails?.isForbidden) &&
      !entity
    ) {
      return <EntityForbiddenAlert />;
    }

    if (!entity) {
      return null;
    }
  }

  return (
    <Box>
      {!canView && <Alert severity='error'>You are not allowed to view this entity.</Alert>}
      {canView && children}
    </Box>
  );
}
