import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";

import { CustomFormikSubProps } from "@/common/helpers/formik";
import { TypeHelper } from "@/common/helpers/type";
import { ValidationHelper } from "@/common/validation";
import {
  GeneralCurrencyDto,
  GeneralCurrencyInputDto,
  RepairSpecItemCustomItemInputDto,
} from "@/core/api/generated";

import ApiEnumSelect from "../../Enum/ApiEnumSelect";
import PercentInput from "../../Form/Input/PercentInput";
import PriceSummaryInput from "../../PriceSummary/PriceSummaryInput";
import AppTypography from "../../Text/AppTypography";

type ValuesType = RepairSpecItemCustomItemInputDto & {
  total?: number | null;
};

const defaultDisplayProps = {
  placeholder: true,
};

interface OwnProps {
  formikProps: CustomFormikSubProps<ValuesType | null | undefined>;
  currency: GeneralCurrencyDto | GeneralCurrencyInputDto | null | undefined;
  withGeneralValidationError?: boolean;
  disabled?: boolean;
  size?: TextFieldProps["size"];
  displayProps?: Partial<typeof defaultDisplayProps>;
  onChange: (newValue: ValuesType) => void;
}

type Props = OwnProps;

export default function RepairSpecItemCustomItemInput({
  formikProps,
  currency,
  withGeneralValidationError = true,
  disabled = false,
  size,
  displayProps = defaultDisplayProps,
  onChange,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const { values, errors, setFieldValue } = formikProps;

  return (
    <Stack spacing={1}>
      <Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xxs: "1fr",
              md: "1fr 1fr 1fr",
            },
            gap: 1,
          }}
        >
          <TextField
            error={Boolean(errors?.name)}
            fullWidth
            required
            disabled={disabled}
            helperText={errors?.name}
            label='Name'
            margin='dense'
            size={size}
            onChange={(e) => {
              setFieldValue("name", e.target.value || undefined);
            }}
            type='text'
            value={values?.name || ""}
            variant='outlined'
          />

          <FormControl fullWidth margin='dense' size={size} error={Boolean(errors?.unit)}>
            <InputLabel required>Unit</InputLabel>
            <ApiEnumSelect
              type='MeasurementUnit'
              value={values?.unit}
              onChange={(newValue) => {
                setFieldValue("unit", newValue);
              }}
              selectProps={{
                label: "Unit",
                required: true,
                disabled: disabled,
                size: size,
              }}
            />
            <FormHelperText>{errors?.unit}</FormHelperText>
          </FormControl>

          <PercentInput
            error={Boolean(errors?.expendablesPercent)}
            fullWidth
            disabled={disabled}
            helperText={errors?.expendablesPercent}
            label={
              <AppTypography
                component='span'
                isInheritFontStyle
                decoration={{ variant: "helpText" }}
                tooltipProps={{
                  title:
                    "Percentage of expendables included in the final price. I.e. SubTotal = Price + (Price * ExpendablesPercent).",
                }}
              >
                Included expendables
              </AppTypography>
            }
            margin='dense'
            size={size}
            value={values?.expendablesPercent}
            variant='outlined'
            onChange={(e) => {
              setFieldValue(
                `expendablesPercent`,
                TypeHelper.isNil(e.target.value) || TypeHelper.isEmptyString(e.target.value)
                  ? undefined
                  : +e.target.value,
              );
            }}
          />
        </Box>

        <FormControl fullWidth margin='dense'>
          <PriceSummaryInput
            values={{
              currency: currency || undefined,
              subTotal: values?.price,
              discount: values?.discount,
              tax: values?.tax,
              total: values?.total ?? undefined,
            }}
            formikProps={{
              errors: {
                subTotal: errors?.price,
                discount: errors?.discount,
                tax: errors?.tax,
                total: errors?.total,
              },
            }}
            displayProps={{
              insurance: false,
              calcExplanationInline: false,
              calcExplanationModal: true,
            }}
            inputsProps={{
              all: {
                margin: "none",
                size: size,
                disabled: disabled,
              },
              subTotal: {
                label: "Price",
                required: true,
              },
              total: {
                required: true,
              },
            }}
            onChange={(newValue) => {
              setFieldValue(`currency`, newValue?.currency);
              setFieldValue(`price`, newValue?.subTotal);
              setFieldValue(`discount`, newValue?.discount);
              setFieldValue(`tax`, newValue?.tax);
              setFieldValue(`total`, newValue?.total);
            }}
          />
        </FormControl>

        <FormControl margin='dense' fullWidth={false} error={Boolean(errors?.isDividable)}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values?.isDividable ?? false}
                size={size}
                disabled={disabled}
                onChange={(e) => {
                  setFieldValue("isDividable", e.target.checked);
                }}
              />
            }
            label={
              <Typography component='span' sx={{ display: "flex" }}>
                Is dividable on parts or pieces
              </Typography>
            }
          />
          <FormHelperText error={Boolean(errors?.isDividable)}>
            {errors?.isDividable}
          </FormHelperText>
        </FormControl>

        {withGeneralValidationError && (
          <FormHelperText error={!!errors}>
            {ValidationHelper.getFormikErrorsAsString(errors, { isIncludeNested: false })}
          </FormHelperText>
        )}
      </Box>
    </Stack>
  );
}
