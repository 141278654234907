import { Button, DialogActions, DialogContent, FormControl } from "@mui/material";
import { getIn, useFormik } from "formik";

import AppTextArea from "@/common/components/Form/Input/AppTextArea";
import MileageInput from "@/common/components/Form/Input/MileageInput";
import AppModal, { AppModalProps } from "@/common/components/Modals/AppModal";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { UpdateVehicleMileageDto, VehicleDto } from "@/core/api/generated";

interface Props extends Omit<AppModalProps, "children"> {
  vehicle: VehicleDto;
  onMileageChanged?: () => void;
}

export default function UpdateMileageModal({
  trigger,
  vehicle,
  open,
  onOpen = () => {},
  onClose = () => {},
  onMileageChanged = () => {},
}: Props) {
  const formik = useFormik<UpdateVehicleMileageDto>({
    enableReinitialize: true,
    initialValues: {
      vehicleId: vehicle.id!,
      mileage: vehicle.mileage || undefined,
    },
    onSubmit: async (values, { setSubmitting, setFieldError, setStatus }) => {
      setSubmitting(true);
      try {
        await apiClient.vehiclesApi.apiV1VehiclesVehicleIdMileagePut({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          vehicleId: vehicle.id!,
          updateVehicleMileageDto: values,
        });
        onMileageChanged();
        onClose();
      } catch (err: any) {
        ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
        setStatus({ success: false });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue,
    isSubmitting,
  } = formik;

  return (
    <AppModal
      fullWidth
      maxWidth='md'
      trigger={trigger}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
    >
      <AppModalTitle title={"Update vehicle mileage"} withCloseIcon onCloseClicked={onClose} />

      <DialogContent>
        {/* Mileage */}
        <MileageInput
          error={Boolean(touched.mileage && errors.mileage)}
          helperText={touched.mileage && errors.mileage}
          vehicle={vehicle}
          disabled={!vehicle}
          fullWidth
          name='mileage'
          onBlur={handleBlur}
          onChange={(newValue) => {
            setValues({
              ...values,
              mileage: {
                ...newValue,
                notes: values.mileage?.notes,
              },
            });
          }}
          value={values.mileage}
        />
        {/* Notes */}
        <FormControl fullWidth margin='dense'>
          <AppTextArea
            mode='notes'
            name='mileage.notes'
            error={Boolean(getIn(errors, "mileage.notes") && getIn(touched, "mileage.notes"))}
            rows={4}
            value={values.mileage?.notes}
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e);
              setFieldValue("mileage.notes", e.target.value);
            }}
          />
        </FormControl>
      </DialogContent>

      <DialogActions sx={{ mb: 1 }}>
        <Button variant='outlined' color='text' sx={{ minWidth: 200 }} onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          variant='contained'
          loading={isSubmitting}
          sx={{ minWidth: 200 }}
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </AppModal>
  );
}
