import { combineReducers } from "@reduxjs/toolkit";

import { subscriptionPlansReducer } from "./subscriptionPlans/slice";
import { tenantAccountReducer } from "./tenantAccount/slice";
import { tenantInvitesReducer } from "./tenantInvites/slice";
import { usersReducer } from "./users/slice";

export const managementReducer = combineReducers({
  subscriptionPlans: subscriptionPlansReducer,
  users: usersReducer,
  tenantAccount: tenantAccountReducer,
  tenantInvites: tenantInvitesReducer,
});
