import {
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
} from "@mui/material";
import { useCallback, useMemo, useRef, useState } from "react";

import { FileItem } from "@/common/fileItem";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { enumService } from "@/common/services/enum";
import { apiClient } from "@/core/api/ApiClient";
import {
  GeneralAttachmentInputDto,
  MileageUnitType,
  VehicleDto,
  VehicleMileageInputDto,
} from "@/core/api/generated";

import AppIconButton from "../../Button/AppIconButton";
import DropdownButton from "../../Button/DropdownButton";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import FileItemView from "../../Files/FileItemView";
import FileUploader from "../../Files/FileUploader";
import AppIcon from "../../Icons/AppIcon";
import FullScreenFileViewerV2, { FullScreenFileActions } from "../../Images/FullScreenFileViewerV2";
import AppModal from "../../Modals/AppModal";
import AppModalContent from "../../Modals/AppModalContent";
import AppModalTitle from "../../Modals/AppModalTitle";
import AppTypography from "../../Text/AppTypography";
import InfoTile from "../Display/InfoTile";
import NumberInput, { NumberInputProps } from "./NumberInput";

interface MileageInputProps extends Omit<NumberInputProps, "inputMode" | "value" | "onChange"> {
  vehicleId?: string;
  vehicle?: VehicleDto;
  value?: VehicleMileageInputDto;
  onChange?: (value?: VehicleMileageInputDto) => void;
}
export default function MileageInput({
  vehicleId,
  vehicle,
  value,
  onChange,
  disabled,
  error,
  helperText,
  ...other
}: MileageInputProps) {
  const fileItemsRef = useRef<FileItem[]>([]);

  const [fsFiles, setFsFiles] = useState<FileItem[]>([]);
  const [fsFileId, setFsFileId] = useState<string>();

  const [isAttachmentsModalOpened, setIsAttachmentsModalOpened] = useState(false);

  const vehicleRequest = useApiRequest(
    apiClient.vehiclesApi.apiV1VehiclesVehicleIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: vehicleId!,
    },
    {
      skip: !vehicleId || !!vehicle || disabled,
    },
  );

  const lastMileage = useMemo(
    () => (vehicle || vehicleRequest.data)?.mileage,
    [vehicleRequest.data, vehicle],
  );

  const isShouldAddAttachment = useMemo(
    () => value && lastMileage && lastMileage.mileage! > value.mileage!,
    [value, lastMileage],
  );

  const handleChangeMileage = useCallback(
    (mileage: number) => {
      onChange &&
        onChange({
          ...(value || {}),
          mileage: mileage,
          unit: value?.unit || MileageUnitType.Km,
        });
    },
    [onChange, value],
  );

  const handleChangeAttachments = useCallback(
    (attachments: GeneralAttachmentInputDto[]) => {
      onChange && onChange({ ...value, attachments } as VehicleMileageInputDto);
    },
    [onChange],
  );

  const handleChangeUnit = useCallback(
    (unit: MileageUnitType) => {
      onChange &&
        onChange({ ...value, unit, mileage: value ? value.mileage : 0 } as VehicleMileageInputDto);
    },
    [onChange],
  );

  return (
    <>
      <Stack direction='column' spacing={1}>
        <Stack direction='row' spacing={2} sx={{ alignItems: "center" }}>
          {/* Mileage and Unit */}
          <FormControl fullWidth={other.fullWidth} error={error}>
            <NumberInput
              {...other}
              disabled={disabled}
              label='Mileage'
              margin='dense'
              inputMode='numeric'
              value={value?.mileage}
              // defaultValue={0} // this make input uncontrolled
              onChange={(e) => handleChangeMileage(+e.target.value)}
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <AppIcon of='mileage' />
                  </InputAdornment>
                ),
                endAdornment: value?.unit && (
                  <InputAdornment position='end'>
                    <DropdownButton
                      disabled={disabled}
                      buttonProps={{
                        variant: "text",
                        color: "text",
                      }}
                      dropdownContent={
                        <MenuList>
                          {enumService
                            .getEnumDtos("MileageUnitType", { exceptNone: true })
                            .map((x, i) => (
                              <MenuItem
                                key={i}
                                onClick={() => handleChangeUnit(x.value as MileageUnitType)}
                              >
                                <ListItemText>{x.name}</ListItemText>
                              </MenuItem>
                            ))}
                        </MenuList>
                      }
                    >
                      <InlineApiEnumValue type='MileageUnitType' value={value?.unit} />
                    </DropdownButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          {/* Attachments */}
          {isShouldAddAttachment && (!value?.attachments || value.attachments.length === 0) && (
            <AppIconButton
              onClick={() => setIsAttachmentsModalOpened(true)}
              size='large'
              sx={{ mt: "4px !important", backgroundColor: "white" }}
            >
              <AppIcon of='addAttachments' />
            </AppIconButton>
          )}
          {isShouldAddAttachment && value?.attachments && value.attachments.length !== 0 && (
            <>
              <AppIconButton
                onClick={() => setIsAttachmentsModalOpened(true)}
                size='large'
                sx={{ mt: "4px !important", backgroundColor: "white" }}
              >
                <AppIcon of='check' sx={{ fill: (th) => th.palette.primary.main }} />
              </AppIconButton>
              <FileItemView
                fileItem={fileItemsRef.current[0]}
                size={{
                  width: 100,
                }}
                actions={{
                  enabled: true,
                  click: true,
                  onClick: () => {
                    setFsFiles(fileItemsRef.current);
                    setFsFileId(fileItemsRef.current[0].id);
                  },
                }}
                imageSx={{
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </>
          )}
          {/* Attachments Modal */}
          <AppModal
            fullWidth
            maxWidth='md'
            open={isAttachmentsModalOpened}
            onClose={() => setIsAttachmentsModalOpened(false)}
            keepMounted
          >
            <AppModalTitle withCloseIcon onCloseClicked={() => setIsAttachmentsModalOpened(false)}>
              Add photo of odometer
            </AppModalTitle>
            <AppModalContent>
              <Stack direction='column' spacing={2}>
                <FileUploader
                  accept='image/*'
                  maxFiles={1}
                  onChange={(newFiles) => {
                    fileItemsRef.current = newFiles;
                    const attachments = FileItem.toManyGeneralAttachmentInputDto(newFiles);
                    handleChangeAttachments(attachments);
                  }}
                  fileUploadListProps={{
                    itemActions: (item) => ({
                      enabled: true,
                      remove: true,
                      onRemove: () => {
                        fileItemsRef.current = (fileItemsRef.current || []).filter(
                          (x) => x.id !== item.id,
                        );
                        handleChangeAttachments([]);
                      },
                    }),
                  }}
                />
                <Stack direction='row' justifyContent='flex-end'>
                  <Button
                    variant='contained'
                    onClick={() => setIsAttachmentsModalOpened(false)}
                    sx={{ width: 100 }}
                  >
                    Save
                  </Button>
                </Stack>
              </Stack>
            </AppModalContent>
          </AppModal>
          {/* Previously reported Mileage */}
          {lastMileage && (
            <InfoTile
              label='Last Mileage'
              sx={{
                height: "53px",
                mt: "4px !important",
                width: "15vw",
                backgroundColor: "white",
                border: "none",
                borderLeft: (th) => `3px solid ${th.palette.divider}`,
              }}
            >
              <AppTypography>
                {lastMileage.mileage} {lastMileage.unit}
              </AppTypography>
            </InfoTile>
          )}
        </Stack>
        {/* Helper text */}
        <FormHelperText error={error}>{helperText || ""}</FormHelperText>
        {/* Warning alert */}
        {isShouldAddAttachment && (
          <Stack
            direction='row'
            spacing={2}
            sx={{
              p: 2,
              borderRadius: "5px",
              backgroundColor: (th) => th.palette.warning.light,
              borderLeft: (th) => `3px solid ${th.palette.warning.main}`,
            }}
          >
            <AppIcon of='warning' />
            <AppTypography>
              The mileage you&apos;ve entered is less than previously reported. You can still save
              the mileage you&apos;ve entered if it is correct, by attaching a photo of the odometer
            </AppTypography>
          </Stack>
        )}
      </Stack>
      {/* Fullscreen file viewer */}
      <FullScreenFileViewerV2
        files={fsFiles}
        selectedFileId={fsFileId}
        actions={(item) =>
          ({
            download: true,
          }) as FullScreenFileActions
        }
        onSelectFile={(file) => {
          setFsFileId(file.id);
        }}
        onClose={() => {
          setFsFiles([]);
          setFsFileId(undefined);
        }}
      />
    </>
  );
}
