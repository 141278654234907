import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import _ from "lodash";
import * as Yup from "yup";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import useMounted from "@/common/hooks/mount/useMounted";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { ChatDto, UpdateChatDto } from "@/core/api/generated";
import * as chatsSlice from "@/store/communication/chatsSlice";

import GeneralValidationError from "../../Error/GeneralValidationError";
import AppTextArea from "../../Form/Input/AppTextArea";

interface Props {
  open: boolean;
  onClose?: () => void;
  onUpdated?: (newValues?: ChatDto) => void;

  chat: ChatDto;
}

function UpdateChatModal({ open, onClose, onUpdated, chat }: Props) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const thunkDispatch = useAppThunkDispatch();

  const canEdit = chat.settings!.allowEdit!;

  return (
    <Box>
      <Dialog open={open} onClose={onClose}>
        <AppModalTitle onCloseClicked={() => onClose && onClose()}>Edit chat</AppModalTitle>
        <DialogContent>
          <Formik<UpdateChatDto & BaseFormikValues>
            enableReinitialize
            initialValues={{
              name: chat?.name || "",
              description: chat?.description || "",
              settings: chat?.settings || undefined,
              submit: "",
            }}
            validationSchema={Yup.object().shape({
              // name: Yup.string().required("Name is required"),
            })}
            onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
              try {
                const updatedChat = await thunkDispatch(
                  chatsSlice.updateChat({
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    chatId: chat!.id!,
                    updateChatDto: {
                      ..._.omit(values, "submit"),
                    },
                  }),
                );
                enqueueSnackbar("Chat updated.", { variant: "success" });

                if (mounted.current) {
                  setStatus({ success: true });
                  setSubmitting(false);
                }

                onUpdated && onUpdated(updatedChat);
              } catch (err: any) {
                if (mounted.current) {
                  ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
                  setStatus({ success: false });
                  setSubmitting(false);
                }
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setErrors,
              setFieldValue,
              setValues,
            }) => {
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <Box>
                    {!canEdit && <Alert severity='warning'>Edit is disabled for this chat!</Alert>}

                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      disabled={!canEdit}
                      helperText={touched.name && errors.name}
                      label='Name'
                      margin='normal'
                      name='name'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='text'
                      value={values.name}
                      variant='outlined'
                    />

                    <AppTextArea
                      error={Boolean(touched.description && errors.description)}
                      fullWidth
                      disabled={!canEdit}
                      helperText={touched.description && errors.description}
                      mode='description'
                      margin='normal'
                      name='description'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      variant='outlined'
                    />

                    {/* Settings */}
                    <Box sx={{ mt: 2 }}>
                      <Typography component='div' variant='subtitle1'>
                        Chat settings
                      </Typography>

                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={!canEdit}
                              checked={values.settings?.allowEditMessages || false}
                              onBlur={handleBlur}
                              onChange={(e) =>
                                setFieldValue("settings.allowEditMessages", e.target.checked)
                              }
                            />
                          }
                          label='Allow edit messages'
                        />
                      </FormGroup>

                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={!canEdit}
                              checked={values.settings?.allowDeleteMessages || false}
                              onBlur={handleBlur}
                              onChange={(e) =>
                                setFieldValue("settings.allowDeleteMessages", e.target.checked)
                              }
                            />
                          }
                          label='Allow delete messages'
                        />
                      </FormGroup>

                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={!canEdit}
                              checked={values.settings?.isNotifyAboutNewMessageViaEmail || false}
                              onBlur={handleBlur}
                              onChange={(e) =>
                                setFieldValue(
                                  "settings.isNotifyAboutNewMessageViaEmail",
                                  e.target.checked,
                                )
                              }
                            />
                          }
                          label='Notify participants about new messages via email'
                        />
                      </FormGroup>
                    </Box>
                  </Box>

                  <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                  <Stack direction='row' spacing={1} sx={{ justifyContent: "flex-end" }}>
                    <Button variant='outlined' color='text' onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      loading={isSubmitting}
                      disabled={!chat}
                      type='submit'
                    >
                      Save
                    </Button>
                  </Stack>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default UpdateChatModal;
