import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { useMemo, useState } from "react";

import { TypeHelper } from "@/common/helpers/type";
import { apiClient } from "@/core/api/ApiClient";
import { DamageDetectionDto, EntityType } from "@/core/api/generated";

import FoldableBlock from "../../Display/FoldableBlock";
import AppTypography from "../../Text/AppTypography";
import VehicleDamageLink from "../VehicleDamage/VehicleDamageLink";
import BaseEntitiesDeleteModal, {
  BaseEntitiesDeleteModalInheritableProps,
} from "../components/BaseEntitiesDeleteModal";
import DamageDetectionLink from "./DamageDetectionLink";

export interface OwnProps {
  entities: DamageDetectionDto[];
}

type Props = OwnProps & BaseEntitiesDeleteModalInheritableProps;

export default function DamageDetectionsDeleteModal({ entities, ...otherProps }: Props) {
  const [isDeleteVehicleDamages, setIsDeleteVehicleDamages] = useState(false);

  const entityAndDamagesPairs = useMemo(
    () =>
      entities
        .map((entity) => ({
          entity,
          damages: entity.items?.map((x) => x.damage!).filter(Boolean) ?? [],
        }))
        .filter((x) => TypeHelper.isNotEmptyArray(x.damages)),
    [entities],
  );

  return (
    <BaseEntitiesDeleteModal
      cascadeActionDisplay={true}
      bodyAfter={
        TypeHelper.isNotEmptyArray(entityAndDamagesPairs) && (
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isDeleteVehicleDamages}
                  onChange={(e) => setIsDeleteVehicleDamages(e.target.checked)}
                />
              }
              label='Delete the corresponding damages from the vehicle history'
            />

            <FoldableBlock
              defaultIsFolded
              trigger={{ label: <AppTypography>Damages</AppTypography> }}
            >
              <Box component='ol' sx={{ m: 0 }}>
                {entityAndDamagesPairs.map((pair, i) => (
                  <li key={pair.entity.id ?? i}>
                    <DamageDetectionLink entityId={pair.entity.id} entity={pair.entity} withIcon />

                    <Box component='ol' sx={{ m: 0 }}>
                      {pair.damages.map((damage, j) => (
                        <li key={damage.id ?? j}>
                          <VehicleDamageLink
                            vehicleId={undefined}
                            entityId={damage.id}
                            entity={damage}
                            withIcon
                          />
                        </li>
                      ))}
                    </Box>
                  </li>
                ))}
              </Box>
            </FoldableBlock>
          </Box>
        )
      }
      entityType={EntityType.DamageDetection}
      entities={entities}
      deleteFunc={(params) => {
        apiClient.damageDetectionsApi.apiV1DamageDetectionsBulkDeleteDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          damageDetectionBulkDeleteDto: {
            ids: params.entityIds,
            relatedEntitiesTypes: params.relatedEntitiesTypes,
            isDeleteVehicleDamages: isDeleteVehicleDamages,
          },
        });
      }}
      {...otherProps}
    />
  );
}
