import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid2,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import ViewContentBlockHeader from "@/App/Layouts/ViewContentBlockHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AppTooltip from "@/common/components/AppTooltip";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AuthorizedTenantElement from "@/common/components/Auth/AuthorizedTenantElement";
import AppIconButton from "@/common/components/Button/AppIconButton";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import ContractBlock from "@/common/components/Entity/Contract/ContractBlock";
import GeneralCurrencyDisplay from "@/common/components/Entity/General/Display/GeneralCurrencyDisplay";
import GeneralInspectorDisplay from "@/common/components/Entity/General/Display/GeneralInspectorDisplay";
import { RepairOperationAddVehicleDamagesModal } from "@/common/components/Entity/RepairOperation/RepairOperationAddVehicleDamagesModal";
import CreateUpdateRepairSpecModal from "@/common/components/Entity/RepairSpec/CreateUpdateRepairSpecModal";
import RepairSpecLink from "@/common/components/Entity/RepairSpec/RepairSpecLink";
import VehicleBlock from "@/common/components/Entity/Vehicle/VehicleBlock";
import VehicleDamageLink from "@/common/components/Entity/VehicleDamage/VehicleDamageLink";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FileListView from "@/common/components/Files/FileListView";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import GeneralStageHistory from "@/common/components/GeneralStageHistory";
import AppIcon from "@/common/components/Icons/AppIcon";
import GeneralEntitySourceLink from "@/common/components/Link/GeneralEntitySourceLink";
import AppMenuItem from "@/common/components/Menu/AppMenuItem";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import BaseEditNotesModal from "@/common/components/Modals/common/BaseEditNotesModal";
import AppPopover from "@/common/components/Popover/AppPopover";
import AppPopoverContent from "@/common/components/Popover/AppPopoverContent";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FileItem } from "@/common/fileItem";
import { DamageCostEvaluationHelper } from "@/common/helpers/entity/damageCostEvaluation";
import { renderIf } from "@/common/helpers/render/renderIf";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DamageCostEvaluationDto,
  DamageCostEvaluationStage,
  EntityType,
  RepairSpecDataDto,
  RepairSpecDataInputDto,
  VehicleDamageDto,
} from "@/core/api/generated";

import AffiliationInfoDisplay from "../../../EntityAffiliation/AffiliationInfoDisplay";
import UpdateEntityAffiliationCascadeModal from "../../../EntityAffiliation/UpdateEntityAffiliationCascadeModal";
import RepairSpecDataViewModal from "../../../RepairSpec/View/RepairSpecDataViewModal";
import RepairSpecViewModal from "../../../RepairSpec/View/RepairSpecViewModal";
import TenantInline from "../../../Tenant/TenantInline";
import CurrencyMismatchWithRepairSpecAlertCompact from "../../CurrencyMismatchWithRepairSpecAlertCompact";

interface Props {
  damageCostEvaluation: DamageCostEvaluationDto;
  onUpdate: (newValue?: DamageCostEvaluationDto) => void;
}

export default function OverviewTabContent({ damageCostEvaluation, onUpdate }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();
  const history = useHistory();
  const { hasPermissions } = useAuthorizationInfo();
  const currentTenant = useCurrentTenant();
  const profile = useUserProfile();

  const [isSendToCustomer, setIsSendToCustomer] = useState(
    damageCostEvaluation.isSendToCustomer ?? false,
  );

  // menu, dialogs
  const [isUpdateAffiliationModalOpened, setIsUpdateAffiliationModalOpened] = useState(false);
  const [isAddToRepairOperationModalOpen, setIsAddToRepairOperationModalOpen] = useState(false);
  const [isViewRepairSpecModalOpen, setIsViewRepairSpecModalOpen] = useState(false);
  const [isViewRepairSpecDataModalOpen, setIsViewRepairSpecDataModalOpen] = useState(false);
  const [isCreateRepairSpecModalOpen, setIsCreateRepairSpecModalOpen] = useState(false);
  const [isConfirmCompletedModalOpen, setIsConfirmCompletedModalOpen] = useState(false);
  const [damagesToAddToRepairOperation, setDamagesToAddToRepairOperation] = useState<
    VehicleDamageDto[] | undefined
  >(undefined);
  const [repairSpecIdToView, setRepairSpecIdToView] = useState<string | undefined>(undefined);
  const [repairSpecDataToView, setRepairSpecDataToView] = useState<RepairSpecDataDto | undefined>(
    undefined,
  );
  const [createRepairSpecFromSpecData, setCreateRepairSpecFromSpecData] = useState<
    (RepairSpecDataDto & RepairSpecDataInputDto) | undefined
  >(undefined);

  return (
    <PageTabContent>
      <ViewLayoutV2
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={damageCostEvaluation} />}
          />
        }
      >
        <ViewContentBlock>
          {damageCostEvaluation?.stageHistory?.stage === DamageCostEvaluationStage.Draft && (
            <AuthorizedElement permissions={[AppPermission.DamageCostEvaluationManage]}>
              <Alert severity='info' icon={<AppIcon of='tip' />}>
                This operation is in{" "}
                <strong>
                  <InlineApiEnumValue
                    type='DamageCostEvaluationStage'
                    value={DamageCostEvaluationStage.Draft}
                  />
                </strong>{" "}
                stage. Please review and complete it in order for changes to take effect.
              </Alert>
            </AuthorizedElement>
          )}

          <Stack direction={{ xxs: "column", md: "row" }} alignItems='flex-start' spacing={2}>
            <VehicleBlock
              sx={{ maxWidth: { md: "400px" } }}
              variant='reference'
              vehicle={damageCostEvaluation.vehicle}
              withDetailsToggle
              withLink
            />
            {damageCostEvaluation.contract && (
              <ContractBlock
                sx={{ maxWidth: { md: "400px" } }}
                variant='reference'
                contract={damageCostEvaluation.contract}
                withDetailsToggle
                withLink
              />
            )}
          </Stack>
        </ViewContentBlock>

        {/* Affiliation */}
        <ViewContentBlock>
          <ViewContentBlockHeader>
            Affiliation{" "}
            <AppIconButton
              tooltipProps={{ title: "Edit entity affiliation" }}
              onClick={() => setIsUpdateAffiliationModalOpened(true)}
            >
              <AppIcon of='edit' fontSize='small' />
            </AppIconButton>
          </ViewContentBlockHeader>
          <AffiliationInfoDisplay
            tenantId={damageCostEvaluation.tenantId}
            departmentIds={damageCostEvaluation.departmentIds}
            locationIds={damageCostEvaluation.locationIds}
            sx={{ mt: 1 }}
          />
          <UpdateEntityAffiliationCascadeModal
            open={isUpdateAffiliationModalOpened}
            onClose={() => setIsUpdateAffiliationModalOpened(false)}
            entityType={EntityType.DamageCostEvaluation}
            entityId={damageCostEvaluation.id}
            onUpdate={() => onUpdate()}
          />
        </ViewContentBlock>

        <ViewContentBlock>
          <Box>
            <Grid2 container columnSpacing={2} rowSpacing={1} sx={{ mb: 1 }}>
              <Grid2 size={{ xxs: 12 }} minWidth={200}>
                <Stack direction='column' spacing={1}>
                  <FieldValue label='Source'>
                    <GeneralEntitySourceLink source={damageCostEvaluation.source} />
                  </FieldValue>
                  <FieldValue label='Inspection date' isEmpty={!damageCostEvaluation.inspectedAt}>
                    <Datetime datetime={damageCostEvaluation.inspectedAt} withDurationFromNow />
                  </FieldValue>
                  <FieldValue
                    label='Inspector'
                    isEmpty={!damageCostEvaluation.inspector}
                    ellipsing={{ enabled: true }}
                  >
                    <GeneralInspectorDisplay
                      inspector={damageCostEvaluation.inspector}
                      inlineUserProps={{
                        withAvatar: true,
                        withLink: true,
                      }}
                    />
                  </FieldValue>
                </Stack>
              </Grid2>
              <Grid2 size={{ xxs: 12 }} minWidth={200}>
                <Stack direction='column' spacing={1}>
                  <FieldValue label='Currency'>
                    <GeneralCurrencyDisplay currency={damageCostEvaluation.currency} />
                  </FieldValue>

                  <FieldValue
                    label='Notes'
                    isEmpty={!damageCostEvaluation.notes}
                    forceValueRenderEvenIfEmpty
                  >
                    {damageCostEvaluation.notes ? damageCostEvaluation.notes : "-"}{" "}
                    <BaseEditNotesModal
                      initialValues={{ notes: damageCostEvaluation.notes }}
                      onConfirm={async (notes) => {
                        await apiClient.damageCostEvaluationsApi.apiV1DamageCostEvaluationsDamageCostEvaluationIdNotesPut(
                          {
                            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                            damageCostEvaluationId: damageCostEvaluation.id || "",
                            generalNotesInputDto: { notes },
                          },
                        );
                        onUpdate();
                      }}
                    />
                  </FieldValue>
                </Stack>
              </Grid2>
            </Grid2>
          </Box>
        </ViewContentBlock>

        {damageCostEvaluation.attachments!.length !== 0 && (
          <ViewContentBlockHeader>
            <FileListView
              files={FileItem.createManyFrom(damageCostEvaluation.attachments)}
              maxVisibleFiles={10}
              itemWidth={45}
            />
          </ViewContentBlockHeader>
        )}

        {/* Stages */}
        <ViewContentBlock>
          <Typography variant='h2'>Stages</Typography>
          <GeneralStageHistory
            stageEnumType='DamageCostEvaluationStage'
            stageHistory={damageCostEvaluation.stageHistory}
            withUpdateButtons={hasPermissions([AppPermission.DamageCostEvaluationManage])}
            onUpdateStage={async (newStage) => {
              try {
                const response =
                  await apiClient.damageCostEvaluationsApi.apiV1DamageCostEvaluationsDamageCostEvaluationIdStagePut(
                    {
                      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                      damageCostEvaluationId: damageCostEvaluation.id || "",
                      generalStageUpdateDtoOfDamageCostEvaluationStage: {
                        newStage: newStage,
                      },
                    },
                  );
                onUpdate && onUpdate(response.data);
              } catch (err) {
                const validation2 = ValidationHelper.handleApiErrorResponse(err);
                enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
              }
            }}
          />
        </ViewContentBlock>

        <ViewContentBlock>
          {/* Items */}
          <Typography variant='h2'>Items</Typography>

          <DataTabular
            columns={[
              {
                field: "damage",
                title: "Damages",
                flex: 1,
                renderCell: (item) => (
                  <Stack spacing={0}>
                    {item.damages?.map((damage, j) => (
                      <Stack key={j} direction='row' spacing={1} alignItems='center'>
                        <VehicleDamageLink
                          vehicleId={damage.damage?.vehicleId || ""}
                          entity={damage.damage}
                          withIcon
                        />
                        <Typography component='div' variant='body2' color='text.secondary'>
                          (<InlineApiEnumValue type='VehicleArea' value={damage.damage?.area} /> /{" "}
                          {(damage.damage?.projection && (
                            <InlineApiEnumValue
                              type='VehicleProjection'
                              value={damage.damage?.projection}
                            />
                          )) ||
                            "-"}
                          )
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                ),
              },
              {
                field: "name",
                title: "Name",
                flex: 1,
                renderCell: (item) => item.name,
              },
              {
                field: "repairSpec",
                title: "Repair spec",
                flex: 1,
                renderCell: (item) => {
                  const repairSpecPerms = DamageCostEvaluationHelper.getRepairSpecPermissions({
                    tenantId: currentTenant?.id,
                    profile,
                    damageCostEvaluation,
                    item,
                  });
                  if (!repairSpecPerms.canViewAny) {
                    return null;
                  }

                  return (
                    <Box>
                      <Stack spacing={0.5}>
                        <Stack
                          direction='row'
                          spacing={1}
                          sx={{ alignItems: "center", flexWrap: "wrap" }}
                        >
                          {renderIf()
                            .if(repairSpecPerms.canViewLink)
                            .then(<RepairSpecLink entity={item.repairSpec} withIcon />)
                            .elseif(repairSpecPerms.canViewName)
                            .then(item.repairSpec?.name && <Box>{item.repairSpec?.name}</Box>)
                            .else(<Box>{"-"}</Box>)
                            .render()}

                          {repairSpecPerms.canViewIsCreatedLocally && (
                            <Tooltip title='Repair spec was created locally in this damage cost evaluation without creating a dedicated spec.'>
                              <Chip size='small' variant='outlined' label='Created locally' />
                            </Tooltip>
                          )}

                          {repairSpecPerms.canViewIsEditedLocally && (
                            <Tooltip title='Repair spec was edited locally in this damage cost evaluation.'>
                              <Chip size='small' variant='outlined' label='Edited locally' />
                            </Tooltip>
                          )}

                          {repairSpecPerms.canViewIsExternal && (
                            <Tooltip
                              title={
                                <Box>
                                  Repair spec was added from external company{" "}
                                  <TenantInline
                                    entityId={item.repairSpec?.tenantId}
                                    entity={undefined}
                                  />
                                  .
                                </Box>
                              }
                            >
                              <Chip size='small' variant='outlined' label='External' />
                            </Tooltip>
                          )}

                          <Stack direction='row' spacing={0.5} sx={{ flexWrap: "wrap" }}>
                            {/* View repair spec data */}
                            {(repairSpecPerms.canView || repairSpecPerms.canViewData) &&
                              item.repairSpec && (
                                <AppTooltip title='View repair spec details.'>
                                  <AppIconButton size='small'>
                                    <AppIcon
                                      of='view'
                                      onClick={() => {
                                        if (repairSpecPerms.canView) {
                                          setRepairSpecIdToView(item.repairSpec?.id);
                                          setIsViewRepairSpecModalOpen(true);
                                        } else if (repairSpecPerms.canViewData) {
                                          setRepairSpecDataToView(item.repairSpec);
                                          setIsViewRepairSpecDataModalOpen(true);
                                        }
                                      }}
                                    />
                                  </AppIconButton>
                                </AppTooltip>
                              )}

                            {/* Create repair spec from spec data */}
                            {repairSpecPerms.canSaveDataAsNewSpec && item.repairSpec && (
                              <Tooltip title='Create new repair spec based on local evaluation data.'>
                                <AppIconButton
                                  size='small'
                                  onClick={() => {
                                    setCreateRepairSpecFromSpecData(item.repairSpec);
                                    setIsCreateRepairSpecModalOpen(true);
                                  }}
                                >
                                  <AppIcon of='create' />
                                </AppIconButton>
                              </Tooltip>
                            )}
                          </Stack>
                        </Stack>
                      </Stack>
                    </Box>
                  );
                },
              },
              {
                field: "attachments",
                title: "Attachments",
                flex: 0,
                renderCell: (item) => (
                  <>
                    {!_.isEmpty(item.attachments) ? (
                      <FileListView
                        files={FileItem.createManyFrom(item.attachments)}
                        maxVisibleFiles={2}
                        itemWidth={45}
                      />
                    ) : (
                      "-"
                    )}
                  </>
                ),
              },
              {
                field: "notes",
                title: "Notes",
                flex: 0,
                renderCell: (item) =>
                  item.notes ? (
                    <AppTooltip title={item.notes || ""}>
                      <AppTypography ellipsing={{ enabled: true }}>{item.notes}</AppTypography>
                    </AppTooltip>
                  ) : (
                    "-"
                  ),
              },
              {
                field: "total",
                title: "Total",
                flex: 0,
                renderCell: (item) => (
                  <AppPopover
                    hoverBehavior={{}}
                    trigger={
                      <AppTypography decoration={{ variant: "helpText" }}>
                        <CurrencyValue
                          value={item.total}
                          currency={damageCostEvaluation.currency}
                        />
                      </AppTypography>
                    }
                  >
                    <AppPopoverContent>
                      <GeneralPriceSummaryDisplay
                        direction='column'
                        summary={{
                          currency: damageCostEvaluation.currency,
                          subTotal: item.subTotal,
                          discount: item.discount || undefined,
                          tax: item.tax || undefined,
                          total: item.total || 0,
                        }}
                      />
                    </AppPopoverContent>
                  </AppPopover>
                ),
              },
              {
                field: "alerts",
                title: "Alerts",
                flex: 0,
                renderCell: (item) => (
                  <>
                    {/* Currency mismatch alert */}
                    {damageCostEvaluation.currency && (
                      <CurrencyMismatchWithRepairSpecAlertCompact
                        entity={damageCostEvaluation}
                        item={item}
                      />
                    )}
                  </>
                ),
              },
            ]}
            rows={damageCostEvaluation.items!}
            getRowId={(item) => item.id!}
            borderVariant='bordered'
            getRowHeight={() => "auto"}
            rowHeight={65}
            isColumnSelectorEnabled={false}
            isColumnMenuEnabled={false}
            isPaginationEnabled={false}
            isWithScrollHelpers={false}
            renderRowAction={(actionParams) => (
              <MenuWithTrigger
                withAuthCloseOnClick
                trigger={
                  <IconButton>
                    <AppIcon of='moreVert' />
                  </IconButton>
                }
              >
                <AppMenuItem
                  permissionsAny={[
                    AppPermission.RepairOperationPerform,
                    AppPermission.RepairOperationManage,
                  ]}
                  onClick={() => {
                    setIsAddToRepairOperationModalOpen(true);
                    setDamagesToAddToRepairOperation(
                      actionParams.item.damages?.map((x) => x.damage!)?.filter(Boolean),
                    );
                  }}
                >
                  <ListItemIcon>
                    <AppIcon of='add' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Add damage to repair operation</ListItemText>
                </AppMenuItem>
              </MenuWithTrigger>
            )}
          />
        </ViewContentBlock>

        {/* Summary */}
        {damageCostEvaluation.items!.length !== 0 && (
          <ViewContentBlock
            direction='row'
            justifyContent='flex-end'
            sx={{ width: "fit-content", alignSelf: "flex-end" }}
          >
            <GeneralPriceSummaryDisplay
              summary={{
                currency: damageCostEvaluation.currency,
                subTotal: damageCostEvaluation.subTotal,
                subTotalIncDiscount: damageCostEvaluation.subTotalIncDiscount,
                discount: damageCostEvaluation.discount,
                tax: damageCostEvaluation.tax,
                total: damageCostEvaluation.total,
              }}
            />
          </ViewContentBlock>
        )}

        {/* Complete helper buttons */}
        <AuthorizedTenantElement tenantIds={[damageCostEvaluation?.tenantId]}>
          <AuthorizedElement permissions={[AppPermission.DamageCostEvaluationManage]}>
            {damageCostEvaluation.stageHistory!.stage !== DamageCostEvaluationStage.Completed && (
              <Stack direction='row' spacing={1} sx={{ mt: 2, mb: 20 }}>
                <Button
                  sx={{ minWidth: { md: 200 } }}
                  variant='outlined'
                  component={RouterLink}
                  to={ROUTE_PATH.DAMAGE_COST_EVALUATION_EDIT(damageCostEvaluation.id)}
                >
                  Edit
                </Button>

                <Button
                  variant='contained'
                  color='primary'
                  fullWidth
                  onClick={() => {
                    setIsConfirmCompletedModalOpen(true);
                  }}
                >
                  Complete
                </Button>

                {/* <SplitDropdownButton
                    sx={{ flex: 1 }}
                    isSelectAndTriggerAction={false}
                    buttonProps={{
                      variant: "contained",
                      color: "primary",
                      fullWidth: true,
                    }}
                    options={[
                      {
                        content: "Complete",
                        onClick: () => {
                          setIsSendToCustomer(false);
                          setIsConfirmCompletedModalOpen(true);
                        },
                      },
                      {
                        content: "Complete & send to customer",
                        if: !!damageCostEvaluation.contract,
                        onClick: () => {
                          setIsSendToCustomer(true);
                          setIsConfirmCompletedModalOpen(true);
                        },
                      },
                    ]}
                  /> */}
              </Stack>
            )}
          </AuthorizedElement>
        </AuthorizedTenantElement>

        {/* Confirm complete */}
        {damageCostEvaluation && (
          <ConfirmationModal
            title='Complete the cost evaluation?'
            body={
              <>
                <Box>
                  {`You're going to complete the damage cost evaluation`}{" "}
                  <strong>{damageCostEvaluation.localNumber}</strong>.
                </Box>

                <FormControl margin='dense' fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isSendToCustomer}
                        onChange={(e) => setIsSendToCustomer(e.target.checked)}
                      />
                    }
                    label='Send to customer'
                  />
                </FormControl>
              </>
            }
            open={isConfirmCompletedModalOpen}
            onClose={() => setIsConfirmCompletedModalOpen(false)}
            onCancel={() => setIsConfirmCompletedModalOpen(false)}
            onConfirm={async () => {
              try {
                const response =
                  await apiClient.damageCostEvaluationsApi.apiV1DamageCostEvaluationsDamageCostEvaluationIdStageCompletePost(
                    {
                      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                      damageCostEvaluationId: damageCostEvaluation.id || "",
                      damageCostEvaluationCompleteDto: {
                        isSendToCustomer: isSendToCustomer,
                      },
                    },
                  );
                onUpdate && onUpdate(response.data);
                setIsConfirmCompletedModalOpen(false);
                history.goBack();
              } catch (err) {
                const validation2 = ValidationHelper.handleApiErrorResponse(err);
                enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
                throw err;
              }
            }}
          />
        )}

        {/* View repair spec */}
        {repairSpecIdToView && (
          <RepairSpecViewModal
            open={isViewRepairSpecModalOpen}
            onClose={() => {
              setIsViewRepairSpecModalOpen(false);
              setRepairSpecIdToView(undefined);
            }}
            viewProps={{
              repairSpecId: repairSpecIdToView,
            }}
          />
        )}

        {/* View repair spec data */}
        {repairSpecDataToView && (
          <RepairSpecDataViewModal
            open={isViewRepairSpecDataModalOpen}
            onClose={() => {
              setIsViewRepairSpecDataModalOpen(false);
              setRepairSpecDataToView(undefined);
            }}
            viewProps={{
              repairSpec: repairSpecDataToView,
            }}
          />
        )}

        {/* Create repair spec from local repair spec data */}
        {createRepairSpecFromSpecData && (
          <CreateUpdateRepairSpecModal
            open={isCreateRepairSpecModalOpen}
            onClose={() => {
              setIsCreateRepairSpecModalOpen(false);
              setCreateRepairSpecFromSpecData(undefined);
            }}
            createUpdateProps={{
              defaultValues: {
                data: createRepairSpecFromSpecData,
              },
              onSave: () => {
                setIsCreateRepairSpecModalOpen(false);
                setCreateRepairSpecFromSpecData(undefined);
              },
            }}
          />
        )}

        {/* Add to repair operation */}
        {damagesToAddToRepairOperation && (
          <RepairOperationAddVehicleDamagesModal
            open={isAddToRepairOperationModalOpen}
            onClose={() => setIsAddToRepairOperationModalOpen(false)}
            damages={damagesToAddToRepairOperation}
          />
        )}
      </ViewLayoutV2>
    </PageTabContent>
  );
}
