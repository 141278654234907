import AssetSubscriptionPaginatedList from "@/common/components/Entity/AssetSubscription/ListView/AssetSubscriptionPaginatedList";
import { FilterDefinition } from "@/common/filters/filterDefinition";
import { useLocationState } from "@/common/hooks/useLocationParams";
import {
  ContractProductsSubscriptionsStateFilterType,
  FilterDefinitionDto,
} from "@/core/api/generated";

export interface AssetSubscriptionListPageLocationState {
  initialValues?: {
    filterDefinitionDto?: Nil<FilterDefinitionDto>;
    filterDefinition?: Nil<FilterDefinition>;
    filterType?: ContractProductsSubscriptionsStateFilterType;
  };
}

export default function AssetSubscriptionsPage() {
  const locationState = useLocationState<AssetSubscriptionListPageLocationState>();

  return (
    <>
      <AssetSubscriptionPaginatedList
        displayProps={{
          breadcrumbs: true,
          filters: true,
        }}
        initialValues={locationState?.initialValues}
      />
    </>
  );
}
