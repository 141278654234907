import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function RequestsIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      width='20'
      height='22'
      viewBox='0 0 20 22'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <path d='M4.6155 9.61536H0.846267C0.388574 9.61536 0.0385742 9.96536 0.0385742 10.423V21.1923C0.0385742 21.3269 0.17319 21.4615 0.307805 21.4615H1.38473C1.54627 21.4615 1.65396 21.3538 1.65396 21.1923V19.5769C1.65396 19.4154 1.76165 19.3077 1.92319 19.3077H3.53857C3.70011 19.3077 3.8078 19.4154 3.8078 19.5769V21.1923C3.8078 21.3538 3.9155 21.4615 4.07704 21.4615H4.88473C5.18088 21.4615 5.42319 21.2192 5.42319 20.923V10.423C5.42319 9.96536 5.07319 9.61536 4.6155 9.61536ZM2.19242 18.0961C2.19242 18.1769 2.13857 18.2307 2.0578 18.2307H1.25011C1.16934 18.2307 1.1155 18.1769 1.1155 18.0961V16.75C1.1155 16.6692 1.16934 16.6154 1.25011 16.6154H2.0578C2.13857 16.6154 2.19242 16.6692 2.19242 16.75V18.0961ZM2.19242 15.4038C2.19242 15.4846 2.13857 15.5384 2.0578 15.5384H1.25011C1.16934 15.5384 1.1155 15.4846 1.1155 15.4038V14.0577C1.1155 13.9769 1.16934 13.923 1.25011 13.923H2.0578C2.13857 13.923 2.19242 13.9769 2.19242 14.0577V15.4038ZM2.19242 12.7115C2.19242 12.7923 2.13857 12.8461 2.0578 12.8461H1.25011C1.16934 12.8461 1.1155 12.7923 1.1155 12.7115V11.3654C1.1155 11.2846 1.16934 11.2307 1.25011 11.2307H2.0578C2.13857 11.2307 2.19242 11.2846 2.19242 11.3654V12.7115ZM4.34627 18.0961C4.34627 18.1769 4.29242 18.2307 4.21165 18.2307H3.40396C3.32319 18.2307 3.26934 18.1769 3.26934 18.0961V16.75C3.26934 16.6692 3.32319 16.6154 3.40396 16.6154H4.21165C4.29242 16.6154 4.34627 16.6692 4.34627 16.75V18.0961ZM4.34627 15.4038C4.34627 15.4846 4.29242 15.5384 4.21165 15.5384H3.40396C3.32319 15.5384 3.26934 15.4846 3.26934 15.4038V14.0577C3.26934 13.9769 3.32319 13.923 3.40396 13.923H4.21165C4.29242 13.923 4.34627 13.9769 4.34627 14.0577V15.4038ZM4.34627 12.7115C4.34627 12.7923 4.29242 12.8461 4.21165 12.8461H3.40396C3.32319 12.8461 3.26934 12.7923 3.26934 12.7115V11.3654C3.26934 11.2846 3.32319 11.2307 3.40396 11.2307H4.21165C4.29242 11.2307 4.34627 11.2846 4.34627 11.3654V12.7115Z' />
      <path d='M11.0772 12.3077H7.30794C6.85024 12.3077 6.50024 12.6577 6.50024 13.1154V21.1924C6.50024 21.327 6.63486 21.4616 6.76947 21.4616H7.8464C8.00794 21.4616 8.11563 21.3539 8.11563 21.1924V19.577C8.11563 19.4154 8.22332 19.3077 8.38486 19.3077H10.0002C10.1618 19.3077 10.2695 19.4154 10.2695 19.577V21.1924C10.2695 21.3539 10.3772 21.4616 10.5387 21.4616H11.3464C11.6426 21.4616 11.8849 21.2193 11.8849 20.9231V13.1154C11.8849 12.6577 11.5349 12.3077 11.0772 12.3077ZM8.65409 18.0962C8.65409 18.177 8.60024 18.2308 8.51947 18.2308H7.71178C7.63101 18.2308 7.57717 18.177 7.57717 18.0962V16.75C7.57717 16.6693 7.63101 16.6154 7.71178 16.6154H8.51947C8.60024 16.6154 8.65409 16.6693 8.65409 16.75V18.0962ZM8.65409 15.4039C8.65409 15.4847 8.60024 15.5385 8.51947 15.5385H7.71178C7.63101 15.5385 7.57717 15.4847 7.57717 15.4039V14.0577C7.57717 13.977 7.63101 13.9231 7.71178 13.9231H8.51947C8.60024 13.9231 8.65409 13.977 8.65409 14.0577V15.4039ZM10.8079 18.0962C10.8079 18.177 10.7541 18.2308 10.6733 18.2308H9.86563C9.78486 18.2308 9.73101 18.177 9.73101 18.0962V16.75C9.73101 16.6693 9.78486 16.6154 9.86563 16.6154H10.6733C10.7541 16.6154 10.8079 16.6693 10.8079 16.75V18.0962ZM10.8079 15.4039C10.8079 15.4847 10.7541 15.5385 10.6733 15.5385H9.86563C9.78486 15.5385 9.73101 15.4847 9.73101 15.4039V14.0577C9.73101 13.977 9.78486 13.9231 9.86563 13.9231H10.6733C10.7541 13.9231 10.8079 13.977 10.8079 14.0577V15.4039Z' />
      <path d='M14.6449 2.73843C14.3045 2.73843 13.9781 2.87364 13.7375 3.11431C13.4968 3.35498 13.3616 3.68141 13.3616 4.02177C13.3616 4.11901 13.4002 4.21228 13.469 4.28104C13.5377 4.34981 13.631 4.38844 13.7282 4.38844C13.8255 4.38844 13.9188 4.34981 13.9875 4.28104C14.0563 4.21228 14.0949 4.11901 14.0949 4.02177C14.0949 3.91299 14.1272 3.80665 14.1876 3.7162C14.248 3.62576 14.3339 3.55526 14.4344 3.51363C14.5349 3.472 14.6455 3.46111 14.7522 3.48233C14.8589 3.50356 14.9569 3.55594 15.0338 3.63286C15.1107 3.70978 15.1631 3.80778 15.1843 3.91447C15.2056 4.02116 15.1947 4.13174 15.153 4.23224C15.1114 4.33274 15.0409 4.41864 14.9505 4.47908C14.86 4.53951 14.7537 4.57177 14.6449 4.57177C14.5477 4.57177 14.4544 4.6104 14.3856 4.67917C14.3169 4.74793 14.2782 4.84119 14.2782 4.93844V5.67178C14.2782 5.76902 14.3169 5.86229 14.3856 5.93105C14.4544 5.99981 14.5477 6.03845 14.6449 6.03845C14.7422 6.03845 14.8354 5.99981 14.9042 5.93105C14.9729 5.86229 15.0116 5.76902 15.0116 5.67178V5.24644C15.3079 5.15991 15.5629 4.96919 15.7297 4.70943C15.8965 4.44968 15.9637 4.13839 15.9191 3.83296C15.8744 3.52752 15.7209 3.24852 15.4867 3.04738C15.2526 2.84624 14.9536 2.73651 14.6449 2.73843Z' />
      <path d='M14.645 7.13849C14.8475 7.13849 15.0117 6.97433 15.0117 6.77182C15.0117 6.56931 14.8475 6.40515 14.645 6.40515C14.4425 6.40515 14.2783 6.56931 14.2783 6.77182C14.2783 6.97433 14.4425 7.13849 14.645 7.13849Z' />
      <path d='M18.3117 0.538452H10.6117C10.1741 0.538452 9.75438 0.712291 9.44494 1.02173C9.13551 1.33116 8.96167 1.75084 8.96167 2.18845V10.4385C8.96184 10.5359 8.9879 10.6316 9.03719 10.7157C9.08647 10.7997 9.15721 10.8692 9.24217 10.917C9.32437 10.9635 9.41719 10.9882 9.51167 10.9885C9.61039 10.9884 9.70727 10.9618 9.79217 10.9115L12.2617 9.41545C12.353 9.36111 12.458 9.33437 12.5642 9.33845H18.3117C18.7493 9.33845 19.169 9.16461 19.4784 8.85518C19.7878 8.54574 19.9617 8.12606 19.9617 7.68845V2.18845C19.9617 1.75084 19.7878 1.33116 19.4784 1.02173C19.169 0.712291 18.7493 0.538452 18.3117 0.538452ZM18.8617 7.68845C18.8617 7.83432 18.8037 7.97422 18.7006 8.07736C18.5974 8.18051 18.4575 8.23845 18.3117 8.23845H12.5642C12.2638 8.23821 11.969 8.31999 11.7117 8.47495L10.0617 9.46495V2.18845C10.0617 2.04258 10.1196 1.90269 10.2228 1.79954C10.3259 1.6964 10.4658 1.63845 10.6117 1.63845H18.3117C18.4575 1.63845 18.5974 1.6964 18.7006 1.79954C18.8037 1.90269 18.8617 2.04258 18.8617 2.18845V7.68845Z' />
    </SvgIcon>
  );
});
