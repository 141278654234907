import { Box, Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { useMemo, useState } from "react";

import { TypeHelper } from "@/common/helpers/type";
import { apiClient } from "@/core/api/ApiClient";
import { DamageDetectionDto, EntityType } from "@/core/api/generated";

import FoldableBlock from "../../Display/FoldableBlock";
import AppTypography from "../../Text/AppTypography";
import VehicleDamageLink from "../VehicleDamage/VehicleDamageLink";
import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: DamageDetectionDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function DamageDetectionDeleteModal({ entity, ...otherProps }: Props) {
  const [isDeleteVehicleDamages, setIsDeleteVehicleDamages] = useState(false);

  const damages = useMemo(() => entity.items?.map((x) => x.damage!).filter(Boolean), [entity]);

  return (
    <BaseEntityDeleteModal
      entityType={EntityType.DamageDetection}
      entity={entity}
      entityId={entity.id}
      bodyAfter={
        TypeHelper.isNotEmptyArray(damages) && (
          <Box>
            <FormControl fullWidth margin='dense'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isDeleteVehicleDamages}
                    onChange={(e) => setIsDeleteVehicleDamages(e.target.checked)}
                  />
                }
                label={`Delete the corresponding damages from the vehicle history`}
              />
            </FormControl>

            <FoldableBlock
              defaultIsFolded
              trigger={{ label: <AppTypography>Damages</AppTypography> }}
            >
              <Box component='ol' sx={{ m: 0 }}>
                {damages.map((damage, i) => (
                  <li key={damage.id ?? i}>
                    <VehicleDamageLink
                      vehicleId={undefined}
                      entityId={damage.id}
                      entity={damage}
                      withIcon
                    />
                  </li>
                ))}
              </Box>
            </FoldableBlock>
          </Box>
        )
      }
      deleteFunc={async (params) => {
        await apiClient.damageDetectionsApi.apiV1DamageDetectionsDamageDetectionIdDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          damageDetectionId: params.entityId,
          isDeleteCascade: params.isDeleteCascade,
          damageDetectionDeleteDto: {
            isDeleteVehicleDamages: isDeleteVehicleDamages,
          },
        });
      }}
      {...otherProps}
    />
  );
}
