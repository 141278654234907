import {
  Avatar,
  Badge,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Stack,
  TextField,
} from "@mui/material";
import { getIn, useFormik } from "formik";
import _ from "lodash";
import { useMemo } from "react";
import { useHistory } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout from "@/App/Layouts/ViewLayout";
import ApiEnumSelect from "@/common/components/Enum/ApiEnumSelect";
import AvatarUploader from "@/common/components/Files/AvatarUploader";
import FormActions from "@/common/components/Form/FormActions";
import PhoneInput from "@/common/components/Form/Input/PhoneInput";
import AppIcon from "@/common/components/Icons/AppIcon";
import { FileItem } from "@/common/fileItem";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { ProfilePersonalInfoUpdateDto } from "@/core/api/generated";

export default function ProfilePersonalInfoEdit() {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const history = useHistory();
  const profileRequest = useApiRequest(apiClient.profileApi.apiV1ProfileGet, {
    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
  });
  const profile = useMemo(() => profileRequest.data, [profileRequest.data]);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleSubmit,
    handleChange,
    handleBlur,
    setStatus,
    setSubmitting,
    setFieldError,
    setFieldValue,
  } = useFormik<
    ProfilePersonalInfoUpdateDto &
      BaseFormikValues & {
        avatar?: FileItem;
      }
  >({
    enableReinitialize: true,
    initialValues: {
      personName: {
        firstName: profile?.personName?.firstName || undefined,
        lastName: profile?.personName?.lastName || undefined,
      },
      phoneNumber: profile?.phoneNumber || undefined,
      submit: "",
    },
    onSubmit: async () => {
      try {
        await apiClient.profileApi.apiV1ProfilePersonalInfoPut({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          profilePersonalInfoUpdateDto: {
            ..._.omit(values, ["submit"]),
          },
        });
        enqueueSnackbar("Profile updated.", { variant: "success" });
        if (values.avatar) {
          await apiClient.profileApi.apiV1ProfilePersonalInfoAvatarPut({
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            generalAvatarAddDto: FileItem.toGeneralAvatarAddDto(values.avatar),
          });
        } else {
          await apiClient.profileApi.apiV1ProfilePersonalInfoAvatarRemoveDelete({
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          });
        }

        enqueueSnackbar("Profile updated.", { variant: "success" });
        history.goBack();

        if (mounted.current) {
          setStatus({ success: true });
          setSubmitting(false);
        }
      } catch (err: any) {
        if (mounted.current) {
          ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
          setStatus({ success: false });
          setSubmitting(false);
        }
      }
    },
  });

  return (
    <ViewLayout header={<SimpleViewPageHeader title={"Profile personal info edit"} />}>
      <Stack sx={{ mt: 2 }} direction='column' spacing={1}>
        <Stack
          direction='row'
          spacing={1}
          sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
        >
          {profile && (
            <AvatarUploader
              defaultFile={profile?.avatar ? FileItem.createFrom(profile.avatar) : undefined}
              onChange={async (newAvatar) => {
                setFieldValue("avatar", newAvatar);
              }}
            >
              {() => {
                return (
                  <Badge
                    overlap='circular'
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ "& .MuiBadge-badge": { padding: 0 } }}
                    badgeContent={
                      profile?.avatar?.file?.url ? (
                        <IconButton
                          sx={{
                            background: "white !important",
                            boxShadow: (theme) => theme.shadows[3],
                          }}
                        >
                          <AppIcon
                            of='delete'
                            color='error'
                            onClick={(e) => {
                              setFieldValue("avatar", undefined);
                              e.stopPropagation();
                            }}
                          />
                        </IconButton>
                      ) : null
                    }
                  >
                    <Avatar
                      sx={{ width: 164, height: 164 }}
                      alt={profile?.personName?.name || ""}
                      src={values?.avatar?.file?.url || ""}
                    />
                  </Badge>
                );
              }}
            </AvatarUploader>
          )}
        </Stack>
        <Box>
          {/* <FormControl margin='dense' fullWidth>
            <TextField
              disabled
              fullWidth
              required
              label='ID'
              margin='dense'
              name='id'
              type='text'
              value={profile?.id || ""}
              variant='outlined'
            />
          </FormControl> */}

          <FormControl margin='dense' fullWidth>
            <TextField
              disabled
              fullWidth
              required
              label='Email'
              margin='dense'
              name='email'
              type='email'
              value={profile?.email || ""}
              variant='outlined'
            />
          </FormControl>

          <FormControl margin='dense' fullWidth>
            <InputLabel>Status</InputLabel>
            <ApiEnumSelect
              type='UserStatus'
              value={profile?.status}
              optionProps={{ withDescription: true }}
              selectProps={{ disabled: true, label: "Status" }}
            />
          </FormControl>

          <FormControl margin='dense' fullWidth>
            <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: 2 }}>
              <TextField
                error={Boolean(
                  getIn(touched, "personName.firstName") && getIn(errors, "personName.firstName"),
                )}
                fullWidth
                required
                helperText={
                  getIn(touched, "personName.firstName") && getIn(errors, "personName.firstName")
                }
                label='First name'
                margin='none'
                name='personName.firstName'
                onBlur={handleBlur}
                onChange={handleChange}
                type='text'
                value={values.personName?.firstName || ""}
                variant='outlined'
              />

              <TextField
                error={Boolean(
                  getIn(touched, "personName.lastName") && getIn(errors, "personName.lastName"),
                )}
                fullWidth
                required
                helperText={
                  getIn(touched, "personName.lastName") && getIn(errors, "personName.lastName")
                }
                label='Last name'
                margin='none'
                name='personName.lastName'
                onBlur={handleBlur}
                onChange={handleChange}
                type='text'
                value={values.personName?.lastName || ""}
                variant='outlined'
              />
            </Box>
          </FormControl>

          <FormControl margin='dense' fullWidth>
            <PhoneInput
              error={Boolean(touched.phoneNumber && errors.phoneNumber)}
              helperText={ValidationHelper.getFormikErrorsAsString(errors.phoneNumber)}
              label='Phone number'
              margin='dense'
              variant='outlined'
              fullWidth
              required
              color='primary'
              placeholder='Phone number'
              onBlur={handleBlur}
              value={values.phoneNumber || ""}
              onChange={(e, ph, iso) => {
                setFieldValue(`phoneNumber`, ph);
              }}
            />
          </FormControl>
        </Box>

        <FormActions>
          <Button
            type='submit'
            loading={isSubmitting}
            onClick={() => {
              handleSubmit();
            }}
            variant='contained'
          >
            Save
          </Button>
        </FormActions>
      </Stack>
    </ViewLayout>
  );
}
