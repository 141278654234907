import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { ROUTE_PATH } from "@/common/constants/routing";
import { useInternetConnectionStatus } from "@/common/hooks/useInternetConnectionStatus";
import { tenantService } from "@/common/services/tenant";

export default function NoInternetErrorPage() {
  const history = useHistory();
  const internetStatus = useInternetConnectionStatus();

  // redirect to root if internet is present
  useEffect(() => {
    if (internetStatus.isJustConnected) {
      tenantService.redirectToTenantRootUrl(); // redirect with full app reload
    } else if (internetStatus.isConnected) {
      history.push(ROUTE_PATH.ROOT);
    }
  }, [internetStatus]);

  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography component='div' variant='h4'>
        No internet
      </Typography>
      <Box>
        Unable to connect to internet. Please check your internet connection and refresh the page.
      </Box>
    </Box>
  );
}
