import { DependencyList, useEffect } from "react";

import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import {
  DataUpdatesHubClientMethodName,
  EntityType,
  OperationCountersDto,
} from "@/core/api/generated";
import * as operationsSlice from "@/store/operation/operationsSlice";

import { useRealtimeDataUpdates } from "../../realtime/useRealtimeDataUpdates";
import { useAppSelector, useAppThunkDispatch } from "../../redux";
import { useCurrentTenant } from "../tenant/useCurrentTenant";

export const useOperationsCounters = (params?: {
  deps?: DependencyList;
}): OperationCountersDto | undefined => {
  const currentTenant = useCurrentTenant();
  const thunkDispatch = useAppThunkDispatch();

  const counters = useAppSelector((x) => x.operations.counters);

  useEffect(() => {
    if (!counters) {
      thunkDispatch(operationsSlice.getCountersThrottle());
    }
  }, [counters, ...(params?.deps || [])]);

  useRealtimeDataUpdates({
    enabled: !!currentTenant?.id,
    channelNames: [
      DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.AccessoryCheck),
      DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.DamageDetection),
      DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.DamageCostEvaluation),
      DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.RepairOperation),
      DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.Wash),
      DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.WheelOperation),
      DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.Wash),
      DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.Maintenance),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      thunkDispatch(operationsSlice.getCountersThrottle());
    },
  });

  return counters;
};
