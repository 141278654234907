import VehiclePaginatedList from "@/common/components/Entity/Vehicle/ListView/VehiclePaginatedList";
import { FilterDefinition } from "@/common/filters/filterDefinition";
import { useLocationState } from "@/common/hooks/useLocationParams";
import { FilterDefinitionDto } from "@/core/api/generated";

export interface VehicleListPageLocationState {
  initialValues?: {
    filterDefinition?: Nil<FilterDefinition>;
    filterDefinitionDto?: Nil<FilterDefinitionDto>;
  };
}

export default function VehiclesPage() {
  const locationState = useLocationState<VehicleListPageLocationState>();

  return (
    <>
      <VehiclePaginatedList initialValues={locationState?.initialValues} />
    </>
  );
}
