import { Box, TextField, TextFieldProps } from "@mui/material";
import { useMemo } from "react";

import { VALIDATION_CONSTANTS } from "@/common/constants/validation";

import AppTypography from "../../Text/AppTypography";

type Props = TextFieldProps & {
  mode: "notes" | "description";
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | null | undefined;
  showCharacterCount?: boolean;
};

type DefaultPropsByMode = {
  [K in Props["mode"]]: {
    label: string;
    rows: number;
    maxLength: number;
  };
};

const defaultPropsByMode: DefaultPropsByMode = {
  notes: {
    label: "Notes",
    rows: 2,
    maxLength: VALIDATION_CONSTANTS.NotesMaxLength,
  },
  description: {
    label: "Description",
    rows: 2,
    maxLength: VALIDATION_CONSTANTS.DescriptionMaxLength,
  },
};
export default function AppTextArea({
  mode,
  value,
  showCharacterCount = true,
  onChange,
  error,
  helperText,
  ...props
}: Props) {
  const { label, rows, maxLength } = defaultPropsByMode[mode];

  const currentLength = useMemo(() => value?.length || 0, [value]);
  const showCharacterCountComputed = showCharacterCount && value;

  return (
    <TextField
      multiline
      rows={rows}
      label={label}
      type='text'
      value={value}
      error={error}
      onChange={onChange}
      inputProps={{ ...props.inputProps, maxLength }}
      helperText={
        <Box>
          {helperText}

          {/* Character counter */}
          {showCharacterCountComputed && (
            <AppTypography
              sx={{
                position: "absolute", // TextField render FormGroup which has relative position
                right: 0,
                top: 0,
                py: 0.25,
                px: 0.75,
              }}
              color='text'
              variant='caption2'
            >
              {currentLength}/{maxLength}
            </AppTypography>
          )}
        </Box>
      }
      {...props}
    />
  );
}
