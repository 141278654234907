import { Box, Grid2, Stack, Typography } from "@mui/material";
import { useState } from "react";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AppIconButton from "@/common/components/Button/AppIconButton";
import Datetime from "@/common/components/Datetime/Datetime";
import AccessoryCheckItem from "@/common/components/Entity/AccessoryCheck/AccessoryCheckItem";
import ContractBlock from "@/common/components/Entity/Contract/ContractBlock";
import GeneralInspectorDisplay from "@/common/components/Entity/General/Display/GeneralInspectorDisplay";
import VehicleBlock from "@/common/components/Entity/Vehicle/VehicleBlock";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import FileListView from "@/common/components/Files/FileListView";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import BaseEditNotesModal from "@/common/components/Modals/common/BaseEditNotesModal";
import { FileItem } from "@/common/fileItem";
import { apiClient } from "@/core/api/ApiClient";
import { AccessoryCheckDto, EntityType } from "@/core/api/generated";

import AffiliationInfoDisplay from "../../../EntityAffiliation/AffiliationInfoDisplay";
import UpdateEntityAffiliationCascadeModal from "../../../EntityAffiliation/UpdateEntityAffiliationCascadeModal";

interface Props {
  accessoryCheck: AccessoryCheckDto;
  onUpdate?: (newValue?: AccessoryCheckDto) => void;
}

export default function OverviewTabContent({ accessoryCheck, onUpdate }: Props) {
  const [isUpdateAffiliationModalOpened, setIsUpdateAffiliationModalOpened] = useState(false);

  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={accessoryCheck} />}
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <Stack direction='column' spacing={1}>
            {(accessoryCheck.vehicle || accessoryCheck.contract) && (
              <Stack direction={{ xxs: "column", md: "row" }} alignItems='flex-start' spacing={2}>
                {accessoryCheck.vehicle && (
                  <VehicleBlock
                    sx={{ xxs: "100%", maxWidth: { md: 400 } }}
                    variant='reference'
                    vehicle={accessoryCheck.vehicle}
                    withDetailsToggle
                    withLink
                  />
                )}

                {accessoryCheck.contract && (
                  <ContractBlock
                    sx={{ maxWidth: { xxs: "100%", md: 400 } }}
                    variant='reference'
                    contract={accessoryCheck.contract}
                    withDetailsToggle
                    withLink
                  />
                )}
              </Stack>
            )}
          </Stack>

          <Box>
            <Grid2 container columnSpacing={2} rowSpacing={1} sx={{ mb: 1 }}>
              <Grid2 size={{ xxs: 12 }} minWidth={200}>
                <Stack direction='column' spacing={1}>
                  <FieldValue label='Inspection date'>
                    <Datetime datetime={accessoryCheck.inspectedAt} withDurationFromNow />
                  </FieldValue>
                  <FieldValue label='Inspector'>
                    <GeneralInspectorDisplay
                      inspector={accessoryCheck.inspector}
                      inlineUserProps={{
                        withAvatar: true,
                        withLink: true,
                      }}
                    />
                  </FieldValue>
                </Stack>
              </Grid2>
              <Grid2 size={{ xxs: 12 }} minWidth={200}>
                <Stack direction='column' spacing={1}>
                  <FieldValue label='Notes' forceValueRenderEvenIfEmpty>
                    {accessoryCheck.notes ? accessoryCheck.notes : "-"}{" "}
                    <BaseEditNotesModal
                      onConfirm={async (notes) => {
                        await apiClient.accessoryChecksApi.apiV1AccessoryChecksAccessoryCheckIdNotesPut(
                          {
                            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                            accessoryCheckId: accessoryCheck.id || "",
                            generalNotesInputDto: { notes },
                          },
                        );
                      }}
                    />
                  </FieldValue>
                </Stack>
              </Grid2>
            </Grid2>
          </Box>

          {/* Affiliation */}
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography variant='subtitle1'>
              Affiliation{" "}
              <AppIconButton
                tooltipProps={{ title: "Edit entity affiliation" }}
                onClick={() => setIsUpdateAffiliationModalOpened(true)}
              >
                <AppIcon of='edit' fontSize='small' />
              </AppIconButton>
            </Typography>
            <AffiliationInfoDisplay
              tenantId={accessoryCheck.tenantId}
              departmentIds={accessoryCheck.departmentIds}
              locationIds={accessoryCheck.locationIds}
              sx={{ mt: 1 }}
            />
            <UpdateEntityAffiliationCascadeModal
              open={isUpdateAffiliationModalOpened}
              onClose={() => setIsUpdateAffiliationModalOpened(false)}
              entityType={EntityType.AccessoryCheck}
              entityId={accessoryCheck.id}
              onUpdate={onUpdate}
            />
          </Stack>

          {accessoryCheck.attachments?.length !== 0 && (
            <Box>
              <FileListView
                files={FileItem.createManyFrom(accessoryCheck.attachments)}
                maxVisibleFiles={10}
                itemWidth={45}
              />
            </Box>
          )}

          {accessoryCheck.items && (
            <Grid2 container>
              <Grid2 size={{ xxs: 12, md: 6, lg: 4 }}>
                <Stack direction='column' spacing={1}>
                  {accessoryCheck.items.map((item) => (
                    <AccessoryCheckItem key={item.id} item={item} />
                  ))}
                </Stack>
              </Grid2>
            </Grid2>
          )}
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}
