import { ApiEnumName } from "@/common/services/enum";
import { VehicleDamageDto } from "@/core/api/generated";

import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import { StatusDisplay, StatusDisplayProps } from "../../StatusDisplay/StatusDisplay";

interface Props {
  vehicleDamage: VehicleDamageDto | null | undefined;
  statusProps?: Partial<StatusDisplayProps>;
  sx?: StatusDisplayProps["sx"];
}

export const VehicleDamageStatus = ({ vehicleDamage, statusProps, sx }: Props) => {
  return (
    <StatusDisplay
      color={
        vehicleDamage?.state === "Repaired"
          ? (theme) => theme.palette.success.main
          : (theme) => theme.palette.error.main
      }
      size={8}
      title={
        <InlineApiEnumValue
          type={ApiEnumName.VehicleDamageState}
          value={vehicleDamage?.state}
          withHelperTooltip
        />
      }
      {...statusProps}
      sx={sx}
    />
  );
};
