import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { apiClient } from "@/core/api/ApiClient";
import { PermissionDto, RoleDto } from "@/core/api/generated";
import { AppThunk } from "@/store";

interface AccountState {
  allPermissions?: PermissionDto[];
  globalPermissions?: PermissionDto[];
  tenantPermissions?: PermissionDto[];
  globalRoles?: RoleDto[];
  globalRole?: RoleDto;
  loading: {
    getPaginatedRoles?: boolean;
  };
}

const initialState: AccountState = {
  allPermissions: undefined,
  globalPermissions: undefined,
  tenantPermissions: undefined,
  globalRoles: undefined,
  globalRole: undefined,
  loading: {},
};

const accountSlice = createSlice({
  name: "adminAccount",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<AccountState["loading"]>) => {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
    _allPermissionsFetched: (state, action: PayloadAction<PermissionDto[]>) => {
      state.allPermissions = action.payload;
    },
    _globalPermissionsFetched: (state, action: PayloadAction<PermissionDto[]>) => {
      state.globalPermissions = action.payload;
    },
    _tenantPermissionsFetched: (state, action: PayloadAction<PermissionDto[]>) => {
      state.tenantPermissions = action.payload;
    },
    _globalRolesFetched: (state, action: PayloadAction<RoleDto[]>) => {
      state.globalRoles = action.payload;
    },
    _globalRoleFetched: (state, action: PayloadAction<RoleDto>) => {
      state.globalRole = action.payload;
    },
    _globalRoleCreated: (state, action: PayloadAction<RoleDto>) => {
      if (state.globalRoles) {
        state.globalRoles.push(action.payload);
      }
    },
    _globalRoleUpdated: (state, action: PayloadAction<RoleDto>) => {
      if (state.globalRoles) {
        const index = state.globalRoles.findIndex((x) => x.id === action.payload.id);
        index !== -1 && state.globalRoles.splice(index, 1, action.payload);
      }
      if (state.globalRole?.id === action.payload.id) {
        state.globalRole = action.payload;
      }
    },
    _globalRoleDeleted: (state, action: PayloadAction<{ roleId: string }>) => {
      if (state.globalRoles) {
        const index = state.globalRoles.findIndex((x) => x.id === action.payload.roleId);
        index !== -1 && state.globalRoles.splice(index, 1);
      }
    },
    resetGlobalRole: (state, action: PayloadAction<undefined>) => {
      state.globalRole = undefined;
    },
  },
});

export const {
  _allPermissionsFetched,
  _globalPermissionsFetched,
  _tenantPermissionsFetched,
  _globalRolesFetched,
  _globalRoleFetched,
  _globalRoleCreated,
  _globalRoleUpdated,
  _globalRoleDeleted,
  resetGlobalRole,
  setLoading,
} = accountSlice.actions;

export const accountReducer = accountSlice.reducer;

export const getAllPermissions =
  (
    ...arg: Parameters<typeof apiClient.adminAccountApi.apiV1AdminAccountAllPermissionsGet>
  ): AppThunk<Promise<PermissionDto[]>> =>
  async (dispatch) => {
    const response = await apiClient.adminAccountApi.apiV1AdminAccountAllPermissionsGet(...arg);
    await dispatch(_allPermissionsFetched(response.data));
    return response.data;
  };

export const getGlobalPermissions =
  (
    ...arg: Parameters<typeof apiClient.adminAccountApi.apiV1AdminAccountGlobalPermissionsGet>
  ): AppThunk<Promise<PermissionDto[]>> =>
  async (dispatch) => {
    const response = await apiClient.adminAccountApi.apiV1AdminAccountGlobalPermissionsGet(...arg);
    await dispatch(_globalPermissionsFetched(response.data));
    return response.data;
  };

export const getTenantPermissions =
  (
    ...arg: Parameters<typeof apiClient.adminAccountApi.apiV1AdminAccountTenantsPermissionsGet>
  ): AppThunk<Promise<PermissionDto[]>> =>
  async (dispatch) => {
    const response = await apiClient.adminAccountApi.apiV1AdminAccountTenantsPermissionsGet(...arg);
    await dispatch(_tenantPermissionsFetched(response.data));
    return response.data;
  };

export const getGlobalRoles =
  (
    ...args: Parameters<typeof apiClient.adminAccountApi.apiV1AdminAccountGlobalRolesGet>
  ): AppThunk<Promise<RoleDto[]>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        getPaginatedRoles: true,
      }),
    );
    const response = await apiClient.adminAccountApi.apiV1AdminAccountGlobalRolesGet(...args);
    await dispatch(_globalRolesFetched(response.data));
    dispatch(
      setLoading({
        getPaginatedRoles: false,
      }),
    );
    return response.data;
  };

export const getGlobalRole =
  (
    ...args: Parameters<typeof apiClient.adminAccountApi.apiV1AdminAccountGlobalRolesRoleIdGet>
  ): AppThunk<Promise<RoleDto>> =>
  async (dispatch) => {
    const response = await apiClient.adminAccountApi.apiV1AdminAccountGlobalRolesRoleIdGet(...args);
    await dispatch(_globalRoleFetched(response.data));
    return response.data;
  };

export const createGlobalRole =
  (
    ...args: Parameters<typeof apiClient.adminAccountApi.apiV1AdminAccountGlobalRolesPost>
  ): AppThunk<Promise<RoleDto>> =>
  async (dispatch) => {
    const response = await apiClient.adminAccountApi.apiV1AdminAccountGlobalRolesPost(...args);
    dispatch(_globalRoleCreated(response.data));
    return response.data;
  };

export const updateGlobalRole =
  (
    ...args: Parameters<typeof apiClient.adminAccountApi.apiV1AdminAccountGlobalRolesRoleIdPut>
  ): AppThunk<Promise<RoleDto>> =>
  async (dispatch) => {
    const response = await apiClient.adminAccountApi.apiV1AdminAccountGlobalRolesRoleIdPut(...args);
    dispatch(_globalRoleUpdated(response.data));
    return response.data;
  };

export const deleteGlobalRole =
  (roleId: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    await apiClient.adminAccountApi.apiV1AdminAccountGlobalRolesRoleIdDelete({
      roleId,
    });
    dispatch(_globalRoleDeleted({ roleId }));
  };
