import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function EditIcon(props: SvgIconProps, ref) {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <path d='M6.97987 20.8004V22.3064C6.97987 23.1485 6.29971 23.8124 5.47387 23.8124C4.64803 23.8124 3.96787 23.1485 3.96787 22.3064V20.8004H2.44556V15.8774C2.44556 14.7925 3.17428 13.9017 4.16212 13.6265L5.92724 9.4322C6.18637 8.81683 6.78553 8.37958 7.49803 8.37958H16.5181C17.2306 8.37958 17.8298 8.81683 18.0889 9.4322L19.8379 13.6265C20.8257 13.9018 21.5544 14.7925 21.5544 15.8774V20.8004H20.016V22.3064C20.016 23.1485 19.3358 23.8124 18.51 23.8124C17.6679 23.8124 17.004 23.1485 17.004 22.3064V20.8004H6.97987ZM5.57099 18.096C6.41306 18.096 7.09321 17.4159 7.09321 16.5738C7.09321 15.7317 6.41306 15.0516 5.57099 15.0516C4.72893 15.0516 4.04878 15.7317 4.04878 16.5738C4.04878 17.4159 4.72884 18.096 5.57099 18.096ZM19.8541 16.5738C19.8541 15.7317 19.1739 15.0516 18.3319 15.0516C17.4898 15.0516 16.8097 15.7317 16.8097 16.5738C16.8097 17.4159 17.4898 18.096 18.3319 18.096C19.1739 18.096 19.8541 17.4159 19.8541 16.5738ZM18.3319 13.5455L16.8259 9.91801C16.7611 9.73989 16.583 9.61033 16.3724 9.61033H7.62759C7.43324 9.61033 7.25512 9.73989 7.19034 9.91801L5.68434 13.5455H18.3319Z' />
      <path d='M12 1.5C11.6044 1.5 11.2178 1.6173 10.8889 1.83706C10.56 2.05682 10.3036 2.36918 10.1522 2.73463C10.0009 3.10009 9.96126 3.50222 10.0384 3.89018C10.1156 4.27814 10.3061 4.63451 10.5858 4.91421C10.8655 5.19392 11.2219 5.3844 11.6098 5.46157C11.9978 5.53874 12.3999 5.49913 12.7654 5.34776C13.1308 5.19638 13.4432 4.94004 13.6629 4.61114C13.8827 4.28224 14 3.89556 14 3.5C14 2.96957 13.7893 2.46086 13.4142 2.08579C13.0391 1.71071 12.5304 1.5 12 1.5ZM12 4.35714C11.8305 4.35714 11.6648 4.30687 11.5238 4.21269C11.3828 4.1185 11.273 3.98464 11.2081 3.82801C11.1432 3.67139 11.1263 3.49905 11.1593 3.33278C11.1924 3.16651 11.274 3.01378 11.3939 2.89391C11.5138 2.77403 11.6665 2.6924 11.8328 2.65933C11.999 2.62625 12.1714 2.64323 12.328 2.7081C12.4846 2.77298 12.6185 2.88284 12.7127 3.0238C12.8069 3.16475 12.8571 3.33047 12.8571 3.5C12.8571 3.72733 12.7668 3.94535 12.6061 4.10609C12.4453 4.26684 12.2273 4.35714 12 4.35714Z' />
      <path d='M16.933 3.25084C16.6132 2.69606 14.8539 -0.087832 11.8651 0.00213219C9.10115 0.0721044 7.50179 2.50114 7.06696 3.25084C7.02309 3.32682 7 3.41301 7 3.50074C7 3.58847 7.02309 3.67466 7.06696 3.75064C7.38184 4.29543 9.06617 6.99935 12.01 6.99935H12.1349C14.8988 6.92938 16.5032 4.50034 16.933 3.75064C16.9769 3.67466 17 3.58847 17 3.50074C17 3.41301 16.9769 3.32682 16.933 3.25084ZM12.11 5.99975C9.95581 6.04973 8.55137 4.20546 8.11155 3.50074C8.61135 2.69606 9.91583 1.05171 11.915 1.00173C14.0592 0.946757 15.4686 2.79602 15.9134 3.50074C15.3986 4.30542 14.1092 5.94977 12.11 5.99975Z' />
    </SvgIcon>
  );
});
