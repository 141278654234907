import { Badge, BadgeOrigin, BadgeProps } from "@mui/material";
import { forwardRef } from "react";

import { AppIconProps } from "./AppIcon";

type AppIconPropsLocal = Omit<AppIconProps, "of" | "children">;

interface Props extends AppIconPropsLocal {
  mainIcon: React.ReactNode;
  badgeIcon: React.ReactNode;
  badgeIconPlacement?: BadgeOrigin;
  badgeOverlap?: BadgeProps["overlap"];
}

/**  */
export default forwardRef<HTMLSpanElement, Props>(function CompoundIcon(
  { mainIcon, badgeIcon, badgeIconPlacement, badgeOverlap }: Props,
  ref,
) {
  return (
    <Badge
      ref={ref}
      badgeContent={badgeIcon}
      anchorOrigin={badgeIconPlacement}
      overlap={badgeOverlap}
      color='default'
      sx={{
        background: "none",
        color: "inherit",
        "& .MuiBadge-badge": {
          background: (th) => th.palette.background.default,
          color: "inherit",
        },
      }}
    >
      {mainIcon}
    </Badge>
  );
});
