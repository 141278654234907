import { useEffect } from "react";
import { useHistory } from "react-router";

import { UrlHelper } from "@/common/helpers/url";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { tenantService } from "@/common/services/tenant";

export interface LoggedOutSearchParams {
  tenantIdentifier?: string;
}

/** Handles logged out user. User is redirected here after Auth0 logout. */
function LoggedOut() {
  const { tenantIdentifier } = useQueryParams<LoggedOutSearchParams>();
  const history = useHistory();

  useEffect(() => {
    if (tenantIdentifier) {
      const tenantUrl = tenantService.getTenantUrl(tenantIdentifier, false);
      UrlHelper.redirectToUrl(UrlHelper.deleteUrlSearchParams(tenantUrl));
    } else {
      history.push(`/`);
    }
  }, [tenantIdentifier]);

  return null;
}

export default LoggedOut;
