import { IconButton, ListItemIcon, ListItemText, MenuItem, Stack } from "@mui/material";
import _ from "lodash";
import { useMemo, useState } from "react";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import { ExportMode } from "@/common/components/DataExport/DataExportForm";
import DataExportModal from "@/common/components/DataExport/DataExportModal";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityTagsInfoDisplay from "@/common/components/EntityInfo/EntityTagsInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { TagStaticFilterInputDto, TagsHelper } from "@/common/helpers/entity/tags";
import { PaginationHelper } from "@/common/helpers/pagination";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ApiEnumName } from "@/common/services/enum";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  DataUpdatesHubClientMethodName,
  EntityType,
  ExportEntityType,
  GeneralAttachedTagsInputDto,
  GeneralStatusHistoryDto,
  GeneralStatusHistoryGetPaginatedDto,
  TagEntityType,
} from "@/core/api/generated";

import GeneralByWhoDisplay from "../../../General/Display/GeneralByWhoDisplay";
import EntityInline from "../../../components/EntityInline";
import GeneralStatusEnumValueInline from "../../common/GeneralStatusEnumValueInline";
import GeneralStatusHistoryMenu from "../GeneralStatusHistoryMenu";
import GeneralStatusHistoryViewModal from "../View/GeneralStatusHistoryViewModal";

const defaultDisplayProps = {
  viewVariant: ViewLayoutVariant.Page,
  isForSubjectEntity: false,
};

type GeneralStatusHistoryGetPaginatedInputDto = Omit<
  GeneralStatusHistoryGetPaginatedDto,
  "tags"
> & {
  tags?: TagStaticFilterInputDto;
};

export interface GeneralStatusHistoryPaginatedListProps {
  displayProps?: Partial<typeof defaultDisplayProps>;
  defaultValues?: Partial<GeneralStatusHistoryGetPaginatedInputDto>;
}

export default function GeneralStatusHistoryPaginatedList({
  displayProps,
  defaultValues,
}: GeneralStatusHistoryPaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const [isExportModalOpened, setIsExportModalOpened] = useState(false);

  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<GeneralStatusHistoryGetPaginatedInputDto>({
    statePersistence: {
      persistenceKey: EntityType.GeneralStatusHistory,
    },
    defaultValues: {
      limit: defaultValues?.limit ?? PaginationHelper.defaultLimit,
      params: {
        ...defaultValues,
      },
    },
    initParams: (filterDefinition) => {
      const tagFilter = filterDefinition.filterDefinition?.items.find(
        (x) => x.field === getTypedPath<GeneralStatusHistoryDto>().tags.$path,
      );

      return {
        tags: {
          operator: tagFilter?.operator,
          query: tagFilter?.value as GeneralAttachedTagsInputDto,
        } as TagStaticFilterInputDto,
      };
    },
  });

  const [entityToView, setEntityToView] = useState<GeneralStatusHistoryDto | undefined>(undefined);

  const paginatedGeneralStatusHistoryRequest = useApiRequest(
    apiClient.generalStatusHistoryApi.apiV1GeneralStatusHistoryGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      generalStatusHistoryGetPaginatedDto: {
        ..._.omit(commonRequestParams.params, "tags"),
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        tags:
          commonRequestParams.filterDefinition?.items
            .filter((x) => x.field === getTypedPath<GeneralStatusHistoryDto>().tags.$path)
            .map((x) => TagsHelper.mapFromFilter(x))[0] || undefined,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps, commonRequestParams.filterDefinition],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedGeneralStatusHistory = paginatedGeneralStatusHistoryRequest?.data;
  const itemIds = useMemo(
    () => paginatedGeneralStatusHistory?.items?.map((x) => x.id!) || [],
    [paginatedGeneralStatusHistory?.items],
  );

  useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.GeneralStatusHistory),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedGeneralStatusHistoryRequest.handleEntityChanged(data);
    },
  });

  return (
    <ViewLayout
      displayProps={displayProps}
      header={
        <SimpleViewPageHeader
          title={undefined}
          secondaryActions={
            <MenuWithTrigger
              withAuthCloseOnClick={false}
              trigger={
                <IconButton sx={{ ml: "auto" }}>
                  <AppIcon of='moreVert' />
                </IconButton>
              }
            >
              {(params) => (
                <MenuItem onClick={() => setIsExportModalOpened(true)}>
                  <ListItemIcon>
                    <AppIcon of='export' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Export General Status History</ListItemText>
                </MenuItem>
              )}
            </MenuWithTrigger>
          }
        />
      }
    >
      <Stack sx={{ mt: 1 }} spacing={1}>
        <DataTabular
          columns={[
            {
              field: getTypedPath<GeneralStatusHistoryDto>().id.$path,
              title: "ID",
              width: 250,
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.id,
              filters: {
                fieldType: FilterFieldType.Id,
                isUseDefaultOperators: true,
              },
            },
            {
              if: !displayProps?.isForSubjectEntity,
              field: getTypedPath<GeneralStatusHistoryDto>().subject.type.$path,
              title: "Subject type",
              width: 100,
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.subject?.type ? (
                  <InlineApiEnumValue type='GeneralStatusSubjectType' value={item.subject.type} />
                ) : (
                  "-"
                ),
              filters: {
                fieldType: FilterFieldType.Enum,
                isUseDefaultOperators: true,
                fieldTypeMeta: {
                  enum: {
                    enumName: ApiEnumName.EntityType,
                  },
                },
              },
            },
            {
              if: !displayProps?.isForSubjectEntity,
              field: getTypedPath<GeneralStatusHistoryDto>().subject.entityType.$path,
              title: "Subject entity type",
              width: 100,
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.subject?.entityType ? (
                  <InlineApiEnumValue type='EntityType' value={item.subject.entityType} />
                ) : (
                  "-"
                ),
              filters: {
                fieldType: FilterFieldType.Enum,
                isUseDefaultOperators: true,
                fieldTypeMeta: {
                  enum: {
                    enumName: ApiEnumName.EntityType,
                  },
                },
              },
            },
            {
              if: !displayProps?.isForSubjectEntity,
              field: getTypedPath<GeneralStatusHistoryDto>().subject.entityId.$path,
              title: "Subject entity",
              width: 200,
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.subject?.entityId ? (
                  <EntityInline
                    entityType={item.subject.entityType}
                    entityId={item.subject.entityId}
                    entity={undefined}
                  />
                ) : (
                  "-"
                ),
              filters: {
                fieldType: FilterFieldType.Id,
                isUseDefaultOperators: true,
              },
            },
            {
              if: !displayProps?.isForSubjectEntity,
              field: getTypedPath<GeneralStatusHistoryDto>().subject.identifier.$path,
              title: "Subject identifier",
              width: 100,
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.subject?.identifier || "-",
              filters: {
                fieldType: FilterFieldType.String,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<GeneralStatusHistoryDto>().statusIdentifier.$path,
              title: "Status identifier",
              width: 150,
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.status ? (
                  <GeneralStatusEnumValueInline
                    statusEnumTypeName='GeneralStatusIdentifier'
                    status={item.statusIdentifier}
                  />
                ) : (
                  "-"
                ),
              filters: {
                fieldType: FilterFieldType.Enum,
                isUseDefaultOperators: true,
                fieldTypeMeta: {
                  enum: {
                    enumName: ApiEnumName.GeneralStatusIdentifier,
                  },
                },
              },
            },
            {
              field: getTypedPath<GeneralStatusHistoryDto>().status.$path,
              title: "Status",
              width: 100,
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.status ? (
                  <GeneralStatusEnumValueInline
                    statusEnumTypeName={item.statusEnumTypeName}
                    status={item.status}
                  />
                ) : (
                  "-"
                ),
              filters: {
                fieldType: FilterFieldType.String,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<GeneralStatusHistoryDto>().prevStatus.$path,
              title: "Previous status",
              width: 100,
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.prevStatus ? (
                  <GeneralStatusEnumValueInline
                    statusEnumTypeName={item.statusEnumTypeName}
                    status={item.prevStatus}
                  />
                ) : (
                  "-"
                ),
              filters: {
                fieldType: FilterFieldType.String,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<GeneralStatusHistoryDto>().isCurrent.$path,
              title: "Is current",
              width: 50,
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => <BooleanValue value={item.isCurrent} />,
              filters: {
                fieldType: FilterFieldType.Boolean,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<GeneralStatusHistoryDto>().isEnded.$path,
              title: "Is ended",
              width: 50,
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => <BooleanValue value={item.isEnded} />,
              filters: {
                fieldType: FilterFieldType.Boolean,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<GeneralStatusHistoryDto>().startedAt.$path,
              title: "Started at",
              width: 200,
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.startedAt ? <Datetime datetime={item.startedAt} withDurationFromNow /> : "-",
              filters: {
                fieldType: FilterFieldType.Date,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<GeneralStatusHistoryDto>().endedAt.$path,
              title: "Ended at",
              width: 200,
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.endedAt ? <Datetime datetime={item.endedAt} withDurationFromNow /> : "-",
              filters: {
                fieldType: FilterFieldType.Date,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<GeneralStatusHistoryDto>().startedBy.$path,
              title: "Started by",
              width: 200,
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) =>
                item.startedBy ? <GeneralByWhoDisplay who={item.startedBy} size='full' /> : "-",
            },
            {
              field: getTypedPath<GeneralStatusHistoryDto>().endedBy.$path,
              title: "Ended by",
              width: 200,
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) =>
                item.endedBy ? <GeneralByWhoDisplay who={item.endedBy} size='full' /> : "-",
            },
            {
              field: getTypedPath<GeneralStatusHistoryDto>().startReason.$path,
              title: "Start reason",
              width: 100,
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.startReason || "-",
              filters: {
                fieldType: FilterFieldType.String,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<GeneralStatusHistoryDto>().endReason.$path,
              title: "End reason",
              width: 100,
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.endReason || "-",
              filters: {
                fieldType: FilterFieldType.String,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<GeneralStatusHistoryDto>().startAutoMode.$path,
              title: "Start mode",
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.startAutoMode ? (
                  <InlineApiEnumValue
                    type='AutomationCreateMode'
                    value={item.startAutoMode}
                    withHelperTooltip
                  />
                ) : (
                  "-"
                ),
              filters: {
                fieldType: FilterFieldType.Enum,
                isUseDefaultOperators: true,
                fieldTypeMeta: {
                  enum: {
                    enumName: ApiEnumName.AutomationCreateMode,
                  },
                },
              },
            },
            {
              field: getTypedPath<GeneralStatusHistoryDto>().endAutoMode.$path,
              title: "End mode",
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.endAutoMode ? (
                  <InlineApiEnumValue
                    type='AutomationCreateMode'
                    value={item.endAutoMode}
                    withHelperTooltip
                  />
                ) : (
                  "-"
                ),
              filters: {
                fieldType: FilterFieldType.Enum,
                isUseDefaultOperators: true,
                fieldTypeMeta: {
                  enum: {
                    enumName: ApiEnumName.AutomationCreateMode,
                  },
                },
              },
            },
            {
              field: getTypedPath<GeneralStatusHistoryDto>().createdAt.$path,
              title: "Created at",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
              filters: {
                fieldType: FilterFieldType.Date,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<GeneralStatusHistoryDto>().updatedAt.$path,
              title: "Updated at",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
              filters: {
                fieldType: FilterFieldType.Date,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<GeneralStatusHistoryDto>().createdBy.$path,
              title: "Created by",
              isVisible: false,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) =>
                item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
              filters: {
                fieldType: FilterFieldType.Id,
                operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
              },
            },
            {
              field: getTypedPath<GeneralStatusHistoryDto>().updatedBy.$path,
              title: "Updated by",
              isVisible: false,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) =>
                item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
              filters: {
                fieldType: FilterFieldType.Id,
                operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
              },
            },
            {
              field: getTypedPath<GeneralStatusHistoryDto>().tags.$path,
              title: "Tags",
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              isColumnMenuDisabled: false,
              isToDisabled: true,
              renderCell: (item) => (
                <EntityTagsInfoDisplay
                  entityType={TagEntityType.GeneralStatusHistory}
                  entity={item}
                  noDataPlaceholder='-'
                  edit={{
                    onSaved: (newValue) =>
                      paginatedGeneralStatusHistoryRequest.updateData((data) => {
                        data.items?.forEach((item2) => {
                          if (item2.id === item.id) {
                            item2.tags = newValue || undefined;
                          }
                        });
                      }),
                  }}
                />
              ),
              filters: {
                fieldType: FilterFieldType.Arbitrary,
                isUseSingleFilter: true,
                operators: FilterCatalog.getOperatorsForTagsField(),
              },
            },
          ]}
          rows={paginatedGeneralStatusHistory?.items}
          isLoading={paginatedGeneralStatusHistoryRequest.isLoading}
          getRowId={(item) => item.id!}
          rowTo={undefined}
          renderRowAction={({ item }) => (
            <GeneralStatusHistoryMenu
              entity={item}
              displayProps={{
                actions: {
                  view: true,
                  viewInNewTab: true,
                },
              }}
              onUpdated={(newValue) => {
                paginatedGeneralStatusHistoryRequest.updateData((data) => {
                  data.items = data.items?.map((x) => (x.id === newValue.id ? newValue : x));
                });
              }}
              onDeleted={(newValue) => {
                paginatedGeneralStatusHistoryRequest.refetch();
              }}
            />
          )}
          statePersistence={commonRequestParams.dataTabularProps.statePersistence}
          pagination={commonRequestParams.dataTabularProps.pagination}
          sort={commonRequestParams.dataTabularProps.sort}
          quickFilter={commonRequestParams.dataTabularProps.quickFilter}
          filters={commonRequestParams.dataTabularProps.filters}
          refetch={commonRequestParams.dataTabularProps.refetch}
          onRowClick={(item) => {
            setEntityToView(item);
          }}
        />

        {/* View */}
        {entityToView && (
          <GeneralStatusHistoryViewModal
            open={!!entityToView}
            onClose={() => setEntityToView(undefined)}
            viewProps={{
              generalStatusHistoryId: entityToView.id,
              generalStatusHistory: entityToView,
              onUpdated: (newValue) => {
                paginatedGeneralStatusHistoryRequest.updateData((data) => {
                  data.items = data.items?.map((x) => (x.id === newValue.id ? newValue : x));
                });
              },
              onDeleted: () => {
                paginatedGeneralStatusHistoryRequest.refetch();
              },
            }}
          />
        )}

        {/* Export data modal */}
        <DataExportModal
          open={isExportModalOpened}
          onClose={() => setIsExportModalOpened(false)}
          exportDataProps={{
            primaryEntityId: defaultValues?.subjectEntityId ?? undefined,
            primaryEntityType: defaultValues?.subjectEntityType ?? undefined,
            entityType: ExportEntityType.GeneralStatusHistory,
            ids: itemIds,
            onExportDone: () => {
              setIsExportModalOpened(false);
            },
            defaultValues: {
              mode: ExportMode.AllItems,
            },
          }}
        />
      </Stack>
    </ViewLayout>
  );
}
