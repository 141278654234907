import { useParams } from "react-router-dom";

import TenantRequestView from "@/common/components/Entity/TenantRequest/View/TenantRequestView";

export default function TenantRequestViewPage() {
  const { tenantRequestId } = useParams<{ tenantRequestId?: string }>();

  return (
    <>
      <TenantRequestView
        tenantRequestId={tenantRequestId}
        tenantRequest={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
