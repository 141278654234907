import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

// Figma name:
export default forwardRef<SVGSVGElement, SvgIconProps>(function PoolIcon(props: SvgIconProps, ref) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='28'
      height='24'
      viewBox='0 0 28 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M4.93186 6.2678V6.96319C4.93186 7.35202 4.61779 7.65859 4.23646 7.65859C3.85513 7.65859 3.54106 7.35202 3.54106 6.96319V6.2678H2.83813V3.9946C2.83813 3.49362 3.17462 3.08233 3.63076 2.95523L4.44581 1.01852C4.56546 0.734371 4.84212 0.532471 5.17111 0.532471H9.33613C9.66513 0.532471 9.94183 0.734371 10.0614 1.01852L10.869 2.95523C11.3252 3.08237 11.6617 3.49362 11.6617 3.9946V6.2678H10.9513V6.96319C10.9513 7.35202 10.6372 7.65859 10.2559 7.65859C9.86707 7.65859 9.5605 7.35202 9.5605 6.96319V6.2678H4.93186ZM4.28131 5.01904C4.67013 5.01904 4.98419 4.70498 4.98419 4.31615C4.98419 3.92733 4.67013 3.61327 4.28131 3.61327C3.89248 3.61327 3.57842 3.92733 3.57842 4.31615C3.57842 4.70498 3.89244 5.01904 4.28131 5.01904ZM10.8765 4.31615C10.8765 3.92733 10.5625 3.61327 10.1736 3.61327C9.78482 3.61327 9.47076 3.92733 9.47076 4.31615C9.47076 4.70498 9.78482 5.01904 10.1736 5.01904C10.5625 5.01904 10.8765 4.70498 10.8765 4.31615ZM10.1736 2.91783L9.47825 1.24284C9.44834 1.1606 9.36609 1.10077 9.26886 1.10077H5.23094C5.1412 1.10077 5.05895 1.1606 5.02904 1.24284L4.33364 2.91783H10.1736Z' />
      <path d='M4.93186 14.3561V15.0515C4.93186 15.4403 4.61779 15.7468 4.23646 15.7468C3.85513 15.7468 3.54106 15.4403 3.54106 15.0515V14.3561H2.83813V12.0829C2.83813 11.5819 3.17462 11.1706 3.63076 11.0435L4.44581 9.10678C4.56546 8.82263 4.84212 8.62073 5.17111 8.62073H9.33613C9.66513 8.62073 9.94183 8.82263 10.0614 9.10678L10.869 11.0435C11.3252 11.1706 11.6617 11.5819 11.6617 12.0829V14.3561H10.9513V15.0515C10.9513 15.4403 10.6372 15.7468 10.2559 15.7468C9.86707 15.7468 9.5605 15.4403 9.5605 15.0515V14.3561H4.93186ZM4.28131 13.1073C4.67013 13.1073 4.98419 12.7932 4.98419 12.4044C4.98419 12.0156 4.67013 11.7015 4.28131 11.7015C3.89248 11.7015 3.57842 12.0156 3.57842 12.4044C3.57842 12.7932 3.89244 13.1073 4.28131 13.1073ZM10.8765 12.4044C10.8765 12.0156 10.5625 11.7015 10.1736 11.7015C9.78482 11.7015 9.47076 12.0156 9.47076 12.4044C9.47076 12.7932 9.78482 13.1073 10.1736 13.1073C10.5625 13.1073 10.8765 12.7932 10.8765 12.4044ZM10.1736 11.0061L9.47825 9.3311C9.44834 9.24885 9.36609 9.18903 9.26886 9.18903H5.23094C5.1412 9.18903 5.05895 9.24885 5.02904 9.3311L4.33364 11.0061H10.1736Z' />
      <path d='M4.93186 22.0766V22.772C4.93186 23.1609 4.61779 23.4674 4.23646 23.4674C3.85513 23.4674 3.54106 23.1609 3.54106 22.772V22.0766H2.83813V19.8034C2.83813 19.3025 3.17462 18.8912 3.63076 18.7641L4.44581 16.8274C4.56546 16.5432 4.84212 16.3413 5.17111 16.3413H9.33613C9.66513 16.3413 9.94183 16.5432 10.0614 16.8274L10.869 18.7641C11.3252 18.8912 11.6617 19.3025 11.6617 19.8034V22.0766H10.9513V22.772C10.9513 23.1609 10.6372 23.4674 10.2559 23.4674C9.86707 23.4674 9.5605 23.1609 9.5605 22.772V22.0766H4.93186ZM4.28131 20.8279C4.67013 20.8279 4.98419 20.5138 4.98419 20.125C4.98419 19.7362 4.67013 19.4221 4.28131 19.4221C3.89248 19.4221 3.57842 19.7362 3.57842 20.125C3.57842 20.5138 3.89244 20.8279 4.28131 20.8279ZM10.8765 20.125C10.8765 19.7362 10.5625 19.4221 10.1736 19.4221C9.78482 19.4221 9.47076 19.7362 9.47076 20.125C9.47076 20.5138 9.78482 20.8279 10.1736 20.8279C10.5625 20.8279 10.8765 20.5138 10.8765 20.125ZM10.1736 18.7267L9.47825 17.0517C9.44834 16.9694 9.36609 16.9096 9.26886 16.9096H5.23094C5.1412 16.9096 5.05895 16.9694 5.02904 17.0517L4.33364 18.7267H10.1736Z' />
      <path d='M1 7.51782H13.5' stroke='currentColor' strokeWidth='0.5' strokeLinecap='round' />
      <path d='M1 15.606H13.5' stroke='currentColor' strokeWidth='0.5' strokeLinecap='round' />
      <path d='M18.4319 6.2678V6.96319C18.4319 7.35202 18.1178 7.65859 17.7365 7.65859C17.3551 7.65859 17.0411 7.35202 17.0411 6.96319V6.2678H16.3381V3.9946C16.3381 3.49362 16.6746 3.08233 17.1308 2.95523L17.9458 1.01852C18.0655 0.734371 18.3421 0.532471 18.6711 0.532471H22.8361C23.1651 0.532471 23.4418 0.734371 23.5614 1.01852L24.369 2.95523C24.8252 3.08237 25.1617 3.49362 25.1617 3.9946V6.2678H24.4513V6.96319C24.4513 7.35202 24.1372 7.65859 23.7559 7.65859C23.3671 7.65859 23.0605 7.35202 23.0605 6.96319V6.2678H18.4319ZM17.7813 5.01904C18.1701 5.01904 18.4842 4.70498 18.4842 4.31615C18.4842 3.92733 18.1701 3.61327 17.7813 3.61327C17.3925 3.61327 17.0784 3.92733 17.0784 4.31615C17.0784 4.70498 17.3924 5.01904 17.7813 5.01904ZM24.3765 4.31615C24.3765 3.92733 24.0625 3.61327 23.6736 3.61327C23.2848 3.61327 22.9708 3.92733 22.9708 4.31615C22.9708 4.70498 23.2848 5.01904 23.6736 5.01904C24.0625 5.01904 24.3765 4.70498 24.3765 4.31615ZM23.6736 2.91783L22.9782 1.24284C22.9483 1.1606 22.8661 1.10077 22.7689 1.10077H18.7309C18.6412 1.10077 18.559 1.1606 18.529 1.24284L17.8336 2.91783H23.6736Z' />
      <path d='M18.4319 14.3561V15.0515C18.4319 15.4403 18.1178 15.7468 17.7365 15.7468C17.3551 15.7468 17.0411 15.4403 17.0411 15.0515V14.3561H16.3381V12.0829C16.3381 11.5819 16.6746 11.1706 17.1308 11.0435L17.9458 9.10678C18.0655 8.82263 18.3421 8.62073 18.6711 8.62073H22.8361C23.1651 8.62073 23.4418 8.82263 23.5614 9.10678L24.369 11.0435C24.8252 11.1706 25.1617 11.5819 25.1617 12.0829V14.3561H24.4513V15.0515C24.4513 15.4403 24.1372 15.7468 23.7559 15.7468C23.3671 15.7468 23.0605 15.4403 23.0605 15.0515V14.3561H18.4319ZM17.7813 13.1073C18.1701 13.1073 18.4842 12.7932 18.4842 12.4044C18.4842 12.0156 18.1701 11.7015 17.7813 11.7015C17.3925 11.7015 17.0784 12.0156 17.0784 12.4044C17.0784 12.7932 17.3924 13.1073 17.7813 13.1073ZM24.3765 12.4044C24.3765 12.0156 24.0625 11.7015 23.6736 11.7015C23.2848 11.7015 22.9708 12.0156 22.9708 12.4044C22.9708 12.7932 23.2848 13.1073 23.6736 13.1073C24.0625 13.1073 24.3765 12.7932 24.3765 12.4044ZM23.6736 11.0061L22.9782 9.3311C22.9483 9.24885 22.8661 9.18903 22.7689 9.18903H18.7309C18.6412 9.18903 18.559 9.24885 18.529 9.3311L17.8336 11.0061H23.6736Z' />
      <path d='M18.4319 22.0766V22.772C18.4319 23.1609 18.1178 23.4674 17.7365 23.4674C17.3551 23.4674 17.0411 23.1609 17.0411 22.772V22.0766H16.3381V19.8034C16.3381 19.3025 16.6746 18.8912 17.1308 18.7641L17.9458 16.8274C18.0655 16.5432 18.3421 16.3413 18.6711 16.3413H22.8361C23.1651 16.3413 23.4418 16.5432 23.5614 16.8274L24.369 18.7641C24.8252 18.8912 25.1617 19.3025 25.1617 19.8034V22.0766H24.4513V22.772C24.4513 23.1609 24.1372 23.4674 23.7559 23.4674C23.3671 23.4674 23.0605 23.1609 23.0605 22.772V22.0766H18.4319ZM17.7813 20.8279C18.1701 20.8279 18.4842 20.5138 18.4842 20.125C18.4842 19.7362 18.1701 19.4221 17.7813 19.4221C17.3925 19.4221 17.0784 19.7362 17.0784 20.125C17.0784 20.5138 17.3924 20.8279 17.7813 20.8279ZM24.3765 20.125C24.3765 19.7362 24.0625 19.4221 23.6736 19.4221C23.2848 19.4221 22.9708 19.7362 22.9708 20.125C22.9708 20.5138 23.2848 20.8279 23.6736 20.8279C24.0625 20.8279 24.3765 20.5138 24.3765 20.125ZM23.6736 18.7267L22.9782 17.0517C22.9483 16.9694 22.8661 16.9096 22.7689 16.9096H18.7309C18.6412 16.9096 18.559 16.9694 18.529 17.0517L17.8336 18.7267H23.6736Z' />
      <path d='M14.5 7.51782H27' stroke='currentColor' strokeWidth='0.5' strokeLinecap='round' />
      <path d='M14.5 15.606H27' stroke='currentColor' strokeWidth='0.5' strokeLinecap='round' />
    </SvgIcon>
  );
});
