import { CardContent } from "@mui/material";
import { ReactNode } from "react";

import AppCard from "../../../Card/AppCard";

export interface CounterWidgetCardProps {
  children: ReactNode;
}

export default function CounterWidgetCard({ children }: CounterWidgetCardProps) {
  return (
    <AppCard variant='contained' variant2='standard'>
      <CardContent sx={{ p: 1.5 }}>{children}</CardContent>
    </AppCard>
  );
}
