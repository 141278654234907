import { ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import _ from "lodash";
import { ReactNode } from "react";
import { useHistory } from "react-router-dom";

import { ROUTE_PATH } from "@/common/constants/routing";
import { FileHelper } from "@/common/helpers/file";
import { apiClient } from "@/core/api/ApiClient";
import { AppPermission, DamageCostEvaluationDto } from "@/core/api/generated";

import AuthorizedMenuItem from "../../Auth/AuthorizedMenuItem";
import EntityMenu from "../../EntityMenu/EntityMenu";
import AppIcon from "../../Icons/AppIcon";
import AppLink from "../../Link/AppLink";
import HeaderMenuItem from "../../Menu/HeaderMenuItem";
import LoadingMenuItem from "../../Menu/LoadingMenuItem";
import RepairOperationCreateUpdateModal from "../RepairOperation/RepairOperationCreateUpdateModal";
import AddDamageCostEvaluationToContractModal from "./AddDamageCostEvaluationToContractModal";
import DamageCostEvaluationDeleteModal from "./DamageCostEvaluationDeleteModal";

enum ActionFlags {
  DownloadPdf = "DownloadPdf",
  SendToCustomer = "SendToCustomer",
  Edit = "Edit",
  AddToContract = "AddToContract",
  Delete = "Delete",
  // quick actions
  CreateRepairOperation = "CreateRepairOperation",
}
const defaultDisplayProps = {
  actions: {
    downloadPdf: true,
    sendToCustomer: true,
    edit: true,
    addToContract: true,
    delete: true,
    // quick actions
    createRepairOperation: true,
  },
};

interface TriggersProps {
  entity?: DamageCostEvaluationDto | null;
  definition: typeof ActionFlags;
  handleClose: () => void;
  startAction: (props: ActionFlags) => void;
}

interface Props {
  entity?: DamageCostEvaluationDto | null;
  disabled?: boolean;
  onUpdate: ((newValue: DamageCostEvaluationDto | null | undefined) => void) | undefined;

  onDelete?: (() => void) | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  customActions?: (props: TriggersProps) => ReactNode;
}

export default function DamageCostEvaluationMenu({
  entity,
  disabled,
  customActions,
  onUpdate,
  onDelete,
  displayProps = defaultDisplayProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const history = useHistory();

  return (
    <EntityMenu
      definition={ActionFlags}
      entity={entity}
      disabled={disabled}
      actionTriggers={({ handleClose, startAction }) => (
        <>
          {customActions &&
            customActions({
              entity,
              definition: ActionFlags,
              handleClose,
              startAction,
            })}
          {displayProps?.actions?.downloadPdf && (
            <LoadingMenuItem
              key='downloadPdf'
              onClick={async () => {
                const downloadResult = await FileHelper.getDownloadFileApiResult(() =>
                  apiClient.damageCostEvaluationsApi.apiV1DamageCostEvaluationsDamageCostEvaluationIdDownloadPdfGet(
                    {
                      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                      damageCostEvaluationId: entity?.id || "",
                    },
                    {
                      responseType: "blob",
                    },
                  ),
                );
                FileHelper.downloadBlobFromApiResult(downloadResult);
                handleClose();
              }}
            >
              <ListItemIcon>
                <AppIcon of='download' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Download PDF</ListItemText>
            </LoadingMenuItem>
          )}

          {displayProps?.actions?.sendToCustomer && (
            <LoadingMenuItem
              onClick={async () => {
                await apiClient.damageCostEvaluationsApi.apiV1DamageCostEvaluationsDamageCostEvaluationIdSendToCustomerPost(
                  {
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    damageCostEvaluationId: entity?.id || "",
                  },
                );
              }}
            >
              <ListItemIcon>
                <AppIcon of='mail' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Send to customer</ListItemText>
            </LoadingMenuItem>
          )}

          {displayProps?.actions?.edit && (
            <AuthorizedMenuItem
              permissions={[AppPermission.DamageCostEvaluationManage]}
              component={AppLink}
              to={ROUTE_PATH.DAMAGE_COST_EVALUATION_EDIT(entity?.id)}
            >
              <ListItemIcon>
                <AppIcon of='edit' fontSize='small' />
              </ListItemIcon>
              <Typography component='span'>Edit</Typography>
            </AuthorizedMenuItem>
          )}

          {displayProps?.actions?.addToContract && (
            <AuthorizedMenuItem
              permissions={[AppPermission.DamageCostEvaluationManage]}
              disabled={!!entity?.contract}
              onClick={() => startAction(ActionFlags.AddToContract)}
            >
              <ListItemIcon>
                <AppIcon of='add' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Add to contract</ListItemText>
            </AuthorizedMenuItem>
          )}

          {/* NB: temporary disabled */}
          {/* <MenuItem
          component={RouterLink}
          to={ROUTE_PATH.DOCUMENTS({
            sourceType: EntitySourceType.DamageCostEvaluation,
            sourceId: entity.id,
          })}
        >
          <ListItemIcon>
            <AppIcon of='documents' fontSize='small' />
          </ListItemIcon>
          <Typography component='span'>Documents</Typography>
        </MenuItem> */}

          {displayProps?.actions?.delete && (
            <AuthorizedMenuItem
              permissions={[AppPermission.DamageCostEvaluationManage]}
              onClick={() => startAction(ActionFlags.Delete)}
            >
              <ListItemIcon>
                <AppIcon of='delete' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </AuthorizedMenuItem>
          )}

          {displayProps?.actions?.createRepairOperation && (
            <>
              <HeaderMenuItem primaryTitle='Quick actions' />
              <AuthorizedMenuItem
                permissionsAny={[
                  AppPermission.RepairOperationPerform,
                  AppPermission.RepairOperationManage,
                ]}
                key='repair'
                onClick={() => startAction(ActionFlags.CreateRepairOperation)}
              >
                <ListItemIcon>
                  <AppIcon of='repair' fontSize='small' />
                </ListItemIcon>
                <ListItemText>New repair operation</ListItemText>
              </AuthorizedMenuItem>
            </>
          )}
        </>
      )}
      actionHandlers={({ currentAction, cancelAction, completeAction }) => (
        <Stack>
          {/* Delete */}
          {entity && (
            <DamageCostEvaluationDeleteModal
              entity={entity}
              open={currentAction === ActionFlags.Delete}
              onClose={() => cancelAction()}
              onDelete={() => {
                onDelete && onDelete();
                completeAction();
              }}
            />
          )}

          {/* Add to contract */}
          {entity && (
            <AddDamageCostEvaluationToContractModal
              open={currentAction === ActionFlags.AddToContract}
              onClose={() => cancelAction()}
              damageCostEvaluation={entity}
            />
          )}

          {/* Create RepairOperation */}
          {entity && (
            <RepairOperationCreateUpdateModal
              open={currentAction === ActionFlags.CreateRepairOperation}
              onClose={() => cancelAction()}
              createUpdateProps={{
                defaultValues: {
                  vehicleId: entity.vehicle?.id,
                  contractId: entity.contract?.id,
                  vehicleDamageIds: _.flatten(
                    entity.items
                      ?.map((x) => x.damages?.map((y) => y.damage?.id || "") || [])
                      ?.filter(Boolean),
                  ),
                },
                onSave: (newValue) => {
                  completeAction();
                  history.push(ROUTE_PATH.REPAIR_OPERATION_VIEW(newValue.id));
                },
              }}
            />
          )}
        </Stack>
      )}
    />
  );
}
