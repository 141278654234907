import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { auth0Provider } from "@/common/auth0Provider";
import { ROUTE_PATH } from "@/common/constants/routing";
import { UrlHelper } from "@/common/helpers/url";
import { tenantService } from "@/common/services/tenant";

import {
  AuthorizationProfileDto,
  CompanyIdentifierValidateResultDto,
  PasswordPolicyDto,
  PasswordValidateResultDto,
  RegisterCompleteDto,
  RegisterDto,
  RegisterResultDto,
  SendEmailVerificationTokenDto,
  ValidateCompanyIdentifierDto,
  ValidateEmailVerificationTokenDto,
  ValidatePasswordDto,
} from "../../core/api/generated";
import { apiClient } from "./../../core/api/ApiClient";
import { AppDispatchType, AppThunk } from "./../index";

export interface SavedCompanyRegistrationInfo {
  companyId: string;
  userId?: string | null;
  email: string;
}

export interface AuthState {
  /** undefined - means initializing/loading */
  isAuthenticated?: boolean;
  authorizationProfile?: AuthorizationProfileDto;
  savedCompanyRegistrationInfo?: SavedCompanyRegistrationInfo;
}

const initialState: AuthState = {
  isAuthenticated: undefined,
  authorizationProfile: undefined,
  savedCompanyRegistrationInfo: localStorage.getItem("savedCompanyRegistrationInfo")
    ? JSON.parse(localStorage.getItem("savedCompanyRegistrationInfo") || "")
    : undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    _authenticateStarted: () => {},
    _authenticateSucceeded: (state) => {
      state.isAuthenticated = true;
    },
    _authenticateFailed: (state) => {
      state.isAuthenticated = false;
    },

    _authorizationProfileFetched: (state, action: PayloadAction<AuthorizationProfileDto>) => {
      state.authorizationProfile = action.payload;
    },

    _userDataFetched: (state, action) => {},

    _tokenRefreshSucceeded: (state, action) => {},
    _tokenRefreshFailed: () => {},
    _tokenRevokeSucceeded: () => {},
    _initialJwtRefresh: () => {},

    _logoutSucceeded: (state) => {
      state.isAuthenticated = false;
    },

    _registerCompanyInfoSucceeded: (state, action: PayloadAction<RegisterResultDto>) => {
      state.savedCompanyRegistrationInfo = {
        companyId: action.payload.companyId!,
        userId: action.payload.userId,
        email: action.payload.email!,
      };
      localStorage.setItem("savedCompanyRegistrationInfo", JSON.stringify(action.payload));
    },
    _completeRegistrationSucceeded: (state) => {
      state.savedCompanyRegistrationInfo = undefined;
      localStorage.removeItem("savedCompanyRegistrationInfo");
    },
    resetSavedCompanyRegistrationInfo: (state) => {
      state.savedCompanyRegistrationInfo = undefined;
      localStorage.removeItem("savedCompanyRegistrationInfo");
    },
  },
});

export const {
  _authenticateSucceeded,
  _authenticateFailed,
  _tokenRefreshSucceeded,
  _tokenRefreshFailed,
  _tokenRevokeSucceeded,
  _initialJwtRefresh,
  _userDataFetched,
  _logoutSucceeded,

  _authorizationProfileFetched,

  _registerCompanyInfoSucceeded,
  _completeRegistrationSucceeded,
  resetSavedCompanyRegistrationInfo,
} = authSlice.actions;

export const authReducer = authSlice.reducer;

// actions

export const logout = (): AppThunk<Promise<void>> => async (dispatch) => {
  const auth0 = auth0Provider.getAuth0Client();

  const loggedOutPath = ROUTE_PATH.AUTH_SIGNED_OUT;
  let returnToUrl = UrlHelper.updateUrlPathname(`${window.location.origin}`, loggedOutPath);

  if (tenantService.strategy === "basepath") {
    // const tenantInfo = tenantService.resolveTenant(undefined, true);
    // Auth0 doesn't support wildcards in pathname, so we must use the same pathname every time,
    // and handle redirect to desired pathname after logout manually
    returnToUrl = `${window.location.origin}/${tenantService.defaultTenantIdentifier}`;
    returnToUrl = UrlHelper.updateUrlPathname(
      `${window.location.origin}`,
      `/${tenantService.defaultTenantIdentifier}${loggedOutPath}`,
    );
  }

  try {
    await auth0?.logout({
      logoutParams: {
        returnTo: returnToUrl,
      },
    });
    await dispatch(_logoutSucceeded());
  } catch (error) {
    console.error(error);
  }
};

const _getAuthorizationProfile = async (
  dispatch: AppDispatchType,
): Promise<AuthorizationProfileDto> => {
  const response = await apiClient.profileApi.apiV1ProfileAuthorizationGet({
    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
  });
  dispatch(_authorizationProfileFetched(response.data));
  return response.data;
};
const _getAuthorizationProfileDebounce = _.debounce(_getAuthorizationProfile, 1000, {
  leading: false,
  trailing: true,
});
const _getAuthorizationProfileThrottle = _.throttle(_getAuthorizationProfile, 1000, {
  leading: true,
  trailing: false,
});

export const getAuthorizationProfile =
  (): AppThunk<Promise<AuthorizationProfileDto>> => async (dispatch, getState) => {
    return _getAuthorizationProfile(dispatch);
  };
export const getAuthorizationProfileDebounce =
  (): AppThunk<Promise<AuthorizationProfileDto | undefined>> => async (dispatch, getState) => {
    return _getAuthorizationProfileDebounce(dispatch);
  };
export const getAuthorizationProfileThrottle =
  (): AppThunk<Promise<AuthorizationProfileDto | undefined>> => async (dispatch, getState) => {
    return _getAuthorizationProfileThrottle(dispatch);
  };

export const validateCompanyIdentifier =
  (
    validateCompanyIdentifierDto: ValidateCompanyIdentifierDto,
  ): AppThunk<Promise<CompanyIdentifierValidateResultDto>> =>
  async () => {
    const response = await apiClient.accountApi.apiV1AccountRegisterCompanyIdentifierValidatePost({
      validateCompanyIdentifierDto,
    });
    return response.data;
  };

export const createNewCompany =
  (...args: Parameters<typeof apiClient.accountApi.apiV1AccountRegisterCompanyNewPost>): AppThunk =>
  async () => {
    await apiClient.accountApi.apiV1AccountRegisterCompanyNewPost(...args);
  };

export const registerCompanyInfo =
  (registerDto: RegisterDto): AppThunk =>
  async (dispatch) => {
    const response = await apiClient.accountApi.apiV1AccountRegisterCompanyInfoPost({
      registerDto,
    });
    dispatch(_registerCompanyInfoSucceeded(response.data));
  };

export const sendEmailVerificationToken =
  (sendEmailVerificationTokenDto: SendEmailVerificationTokenDto): AppThunk =>
  async () => {
    await apiClient.accountApi.apiV1AccountVerificationEmailTokenSendPost({
      sendEmailVerificationTokenDto,
    });
  };

export const validateEmailVerificationToken =
  (validateEmailVerificationTokenDto: ValidateEmailVerificationTokenDto): AppThunk =>
  async () => {
    await apiClient.accountApi.apiV1AccountVerificationEmailTokenValidatePost({
      validateEmailVerificationTokenDto,
    });
  };

export const getPasswordPolicy = (): AppThunk<Promise<PasswordPolicyDto>> => async () => {
  const response = await apiClient.accountApi.apiV1AccountPasswordPolicyGet();
  return response.data;
};

export const validateAccountPassword =
  (validatePasswordDto: ValidatePasswordDto): AppThunk<Promise<PasswordValidateResultDto>> =>
  async () => {
    const response = await apiClient.accountApi.apiV1AccountPasswordValidatePost({
      validatePasswordDto,
    });
    return response.data as unknown as PasswordValidateResultDto;
  };

export const completeRegistration =
  (registerCompleteDto: RegisterCompleteDto): AppThunk =>
  async (dispatch) => {
    await apiClient.accountApi.apiV1AccountRegisterCompanyCompletePost({ registerCompleteDto });
    dispatch(_completeRegistrationSucceeded());
  };
