import { CustomEnvName } from "@/common/constants/customEnvName";
import { EnvHelper } from "@/common/helpers/env";
import { TenantResolveStrategy } from "@/common/services/tenant";

export const appCommonConfig = {
  appName: "NexusFleet",
  version: EnvHelper.getEnvValue("REACT_APP_VERSION") || "0.0.0.0",
  customEnv: (EnvHelper.getEnvValue("REACT_APP_CUSTOM_ENV") ||
    CustomEnvName.DevelopmentLocalhost) as CustomEnvName,
  apiUrl: EnvHelper.getEnvValue("REACT_APP_API_URL"),
  nexusOpsPublicDocsUrl: EnvHelper.getEnvValue("REACT_APP_NEXUSOPS_PUBLIC_DOCS_URL"),
};

export const multitenancyConfig = {
  tenantResolveStrategy: EnvHelper.getEnvValue(
    "REACT_APP_TENANT_RESOLVE_STRATEGY",
  ) as TenantResolveStrategy,
};

const baseSentryConfig = {
  sentryDsn: EnvHelper.getEnvValue("REACT_APP_SENTRY_DSN"),
  tracePropagationTargets: [
    new RegExp(`https://.*${EnvHelper.getEnvValue("REACT_APP_APP_DOMAIN")}.*`),
    new RegExp(`https://.*${EnvHelper.getEnvValue("REACT_APP_APP_DOMAIN")}.*`),
  ],
};

const devSentryConfig = {
  ...baseSentryConfig,
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
};
const stagingSentryConfig = {
  ...baseSentryConfig,
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.5,
  replaysOnErrorSampleRate: 1.0,
};
const prodSentryConfig = {
  ...baseSentryConfig,
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
};

export const sentryConfig =
  appCommonConfig.customEnv === CustomEnvName.ProductionAzure
    ? prodSentryConfig
    : appCommonConfig.customEnv === CustomEnvName.StagingAzure
      ? stagingSentryConfig
      : devSentryConfig;

export const auth0Config = {
  client_id: EnvHelper.getEnvValue("REACT_APP_AUTH0_CLIENT_ID") || "",
  domain: EnvHelper.getEnvValue("REACT_APP_AUTH0_DOMAIN") || "",
  audience: "webapi",
  connections: {
    /** Login via email/password. */
    database: "UsernamePassword",
    /** Passwordless login via email. */
    email: "email",
    /** Passwordless login via phone. */
    sms: "sms",
  },
  defaultConnection: "UsernamePassword",
};

export const firebaseConfig = {
  apiKey: EnvHelper.getEnvValue("REACT_APP_FIREBASE_API_KEY") || "",
  authDomain: EnvHelper.getEnvValue("REACT_APP_FIREBASE_AUTH_DOMAIN") || "",
  projectId: EnvHelper.getEnvValue("REACT_APP_FIREBASE_PROJECT_ID") || "",
  storageBucket: EnvHelper.getEnvValue("REACT_APP_FIREBASE_STORAGE_BUCKET") || "",
  messagingSenderId: EnvHelper.getEnvValue("REACT_APP_FIREBASE_MESSAGING_SENDER_ID") || "",
  appId: EnvHelper.getEnvValue("REACT_APP_FIREBASE_APP_ID") || "",
  vapidKey: EnvHelper.getEnvValue("REACT_APP_FIREBASE_VAPID_KEY") || "",
};

export const clarityConfig = {
  enabled: !!EnvHelper.getEnvValue("REACT_APP_CLARITY_TRACKING_CODE"),
  trackingCode: EnvHelper.getEnvValue("REACT_APP_CLARITY_TRACKING_CODE") || "",
};

export const teslaConfig = {
  authUrl: EnvHelper.getEnvValue("REACT_APP_TESLA_AUTH_URL") || "",
  partnerApplicationClientId:
    EnvHelper.getEnvValue("REACT_APP_TESLA_PARTNER_APPLICATION_CLIENT_ID") || "",
  partnerApplicationCallbackUrl:
    EnvHelper.getEnvValue("REACT_APP_TESLA_PARTNER_APPLICATION_CALLBACK_URL") || "",
};

/** Query string params that are set either internally or externally.
 *  For instance, by back-end in email, sms links.
 */
export const ExternalQueryStringParamName = {
  /** Absolute URI. */
  RedirectUrl: "redirectUrl",
  /** Value contains base64 encoded JSON object. */
  ActionParamsEnc: "actionParamsEnc",
  /** Value contains base64 encoded JSON object. */
  AuthParamsEnc: "authParamsEnc",
} as const;
export type ExternalQueryStringParamName =
  (typeof ExternalQueryStringParamName)[keyof typeof ExternalQueryStringParamName];

/** Query string params that are set internally in the app. */
export const InternalQueryStringParamName = {
  /** Relative URI (handled by react router). */
  SpaRedirectUrl: "spaRedirectUrl",
  /** Encoded highlight props, used to highlight something on the page on mount. */
  HighlightPropsEnc: "highlightPropsEnc",
  /** Id of active tab. */
  ActiveTabId: "activeTabId",
} as const;
export type InternalQueryStringParamName =
  (typeof InternalQueryStringParamName)[keyof typeof InternalQueryStringParamName];
