import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function NegotiationsIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <g clipPath='url(#clip0_12097_26419)'>
        <path d='M13.3524 19.2201C12.4263 19.4847 11.4561 19.617 10.4712 19.617C8.66803 19.617 6.91384 19.1613 5.40466 18.3038C5.21846 18.196 4.98326 18.2009 4.79706 18.3087C3.91997 18.833 2.97918 18.9702 2.24908 18.9702C2.24418 18.9702 2.23928 18.9702 2.23928 18.9702C2.72438 18.4949 3.10167 17.902 3.35647 17.2062C3.43487 16.9955 3.38587 16.7554 3.23397 16.5888C1.90118 15.1433 1.20049 13.4038 1.20049 11.5566C1.20049 7.1123 5.36056 3.50103 10.4761 3.50103C15.2193 3.50103 19.1833 6.588 19.6929 10.6795C19.7321 11.0078 20.031 11.243 20.3642 11.1989C20.6925 11.1597 20.9277 10.8559 20.8836 10.5276C20.3005 5.83831 15.822 2.30054 10.4712 2.30054C4.69906 2.30054 0 6.4557 0 11.5566C0 13.5606 0.739894 15.5157 2.09718 17.1131C1.81788 17.706 1.42099 18.1715 0.906492 18.5096C0.597795 18.7105 0.450796 19.078 0.529196 19.4357C0.607595 19.7885 0.896693 20.0531 1.25439 20.1021C2.07268 20.2148 3.62107 20.2785 5.11066 19.5092C6.73254 20.3618 8.57493 20.8126 10.4712 20.8126C11.5688 20.8126 12.6468 20.6656 13.6807 20.3716C13.9992 20.2834 14.1854 19.9502 14.0923 19.6317C13.9992 19.3132 13.6758 19.127 13.3524 19.2201Z' />
        <path d='M10.4713 12.2229C10.8394 12.2229 11.1377 11.9246 11.1377 11.5565C11.1377 11.1885 10.8394 10.8901 10.4713 10.8901C10.1033 10.8901 9.80493 11.1885 9.80493 11.5565C9.80493 11.9246 10.1033 12.2229 10.4713 12.2229Z' />
        <path d='M7.59974 12.2229C7.96778 12.2229 8.26614 11.9246 8.26614 11.5565C8.26614 11.1885 7.96778 10.8901 7.59974 10.8901C7.2317 10.8901 6.93335 11.1885 6.93335 11.5565C6.93335 11.9246 7.2317 12.2229 7.59974 12.2229Z' />
        <path d='M13.3378 12.2229C13.7058 12.2229 14.0042 11.9246 14.0042 11.5565C14.0042 11.1885 13.7058 10.8901 13.3378 10.8901C12.9697 10.8901 12.6714 11.1885 12.6714 11.5565C12.6714 11.9246 12.9697 12.2229 13.3378 12.2229Z' />
        <path d='M19.0264 11.7526C17.6985 11.7526 16.4491 12.272 15.5083 13.2079C13.5679 15.1482 13.5679 18.3038 15.5083 20.2442C16.4491 21.185 17.6985 21.6995 19.0264 21.6995C20.3543 21.6995 21.6038 21.1801 22.5446 20.2442C24.485 18.3038 24.485 15.1482 22.5446 13.2079C21.6038 12.272 20.3543 11.7526 19.0264 11.7526ZM21.6969 19.3965C20.9815 20.107 20.0358 20.5039 19.0264 20.5039C18.017 20.5039 17.0714 20.1119 16.356 19.3965C14.886 17.9265 14.886 15.5304 16.356 14.0604C17.0714 13.35 18.017 12.9531 19.0264 12.9531C20.0358 12.9531 20.9815 13.3451 21.6969 14.0604C23.1669 15.5304 23.1669 17.9265 21.6969 19.3965Z' />
        <path d='M20.5405 15.2168C20.3053 14.9816 19.928 14.9816 19.6928 15.2168L19.0264 15.8832L18.36 15.2168C18.1248 14.9816 17.7475 14.9816 17.5123 15.2168C17.2771 15.452 17.2771 15.8293 17.5123 16.0645L18.1787 16.7309L17.5123 17.3924C17.2771 17.6276 17.2771 18.0049 17.5123 18.2401C17.6299 18.3577 17.7818 18.4165 17.9386 18.4165C18.0954 18.4165 18.2473 18.3577 18.3649 18.2401L19.0313 17.5737L19.6977 18.2401C19.8153 18.3577 19.9672 18.4165 20.124 18.4165C20.2808 18.4165 20.4327 18.3577 20.5503 18.2401C20.7855 18.0049 20.7855 17.6276 20.5503 17.3924L19.8839 16.726L20.5503 16.0596C20.7757 15.8293 20.7757 15.452 20.5405 15.2168Z' />
      </g>
      <defs>
        <clipPath id='clip0_12097_26419'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
});
