import { SxProps, Theme } from "@mui/material";
import { useState } from "react";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import { tenantToTenantEventEmitter } from "@/common/eventEmmiters/tenantToTenantEventEmitter";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  DataUpdatesHubClientMethodName,
  EntityType,
  TenantConnectionDto,
  TenantConnectionGetPaginatedDto,
} from "@/core/api/generated";

import TenantInline from "../../Tenant/TenantInline";
import TenantConnectionViewModal from "../View/TenantConnectionViewModal";

const defaultDisplayProps = {
  breadcrumbs: true,
  filters: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultPropagatedDeps = {
  refetch: {},
};
export type TenantConnectionPaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;

export interface TenantConnectionPaginatedListOwnProps {
  displayProps?: Partial<typeof defaultDisplayProps>;
  defaultValues?: {
    limit?: number;
  };
  propagatedDeps?: PropagatedDeps<TenantConnectionPaginatedListPropagatedDepsMap>;
  sx?: SxProps<Theme>;
}

export type TenantConnectionPaginatedListProps = TenantConnectionPaginatedListOwnProps;

export default function TenantConnectionPaginatedList({
  displayProps,
  defaultValues,
  propagatedDeps,
  sx,
}: TenantConnectionPaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<TenantConnectionGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.TenantConnection,
    },
    defaultValues: {
      limit: defaultValues?.limit,
      params: {
        ...defaultValues,
      },
    },
  });

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [toView, setToView] = useState<TenantConnectionDto | null | undefined>(undefined);

  const paginatedTenantConnectionsRequest = useApiRequest(
    apiClient.tenantConnectionsApi.apiV1TenantToTenantTenantConnectionsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      tenantConnectionGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps, propagatedDeps?.depsMap["refetch"]],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedTenantConnections = paginatedTenantConnectionsRequest?.data;

  useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entities(undefined, EntityType.TenantConnection),
      DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.TenantConnection),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedTenantConnectionsRequest.handleEntityChanged(data);
    },
  });

  return (
    <ViewLayoutV2
      displayProps={displayProps}
      header={<SimpleViewPageHeader title={undefined} primaryActions={undefined} />}
    >
      <DataTabular<TenantConnectionDto>
        columns={[
          {
            field: "connectedTenant1.id",
            title: "Company 1",
            flex: 1,
            renderCell: (item) => <TenantInline entity={item.connectedTenant1} />,
          },
          {
            field: "connectedTenant2.id",
            title: "Company 2",
            flex: 1,
            renderCell: (item) => <TenantInline entity={item.connectedTenant2} />,
          },
          {
            field: "status",
            title: "Status",
            flex: 1,
            renderCell: (item) => (
              <InlineApiEnumValue type='TenantConnectionStatus' value={item.status} />
            ),
          },
          {
            field: "createdAt",
            title: "Created at",
            flex: 1,
            renderCell: (item) => <Datetime datetime={item.createdAt} withDurationFromNow />,
          },
        ]}
        rows={paginatedTenantConnections?.items}
        isLoading={paginatedTenantConnectionsRequest.isLoading}
        getRowId={(item) => item.id!}
        // rowTo={(item) => ROUTE_PATH.MANAGEMENT_TENANT_CONNECTION_REQUEST_VIEW(item.id)}
        onRowClick={(item) => {
          setToView(item);
          setIsViewModalOpen(true);
        }}
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
        refetch={commonRequestParams.dataTabularProps.refetch}
      />

      {/* View modal */}
      {toView && (
        <TenantConnectionViewModal
          open={isViewModalOpen}
          onClose={() => setIsViewModalOpen(false)}
          viewProps={{
            tenantConnectionId: toView.id,
            tenantConnection: undefined,
            onSave: (newValue) => {
              tenantToTenantEventEmitter.emit("tenantConnectionUpdated", newValue);
            },
          }}
        />
      )}
    </ViewLayoutV2>
  );
}
