import { Divider, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import {
  GridColumnMenuContainer,
  GridColumnMenuProps,
  GridPreferencePanelsValue,
  GridSortModel,
  GridValidRowModel,
  gridColumnMenuSelector,
  gridSortModelSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from "@mui/x-data-grid";
import { useMemo } from "react";

import { useCustomDataGridContext } from "@/common/contexts/customDataGrid";

import AppIcon from "../Icons/AppIcon";

declare module "@mui/x-data-grid" {
  // interface ColumnMenuPropsOverrides {
  //   // openFilterPanel: (targetColumn: TabularColumnModel<any>) => void;
  // }
}

interface Props<T extends GridValidRowModel> extends GridColumnMenuProps {
  // hideMenu: (event: React.SyntheticEvent) => void;
  // colDef: TabularColumnModel<T>;
  // open: boolean;
  // id?: string;
  // labelledby?: string;
  // openFilterPanel: (targetColumn: TabularColumnModel<T>) => void;
}

export default function CustomDataGridColumnMenu<T extends GridValidRowModel>(props: Props<T>) {
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();
  // const preferencePanelState = useGridSelector(apiRef, gridPreferencePanelStateSelector);
  const columnMenuState = useGridSelector(apiRef, gridColumnMenuSelector);
  const sortModel = useGridSelector(apiRef, gridSortModelSelector);
  const customDataGridContext = useCustomDataGridContext();

  const isColumnSelectorEnabled = useMemo(
    () => !rootProps.disableColumnSelector,
    [rootProps.disableColumnSelector],
  );
  const isColumnSortEnabled = useMemo(
    () => !rootProps.disableColumnSorting,
    [rootProps.disableColumnSorting],
  );
  const isColumnFilterEnabled = useMemo(
    () => !rootProps.disableColumnFilter,
    [rootProps.disableColumnFilter],
  );

  const isHideable = useMemo(
    () => isColumnSelectorEnabled && props.colDef.hideable,
    [isColumnSelectorEnabled, props.colDef.hideable],
  );
  const isSortable = useMemo(
    () => isColumnSortEnabled && props.colDef.sortable,
    [isColumnSortEnabled, props.colDef.sortable],
  );
  const isFilterable = useMemo(
    () => isColumnFilterEnabled && props.colDef.filterable,
    [isColumnFilterEnabled, props.colDef.filterable],
  );

  const enabledList = [isHideable, isSortable, isFilterable];
  const isAnyEnabled = useMemo(() => enabledList.some((x) => x === true), [...enabledList]);

  const sortItem = useMemo(
    () => sortModel?.find((x) => x.field === props.colDef.field),
    [sortModel],
  );
  const isSorted = useMemo(() => !!sortItem, [sortItem]);
  const isSortedAsc = useMemo(() => sortItem?.sort === "asc", [sortItem]);
  const isSortedDesc = useMemo(() => sortItem?.sort === "desc", [sortItem]);

  const closeMenu = () => {
    if (columnMenuState.open) {
      apiRef.current.hideColumnMenu();
    }
  };

  if (!isAnyEnabled) {
    return null;
  }

  return (
    <GridColumnMenuContainer {...props}>
      {/* <MenuList> */}
      {/* Sort */}
      {isSortable && !isSortedAsc && (
        <MenuItem
          onClick={() => {
            apiRef.current.sortColumn(props.colDef.field, "asc");
            closeMenu();
          }}
        >
          <ListItemIcon>
            <AppIcon of='sortAsc' />
          </ListItemIcon>
          <ListItemText>Sort ascending (ASC)</ListItemText>
        </MenuItem>
      )}
      {isSortable && !isSortedDesc && (
        <MenuItem
          onClick={() => {
            apiRef.current.sortColumn(props.colDef.field, "desc");
            closeMenu();
          }}
        >
          <ListItemIcon>
            <AppIcon of='sortDesc' />
          </ListItemIcon>
          <ListItemText>Sort ascending (DESC)</ListItemText>
        </MenuItem>
      )}
      {isSortable && isSorted && (
        <MenuItem
          onClick={() => {
            const newSortModel: GridSortModel = sortModel.filter(
              (x) => x.field !== props.colDef.field,
            );
            apiRef.current.setSortModel(newSortModel);
            closeMenu();
          }}
        >
          <ListItemIcon>
            <AppIcon of='remove' />
          </ListItemIcon>
          <ListItemText>Unsort</ListItemText>
        </MenuItem>
      )}
      {isSortable && (isFilterable || isHideable) && <Divider />}

      {/* Filter */}
      {isFilterable && (
        <MenuItem
          onClick={() => {
            customDataGridContext.requestAddFieldFilter({ field: props.colDef.field });
            closeMenu();
          }}
        >
          <ListItemIcon>
            <AppIcon of='filter' />
          </ListItemIcon>
          <ListItemText>Filter</ListItemText>
        </MenuItem>
      )}
      {isFilterable && isHideable && <Divider />}

      {/* Column management */}
      {isHideable && (
        <MenuItem
          onClick={() => {
            apiRef.current.setColumnVisibility(props.colDef.field, false);
            closeMenu();
          }}
        >
          <ListItemIcon>
            <AppIcon of='hide' />
          </ListItemIcon>
          <ListItemText>Hide column</ListItemText>
        </MenuItem>
      )}
      {isHideable && (
        <MenuItem
          onClick={() => {
            apiRef.current.showPreferences(GridPreferencePanelsValue.columns);
            closeMenu();
          }}
        >
          <ListItemIcon>
            <AppIcon of='columns' />
          </ListItemIcon>
          <ListItemText>Manage columns</ListItemText>
        </MenuItem>
      )}
      {/* </MenuList> */}
    </GridColumnMenuContainer>
  );
}
