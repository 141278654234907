import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { FormikProps, getIn } from "formik";
import _ from "lodash";
import { useState } from "react";

import { FeatureName } from "@/common/constants/featureName";
import { FileItem } from "@/common/fileItem";
import { ArrayHelper } from "@/common/helpers/array";
import { FormikHelper } from "@/common/helpers/formik";
import { MemoHelper, memoWithReversedArgs } from "@/common/helpers/memo";
import { ValidationHelper } from "@/common/validation";
import { featureManager } from "@/config/features";
import {
  AppPermission,
  GeneralCurrencyInputDto,
  RepairSpecDataDto,
  RepairSpecDataInputDto,
  RepairSpecDetalizationType,
  RepairSpecDto,
  RepairSpecMatchQueryDto,
  VehicleReferenceDto,
  VehicleType,
} from "@/core/api/generated";

import NoDataAlert from "../../../AppAlerts/NoDataAlert";
import AuthorizedElement from "../../../Auth/AuthorizedElement";
import DropdownIconButton from "../../../Button/DropdownIconButton";
import DevRenderCount from "../../../Dev/DevRenderCount";
import FoldableBlock from "../../../Display/FoldableBlock";
import FileUploader from "../../../Files/FileUploader";
import AppTextArea from "../../../Form/Input/AppTextArea";
import AppIcon from "../../../Icons/AppIcon";
import CompoundIcon from "../../../Icons/CompoundIcon";
import GeneralPriceSummaryDisplay from "../../../PriceSummary/GeneralPriceSummaryDisplay";
import CreateUpdateRepairSpecModal from "../../RepairSpec/CreateUpdateRepairSpecModal";
import RepairSpecCrossTenantSelectorPopover from "../../RepairSpec/RepairSpecCrossTenantSelectorPopover";
import RepairSpecDataInputDtoFormInputs, {
  mapRepairSpecDtoToDataInputDto,
} from "../../RepairSpec/RepairSpecDataInputDtoFormInputs";
import SelectOrCreateRepairSpec from "../../RepairSpec/SelectOrCreateRepairSpec";
import VehicleDamageAutocomplete from "../../VehicleDamage/VehicleDamageAutocomplete";
import CurrencyMismatchWithRepairSpecAlert from "../CurrencyMismatchWithRepairSpecAlert";
import {
  DamageCostEvaluationItemValues,
  DamageCostEvaluationValues,
} from "../DamageCostEvaluationCreateUpdate";

export interface DamageCostEvaluationItemInputProps {
  formikProps: FormikProps<DamageCostEvaluationValues>;
  isCreate: boolean;
  isEdit: boolean;
  canEdit: boolean;
  canEditDamages: boolean;
  canAddDamages: boolean;
  canRemoveDamages: boolean;
  canRemoveItems: boolean;
  canEditRepairSpec: boolean;
  vehicleId: string;
  vehicle: VehicleReferenceDto | undefined;
  currency: GeneralCurrencyInputDto | undefined;
  selectedDamageIds: string[] | undefined;
  itemCount: number; // ensures that all items will re-render if any item is added/removed
  item: DamageCostEvaluationItemValues;
  itemIndex: number;
  setIsAttachmentFilesUploading: (newValue: boolean) => void;
}

export default memoWithReversedArgs(
  MemoHelper.arePropsEqual.factory<DamageCostEvaluationItemInputProps>({
    // debugKey: "DamageCostEvaluationItemInput",
    isExcludeFunctionProps: true,
    excludeProps: (t) => [t.formikProps.$path],
    includeProps: (t) => [t.formikProps.errors.$path],
  }),
  function DamageCostEvaluationItemInput({
    formikProps,
    isCreate,
    isEdit,
    canEdit,
    canEditDamages,
    canAddDamages,
    canRemoveDamages,
    canRemoveItems,
    canEditRepairSpec,
    vehicleId,
    vehicle,
    currency,
    selectedDamageIds,
    itemCount,
    item,
    itemIndex,
    setIsAttachmentFilesUploading,
  }: DamageCostEvaluationItemInputProps) {
    const { values, errors, setFieldValue, handleBlur, handleChange } = formikProps;

    const [isCreateRepairSpecModalOpen, setIsCreateRepairSpecModalOpen] = useState(false);
    const [createRepairSpecFromSpecData, setCreateRepairSpecFromSpecData] = useState<
      (RepairSpecDataDto & RepairSpecDataInputDto) | undefined
    >(undefined);

    // use match to query best suggestions (match by the first damage)
    const repairSpecMatchQuery: RepairSpecMatchQueryDto = {
      damageTypeId: item.damages?.at(0)?.damage?.damageType?.id || undefined,
      partTypeId: item.damages?.at(0)?.damage?.partType?.id || undefined,
      vehicleId: vehicleId || undefined,
    };

    const setItemNameAuto = (newValue: Nil<string>) => {
      if ((!item.isNameEditedManually || !item.name) && newValue) {
        setFieldValue(`items[${itemIndex}].name`, newValue);
      }
    };

    const setItemRepairSpec = (
      newValue: Nil<RepairSpecDto>,
      options?: { isExternal?: boolean },
    ) => {
      console.log("spec", newValue && mapRepairSpecDtoToDataInputDto(newValue));
      setFieldValue(
        `items[${itemIndex}].repairSpec`,
        newValue?.id ? mapRepairSpecDtoToDataInputDto(newValue) : undefined,
      );
      setFieldValue(`items[${itemIndex}].isRepairSpecChanged`, options?.isExternal ? true : false);
      setItemNameAuto(newValue?.name);
    };

    return (
      <Box>
        <Stack spacing={2}>
          <DevRenderCount description='DamageCostEvaluationItemInput' />

          {/* Header */}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 0fr",
              gridTemplateRows: "auto",
              gap: 2,
              alignItems: "start",
            }}
          >
            <Stack direction='row' spacing={2} sx={{ alignItems: "center" }}>
              <Typography variant='h3'>#{itemIndex + 1}</Typography>

              <TextField
                variant='outlined'
                size='small'
                label='Name'
                required
                disabled={!canEdit}
                fullWidth
                error={Boolean(getIn(errors, `items[${itemIndex}].name`))}
                helperText={ValidationHelper.getFormikErrorsAsString(
                  getIn(errors, `items[${itemIndex}].name`),
                )}
                value={item.name || ""}
                onChange={(e) => {
                  setFieldValue(`items[${itemIndex}].name`, e.target.value || undefined);
                  setFieldValue(`items[${itemIndex}].isNameEditedManually`, true);
                }}
              />
            </Stack>

            {/* Controls */}
            <Stack
              direction='row'
              sx={{
                ml: "auto",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {/* Notes */}
              {(() => {
                const isAdd = _.isNil(item.notes);
                return (
                  <Tooltip title={isAdd ? "Add notes" : "Remove notes"}>
                    <IconButton
                      disabled={!canEdit}
                      onClick={() =>
                        setFieldValue(`items[${itemIndex}].notes`, isAdd ? "" : undefined)
                      }
                    >
                      <CompoundIcon
                        mainIcon={<AppIcon of='notes' />}
                        badgeIcon={<AppIcon of={isAdd ? "add" : "remove"} />}
                      />
                    </IconButton>
                  </Tooltip>
                );
              })()}

              {/* Attachments */}
              {(() => {
                const isAdd = _.isNil(item.attachments);
                return (
                  <Tooltip title={isAdd ? "Add attachments" : "Remove attachments"}>
                    <IconButton
                      disabled={!canEdit}
                      onClick={() =>
                        setFieldValue(`items[${itemIndex}].attachments`, isAdd ? [] : undefined)
                      }
                    >
                      <CompoundIcon
                        mainIcon={<AppIcon of='attachments' />}
                        badgeIcon={<AppIcon of={isAdd ? "add" : "remove"} />}
                      />
                    </IconButton>
                  </Tooltip>
                );
              })()}

              {/* Remove item */}
              <Tooltip title='Remove item'>
                <IconButton
                  disabled={!canRemoveItems}
                  onClick={() => {
                    setFieldValue(
                      `items`,
                      values.items?.filter((x) => x.tempId !== item.tempId),
                    );
                  }}
                >
                  <AppIcon of='remove' />
                </IconButton>
              </Tooltip>
            </Stack>
          </Box>

          <Divider />

          {/* Currency mismatch alert */}
          {currency && <CurrencyMismatchWithRepairSpecAlert entity={values} item={item} />}

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridTemplateRows: "auto",
              gap: 2,
              alignItems: "start",
            }}
          >
            {/* Damages */}
            <Stack spacing={2}>
              <Stack spacing={2}>
                {_.isEmpty(item.damages) && (
                  <NoDataAlert title='No damages added' variant='inline' />
                )}

                {item.damages?.map((damage, damageIndex) => {
                  const excludeDamageIds = selectedDamageIds?.filter(
                    (x) => !!x && x !== damage.damageId,
                  );

                  return (
                    <Box
                      key={damageIndex}
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 0fr",
                        gridTemplateRows: "auto",
                        columnGap: 1,
                        rowGap: 1,
                      }}
                    >
                      {/* Damage */}
                      <FormControl fullWidth margin='none'>
                        <VehicleDamageAutocomplete
                          entityId={damage.damageId}
                          requestConfig={{
                            skip: _.isNil(selectedDamageIds),
                          }}
                          searchFilters={{
                            vehicleId: vehicleId || "",
                            excludeIds: excludeDamageIds,
                          }}
                          disabled={!canEditDamages}
                          required
                          fullWidth
                          isAutoSelectSingleOption={false}
                          size='small'
                          textFieldProps={{
                            error: Boolean(
                              getIn(errors, `items[${itemIndex}].damages[${damageIndex}].damageId`),
                            ),
                            helperText: ValidationHelper.getFormikErrorsAsString(
                              getIn(errors, `items[${itemIndex}].damages[${damageIndex}].damageId`),
                            ),
                          }}
                          shouldHide={(entity) =>
                            !!excludeDamageIds && excludeDamageIds.includes(entity.id || "")
                          }
                          onChange={(newValue) => {
                            // const oldDamageId = damage.damageId;
                            // const newDamageId = newValue?.id;
                            const updatedItem = { ...item };

                            if (updatedItem.damages?.[damageIndex]) {
                              updatedItem.damages[damageIndex].damageId = newValue?.id;
                              updatedItem.damages[damageIndex].damage = newValue;

                              // setFieldValue(
                              //   "selectedDamageIds",
                              //   selectedDamageIds?.extensions
                              //     .filterIf(!!oldDamageId, (x) => x !== oldDamageId)
                              //     .extensions.concatItemIf(!!newDamageId, newDamageId || ""),
                              // );
                            }

                            // filtering attachments to keep only those
                            // that are actual and vehicleDamage attached
                            const computedAttachments = [
                              ...(updatedItem.attachments?.filter((prevAttachment) => {
                                // if attachment have not id it means it is new attachment
                                if (_.isNil(prevAttachment.id)) {
                                  return true;
                                }
                                return updatedItem?.damages?.some((prevDamage) =>
                                  prevDamage.damage?.attachments?.some(
                                    (att) => att.id === prevAttachment.id,
                                  ),
                                );
                              }) || []),
                              ...(newValue?.attachments || []),
                            ];

                            updatedItem.attachments =
                              computedAttachments.length > 0 ? computedAttachments : undefined;

                            setFieldValue(`items[${itemIndex}]`, updatedItem);
                          }}
                        />
                      </FormControl>

                      {/* Controls */}
                      <Stack
                        direction='row'
                        sx={{
                          ml: "auto",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        {/* Move damage to different item */}
                        {damage.damageId && itemCount > 1 && (
                          <Tooltip title='Move damage to different item'>
                            <DropdownIconButton
                              variant='default'
                              size='small'
                              color='text'
                              disabled={!canEdit}
                              dropdownContent={
                                <MenuList sx={{ maxHeight: 400, overflow: "auto" }}>
                                  {values.items
                                    ?.map(
                                      (item2, i2) =>
                                        i2 !== itemIndex && (
                                          <MenuItem
                                            key={i2}
                                            onClick={() => {
                                              const movedDamage = damage;
                                              setFieldValue(
                                                `items[${itemIndex}].damages`,
                                                ArrayHelper.removeByIndex(
                                                  [...(item.damages || [])],
                                                  damageIndex,
                                                ),
                                              );
                                              setFieldValue(`items[${i2}].damages`, [
                                                ...(values.items?.at(i2)?.damages || []),
                                                movedDamage,
                                              ]);
                                            }}
                                          >
                                            <ListItemText>
                                              Move to #{i2 + 1} {item2.name}
                                            </ListItemText>
                                          </MenuItem>
                                        ),
                                    )
                                    .filter(Boolean)}
                                </MenuList>
                              }
                            >
                              <AppIcon of='moveItem' />
                            </DropdownIconButton>
                          </Tooltip>
                        )}

                        {/* Remove damage */}
                        <Tooltip title='Remove damage'>
                          <IconButton
                            size='small'
                            color='text'
                            disabled={!canRemoveDamages}
                            onClick={() => {
                              setFieldValue(
                                `items[${itemIndex}].damages`,
                                ArrayHelper.removeByIndex([...(item.damages || [])], damageIndex),
                              );
                              if (item.damages?.[damageIndex]?.damage?.attachments?.length) {
                                // filtering attachments to delete attachments from deleted damage
                                const filteredAttachments =
                                  item.attachments?.filter(
                                    (attachment) =>
                                      !item.damages?.[damageIndex]?.damage?.attachments?.some(
                                        (deletedAttachment) =>
                                          deletedAttachment?.id === attachment?.id,
                                      ),
                                  ) || [];
                                setFieldValue(
                                  `items[${itemIndex}].attachments`,
                                  filteredAttachments.length > 0 ? filteredAttachments : undefined,
                                );
                              }
                            }}
                          >
                            <AppIcon of='remove' />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </Box>
                  );
                })}
              </Stack>

              {ValidationHelper.getFormikErrorsAsString(
                getIn(errors, `items[${itemIndex}].damages`),
                {
                  isIncludeNested: false,
                },
              ) && (
                <FormHelperText error>
                  {ValidationHelper.getFormikErrorsAsString(
                    getIn(errors, `items[${itemIndex}].damages`),
                    {
                      isIncludeNested: false,
                    },
                  )}
                </FormHelperText>
              )}

              {/* Add damage */}
              <Box>
                <Button
                  disabled={!canAddDamages}
                  variant='outlined'
                  size='small'
                  color='text'
                  startIcon={<AppIcon of='add' />}
                  onClick={() =>
                    setFieldValue(`items[${itemIndex}].damages`, [...(item.damages || []), {}])
                  }
                >
                  Add damage
                </Button>
              </Box>
            </Stack>

            {/* Repair spec  */}
            <Stack
              direction={{ xxs: "column", md: "row" }}
              spacing={1}
              sx={{ alignItems: "center" }}
            >
              <FormControl
                sx={{ flex: 10 }}
                margin='none'
                error={Boolean(
                  getIn(errors, `items[${itemIndex}].repairSpecId`) ||
                    getIn(errors, `items[${itemIndex}].repairSpec`),
                )}
              >
                <SelectOrCreateRepairSpec
                  autocompleteProps={{
                    debugKey: "RepairSpec",
                    entityId: item.repairSpec?.id,
                    disabled: !canEditRepairSpec || item.isRepairSpecChanged,
                    required: true,
                    size: "small",
                    isPreload: isCreate,
                    isAutoSelectSingleOption: true,
                    searchFilters: {
                      match: repairSpecMatchQuery,
                    },
                    textFieldProps: {
                      error: Boolean(
                        getIn(errors, `items[${itemIndex}].repairSpecId`) ||
                          getIn(errors, `items[${itemIndex}].repairSpec`),
                      ),
                      helperText: (
                        <>
                          {getIn(errors, `items[${itemIndex}].repairSpecId`) && (
                            <Box>
                              {ValidationHelper.getFormikErrorsAsString(
                                getIn(errors, `items[${itemIndex}].repairSpecId`),
                              )}
                            </Box>
                          )}
                          {getIn(errors, `items[${itemIndex}].repairSpec`) && (
                            <Box>
                              {ValidationHelper.getFormikErrorsAsString(
                                getIn(errors, `items[${itemIndex}].repairSpec`),
                              )}
                            </Box>
                          )}
                        </>
                      ),
                    },
                    onChange: (newValue) => {
                      setItemRepairSpec(newValue);
                    },
                    onLoaded: (entities) => {
                      // when creating DCE with prefilled damages, auto-select the best matching repair spec for each DCE item
                      // NB: ensure best matching repair spec is auto-selected only initially

                      // Problem: setFieldValue experiences inconsistent behavior in the onLoaded method
                      // due to React's asynchronous state updates and batching. This can lead to stale
                      // or outdated values being applied, especially when multiple state changes occur simultaneously.

                      // Temporary solution: Wrapping setFieldValue in setTimeout ensures that the state
                      // updates occur after React's rendering process is completed, preventing issues
                      // with stale values. This approach is used as other solutions (like async/await)
                      // were found unreliable.
                      setTimeout(() => {
                        if (
                          isCreate &&
                          // !_.isEmpty(defaultVehicleDamages) &&
                          !item.repairSpec &&
                          !item.isInitiallyAutoSelectedBestMatchingRepairSpec
                        ) {
                          const newValue = _.orderBy(
                            entities,
                            (x) => x.matchMeta?.score ?? 0,
                            "desc",
                          ).at(0);

                          if (newValue) {
                            setItemRepairSpec(newValue);
                            setFieldValue(
                              `items[${itemIndex}].isInitiallyAutoSelectedBestMatchingRepairSpec`,
                              true,
                            );
                          }
                        }
                      }, 3000);
                    },
                  }}
                  createUpdateProps={{
                    defaultValues: {
                      damageTypeId: item.damages?.at(0)?.damage?.damageType?.id || undefined,
                      partTypeId: item.damages?.at(0)?.damage?.partType?.id || undefined,
                      currency: currency,
                    },
                  }}
                  createFormPlacement='modal'
                  onCreate={(newValue) => {
                    setItemRepairSpec(newValue);
                  }}
                />
              </FormControl>

              {featureManager.isEnabled(FeatureName.CrossTenantRepairSpecAccess) && (
                <RepairSpecCrossTenantSelectorPopover
                  clickBehavior={{}}
                  trigger={
                    <Tooltip title='My cross-company repair specs (advanced)'>
                      <IconButton
                        size='small'
                        color='text'
                        disabled={!canEditRepairSpec || item.isRepairSpecChanged}
                      >
                        <AppIcon of='crossTenantBrowse' />
                      </IconButton>
                    </Tooltip>
                  }
                  selectorProps={{
                    repairSpecAutocompleteProps: {
                      searchFilters: {
                        match: repairSpecMatchQuery,
                      },
                    },
                    onSave: (newValue) => {
                      console.log("cross-tenant repair spec selected", newValue);
                      setItemRepairSpec(newValue?.repairSpec, {
                        isExternal: newValue?.isExternal,
                      });
                    },
                    onCancel: () => {},
                  }}
                />
              )}

              <AuthorizedElement
                permissions={[
                  AppPermission.FleetAppAccess,
                  AppPermission.RepairCatalogRead,
                  AppPermission.RepairCatalogManage,
                ]}
              >
                {!item.isRepairSpecChanged && canEditRepairSpec && (
                  <Tooltip title='Enter custom values for this repair spec'>
                    <Button
                      variant='outlined'
                      color='text'
                      size='small'
                      disabled={!canEdit}
                      startIcon={<AppIcon of='edit' />}
                      onClick={() => {
                        setFieldValue(`items[${itemIndex}].isRepairSpecChanged`, true);
                        if (!item.repairSpec) {
                          setFieldValue(`items[${itemIndex}].repairSpec`, {});
                        }
                      }}
                    >
                      Edit repair spec
                    </Button>
                  </Tooltip>
                )}

                {item.isRepairSpecChanged && canEditRepairSpec && (
                  <Button
                    variant='outlined'
                    color='text'
                    size='small'
                    disabled={!canEdit}
                    startIcon={<AppIcon of='list' />}
                    onClick={() => {
                      setFieldValue(`items[${itemIndex}].isRepairSpecChanged`, false);
                      setFieldValue(`items[${itemIndex}].repairSpec`, undefined);
                    }}
                  >
                    Select existing spec
                  </Button>
                )}

                {/* Create repair spec from spec data */}
                {item.isRepairSpecChanged && canEditRepairSpec && (
                  <Tooltip title='Create new repair spec based on local evaluation data '>
                    <Button
                      size='small'
                      color='text'
                      variant='outlined'
                      disabled={!canEdit}
                      startIcon={<AppIcon of='add' />}
                      onClick={() => {
                        setCreateRepairSpecFromSpecData(item.repairSpec);
                        setIsCreateRepairSpecModalOpen(true);
                      }}
                    >
                      Save as new spec
                    </Button>
                  </Tooltip>
                )}
              </AuthorizedElement>
            </Stack>
          </Box>

          {/* Selected repair spec */}
          {item.repairSpec && (
            <Box>
              <FoldableBlock
                defaultIsFolded={false}
                trigger={{
                  label: <Typography variant='h4'>Repair spec</Typography>,
                }}
              >
                <Stack spacing={1}>
                  {/* Repair spec details with create/edit */}
                  <AuthorizedElement
                    permissions={[
                      AppPermission.FleetAppAccess,
                      AppPermission.RepairCatalogRead,
                      AppPermission.RepairCatalogManage,
                    ]}
                  >
                    <FoldableBlock
                      defaultIsFolded={true}
                      trigger={{
                        label: (
                          <Typography component='span' variant='h6'>
                            Details
                          </Typography>
                        ),
                      }}
                    >
                      <RepairSpecDataInputDtoFormInputs
                        disabled={!canEdit || !item.isRepairSpecChanged}
                        mode={isEdit ? "edit" : "create"}
                        values={item.repairSpec || undefined}
                        defaultValues={{
                          currency: currency,
                          vehicleType: vehicle?.spec?.type || VehicleType.Car,
                          partTypeId: item.damages?.at(0)?.damage?.partType?.id,
                          damageTypeId: item.damages?.at(0)?.damage?.damageType?.id,
                          detalization: {
                            type: RepairSpecDetalizationType.General,
                          },
                          items: [],
                        }}
                        // formikProps={{
                        //   errors: getIn(
                        //     errors,
                        //     `items[${itemIndex}].repairSpec`,
                        //   ),
                        //   touched: getIn(
                        //     touched,
                        //     `items[${itemIndex}].repairSpec`,
                        //   ),
                        // }}
                        formikProps={FormikHelper.getSubProps(
                          formikProps,
                          `items[${itemIndex}].repairSpec`,
                          (x) => x.items?.at(itemIndex)?.repairSpec,
                        )}
                        displayProps={{
                          mainInputs: false,
                          items: true,
                          itemsHeader: false,
                          itemInputs: true,
                          lineItemsSummary: false,
                        }}
                        withGeneralValidationError
                        onChange={(newValue) => {
                          setFieldValue(`items[${itemIndex}].repairSpec`, newValue || undefined);
                          setItemNameAuto(newValue?.name);
                        }}
                      />
                    </FoldableBlock>
                  </AuthorizedElement>

                  {/* Repair spec summary */}
                  {/* <FoldableBlock
                  defaultIsFolded={false}
                  trigger={{
                    label: <Typography variant='h6'>Summary</Typography>,
                  }}
                >
                  <Box>
                    <GeneralPriceSummaryDisplay
                      direction='row'
                      summary={{
                        currency: item.repairSpec?.currency,
                        subTotal: item.repairSpec?.subTotal,
                        discount: item?.repairSpec?.discount || undefined,
                        tax: item?.repairSpec?.tax || undefined,
                        total: item.repairSpec?.total || 0,
                      }}
                    />
                  </Box>
                </FoldableBlock> */}
                  <Box>
                    <GeneralPriceSummaryDisplay
                      direction='row'
                      summary={{
                        currency: item.repairSpec?.currency,
                        subTotal: item.repairSpec?.subTotal,
                        discount: item?.repairSpec?.discount || undefined,
                        tax: item?.repairSpec?.tax || undefined,
                        total: item.repairSpec?.total || 0,
                      }}
                    />
                  </Box>
                </Stack>
              </FoldableBlock>
            </Box>
          )}

          {/* Notes, attachments */}
          <Box>
            {!_.isNil(item.notes) && (
              <AppTextArea
                error={Boolean(getIn(errors, `items[${itemIndex}].notes`))}
                fullWidth
                helperText={getIn(errors, `items[${itemIndex}].notes`)}
                mode='notes'
                margin='dense'
                name={`items[${itemIndex}].notes`}
                onBlur={handleBlur}
                onChange={handleChange}
                value={item.notes}
                variant='outlined'
              />
            )}

            {!_.isNil(item.attachments) && (
              <FormControl fullWidth margin='dense'>
                <FileUploader
                  multiple
                  defaultFiles={
                    isCreate
                      ? item.attachments
                        ? FileItem.createManyFrom(item.attachments)
                        : undefined
                      : item.initialAttachments
                        ? FileItem.createManyFrom(item.initialAttachments)
                        : undefined
                  }
                  onChange={(newFiles) => {
                    setFieldValue(
                      `items[${itemIndex}].attachments`,
                      FileItem.toManyGeneralAttachmentInputDto(newFiles),
                    );
                  }}
                  onUploadStarted={() => {
                    setIsAttachmentFilesUploading(true);
                  }}
                  onUploadFinished={() => {
                    setIsAttachmentFilesUploading(false);
                  }}
                  onValidationStatusChange={(filesValidationStatus) => {
                    if (filesValidationStatus)
                      setFieldValue(
                        `items[${itemIndex}].isAttachmentFilesHaveErrors`,
                        Object.values(filesValidationStatus).some((x) => x === false),
                      );
                  }}
                />
              </FormControl>
            )}
          </Box>
        </Stack>

        {/* Create repair spec from local repair spec data */}
        {createRepairSpecFromSpecData && (
          <CreateUpdateRepairSpecModal
            open={isCreateRepairSpecModalOpen}
            onClose={() => {
              setIsCreateRepairSpecModalOpen(false);
              setCreateRepairSpecFromSpecData(undefined);
            }}
            createUpdateProps={{
              defaultValues: {
                data: createRepairSpecFromSpecData,
              },
              onSave: () => {
                setIsCreateRepairSpecModalOpen(false);
                setCreateRepairSpecFromSpecData(undefined);
              },
            }}
          />
        )}
      </Box>
    );
  },
);
