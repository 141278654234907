import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  LinearProgress,
  Stack,
  Switch,
} from "@mui/material";
import _ from "lodash";
import { useState } from "react";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import AppButton from "@/common/components/Button/AppButton";
import Datetime from "@/common/components/Datetime/Datetime";
import ProblemDetailsDisplay from "@/common/components/Error/ProblemDetailsDisplay";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import GeolocationMap from "@/common/components/Geolocation/GeolocationMap";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { TypeHelper } from "@/common/helpers/type";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { apiClient } from "@/core/api/ApiClient";
import { VehicleDto } from "@/core/api/generated";
import { TeslaFeatureInfoDto, TeslaVehicleStateCustom } from "@/core/api/generated/v0.1-demo";

interface Props {
  vehicle: VehicleDto;
  teslaFeatureInfo: TeslaFeatureInfoDto | undefined;
}

export default function TeslaVehicleDataTabContent({ vehicle, teslaFeatureInfo }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();

  const [isUseDemoData, setIsUseDemoData] = useState(false);

  const userAuthInfoRequest = useApiRequest(
    apiClient.teslaAuthApi.apiV01DemoProvidersTeslaAuthInfoGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: vehicle.id || "",
    },
    {
      deps: [],
    },
  );
  const userAuthInfo = userAuthInfoRequest?.data;

  // general vehicle data
  const teslaVehicleRequest = useApiRequest(
    apiClient.teslaVehiclesApi.apiV01DemoProvidersTeslaVehiclesVehicleIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: vehicle.id || "",
      isUseDemoData: isUseDemoData,
    },
    {
      deps: [vehicle.id, isUseDemoData],
      skip: !vehicle.id || (!userAuthInfo?.isAuthenticated && !isUseDemoData),
    },
  );
  const teslaVehicle = teslaVehicleRequest?.data;
  const teslaVehicleProblemDetails = apiClient.getProblemDetails(teslaVehicleRequest.error);

  // realtime vehicle data
  const teslaVehicleDataRequest = useApiRequest(
    apiClient.teslaVehiclesApi.apiV01DemoProvidersTeslaVehiclesVehicleIdVehicleDataGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: vehicle.id || "",
      isUseDemoData: isUseDemoData,
    },
    {
      deps: [vehicle.id, isUseDemoData],
      skip: !vehicle.id || (!userAuthInfo?.isAuthenticated && !isUseDemoData),
    },
  );
  const teslaVehicleData = teslaVehicleDataRequest?.data;
  const teslaVehicleDataProblemDetails = apiClient.getProblemDetails(teslaVehicleDataRequest.error);

  const isTeslaVehicleOnline =
    teslaVehicle?.stateCustom === TeslaVehicleStateCustom.Online ||
    teslaVehicleData?.stateCustom === TeslaVehicleStateCustom.Online;

  if (userAuthInfoRequest.isLoading) {
    return <LinearProgress />;
  }

  if (!teslaFeatureInfo) {
    return null;
  }
  if (!teslaFeatureInfo?.isEnabled) {
    return (
      <Alert severity='error'>
        Tesla demo is not available for current profile and/or vehicle.
      </Alert>
    );
  }

  return (
    <PageTabContent>
      <ViewContentBlock>
        <FormControlLabel
          control={
            <Switch checked={isUseDemoData} onChange={(e) => setIsUseDemoData(e.target.checked)} />
          }
          label={isUseDemoData ? "Using demo data" : "Using real data"}
        />

        {!userAuthInfo?.isAuthenticated && (
          <Stack spacing={2}>
            <Alert severity='warning' sx={{ "& .MuiAlert-message": { flex: 1 } }}>
              <Stack
                direction='row'
                spacing={2}
                sx={{ justifyContent: "space-between", alignItems: "center" }}
              >
                <Box>
                  {!userAuthInfo?.isAccountConnected && (
                    <Box>{`You haven't connected any Tesla account yet.`}</Box>
                  )}
                  {userAuthInfo?.isAccessTokenExpired && (
                    <Box>{`Tesla account connection is expired. Please reconnect.`}</Box>
                  )}
                </Box>

                <Box>
                  {/* <Button
                    variant='contained'
                    color='primary'
                    size='extraSmall'
                    onClick={() => {
                      teslaService.loginAsUserWithRedirect({
                        appState: {
                          redirectUrl: UrlHelper.getCurrentUrl(),
                        },
                      });
                    }}
                  >
                    Connect
                  </Button> */}
                  <AppLink
                    to={ROUTE_PATH.MANAGEMENT_INTEGRATION_TESLA_CONNECTIONS}
                    icon={<AppIcon of='edit' inText />}
                  >
                    Manage Tesla connections
                  </AppLink>
                </Box>
              </Stack>
            </Alert>
          </Stack>
        )}

        {userAuthInfo?.isAuthenticated && (
          <Alert severity='success' sx={{ "& .MuiAlert-message": { flex: 1 } }}>
            <Stack
              direction='row'
              spacing={2}
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Box>Tesla account connected.</Box>
              <Box>
                <AppLink
                  to={ROUTE_PATH.MANAGEMENT_INTEGRATION_TESLA_CONNECTIONS}
                  icon={<AppIcon of='edit' inText />}
                >
                  Manage Tesla connections
                </AppLink>
              </Box>
            </Stack>
          </Alert>
        )}

        {(userAuthInfo?.isAuthenticated || isUseDemoData) && (
          <Stack direction='column' spacing={2}>
            {(teslaVehicle || teslaVehicleData) && (
              <Stack direction='column' spacing={2}>
                <Box>
                  <AppTypography variant='h4'>Vehicle commands</AppTypography>
                </Box>

                <Alert severity='info'>
                  Vehicle commands are for demo purposes only and available only for business
                  vehicles and Pre-2021 S and X vehicles.
                </Alert>

                <Stack spacing={1}>
                  <Stack direction='row' spacing={1}>
                    <AppButton
                      variant='outlined'
                      color='text'
                      size='medium'
                      onClick={async () => {
                        try {
                          await apiClient.teslaVehiclesApi.apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsWakeUpPost(
                            {
                              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                              vehicleId: vehicle.id || "",
                              isUseDemoData: isUseDemoData,
                            },
                          );
                          enqueueSnackbar(
                            "The vehicle wake up requested. It could take several seconds to wake up.",
                            { variant: "success" },
                          );
                        } catch (err) {
                          const problemDetails = apiClient.getProblemDetails(err);
                          enqueueSnackbar(
                            `Vehicle wake up error: ${problemDetails?.title}, ${problemDetails?.detail}`,
                            { variant: "error" },
                          );
                        }
                      }}
                    >
                      Wake up
                    </AppButton>

                    <AppButton
                      variant='outlined'
                      color='text'
                      size='medium'
                      disabled={!isTeslaVehicleOnline}
                      onClick={async () => {
                        try {
                          await apiClient.teslaVehiclesApi.apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsFlashLightsPost(
                            {
                              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                              vehicleId: vehicle.id || "",
                              isUseDemoData: isUseDemoData,
                            },
                          );
                          enqueueSnackbar("Flash lights success.", { variant: "success" });
                        } catch (err) {
                          const problemDetails = apiClient.getProblemDetails(err);
                          enqueueSnackbar(
                            `Flash lights error: ${problemDetails?.title}, ${problemDetails?.detail}`,
                            { variant: "error" },
                          );
                        }
                      }}
                    >
                      Flash lights
                    </AppButton>

                    <AppButton
                      variant='outlined'
                      color='text'
                      size='medium'
                      disabled={!isTeslaVehicleOnline}
                      onClick={async () => {
                        try {
                          await apiClient.teslaVehiclesApi.apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsHonkHornPost(
                            {
                              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                              vehicleId: vehicle.id || "",
                              isUseDemoData: isUseDemoData,
                            },
                          );
                          enqueueSnackbar("Honk horn success.", { variant: "success" });
                        } catch (err) {
                          const problemDetails = apiClient.getProblemDetails(err);
                          enqueueSnackbar(
                            `Honk horn error: ${problemDetails?.title}, ${problemDetails?.detail}`,
                            { variant: "error" },
                          );
                        }
                      }}
                    >
                      Honk horn
                    </AppButton>

                    <AppButton
                      variant='outlined'
                      color='text'
                      size='medium'
                      disabled={!isTeslaVehicleOnline}
                      onClick={async () => {
                        try {
                          await apiClient.teslaVehiclesApi.apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorUnlockPost(
                            {
                              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                              vehicleId: vehicle.id || "",
                              isUseDemoData: isUseDemoData,
                            },
                          );
                          enqueueSnackbar("Unlock doors success.", { variant: "success" });
                        } catch (err) {
                          const problemDetails = apiClient.getProblemDetails(err);
                          enqueueSnackbar(
                            `Unlock doors error: ${problemDetails?.title}, ${problemDetails?.detail}`,
                            { variant: "error" },
                          );
                        }
                      }}
                    >
                      Unlock doors
                    </AppButton>

                    <AppButton
                      variant='outlined'
                      color='text'
                      size='medium'
                      disabled={!isTeslaVehicleOnline}
                      onClick={async () => {
                        try {
                          await apiClient.teslaVehiclesApi.apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorLockPost(
                            {
                              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                              vehicleId: vehicle.id || "",
                              isUseDemoData: isUseDemoData,
                            },
                          );
                          enqueueSnackbar("Lock doors success.", { variant: "success" });
                        } catch (err) {
                          const problemDetails = apiClient.getProblemDetails(err);
                          enqueueSnackbar(
                            `Lock doors error: ${problemDetails?.title}, ${problemDetails?.detail}`,
                            { variant: "error" },
                          );
                        }
                      }}
                    >
                      Lock doors
                    </AppButton>

                    <AppButton
                      variant='outlined'
                      color='text'
                      size='medium'
                      disabled={!isTeslaVehicleOnline}
                      onClick={async () => {
                        try {
                          await apiClient.teslaVehiclesApi.apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsRemoteStartDrivePost(
                            {
                              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                              vehicleId: vehicle.id || "",
                              isUseDemoData: isUseDemoData,
                            },
                          );
                          enqueueSnackbar("Remote start drive success.", { variant: "success" });
                        } catch (err) {
                          const problemDetails = apiClient.getProblemDetails(err);
                          enqueueSnackbar(
                            `Remote start drive error: ${problemDetails?.title}, ${problemDetails?.detail}`,
                            { variant: "error" },
                          );
                        }
                      }}
                    >
                      Remote start drive
                    </AppButton>
                  </Stack>
                </Stack>
              </Stack>
            )}

            <Stack direction='column' spacing={2}>
              <Stack
                direction='row'
                spacing={2}
                sx={{ justifyContent: "space-between", alignItems: "center" }}
              >
                <AppTypography variant='h4'>Vehicle info</AppTypography>

                {teslaVehicle && (
                  <Box>
                    <Stack
                      direction='row'
                      spacing={1}
                      sx={{ justifyContent: "space-between", alignItems: "center" }}
                    >
                      <AppTypography variant='caption'>
                        Last update at <Datetime datetime={teslaVehicle.fetchedAt} />
                      </AppTypography>

                      <Button
                        variant='outlined'
                        color='text'
                        size='extraSmall'
                        loading={teslaVehicleRequest.isLoading}
                        onClick={async () => {
                          teslaVehicleRequest.refetchWithNewParams({
                            isIgnoreCache: true,
                          });
                        }}
                      >
                        Refresh
                      </Button>
                    </Stack>
                  </Box>
                )}
              </Stack>

              {teslaVehicleRequest.isLoading && <LinearProgress />}

              {teslaVehicleProblemDetails && (
                <ProblemDetailsDisplay problemDetails={teslaVehicleProblemDetails} />
              )}

              {teslaVehicle && (
                <Stack spacing={1}>
                  <FieldValue label='VIN' isEmpty={!teslaVehicle?.vin}>
                    {teslaVehicle?.vin}
                  </FieldValue>

                  <FieldValue label='State' isEmpty={!teslaVehicle?.state}>
                    {teslaVehicle?.state}
                  </FieldValue>

                  <FieldValue label='Display name' isEmpty={!teslaVehicle?.display_name}>
                    {teslaVehicle?.display_name}
                  </FieldValue>

                  <FieldValue label='Access type' isEmpty={!teslaVehicle?.access_type}>
                    {teslaVehicle?.access_type}
                  </FieldValue>

                  <FieldValue label='API version' isEmpty={!teslaVehicle?.api_version}>
                    {teslaVehicle?.api_version}
                  </FieldValue>
                </Stack>
              )}
            </Stack>

            <Stack direction='column' spacing={2}>
              <Stack
                direction='row'
                spacing={2}
                sx={{ justifyContent: "space-between", alignItems: "center" }}
              >
                <AppTypography variant='h4'>Vehicle realtime info</AppTypography>

                {teslaVehicleData && (
                  <Box>
                    <Stack
                      direction='row'
                      spacing={1}
                      sx={{ justifyContent: "space-between", alignItems: "center" }}
                    >
                      <AppTypography variant='caption'>
                        Last update at <Datetime datetime={teslaVehicleData.fetchedAt} />
                      </AppTypography>

                      <Button
                        variant='outlined'
                        color='text'
                        size='extraSmall'
                        loading={teslaVehicleDataRequest.isLoading}
                        onClick={async () => {
                          teslaVehicleDataRequest.refetchWithNewParams({
                            isIgnoreCache: true,
                          });
                        }}
                      >
                        Refresh
                      </Button>
                    </Stack>
                  </Box>
                )}
              </Stack>

              {teslaVehicleDataRequest.isLoading && <LinearProgress />}

              {teslaVehicleDataProblemDetails && (
                <ProblemDetailsDisplay problemDetails={teslaVehicleDataProblemDetails} />
              )}

              {teslaVehicleData && (
                <Stack direction='column' spacing={2}>
                  <Stack direction='column' spacing={1}>
                    <AppTypography variant='h5'>Charge state</AppTypography>
                    <FieldValue
                      label='Battery level'
                      isEmpty={!teslaVehicleData?.charge_state?.battery_level}
                    >
                      {teslaVehicleData?.charge_state?.battery_level}
                    </FieldValue>

                    <FieldValue
                      label='Battery Range'
                      isEmpty={!teslaVehicleData?.charge_state?.batteryRangeKm}
                    >
                      {_.floor(teslaVehicleData?.charge_state?.batteryRangeKm ?? 0)} km
                    </FieldValue>
                  </Stack>

                  <Stack direction='column' spacing={1}>
                    <AppTypography variant='h5'>Vehicle state</AppTypography>

                    <FieldValue
                      label='Car version'
                      isEmpty={TypeHelper.isEmpty(teslaVehicleData?.vehicle_state?.car_version)}
                    >
                      {teslaVehicleData?.vehicle_state?.car_version}
                    </FieldValue>

                    <FieldValue
                      label='Mileage'
                      isEmpty={TypeHelper.isEmpty(teslaVehicleData?.vehicle_state?.mileageKm)}
                    >
                      {_.floor(teslaVehicleData?.vehicle_state?.mileageKm ?? 0)} km
                    </FieldValue>
                  </Stack>

                  <Stack direction='column' spacing={1}>
                    <AppTypography variant='h5'>Drive state</AppTypography>

                    <FieldValue
                      label='Shift state'
                      isEmpty={TypeHelper.isEmpty(teslaVehicleData?.drive_state?.shift_state)}
                    >
                      {teslaVehicleData?.drive_state?.shift_state}
                    </FieldValue>

                    <FieldValue
                      label='Power'
                      isEmpty={TypeHelper.isEmpty(teslaVehicleData?.drive_state?.power)}
                    >
                      {teslaVehicleData?.drive_state?.power}
                    </FieldValue>

                    <FieldValue
                      label='Speed'
                      isEmpty={TypeHelper.isEmpty(teslaVehicleData?.drive_state?.speed)}
                    >
                      {teslaVehicleData?.drive_state?.speed}
                    </FieldValue>

                    {teslaVehicleData?.drive_state && (
                      <Card sx={{ width: "fit-content", height: "fit-content" }}>
                        <CardContent>
                          <Stack spacing={2}>
                            {(!teslaVehicleData?.drive_state?.latitude ||
                              !teslaVehicleData?.drive_state?.longitude) && (
                              <Box>Location is unavailable for the vehicle.</Box>
                            )}

                            <GeolocationMap
                              latitude={teslaVehicleData?.drive_state?.latitude || 0}
                              longitude={teslaVehicleData?.drive_state?.longitude || 0}
                            />
                          </Stack>
                        </CardContent>
                      </Card>
                    )}
                    {/* <FieldValue label='Azimuth' isEmpty={!teslaVehicleData?.driveInfo?.heading}>
{teslaVehicleData?.driveInfo?.heading}
</FieldValue>
<FieldValue label='Latitude' isEmpty={!teslaVehicleData?.driveInfo?.latitude}>
{teslaVehicleData?.driveInfo?.latitude}
</FieldValue>
<FieldValue label='Longitude' isEmpty={!teslaVehicleData?.driveInfo?.longitude}>
{teslaVehicleData?.driveInfo?.longitude}
</FieldValue> */}
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Stack>
        )}
      </ViewContentBlock>
    </PageTabContent>
  );
}
