import {
  Chip,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  InputLabelProps,
  MenuItem,
  Select,
  SelectProps,
  Stack,
  TextFieldProps,
} from "@mui/material";
import _ from "lodash";
import { ReactNode, useEffect, useMemo, useState } from "react";

import { useEffectWithDeepCompare } from "@/common/hooks/effect/useEffectWithDeepCompare";
import {
  IBaseSelectItem,
  ItemToSelectOptionFunc,
  ItemsToSelectOptionsFunc,
  SelectOption,
} from "@/common/ts/select";

import { AppMenuItemProps } from "../../Menu/AppMenuItem";

export interface BaseMultiselectProps<TItem extends IBaseSelectItem> {
  /** Selected itemIds or items. */
  values: TItem[] | string[] | null | undefined;
  /** All items. */
  items: TItem[] | null | undefined;
  itemToOption: ItemToSelectOptionFunc<TItem>;
  /** Must render <li><li/> */
  renderOption: (props: AppMenuItemProps, option: SelectOption<TItem>) => ReactNode;
  renderValue?: (options: SelectOption<TItem>[] | null | undefined) => ReactNode;
  renderValueProps?: {
    direction?: "row" | "column";
  };
  label: SelectProps["label"];
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  size?: "small" | "medium";
  fullWidth?: boolean;
  margin?: FormControlProps["margin"];
  placeholder?: TextFieldProps["placeholder"];
  helperText?: TextFieldProps["helperText"];
  formControlProps?: Partial<FormControlProps>;
  inputLabelProps?: Partial<InputLabelProps>;
  selectProps?: Partial<SelectProps>;
  sx?: FormControlProps["sx"];
  onChange?: (newItems?: TItem[]) => void;
}

/** Interface to be used by wrapper components that utilize this component. */
export type BaseItemMultiselectInheritableProps<TItem extends IBaseSelectItem> = Pick<
  BaseMultiselectProps<TItem>,
  | "values"
  | "items"
  | "renderValueProps"
  | "label"
  | "disabled"
  | "required"
  | "error"
  | "size"
  | "fullWidth"
  | "margin"
  | "placeholder"
  | "helperText"
  | "formControlProps"
  | "inputLabelProps"
  | "selectProps"
  | "sx"
  | "onChange"
>;

export default function BaseMultiselect<TItem extends IBaseSelectItem>({
  values,
  items,
  itemToOption,
  renderOption,
  renderValue,
  renderValueProps,
  label,
  disabled,
  required,
  error,
  size,
  fullWidth = true,
  margin,
  placeholder,
  helperText,
  formControlProps,
  inputLabelProps,
  selectProps,
  sx,
  onChange,
}: BaseMultiselectProps<TItem>) {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<readonly SelectOption<TItem>[] | null>([]);
  const [selectedOptions, setSelectedOptions] = useState<SelectOption<TItem>[] | null>(null);

  const itemIdsComputed = useMemo(
    () =>
      values
        ?.map((x) => (_.isString(x) && !_.isEmpty(x) ? x : undefined)!)
        ?.filter((x) => !_.isNil(x)),
    [values],
  );
  const itemsComputed = useMemo(
    () =>
      values
        ?.map((x) => (_.isObject(x) && !_.isNil(x) ? (x as TItem) : undefined)!)
        ?.filter((x) => !_.isNil(x)),
    [values],
  );
  const selectedOptionIds = useMemo(() => selectedOptions?.map((x) => x.id), [selectedOptions]);
  const optionsMap = useMemo(
    () =>
      _.chain(options)
        .keyBy((x) => x.id)
        .mapValues((x) => x)
        .value(),
    [options],
  );
  const selectedOptionsMap = useMemo(
    () =>
      _.chain(selectedOptions)
        .keyBy((x) => x.id)
        .mapValues((x) => x)
        .value(),
    [selectedOptions],
  );

  const itemsToOptions = useMemo<ItemsToSelectOptionsFunc<TItem>>(
    () => (items2) => (items2 && items2.map((x) => itemToOption(x))) || [],
    [itemToOption],
  );

  // create options fom items
  useEffectWithDeepCompare(() => {
    setOptions(!_.isNil(items) ? itemsToOptions(items) : null);
  }, [items]);

  useEffect(() => {
    if (_.isNil(itemsComputed) && _.isNil(itemIdsComputed)) {
      setSelectedOptions(null);
    } else if (
      !_.isNil(itemsComputed) &&
      itemsComputed.some((x) => _.isNil(selectedOptionsMap[x.id || ""]))
    ) {
      setOptions(_.uniqBy([...itemsToOptions(itemsComputed), ...(options || [])], (x) => x.id));
      setSelectedOptions(itemsToOptions(itemsComputed));
    } else if (
      !_.isNil(itemIdsComputed) &&
      itemIdsComputed.some((id) => _.isNil(selectedOptionsMap[id || ""]))
    ) {
      setSelectedOptions(options?.filter((x) => itemIdsComputed.includes(x.id)) || null);
    }
  }, [itemIdsComputed, itemsComputed, selectedOptions, options]);

  return (
    <FormControl
      fullWidth={fullWidth}
      required={required}
      disabled={disabled}
      error={error}
      size={size}
      margin={margin}
      {...formControlProps}
      // sx={{ ...sx, ...formControlProps?.sx }}
    >
      <InputLabel required={required} disabled={disabled} error={error} {...inputLabelProps}>
        {label ?? selectProps?.label}
      </InputLabel>

      <Select
        multiple
        {...selectProps}
        label={label ?? selectProps?.label}
        required={required ?? selectProps?.required}
        disabled={disabled ?? selectProps?.disabled}
        error={error ?? selectProps?.error}
        fullWidth={fullWidth ?? selectProps?.fullWidth}
        size={size ?? selectProps?.size}
        // placeholder={placeholder ?? selectProps?.placeholder}
        native={false}
        open={isOpen}
        value={selectedOptionIds || []}
        onChange={(e) => {
          // On autofill we get a stringified value.
          const newOptionIds: string[] | undefined =
            typeof e.target.value === "string"
              ? e.target.value.split(",")
              : _.isArray(e.target.value)
                ? e.target.value
                : undefined;
          const newOptions = newOptionIds?.map((id) => optionsMap[id || ""]!).filter((x) => !!x);
          setSelectedOptions(newOptions || null);
          onChange && onChange(newOptions?.map((x) => x.data!)?.filter((x) => !!x) || undefined);
        }}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        renderValue={(selectedValue) => {
          const selectedOptionIds2: string[] | undefined =
            typeof selectedValue === "string"
              ? selectedValue.split(",")
              : _.isArray(selectedValue)
                ? selectedValue
                : undefined;
          const options2 = selectedOptionIds2
            ?.map((id) => optionsMap[id || ""]!)
            .filter((x) => !_.isNil(x));

          const direction = renderValueProps?.direction || "row";

          return !_.isNil(renderValue) ? (
            renderValue(options2)
          ) : (
            <Stack
              direction={direction}
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                ...(direction === "row" && {
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }),
                ...(direction === "column" && {
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }),
              }}
            >
              {options2?.map((option, i) => (
                <Chip
                  key={i}
                  size='small'
                  variant='filled'
                  color='default'
                  label={renderOption(
                    {
                      sx: { p: 0, m: 0 },
                      component: "div",
                      disableAllEffects: true,
                    },
                    option,
                  )}
                />
              ))}
            </Stack>
          );
        }}
      >
        {!required && !selectProps?.required && (
          <MenuItem value=''>
            <em>None</em>
          </MenuItem>
        )}

        {options?.map((option, i) => {
          return renderOption({ key: i, value: option.id, sx: {} }, option);
        })}
      </Select>

      {helperText && (
        <FormHelperText disabled={disabled} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
