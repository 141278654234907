import { ReactNode, useEffect, useState } from "react";

import { useTimeoutIf } from "../hooks/useTimeoutIf";

interface Props {
  /** Whether delayed render logic should be executed.
   *  Once execution is started, changing this value to false won't cancel the execution.
   */
  enabled?: boolean;
  /** Render after delay ms from mount moment.
   *  Set to -1 to never render and always show placeholder.
   */
  delayMs: number;
  /** On re-renders checks this value to know if delay should be cancelled and component rendered without further waiting. */
  interruptIf?: boolean;
  /** Whether to reset and wait from the beginning when delayMs changes.
   *  If delayMs changes after delay time passed, re-schedule won't happen.
   */
  isReScheduleOnDelayChange?: boolean;
  /** Element that is rendered while waiting. */
  placeholder?: ReactNode;
  onReSchedule?: (params: { oldMs?: number; newMs?: number }) => void;
  children: ReactNode;
}

/** Renders children after specified delay. */
export default function DelayedRender({
  enabled = true,
  delayMs,
  interruptIf,
  isReScheduleOnDelayChange = false,
  placeholder,
  onReSchedule,
  children,
}: Props) {
  const [shouldRender, setShouldRender] = useState(false);

  useTimeoutIf(
    enabled && delayMs >= 0,
    () => {
      setShouldRender(true);
    },
    delayMs,
    { isReScheduleOnDelayChange: isReScheduleOnDelayChange, onReSchedule: onReSchedule },
  );

  useEffect(() => {
    if (enabled && interruptIf === true) {
      setShouldRender(true);
    }
  }, [enabled, interruptIf]);

  if (!enabled) {
    return children;
  }

  if (!shouldRender) {
    return placeholder || null;
  }

  return children;
}
