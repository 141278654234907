import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  LinearProgress,
  Paper,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik, getIn } from "formik";
import { useHistory } from "react-router";
import * as Yup from "yup";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import EntityAffiliationInput from "@/common/components/Entity/EntityAffiliation/EntityAffiliationInput";
import TenantRolesSelector from "@/common/components/Entity/Role/TenantRolesSelector";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import FormActions from "@/common/components/Form/FormActions";
import DurationInput from "@/common/components/Form/Input/DurationInput";
import PhoneInput from "@/common/components/Form/Input/PhoneInput";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { useUserAffiliation } from "@/common/hooks/useUserAffiliation";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import { BaseFormikValues } from "@/common/ts/error";
import { TimeSpan } from "@/common/ts/timespan";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { InviteUserDto, UnitOfTime } from "@/core/api/generated";
import * as tenantInvitesSlice from "@/store/management/tenantInvites/slice";

export interface CreateNewPersonalInvitePageQueryParams extends GeneralQueryParams {
  sourceInviteId?: string | null;
}

function CreateNewPersonalInvite() {
  const queryParams = useQueryParams<CreateNewPersonalInvitePageQueryParams>();

  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const history = useHistory();
  const thunkDispatch = useAppThunkDispatch();

  const sourceInviteRequest = useApiRequest(
    apiClient.tenantInvitesApi.apiV1TenantInvitesInviteIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      inviteId: queryParams?.sourceInviteId || "",
    },
    {
      skip: !queryParams?.sourceInviteId,
    },
  );
  const sourceInvite = sourceInviteRequest?.data;

  const { departments, locations } = useUserAffiliation();

  if (sourceInviteRequest.isLoading && !sourceInviteRequest.isFirstEnded) {
    return <LinearProgress />;
  }

  return (
    <Formik<InviteUserDto & BaseFormikValues>
      enableReinitialize
      initialValues={{
        departmentId:
          sourceInvite?.departmentId ||
          (departments && departments[0] && departments[0].id) ||
          undefined,
        locationId:
          sourceInvite?.locationId || (locations && locations[0] && locations[0].id) || undefined,
        email: sourceInvite?.userInfo?.email || undefined,
        personName: {
          firstName: sourceInvite?.userInfo?.personName?.firstName || undefined,
          lastName: sourceInvite?.userInfo?.personName?.lastName || undefined,
        },
        phoneNumber: sourceInvite?.userInfo?.phoneNumber || undefined,
        lifetime: new TimeSpan({ day: 30 }).toString(),
        roleIds: sourceInvite?.roleIds || new Array<string>(),
        isInviteExistingUser: undefined,

        submit: "",
      }}
      validationSchema={Yup.object().shape({
        // email: Yup.string().required("Email is required"),
        // firstName: Yup.string().required("First name is required"),
        // lastName: Yup.string().required("Last name is required"),
        // phoneNumber: Yup.string().required("Phone number is required"),
      })}
      onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
        try {
          await thunkDispatch(
            tenantInvitesSlice.inviteUser({
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              inviteUserDto: {
                ...values,
              },
            }),
          );
          enqueueSnackbar("Invitation was sent.", { variant: "success" });

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
          history.goBack();
        } catch (err: any) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
        setFieldValue,
        setValues,
      }) => {
        return (
          <CreateUpdatePageLayout header={<SimpleViewPageHeader title={"New personal invite"} />}>
            <form noValidate onSubmit={handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  width: "100%",
                  minHeight: 0,
                  overflow: "auto",
                }}
              >
                <TextField
                  autoFocus
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  required
                  helperText={touched.email && errors.email}
                  label='Email'
                  margin='dense'
                  name='email'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='email'
                  value={values.email}
                  variant='outlined'
                />

                <TextField
                  error={Boolean(
                    getIn(touched, "personName.firstName") && getIn(errors, "personName.firstName"),
                  )}
                  fullWidth
                  required
                  helperText={
                    getIn(touched, "personName.firstName") && getIn(errors, "personName.firstName")
                  }
                  label='First name'
                  margin='dense'
                  name='personName.firstName'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.personName?.firstName}
                  variant='outlined'
                />

                <TextField
                  error={Boolean(
                    getIn(touched, "personName.lastName") && getIn(errors, "personName.lastName"),
                  )}
                  fullWidth
                  required
                  helperText={
                    getIn(touched, "personName.lastName") && getIn(errors, "personName.lastName")
                  }
                  label='Last name'
                  margin='dense'
                  name='personName.lastName'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.personName?.lastName}
                  variant='outlined'
                />

                <PhoneInput
                  error={Boolean(errors.phoneNumber)}
                  helperText={
                    errors.phoneNumber &&
                    ValidationHelper.getFormikErrorsAsString(errors.phoneNumber)
                  }
                  fullWidth
                  required
                  label='Phone number'
                  margin='dense'
                  name='phoneNumber'
                  value={values.phoneNumber || ""}
                  variant='outlined'
                  color='primary'
                  placeholder='Phone number'
                  onBlur={handleBlur}
                  onChange={(e, ph, iso) => {
                    setFieldValue(`phoneNumber`, ph);
                  }}
                />
                {/* Entity affiliation */}
                <EntityAffiliationInput
                  department={{
                    label: "Department (optional)",
                    fullWidth: true,
                    departmentId: values.departmentId,
                    onChange: (d) => {
                      setFieldValue("departmentId", d?.id);
                      setFieldValue("locationId", undefined);
                    },
                    error: errors.departmentId,
                  }}
                  location={{
                    label: "Location (optional)",
                    fullWidth: true,
                    locationId: values.locationId,
                    onChange: (l) => {
                      setFieldValue("locationId", l?.id);
                    },
                    searchFilters: { departmentId: values.departmentId },
                    createUpdateProps: { defaultValues: { departmentId: values.departmentId } },
                    error: errors.locationId,
                    disabled: !values.departmentId,
                  }}
                />

                <DurationInput
                  error={Boolean(errors.lifetime)}
                  helperText={errors.lifetime}
                  fullWidth
                  margin='dense'
                  label='Invite lifetime'
                  variant='outlined'
                  defaultUnitOfTime={UnitOfTime.Day}
                  value={values.lifetime}
                  onChange={(newValue) => {
                    setFieldValue("lifetime", newValue);
                  }}
                  onBlur={handleBlur}
                />

                <Box>
                  <TenantRolesSelector
                    roleIds={values.roleIds}
                    onChange={(newValue) =>
                      setFieldValue("roleIds", newValue?.map((x) => x.id) || [])
                    }
                    textFieldProps={{
                      error: Boolean(touched.roleIds && errors.roleIds),
                      helperText: touched.roleIds && errors.roleIds,
                    }}
                  />
                </Box>

                <FormControl margin='dense'>
                  <Paper sx={{ px: 1, pb: 1 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='isInviteExistingUser'
                          checked={values.isInviteExistingUser}
                          onChange={(e) => setFieldValue("isInviteExistingUser", e.target.checked)}
                        />
                      }
                      label='Invite existing company user'
                    />
                    <FormHelperText>
                      If checked, invite will be sent even if user already exists in your company.
                    </FormHelperText>
                  </Paper>
                </FormControl>
              </Box>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <FormActions>
                <Button
                  sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
                  color='primary'
                  loading={isSubmitting}
                  disabled={
                    isSubmitting ||
                    !values.email ||
                    !values.personName?.firstName ||
                    !values.personName.lastName ||
                    !values.phoneNumber
                  }
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Create
                </Button>
              </FormActions>
            </form>
          </CreateUpdatePageLayout>
        );
      }}
    </Formik>
  );
}

export default CreateNewPersonalInvite;
