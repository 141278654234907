import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { apiClient } from "@/core/api/ApiClient";
import { TenantDto, TenantReferenceDto } from "@/core/api/generated";

import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getTenantOptionTitle } from "./TenantAutocomplete";

export interface TenantInlineProps extends BaseEntityInlineInheritableProps {
  entity: TenantDto | TenantReferenceDto | null | undefined;
  entityId?: string | null;
}

/** Inline brief information about the entity. */
export default function TenantInline({ entity, entityId, ...otherProps }: TenantInlineProps) {
  const currentTenant = useCurrentTenant();

  const request = useApiRequest(
    apiClient.tenantsApi.apiV1TenantsTenantIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      tenantId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as TenantDto;
  // const ref = entity as TenantReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='company' inText />}
      content={<>{full && getTenantOptionTitle(full, currentTenant)}</>}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Company"}
    />
  );
}
