import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  FormControl,
  Stack,
  TextField,
} from "@mui/material";
import { ReactNode, useCallback, useEffect, useState } from "react";

import AppModalActions from "./AppModalActions";
import AppModalTitle from "./AppModalTitle";

interface OnCancelOrConfirmParams {
  reasonText?: string;
  closeModal: () => void;
}

interface OwnProps {
  title?: DialogProps["title"] | ReactNode;
  body?: ReactNode;
  withReasonText?: boolean;
  defaultReasonText?: string | null;
  isCancelLoading?: boolean;
  isConfirmLoading?: boolean;
  isCancelEnabled?: boolean;
  isConfirmEnabled?: boolean;
  isAutoCloseOnCancel?: boolean;
  isAutoCloseOnConfirm?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmButtonProps?: Omit<ButtonProps, "onClick">;
  cancelButtonProps?: Omit<ButtonProps, "onClick">;
  // children?: ReactNode;
  onReasonTextChange?: (newValue?: string) => void | Promise<void>;
  onCancel?: (params: OnCancelOrConfirmParams) => void | Promise<void>;
  onConfirm?: (params: OnCancelOrConfirmParams) => void | Promise<void>;
}

export type ConfirmationModalProps = OwnProps & Omit<DialogProps, "title">;

/** Modal with cancel/confirm buttons. */
export default function ConfirmationModal({
  title,
  body,
  withReasonText,
  defaultReasonText,
  isCancelLoading,
  isConfirmLoading,
  isCancelEnabled = true,
  isConfirmEnabled = true,
  isAutoCloseOnCancel = true,
  isAutoCloseOnConfirm = false,
  confirmButtonText,
  cancelButtonText,
  confirmButtonProps,
  cancelButtonProps,
  onReasonTextChange,
  onCancel,
  onConfirm,
  ...dialogProps
}: ConfirmationModalProps) {
  const [_isCancelLoading, _setIsCancelLoading] = useState(isCancelLoading);
  const [_isConfirmLoading, _setIsConfirmLoading] = useState(isConfirmLoading);
  const [_reasonText, _setReasonText] = useState(defaultReasonText);

  useEffect(() => {
    if (isCancelLoading !== _isCancelLoading) {
      _setIsCancelLoading(isCancelLoading);
    }
    if (isConfirmLoading !== _isConfirmLoading) {
      _setIsConfirmLoading(isCancelLoading);
    }
  }, [isCancelLoading, isConfirmLoading]);

  const closeModal = useCallback(() => {
    dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown");
  }, [dialogProps?.onClose]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      _setIsCancelLoading(true);
      try {
        await onCancel({ reasonText: _reasonText || undefined, closeModal });
      } finally {
        _setIsCancelLoading(false);
        if (isAutoCloseOnCancel) {
          dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown");
        }
      }
    } else {
      dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown");
    }
  }, [onCancel]);

  const handleConfirm = useCallback(async () => {
    if (onConfirm) {
      _setIsConfirmLoading(true);
      try {
        await onConfirm({ reasonText: _reasonText || undefined, closeModal });
      } finally {
        _setIsConfirmLoading(false);
        if (isAutoCloseOnConfirm) {
          dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown");
        }
      }
    }
  }, [onConfirm, _reasonText]);

  return (
    <Dialog fullWidth maxWidth='sm' {...dialogProps}>
      <AppModalTitle
        withCloseIcon
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        <Box component='div'>{title || "Do you confirm the action?"}</Box>
      </AppModalTitle>

      {(body || withReasonText) && (
        <DialogContent>
          <Stack spacing={2}>
            {body && <Box>{body}</Box>}{" "}
            {withReasonText && (
              <Box>
                <FormControl fullWidth margin='none'>
                  <TextField
                    type='text'
                    variant='outlined'
                    fullWidth
                    multiline
                    maxRows={2}
                    label='Reason'
                    value={_reasonText || ""}
                    onChange={(e) => {
                      _setReasonText(e.target.value);
                      onReasonTextChange && onReasonTextChange(e.target.value);
                    }}
                  />
                </FormControl>
              </Box>
            )}
          </Stack>
        </DialogContent>
      )}

      {/* <DialogActions>
        <Button
          {...cancelButtonProps}
          className='ConfirmModalCancelButton'
          variant='text'
          color='text'
          loading={_isCancelLoading}
          onClick={handleCancel}
        >
          {cancelButtonText}
        </Button>
        <Button
          {...confirmButtonProps}
          className='ConfirmModalConfirmButton'
          variant='contained'
          color='primary'
          disabled={!isConfirmEnabled}
          loading={_isConfirmLoading}
          onClick={handleConfirm}
        >
          {confirmButtonText}
        </Button>
      </DialogActions> */}

      <DialogActions>
        <AppModalActions
          modalProps={{
            onClose: handleCancel,
          }}
          isAutoCloseOnCancel
          cancelButton={
            <Button
              {...cancelButtonProps}
              className='ConfirmModalCancelButton'
              variant='text'
              color='text'
              disabled={!isCancelEnabled || cancelButtonProps?.disabled}
              loading={_isCancelLoading || cancelButtonProps?.loading}
              onClick={handleCancel}
            >
              {cancelButtonText || cancelButtonProps?.children || "Cancel"}
            </Button>
          }
          submitButton={
            <Button
              {...confirmButtonProps}
              className='ConfirmModalConfirmButton'
              variant='contained'
              color='primary'
              disabled={!isConfirmEnabled || confirmButtonProps?.disabled}
              loading={_isConfirmLoading || confirmButtonProps?.loading}
              onClick={handleConfirm}
            >
              {confirmButtonText || confirmButtonProps?.children || "Confirm"}
            </Button>
          }
        ></AppModalActions>
      </DialogActions>
    </Dialog>
  );
}
