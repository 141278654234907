import { Card, CardContent, Stack, SxProps, Theme, Typography } from "@mui/material";

import { ApiEnumName, ApiEnumValue, enumService } from "@/common/services/enum";

import InlineCode from "../Code/InlineCode";

interface Props<TEnumName extends ApiEnumName> {
  sx?: SxProps<Theme>;
  type: TEnumName;
  value: ApiEnumValue<TEnumName> | null | undefined;
  direction?: "row" | "column";
  withValue?: boolean;
  withName?: boolean;
  withDescription?: boolean;
}

/** Block element for displaying enum value.
 *  Checks for user-friendly enum name in enum catalog.
 */
export default function BlockApiEnumValue<TEnumName extends ApiEnumName>({
  sx,
  type,
  value,
  direction = "row",
  withValue = false,
  withName = true,
  withDescription = false,
}: Props<TEnumName>): React.ReactNode {
  const enumDto = (value && enumService.getEnumDto(type, value)) || null;

  return (
    <Card
      variant='outlined'
      sx={{
        ...sx,
      }}
    >
      <CardContent>
        <Stack direction='row' spacing={1} flexWrap='wrap' justifyContent='space-between'>
          <Stack
            direction={direction}
            spacing={direction === "row" ? 1 : 0.5}
            flexWrap='wrap'
            alignItems={direction === "row" ? "center" : "flex-start"}
          >
            {(withName || withValue) && (
              <Stack direction='row' spacing={1} flexWrap='wrap' justifyContent='space-between'>
                {withName && <Typography component='span'>{enumDto?.name || value}</Typography>}
              </Stack>
            )}
            {withDescription && enumDto?.description && (
              <Typography component='span' variant='body2' color='text.secondary'>
                {enumDto?.description}
              </Typography>
            )}
          </Stack>

          {withValue && (
            <Typography component='span' fontSize='small'>
              <InlineCode>{value}</InlineCode>
            </Typography>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
