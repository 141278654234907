import { ListItemIcon, ListItemText, MenuItem, Stack } from "@mui/material";
import { ReactNode } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import { ROUTE_PATH } from "@/common/constants/routing";
import {
  AppPermission,
  AssetEntityType,
  PoolItemEntityType,
  VehicleDto,
} from "@/core/api/generated";

import AuthorizedElement from "../../Auth/AuthorizedElement";
import AuthorizedMenuItem from "../../Auth/AuthorizedMenuItem";
import EntityMenu from "../../EntityMenu/EntityMenu";
import AppIcon from "../../Icons/AppIcon";
import AppLink from "../../Link/AppLink";
import HeaderMenuItem from "../../Menu/HeaderMenuItem";
import AssetCreateUpdateFromEntityModal from "../Asset/AssetCreateUpdateFromEntityModal";
import { entitySpecToAssetSpecDto } from "../Asset/AssetSpecInput";
import PoolItemCreateFromEntityModal from "../PoolItem/PoolItemCreateFromEntityModal";

enum ActionFlags {
  Edit = "Edit",
  CreateContract = "CreateContract",
  CreateVisualInspection = "CreateVisualInspection",
  CreateDamageDetection = "CreateDamageDetection",
  CreateDamageCostEvaluation = "CreateDamageCostEvaluation",
  CreateRepairOperation = "CreateRepairOperation",
  CreateAccessoryCheck = "CreateAccessoryCheck",
  CreateWheelOperation = "CreateWheelOperation",
  CreateWashOperation = "CreateWashOperation",
  CreateMaintenanceOperation = "CreateMaintenanceOperation",
  CreateAsset = "CreateAsset",
  AddToPool = "AddToPool",
}

const defaultDisplayProps = {
  actions: {
    edit: true,
    createContract: true,
    createVisualInspection: true,
    createDamageDetection: true,
    createDamageCostEvaluation: true,
    createRepairOperation: true,
    createAccessoryCheck: true,
    createWheelOperation: true,
    createWashOperation: true,
    createMaintenanceOperation: true,
    createAsset: true,
    addToPool: true,
  },
};

interface TriggersProps {
  entity?: VehicleDto | null;
  definition: typeof ActionFlags;
  handleClose: () => void;
  startAction: (props: ActionFlags) => void;
}

interface Props {
  entity?: VehicleDto | null;
  disabled?: boolean;
  onUpdate: ((newValue: VehicleDto | null | undefined) => void) | undefined;
  onDelete?: (() => void) | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  customActions?: (props: TriggersProps) => ReactNode;
}

export default function VehicleMenu({
  entity,
  disabled,
  customActions,
  onUpdate,
  onDelete,
  displayProps = defaultDisplayProps,
}: Props) {
  const history = useHistory();
  displayProps = { ...defaultDisplayProps, ...displayProps };

  return (
    <EntityMenu
      definition={ActionFlags}
      entity={entity}
      disabled={disabled}
      actionTriggers={({ handleClose, startAction }) => (
        <>
          {customActions &&
            customActions({
              entity,
              definition: ActionFlags,
              handleClose,
              startAction,
            })}
          {displayProps?.actions?.edit && (
            <AuthorizedMenuItem
              permissions={[AppPermission.VehicleManage]}
              component={RouterLink}
              to={ROUTE_PATH.VEHICLE_EDIT(entity?.id)}
            >
              <ListItemIcon>
                <AppIcon of='edit' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </AuthorizedMenuItem>
          )}
          <HeaderMenuItem primaryTitle='Quick actions' />
          {displayProps?.actions?.createContract && (
            <AuthorizedElement permissions={[AppPermission.ContractManage]}>
              <MenuItem
                component={AppLink}
                to={ROUTE_PATH.CONTRACT_CREATE({
                  vehicleId: entity?.id,
                })}
              >
                <ListItemIcon>
                  <AppIcon of='contract' fontSize='small' />
                </ListItemIcon>
                <ListItemText>New contract</ListItemText>
              </MenuItem>
            </AuthorizedElement>
          )}

          {displayProps?.actions?.createVisualInspection && (
            <AuthorizedElement
              permissionsAny={[
                AppPermission.VisualInspectionPerform,
                AppPermission.VisualInspectionManage,
              ]}
            >
              <MenuItem
                component={AppLink}
                to={ROUTE_PATH.VISUAL_INSPECTION_CREATE({
                  vehicleId: entity?.id,
                })}
              >
                <ListItemIcon>
                  <AppIcon of='visualInspection' fontSize='small' />
                </ListItemIcon>
                <ListItemText>New visual inspection</ListItemText>
              </MenuItem>
            </AuthorizedElement>
          )}

          {displayProps?.actions?.createDamageDetection && (
            <AuthorizedElement
              permissionsAny={[
                AppPermission.DamageDetectionPerform,
                AppPermission.DamageDetectionManage,
              ]}
            >
              <MenuItem
                component={AppLink}
                to={ROUTE_PATH.DAMAGE_DETECTION_CREATE({
                  vehicleId: entity?.id,
                })}
              >
                <ListItemIcon>
                  <AppIcon of='damageDetection' fontSize='small' />
                </ListItemIcon>
                <ListItemText>New damage detection</ListItemText>
              </MenuItem>
            </AuthorizedElement>
          )}

          {displayProps?.actions?.createDamageCostEvaluation && (
            <AuthorizedElement
              permissionsAny={[
                AppPermission.DamageCostEvaluationPerform,
                AppPermission.DamageCostEvaluationManage,
              ]}
            >
              <MenuItem
                component={AppLink}
                to={ROUTE_PATH.DAMAGE_COST_EVALUATION_CREATE({
                  vehicleId: entity?.id,
                })}
              >
                <ListItemIcon>
                  <AppIcon of='damageCostEvaluation' fontSize='small' />
                </ListItemIcon>
                <ListItemText>New damage cost evaluation</ListItemText>
              </MenuItem>
            </AuthorizedElement>
          )}

          {displayProps?.actions?.createRepairOperation && (
            <AuthorizedElement
              permissionsAny={[
                AppPermission.RepairOperationPerform,
                AppPermission.RepairOperationManage,
              ]}
            >
              <MenuItem
                component={AppLink}
                to={ROUTE_PATH.REPAIR_OPERATION_CREATE({
                  vehicleId: entity?.id,
                })}
              >
                <ListItemIcon>
                  <AppIcon of='repairOperation' fontSize='small' />
                </ListItemIcon>
                <ListItemText>New repair operation</ListItemText>
              </MenuItem>
            </AuthorizedElement>
          )}

          {displayProps?.actions?.createAccessoryCheck && (
            <AuthorizedElement permissions={[AppPermission.AssetManage]}>
              <MenuItem
                component={AppLink}
                to={ROUTE_PATH.ACCESSORY_CHECK_CREATE({
                  vehicleId: entity?.id,
                })}
              >
                <ListItemIcon>
                  <AppIcon of='accessoryCheck' fontSize='small' />
                </ListItemIcon>
                <ListItemText>New accessory check</ListItemText>
              </MenuItem>
            </AuthorizedElement>
          )}

          {displayProps?.actions?.createWheelOperation && (
            <AuthorizedElement
              permissionsAny={[
                AppPermission.WheelOperationPerform,
                AppPermission.WheelOperationManage,
              ]}
            >
              <MenuItem
                component={AppLink}
                to={ROUTE_PATH.WHEEL_OPERATION_CREATE({
                  vehicleId: entity?.id,
                })}
              >
                <ListItemIcon>
                  <AppIcon of='wheelOperation' fontSize='small' />
                </ListItemIcon>
                <ListItemText>New wheel operation</ListItemText>
              </MenuItem>
            </AuthorizedElement>
          )}

          {displayProps?.actions?.createWashOperation && (
            <AuthorizedElement
              permissionsAny={[AppPermission.WashPerform, AppPermission.WashManage]}
            >
              <MenuItem
                component={AppLink}
                to={ROUTE_PATH.WASH_CREATE({
                  vehicleId: entity?.id,
                })}
              >
                <ListItemIcon>
                  <AppIcon of='wash' fontSize='small' />
                </ListItemIcon>
                <ListItemText>New wash</ListItemText>
              </MenuItem>
            </AuthorizedElement>
          )}

          {displayProps?.actions?.createWashOperation && (
            <AuthorizedElement
              permissionsAny={[AppPermission.MaintenancePerform, AppPermission.MaintenanceRead]}
            >
              <MenuItem
                component={AppLink}
                to={ROUTE_PATH.MAINTENANCE_CREATE({
                  vehicleId: entity?.id,
                })}
              >
                <ListItemIcon>
                  <AppIcon of='maintenance' fontSize='small' />
                </ListItemIcon>
                <ListItemText>New maintenance</ListItemText>
              </MenuItem>
            </AuthorizedElement>
          )}

          {displayProps?.actions?.createAsset && (
            <AuthorizedElement permissions={[AppPermission.AssetManage]}>
              <MenuItem onClick={() => startAction(ActionFlags.CreateAsset)}>
                <ListItemIcon>
                  <AppIcon of='asset' fontSize='small' />
                </ListItemIcon>
                <ListItemText>New asset</ListItemText>
              </MenuItem>
            </AuthorizedElement>
          )}

          {displayProps?.actions?.addToPool && (
            <AuthorizedElement permissions={[AppPermission.PoolManage]}>
              <MenuItem onClick={() => startAction(ActionFlags.AddToPool)}>
                <ListItemIcon>
                  <AppIcon of='pool' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Add to pool</ListItemText>
              </MenuItem>
            </AuthorizedElement>
          )}
        </>
      )}
      actionHandlers={({ currentAction, cancelAction, completeAction }) => (
        <Stack>
          {/* Create asset from entity */}
          {entity && (
            <AssetCreateUpdateFromEntityModal
              open={currentAction === ActionFlags.CreateAsset}
              onClose={() => cancelAction()}
              entity={{
                entityType: AssetEntityType.Vehicle,
                entityId: entity.id!,
              }}
              spec={entitySpecToAssetSpecDto(AssetEntityType.Vehicle, entity.spec)}
              createUpdateProps={{
                onSave: (asset) => {
                  completeAction();
                  history.push(ROUTE_PATH.ASSET_VIEW(asset.id));
                },
              }}
            />
          )}

          {/* Add to pool */}
          {entity && (
            <PoolItemCreateFromEntityModal
              open={currentAction === ActionFlags.AddToPool}
              onClose={() => cancelAction()}
              createProps={{
                entityType: PoolItemEntityType.Vehicle,
                entity: entity,
                onSave: (newValue) => {
                  completeAction();
                  onUpdate && onUpdate(null);
                },
              }}
            />
          )}
        </Stack>
      )}
    />
  );
}
