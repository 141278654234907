import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission, PermissionDto } from "@/core/api/generated";

import AppTooltip from "../AppTooltip";
import PermissionDisplay from "./PermissionDisplay";

interface Props {
  getPermissions: () => Promise<PermissionDto[]>;

  permissions?: AppPermission[] | null;
  disabled?: boolean;
  onChange?: (newValue1?: AppPermission[] | null, newValue2?: PermissionDto[] | null) => void;
}

export default function PermissionsSelector({
  getPermissions,
  permissions,
  disabled,
  onChange,
}: Props) {
  const { userPermissionsMap } = useAuthorizationInfo();

  const [_permissionList, setPermissionList] = useState<PermissionDto[] | undefined>(undefined);

  const apiParams: Parameters<typeof getPermissions> = [];

  const selectedPermissions = permissions;
  const isAnyPermissionDisabled = useMemo(
    () => _permissionList?.some((p) => !userPermissionsMap[p.permission!]) ?? false,
    [_permissionList, userPermissionsMap],
  );
  const selectedPermissionDtos = useMemo(
    () => _permissionList?.filter((x) => selectedPermissions?.includes(x.permission!)),
    [selectedPermissions, _permissionList],
  );

  const selectedPermissionsMap = useMemo(
    () =>
      _.chain(selectedPermissions || [])
        .keyBy((p) => p)
        .mapValues((x) => true)
        .value() as Record<AppPermission, boolean>,
    [selectedPermissions],
  );

  const groupedPermissions = useMemo(
    () => _.groupBy(_permissionList || [], (x) => x.groupName),
    [_permissionList],
  );

  const getPermissionsThrottle = useCallback(
    _.throttle(
      async () => {
        const result = await getPermissions();
        setPermissionList(result);
      },
      500,
      { leading: true, trailing: false },
    ),
    [getPermissions],
  );

  // initial load
  useEffect(() => {
    getPermissionsThrottle(...apiParams);
  }, []);

  return (
    <Stack spacing={1}>
      {isAnyPermissionDisabled && (
        <Alert severity='info'>{`Permissions that you can't assign are disabled.`}</Alert>
      )}

      <Stack spacing={1}>
        {Object.keys(groupedPermissions).map((groupName) => (
          <FormGroup key={groupName}>
            <Stack spacing={1} sx={{ justifyContent: "flex-start", alignItems: "flex-start" }}>
              <Typography component='div' variant='subtitle1'>
                <AppTooltip variant='helpText' title='Permission group name'>
                  <span>{groupName}:</span>
                </AppTooltip>
              </Typography>

              <Box>
                <Stack spacing={1} sx={{ ml: 2 }}>
                  {groupedPermissions[groupName].map((p, index) => {
                    const canReAssign = !!userPermissionsMap[p.permission!];
                    const disabled2 = disabled || !canReAssign;

                    return (
                      <Box key={index}>
                        <FormControlLabel
                          disabled={disabled2}
                          control={
                            <Checkbox
                              disabled={disabled2}
                              checked={selectedPermissionsMap[p.permission!] || false}
                              onChange={(e) => {
                                const newValue1 = e.target.checked
                                  ? [...(selectedPermissions || []), p.permission!]
                                  : selectedPermissions?.filter((p2) => p2 !== p.permission!);
                                const newValue2 = e.target.checked
                                  ? [...(selectedPermissionDtos || []), p]
                                  : selectedPermissionDtos?.filter(
                                      (p2) => p2.permission! !== p.permission!,
                                    );
                                onChange && onChange(newValue1, newValue2);
                              }}
                              name={p.permission!.toString()}
                            />
                          }
                          label={
                            <PermissionDisplay variant='block' permission={p} withIcon={false} />
                          }
                        />
                      </Box>
                    );
                  })}
                </Stack>
              </Box>
            </Stack>
          </FormGroup>
        ))}
      </Stack>
    </Stack>
  );
}
