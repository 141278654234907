import { Button, IconButton, ListItemIcon, ListItemText, MenuItem, Stack } from "@mui/material";
import { useCallback, useState } from "react";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AssetSubscriptionPlanAddProductLocationsModal from "@/App/MainAppView/Products/AssetSubscriptionPlans/components/AssetSubscriptionPlanAddProductLocationsModal";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import GeneralAddressDisplay from "@/common/components/Entity/General/Display/GeneralAddressDisplay";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AssetSubscriptionPlanDto,
  DataUpdatesHubClientMethodName,
  EntityType,
  ProductLocationDto,
  ProductLocationGetPaginatedDto,
} from "@/core/api/generated";

interface Props {
  assetSubscriptionPlan: AssetSubscriptionPlanDto;
}

export default function ProductLocationsTabContent({ assetSubscriptionPlan }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<ProductLocationGetPaginatedDto>({});

  const [isAddProductLocationsModalOpen, setIsAddProductLocationsModelOpen] = useState(false);
  const [isRemoveProductLocationModalOpen, setIsRemoveProductLocationModalOpen] = useState(false);
  const [productLocationToRemove, setProductLocationToRemove] = useState<
    ProductLocationDto | undefined
  >(undefined);

  const paginatedProductLocationsRequest = useApiRequest(
    apiClient.productLocationsApi.apiV1ProductLocationsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      productLocationGetPaginatedDto: {
        assetSubscriptionPlanId: assetSubscriptionPlan.id!,
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [assetSubscriptionPlan.id, ...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedProductLocations = paginatedProductLocationsRequest?.data;

  useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.ProductLocation)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedProductLocationsRequest.handleEntityChanged(data);
    },
  });

  const handleProductLocationRemovalConfirmed = useCallback(
    async (productLocation: ProductLocationDto) => {
      try {
        await apiClient.assetSubscriptionPlansApi.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsProductLocationIdDelete(
          {
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            assetSubscriptionPlanId: assetSubscriptionPlan!.id!,
            productLocationId: productLocation.id!,
          },
        );
        enqueueSnackbar("Sales location removed from the asset subscription plan.", {
          variant: "success",
        });
        paginatedProductLocationsRequest.refetch();
        setIsRemoveProductLocationModalOpen(false);
      } catch (err) {
        const validation2 = ValidationHelper.handleApiErrorResponse(err);
        validation2.hasErrors &&
          enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
      }
    },
    [assetSubscriptionPlan],
  );

  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Sales locations"}
            primaryAction={
              <Button
                variant='contained'
                color='primary'
                onClick={() => setIsAddProductLocationsModelOpen(true)}
              >
                Add sales locations
              </Button>
            }
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <DataTabular
            columns={[
              {
                field: "localNumber",
                title: "Number",
                flex: 1,
                renderCell: (item) => item.localNumber,
              },
              {
                field: "name",
                title: "Name",
                flex: 1,
                renderCell: (item) => item.name,
              },
              {
                field: "description",
                title: "Description",
                flex: 1,
                renderCell: (item) => item.description,
              },
              {
                field: "address",
                title: "Address",
                flex: 1,
                renderCell: (item) => (
                  <GeneralAddressDisplay address={item.address} direction='row' />
                ),
              },
            ]}
            isLoading={paginatedProductLocationsRequest.isLoading}
            rows={paginatedProductLocations?.items}
            getRowId={(item) => item.id!}
            rowTo={(item) => ROUTE_PATH.PRODUCT_SALES_LOCATION_VIEW(item.id)}
            statePersistence={commonRequestParams.dataTabularProps.statePersistence}
            pagination={commonRequestParams.dataTabularProps.pagination}
            sort={commonRequestParams.dataTabularProps.sort}
            quickFilter={commonRequestParams.dataTabularProps.quickFilter}
            filters={commonRequestParams.dataTabularProps.filters}
            refetch={commonRequestParams.dataTabularProps.refetch}
            renderRowAction={(actionParams) => (
              <MenuWithTrigger
                withAuthCloseOnClick
                trigger={
                  <IconButton>
                    <AppIcon of='moreVert' />
                  </IconButton>
                }
              >
                <MenuItem
                  onClick={() => {
                    setProductLocationToRemove(actionParams.item);
                    setIsRemoveProductLocationModalOpen(true);
                  }}
                >
                  <ListItemIcon>
                    <AppIcon of='remove' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Remove from asset subscription plan</ListItemText>
                </MenuItem>
              </MenuWithTrigger>
            )}
            // TODO: Vadym
            // DataListProps={{
            //   renderRowEnd: (item) => (
            //     <Card>
            //       <CardContent>
            //         <Stack spacing={1}>
            //           <Typography variant='subtitle1'>Spots</Typography>
            //           <SpotPaginatedList
            //             displayProps={{
            //               filters: true,
            //               hints: false,
            //               viewVariant: ViewLayoutVariant.Tab,
            //             }}
            //             defaultValues={{
            //               productLocationId: item.id || undefined,
            //             }}
            //           />
            //         </Stack>
            //       </CardContent>
            //     </Card>
            //   ),
            // }}
          />
        </Stack>

        <AssetSubscriptionPlanAddProductLocationsModal
          assetSubscriptionPlan={assetSubscriptionPlan}
          open={isAddProductLocationsModalOpen}
          onClose={() => {
            setIsAddProductLocationsModelOpen(false);
          }}
          onAdded={() => {
            paginatedProductLocationsRequest.refetch();
          }}
        />

        {/* Confirm ProductLocation removal */}
        {productLocationToRemove && (
          <ConfirmationModal
            title='Remove sales location from the plan?'
            body={
              <>
                {`You're going to remove sales location`}{" "}
                <strong>{productLocationToRemove.name}</strong> from the assetSubscriptionPlan{" "}
                <strong>{assetSubscriptionPlan.name}</strong>. {`This action can't be undone.`}
              </>
            }
            open={isRemoveProductLocationModalOpen}
            onClose={() => setIsRemoveProductLocationModalOpen(false)}
            onCancel={() => setIsRemoveProductLocationModalOpen(false)}
            onConfirm={() => handleProductLocationRemovalConfirmed(productLocationToRemove)}
          />
        )}
      </ViewLayout>
    </PageTabContent>
  );
}
