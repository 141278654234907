import {
  Alert,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { Formik } from "formik";
import _ from "lodash";
import moment, { Moment } from "moment";
import { useMemo, useState } from "react";
import * as Yup from "yup";

import { DATETIME_FORMATS } from "@/common/constants/common";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FileItem } from "@/common/fileItem";
import { EntityHelper } from "@/common/helpers/entity";
import { DamageDetectionHelper } from "@/common/helpers/entity/damageDetection";
import { MemoHelper } from "@/common/helpers/memo";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useUserAffiliation } from "@/common/hooks/useUserAffiliation";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  ContractDto,
  ContractStage,
  CreateDamageDetectionDto,
  CreateDamageDetectionItemDto,
  DamageDetectionDto,
  DamageDetectionItemDto,
  DamageTypeDto,
  EntityType,
  GeneralAttachmentDto,
  GeneralAttachmentInputDto,
  UpdateDamageDetectionDto,
  UpdateDamageDetectionItemDto,
  VehicleArea,
  VehicleDamageDto,
  VehicleDto,
  VehiclePartTypeDto,
  VehicleProjection,
  VehicleVisualModelDto,
  VehicleVisualModelSvgPathMetadataDto,
  VehicleVisualModelType,
} from "@/core/api/generated";

import DevRenderCount from "../../Dev/DevRenderCount";
import EntityDataBlock from "../../EntityData/EntityDataBlock";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FileListView from "../../Files/FileListView";
import FileUploader from "../../Files/FileUploader";
import FormActions from "../../Form/FormActions";
import FormikComputedField from "../../Form/Formik/FormikComputedField";
import AppTextArea from "../../Form/Input/AppTextArea";
import FuelLevelInput from "../../Form/Input/FuelLevelInput";
import MileageInput from "../../Form/Input/MileageInput";
import AppIcon from "../../Icons/AppIcon";
import { DamagePointsEditor } from "../../Images/DamagePointsEditor";
import { CursorPoint } from "../../Images/DamagePointsVisualizer";
import MemoWrapper from "../../Memo/MemoWrapper";
import ConfirmationModal from "../../Modals/ConfirmationModal";
import AppTypography from "../../Text/AppTypography";
import ContractAutocompleteOrCreate from "../Contract/ContractAutocompleteOrCreate";
import EntityAffiliationInput from "../EntityAffiliation/EntityAffiliationInput";
import GeneralAttachedTagsInput from "../General/GeneralTag/GeneralAttachedTagsInput";
import VehicleAutocompleteOrCreate from "../Vehicle/VehicleAutocompleteOrCreate";
import VehicleVisualModelLatestOrDefaultSelect from "../VehicleVisualModel/VehicleVisualModelLatestOrDefaultSelect";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";

function mapItemToInputDto(x: DamageDetectionItemDto): ExtendedDamageType {
  return {
    ...x,
    id: x.id,
    partTypeId: x.partType?.id,
    savedPartType: x.partType,
    damageTypeId: x.damageType?.id,
    savedDamageType: x.damageType,
    notes: x.notes || undefined,
    initialAttachments: x.attachments && x.attachments.length > 0 ? x.attachments : undefined,
    attachments: x.attachments && x.attachments.length > 0 ? x.attachments : undefined,
    area: x.area,
    projection: x.projection,
    point: x.point,
  };
}

export type ExtendedDamageType = Omit<VehicleDamageDto, "attachments"> &
  Omit<DamageDetectionItemDto, "attachments"> &
  Omit<CreateDamageDetectionItemDto, "attachments"> &
  Omit<UpdateDamageDetectionItemDto, "attachments"> & {
    attachments?: Array<GeneralAttachmentInputDto | GeneralAttachmentDto>;
    initialAttachments?: Array<GeneralAttachmentInputDto | GeneralAttachmentDto>;
    svgPathMetadata?: VehicleVisualModelSvgPathMetadataDto | null;
    isOld?: boolean;
    color?: string;
    isDraggable?: boolean;

    // FIXME: Why this is needed if partType and damageType exist?
    savedPartType?: VehiclePartTypeDto | null;
    savedDamageType?: DamageTypeDto | null;
  };
// type CreateUpdateDamageDetectionItem = (Omit<CreateDamageDetectionItemDto, "attachments"> &
//   Omit<UpdateDamageDetectionItemDto, "attachments">) & {
//   attachments?: Array<GeneralAttachmentInputDto> | null;
//   initialAttachments?: GeneralAttachmentInputDto[];
// };

export type VisualModelPointClickedInfo = {
  id: string;
  area?: VehicleArea;
  projection?: VehicleProjection;
  point: CursorPoint;
  svgPathMetadata: VehicleVisualModelSvgPathMetadataDto;
};

export type VisualModelPointDraggedInfo = VisualModelPointClickedInfo & {
  initialPointInfo: ExtendedDamageType;
};

export type CreateUpdateDDFormikType = BaseFormikValues &
  (Omit<CreateDamageDetectionDto, "items"> & Omit<UpdateDamageDetectionDto, "items">) & {
    initialAttachments: DamageDetectionDto["attachments"];
    items: ExtendedDamageType[];
    isAnyDamagesOnVehicle: boolean;
  };

type DefaultValues = {
  vehicleId?: CreateDamageDetectionDto["vehicleId"];
  contractId?: CreateDamageDetectionDto["contractId"];
  tenantRequestsMeta?: CreateDamageDetectionDto["tenantRequestsMeta"];
};

export interface DamageDetectionCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<DamageDetectionDto, DefaultValues> {
  damageDetectionId?: string;
}

export type DamageDetectionCreateUpdateProps = DamageDetectionCreateUpdateOwnProps;

export default function DamageDetectionCreateUpdate({
  damageDetectionId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: DamageDetectionCreateUpdateProps) {
  const formInfo = useMemo(
    () => EntityHelper.getEntityFormInfoByEntityId(damageDetectionId),
    [damageDetectionId],
  );

  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const profile = useUserProfile();

  const [contract] = useState<ContractDto | undefined>(undefined);
  const [visualModel, setVisualModel] = useState<VehicleVisualModelDto | undefined>(undefined);
  const [isAttachmentFilesUploading, setIsAttachmentFilesUploading] = useState(false);
  const [confirmVehicleHasNoNewDamageOpen, setConfirmVehicleHasNoNewDamageOpen] = useState(false);

  const damageDetectionRequest = useApiRequest(
    apiClient.damageDetectionsApi.apiV1DamageDetectionsDamageDetectionIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      damageDetectionId: damageDetectionId!,
    },
    {
      deps: [damageDetectionId],
      skip: !damageDetectionId,
    },
  );
  const damageDetection = damageDetectionRequest?.data;

  const vehicleIdComputed = defaultValues?.vehicleId || damageDetection?.vehicle?.id;
  const contractIdComputed = defaultValues?.contractId || damageDetection?.contract?.id;

  const paginatedVehicleDamagesRequest = useApiRequest(
    apiClient.vehicleDamagesApi.apiV1VehiclesVehicleIdDamagesGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: vehicleIdComputed || "",
      vehicleDamageGetPaginatedDto: {
        offset: 0,
        limit: 100,
      },
    },
    {
      deps: [vehicleIdComputed],
      skip: !vehicleIdComputed,
    },
  );
  const paginatedVehicleDamages = paginatedVehicleDamagesRequest?.data;

  const { departments, locations } = useUserAffiliation();

  const formPerms = useMemo(
    () =>
      DamageDetectionHelper.getEntityFormPermissions({
        formInfo,
        damageDetection,
      }),
    [formInfo],
  );

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: damageDetection && {
      idValue: damageDetection.id!,
      newTitle: damageDetection.localNumber || "",
    },
  });

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.DamageDetection}
      entityId={damageDetectionId}
      entity={damageDetection}
      entityRequest={damageDetectionRequest}
      isIniting={damageDetectionRequest.isLoading || !profile}
    >
      <Formik<
        CreateUpdateDDFormikType & {
          vehicle: VehicleDto | undefined;
          isAttachmentFilesHaveErrors: boolean;
        }
      >
        initialValues={{
          isAttachmentFilesHaveErrors: false,
          contractId: damageDetection?.contract?.id || defaultValues?.contractId || undefined,
          vehicleId: damageDetection?.vehicle?.id || defaultValues?.vehicleId || undefined,
          vehicle: undefined,
          departmentId:
            (damageDetection?.departmentIds || []).at(0) ||
            (departments && departments[0] && departments[0].id) ||
            undefined,
          locationId:
            (damageDetection?.locationIds || []).at(0) ||
            (locations && locations[0] && locations[0].id) ||
            undefined,
          inspectedAt:
            damageDetection?.inspectedAt || moment().format(DATETIME_FORMATS.DISPLAY_DATETIME),
          inspector: {
            isCurrentUser: true,
            ...(damageDetection?.inspector || {}),
            phoneNumber: damageDetection?.inspector?.phoneNumber,
          },
          isUseSupplierBranding: damageDetection ? damageDetection.isUseSupplierBranding : true,
          mileage: damageDetection?.mileage || undefined,
          fuelLevel: damageDetection?.fuelLevel || undefined,
          isAnyDamagesOnVehicle: !damageDetection?.isNoDamagesDetected || true,
          isSendToCustomer: damageDetection?.isSendToCustomer ?? undefined,
          notes: damageDetection?.notes || "",
          vehicleVisualModelId: damageDetection?.vehicleVisualModel?.id,
          initialAttachments: damageDetection?.attachments || undefined,
          attachments:
            damageDetection?.attachments && damageDetection.attachments.length > 0
              ? damageDetection.attachments
              : undefined,
          items: damageDetection?.items?.map((x) => mapItemToInputDto(x)) || [],
          tenantRequestsMeta:
            damageDetection?.tenantRequestsMeta || defaultValues?.tenantRequestsMeta || undefined,
          tags: damageDetection?.tags || undefined,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // contractId: Yup.string().required("Contract is required"),
          // vehicleId: Yup.string().required("Vehicle is required"),
          // type: Yup.string().required("Type is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            if (formInfo.isCreate) {
              const response = await apiClient.damageDetectionsApi.apiV1DamageDetectionsPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                createDamageDetectionDto: {
                  ..._.omit(values, ["submit"]),
                  contractId: contractIdComputed,
                  isNoDamagesDetected: !values.isAnyDamagesOnVehicle,
                  inspectedAt: values.inspectedAt
                    ? moment(values.inspectedAt).utc().format()
                    : undefined,
                  attachments: values.attachments,
                  items: values.items,
                },
              });
              enqueueSnackbar("Damage detection created.", { variant: "success" });
              onCreate && (await onCreate(response.data));
              onSave && (await onSave(response.data));
            } else {
              const response =
                await apiClient.damageDetectionsApi.apiV1DamageDetectionsDamageDetectionIdPut({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  damageDetectionId: damageDetectionId || "",
                  updateDamageDetectionDto: {
                    ..._.omit(values, ["submit"]),
                    isNoDamagesDetected: !values.isAnyDamagesOnVehicle,
                    inspectedAt: values.inspectedAt
                      ? moment(values.inspectedAt).utc().format()
                      : undefined,
                    attachments: values.attachments,
                    items: values.items?.map((item) => {
                      return {
                        ...item,
                        id: item?.id?.includes("frontend-temp-id-") ? undefined : item.id,
                      };
                    }),
                  },
                });
              enqueueSnackbar("Damage detection updated.", { variant: "success" });
              onUpdate && (await onUpdate(response.data));
              onSave && (await onSave(response.data));
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <DevRenderCount isPositionFixed description='Form root' />

              <FormikComputedField<
                typeof values,
                Pick<typeof values, "vehicle" | "departmentId" | "locationId">
              >
                debouncedDeps={{
                  deps: [values?.vehicleId],
                  wait: 100,
                  options: { leading: false, trailing: true },
                }}
                compute={async (v) => {
                  const vehicleResponse = v.vehicleId
                    ? await apiClient.vehiclesApi.apiV1VehiclesVehicleIdGet({
                        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                        vehicleId: v.vehicleId!,
                      })
                    : null;
                  return {
                    vehicle: vehicleResponse?.data,
                    departmentId: !values.departmentId
                      ? vehicleResponse?.data?.departmentIds?.at(0)
                      : undefined,
                    locationId: !values.locationId
                      ? vehicleResponse?.data?.locationIds?.at(0)
                      : undefined,
                  };
                }}
                onComputed={(computed) => {
                  setFieldValue("vehicle", computed.vehicle);
                  setFieldValue("departmentId", computed.departmentId);
                  setFieldValue("locationId", computed.locationId);
                }}
              />

              <MemoWrapper
                props={{
                  isCreate: formInfo.isCreate,
                  formPerms,
                  vehicleIdComputed,
                  damageDetection,
                  contract,
                  errors,
                  tags: values.tags,
                  vehicleId: values.vehicleId,
                  vehicle: values.vehicle,
                  departmentId: values.departmentId,
                  locationId: values.locationId,
                  contractId: values.contractId,
                  inspectedAt: values.inspectedAt,
                  mileage: values.mileage,
                  fuelLevel: values.fuelLevel,
                  isUseSupplierBranding: values.isUseSupplierBranding,
                  vehicleVisualModelId: values.vehicleVisualModelId,
                }}
                arePropsEqualFactory={(params) =>
                  MemoHelper.arePropsEqual.factory<typeof params.props>({
                    isExcludeFunctionProps: true,
                  })
                }
              >
                {() => (
                  <Box>
                    <DevRenderCount sx={{ mb: 1 }} description='Form first section' />

                    {/* Tags */}
                    <FormControl margin='dense' fullWidth>
                      <GeneralAttachedTagsInput
                        value={values.tags}
                        onChange={(newValue) => {
                          setFieldValue("tags", newValue);
                        }}
                      />
                      <FormHelperText error>
                        {ValidationHelper.getFormikErrorsAsString(errors.tags, {
                          isIncludeNested: false,
                        })}
                      </FormHelperText>
                    </FormControl>

                    <FormControl margin='dense' fullWidth error={Boolean(errors.vehicleId)}>
                      <VehicleAutocompleteOrCreate
                        autocompleteProps={{
                          required: true,
                          disabled: !formPerms.canEditVehicle || !_.isEmpty(vehicleIdComputed),
                          entityId: values.vehicleId,
                          isPreload: formPerms.canEditVehicle,
                          onChange: (newValue) => {
                            setFieldValue(`vehicleId`, newValue?.id);
                            setFieldValue("vehicleVisualModelId", undefined);
                            if (newValue?.id) {
                              paginatedVehicleDamagesRequest.refetchWithNewParams({
                                ...paginatedVehicleDamagesRequest.requestParams,
                                vehicleId: newValue?.id || "",
                              });
                            } else {
                              paginatedVehicleDamagesRequest.replaceData(null);
                            }
                          },
                        }}
                        createFormPlacement='modal'
                        onCreate={(newValue) => {
                          setFieldValue(`vehicleId`, newValue?.id);
                          setFieldValue("vehicleVisualModelId", undefined);
                          if (newValue?.id) {
                            paginatedVehicleDamagesRequest.refetchWithNewParams({
                              ...paginatedVehicleDamagesRequest.requestParams,
                              vehicleId: newValue?.id || "",
                            });
                          } else {
                            paginatedVehicleDamagesRequest.replaceData(null);
                          }
                        }}
                      />
                      <FormHelperText>{errors.vehicleId}</FormHelperText>
                    </FormControl>

                    {/* Entity affiliation */}
                    <EntityAffiliationInput
                      department={{
                        departmentId: values.departmentId,
                        onChange: (d) => {
                          setFieldValue("departmentId", d?.id);
                          setFieldValue("locationId", undefined);
                        },
                        error: errors.departmentId,
                      }}
                      location={{
                        locationId: values.locationId,
                        onChange: (l) => {
                          setFieldValue("locationId", l?.id);
                        },
                        searchFilters: { departmentId: values.departmentId },
                        createUpdateProps: { defaultValues: { departmentId: values.departmentId } },
                        error: errors.locationId,
                        disabled: !values.departmentId,
                      }}
                    />

                    {values.contractId && (
                      <FormControl margin='dense' fullWidth error={Boolean(errors.contractId)}>
                        <ContractAutocompleteOrCreate
                          autocompleteProps={{
                            disabled: !formPerms.canEditContract,
                            entityId: values.contractId,
                            isPreload: formPerms.canEditContract,
                            searchFilters: {
                              vehicleId: values.vehicleId || undefined,
                              excludeStage: ContractStage.Draft,
                            },
                            onChange: (newValue) => {
                              setFieldValue(`contractId`, newValue?.id);
                              setFieldValue(`contract`, newValue || undefined);
                            },
                          }}
                          createFormPlacement='modal'
                          onCreate={(newValue) => {
                            setFieldValue(`contractId`, newValue?.id);
                            setFieldValue(`contract`, newValue || undefined);
                          }}
                        />
                        <FormHelperText>{errors.contractId}</FormHelperText>
                      </FormControl>
                    )}

                    {values.vehicleId && (
                      <>
                        <FormControl
                          margin='dense'
                          error={Boolean(touched.inspectedAt && errors.inspectedAt)}
                        >
                          <MobileDateTimePicker
                            ampm={false}
                            label='Inspected at'
                            value={(values.inspectedAt && moment(values.inspectedAt)) || null}
                            format={DATETIME_FORMATS.DISPLAY_DATETIME}
                            onChange={(newValue: Moment | null) => {
                              setFieldValue("inspectedAt", newValue?.format() || null);
                            }}
                            slots={{ textField: (params) => <TextField {...params} /> }}
                          />
                          <FormHelperText>
                            {touched.inspectedAt && errors.inspectedAt}
                          </FormHelperText>
                        </FormControl>

                        {/* Mileage */}
                        <MileageInput
                          error={Boolean(touched.mileage && errors.mileage)}
                          helperText={touched.mileage && errors.mileage}
                          vehicleId={values.vehicleId}
                          vehicle={values.vehicle}
                          disabled={!values.vehicle}
                          fullWidth
                          name='mileage'
                          onBlur={handleBlur}
                          onChange={(newValue) => {
                            setFieldValue("mileage", newValue);
                          }}
                          value={values.mileage}
                        />

                        {/* Fuel level */}
                        <FormControl
                          margin='normal'
                          fullWidth
                          error={Boolean(touched.fuelLevel && errors.fuelLevel)}
                        >
                          <Typography component='div' gutterBottom>
                            Fuel level
                          </Typography>

                          <FuelLevelInput
                            onBlur={handleBlur}
                            onChange={(e, val) => setFieldValue("fuelLevel", val || 0)}
                            value={values.fuelLevel || 0}
                          />
                          <FormHelperText>{touched.fuelLevel && errors.fuelLevel}</FormHelperText>
                        </FormControl>

                        {/* Include supplier branding */}
                        <FormControl margin='dense' fullWidth>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.isUseSupplierBranding}
                                onChange={(e) =>
                                  setFieldValue(`isUseSupplierBranding`, e.target.checked)
                                }
                              />
                            }
                            label={
                              <Typography component='span' sx={{ display: "flex" }}>
                                Include supplier branding
                              </Typography>
                            }
                          />
                        </FormControl>

                        {/* Visual model */}
                        <Box sx={{ my: 1 }}>
                          <EntityDataBlock
                            withDetailsToggle
                            title={
                              <>
                                <AppIcon of='document' inText /> Vehicle visual model (view only)
                              </>
                            }
                            briefContent={
                              <>
                                <FormControl
                                  margin='dense'
                                  fullWidth
                                  disabled={true}
                                  error={Boolean(errors.vehicleVisualModelId)}
                                >
                                  <InputLabel>Vehicle visual model</InputLabel>

                                  <VehicleVisualModelLatestOrDefaultSelect
                                    visualModelId={values.vehicleVisualModelId}
                                    filters={{
                                      type: VehicleVisualModelType.TwoDimensionalDivided,
                                      vehicleType: contract?.vehicle?.spec?.type,
                                      bodyType: contract?.vehicle?.spec?.bodyType,
                                      vehicleId: values.vehicleId || contract?.vehicle?.id,
                                    }}
                                    disabled
                                    label='Vehicle visual model'
                                    onChange={async (newValue) => {
                                      if (formInfo.isCreate) {
                                        setFieldValue(`vehicleVisualModelId`, newValue?.id);
                                      }
                                      setVisualModel(newValue || undefined);
                                    }}
                                  />
                                  <FormHelperText>{errors.vehicleVisualModelId}</FormHelperText>
                                </FormControl>
                              </>
                            }
                            detailedContent={
                              <>
                                <FormControl
                                  margin='dense'
                                  fullWidth
                                  disabled={true}
                                  error={Boolean(errors.vehicleVisualModelId)}
                                >
                                  <InputLabel>Vehicle visual model</InputLabel>
                                  <VehicleVisualModelLatestOrDefaultSelect
                                    visualModelId={values.vehicleVisualModelId}
                                    filters={{
                                      type: VehicleVisualModelType.TwoDimensionalDivided,
                                      vehicleType: contract?.vehicle?.spec?.type,
                                      bodyType: contract?.vehicle?.spec?.bodyType,
                                      vehicleId: values.vehicleId || contract?.vehicle?.id,
                                    }}
                                    disabled
                                    label='Vehicle visual model'
                                    onChange={async (newValue) => {
                                      if (formInfo.isCreate) {
                                        setFieldValue(`vehicleVisualModelId`, newValue?.id);
                                      }
                                      setVisualModel(newValue || undefined);
                                    }}
                                  />
                                  <FormHelperText>{errors.vehicleVisualModelId}</FormHelperText>
                                </FormControl>

                                <FileListView
                                  files={FileItem.createManyFrom(visualModel?.images)}
                                  sx={{
                                    "& svg": {
                                      pointerEvents: "none",
                                    },
                                  }}
                                />
                              </>
                            }
                          />
                        </Box>
                      </>
                    )}
                  </Box>
                )}
              </MemoWrapper>

              <Box>
                {
                  /* TODO: Find out whats going on here because QA reported this */
                  // eslint-disable-next-line no-constant-binary-expression
                  /* values.vehicleId */ true && (
                    <>
                      {/* Items */}
                      <Box sx={{ my: 2, display: "flex", flexDirection: "column" }}>
                        <Typography component='div' sx={{ mb: 1 }} variant='h2'>
                          Damages *
                        </Typography>

                        <Alert severity='info' icon={<AppIcon of='tip' />} sx={{ mb: 1 }}>
                          <Box component='ul' sx={{ m: 0 }}>
                            <li>Click on vehicle image to add damages.</li>
                            <li>
                              After submission of this operation, corresponding damages will be
                              recorded in the vehicle history.
                            </li>
                          </Box>
                        </Alert>

                        <FormControl sx={{ mb: 2 }} error={Boolean(errors.vehicleId)}>
                          <FormControlLabel
                            sx={{ width: "max-content" }}
                            control={
                              <Checkbox
                                name='isAnyDamagesOnVehicle'
                                checked={values.isAnyDamagesOnVehicle}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  if (!values.isAnyDamagesOnVehicle) {
                                    setFieldValue("isAnyDamagesOnVehicle", true);
                                    setFieldValue(
                                      "items",
                                      damageDetection?.items?.map((x) => mapItemToInputDto(x)) ||
                                        [],
                                    );
                                  } else {
                                    setConfirmVehicleHasNoNewDamageOpen(true);
                                  }
                                }}
                              />
                            }
                            label={"Vehicle has new damages"}
                          />
                        </FormControl>

                        {values.isAnyDamagesOnVehicle && (
                          <DamagePointsEditor
                            formInfo={formInfo}
                            formPerms={formPerms}
                            visualModel={visualModel}
                            errors={errors}
                            containerSx={{
                              width: `500px`,
                              height: `500px`,
                              cursor: "crosshair",
                              // overflow: "auto",
                            }}
                            isPreload={true}
                            damages={
                              values.items?.map((x) => ({
                                ...x,
                                isDraggable: formPerms.canEdit,
                              })) || []
                            }
                            oldDamages={
                              paginatedVehicleDamages?.items?.map((x) => ({
                                ...x,
                                isOld: true,
                                color: "orange",
                                isDraggable: false,
                              })) || []
                            }
                            updateDamageItems={(damageItems) => {
                              setFieldValue("items", damageItems);
                            }}
                            isCreateModalConfirmEnabled={!isAttachmentFilesUploading}
                            isEditingModalConfirmEnabled={!isAttachmentFilesUploading}
                            isDisableDelete={false}
                            isDisablePartTypeEdit={!formPerms.canEdit}
                            isDisableDamageTypeEdit={!formPerms.canEdit}
                          />
                        )}
                      </Box>

                      {/* TODO: dev and test refactored SVG viewer */}
                      {/* <Stack spacing={2}>
                        <Typography variant='h2'>Test VehicleVisualModelSvgViewer </Typography>

                        {visualModel && (
                          <VehicleVisualModelSvgViewer
                            svgUrl={visualModel.images!.at(0)!.file!.url!}
                            points={values.items?.map((x) => ({
                              id: x.id || "",
                              coords: x.point,
                              isDraggable: true,
                            }))}
                            withZooming
                            containerSx={{
                              width: `500px`,
                              height: `500px`,
                              cursor: "crosshair",
                              // overflow: "auto",
                            }}
                          />
                        )}
                      </Stack> */}

                      <MemoWrapper
                        props={{
                          errors,
                          notes: values.notes,
                          contractId: values.contractId,
                          initialAttachments: values.initialAttachments,
                          attachments: values.attachments,
                          inspector: values.inspector,
                          isSendToCustomer: values.isSendToCustomer,
                        }}
                        arePropsEqualFactory={(params) =>
                          MemoHelper.arePropsEqual.factory<typeof params.props>({
                            isExcludeFunctionProps: true,
                          })
                        }
                      >
                        {() => (
                          <Stack spacing={2}>
                            <DevRenderCount description='Form last section' />

                            <AppTextArea
                              error={Boolean(touched.notes && errors.notes)}
                              fullWidth
                              helperText={touched.notes && errors.notes}
                              mode='notes'
                              margin='dense'
                              name='notes'
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.notes}
                              variant='outlined'
                            />

                            <FormControl fullWidth component='fieldset' margin='dense'>
                              <FileUploader
                                multiple
                                defaultFiles={FileItem.createManyFrom(values.initialAttachments)}
                                onChange={(newFiles) => {
                                  setFieldValue(
                                    `attachments`,
                                    FileItem.toManyGeneralAttachmentInputDto(newFiles),
                                  );
                                }}
                                onUploadStarted={() => {
                                  setIsAttachmentFilesUploading(true);
                                }}
                                onUploadFinished={() => {
                                  setIsAttachmentFilesUploading(false);
                                }}
                                onValidationStatusChange={(filesValidationStatus) => {
                                  if (filesValidationStatus)
                                    setFieldValue(
                                      "isAttachmentFilesHaveErrors",
                                      Object.values(filesValidationStatus).some((x) => x === false),
                                    );
                                }}
                              />
                            </FormControl>

                            <FormControl>
                              <FormControlLabel
                                control={
                                  <Switch
                                    disabled
                                    checked={values.inspector?.isCurrentUser}
                                    onChange={handleChange}
                                    name='inspector.isCurrentUser'
                                  />
                                }
                                label={
                                  <AppTypography
                                    decoration={{ variant: "helpText" }}
                                    tooltipProps={{
                                      title: "Currently logged in user evaluated the damages",
                                    }}
                                  >
                                    Inspected by{" "}
                                    {values.inspector?.personName
                                      ? `${values.inspector.personName?.firstName} ${values.inspector.personName?.lastName}`
                                      : profile?.personName?.name}
                                  </AppTypography>
                                }
                              />
                            </FormControl>

                            {values.contractId && (
                              <FormControl margin='dense' fullWidth>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.isSendToCustomer ?? undefined}
                                      onChange={(e) =>
                                        setFieldValue("isSendToCustomer", e.target.checked)
                                      }
                                    />
                                  }
                                  label='Send to customer'
                                />
                              </FormControl>
                            )}
                          </Stack>
                        )}
                      </MemoWrapper>
                    </>
                  )
                }
              </Box>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              {values.vehicleId && (
                <FormActions>
                  <Button
                    color='primary'
                    disabled={isAttachmentFilesUploading || values.isAttachmentFilesHaveErrors}
                    loading={isSubmitting}
                    fullWidth
                    type='submit'
                    variant='contained'
                  >
                    Save
                  </Button>
                </FormActions>
              )}

              <ConfirmationModal
                title='Vehicle has no new damages?'
                body={
                  "Please confirm that you haven't detected any new damages during the inspection."
                }
                open={confirmVehicleHasNoNewDamageOpen}
                onClose={() => setConfirmVehicleHasNoNewDamageOpen(false)}
                onCancel={() => setConfirmVehicleHasNoNewDamageOpen(false)}
                onConfirm={() => {
                  setFieldValue("isAnyDamagesOnVehicle", false);
                  setFieldValue("items", []);
                  setConfirmVehicleHasNoNewDamageOpen(false);
                }}
              />
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
