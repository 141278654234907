import { useParams } from "react-router-dom";

import DataGrantView from "@/common/components/Entity/DataGrant/View/DataGrantView";

export default function DataGrantViewPage() {
  const { dataGrantId } = useParams<{ dataGrantId?: string }>();

  return (
    <>
      <DataGrantView
        dataGrantId={dataGrantId}
        dataGrant={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
