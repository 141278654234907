import { Alert } from "@mui/lab";
import { RouteProps } from "react-router-dom";

import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission } from "@/core/api/generated";

import AppRoute from "../Route/AppRoute";

type AuthorizedElementProps = {
  /** List of permissions user must have (all) */
  permissions?: AppPermission[];
  /** List of permissions user must have (at least one from the list) */
  permissionsAny?: AppPermission[];
} & RouteProps;

/** Renders Route that renders forbidden page if user doesn't have required permissions */
export default function AuthorizedRoute({
  permissions,
  permissionsAny,
  ...routeProps
}: AuthorizedElementProps) {
  const authorizationInfo = useAuthorizationInfo();

  const hasPermissions = authorizationInfo.hasPermissionsAllAndAny({
    permissions,
    permissionsAny,
  });

  return (
    <AppRoute
      {...routeProps}
      render={(renderProps) => {
        if (hasPermissions) {
          return routeProps?.render && routeProps?.render(renderProps);
        } else {
          return (
            <Alert
              sx={{ my: 1 }}
              severity='error'
            >{`You don't have enough permissions to access the resource!`}</Alert>
          );
        }
      }}
    />
  );
}
