import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { useMemo, useState } from "react";

import AppTooltip from "@/common/components/AppTooltip";
import ApiEnumAutocomplete from "@/common/components/Enum/ApiEnumAutocomplete";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppModalActions from "@/common/components/Modals/AppModalActions";
import AppModalContent from "@/common/components/Modals/AppModalContent";
import AppModalV2 from "@/common/components/Modals/AppModalV2";
import { CustomFormikSubProps } from "@/common/helpers/formik";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { ApiEnumName, ApiEnumValue, enumService } from "@/common/services/enum";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { GeneralStatusInputDto, GeneralStatusMetaDto } from "@/core/api/generated";

import GeneralAttachedTagsInput from "../../General/GeneralTag/GeneralAttachedTagsInput";

interface Props {
  statusEnumTypeName: ApiEnumName;
  currentValue: GeneralStatusMetaDto | undefined | null;
  formikProps: CustomFormikSubProps<GeneralStatusInputDto | null | undefined>;
}

export default function GeneralStatusInput({
  statusEnumTypeName,
  currentValue,
  formikProps,
}: Props) {
  const { values, errors, setValues, setFieldValue } = formikProps;
  const currentStatus = currentValue?.status as ApiEnumValue<ApiEnumName> | undefined;

  const [isConfirmUpdateModalOpen, setIsConfirmUpdateModalOpen] = useState(false);

  const enumTransitionSpecRequest = useApiRequest(
    apiClient.enumsApi.apiV1EnumsTransitionSpecGet,
    {
      enumTypeName: statusEnumTypeName,
    },
    {
      deps: [statusEnumTypeName],
      skip: !statusEnumTypeName,
    },
  );
  const enumTransitionSpec = enumTransitionSpecRequest.data;

  const newStatusCandidates = useMemo(
    () =>
      statusEnumTypeName && currentStatus && enumTransitionSpec
        ? enumService.getEnumValues(statusEnumTypeName).filter((status) =>
            enumService.canTransit({
              enumName: statusEnumTypeName,
              fromEnumValue: currentStatus,
              toEnumValue: status,
              transitionSpec: enumTransitionSpec,
            }),
          )
        : undefined,
    [enumTransitionSpec],
  );

  return (
    <Box>
      <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
        <ApiEnumAutocomplete
          sx={{ flex: 1 }}
          type={statusEnumTypeName}
          onlyEnumValues={newStatusCandidates}
          value={values?.status as ApiEnumValue<ApiEnumName>}
          label='Status'
          onChange={(newValue) => {
            if (newValue !== values?.status) {
              const newValues: typeof values = {
                ...values,
                startReason: undefined,
                tags: undefined,
              };
              setValues(newValues);
            }
            setFieldValue("status", newValue);
          }}
        />

        <AppTooltip title='Edit details'>
          <IconButton
            size='medium'
            sx={{ ml: 0.25 }}
            onClick={() => setIsConfirmUpdateModalOpen(true)}
          >
            <AppIcon of='details' />
          </IconButton>
        </AppTooltip>
      </Stack>

      <AppModalV2
        open={isConfirmUpdateModalOpen}
        fullWidth
        maxWidth='md'
        keepMounted={false}
        titleProps={{
          title: "Status details",
        }}
        onClose={() => setIsConfirmUpdateModalOpen(false)}
      >
        <AppModalContent>
          <Stack spacing={2}>
            <Box>
              <Stack spacing={1}>
                <FieldValue label='Old status' direction='row' isEmpty={!currentStatus}>
                  <InlineApiEnumValue
                    type={statusEnumTypeName}
                    value={currentStatus}
                    withHelperTooltip
                  />
                </FieldValue>

                <FieldValue label='New status' direction='row' isEmpty={!values?.status}>
                  <InlineApiEnumValue
                    type={statusEnumTypeName}
                    value={values?.status as ApiEnumValue<ApiEnumName>}
                    withHelperTooltip
                  />
                </FieldValue>
              </Stack>

              <TextField
                error={Boolean(errors?.startReason)}
                fullWidth
                multiline
                rows={2}
                helperText={errors?.startReason}
                label='Reason'
                margin='normal'
                onChange={(e) => setFieldValue("startReason", e.target.value || undefined)}
                type='text'
                defaultValue={values?.startReason || ""}
                variant='outlined'
              />

              {/* Tags */}
              <FormControl margin='dense' fullWidth>
                <GeneralAttachedTagsInput
                  value={values?.tags}
                  onChange={(newValue) => {
                    setFieldValue("tags", newValue);
                  }}
                />
                <FormHelperText error>
                  {ValidationHelper.getFormikErrorsAsString(errors?.tags, {
                    isIncludeNested: false,
                  })}
                </FormHelperText>
              </FormControl>
            </Box>

            <FormHelperText error>
              {ValidationHelper.getFormikErrorsAsString(errors, {
                isIncludeNested: false,
              })}
            </FormHelperText>

            <AppModalActions
              modalProps={{
                onClose: () => setIsConfirmUpdateModalOpen(false),
              }}
              isAutoCloseOnSubmit
              submitButton={
                <Button variant='contained' color='primary' type='submit'>
                  Ok
                </Button>
              }
            ></AppModalActions>
          </Stack>
        </AppModalContent>
      </AppModalV2>
    </Box>
  );
}
