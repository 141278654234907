import { Stack } from "@mui/material";
import { useMemo } from "react";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import EmbeddedChat from "@/App/MainAppView/Chats/components/EmbeddedChat";
import { AssessmentFlowChatPageQueryParams } from "@/App/MainAppView/Flows/Assessment/AssessmentFlowChatPage";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { AssessmentFlowDto, ChatType } from "@/core/api/generated";

interface Props {
  assessmentFlow: AssessmentFlowDto;
}

const ApprovalsTabContent = ({ assessmentFlow }: Props) => {
  const { chatId } = useQueryParams<AssessmentFlowChatPageQueryParams>();
  const chatIdComputed = useMemo(() => chatId || assessmentFlow?.chatId, [chatId, assessmentFlow]);

  return (
    <PageTabContent>
      <Stack
        sx={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: (t) => t.shapeCustom.borderRadiusCard,
          borderBottomRightRadius: (t) => t.shapeCustom.borderRadiusCard,
        }}
      >
        {chatIdComputed && (
          <EmbeddedChat
            chatType={ChatType.Negotiation}
            chatId={chatIdComputed}
            chatProps={{
              size: "medium",
              displayProps: {
                contentHeader: true,
                contentFolding: true,
              },
            }}
          />
        )}
      </Stack>
    </PageTabContent>
  );
};

export default ApprovalsTabContent;
