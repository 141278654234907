import { Dialog, DialogProps } from "@mui/material";

import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AppModalContent from "@/common/components/Modals/AppModalContent";

import RepairSpecDataView, { RepairSpecDataViewProps } from "./RepairSpecDataView";

export interface OwnProps {
  viewProps: RepairSpecDataViewProps;
}

type Props = OwnProps & DialogProps;

export default function RepairSpecDataViewModal({ viewProps, ...dialogProps }: Props) {
  return (
    <Dialog fullWidth maxWidth='lg' {...dialogProps}>
      <AppModalContent>
        <RepairSpecDataView
          {...viewProps}
          displayProps={{
            viewVariant: ViewLayoutVariant.Tab, // TODO: must be Modal
            ...viewProps?.displayProps,
          }}
        />
      </AppModalContent>
    </Dialog>
  );
}
