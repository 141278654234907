import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik, getIn } from "formik";
import * as Yup from "yup";

import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  CreateLocationDto,
  EntityType,
  LocationDto,
  UpdateLocationDto,
} from "@/core/api/generated";

import GeneralValidationError from "../../Error/GeneralValidationError";
import FormActions from "../../Form/FormActions";
import AppTextArea from "../../Form/Input/AppTextArea";
import DepartmentAutocompleteOrCreate from "../Department/DepartmentAutocompleteOrCreate";
import GeneralAddressInput from "../General/Input/GeneralAddressInput";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";

export type LocationCreateUpdateDefaultValues = {
  departmentId?: CreateLocationDto["departmentId"];
  address?: CreateLocationDto["address"];
  name?: CreateLocationDto["name"];
};

type InputDisplayProps = {
  withDepartmentInput?: boolean;
};

export interface LocationCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<LocationDto, LocationCreateUpdateDefaultValues> {
  locationId?: string;
  inputDisplayProps?: InputDisplayProps;
}

export type LocationCreateUpdateProps = LocationCreateUpdateOwnProps;

export default function LocationCreateUpdate({
  locationId,
  defaultValues,
  inputDisplayProps = {
    withDepartmentInput: true,
  },
  onCreate,
  onUpdate,
  onSave,
}: LocationCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const isCreate = !locationId;

  const locationRequest = useApiRequest(
    apiClient.locationsApi.apiV1LocationsLocationIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      locationId: locationId!,
    },
    {
      skip: !locationId,
    },
  );
  const location = locationRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: location && {
      idValue: location.id!,
      newTitle: location.localNumber || "",
    },
  });

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.Location}
      entityId={locationId}
      entity={location}
      entityRequest={locationRequest}
    >
      <Formik<CreateLocationDto & UpdateLocationDto & BaseFormikValues>
        enableReinitialize={!isCreate}
        initialValues={{
          departmentId: defaultValues?.departmentId || location?.department?.id || undefined,
          name: defaultValues?.name || location?.name || undefined,
          description: location?.description || undefined,
          address: defaultValues?.address || location?.address || undefined,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // vehicleId: Yup.string().required("Vehicle is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            if (isCreate) {
              const response = await apiClient.locationsApi.apiV1LocationsPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                createLocationDto: {
                  ...values,
                },
              });
              enqueueSnackbar("Location created.", { variant: "success" });
              onCreate && onCreate(response.data);
              onSave && onSave(response.data);
            } else {
              const response = await apiClient.locationsApi.apiV1LocationsLocationIdPut({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                locationId,
                updateLocationDto: {
                  ...values,
                },
              });
              enqueueSnackbar("Location updated.", { variant: "success" });
              onUpdate && onUpdate(response.data);
              onSave && onSave(response.data);
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Box>
                  {inputDisplayProps.withDepartmentInput && (
                    <FormControl
                      margin='dense'
                      fullWidth
                      disabled={!isCreate}
                      error={Boolean(errors.departmentId)}
                    >
                      <DepartmentAutocompleteOrCreate
                        autocompleteProps={{
                          entityId: values.departmentId,
                          isPreload: isCreate,
                          onChange: (newValue) => {
                            setFieldValue("departmentId", newValue?.id);
                            newValue?.address && setFieldValue("address", newValue.address);
                          },
                        }}
                        createFormPlacement='modal'
                        onCreate={(newValue) => {
                          setFieldValue("departmentId", newValue?.id);
                          newValue?.address && setFieldValue("address", newValue?.address);
                        }}
                      />
                      <FormHelperText>{errors.departmentId}</FormHelperText>
                    </FormControl>
                  )}

                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    required
                    fullWidth
                    helperText={touched.name && errors.name}
                    label='Name'
                    margin='dense'
                    name='name'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='text'
                    value={values.name || ""}
                    variant='outlined'
                  />

                  <AppTextArea
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    helperText={touched.description && errors.description}
                    mode='description'
                    margin='dense'
                    name='description'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description || ""}
                    variant='outlined'
                  />

                  <FormControl fullWidth>
                    <Paper sx={{ p: 1 }}>
                      <FormLabel required>
                        <Typography component='span' variant='subtitle1'>
                          Address
                        </Typography>
                      </FormLabel>

                      <GeneralAddressInput
                        errors={getIn(errors, "address")}
                        touched={getIn(touched, "address")}
                        value={values.address}
                        inputsProps={{ all: {} }}
                        onChange={(newValue) => setFieldValue("address", newValue)}
                      />
                    </Paper>
                  </FormControl>
                </Box>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <FormActions>
                  <Button
                    color='primary'
                    loading={isSubmitting}
                    fullWidth
                    type='submit'
                    variant='contained'
                  >
                    Save
                  </Button>
                </FormActions>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
