import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

// Figma name: Icon/Outline/checkmark-circle
export default forwardRef<SVGSVGElement, SvgIconProps>(function CheckCircleOutlineIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.7046 8.39453L10.9206 13.3635L9.2876 11.2775C8.9476 10.8415 8.3176 10.7655 7.8836 11.1065C7.4486 11.4465 7.3716 12.0755 7.7126 12.5095L10.1436 15.6165C10.3336 15.8585 10.6236 15.9995 10.9316 15.9995H10.9386C11.2476 15.9985 11.5396 15.8525 11.7266 15.6055L16.2956 9.60553C16.6306 9.16553 16.5456 8.53953 16.1056 8.20453C15.6646 7.86953 15.0386 7.95553 14.7046 8.39453ZM12 20C7.589 20 4 16.411 4 12C4 7.58903 7.589 4.00003 12 4.00003C16.411 4.00003 20 7.58903 20 12C20 16.411 16.411 20 12 20ZM12 2.00003C6.477 2.00003 2 6.47803 2 12C2 17.522 6.477 22 12 22C17.523 22 22 17.522 22 12C22 6.47803 17.523 2.00003 12 2.00003Z'
      />
      <mask id='mask0_1_403' maskUnits='userSpaceOnUse' x='2' y='2' width='20' height='20'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.7046 8.39453L10.9206 13.3635L9.2876 11.2775C8.9476 10.8415 8.3176 10.7655 7.8836 11.1065C7.4486 11.4465 7.3716 12.0755 7.7126 12.5095L10.1436 15.6165C10.3336 15.8585 10.6236 15.9995 10.9316 15.9995H10.9386C11.2476 15.9985 11.5396 15.8525 11.7266 15.6055L16.2956 9.60553C16.6306 9.16553 16.5456 8.53953 16.1056 8.20453C15.6646 7.86953 15.0386 7.95553 14.7046 8.39453ZM12 20C7.589 20 4 16.411 4 12C4 7.58903 7.589 4.00003 12 4.00003C16.411 4.00003 20 7.58903 20 12C20 16.411 16.411 20 12 20ZM12 2.00003C6.477 2.00003 2 6.47803 2 12C2 17.522 6.477 22 12 22C17.523 22 22 17.522 22 12C22 6.47803 17.523 2.00003 12 2.00003Z'
        />
      </mask>
      <g mask='url(#mask0_1_403)'>
        <rect y='3.05176e-05' width='24' height='24' />
      </g>
    </SvgIcon>
  );
});
