import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function EditIcon(props: SvgIconProps, ref) {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <g clipPath='url(#clip0_12064_26577)'>
        <path
          d='M22.5 10.09V11.04H16.7L4.36 22.5H1.5V19.64L12.96 7.3V1.5H13.91L22.5 10.09Z'
          stroke='currentColor'
          strokeWidth='2'
          strokeMiterlimit='10'
          fill='none'
        />
        <path
          d='M16.77 7.22999L18.2 5.79999'
          stroke='currentColor'
          strokeWidth='2'
          strokeMiterlimit='10'
          fill='none'
        />
        <path
          d='M10.0901 7.22998L13.9101 16.77'
          stroke='currentColor'
          strokeWidth='2'
          strokeMiterlimit='10'
          fill='none'
        />
        <path
          d='M7.22998 10.09L11.05 19.64'
          stroke='currentColor'
          strokeWidth='2'
          strokeMiterlimit='10'
          fill='none'
        />
        <path
          d='M4.36011 12.95L8.18011 22.5'
          stroke='currentColor'
          strokeWidth='2'
          strokeMiterlimit='10'
          fill='none'
        />
      </g>
      <defs>
        <clipPath id='clip0_12064_26577'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
});
