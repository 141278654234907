import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { StringHelper } from "@/common/helpers/string";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import {
  VehicleModificationDto,
  VehicleModificationSearchPaginatedDto,
} from "@/core/api/generated";

export type VehicleModificationAutocompleteOption = BaseAutocompleteOption<VehicleModificationDto>;

export const entityToOption = (
  data: VehicleModificationDto,
): VehicleModificationAutocompleteOption => ({
  id: data.id!,
  title: StringHelper.joinIntoString([data.name], ", ", {
    skipEmpty: true,
  }),
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface VehicleModificationAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<VehicleModificationDto> {
  searchFilters?: Partial<Omit<VehicleModificationSearchPaginatedDto, "search" | "includeIds">>;
}

export default function VehicleModificationAutocomplete({
  searchFilters,
  isPreload = true,
  ...otherProps
}: VehicleModificationAutocompleteProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200 }}
      entityToOption={entityToOption}
      isPreload={isPreload}
      request={{
        requestFunc:
          apiClient.vehicleModificationsApi.apiV1ReferenceDataVehiclesModificationsSearchPost,
        limit: 25,
        parameters: {
          vehicleModificationSearchPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          vehicleModificationSearchPaginatedDto: {
            ...params.vehicleModificationSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Modification'
      placeholder='Search for modification...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props} key={option.id}>
            <ListItemIcon>
              <AppIcon of='vehicle' />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography component='div' variant='body1'>
                  {option.title}
                </Typography>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}
