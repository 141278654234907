import React, { ComponentClass, FunctionComponent, ReactElement, ReactNode } from "react";

export class ReactHelper {
  /** Checks whether provided object is React class component. */
  public static isClassComponent(object: any | null | undefined): object is ComponentClass<any> {
    return !!object && typeof object === "function" && !!(object as any).prototype.isReactComponent;
  }

  /** Checks whether provided object is React function component. */
  public static isFunctionComponent(
    object: any | null | undefined,
  ): object is FunctionComponent<any> {
    return (
      !!object &&
      typeof object === "function" &&
      String(object).includes("return React.createElement")
    );
  }

  /** A wrapper for working with React.cloneElement with `ref` support */
  public static cloneWithRef<T>(
    element: React.ReactElement<any>,
    props: Partial<T & { ref?: React.Ref<any> }>,
  ) {
    return React.cloneElement(element as React.ReactElement<any>, props);
  }

  /** Checks whether provided object is React component. */
  public static isComponent(
    object: any | null | undefined,
  ): object is ComponentClass<any> | FunctionComponent<any> {
    return this.isClassComponent(object) || this.isFunctionComponent(object);
  }

  /** Checks whether provided object is React element (either a component or a DOM element). */
  public static isElement<P>(object: any | null | undefined): object is ReactElement<P> {
    return React.isValidElement<P>(object);
  }

  /** Creates new string with replaced value at index. */
  public static isEmptyNode(node: ReactNode | null | undefined) {
    throw new Error("Not implemented");
  }
}
