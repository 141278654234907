import { Box, IconButton, Typography } from "@mui/material";

import BaseHeader from "@/App/BaseApp/Header/BaseHeader";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIcon from "@/common/components/Icons/AppIcon";
import NexusIcon from "@/common/components/Icons/Svg/NexusIcon";
import { NavDisplayVariants, useNavDisplay } from "@/common/contexts/useNavDisplay";
import { AppPermission } from "@/core/api/generated";

import GlobalSearch from "./GlobalSearch/GlobalSearch";

export default function Header() {
  const { navDisplay, navToggle, navDisplayWidth } = useNavDisplay();
  return (
    <BaseHeader
      mainContent={
        <>
          <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                py: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flex: "0 0 300px",
                  zIndex: 2,
                  mr: 3,
                }}
              >
                <Box
                  sx={{
                    width: navDisplayWidth.collapsed,
                    pl: 3.5,
                    mr: 0.5,
                  }}
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      navToggle && navToggle({ variant: "button" });
                    }}
                    title={`${
                      navDisplay === NavDisplayVariants.collapsed ? "Expand" : "Collapse"
                    } navigation`}
                  >
                    <AppIcon fontSize='small' of='menu' />
                  </IconButton>
                </Box>
                <NexusIcon width='27' height='27' viewBox='0 0 27 27' />
                <Typography
                  component='div'
                  variant='h5'
                  sx={{ color: (t) => t.palette.background.logo?.text, pl: 1, fontWeight: 500 }} // unknown color
                >
                  Nexus
                </Typography>
                <Typography
                  component='div'
                  variant='h5'
                  sx={{ color: (t) => t.palette.background.logo?.text, fontWeight: 400 }} // unknown color
                >
                  Fleet
                </Typography>
              </Box>
              <GlobalSearch />
            </Box>
          </AuthorizedElement>
        </>
      }
    />
  );
}
