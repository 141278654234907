import { Box, Stack, Typography } from "@mui/material";

import { GeneralPriceSummaryDtoDetailedLocal } from "@/common/ts/lineItems";

import GeneralPriceSummaryDisplay from "./GeneralPriceSummaryDisplay";
import GeneralPriceSummaryInline from "./GeneralPriceSummaryInline";

export interface TotalPriceExplanationProps {
  summary: GeneralPriceSummaryDtoDetailedLocal;
}

/** Displays explanation that total = subTotal + tax + etc */
export default function TotalPriceExplanation({ summary }: TotalPriceExplanationProps) {
  return (
    <Stack spacing={1}>
      <GeneralPriceSummaryDisplay
        summary={summary}
        displayProps={{
          calcExplanationPopover: true,
          calcExplanationModal: false,
        }}
      />

      <Box>
        <Typography component='div' variant='subtitle1'>
          Calculation
        </Typography>
        <GeneralPriceSummaryInline summary={summary} />
      </Box>
    </Stack>
  );
}
