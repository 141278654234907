import { Box, Button, Stack, StackProps } from "@mui/material";
import _ from "lodash";
import { ReactNode, useMemo, useState } from "react";

import { TextHelper } from "@/common/helpers/text";

import AppIcon from "../../../Icons/AppIcon";

export interface TagListProps {
  tags: ReactNode[];
  direction?: StackProps["direction"];
  maxVisibleItems?: number;
  startAction?: ReactNode;
  endAction?: ReactNode;
  sx?: StackProps["sx"];
}

export default function TagList({
  tags,
  direction = "row",
  maxVisibleItems,
  startAction,
  endAction,
  sx,
}: TagListProps) {
  const [isAllVisible, setIsAllVisible] = useState(false);

  const visibleTags = useMemo(
    () => (_.isNil(maxVisibleItems) ? tags : tags.slice(0, maxVisibleItems)),
    [tags, maxVisibleItems],
  );
  const invisibleTags = useMemo(
    () => (_.isNil(maxVisibleItems) ? [] : tags.slice(maxVisibleItems, tags.length)),
    [tags, maxVisibleItems],
  );

  return (
    <Stack
      sx={{ backgroundColor: "transparent", display: "flex", minWidth: 0, flexWrap: "wrap", ...sx }}
      gap={0.5}
      direction={direction}
    >
      {startAction && <Box>{startAction}</Box>}

      {visibleTags?.map((tag, i) => <Box key={i}>{tag}</Box>)}

      {isAllVisible && invisibleTags?.map((tag, i) => <Box key={i}>{tag}</Box>)}

      {invisibleTags && invisibleTags.length > 0 && (
        <Button
          sx={{ p: 0 }}
          variant='text'
          size='small'
          color='text'
          onClick={() => setIsAllVisible(!isAllVisible)}
        >
          {isAllVisible ? "-" : "+"}
          {invisibleTags?.length}{" "}
          {TextHelper.pluralizeManual("tag", invisibleTags?.length || 0, "tags")}{" "}
          <AppIcon of={isAllVisible ? "expandLess" : "expandMore"} />
        </Button>
      )}

      {endAction && <Box sx={{ backgroundColor: "transparent" }}>{endAction}</Box>}
    </Stack>
  );
}
