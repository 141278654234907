import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid2,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";

import { CustomFormikSubProps } from "@/common/helpers/formik";
import { ContractSettingsInputDto } from "@/core/api/generated";

interface Props {
  formikProps: CustomFormikSubProps<ContractSettingsInputDto | null | undefined>;
  sx?: SxProps<Theme>;
}

export default function ContractSettingsInput({ formikProps, sx }: Props) {
  const { values, setFieldValue } = formikProps;

  return (
    <Grid2 container spacing={3} sx={sx}>
      {/* Titles */}
      <Grid2 container size={{ xs: 12 }} spacing={3} alignItems='center'>
        <Grid2 size={{ xs: 6 }}>
          <Typography variant='subtitle1' fontWeight='bold'>
            Contract timeline settings
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: 6 }}>
          <Typography variant='subtitle1' fontWeight='bold'>
            Check-In/Check-Out Settings
          </Typography>
        </Grid2>
      </Grid2>

      <Grid2 container size={{ xs: 12 }} spacing={3}>
        {/* Contract timeline settings */}
        <Grid2 size={{ xs: 6 }}>
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 6 }}>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values?.isRequireStartDate ?? false}
                      onChange={(e) => setFieldValue("isRequireStartDate", e.target.checked)}
                    />
                  }
                  label='Require contract start date'
                />
              </FormControl>
            </Grid2>
            <Grid2 size={{ xs: 6 }}>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values?.isRequireEndDate ?? false}
                      onChange={(e) => setFieldValue("isRequireEndDate", e.target.checked)}
                    />
                  }
                  label='Require contract end date'
                />
              </FormControl>
            </Grid2>
          </Grid2>
        </Grid2>

        {/* Check-In/Check-Out Settings */}
        <Grid2 size={{ xs: 6 }}>
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 6 }}>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values?.isCheckEnabled ?? false}
                      onChange={(e) => {
                        setFieldValue("isCheckEnabled", e.target.checked);
                        if (!e.target.checked) {
                          setFieldValue("isRequireVisualInspectionBeforeCheckOut", false);
                          setFieldValue("isRequireVisualInspectionBeforeCheckIn", false);
                        }
                      }}
                    />
                  }
                  label='Enable contract Check-In/Check-Out'
                />
              </FormControl>
            </Grid2>
            <Grid2 size={{ xs: 6 }}>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values?.isRequireVisualInspectionBeforeCheckOut ?? false}
                      disabled={!values?.isCheckEnabled}
                      onChange={(e) =>
                        setFieldValue("isRequireVisualInspectionBeforeCheckOut", e.target.checked)
                      }
                    />
                  }
                  label='Require visual inspection before check-out'
                />
              </FormControl>
            </Grid2>
            <Grid2 size={{ xs: 6 }}>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values?.isRequireVisualInspectionBeforeCheckIn ?? false}
                      disabled={!values?.isCheckEnabled}
                      onChange={(e) =>
                        setFieldValue("isRequireVisualInspectionBeforeCheckIn", e.target.checked)
                      }
                    />
                  }
                  label='Require visual inspection before check-in'
                />
              </FormControl>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
}
