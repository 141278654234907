import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { InviteAnonymousUserResultDto, PaginationDtoOfTenantInviteDto } from "@/core/api/generated";

import { AppThunk } from "../..";
import { apiClient } from "../../../core/api/ApiClient";

export interface TenantInvitesState {
  loading: {
    getPaginated?: boolean;
    get?: boolean;
    update?: boolean;
  };
  paginatedInvites?: PaginationDtoOfTenantInviteDto;
}

const initialState: TenantInvitesState = {
  loading: {},
  paginatedInvites: undefined,
};

export const tenantInvitesSlice = createSlice({
  name: "tenantInvites",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<TenantInvitesState["loading"]>) => {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
    _inviteDeleted: (state, action: PayloadAction<{ inviteId: string }>) => {
      if (state.paginatedInvites) {
        const index = state.paginatedInvites.items!.findIndex(
          (x) => x.id === action.payload.inviteId,
        );
        if (index !== -1) {
          state.paginatedInvites.items!.splice(index, 1);
        }
      }
    },
  },
});

export const { setLoading, _inviteDeleted } = tenantInvitesSlice.actions;

export const tenantInvitesReducer = tenantInvitesSlice.reducer;

export const inviteUser =
  (...args: Parameters<typeof apiClient.tenantInvitesApi.apiV1TenantInvitesPost>): AppThunk =>
  async (dispatch) => {
    await apiClient.tenantInvitesApi.apiV1TenantInvitesPost(...args);
  };

export const createAnonymousInvite =
  (
    ...args: Parameters<typeof apiClient.tenantInvitesApi.apiV1TenantInvitesAnonymousPost>
  ): AppThunk<Promise<InviteAnonymousUserResultDto>> =>
  async (dispatch) => {
    const response = await apiClient.tenantInvitesApi.apiV1TenantInvitesAnonymousPost(...args);
    return response.data;
  };

export const resendPersonalInvite =
  (
    ...args: Parameters<typeof apiClient.tenantInvitesApi.apiV1TenantInvitesInviteIdResendPost>
  ): AppThunk =>
  async (dispatch) => {
    await apiClient.tenantInvitesApi.apiV1TenantInvitesInviteIdResendPost(...args);
  };

export const deleteInvite =
  (
    ...args: Parameters<typeof apiClient.tenantInvitesApi.apiV1TenantInvitesInviteIdDelete>
  ): AppThunk =>
  async (dispatch) => {
    await apiClient.tenantInvitesApi.apiV1TenantInvitesInviteIdDelete(...args);
    dispatch(_inviteDeleted({ inviteId: args[0].inviteId }));
  };
