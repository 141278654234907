import { Button, IconButton, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { useHistory } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  EntityType,
  ImportDto,
  ImportEntityType,
  ImportGetPaginatedDto,
} from "@/core/api/generated";

const defaultDisplayProps = {
  viewVariant: ViewLayoutVariant.Page,
};
interface Props {
  displayProps?: Partial<typeof defaultDisplayProps>;
}

export default function Imports({ displayProps }: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const history = useHistory();
  const commonRequestParams = useCommonRequestParams<ImportGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.Import,
    },
  });

  const importsRequest = useApiRequest(
    apiClient.dataImportApi.apiV1DataImportGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      importGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );

  // const paginatedImports = useMemo(() => {
  //   return importsRequest.data
  //     ? importsRequest.data
  //     : {
  //         items: [],
  //         pagination: { currentPage: 1, limit: 0, offset: 0, totalCount: 0, totalPages: 0 },
  //       };
  // }, [importsRequest.data]);

  return (
    <ViewLayoutV2
      displayProps={displayProps}
      header={
        <SimpleViewPageHeader
          title='Imports'
          primaryActions={
            <AuthorizedElement permissions={[AppPermission.ImportManage]}>
              <Button
                variant='contained'
                color='primary'
                startIcon={<AppIcon of='add' />}
                onClick={() => history.push(ROUTE_PATH.MANAGEMENT_IMPORTS_CREATE())}
              >
                New import
              </Button>
            </AuthorizedElement>
          }
        />
      }
    >
      <DataTabular<ImportDto>
        columns={[
          {
            field: "entityType",
            title: "Import entity",
            flex: 1,
            renderCell: (item) => (
              <>
                {item.entityType === ImportEntityType.None ? (
                  "Not configured"
                ) : (
                  <InlineApiEnumValue type='ImportEntityType' value={item.entityType} />
                )}
              </>
            ),
          },
          {
            field: "status",
            title: "Status",
            flex: 1,
            renderCell: (item) => <InlineApiEnumValue type='ImportStatus' value={item.status} />,
          },
          {
            field: "createdAt",
            title: "Created",
            flex: 1,
            renderCell: (item) => (
              <Datetime datetime={item.createdAt} direction='column' withDurationFromNow />
            ),
          },
          {
            field: "updatedAt",
            title: "Updated",
            flex: 1,
            renderCell: (item) => (
              <Datetime datetime={item.updatedAt} direction='column' withDurationFromNow />
            ),
          },
        ]}
        rows={importsRequest.data?.items || []}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.MANAGEMENT_IMPORTS_VIEW(item.id)}
        renderRowAction={({ item }) => (
          <MenuWithTrigger
            trigger={
              <IconButton>
                <AppIcon of='moreVert' />
              </IconButton>
            }
          >
            {({ handleClose }) => [
              <MenuItem
                key='view'
                onClick={() => {
                  handleClose();
                  history.push(ROUTE_PATH.MANAGEMENT_IMPORTS_VIEW(item.id));
                }}
              >
                <ListItemIcon>
                  <AppIcon of='view' fontSize='small' />
                </ListItemIcon>
                <ListItemText>View</ListItemText>
              </MenuItem>,
              <AuthorizedElement key='edit' permissions={[AppPermission.ImportManage]}>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    history.push(ROUTE_PATH.MANAGEMENT_IMPORTS_EDIT(item.id));
                  }}
                >
                  <ListItemIcon>
                    <AppIcon of='edit' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Edit</ListItemText>
                </MenuItem>
              </AuthorizedElement>,
            ]}
          </MenuWithTrigger>
        )}
        isLoading={importsRequest.isLoading}
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
        refetch={commonRequestParams.dataTabularProps.refetch}
      />
    </ViewLayoutV2>
  );
}
