import TenantRequestPaginatedList from "@/common/components/Entity/TenantRequest/ListView/TenantRequestPaginatedList";
import { useLocationState } from "@/common/hooks/useLocationParams";
import { FilterDefinitionDto } from "@/core/api/generated";

export interface TenantRequestListPageLocationState {
  initialValues?: {
    filterDefinitionDto?: Nil<FilterDefinitionDto>;
  };
}

export default function TenantRequestsPage() {
  const locationState = useLocationState<TenantRequestListPageLocationState>();

  return (
    <>
      <TenantRequestPaginatedList
        displayProps={{
          breadcrumbs: true,
          filters: true,
        }}
        initialValues={locationState?.initialValues}
      />
    </>
  );
}
