import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function EditIcon(props: SvgIconProps, ref) {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <path d='M11 21H4C3.86867 21 3.73862 20.9742 3.61728 20.9239C3.49594 20.8737 3.38568 20.8 3.29282 20.7072C3.19995 20.6143 3.12629 20.5041 3.07605 20.3827C3.02581 20.2614 2.99997 20.1313 3 20V4C2.99997 3.86867 3.02581 3.73862 3.07605 3.61728C3.12629 3.49594 3.19995 3.38568 3.29282 3.29282C3.38568 3.19995 3.49594 3.12629 3.61728 3.07605C3.73862 3.02581 3.86867 2.99997 4 3H20C20.1313 2.99997 20.2614 3.02581 20.3827 3.07605C20.5041 3.12629 20.6143 3.19995 20.7072 3.29282C20.8 3.38568 20.8737 3.49594 20.9239 3.61728C20.9742 3.73862 21 3.86867 21 4V11C21 11.2652 20.8946 11.5196 20.7071 11.7071C20.5196 11.8946 20.2652 12 20 12C19.7348 12 19.4804 11.8946 19.2929 11.7071C19.1054 11.5196 19 11.2652 19 11V5H5V19H11C11.2652 19 11.5196 19.1054 11.7071 19.2929C11.8946 19.4804 12 19.7348 12 20C12 20.2652 11.8946 20.5196 11.7071 20.7071C11.5196 20.8946 11.2652 21 11 21Z' />
      <path d='M10 4V10L12 8L14 10V4H10Z' />
      <path d='M17 21C16.8687 21 16.7386 20.9742 16.6173 20.9239C16.4959 20.8737 16.3857 20.8 16.2928 20.7072C16.2 20.6143 16.1263 20.5041 16.0761 20.3827C16.0258 20.2614 16 20.1313 16 20V14C16 13.7348 16.1054 13.4804 16.2929 13.2929C16.4804 13.1054 16.7348 13 17 13C17.2652 13 17.5196 13.1054 17.7071 13.2929C17.8946 13.4804 18 13.7348 18 14V20C18 20.1313 17.9742 20.2614 17.9239 20.3827C17.8737 20.5041 17.8 20.6143 17.7072 20.7072C17.6143 20.8 17.5041 20.8737 17.3827 20.9239C17.2614 20.9742 17.1313 21 17 21Z' />
      <path d='M20 18H14C13.7348 18 13.4804 17.8946 13.2929 17.7071C13.1054 17.5196 13 17.2652 13 17C13 16.7348 13.1054 16.4804 13.2929 16.2929C13.4804 16.1054 13.7348 16 14 16H20C20.2652 16 20.5196 16.1054 20.7071 16.2929C20.8946 16.4804 21 16.7348 21 17C21 17.2652 20.8946 17.5196 20.7071 17.7071C20.5196 17.8946 20.2652 18 20 18Z' />
    </SvgIcon>
  );
});
