import {
  Alert,
  AlertTitle,
  Dialog,
  DialogContent,
  DialogProps,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";

import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { EnvHelper } from "@/common/helpers/env";
import { TypeHelper } from "@/common/helpers/type";
import { UrlHelper } from "@/common/helpers/url";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { clipboardService } from "@/common/services/clipboard";
import { ValidationInfo } from "@/common/validation";
import { ProblemDetails, ValidationProblemDetails } from "@/core/api/generated";

import AppTooltip from "../AppTooltip";
import BlockCode from "../Code/BlockCode";
import FoldableBlock from "../Display/FoldableBlock";
import FieldValue from "../Form/Display/FieldValue";
import AppIcon from "../Icons/AppIcon";

const defaultDisplayProps = {
  placeholder: true,
};

export interface OwnProps {
  problemDetails: ProblemDetails | ValidationProblemDetails | null | undefined;
  validationInfo: ValidationInfo | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  customContent?: ReactNode;
}

type Props = OwnProps & DialogProps;

export default function ValidationInfoModal({
  problemDetails,
  validationInfo,
  displayProps = defaultDisplayProps,
  customContent,
  ...dialogProps
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const { enqueueSnackbar } = useAppSnackbar();

  const problemDetailsComputed = problemDetails || validationInfo?.problemDetails;

  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Error details
      </AppModalTitle>

      <DialogContent>
        <Stack spacing={1.5} sx={{ overflowX: "hidden" }}>
          <Alert severity='info'>
            You can report the issue and help us to fix it much quicker. Please copy{" "}
            <strong>Operation ID</strong> below and send it to us along with the following info:
            <ol>
              <li>Page URL.</li>
              <li>Screenshots of the page where error occurred.</li>
            </ol>
            Thank you!
          </Alert>

          <Alert severity='error' sx={{ "& .MuiAlert-icon": { display: "none" } }}>
            <Stack sx={{ width: "100%" }} direction={"row"} justifyContent={"space-between"}>
              <AlertTitle sx={{ color: (t) => `${t.palette.text.main} !important` }}>
                Error
              </AlertTitle>

              <AlertTitle>{problemDetailsComputed?.title}</AlertTitle>
            </Stack>

            <Typography color='error'>
              {!TypeHelper.isEmpty(problemDetailsComputed) ? problemDetailsComputed?.detail : "-"}
            </Typography>
          </Alert>

          <Alert severity='error' sx={{ "& .MuiAlert-icon": { display: "none" } }}>
            <AlertTitle sx={{ color: (t) => `${t.palette.text.main} !important` }}>
              Validation error
            </AlertTitle>

            <Typography color='error'>
              {!TypeHelper.isEmpty(validationInfo?.generalError)
                ? validationInfo?.generalError
                : "-"}
            </Typography>
          </Alert>

          <FieldValue variant='filled' direction='column' label='Page URL'>
            <ViewContentBlock direction={"row"} gap={1} justifyContent={"space-between"}>
              {UrlHelper.getCurrentUrl()}

              <AppTooltip title='Copy'>
                <IconButton
                  size='small'
                  sx={{
                    p: 0.5,
                    fontSize: "1rem",
                    width: "fit-content",
                  }}
                  onClick={async () => {
                    try {
                      const valueText = UrlHelper.getCurrentUrl();
                      await clipboardService.writeText(valueText);
                      enqueueSnackbar("Value copied to clipboard.", {
                        variant: "success",
                      });
                    } catch (error) {
                      console.error("Clipboard copy error", error);
                    }
                  }}
                >
                  <AppIcon of='copy' />
                </IconButton>
              </AppTooltip>
            </ViewContentBlock>
          </FieldValue>

          <FieldValue
            variant='filled'
            direction='column'
            label='Operation ID'
            isEmpty={TypeHelper.isEmpty(problemDetailsComputed)}
          >
            <ViewContentBlock direction={"row"} gap={1} justifyContent={"space-between"}>
              {problemDetailsComputed && problemDetailsComputed["operationId"]}

              {problemDetailsComputed && (
                <AppTooltip title='Copy'>
                  <IconButton
                    size='small'
                    sx={{
                      p: 0.5,
                      fontSize: "1rem",
                      width: "fit-content",
                    }}
                    onClick={async () => {
                      try {
                        const valueText = problemDetailsComputed?.["operationId"];
                        await clipboardService.writeText(valueText);
                        enqueueSnackbar("Value copied to clipboard.", {
                          variant: "success",
                        });
                      } catch (error) {
                        console.error("Clipboard copy error", error);
                      }
                    }}
                  >
                    <AppIcon of='copy' />
                  </IconButton>
                </AppTooltip>
              )}
            </ViewContentBlock>
          </FieldValue>

          {customContent && <>{customContent}</>}

          {EnvHelper.isDevelopmentAny && (
            <ViewContentBlock variant='one-sided'>
              <Typography component='span' variant='subtitle2'>
                Debug info
              </Typography>

              <ViewContentBlock>
                <FoldableBlock
                  defaultIsFolded
                  trigger={{
                    label: <Typography>Show code</Typography>,
                    size: "medium",
                  }}
                >
                  <Stack spacing={1}>
                    <Alert severity='warning'>Shown on development envs only.</Alert>

                    <Stack spacing={1}>
                      <FieldValue
                        ellipsing={{
                          enabled: true,
                        }}
                        sx={{ maxWidth: "100%", overflow: "auto" }}
                        label={
                          <Stack direction={"row"}>
                            <span>Raw ProblemDetails from BE error response</span>

                            <AppTooltip title='Copy'>
                              <IconButton
                                size='small'
                                sx={{
                                  ml: "auto",
                                  p: 0.5,
                                  fontSize: "1rem",
                                  width: "fit-content",
                                }}
                                onClick={async () => {
                                  try {
                                    const valueText = JSON.stringify(
                                      problemDetailsComputed,
                                      null,
                                      4,
                                    );
                                    await clipboardService.writeText(valueText);
                                    enqueueSnackbar("Value copied to clipboard.", {
                                      variant: "success",
                                    });
                                  } catch (error) {
                                    console.error("Clipboard copy error", error);
                                  }
                                }}
                              >
                                <AppIcon of='copy' />
                              </IconButton>
                            </AppTooltip>
                          </Stack>
                        }
                        isEmpty={TypeHelper.isEmpty(problemDetailsComputed)}
                        direction='column'
                      >
                        <BlockCode
                          sx={{
                            boxShadow: "none",
                            borderRadius: (t) => t.shapeCustom.borderRadiusCard,
                            overflowX: "auto",
                          }}
                        >
                          {JSON.stringify(problemDetailsComputed, null, 4)}
                        </BlockCode>
                      </FieldValue>

                      <FieldValue
                        ellipsing={{
                          enabled: true,
                        }}
                        label={
                          <Stack direction={"row"}>
                            <span>Exception message</span>

                            <AppTooltip title='Copy'>
                              <IconButton
                                size='small'
                                sx={{
                                  ml: "auto",
                                  p: 0.5,
                                  fontSize: "1rem",
                                  width: "fit-content",
                                }}
                                onClick={async () => {
                                  try {
                                    const valueText =
                                      problemDetailsComputed?.debug?.exceptionMessage;
                                    await clipboardService.writeText(valueText);
                                    enqueueSnackbar("Value copied to clipboard.", {
                                      variant: "success",
                                    });
                                  } catch (error) {
                                    console.error("Clipboard copy error", error);
                                  }
                                }}
                              >
                                <AppIcon of='copy' />
                              </IconButton>
                            </AppTooltip>
                          </Stack>
                        }
                        isEmpty={TypeHelper.isEmpty(
                          problemDetailsComputed?.debug?.exceptionMessage,
                        )}
                        direction='column'
                      >
                        <BlockCode
                          sx={{
                            width: "100%",
                            boxShadow: "none",
                            borderRadius: (t) => t.shapeCustom.borderRadiusCard,
                          }}
                        >
                          {problemDetailsComputed?.debug?.exceptionMessage}
                        </BlockCode>
                      </FieldValue>

                      <FieldValue
                        ellipsing={{
                          enabled: true,
                        }}
                        sx={{ maxWidth: "100%", overflow: "auto" }}
                        label={
                          <Stack direction={"row"}>
                            <span>Stack trace</span>

                            <AppTooltip title='Copy'>
                              <IconButton
                                size='small'
                                sx={{
                                  ml: "auto",
                                  p: 0.5,
                                  fontSize: "1rem",
                                  width: "fit-content",
                                }}
                                onClick={async () => {
                                  try {
                                    const valueText = problemDetailsComputed?.debug?.stackTrace;
                                    await clipboardService.writeText(valueText);
                                    enqueueSnackbar("Value copied to clipboard.", {
                                      variant: "success",
                                    });
                                  } catch (error) {
                                    console.error("Clipboard copy error", error);
                                  }
                                }}
                              >
                                <AppIcon of='copy' />
                              </IconButton>
                            </AppTooltip>
                          </Stack>
                        }
                        isEmpty={TypeHelper.isEmpty(problemDetailsComputed?.debug?.stackTrace)}
                        direction='column'
                      >
                        <BlockCode
                          sx={{
                            boxShadow: "none",
                            borderRadius: (t) => t.shapeCustom.borderRadiusCard,
                            overflowX: "auto",
                          }}
                        >
                          {problemDetailsComputed?.debug?.stackTrace}
                        </BlockCode>
                      </FieldValue>
                    </Stack>
                  </Stack>
                </FoldableBlock>
              </ViewContentBlock>
            </ViewContentBlock>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
