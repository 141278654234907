import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { apiClient } from "@/core/api/ApiClient";
import { PaginationDtoOfChatEventDto } from "@/core/api/generated";
import { AppThunk } from "@/store";

export interface ChatEventsState {
  loading: {
    getPaginated?: boolean;
  };
  paginatedEvents?: PaginationDtoOfChatEventDto;
}

const initialState: ChatEventsState = {
  loading: {},
};

const tenantsSlice = createSlice({
  name: "chatEvents",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<ChatEventsState["loading"]>) => {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
    _chatEventsFetched: (state, action: PayloadAction<PaginationDtoOfChatEventDto>) => {
      state.paginatedEvents = action.payload;
    },
    resetChatEvents: (state, action: PayloadAction<undefined>) => {
      state.paginatedEvents = undefined;
    },
  },
});

export const { setLoading, _chatEventsFetched, resetChatEvents } = tenantsSlice.actions;

export const chatEventsReducer = tenantsSlice.reducer;

export const getChatEvents =
  (
    ...arg: Parameters<typeof apiClient.chatEventsApi.apiV1ChatsChatIdEventsGetPost>
  ): AppThunk<Promise<PaginationDtoOfChatEventDto>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        getPaginated: true,
      }),
    );

    try {
      const response = await apiClient.chatEventsApi.apiV1ChatsChatIdEventsGetPost(...arg);
      await dispatch(_chatEventsFetched(response.data));
      return response.data;
    } finally {
      dispatch(
        setLoading({
          getPaginated: false,
        }),
      );
    }
  };
