import { useParams } from "react-router";

import PoolView from "@/common/components/Entity/Pool/View/PoolView";

export default function PoolViewPage() {
  const { poolId } = useParams<{ poolId?: string }>();

  return (
    <>
      <PoolView
        poolId={poolId}
        pool={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
