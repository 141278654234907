import { FormControl, FormHelperText, Stack, SxProps, Theme } from "@mui/material";

import { CustomFormikSubProps, FormikHelper } from "@/common/helpers/formik";
import { renderIf } from "@/common/helpers/render/renderIf";
import {
  AssetEntityType,
  AssetSpecDto,
  AssetSpecInputDto,
  VehicleSpecDto,
} from "@/core/api/generated";

import ApiEnumAutocomplete from "../../Enum/ApiEnumAutocomplete";
import VehicleSpecInput, {
  vehicleSpecInputDtoFormatBeforePost,
  vehicleSpecToInputDto,
} from "../Vehicle/Inputs/VehicleSpecInput";

export function assetSpecToInputDto(
  spec: AssetSpecDto | AssetSpecInputDto | null | undefined,
): AssetSpecInputDto | undefined {
  if (!spec) {
    return undefined;
  }

  return {
    ...spec,
    vehicle: vehicleSpecToInputDto(spec?.vehicle),
  };
}

export function assetSpecToInputDtoFormatBeforePost(
  spec: AssetSpecInputDto | null | undefined,
): AssetSpecInputDto | undefined {
  if (!spec) {
    return undefined;
  }

  return {
    ...spec,
    vehicle: vehicleSpecInputDtoFormatBeforePost(spec?.vehicle),
  };
}

export function entitySpecToAssetSpecDto(
  entityType: AssetEntityType,
  spec: VehicleSpecDto | null | undefined,
): AssetSpecDto | undefined {
  return {
    entityType: entityType,
    vehicle: entityType === AssetEntityType.Vehicle ? spec || undefined : undefined,
  };
}

const defaultDisplayProps = {
  editEntityType: true,
  enableEditEntityType: true,
};

interface Props {
  formikProps: CustomFormikSubProps<AssetSpecInputDto | null | undefined>;
  displayProps?: Partial<typeof defaultDisplayProps>;
  onEntityTypeChange?: (enwValue?: AssetEntityType) => void;
  sx?: SxProps<Theme>;
}

/** Input for duration that works on Moment.js Duration. */
export default function AssetSpecInput({
  formikProps,
  displayProps = defaultDisplayProps,
  onEntityTypeChange,
  sx,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const { values, errors, setFieldValue } = formikProps;

  const vehicleFormikProps = FormikHelper.getSubProps(formikProps, "vehicle", (v) => v.vehicle);

  return (
    <Stack sx={sx}>
      {/* AssetEntityType */}
      {displayProps.editEntityType && (
        <FormControl margin='dense' fullWidth>
          <ApiEnumAutocomplete
            type='AssetEntityType'
            value={values?.entityType}
            excludeEnumValues={[AssetEntityType.Accessory]} // exclude not implemented
            onChange={(newValue) => {
              setFieldValue("entityType", newValue);
              onEntityTypeChange && onEntityTypeChange(newValue || undefined);
            }}
            label='Entity type'
            required
            disabled={!displayProps.enableEditEntityType}
            textFieldProps={{
              error: Boolean(errors?.entityType),
              helperText: errors?.entityType,
            }}
          />
        </FormControl>
      )}

      {/* Spec */}
      {values?.entityType && (
        <>
          {renderIf()
            .if(values.entityType === AssetEntityType.Vehicle)
            .then(<VehicleSpecInput formikProps={vehicleFormikProps} />)
            .else(<FormHelperText error>Unknown asset type {values.entityType}</FormHelperText>)
            .render()}
        </>
      )}
    </Stack>
  );
}
