import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function EditIcon(props: SvgIconProps, ref) {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <path d='M6.97987 20.8004V22.3064C6.97987 23.1485 6.29971 23.8124 5.47387 23.8124C4.64803 23.8124 3.96787 23.1485 3.96787 22.3064V20.8004H2.44556V15.8774C2.44556 14.7925 3.17428 13.9017 4.16212 13.6265L5.92724 9.4322C6.18637 8.81683 6.78553 8.37958 7.49803 8.37958H16.5181C17.2306 8.37958 17.8298 8.81683 18.0889 9.4322L19.8379 13.6265C20.8257 13.9018 21.5544 14.7925 21.5544 15.8774V20.8004H20.016V22.3064C20.016 23.1485 19.3358 23.8124 18.51 23.8124C17.6679 23.8124 17.004 23.1485 17.004 22.3064V20.8004H6.97987ZM5.57099 18.096C6.41306 18.096 7.09321 17.4159 7.09321 16.5738C7.09321 15.7317 6.41306 15.0516 5.57099 15.0516C4.72893 15.0516 4.04878 15.7317 4.04878 16.5738C4.04878 17.4159 4.72884 18.096 5.57099 18.096ZM19.8541 16.5738C19.8541 15.7317 19.1739 15.0516 18.3319 15.0516C17.4898 15.0516 16.8097 15.7317 16.8097 16.5738C16.8097 17.4159 17.4898 18.096 18.3319 18.096C19.1739 18.096 19.8541 17.4159 19.8541 16.5738ZM18.3319 13.5455L16.8259 9.91801C16.7611 9.73989 16.583 9.61033 16.3724 9.61033H7.62759C7.43324 9.61033 7.25512 9.73989 7.19034 9.91801L5.68434 13.5455H18.3319Z' />
      <path d='M4.38452 3.39681L6.78133 1H18.5857C19.3756 1 19.3845 1.79884 19.3845 1.79884C19.3845 1.79884 19.3753 4.19564 19.3845 4.99477C19.3756 5.79361 18.5857 5.79361 18.5857 5.79361H6.78133L4.38452 3.39681ZM7.67008 3.39681C7.66117 2.99265 7.35611 2.68759 6.96086 2.68759C6.5656 2.68759 6.25164 3.00155 6.25164 3.39681C6.25164 3.79206 6.5656 4.10602 6.96086 4.10602C7.35611 4.10602 7.67008 3.79206 7.67008 3.39681Z' />
    </SvgIcon>
  );
});
