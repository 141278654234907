import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function DownloadIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      width='18'
      height='18'
      viewBox='0 0 18 18'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.00284 9.5759C7.00096 9.55084 7 9.52553 7 9.5V1.5C7 0.948 7.447 0.5 8 0.5C8.553 0.5 9 0.948 9 1.5V9.49982L11.4 7.7C11.842 7.367 12.469 7.458 12.8 7.9C13.132 8.342 13.042 8.969 12.6 9.3L8.6 12.3C8.423 12.433 8.211 12.5 8 12.5C7.799 12.5 7.598 12.439 7.425 12.318L3.425 9.504C2.973 9.186 2.864 8.562 3.182 8.111C3.5 7.659 4.123 7.55 4.575 7.868L7.00284 9.5759ZM2 14.5V15.5H14V14.5C14 13.95 14.45 13.5 15 13.5C15.55 13.5 16 13.95 16 14.5V16.5C16 17.05 15.55 17.5 15 17.5H1C0.45 17.5 0 17.05 0 16.5V14.5C0 13.95 0.45 13.5 1 13.5C1.55 13.5 2 13.95 2 14.5Z'
      />
    </SvgIcon>
  );
});
