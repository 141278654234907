import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useEffect } from "react";

import useAppSnackbar from "./common/hooks/useAppSnackbar";
import { firebaseConfig } from "./config/config";
import { apiClient } from "./core/api/ApiClient";

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
let isTokenRegistrationInProgress = false;

let registration: ServiceWorkerRegistration;
export async function registerSW() {
  if ("serviceWorker" in navigator) {
    console.log("Firebase SW registration started");
    try {
      registration = await navigator.serviceWorker.register(
        `/firebase-messaging-sw.js?firebaseConfig=${JSON.stringify(firebaseConfig)}`,
      );
      console.log("Firebase SW registration successful, scope is:", registration.scope);
    } catch (err) {
      console.error("Firebase SW registration failed, error:", err);
    }
  }
}

export const useFirebaseMessaging = (isAuthenticated?: boolean) => {
  const { enqueueSnackbar } = useAppSnackbar();
  useEffect(() => {
    (async function () {
      await registerSW();
      if (isAuthenticated) {
        try {
          if (isTokenRegistrationInProgress) {
            console.log("Token registration is in progress, skipping...");
            return;
          }
          isTokenRegistrationInProgress = true;
          const currentToken = await getToken(messaging, {
            serviceWorkerRegistration: registration,
            vapidKey: firebaseConfig.vapidKey,
          });
          if (currentToken) {
            console.log("Current token for client: ", currentToken);
            await apiClient.deviceTokensApi.apiV1DeviceTokensPost({
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              registerDeviceTokenDto: { token: currentToken },
            });
            onMessage(messaging, (payload) => {
              console.log("Push notification arrived(active): ", payload);
              // todo: add custom variant to notistack provider, to customize popups: https://notistack.com/features/customization#custom-component
              // to be able to redirect and etc.

              if (payload.data) {
                const messageId = payload.data["MessageId"];

                apiClient.webPushWebhookApi.apiV1WebHookWebPushPost({
                  webPushWebhookDto: {
                    messageId: messageId,
                    messageStatus: "Delivered",
                  },
                });
              }
              enqueueSnackbar(`${payload.notification?.title}. ${payload.notification?.body}`, {
                variant: "info",
              });
            });
          } else {
            console.log("No registration token available. Request permission to generate one.");
            // todo: notistack with actions to call requestPermission
          }
          isTokenRegistrationInProgress = false;
        } catch (err) {
          console.log("An error occurred while retrieving token. ", err);
          // todo: if permissions error notify user about perrmissions required
          // notistack with actions to call requestPermission
        }
      }
    })();
  }, [isAuthenticated]);
};

// function requestPermission() {
//   console.log("Requesting permission...");
//   Notification.requestPermission().then((permission) => {
//     if (permission === "granted") {
//       console.log("Notification permission granted.");
//       // todo: retry getToken()
//     } else {
//       console.log("Unable to get permission to notify.");
//     }
//   });
// }
