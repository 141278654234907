import { Dialog, DialogProps } from "@mui/material";
import React, { ReactElement } from "react";

import { ReactHelper } from "@/common/helpers/react";

export type AppModalTrigger = ((isModalOpened: boolean) => ReactElement) | ReactElement;

export interface AppModalBaseProps {
  open: boolean;
  onClose?: (...args: any[]) => any;
  onOpen?: (...args: any[]) => any;
  trigger?: AppModalTrigger;
}

export interface AppModalProps
  extends AppModalBaseProps,
    Omit<DialogProps, "onClose" | "open" | "children"> {
  children: ((handleClose?: () => void) => ReactElement) | React.ReactNode;
}

export default function AppModal({
  trigger,
  children,
  open,
  onClose = () => {},
  onOpen = () => {},
  ...otherProps
}: AppModalProps) {
  const handleOpen = () => {
    onOpen();
  };
  const handleClose = () => {
    onClose();
  };

  if (typeof trigger === "function") {
    trigger = trigger(open) as ReactElement;
  }

  const triggerProps = {
    onClick: (e: Event) => {
      e.stopPropagation();

      if (open) {
        handleClose();
      } else {
        handleOpen();
      }
    },
  };

  return (
    // use React.Fragment to render trigger directly without wrapping
    <>
      {!!trigger && ReactHelper.cloneWithRef(trigger as ReactElement, triggerProps)}

      <Dialog {...otherProps} open={open} onClose={handleClose}>
        {typeof children === "function" ? children(handleClose) : children}
      </Dialog>
    </>
  );
}
