import { Box, SxProps, Theme } from "@mui/material";

import AppAvatar, { AppAvatarProps } from "@/common/components/Avatar/AppAvatar";
import { ChatParticipantDto } from "@/core/api/generated";

interface Props {
  participant?: ChatParticipantDto;
  withAvatar?: boolean;
  avatarProps?: AppAvatarProps;
  sx?: SxProps<Theme>;
}

/** Inline brief information about chat participant. */
export default function InlineChatParticipant({
  participant,
  withAvatar,
  avatarProps = {},
  sx,
}: Props) {
  const text = participant?.personName?.name || participant?.email;
  const avatarSize = 20;

  const content = (
    <Box component='span' sx={sx}>
      {participant && (
        <>
          {withAvatar && (
            <AppAvatar
              size={avatarSize}
              participant={participant}
              withPopover={false}
              sx={{ mr: 0.5 }}
              {...avatarProps}
            />
          )}
          <Box component='span'>{text}</Box>
        </>
      )}
    </Box>
  );

  return content;
}
