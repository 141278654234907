import { Button, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { useCallback, useState } from "react";

import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import { FileHelper } from "@/common/helpers/file";
import { HttpHelper } from "@/common/helpers/http";
import { MimeTypeHelper } from "@/common/helpers/mimeType";
import { apiClient } from "@/core/api/ApiClient";
import { ImportEntityType } from "@/core/api/generated";

interface Props {
  onDownloadDone?: () => void;
}

export default function ExampleImportFileDownloadForm({ onDownloadDone }: Props) {
  const [entityType, setEntityType] = useState<ImportEntityType | undefined>(undefined);
  const [mimeType, setMimeType] = useState<string | undefined>(undefined);

  const handleDownload = useCallback(async () => {
    const response = await apiClient.dataImportApi.apiV1DataImportFileExampleGet(
      {
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
        entityType,
        mimeType,
      },
      {
        responseType: "blob",
      },
    );

    const parsedContent = HttpHelper.parseContentHeaders(response.headers);

    const downloadedFile = {
      blob: response.data as Blob,
      contentType: parsedContent.contentType!,
      filename: parsedContent.contentDispositionInfo?.directives?.filename,
    };

    await FileHelper.downloadBlobFromApiResult(downloadedFile);
    onDownloadDone && onDownloadDone();
  }, [entityType, mimeType, onDownloadDone]);

  return (
    <Stack spacing={2} sx={{ alignItems: "center" }}>
      <FormControl sx={{ width: 400 }}>
        <InputLabel>Entity type</InputLabel>
        <Select
          label='Entity type'
          value={entityType ?? ""}
          onChange={(e) => setEntityType(e.target.value as ImportEntityType)}
        >
          {Object.values(ImportEntityType)
            .filter((type) => type !== ImportEntityType.None)
            .map((type) => (
              <MenuItem key={type} value={type}>
                <InlineApiEnumValue type='ImportEntityType' value={type} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: 400 }}>
        <InputLabel>File format</InputLabel>
        <Select
          label='File format'
          value={mimeType ?? ""}
          onChange={(e) => setMimeType(e.target.value)}
        >
          {MimeTypeHelper.getSpreadsheetFormats().map((format) => (
            <MenuItem key={format.mimeType} value={format.mimeType}>
              {format.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant='contained'
        disabled={!entityType || !mimeType}
        onClick={handleDownload}
        sx={{ width: 200 }}
      >
        Download
      </Button>
    </Stack>
  );
}
