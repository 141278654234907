import {
  Alert,
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios, { AxiosError } from "axios";
import { useState } from "react";

import InlineCode from "@/common/components/Code/InlineCode";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import AppLink from "@/common/components/Link/AppLink";
import { useOverflowDetector } from "@/common/hooks/useOverflowDetector";

export default function DevMisc() {
  const overflowDetector = useOverflowDetector({
    onChange: (params) => {
      console.log("On overflow change:", params);
    },
  });

  const [networkError, setNetworkError] = useState<AxiosError | null>(null);
  const [overflowContainerText, setOverflowContainerText] = useState<string | undefined>(
    `Lorem ipsum, dolor sit amet consectetur adipisicing elit.`,
  );
  const [overflowContainerWidth, setOverflowContainerWidth] = useState<number | undefined>(
    undefined,
  );

  const callApi = async () => {
    try {
      await axios.get("https://unexistingapi123123dfdsf.example/api/v1/test");
    } catch (err) {
      console.error("API error:", err);
      setNetworkError(err as AxiosError);
    }
  };

  return (
    <Stack spacing={4}>
      <Typography component='div' variant='h4'>
        Misc (hooks, test things, etc)
      </Typography>

      <Stack spacing={1}>
        <Typography component='div' variant='h5'>
          Internet connection
        </Typography>
        <Box>
          <Button variant='contained' onClick={callApi}>
            Call API and get Network Error
          </Button>

          <FormHelperText error>
            {networkError?.code} {networkError?.message}
          </FormHelperText>
        </Box>
      </Stack>

      <Stack spacing={1}>
        <Typography component='div' variant='h5'>
          <InlineCode>useOverflowDetector</InlineCode>
        </Typography>
        <Stack spacing={1}>
          <Typography component='div' variant='caption'>
            See overflow changes in console.
          </Typography>

          <FormControl>
            <TextField
              type='text'
              label='Container text'
              size='small'
              value={overflowContainerText ?? ""}
              onChange={(e) => setOverflowContainerText(e.target.value)}
            />
          </FormControl>

          <FormControl>
            <TextField
              inputMode='numeric'
              label='Container width (no value == none)'
              size='small'
              value={overflowContainerWidth ?? ""}
              onChange={(e) =>
                setOverflowContainerWidth(e.target.value ? +e.target.value : undefined)
              }
            />
          </FormControl>

          <Typography component='div' variant='body1'>
            Overflow container:{" "}
            <Chip
              label={
                <>
                  Overflows: <BooleanValue value={overflowDetector.isOverflows} />
                </>
              }
            />
          </Typography>
          <Box
            ref={overflowDetector.ref}
            sx={{
              width: overflowContainerWidth,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              background: "beige",
              border: "1px solid red",
            }}
          >
            <Stack spacing={2}>
              <Box>{overflowContainerText}</Box>

              <AppLink to='#'>{overflowContainerText}</AppLink>

              <Alert severity='warning'>{overflowContainerText}</Alert>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}
