import { Box, FormHelperText, SxProps, Theme } from "@mui/material";
import "react-international-phone/style.css";

import { renderIf } from "@/common/helpers/render/renderIf";
import { EntityType, GeneralEntitySubTypeDto } from "@/core/api/generated";

import InlineApiEnumValue from "../../../Enum/InlineApiEnumValue";
import ApiEnumIcon from "../../../Icons/ApiEnumIcon";

interface Props {
  value: GeneralEntitySubTypeDto | null | undefined;
  withIcon?: boolean;
  sx?: SxProps<Theme>;
}

export default function GeneralEntitySubTypeInline({ value, withIcon = true, sx }: Props) {
  if (!value) {
    return null;
  }

  return (
    <Box component='span' sx={sx}>
      {renderIf()
        .if(value.entityType === EntityType.Asset)
        .then(
          <>
            {withIcon && (
              <>
                <ApiEnumIcon type='EntityType' value={value.assetEntityType} inText />{" "}
              </>
            )}
            <InlineApiEnumValue type='AssetEntityType' value={value.assetEntityType} />
          </>,
        )
        .elseif(value.entityType === EntityType.PoolItem)
        .then(
          <>
            {withIcon && (
              <>
                <ApiEnumIcon type='EntityType' value={value.poolItemEntityType} inText />{" "}
              </>
            )}
            <InlineApiEnumValue type='PoolItemEntityType' value={value.poolItemEntityType} />
          </>,
        )
        .else(<FormHelperText error>No sub entity type for {value.entityType}.</FormHelperText>)
        .render()}
    </Box>
  );
}
