import {
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useMemo, useState } from "react";

import { NegotiationHelper } from "@/common/helpers/entity/negotiation";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper, ValidationInfo } from "@/common/validation";
import {
  ApprovalResponseType,
  NegotiationDto,
  NegotiationProposalDto,
  UserPartiesMembershipDto,
} from "@/core/api/generated";
import * as negotiationsSlice from "@/store/communication/negotiationsSlice";

import AppIcon from "../../../Icons/AppIcon";
import AppModalTitle from "../../../Modals/AppModalTitle";

export interface OwnProps {
  negotiation?: NegotiationDto | null;
  proposal: NegotiationProposalDto;
  partiesMembership?: UserPartiesMembershipDto | null;
}

type Props = OwnProps & DialogProps;

function RespondOnNegotiationProposalModal({
  negotiation,
  proposal,
  partiesMembership,

  ...dialogProps
}: Props) {
  const { enqueueSnackbar } = useAppSnackbar();
  const thunkDispatch = useAppThunkDispatch();
  const allowedActions = useAppSelector(
    (x) =>
      x.communication.negotiations.negotiationAllowedActionsMap[negotiation?.id || ""] || undefined,
  );

  const [validation, setValidation] = useState(new ValidationInfo());
  const [isRespondingMap, setIsRespondingMap] = useState<Record<ApprovalResponseType, boolean>>(
    {} as Record<ApprovalResponseType, boolean>,
  );

  const responseMap = useMemo(
    () => NegotiationHelper.getProposalResponseMap(proposal, partiesMembership),
    [partiesMembership, proposal],
  );
  const isResponded = useMemo(
    () => NegotiationHelper.isRespondedOnProposal(proposal, partiesMembership),
    [partiesMembership, proposal],
  );

  const canRespond = NegotiationHelper.canRespondOnProposal({
    proposal,
    allowedActions,
    partiesMembership,
  });
  const canChangeResponse = NegotiationHelper.canChangeProposalResponse({
    proposal,
    allowedActions,
    partiesMembership,
  });

  const respondOnProposal = async (responseType: ApprovalResponseType) => {
    setIsRespondingMap({
      ...isRespondingMap,
      [responseType]: true,
    });

    try {
      await thunkDispatch(
        negotiationsSlice.respondOnNegotiationProposal({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          negotiationId: negotiation!.id!,
          respondOnNegotiationProposalDto: {
            proposalId: proposal?.id,
            responseType,
          },
        }),
      );
      setValidation(validation.clear());
      enqueueSnackbar("Your response saved.", { variant: "success" });
      dialogProps && dialogProps.onClose && dialogProps.onClose({}, "escapeKeyDown");
    } catch (err) {
      setValidation(ValidationHelper.handleApiErrorResponse(err));
    } finally {
      setIsRespondingMap({
        ...isRespondingMap,
        [responseType]: false,
      });
    }
  };

  return (
    <Box>
      <Dialog fullWidth maxWidth='sm' {...dialogProps}>
        <AppModalTitle
          onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
        >
          <Typography component='div' variant='h6'>
            Respond on negotiation proposal
          </Typography>
        </AppModalTitle>
        <DialogContent>
          <Stack direction='column' spacing={1} sx={{ flex: 1, width: "100%" }}>
            <Stack direction='row' spacing={1}>
              <Button
                disabled={!canRespond}
                loading={isRespondingMap[ApprovalResponseType.Approve]}
                size='small'
                color='success'
                variant={responseMap[ApprovalResponseType.Approve] ? "contained" : "outlined"}
                startIcon={<AppIcon of='approve' />}
                sx={{ flex: 1 }}
                onClick={() => respondOnProposal(ApprovalResponseType.Approve)}
              >
                Approve
              </Button>
              <Button
                disabled={!canRespond}
                loading={isRespondingMap[ApprovalResponseType.Decline]}
                size='small'
                color='error'
                variant={responseMap[ApprovalResponseType.Decline] ? "contained" : "outlined"}
                startIcon={<AppIcon of='decline' />}
                sx={{ flex: 1 }}
                onClick={() => respondOnProposal(ApprovalResponseType.Decline)}
              >
                Decline
              </Button>
            </Stack>
            {!canRespond && (
              <Typography component='div'>{`You're not allowed to respond on this proposal.`}</Typography>
            )}
            {!canChangeResponse && (
              <Typography component='div' variant='caption' color='text.secondary' sx={{ my: 1 }}>
                {`After submitting your response you won't be able to change it.`}
              </Typography>
            )}
            {canChangeResponse && !isResponded && (
              <Typography component='div' variant='caption' color='text.secondary' sx={{ my: 1 }}>
                You can change your response.
              </Typography>
            )}
            {canChangeResponse && isResponded && (
              <Typography component='div' variant='caption' color='text.secondary' sx={{ my: 1 }}>
                You have already responded, but you can change your response.
              </Typography>
            )}

            {allowedActions?.canForceRespondOnProposal && (
              <Box>
                <Typography component='div' variant='subtitle1'>
                  Advanced options (only for authorized party)
                </Typography>
                <Typography component='div' variant='caption' color='text.secondary' sx={{ mb: 1 }}>
                  Force response will close the proposal without waiting for other responses.
                </Typography>

                <Stack direction='row' spacing={1}>
                  <Button
                    disabled={!canRespond}
                    loading={isRespondingMap[ApprovalResponseType.ForceApprove]}
                    size='small'
                    color='success'
                    variant={
                      responseMap[ApprovalResponseType.ForceApprove] ? "contained" : "outlined"
                    }
                    startIcon={<AppIcon of='approve' />}
                    sx={{ flex: 1 }}
                    onClick={() => respondOnProposal(ApprovalResponseType.ForceApprove)}
                  >
                    Force Approve!
                  </Button>
                  <Button
                    disabled={!canRespond}
                    loading={isRespondingMap[ApprovalResponseType.ForceDecline]}
                    size='small'
                    color='error'
                    variant={
                      responseMap[ApprovalResponseType.ForceDecline] ? "contained" : "outlined"
                    }
                    startIcon={<AppIcon of='decline' />}
                    sx={{ flex: 1 }}
                    onClick={() => respondOnProposal(ApprovalResponseType.ForceDecline)}
                  >
                    Force Decline!
                  </Button>
                </Stack>
              </Box>
            )}
          </Stack>

          {validation.hasErrors && (
            <Box sx={{ my: 1 }}>
              <FormHelperText error>{validation.getErrorsAsString()}</FormHelperText>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default RespondOnNegotiationProposalModal;
