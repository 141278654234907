import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import WheelOperationCreateUpdate from "@/common/components/Entity/WheelOperation/WheelOperationCreateUpdate";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";

export interface WheelOperationCreateUpdateQueryParams extends GeneralQueryParams {
  vehicleId?: string | null;
}

export default function WheelOperationCreateUpdatePage() {
  const { wheelOperationId } = useParams<{ wheelOperationId?: string }>();
  const { vehicleId } = useQueryParams<WheelOperationCreateUpdateQueryParams>();
  const history = useHistory();
  const isCreate = !wheelOperationId;
  // const isEdit = !!wheelOperationId;

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader
          title={isCreate ? "Create new wheel operation" : "Edit wheel operation"}
        />
      }
    >
      <WheelOperationCreateUpdate
        wheelOperationId={wheelOperationId}
        defaultValues={{
          vehicleId: vehicleId || undefined,
        }}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.WHEEL_OPERATION_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}
