import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormHelperText,
  ModalProps,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";

import ContractAutocomplete from "@/common/components/Entity/Contract/ContractAutocomplete";
import ContractLink from "@/common/components/Entity/Contract/ContractLink";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { AccessoryCheckDto, ContractDto, ContractStage } from "@/core/api/generated";

import GeneralValidationError from "../../Error/GeneralValidationError";
import HelpTooltipIcon from "../../Icons/HelpTooltipIcon";

type Props = Pick<ModalProps, "open" | "onClose"> & {
  accessoryCheck: AccessoryCheckDto;
  onSaved?: (contract: ContractDto) => void;
};

function AddAccessoryCheckToContractModal({ open, onClose, accessoryCheck, onSaved }: Props) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const [contract, setContract] = useState<ContractDto | undefined>(undefined);

  return (
    <Dialog fullWidth maxWidth='md' open={open} onClose={onClose}>
      <AppModalTitle onCloseClicked={() => onClose && onClose({}, "escapeKeyDown")}>
        Add accessory check to a contract
      </AppModalTitle>
      <DialogContent>
        {accessoryCheck.contract && (
          <Alert severity='info' sx={{ mb: 1 }}>
            This accessory check is already added to the contract{" "}
            <ContractLink entity={accessoryCheck.contract} />
          </Alert>
        )}

        <DialogContentText
          sx={{ mb: 1 }}
        >{`Make the accessory check visible for the selected contract.`}</DialogContentText>

        <Box>
          <Formik<
            BaseFormikValues & {
              contractId: string;
              isSendToCustomer: boolean;
            }
          >
            enableReinitialize
            initialValues={{
              contractId: accessoryCheck.contract?.id || "",
              isSendToCustomer: true,
              submit: "",
            }}
            validationSchema={Yup.object().shape({
              // contractId: Yup.string().required("Required"),
            })}
            onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
              try {
                await apiClient.accessoryChecksApi.apiV1AccessoryChecksAccessoryCheckIdAddContractPut(
                  {
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    accessoryCheckId: accessoryCheck.id!,
                    addAccessoryCheckToContractDto: {
                      contractId: values.contractId,
                      isSendToCustomer: values.isSendToCustomer,
                    },
                  },
                );
                enqueueSnackbar("Successfully added.", {
                  variant: "success",
                });

                if (mounted.current) {
                  setStatus({ success: true });
                  setSubmitting(false);
                }

                onSaved && onSaved(contract!);
                onClose && onClose({}, "escapeKeyDown");
              } catch (err) {
                if (mounted.current) {
                  ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
                  setStatus({ success: false });
                  setSubmitting(false);
                }
              }

              setSubmitting(false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setErrors,
              setFieldValue,
              setValues,
            }) => {
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <FormControl
                    margin='dense'
                    fullWidth
                    error={Boolean(touched.contractId && errors.contractId)}
                  >
                    <ContractAutocomplete
                      entityId={values.contractId}
                      searchFilters={{
                        vehicleId: accessoryCheck?.vehicle?.id || undefined,
                        excludeStage: ContractStage.Draft,
                      }}
                      onChange={async (newValue) => {
                        setFieldValue(`contractId`, newValue?.id);
                        setContract(newValue);
                      }}
                    />
                    <FormHelperText>{touched.contractId && errors.contractId}</FormHelperText>
                  </FormControl>
                  <FormControl margin='dense' fullWidth sx={{ ml: 1 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='isSendToCustomer'
                          checked={values.isSendToCustomer}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      }
                      label={
                        <Stack direction='row' spacing={1} sx={{ alignItems: "center", ml: 1 }}>
                          <Typography component='span'>Send to customer</Typography>
                          <HelpTooltipIcon title='Indicates whether accessory check must be sent to customer' />
                        </Stack>
                      }
                    />
                  </FormControl>

                  <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                  <Button
                    sx={{ flex: 1, mt: 1 }}
                    fullWidth
                    color='primary'
                    disabled={!values.contractId}
                    loading={isSubmitting}
                    type='submit'
                    variant='contained'
                  >
                    Save
                  </Button>
                </form>
              );
            }}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default AddAccessoryCheckToContractModal;
