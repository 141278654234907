import { Box, Button, FormControl, Stack, TextField } from "@mui/material";
import { Formik } from "formik";
import { useMemo } from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import AppAvatar from "@/common/components/Avatar/AppAvatar";
import TenantRolesSelector from "@/common/components/Entity/Role/TenantRolesSelector";
import AdminTenantAutocomplete from "@/common/components/Entity/Tenant/AdminTenantAutocomplete";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { AdminUserCompanyDto, AdminUserDto, UpdateUserRolesDto } from "@/core/api/generated";

import GlobalRolesSelector from "../components/Role/GlobalRolesSelector";

export interface AdminUserRolesEditPageQueryParams extends GeneralQueryParams {
  tenantId?: string;
}

export default function UpdateUserRolesPage() {
  const { userId } = useParams<{ userId?: string }>();
  const { tenantId } = useQueryParams<AdminUserRolesEditPageQueryParams>();
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const history = useHistory();

  const userRequest = useApiRequest(
    apiClient.adminUsersApi.apiV1AdminUsersUserIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      userId: userId!,
    },
    {
      deps: [userId],
      skip: !userId,
    },
  );
  const user = userRequest?.data;

  const selectedUserProfile = useMemo<AdminUserDto | AdminUserCompanyDto | undefined>(
    () =>
      tenantId ? user?.companies?.find((x) => x.id === tenantId) || undefined : user || undefined,
    [user, tenantId],
  );

  return (
    <CreateUpdatePageLayout
      breadcrumbs={{
        replacements: {
          isWaitInfinitely: true,
          idBreadcrumb: user && {
            idValue: user.id || "",
            newTitle: `${user?.email}`,
          },
        },
      }}
      header={<SimpleViewPageHeader title={"Edit user roles"} />}
    >
      <Formik<BaseFormikValues & UpdateUserRolesDto>
        enableReinitialize
        initialValues={{
          roleIds: selectedUserProfile?.roles?.map((x) => x.role?.id || "") || undefined,

          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // phoneNumber: Yup.string().required("Phone number is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            if (tenantId) {
              await apiClient.adminAccountApi.apiV1AdminAccountTenantsTenantIdUsersUserIdRolesPut({
                tenantId,
                userId: user?.id || "",
                adminUserRolesUpdateDto: {
                  tenantId,
                  roleIds: values.roleIds,
                },
              });
            } else {
              await apiClient.adminAccountApi.apiV1AdminAccountGlobalUsersUserIdRolesPut({
                userId: user?.id || "",
                updateUserRolesDto: {
                  roleIds: values.roleIds,
                },
              });
            }

            enqueueSnackbar("User roles updated.", { variant: "success" });

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
            history.goBack();
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Box>
                  <AppAvatar user={user} size={100} />
                </Box>

                <Box>
                  <FormControl margin='dense' fullWidth>
                    <AdminTenantAutocomplete entityId={tenantId} disabled fullWidth />
                  </FormControl>

                  <TextField
                    disabled
                    fullWidth
                    label='Email'
                    margin='dense'
                    type='email'
                    value={user?.email || ""}
                    variant='outlined'
                  />

                  {tenantId && user && (
                    <TenantRolesSelector
                      tenantIdentifier={tenantId}
                      targetUserId={user.id}
                      roleIds={values.roleIds}
                      onChange={(newValue) =>
                        setFieldValue("roleIds", newValue?.map((x) => x.id) || [])
                      }
                      textFieldProps={{
                        error: Boolean(errors.roleIds),
                        helperText: errors.roleIds,
                      }}
                      displayProps={{
                        selectedRolesOverview: true,
                      }}
                    />
                  )}

                  {!tenantId && (
                    <GlobalRolesSelector
                      roleIds={values.roleIds}
                      onChange={(newValue) =>
                        setFieldValue("roleIds", newValue?.map((x) => x.id) || [])
                      }
                      textFieldProps={{
                        error: Boolean(errors.roleIds),
                        helperText: errors.roleIds,
                      }}
                      displayProps={{
                        selectedRolesOverview: true,
                      }}
                    />
                  )}
                </Box>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <Button
                  sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
                  color='primary'
                  loading={isSubmitting}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Save
                </Button>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </CreateUpdatePageLayout>
  );
}
