import {
  Button,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import _ from "lodash";
import { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import ContractAssessmentFlowStateFilterTypeIcon from "@/App/MainAppView/Flows/Assessment/components/ContractAssessmentFlowStateFilterTypeIcon";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIconButton from "@/common/components/Button/AppIconButton";
import DataExportModal from "@/common/components/DataExport/DataExportModal";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityTagsInfoDisplay from "@/common/components/EntityInfo/EntityTagsInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { EntityHelper } from "@/common/helpers/entity";
import { TagStaticFilterInputDto, TagsHelper } from "@/common/helpers/entity/tags";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ApiEnumName, enumService } from "@/common/services/enum";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  AssessmentFlowBriefDto,
  AssessmentFlowGetPaginatedDto,
  ChatType,
  ContractAssessmentFlowStateFilterType,
  DataUpdatesHubClientMethodName,
  EntityType,
  ExportEntityType,
  FilterDefinitionDto,
  GeneralAttachedTagsInputDto,
  TagEntityType,
} from "@/core/api/generated";

import ChatActivator from "../../Chat/ChatActivator";
import ContractInline from "../../Contract/ContractInline";
import CustomerInline from "../../Customer/CustomerInline";
import AffiliationInfoDisplay from "../../EntityAffiliation/AffiliationInfoDisplay";
import GeneralAttachedTagsOfEntitiesEditModal from "../../General/GeneralTag/GeneralAttachedTagsOfEntitiesEditModal";
import TenantStructureMembershipOfEntityEditModal from "../../Membership/TenantStructureMembershipOfEntityEditModal";
import VehicleInline from "../../Vehicle/VehicleInline";

enum BulkActionFlags {
  UpdateTenantStructureMembership = "UpdateTenantStructureMembership",
  UpdateTags = "UpdateTags",
  Export = "Export",
}

const defaultDisplayProps = {
  filters: true,
  counters: true,
  create: true,
  edit: true,
  delete: true,
};

const defaultPropagatedDeps = {
  refetch: {},
};
export type AssessmentFlowPaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;

type AssessmentFlowGetPaginatedInputDto = Omit<AssessmentFlowGetPaginatedDto, "tags"> & {
  tags?: TagStaticFilterInputDto;
};

export interface AssessmentFlowPaginatedListOwnProps {
  displayProps?: Partial<typeof defaultDisplayProps>;

  initialValues?: {
    filterDefinitionDto?: Nil<FilterDefinitionDto>;
    isResetFilterDefinition?: boolean;
    filterType?: ContractAssessmentFlowStateFilterType;
  };
  defaultValues?: {
    limit?: number;
    vehicleId?: string;
    contractId?: string;
    customerId?: string;
  };
  propagatedDeps?: PropagatedDeps<AssessmentFlowPaginatedListPropagatedDepsMap>;
}

export type AssessmentFlowPaginatedListProps = AssessmentFlowPaginatedListOwnProps;

export default function AssessmentFlowPaginatedList({
  displayProps,
  initialValues,
  defaultValues,
  propagatedDeps,
}: AssessmentFlowPaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<AssessmentFlowGetPaginatedInputDto>({
    statePersistence: {
      persistenceKey: EntityType.AssessmentFlow,
    },
    initialValues: {
      filterDefinitionDto: initialValues?.filterDefinitionDto || undefined,
      isResetFilterDefinition: initialValues?.isResetFilterDefinition,
      params: {
        ...(initialValues?.filterType ? { filterType: initialValues?.filterType } : undefined),
      },
    },
    defaultValues: {
      limit: defaultValues?.limit,
      params: {
        filterType: ContractAssessmentFlowStateFilterType.All,
        ...defaultValues,
      },
    },
    initParams: (filterDefinition) => {
      const tagFilter = filterDefinition.filterDefinition?.items.find(
        (x) => x.field === getTypedPath<AssessmentFlowBriefDto>().tags.$path,
      );

      return {
        tags: {
          operator: tagFilter?.operator,
          query: tagFilter?.value as GeneralAttachedTagsInputDto,
        } as TagStaticFilterInputDto,
      };
    },
  });

  const [isDataExportModalOpened, setIsDataExportModalOpened] = useState(false);

  const isCountersVisible = displayProps?.counters && !commonRequestParams.isAnyFilters;

  const countersRequest = useApiRequest(
    apiClient.assessmentFlowsApi.apiV1FlowsAssessmentFlowsCountersGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assessmentFlowGetCountersDto: {
        isEmptyDamagesAndCosts: false,
      },
    },
    {
      deps: [],
    },
  );
  const counters = countersRequest?.data;

  const paginatedAssessmentFlowsRequest = useApiRequest(
    apiClient.assessmentFlowsApi.apiV1FlowsAssessmentFlowsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assessmentFlowGetPaginatedDto: {
        ..._.omit(commonRequestParams.params, "tags"),
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        tags:
          commonRequestParams.filterDefinition?.items
            .filter((x) => x.field === getTypedPath<AssessmentFlowBriefDto>().tags.$path)
            .map((x) => TagsHelper.mapFromFilter(x))[0] || undefined,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
        isEmptyDamagesAndCosts: false,
      },
    },
    {
      deps: [
        ...commonRequestParams.deps,
        commonRequestParams.filterDefinition,
        propagatedDeps?.depsMap["refetch"],
      ],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedAssessmentFlows = useMemo(
    () => paginatedAssessmentFlowsRequest?.data,
    [paginatedAssessmentFlowsRequest.data],
  );

  const assessmentFlowIds = useMemo(
    () => paginatedAssessmentFlows?.items?.map((x) => x.id!) || [],
    [paginatedAssessmentFlows],
  );

  useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.Contract)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      console.log("Reloading assessments on contract update...");
      paginatedAssessmentFlowsRequest.refetch();
    },
  });

  return (
    <ViewLayoutV2
      header={
        <SimpleViewPageHeader
          title='Damages & Approvals'
          primaryActions={
            displayProps?.create && (
              <AuthorizedElement permissions={[AppPermission.ContractManage]}>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<AppIcon of='add' />}
                  component={RouterLink}
                  to={ROUTE_PATH.CONTRACT_CREATE()}
                >
                  Create new contract
                </Button>
              </AuthorizedElement>
            )
          }
          secondaryActions={
            <AuthorizedElement permissions={[AppPermission.ExportManage]}>
              <MenuWithTrigger
                withAuthCloseOnClick
                trigger={
                  <IconButton>
                    <AppIcon of='moreVert' />
                  </IconButton>
                }
              >
                <MenuItem onClick={() => setIsDataExportModalOpened(true)}>
                  <AppIcon of='export' fontSize='small' sx={{ mr: 1 }} />
                  <Typography>Export assessment flows</Typography>
                </MenuItem>
              </MenuWithTrigger>
            </AuthorizedElement>
          }
        />
      }
    >
      <DataTabular
        tabs={{
          value: commonRequestParams.params?.filterType,
          onChange: (e, newValue) => commonRequestParams.setOneParam2({ filterType: newValue }),
          tabs: enumService
            .getEnumValues("ContractAssessmentFlowStateFilterType", {
              except: [ContractAssessmentFlowStateFilterType.None],
            })
            .map((value) => ({
              label: (
                <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
                  <Box>
                    <InlineApiEnumValue
                      type='ContractAssessmentFlowStateFilterType'
                      value={value}
                    />
                  </Box>
                  {isCountersVisible && counters?.countByFilterTypeMap && (
                    <Chip
                      variant='outlined'
                      size='small'
                      color='default'
                      label={counters.countByFilterTypeMap[value] ?? 0}
                    />
                  )}
                </Stack>
              ),
              value,
            })),
        }}
        columns={[
          {
            field: getTypedPath<AssessmentFlowBriefDto>().id.$path,
            title: "ID",
            width: 250,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.id,
            filters: {
              fieldType: FilterFieldType.Id,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AssessmentFlowBriefDto>().localNumber.$path,
            title: "Number",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.localNumber,
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AssessmentFlowBriefDto>().vehicle.id.$path,
            title: "Vehicle",
            width: 300,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => <VehicleInline entity={item.vehicle} />,
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfVehicle(),
            },
          },
          {
            field: getTypedPath<AssessmentFlowBriefDto>().contract.id.$path,
            title: "Contract",
            width: 300,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => <ContractInline entity={item.contract} />,
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfContract(),
            },
          },
          {
            field: getTypedPath<AssessmentFlowBriefDto>().customer.id.$path,
            title: "Customer",
            width: 300,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => <CustomerInline entity={item.customer} />,
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfCustomer(),
            },
          },

          // {
          //   title: "Damage status",
          //   xxs: 12,
          //   md: 2,
          //   renderCell: (item) => (
          //     <Stack spacing={1}>
          //       <InlineApiEnumValue
          //         type='DamageDetectionStatusInContract'
          //         value={item.contract!.assessmentFlow?.damageDetectionStatus}
          //       />
          //       <NegotiationProposalComputedStatusIconsStrip
          //         negotiations={item.negotiations!.filter(
          //           (x) => item.damageNegotiationIdsMap![x.id!],
          //         )}
          //       />
          //     </Stack>
          //   ),
          // },
          // {
          //   title: "Damage cost status",
          //   xxs: 12,
          //   md: 1,
          //   renderCell: (item) => (
          //     <Stack spacing={1}>
          //       <InlineApiEnumValue
          //         type='DamageCostEvaluationStatusInContract'
          //         value={item.contract!.assessmentFlow?.damageCostEvaluationStatus}
          //       />
          //       <NegotiationProposalComputedStatusIconsStrip
          //         negotiations={item.negotiations!.filter(
          //           (x) => item.damageCostNegotiationIdsMap![x.id!],
          //         )}
          //       />
          //     </Stack>
          //   ),
          // },
          {
            field: getTypedPath<AssessmentFlowBriefDto>().state.lastActivityAt.$path,
            title: "Last activity",
            width: 100,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <Datetime
                datetime={item.state?.lastActivityAt}
                direction='column'
                withDurationFromNow
              />
            ),
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AssessmentFlowBriefDto>().state.filterState.$path,
            title: "Status",
            width: 70,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <ContractAssessmentFlowStateFilterTypeIcon
                assessmentFlowId={item.id || ""}
                value={item.state?.filterState}
                fontSize='medium'
                withTooltip
              />
            ),
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: true,
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.ContractAssessmentFlowStateFilterType,
                },
              },
            },
          },
          {
            field: getTypedPath<AssessmentFlowBriefDto>().createdAt.$path,
            title: "Created at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AssessmentFlowBriefDto>().updatedAt.$path,
            title: "Updated at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AssessmentFlowBriefDto>().createdBy.$path,
            title: "Created by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<AssessmentFlowBriefDto>().updatedBy.$path,
            title: "Updated by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: "affiliation",
            title: "Affiliation",
            description: "Affiliation to departments & locations",
            width: 120,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            isToDisabled: true,
            renderCell: (item) => (
              <AffiliationInfoDisplay
                variant='compact'
                tenantId={item.tenantId}
                departmentIds={item.departmentIds}
                locationIds={item.locationIds}
              />
            ),
          },
          {
            field: getTypedPath<AssessmentFlowBriefDto>().tags.$path,
            title: "Tags",
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            isColumnMenuDisabled: false,
            isToDisabled: true,
            renderCell: (item) => (
              <EntityTagsInfoDisplay
                entityType={TagEntityType.AssessmentFlow}
                entity={item}
                noDataPlaceholder='-'
                edit={{
                  onSaved: (newValue) =>
                    paginatedAssessmentFlowsRequest.updateData((data) => {
                      data.items?.forEach((item2) => {
                        if (item2.id === item.id) {
                          item2.tags = newValue || undefined;
                        }
                      });
                    }),
                }}
              />
            ),
            filters: {
              fieldType: FilterFieldType.Arbitrary,
              isUseSingleFilter: true,
              operators: FilterCatalog.getOperatorsForTagsField(),
            },
          },
          {
            field: "actions",
            title: "Actions",
            renderCell: (item) => (
              <Stack direction={{ xxs: "row", md: "column", desktop: "row" }}>
                <ChatActivator
                  chatType={ChatType.Negotiation}
                  chatId={item.chatId || ""}
                  to={ROUTE_PATH.ASSESSMENT_FLOW_CHAT_VIEW(item.id)}
                  withActivityIndicator
                  tooltipTitle='Open chat'
                  sx={{ ml: { xxs: 1, md: 0, xl: 1 }, width: "fit-content" }}
                />
              </Stack>
            ),
            isToDisabled: true,
          },
        ]}
        rows={paginatedAssessmentFlows?.items}
        isLoading={paginatedAssessmentFlowsRequest.isLoading}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.ASSESSMENT_FLOW_VIEW(item.id)}
        renderRowAction={({ item }) => (
          <MenuWithTrigger
            withAuthCloseOnClick
            trigger={
              <IconButton>
                <AppIcon of='moreVert' />
              </IconButton>
            }
          >
            <MenuItem component={RouterLink} to={ROUTE_PATH.ASSESSMENT_FLOW_CHAT_VIEW(item.id!)}>
              <ListItemIcon>
                <AppIcon of='chat' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Chat</ListItemText>
            </MenuItem>
          </MenuWithTrigger>
        )}
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
        refetch={commonRequestParams.dataTabularProps.refetch}
        bulkActions={{
          enabled: true,
          definition: BulkActionFlags,
          actionTriggers: ({ currentAction, startAction, selectedIds }) => (
            <>
              <AppIconButton
                tooltipProps={{ title: "Add to department/location" }}
                onClick={() => startAction(BulkActionFlags.UpdateTenantStructureMembership)}
              >
                <AppIcon of='department' />
              </AppIconButton>
              <AppIconButton
                tooltipProps={{ title: "Export" }}
                onClick={() => startAction(BulkActionFlags.Export)}
              >
                <AppIcon of='export' />
              </AppIconButton>
              <AppIconButton
                tooltipProps={{ title: "Update tags" }}
                onClick={() => startAction(BulkActionFlags.UpdateTags)}
              >
                <AppIcon of='tag' />
              </AppIconButton>
            </>
          ),
          actionHandlers: ({ selectedIds, currentAction, cancelAction, completeAction }) => {
            const selectedEntities = EntityHelper.filterEntitiesByIds(
              paginatedAssessmentFlows?.items || [],
              selectedIds as string[],
            );
            return (
              <>
                <TenantStructureMembershipOfEntityEditModal
                  open={currentAction === BulkActionFlags.UpdateTenantStructureMembership}
                  onClose={cancelAction}
                  tenantStructureMembershipOfEntityEditProps={{
                    entityType: EntityType.AssessmentFlow,
                    entities: selectedEntities,
                    onSave: () => {
                      completeAction();
                      paginatedAssessmentFlowsRequest.refetch();
                    },
                  }}
                />
                <GeneralAttachedTagsOfEntitiesEditModal
                  tagEntityType={TagEntityType.AssessmentFlow}
                  entities={selectedEntities}
                  open={currentAction === BulkActionFlags.UpdateTags}
                  onClose={cancelAction}
                  onSaved={() => {
                    completeAction();
                    paginatedAssessmentFlowsRequest.refetch();
                  }}
                />
                <DataExportModal
                  open={currentAction === BulkActionFlags.Export}
                  onClose={cancelAction}
                  exportDataProps={{
                    entityType: ExportEntityType.AssessmentFlow,
                    ids: selectedIds as string[],
                    onExportDone: completeAction,
                    displayProps: {
                      mode: false,
                    },
                  }}
                />
              </>
            );
          },
        }}
      />

      {/* Export data modal */}
      <DataExportModal
        open={isDataExportModalOpened}
        onClose={() => setIsDataExportModalOpened(false)}
        exportDataProps={{
          entityType: ExportEntityType.AssessmentFlow,
          ids: assessmentFlowIds,
          onExportDone: () => {
            setIsDataExportModalOpened(false);
          },
        }}
      />
    </ViewLayoutV2>
  );
}
