import TenantConnectionRequestPaginatedList from "@/common/components/Entity/TenantConnectionRequest/ListView/TenantConnectionRequestPaginatedList";

export default function TenantConnectionRequestsPage() {
  return (
    <>
      <TenantConnectionRequestPaginatedList
        displayProps={{
          breadcrumbs: true,
          filters: true,
        }}
      />
    </>
  );
}
