import MaintenancePaginatedList from "@/common/components/Entity/Maintenance/ListView/MaintenancePaginatedList";
import { useLocationState } from "@/common/hooks/useLocationParams";
import { FilterDefinitionDto } from "@/core/api/generated";

export interface MaintenanceListPageLocationState {
  initialValues?: {
    filterDefinitionDto?: Nil<FilterDefinitionDto>;
  };
}

export default function MaintenancesPage() {
  const locationState = useLocationState<MaintenanceListPageLocationState>();

  return (
    <>
      <MaintenancePaginatedList initialValues={locationState?.initialValues} />
    </>
  );
}
