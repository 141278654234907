import { useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";

export default function IntegrationApiClientCreateUpdatePage() {
  const { integrationApiClientId } = useParams<{ integrationApiClientId?: string }>();

  const isCreate = !integrationApiClientId;

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader title={isCreate ? "Create new API client" : "Edit API client"} />
      }
    >
      Not supported!
    </CreateUpdatePageLayout>
  );
}
