import {
  IAssetSourceEntityDto,
  IBaseEntityDto,
  IHasGlobalNumberDto,
  IHasLocalNumberDto,
  IImportedEntityDto,
  IMightHaveDataGrantsBriefDto,
  IMightHaveDataGrantsDto,
  IMightHaveDepartmentsDto,
  IMightHaveLocationsDto,
  IMightHavePoolsDto,
  IMightHaveTagsDto,
  IMightHaveTenantRequestsDto,
} from "@/core/api/generated";

export const EntityFormMode = {
  Create: "Create",
  Update: "Update",
} as const;
export type EntityFormMode = (typeof EntityFormMode)[keyof typeof EntityFormMode];

export type EntityFormInfo = {
  mode: EntityFormMode;
  isCreate: boolean;
  isUpdate: boolean;
};
export type EntityMeta = IBaseEntityDto &
  IHasGlobalNumberDto &
  IHasLocalNumberDto &
  IImportedEntityDto &
  ((IMightHaveDataGrantsDto | IMightHaveDataGrantsBriefDto) & {
    grantsMeta?: (
      | IMightHaveDataGrantsDto["grantsMeta"]
      | IMightHaveDataGrantsBriefDto["grantsMeta"]
    ) & {
      isIssuedByMe: boolean;
      isConsumedByMe: boolean;
    };
  }) &
  IMightHaveTenantRequestsDto &
  IAssetSourceEntityDto &
  // todo: remove department when Dtos will have departmentIds
  IMightHaveDepartmentsDto &
  // todo: remove location when Dtos will have locationIds
  IMightHaveLocationsDto &
  IMightHavePoolsDto &
  IMightHaveTagsDto;
