import {
  InputAdornment,
  ListItemText,
  MenuItem,
  MenuList,
  TextField,
  TextFieldProps,
} from "@mui/material";
import _ from "lodash";
import { ChangeEvent, useCallback, useEffect, useMemo, useRef } from "react";

import { useCurrentCurrency } from "@/common/hooks/useCurrentCurrency";
import { ApiEnumName, ApiEnumValue, enumService } from "@/common/services/enum";
import { ValidationHelper } from "@/common/validation";
import {
  CurrencyCode,
  GeneralCurrencyDto,
  GeneralCurrencyInputDto,
  GeneralPriceDto,
  GeneralPriceInputDto,
} from "@/core/api/generated";

import DropdownButton from "../../../Button/DropdownButton";
import InlineApiEnumValue from "../../../Enum/InlineApiEnumValue";

// const fallbackCurrencyCode = CurrencyCode.Usd;

export interface GeneralPriceInputProps<TCustomPriceTypeEnumTypeName extends ApiEnumName = any>
  extends Omit<TextFieldProps, "onChange" | "type" | "value" | "startAdornment" | "endAdornment"> {
  value?: GeneralPriceInputDto | GeneralPriceDto | null;
  defaultCurrency?: GeneralCurrencyDto | GeneralCurrencyInputDto | null;
  defaultCurrencyCode?: CurrencyCode;
  allowCurrencyEdit?: boolean;
  customPriceTypeEdit?: {
    enumType: TCustomPriceTypeEnumTypeName;
    enumValue?: ApiEnumValue<TCustomPriceTypeEnumTypeName> | null;
    onChange?: (newValue?: ApiEnumValue<TCustomPriceTypeEnumTypeName> | null) => void;
  };
  /** Select currency from tenant or user settings by default; */
  useCurrentCurrencyByDefault?: boolean;
  onChange?: (
    e?: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    newValue?: GeneralPriceInputDto | null,
  ) => void;
}

export default function GeneralPriceInput<TCustomPriceTypeEnumTypeName extends ApiEnumName = any>({
  value,
  defaultCurrency,
  defaultCurrencyCode,
  allowCurrencyEdit = true,
  customPriceTypeEdit,
  useCurrentCurrencyByDefault,
  onChange,
  ...textFieldProps
}: GeneralPriceInputProps<TCustomPriceTypeEnumTypeName>) {
  const currentCurrency = useCurrentCurrency();

  const isDefaultCurrencyAppliedRef = useRef(false);
  const isCurrentCurrencyAppliedRef = useRef(false);

  const defaultCurrencyCodeComputed = useMemo(
    () => defaultCurrency?.code || defaultCurrencyCode,
    [defaultCurrency, defaultCurrencyCode],
  );

  // ensure currency is always selected
  useEffect(() => {
    if (!isDefaultCurrencyAppliedRef.current && !value?.currency && defaultCurrencyCodeComputed) {
      onChange &&
        onChange(undefined, {
          ...value,
          currency: {
            code: defaultCurrencyCodeComputed,
          },
        });
      isDefaultCurrencyAppliedRef.current = true;
    } else if (
      !isCurrentCurrencyAppliedRef.current &&
      !value?.currency &&
      currentCurrency &&
      useCurrentCurrencyByDefault
    ) {
      onChange &&
        onChange(undefined, {
          ...value,
          currency: currentCurrency,
        });
      isCurrentCurrencyAppliedRef.current = true;
    }
  }, [value?.currency, useCurrentCurrencyByDefault, defaultCurrencyCodeComputed, currentCurrency]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newInputValue =
        _.isNil(e.target.value) || _.isEmpty(e.target.value) ? undefined : +e.target.value;

      onChange &&
        onChange(e, {
          price: newInputValue,
          currency: value?.currency,
        });
    },
    [onChange, value],
  );

  const handleSelectCurrency = useCallback(
    (newCurrencyCode?: CurrencyCode) => {
      onChange &&
        onChange(undefined, {
          ...value,
          currency: {
            ...value?.currency,
            code: newCurrencyCode,
          },
        });
    },
    [value],
  );

  return (
    <TextField
      label='Price'
      inputMode='decimal'
      value={value?.price ?? ""}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <>
            {customPriceTypeEdit && (
              <InputAdornment sx={{ mx: 0 }} position='start'>
                <DropdownButton
                  sx={{
                    fontWeight: "inherit",
                  }}
                  disabled={textFieldProps.disabled}
                  variant='text'
                  size='extraSmall'
                  color='text'
                  dropdownContent={
                    <MenuList>
                      {enumService
                        .getEnumDtos(customPriceTypeEdit.enumType)
                        .filter((x) => x.value !== "None")
                        .map((x, i) => (
                          <MenuItem
                            key={i}
                            onClick={() => {
                              customPriceTypeEdit.onChange &&
                                customPriceTypeEdit.onChange(
                                  x.value as ApiEnumValue<TCustomPriceTypeEnumTypeName>,
                                );
                            }}
                          >
                            <ListItemText>
                              <InlineApiEnumValue
                                type={customPriceTypeEdit.enumType}
                                value={x.value as ApiEnumValue<TCustomPriceTypeEnumTypeName>}
                              />
                            </ListItemText>
                          </MenuItem>
                        ))}
                    </MenuList>
                  }
                >
                  {!customPriceTypeEdit.enumValue && <>Select type</>}

                  {customPriceTypeEdit.enumValue && (
                    <InlineApiEnumValue
                      type={customPriceTypeEdit.enumType}
                      value={customPriceTypeEdit.enumValue}
                      withDescription
                      direction='row'
                    />
                  )}
                </DropdownButton>
              </InputAdornment>
            )}
          </>
        ),
        endAdornment: (
          <>
            {/* Currency code */}
            <InputAdornment position='end'>
              <DropdownButton
                disabled={!allowCurrencyEdit || textFieldProps.disabled}
                variant='text'
                size='extraSmall'
                color='text'
                dropdownContent={
                  <MenuList>
                    {enumService
                      .getEnumDtos("CurrencyCode")
                      .filter((x) => x.value !== CurrencyCode.None)
                      .map((x, i) => (
                        <MenuItem
                          key={i}
                          onClick={() => {
                            handleSelectCurrency(x.value as CurrencyCode);
                          }}
                        >
                          <ListItemText>
                            <InlineApiEnumValue
                              type='CurrencyCode'
                              value={x.value as CurrencyCode}
                            />
                          </ListItemText>
                        </MenuItem>
                      ))}
                  </MenuList>
                }
              >
                <InlineApiEnumValue type='CurrencyCode' value={value?.currency?.code} />
              </DropdownButton>
            </InputAdornment>
          </>
        ),
      }}
      {..._.omit(textFieldProps, ["helperText"])}
      helperText={
        textFieldProps.helperText && ValidationHelper.isValidErrors(textFieldProps.helperText)
          ? ValidationHelper.getFormikErrorsAsString(textFieldProps.helperText)
          : textFieldProps.helperText
      }
    />
  );
}
