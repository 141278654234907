import { Box, Button, Stack } from "@mui/material";

import Chat from "@/common/components/Entity/Chat/Chat";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import { ChatDto } from "@/core/api/generated";
import * as chatsSlice from "@/store/communication/chatsSlice";
import { OpenChatInfo } from "@/store/communication/chatsSlice";

function ChatStack() {
  const thunkDispatch = useAppThunkDispatch();
  const openedChats = useAppSelector((x) => x.communication.chats.openedChats)?.filter(
    (y) => y.placement === "stack",
  );

  const handleCloseChat = (openedChat: OpenChatInfo, chat: ChatDto) => {
    thunkDispatch(chatsSlice.closeChat({ openedChat, chat }));
  };

  const handleCloseAllChats = () => {
    thunkDispatch(chatsSlice.closeAllChats());
  };

  if (!openedChats || openedChats.length === 0) {
    return null;
  }

  return (
    <Stack
      sx={{
        width: { xxs: "100%", md: "400px" },
        minHeight: "100%",
        // maxHeight: "100vh",
        // overflowY: "auto",
        pl: 0,
        pr: 2,
        py: 2,
        // borderLeft: "1px",
        // borderLeftStyle: "solid",
        // borderLeftColor: theme.palette.divider,
      }}
      direction='column'
      spacing={2}
    >
      {openedChats &&
        openedChats.map((openedChat, i) => (
          <Box key={i}>
            <Chat
              chatType={openedChat.chatType}
              chatId={openedChat.chatId!}
              scope={openedChat.scope}
              allowClose
              allowLeave
              fullWidth
              onClose={(chat) => {
                handleCloseChat(openedChat, chat);
              }}
            />
          </Box>
        ))}

      {openedChats && openedChats.length > 1 && (
        <Stack direction='row' sx={{ justifyContent: "center" }}>
          <Button variant='text' color='text' onClick={handleCloseAllChats}>
            Close all chats
          </Button>
        </Stack>
      )}
    </Stack>
  );
}

export default ChatStack;
