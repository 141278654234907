import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import NegotiationMenu from "@/common/components/Entity/Negotiation/NegotiationMenu";
import PartyInline from "@/common/components/Entity/Party/PartyInline";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import GeneralScopeLink from "@/common/components/Link/GeneralScopeLink";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  DataUpdatesHubClientMethodName,
  EntityType,
  NegotiationDto,
  NegotiationGetPaginatedDto,
} from "@/core/api/generated";

const defaultDisplayProps = {
  viewVariant: ViewLayoutVariant.Page,
};
interface Props {
  displayProps?: Partial<typeof defaultDisplayProps>;
}

export default function NegotiationsPage({ displayProps }: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<NegotiationGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.Negotiation,
    },
  });

  const paginatedNegotiationsRequest = useApiRequest(
    apiClient.negotiationsApi.apiV1NegotiationsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      negotiationGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedNegotiations = paginatedNegotiationsRequest?.data;

  useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.Negotiation)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedNegotiationsRequest.handleEntityChanged(data);
    },
  });

  return (
    <ViewLayoutV2
      displayProps={displayProps}
      header={<SimpleViewPageHeader title='Negotiations' primaryActions={undefined} />}
    >
      <DataTabular<NegotiationDto>
        columns={[
          {
            field: "name",
            title: "Name",
            flex: 1.5,
            renderCell: (item) => item.name,
          },
          {
            field: "type",
            title: "Type",
            flex: 1,
            renderCell: (item) => <InlineApiEnumValue type='NegotiationType' value={item.type} />,
          },
          {
            field: "status",
            title: "Status",
            flex: 1,
            renderCell: (item) => (
              <InlineApiEnumValue type='NegotiationStatus' value={item.status} />
            ),
          },
          {
            field: "scope.identifier",
            title: "Scope",
            flex: 1,
            renderCell: (item) => <GeneralScopeLink scope={item.scope} />,
          },
          {
            field: "initiatorParty.id",
            title: "Initiator party",
            flex: 1,
            renderCell: (item) => <PartyInline entity={item.initiatorParty} />,
          },
          {
            field: "targetParty.id",
            title: "Target party",
            flex: 1,
            renderCell: (item) => <PartyInline entity={item.targetParty} />,
          },
        ]}
        rows={paginatedNegotiations?.items}
        isLoading={paginatedNegotiationsRequest.isLoading}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.NEGOTIATION_VIEW(item.id)}
        renderRowAction={({ item }) => (
          <NegotiationMenu
            entity={item}
            onDelete={() => paginatedNegotiationsRequest.refetch()}
            onUpdate={() => paginatedNegotiationsRequest.refetch()}
            displayProps={{
              actions: {
                edit: false,
                createNewProposal: false,
                eventLog: false,
                delete: true,
              },
            }}
          />
        )}
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
        refetch={commonRequestParams.dataTabularProps.refetch}
      />
    </ViewLayoutV2>
  );
}
