import {
  Box,
  Grid2,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import GeneralCurrencyDisplay from "@/common/components/Entity/General/Display/GeneralCurrencyDisplay";
import GeneralDiscountDisplay from "@/common/components/Entity/General/GeneralDiscount/GeneralDiscountDisplay";
import GeneralTaxDisplay from "@/common/components/Entity/General/GeneralTax/GeneralTaxDisplay";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import EntityUpdatedByInfoDisplay from "@/common/components/EntityInfo/EntityUpdatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import { FileHelper } from "@/common/helpers/file";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

function InvoiceViewPage() {
  const { invoiceId } = useParams<{ invoiceId?: string }>();

  const [isPdfLoading, setIsPdfLoading] = useState(false);

  // menu, dialogs
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const invoiceRequest = useApiRequest(
    apiClient.adminInvoicesApi.apiV1AdminInvoicesInvoiceIdGet,
    {
      invoiceId: invoiceId!,
    },
    {
      skip: !invoiceId,
    },
  );
  const invoice = invoiceRequest?.data;

  const handleDownloadPdf = useCallback(async () => {
    try {
      setIsPdfLoading(true);
      const result = await FileHelper.getDownloadFileApiResult(() =>
        apiClient.adminInvoicesApi.apiV1AdminInvoicesInvoiceIdDownloadPdfGet({
          invoiceId: invoice!.id!,
        }),
      );
      FileHelper.downloadBlob(result.blob, result.filename || "invoice.pdf");
    } finally {
      setIsPdfLoading(false);
    }
  }, [invoice]);

  if (invoiceRequest.isLoading) {
    return <LinearProgress />;
  }
  if (!invoice) {
    return <EntityNotFoundAlert />;
  }

  return (
    <ViewPageLayout header={<>Invoice {invoice.localNumber}</>}>
      <Stack direction='column' spacing={2}>
        <Stack direction='row' spacing={1} sx={{ alignItems: "flex-start", mb: 2 }}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={1}
            sx={{ flex: 1, justifyContent: "flex-end" }}
          >
            <IconButton onClick={handleMoreClick} sx={{ ml: "auto" }}>
              <AppIcon of='moreVert' />
            </IconButton>

            <Menu anchorEl={menuAnchorEl} open={isMenuOpen} onClose={handleMenuClose}>
              <MenuItem
                onClick={() => {
                  handleDownloadPdf();
                  handleMenuClose();
                }}
              >
                <ListItemIcon>
                  {!isPdfLoading && <AppIcon of='download' fontSize='small' />}
                  {isPdfLoading && <CircularProgress />}
                </ListItemIcon>
                <ListItemText>Download PDF</ListItemText>
              </MenuItem>
            </Menu>
          </Stack>
        </Stack>

        <Box>
          <Grid2 container columnSpacing={2} rowSpacing={1}>
            <Grid2 size={{ xxs: 12 }} minWidth={200}>
              <Stack direction='column' spacing={1}>
                <FieldValue label='Created' isEmpty={!invoice?.createdAt}>
                  <EntityCreatedByInfoDisplay entity={invoice} />
                </FieldValue>
              </Stack>
            </Grid2>
            <Grid2 size={{ xxs: 12 }} minWidth={200}>
              <Stack direction='column' spacing={1}>
                <FieldValue label='Updated' isEmpty={!invoice?.updatedAt}>
                  <EntityUpdatedByInfoDisplay entity={invoice} />
                </FieldValue>
              </Stack>
            </Grid2>
          </Grid2>
        </Box>

        <Box>
          {" "}
          <Grid2 container columnSpacing={2} rowSpacing={1} sx={{ mb: 2 }}>
            <Grid2 size={{ xxs: 12 }} minWidth={200}>
              <Stack direction='column' spacing={1}>
                <FieldValue label='Status'>
                  <InlineApiEnumValue type='InvoiceStatus' value={invoice.status} withDescription />
                </FieldValue>
                <FieldValue label='Payment method'>
                  <InlineApiEnumValue
                    type='PaymentMethod'
                    value={invoice.paymentMethod}
                    withDescription
                  />
                </FieldValue>
                <FieldValue label='Currency'>
                  <GeneralCurrencyDisplay currency={invoice.currency} />
                </FieldValue>
                <FieldValue label='Description'>{invoice.description}</FieldValue>
              </Stack>
            </Grid2>
            <Grid2 size={{ xxs: 12 }} minWidth={200}>
              <Stack direction='column' spacing={1}>
                <FieldValue label='Date'>
                  <Datetime datetime={invoice.date} withDurationFromNow />
                </FieldValue>
                <FieldValue label='Due date'>
                  <Datetime datetime={invoice.dueAt} withDurationFromNow />
                </FieldValue>
                <FieldValue label='Sent at'>
                  <Datetime datetime={invoice.sentAt} withDurationFromNow />
                </FieldValue>
                {invoice.pendingAt && (
                  <FieldValue label='Pending at'>
                    <Datetime datetime={invoice.pendingAt} withDurationFromNow />
                  </FieldValue>
                )}
                {invoice.paidAt && (
                  <FieldValue label='Paid at'>
                    <Datetime datetime={invoice.paidAt} withDurationFromNow />
                  </FieldValue>
                )}
                {invoice.cancelledAt && (
                  <FieldValue label='Cancelled at'>
                    <Datetime datetime={invoice.cancelledAt} withDurationFromNow />
                  </FieldValue>
                )}
              </Stack>
            </Grid2>
          </Grid2>
        </Box>

        <Typography component='div' variant='h6' sx={{ mb: 2 }}>
          Business info
        </Typography>
        <Box>
          <Grid2 container columnSpacing={2} rowSpacing={1} sx={{ mb: 2 }}>
            <Grid2 size={{ xxs: 12 }} minWidth={200}>
              <Stack direction='column' spacing={1}>
                <FieldValue label='Name'>{invoice.businessInfo?.name}</FieldValue>
                <FieldValue label='Email'>{invoice.businessInfo?.email}</FieldValue>
                <FieldValue label='Phone number'>{invoice.businessInfo?.phoneNumber}</FieldValue>
                <FieldValue label='Web site URL'>{invoice.businessInfo?.webSiteUrl}</FieldValue>
              </Stack>
            </Grid2>
            <Grid2 size={{ xxs: 12 }} minWidth={200}>
              <Stack direction='column' spacing={1}>
                {invoice.businessInfo?.address && (
                  <>
                    <FieldValue label='Country'>
                      {invoice.businessInfo?.address?.country}
                    </FieldValue>
                    <FieldValue label='City'>{invoice.businessInfo?.address?.city}</FieldValue>
                    <FieldValue label='State'>{invoice.businessInfo?.address?.state}</FieldValue>
                    <FieldValue label='Line 1'>{invoice.businessInfo?.address?.line1}</FieldValue>
                    <FieldValue label='Line 2'>{invoice.businessInfo?.address?.line2}</FieldValue>
                    <FieldValue label='Postal code'>
                      {invoice.businessInfo?.address?.postalCode}
                    </FieldValue>
                  </>
                )}
              </Stack>
            </Grid2>
          </Grid2>
        </Box>

        <Typography component='div' variant='h6' sx={{ mb: 2 }}>
          Customer business info
        </Typography>
        <Box>
          <Grid2 container columnSpacing={2} rowSpacing={1} sx={{ mb: 2 }}>
            <Grid2 size={{ xxs: 12 }} minWidth={200}>
              <Stack direction='column' spacing={1}>
                <FieldValue label='Business name'>
                  {invoice.customerBusinessInfo?.businessName}
                </FieldValue>
                <FieldValue label='Name'>
                  {invoice.customerBusinessInfo?.personName?.name}
                </FieldValue>
                <FieldValue label='Email'>{invoice.customerBusinessInfo?.email}</FieldValue>
                <FieldValue label='Phone number'>
                  {invoice.customerBusinessInfo?.phoneNumber}
                </FieldValue>
                s
              </Stack>
            </Grid2>
            <Grid2 size={{ xxs: 12 }} minWidth={200}>
              <Stack direction='column' spacing={1}>
                {invoice.customerBusinessInfo?.address && (
                  <>
                    <FieldValue label='Country'>
                      {invoice.customerBusinessInfo?.address?.country}
                    </FieldValue>
                    <FieldValue label='City'>
                      {invoice.customerBusinessInfo?.address?.city}
                    </FieldValue>
                    <FieldValue label='State'>
                      {invoice.customerBusinessInfo?.address?.state}
                    </FieldValue>
                    <FieldValue label='Line 1'>
                      {invoice.customerBusinessInfo?.address?.line1}
                    </FieldValue>
                    <FieldValue label='Line 2'>
                      {invoice.customerBusinessInfo?.address?.line2}
                    </FieldValue>
                    <FieldValue label='Postal code'>
                      {invoice.customerBusinessInfo?.address?.postalCode}
                    </FieldValue>
                  </>
                )}
              </Stack>
            </Grid2>
          </Grid2>
        </Box>

        <Typography component='div' variant='h6' sx={{ mb: 2 }}>
          Items
        </Typography>

        <DataTabular
          sx={{ mb: 2 }}
          columns={[
            {
              field: "name",
              title: "Name",
              flex: 1,
              renderCell: (item) => (
                <>
                  <Typography component='div' variant='body1'>
                    {item.name}
                  </Typography>
                  <Typography component='div' variant='body2'>
                    {item.description}
                  </Typography>
                </>
              ),
            },
            {
              field: "amount",
              title: "Amount",
              flex: 1,
              renderCell: (item) => <>{item.amount}</>,
            },
            {
              field: "price",
              title: "Price",
              flex: 1,
              renderCell: (item) => (
                <CurrencyValue value={item.price} currency={invoice.currency} />
              ),
            },
            {
              field: "subtotal",
              title: "Sub total",
              flex: 1,
              renderCell: (item) => (
                <CurrencyValue value={item.subtotal} currency={invoice.currency} />
              ),
            },
            {
              field: "discount",
              title: "Discount",
              flex: 1,
              renderCell: (item) => (
                <GeneralDiscountDisplay discount={item.discount} currency={invoice.currency} />
              ),
            },
            {
              field: "tax",
              title: "Tax",
              flex: 1,
              renderCell: (item) => (
                <GeneralTaxDisplay tax={item.tax} currency={invoice.currency} />
              ),
            },
            {
              field: "total",
              title: "Total",
              flex: 1,
              renderCell: (item) => (
                <CurrencyValue value={item.total} currency={invoice.currency} />
              ),
            },
          ]}
          rows={invoice?.lineItems}
          getRowId={(item) => item.id!}
        />

        {/* Summary */}
        {invoice.lineItems?.length !== 0 && (
          <Stack direction='row' justifyContent='flex-end'>
            <GeneralPriceSummaryDisplay
              summary={{
                currency: invoice.currency,
                subTotal: invoice.subTotal,
                subTotalIncDiscount: invoice.subTotalIncDiscount,
                discount: invoice.discount,
                tax: invoice.tax,
                total: invoice.total,
              }}
            />
          </Stack>
        )}
      </Stack>
    </ViewPageLayout>
  );
}

export default InvoiceViewPage;
