import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import CreateUpdateRepairMaterial from "@/common/components/Entity/RepairMaterial/CreateUpdateRepairMaterial";
import { apiClient } from "@/core/api/ApiClient";

export default function CreateUpdateRepairMaterialPage() {
  const { repairMaterialId } = useParams<{ repairMaterialId?: string }>();
  const isCreate = !repairMaterialId;
  const history = useHistory();

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader
          title={isCreate ? "Create new repair material" : "Edit repair material"}
        />
      }
    >
      <CreateUpdateRepairMaterial
        repairMaterialId={repairMaterialId}
        onSave={(newValue) => {
          history.goBack();
        }}
        createFunc={async (params) => {
          return await apiClient.adminRepairMaterialsApi.apiV1AdminRepairMaterialsPost({
            createRepairMaterialDto: params.dto,
          });
        }}
        updateFunc={async (params) => {
          return await apiClient.adminRepairMaterialsApi.apiV1AdminRepairMaterialsRepairMaterialIdPut(
            {
              repairMaterialId: params.repairMaterialId,
              updateRepairMaterialDto: params.dto,
            },
          );
        }}
      />
    </CreateUpdatePageLayout>
  );
}
