import { Box, SxProps, Theme } from "@mui/material";
import _ from "lodash";

import { NumberHelper } from "@/common/helpers/number";

interface Props {
  value?: number | null;
  sx?: SxProps<Theme>;
}

/** Displays percent value normalized to [0;100]. */
export default function PercentValue({ value, sx }: Props) {
  if (_.isNil(value)) {
    return null;
  }

  return (
    <Box component='span' sx={sx}>
      {NumberHelper.normalizePercentToZeroHundred(value)}%
    </Box>
  );
}
