import { Box, MenuItem, Stack, TextField, TextFieldProps, Typography } from "@mui/material";

const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];

export default function DevTextFields() {
  return (
    <Stack spacing={2}>
      <Typography component='div' variant='h2'>
        MUI TextField
      </Typography>

      <Stack direction='column' spacing={2}>
        <Typography component='div' variant='h6'>
          Variant
        </Typography>
        <Stack direction='row' spacing={2} alignItems='center'>
          {(["outlined", "filled", "standard"] as Array<TextFieldProps["variant"]>).map(
            (variant, i) => (
              <TextField key={i} label={variant} variant={variant} />
            ),
          )}
        </Stack>
      </Stack>

      <Stack direction='column' spacing={2}>
        <Typography component='div' variant='h6'>
          Size
        </Typography>
        <Stack direction='row' spacing={2} alignItems='center'>
          {(["outlined", "filled", "standard"] as Array<TextFieldProps["variant"]>).map(
            (variant, i) => (
              <Stack key={i} spacing={2}>
                {(["small", "medium"] as Array<TextFieldProps["size"]>).map((size, j) => (
                  <TextField key={j} label={`${variant}, ${size}`} variant={variant} size={size} />
                ))}
              </Stack>
            ),
          )}
        </Stack>
      </Stack>

      <Stack direction='column' spacing={1}>
        <Stack direction='row' spacing={1}>
          <Box
            component='form'
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete='off'
          >
            <div>
              <TextField
                required
                id='outlined-required'
                label='Required'
                defaultValue='Hello World'
              />
              <TextField
                disabled
                id='outlined-disabled'
                label='Disabled'
                defaultValue='Hello World'
              />
              <TextField
                id='outlined-password-input'
                label='Password'
                type='password'
                autoComplete='current-password'
              />
              <TextField
                id='outlined-read-only-input'
                label='Read Only'
                defaultValue='Hello World'
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                id='outlined-number'
                label='Number'
                inputMode='numeric'
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField id='outlined-search' label='Search field' type='search' />
              <TextField
                id='outlined-helperText'
                label='Helper text'
                defaultValue='Default Value'
                helperText='Some important text'
              />
            </div>
            <div>
              <TextField
                required
                id='filled-required'
                label='Required'
                defaultValue='Hello World'
                variant='filled'
              />
              <TextField
                disabled
                id='filled-disabled'
                label='Disabled'
                defaultValue='Hello World'
                variant='filled'
              />
              <TextField
                id='filled-password-input'
                label='Password'
                type='password'
                autoComplete='current-password'
                variant='filled'
              />
              <TextField
                id='filled-read-only-input'
                label='Read Only'
                defaultValue='Hello World'
                InputProps={{
                  readOnly: true,
                }}
                variant='filled'
              />
              <TextField
                id='filled-number'
                label='Number'
                type='number'
                InputLabelProps={{
                  shrink: true,
                }}
                variant='filled'
              />
              <TextField id='filled-search' label='Search field' type='search' variant='filled' />
              <TextField
                id='filled-helperText'
                label='Helper text'
                defaultValue='Default Value'
                helperText='Some important text'
                variant='filled'
              />
            </div>
            <div>
              <TextField
                required
                id='standard-required'
                label='Required'
                defaultValue='Hello World'
                variant='standard'
              />
              <TextField
                disabled
                id='standard-disabled'
                label='Disabled'
                defaultValue='Hello World'
                variant='standard'
              />
              <TextField
                id='standard-password-input'
                label='Password'
                type='password'
                autoComplete='current-password'
                variant='standard'
              />
              <TextField
                id='standard-read-only-input'
                label='Read Only'
                defaultValue='Hello World'
                InputProps={{
                  readOnly: true,
                }}
                variant='standard'
              />
              <TextField
                id='standard-number'
                label='Number'
                type='number'
                InputLabelProps={{
                  shrink: true,
                }}
                variant='standard'
              />
              <TextField
                id='standard-search'
                label='Search field'
                type='search'
                variant='standard'
              />
              <TextField
                id='standard-helperText'
                label='Helper text'
                defaultValue='Default Value'
                helperText='Some important text'
                variant='standard'
              />
            </div>
          </Box>
        </Stack>
        <Stack direction='row' spacing={1}>
          {" "}
          <Box
            component='form'
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete='off'
          >
            <div>
              <TextField error id='outlined-error' label='Error' defaultValue='Hello World' />
              <TextField
                error
                id='outlined-error-helper-text'
                label='Error'
                defaultValue='Hello World'
                helperText='Incorrect entry.'
              />
            </div>
            <div>
              <TextField
                error
                id='filled-error'
                label='Error'
                defaultValue='Hello World'
                variant='filled'
              />
              <TextField
                error
                id='filled-error-helper-text'
                label='Error'
                defaultValue='Hello World'
                helperText='Incorrect entry.'
                variant='filled'
              />
            </div>
            <div>
              <TextField
                error
                id='standard-error'
                label='Error'
                defaultValue='Hello World'
                variant='standard'
              />
              <TextField
                error
                id='standard-error-helper-text'
                label='Error'
                defaultValue='Hello World'
                helperText='Incorrect entry.'
                variant='standard'
              />
            </div>
          </Box>
        </Stack>
        <Stack direction='row' spacing={1}>
          <Box
            component='form'
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete='off'
          >
            <div>
              <TextField id='outlined-multiline-flexible' label='Multiline' multiline maxRows={4} />
              <TextField
                id='outlined-textarea'
                label='Multiline Placeholder'
                placeholder='Placeholder'
                multiline
              />
              <TextField
                id='outlined-multiline-static'
                label='Multiline'
                multiline
                rows={4}
                defaultValue='Default Value'
              />
            </div>
            <div>
              <TextField
                id='filled-multiline-flexible'
                label='Multiline'
                multiline
                maxRows={4}
                variant='filled'
              />
              <TextField
                id='filled-textarea'
                label='Multiline Placeholder'
                placeholder='Placeholder'
                multiline
                variant='filled'
              />
              <TextField
                id='filled-multiline-static'
                label='Multiline'
                multiline
                rows={4}
                defaultValue='Default Value'
                variant='filled'
              />
            </div>
            <div>
              <TextField
                id='standard-multiline-flexible'
                label='Multiline'
                multiline
                maxRows={4}
                variant='standard'
              />
              <TextField
                id='standard-textarea'
                label='Multiline Placeholder'
                placeholder='Placeholder'
                multiline
                variant='standard'
              />
              <TextField
                id='standard-multiline-static'
                label='Multiline'
                multiline
                rows={4}
                defaultValue='Default Value'
                variant='standard'
              />
            </div>
          </Box>
        </Stack>
        <Stack direction='row' spacing={1}>
          {" "}
          <Box
            component='form'
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete='off'
          >
            <div>
              <TextField
                id='outlined-select-currency'
                select
                label='Select'
                defaultValue='EUR'
                helperText='Please select your currency'
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id='outlined-select-currency-native'
                select
                label='Native select'
                defaultValue='EUR'
                SelectProps={{
                  native: true,
                }}
                helperText='Please select your currency'
              >
                {currencies.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
            <div>
              <TextField
                id='filled-select-currency'
                select
                label='Select'
                defaultValue='EUR'
                helperText='Please select your currency'
                variant='filled'
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id='filled-select-currency-native'
                select
                label='Native select'
                defaultValue='EUR'
                SelectProps={{
                  native: true,
                }}
                helperText='Please select your currency'
                variant='filled'
              >
                {currencies.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
            <div>
              <TextField
                id='standard-select-currency'
                select
                label='Select'
                defaultValue='EUR'
                helperText='Please select your currency'
                variant='standard'
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id='standard-select-currency-native'
                select
                label='Native select'
                defaultValue='EUR'
                SelectProps={{
                  native: true,
                }}
                helperText='Please select your currency'
                variant='standard'
              >
                {currencies.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
          </Box>
        </Stack>
        <Stack direction='row' spacing={1}></Stack>
      </Stack>
    </Stack>
  );
}
