import { ListItemIcon, ListItemText, Stack } from "@mui/material";
import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";

import { ROUTE_PATH } from "@/common/constants/routing";
import { AppPermission, RepairOperationDto } from "@/core/api/generated";

import AuthorizedMenuItem from "../../Auth/AuthorizedMenuItem";
import EntityMenu from "../../EntityMenu/EntityMenu";
import AppIcon from "../../Icons/AppIcon";
import RepairOperationDeleteModal from "./RepairOperationDeleteModal";

enum ActionFlags {
  Edit = "Edit",
  Delete = "Delete",
}
const defaultDisplayProps = {
  actions: {
    edit: true,
    delete: true,
  },
};

interface TriggersProps {
  entity?: RepairOperationDto | null;
  definition: typeof ActionFlags;
  handleClose: () => void;
  startAction: (props: ActionFlags) => void;
}

interface Props {
  entity?: RepairOperationDto | null;
  disabled?: boolean;
  onUpdate: ((newValue: RepairOperationDto | null | undefined) => void) | undefined;
  onDelete?: (() => void) | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  customActions?: (props: TriggersProps) => ReactNode;
}

export default function RepairOperationMenu({
  entity,
  disabled,
  customActions,
  onUpdate,
  onDelete,
  displayProps = defaultDisplayProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  return (
    <EntityMenu
      definition={ActionFlags}
      entity={entity}
      disabled={disabled}
      actionTriggers={({ handleClose, startAction }) => (
        <>
          {customActions &&
            customActions({
              entity,
              definition: ActionFlags,
              handleClose,
              startAction,
            })}
          {displayProps?.actions?.edit && (
            <AuthorizedMenuItem
              permissions={[AppPermission.RepairOperationManage]}
              component={RouterLink}
              to={ROUTE_PATH.REPAIR_OPERATION_EDIT(entity?.id)}
            >
              <ListItemIcon>
                <AppIcon of='edit' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </AuthorizedMenuItem>
          )}
          {displayProps?.actions?.delete && (
            <AuthorizedMenuItem
              permissions={[AppPermission.RepairOperationManage]}
              onClick={() => {
                startAction(ActionFlags.Delete);
                handleClose();
              }}
            >
              <ListItemIcon>
                <AppIcon of='delete' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </AuthorizedMenuItem>
          )}
        </>
      )}
      actionHandlers={({ currentAction, cancelAction, completeAction }) => (
        <Stack>
          {/* Delete */}
          {entity && (
            <RepairOperationDeleteModal
              entity={entity}
              open={currentAction === ActionFlags.Delete}
              onClose={() => cancelAction()}
              onDelete={() => {
                onDelete && onDelete();
                completeAction();
              }}
            />
          )}
        </Stack>
      )}
    />
  );
}
