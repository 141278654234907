import { Alert, Stack } from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIconButton from "@/common/components/Button/AppIconButton";
import SplitDropdownButton from "@/common/components/Button/SplitDropdownButton";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import AffiliationInfoDisplay from "@/common/components/Entity/EntityAffiliation/AffiliationInfoDisplay";
import GeneralAddressDisplay from "@/common/components/Entity/General/Display/GeneralAddressDisplay";
import TenantStructureMembershipOfEntityEditModal from "@/common/components/Entity/Membership/TenantStructureMembershipOfEntityEditModal";
import ProductLocationMenu from "@/common/components/Entity/ProductLocation/ProductLocationMenu";
import ProductLocationsDeleteModal from "@/common/components/Entity/ProductLocation/ProductLocationsDeleteModal";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { EntityHelper } from "@/common/helpers/entity";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  ProductLocationDto,
  ProductLocationGetPaginatedDto,
} from "@/core/api/generated";

import ProductLocationCreateFromLocationModal from "../../../../common/components/Entity/ProductLocation/ProductLocationCreateFromLocationModal";
import InlineUser from "../../components/User/InlineUser";

enum BulkActionFlags {
  UpdateTenantStructureMembership = "UpdateTenantStructureMembership",
  Delete = "Delete",
}
const defaultDisplayProps = {
  viewVariant: ViewLayoutVariant.Page,
  create: true,
};
interface Props {
  displayProps?: Partial<typeof defaultDisplayProps>;
}

export default function ProductLocationsPage({ displayProps }: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const history = useHistory();
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<ProductLocationGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.ProductLocation,
    },
  });

  const [
    isCreateProductLocationFromLocationModalOpen,
    setIsCreateProductLocationFromLocationModalOpen,
  ] = useState(false);

  const paginatedProductLocationsRequest = useApiRequest(
    apiClient.productLocationsApi.apiV1ProductLocationsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      productLocationGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );

  const paginatedProductLocations = paginatedProductLocationsRequest.data;

  useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.ProductLocation)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedProductLocationsRequest.handleEntityChanged(data);
    },
  });

  return (
    <ViewLayoutV2
      displayProps={displayProps}
      header={
        <>
          <Alert severity='info' sx={{ mb: 2 }}>
            Product sales locations are locations that end customers see and where customers can get
            their orders. While regular locations are private to your tenant, product sales
            locations are public.
          </Alert>

          <SimpleViewPageHeader
            title='Sales locations'
            primaryActions={
              displayProps?.create && (
                <AuthorizedElement permissions={[AppPermission.ProductLocationManage]}>
                  <SplitDropdownButton
                    isSelectAndTriggerAction
                    buttonProps={{
                      variant: "contained",
                      color: "primary",
                      startIcon: <AppIcon of='add' />,
                    }}
                    options={[
                      {
                        content: "Create new sales location",
                        onClick: () => history.push(ROUTE_PATH.PRODUCT_SALES_LOCATION_CREATE),
                      },
                      {
                        content: "Create new from existing location",
                        onClick: () => setIsCreateProductLocationFromLocationModalOpen(true),
                      },
                    ]}
                  />
                </AuthorizedElement>
              )
            }
          />
        </>
      }
    >
      <DataTabular
        columns={[
          {
            field: getTypedPath<ProductLocationDto>().id.$path,
            title: "ID",
            width: 250,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.id,
            filters: {
              fieldType: FilterFieldType.Id,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<ProductLocationDto>().localNumber.$path,
            title: "Number",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <Stack direction='row' spacing={0.5}>
                <span>{item.localNumber}</span>
              </Stack>
            ),
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: "Info badges",
            title: "Info badges",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => <EntityChipList entity={item} variant='compact' />,
          },
          {
            field: getTypedPath<ProductLocationDto>().name.$path,
            title: "Name",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.name || "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<ProductLocationDto>().description.$path,
            title: "Description",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => item.description || "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<ProductLocationDto>().address.$path,
            title: "Address",
            width: 400,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => (
              <AppTypography ellipsing={{ enabled: true }} component='div'>
                <GeneralAddressDisplay address={item.address} direction='row' />
              </AppTypography>
            ),
          },
          {
            field: getTypedPath<ProductLocationDto>().createdAt.$path,
            title: "Created at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<ProductLocationDto>().updatedAt.$path,
            title: "Updated at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<ProductLocationDto>().createdBy.$path,
            title: "Created by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<ProductLocationDto>().updatedBy.$path,
            title: "Updated by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: "affiliation",
            title: "Affiliation",
            description: "Affiliation to departments & locations",
            width: 120,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            isToDisabled: true,
            renderCell: (item) => (
              <AffiliationInfoDisplay
                variant='compact'
                tenantId={item.tenantId}
                departmentIds={item.departmentIds}
                locationIds={item.locationIds}
              />
            ),
          },
        ]}
        isLoading={paginatedProductLocationsRequest.isLoading}
        rows={paginatedProductLocations?.items}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.PRODUCT_SALES_LOCATION_VIEW(item.id)}
        renderRowAction={({ item }) => (
          <ProductLocationMenu
            onDelete={() => paginatedProductLocationsRequest.refetch()}
            onUpdate={() => paginatedProductLocationsRequest.refetch()}
            entity={item}
          />
        )}
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
        refetch={commonRequestParams.dataTabularProps.refetch}
        bulkActions={{
          enabled: true,
          definition: BulkActionFlags,
          actionTriggers: ({ currentAction, startAction, selectedIds }) => (
            <>
              <AppIconButton
                tooltipProps={{ title: "Update affiliation" }}
                onClick={() => startAction(BulkActionFlags.UpdateTenantStructureMembership)}
              >
                <AppIcon of='department' />
              </AppIconButton>

              <AppIconButton
                tooltipProps={{ title: "Delete" }}
                onClick={() => startAction(BulkActionFlags.Delete)}
              >
                <AppIcon of='delete' />
              </AppIconButton>
            </>
          ),
          actionHandlers: ({ selectedIds, currentAction, cancelAction, completeAction }) => (
            <>
              <ProductLocationsDeleteModal
                entities={EntityHelper.filterEntitiesByIds(
                  paginatedProductLocations?.items || [],
                  selectedIds as string[],
                )}
                open={currentAction === BulkActionFlags.Delete}
                onClose={() => cancelAction()}
                onDelete={() => {
                  completeAction();
                  paginatedProductLocationsRequest.refetch();
                }}
              />
              <TenantStructureMembershipOfEntityEditModal
                open={currentAction === BulkActionFlags.UpdateTenantStructureMembership}
                onClose={() => cancelAction()}
                tenantStructureMembershipOfEntityEditProps={{
                  entityType: EntityType.ProductLocation,
                  entities: EntityHelper.filterEntitiesByIds(
                    paginatedProductLocations?.items || [],
                    selectedIds as string[],
                  ),
                  onSave: () => {
                    completeAction();
                    paginatedProductLocationsRequest.refetch();
                  },
                }}
              />
            </>
          ),
        }}
      />

      {/* Create ProductLocation from Location */}
      <ProductLocationCreateFromLocationModal
        sourceLocation={undefined}
        isRedirectToViewPageAfterCreate={false}
        open={isCreateProductLocationFromLocationModalOpen}
        onClose={() => setIsCreateProductLocationFromLocationModalOpen(false)}
        onCreate={() => paginatedProductLocationsRequest.refetch()}
      />
    </ViewLayoutV2>
  );
}
