import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function EditIcon(props: SvgIconProps, ref) {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <g clipPath='url(#clip0_12097_25822)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17.6479 0C18.0375 0 18.3538 0.31625 18.3538 0.705887V1.41176H20.4714C21.639 1.41176 22.5891 2.36189 22.5891 3.52941V24H1.4126V3.52941C1.4126 2.36189 2.36271 1.41176 3.53025 1.41176H5.64787V0.705887C5.64787 0.316238 5.96412 1.25e-05 6.35377 1.25e-05C6.74342 1.25e-05 7.05965 0.316238 7.05965 0.705887V1.41176H16.942V0.705887C16.942 0.316238 17.2582 1.25e-05 17.6479 1.25e-05L17.6479 0ZM21.1773 7.05881H2.82437V22.5882H21.1773V7.05881ZM17.1488 10.0892L18.1469 11.0873L10.5884 18.6459L5.85471 13.9108L6.85284 12.9127L10.5884 16.6482L17.1488 10.0892ZM5.64789 2.82354H3.53025C3.142 2.82354 2.82437 3.13976 2.82437 3.52941V5.64706H21.1773V3.52941C21.1773 3.13976 20.8597 2.82354 20.4714 2.82354H18.3538V3.52941C18.3538 3.91906 18.0375 4.2353 17.6479 4.2353C17.2582 4.2353 16.942 3.91906 16.942 3.52943V2.82353H7.05966V3.52941C7.05966 3.91906 6.74341 4.2353 6.35377 4.2353C5.96414 4.2353 5.64789 3.91906 5.64789 3.52943V2.82354Z'
        />
      </g>
      <defs>
        <clipPath id='clip0_12097_25822'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
});
