import PublicIcon from "@mui/icons-material/Public";
import PublicOffIcon from "@mui/icons-material/PublicOff";
import { Alert, AlertTitle, Box, CircularProgress, Fade, Tooltip } from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";

interface Props {
  connected: boolean;
  variant: "icon" | "alert";
  alwaysVisible?: boolean;
  hideAfterConnected?: number;
}

/** Displays status of connection (internet, API, SignalR, etc).
 *  When connection restored, is being displayed for a while and then hides.
 */
function ConnectionStatusIndicator({
  connected,
  variant = "icon",
  alwaysVisible = false,
  hideAfterConnected = 5000,
}: Props) {
  const [prevConnected, setPrevConnected] = useState<boolean | undefined>(undefined);
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const [isSuccessVisible, setIsSuccessVisible] = useState(false);

  const debounce = useCallback(
    _.debounce((callback: () => void) => {
      callback();
    }, 1000),
    [],
  );

  const changeStatus = (newConnected: boolean) => {
    const isConnected = newConnected === true && (prevConnected === false || alwaysVisible);
    const isDisconnected = newConnected === false && (prevConnected === true || alwaysVisible);

    if (isConnected || isDisconnected) {
      const autoHide = isConnected && !alwaysVisible;

      setIsWarningVisible(isDisconnected);
      setIsSuccessVisible(isConnected);

      if (autoHide) {
        setTimeout(() => !alwaysVisible && setIsSuccessVisible(false), hideAfterConnected);
      }
    } else {
      setIsWarningVisible(false);
      setIsSuccessVisible(false);
    }
    setPrevConnected(newConnected);
  };

  useEffect(() => {
    debounce(() => changeStatus(connected));
  }, [connected]);

  if (variant === "icon") {
    return (
      <>
        {isWarningVisible && (
          <Tooltip title='No connection with our servers (possibly internet connection issue)'>
            <PublicOffIcon color='warning' />
          </Tooltip>
        )}
        {isSuccessVisible && (
          <Tooltip title='Connected'>
            <PublicIcon color='success' />
          </Tooltip>
        )}
      </>
    );
  }

  if (variant === "alert") {
    return (
      <>
        {isWarningVisible && (
          <Fade in={isWarningVisible}>
            <Alert severity='warning' icon={<PublicOffIcon fontSize='inherit' />}>
              <AlertTitle>Connection lost!</AlertTitle>
              <Box>
                Reconnecting <CircularProgress color='secondary' size={10} />
              </Box>
            </Alert>
          </Fade>
        )}
        {isSuccessVisible && (
          <Fade in={isSuccessVisible}>
            <Alert severity='success' icon={<PublicIcon fontSize='inherit' />}>
              Connection restored!
            </Alert>
          </Fade>
        )}
      </>
    );
  }

  return null;
}

export default ConnectionStatusIndicator;
