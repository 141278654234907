import { useParams } from "react-router-dom";

import DamageCostEvaluationView from "@/common/components/Entity/DamageCostEvaluation/View/DamageCostEvaluationView";

export default function DamageCostEvaluationViewPage() {
  const { damageCostEvaluationId } = useParams<{ damageCostEvaluationId?: string }>();

  return (
    <>
      <DamageCostEvaluationView
        damageCostEvaluationId={damageCostEvaluationId}
        damageCostEvaluation={undefined}
        headerProps={{
          withLink: false,
        }}
        displayProps={{
          breadcrumbs: true,
        }}
      />
    </>
  );
}
