import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function DataGrantsIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.25013 6.01489C8.25003 6.00994 8.24998 6.00498 8.24998 6V5C8.24998 2.92893 9.92892 1.25 12 1.25C14.0711 1.25 15.75 2.92893 15.75 5V6C15.75 6.00498 15.7499 6.00994 15.7498 6.01489C17.0371 6.05353 17.8248 6.1924 18.4261 6.69147C19.2593 7.38295 19.4787 8.55339 19.9177 10.8943L20.6677 14.8943C21.2849 18.186 21.5934 19.8318 20.6937 20.9159C19.794 22 18.1195 22 14.7704 22H9.22954C5.88048 22 4.20595 22 3.30624 20.9159C2.40652 19.8318 2.71512 18.186 3.33231 14.8943L4.08231 10.8943C4.52122 8.55339 4.74068 7.38295 5.57386 6.69147C6.17521 6.1924 6.96287 6.05353 8.25013 6.01489ZM9.74998 5C9.74998 3.75736 10.7573 2.75 12 2.75C13.2426 2.75 14.25 3.75736 14.25 5V6C14.25 5.99999 14.25 6.00001 14.25 6C14.1747 5.99998 14.0982 6 14.0204 6H9.97954C9.90176 6 9.82525 6 9.74998 6.00002V5Z'
      />
      <path
        d='M16.5 14C15.8078 14 15.1311 14.2053 14.5555 14.5899C13.9799 14.9744 13.5313 15.5211 13.2664 16.1606C13.0015 16.8001 12.9322 17.5039 13.0673 18.1828C13.2023 18.8617 13.5356 19.4854 14.0251 19.9749C14.5146 20.4644 15.1383 20.7977 15.8172 20.9327C16.4961 21.0678 17.1999 20.9985 17.8394 20.7336C18.4789 20.4687 19.0256 20.0201 19.4101 19.4445C19.7947 18.8689 20 18.1922 20 17.5C20 17.0404 19.9095 16.5852 19.7336 16.1606C19.5577 15.736 19.2999 15.3501 18.9749 15.0251C18.6499 14.7001 18.264 14.4423 17.8394 14.2664C17.4148 14.0905 16.9596 14 16.5 14ZM17.9 17.85H16.5C16.4072 17.85 16.3182 17.8131 16.2525 17.7475C16.1869 17.6818 16.15 17.5928 16.15 17.5V16.1C16.15 16.0072 16.1869 15.9181 16.2525 15.8525C16.3182 15.7869 16.4072 15.75 16.5 15.75C16.5928 15.75 16.6819 15.7869 16.7475 15.8525C16.8131 15.9181 16.85 16.0072 16.85 16.1V17.15H17.9C17.9928 17.15 18.0819 17.1869 18.1475 17.2525C18.2131 17.3181 18.25 17.4072 18.25 17.5C18.25 17.5928 18.2131 17.6818 18.1475 17.7475C18.0819 17.8131 17.9928 17.85 17.9 17.85Z'
        fill='white'
      />
    </SvgIcon>
  );
});
