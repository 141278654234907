import { Dialog, DialogContent, DialogProps, List } from "@mui/material";
import { Box } from "@mui/system";
import { useMemo } from "react";

import { useChatParticipants } from "@/common/hooks/communication/useChatParticipants";
import { ChatMessageDto } from "@/core/api/generated";

import AppModalTitle from "../../Modals/AppModalTitle";
import ChatParticipantListItem from "../ChatParticipant/ChatParticipantListItem";

export interface OwnProps {
  message: ChatMessageDto;
}

type Props = OwnProps & DialogProps;

export default function ChatMessageReadByInfoModal({ message, ...dialogProps }: Props) {
  const { participantsMap } = useChatParticipants(message.chatId!);

  const readByParticipants = useMemo(
    () =>
      message
        .readByInfo!.map((x) => ({
          ...x,
          participant: participantsMap[x.participantId || ""]!,
        }))
        .filter((x) => !!x.participant),
    [message, participantsMap],
  );

  return (
    <Box>
      <Dialog fullWidth maxWidth='sm' {...dialogProps}>
        <AppModalTitle
          onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
        >
          Read by {readByParticipants.length}
        </AppModalTitle>
        <DialogContent>
          <List dense>
            {readByParticipants.map((item, i) => (
              <ChatParticipantListItem
                key={i}
                participant={item.participant}
                participantData={{
                  datetime: item.readAt,
                }}
                participantAvatarProps={{
                  withPopover: false,
                }}
              />
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
