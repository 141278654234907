import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogProps,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik, getIn } from "formik";
import _ from "lodash";
import { useState } from "react";
import * as Yup from "yup";

import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import PriceSummaryInput from "@/common/components/PriceSummary/PriceSummaryInput";
import { NegotiationHelper } from "@/common/helpers/entity/negotiation";
import { useNegotiationAllowedActions } from "@/common/hooks/entity/negotiation/useNegotiationAllowedActions";
import useMounted from "@/common/hooks/mount/useMounted";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import {
  CreateNegotiationProposalDto,
  CreateNegotiationProposalValueDto,
  CreateNegotiationProposalValuePriceDto,
  NegotiationDto,
  NegotiationProposalDto,
  NegotiationType,
  NegotiationValueType,
} from "@/core/api/generated";
import * as negotiationsSlice from "@/store/communication/negotiationsSlice";

import ApiEnumSelect from "../../../Enum/ApiEnumSelect";
import AppIcon from "../../../Icons/AppIcon";
import AppModalTitle from "../../../Modals/AppModalTitle";
import GeneralPriceDisplay from "../../General/GeneralPrice/GeneralPriceDisplay";

interface OwnProps {
  negotiation?: NegotiationDto;
  createFromProposal?: NegotiationProposalDto;
  onCreated?: (newValue?: NegotiationDto) => void;
}

type Props = OwnProps & DialogProps;

export default function CreateNegotiationProposalModal({
  onCreated,
  negotiation,
  createFromProposal,
  ...dialogProps
}: Props) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const allowedActions = useNegotiationAllowedActions(negotiation?.id);
  const thunkDispatch = useAppThunkDispatch();

  const [isSettingsVisible, setIsSettingsVisible] = useState(false);

  const canCreateProposal = NegotiationHelper.canCreateProposal(negotiation, allowedActions);

  const handleClose = () => {
    dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown");
    setIsSettingsVisible(false);
  };

  return (
    <Box>
      <Dialog maxWidth='lg' fullWidth {...dialogProps} onClose={handleClose}>
        <AppModalTitle
          onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
        >
          Create negotiation proposal
        </AppModalTitle>

        <DialogContent>
          {!canCreateProposal.value && (
            <Alert severity='info' sx={{ mb: 2 }}>
              {canCreateProposal.reason || `You can't create new proposal.`}
            </Alert>
          )}

          <Formik<
            Omit<CreateNegotiationProposalDto, "value"> & {
              value?: Omit<CreateNegotiationProposalValueDto, "price"> & {
                price?: CreateNegotiationProposalValuePriceDto & {
                  total?: number;
                };
              };
            } & BaseFormikValues
          >
            enableReinitialize
            initialValues={{
              createFromProposalId: createFromProposal?.id,
              type: negotiation?.type || NegotiationType.Approval,
              statement: createFromProposal?.statement || "",
              value:
                (createFromProposal?.value && {
                  ...createFromProposal.value,
                  price:
                    (createFromProposal.value.price && {
                      ...createFromProposal.value.price,
                      currency: createFromProposal.value.price.currency,
                      price: createFromProposal.value.price.price,
                      discount: createFromProposal.value.price.discount,
                      tax: createFromProposal.value.price.tax,
                      total: createFromProposal.value.price.total,
                    }) ||
                    undefined,
                }) ||
                undefined,
              settings: createFromProposal?.settings || undefined,
              submit: "",
            }}
            validationSchema={Yup.object().shape({
              // name: Yup.string().required("Name is required"),
            })}
            onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
              try {
                const updated = await thunkDispatch(
                  negotiationsSlice.createNegotiationProposal({
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    negotiationId: negotiation!.id!,
                    createNegotiationProposalDto: {
                      ..._.omit(values, "submit"),
                    },
                  }),
                );
                enqueueSnackbar("Negotiation proposal created.", { variant: "success" });

                if (mounted.current) {
                  setStatus({ success: true });
                  setSubmitting(false);
                }

                onCreated && onCreated(updated);
                handleClose();
              } catch (err: any) {
                if (mounted.current) {
                  ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
                  setStatus({ success: false });
                  setSubmitting(false);
                }
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setErrors,
              setFieldValue,
              setValues,
            }) => {
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <Box>
                    <FormControl fullWidth margin='dense' error={Boolean(errors.type)}>
                      <InputLabel>Proposal type</InputLabel>
                      <ApiEnumSelect
                        type='NegotiationType'
                        value={values.type}
                        onChange={(newValue) => setFieldValue("type", newValue)}
                        selectProps={{
                          disabled: true,
                          label: "Proposal type",
                        }}
                      />
                      <FormHelperText>{errors.type}</FormHelperText>
                    </FormControl>
                    <TextField
                      multiline
                      rows={2}
                      error={Boolean(errors.statement)}
                      fullWidth
                      helperText={errors.statement ? errors.statement : " What is proposal about?"}
                      label='Statement'
                      margin='dense'
                      name='statement'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='text'
                      value={values.statement}
                      variant='outlined'
                    />
                  </Box>

                  {/* Proposal value */}
                  {negotiation?.type === NegotiationType.Value && (
                    <Box sx={{ my: 2 }}>
                      {/* Price */}
                      {negotiation?.valueType === NegotiationValueType.Price && (
                        <Box>
                          <FormControl fullWidth margin='dense'>
                            <PriceSummaryInput
                              values={{
                                currency:
                                  values.value?.price?.currency ||
                                  values.value?.price?.price?.currency,
                                subTotal: values.value?.price?.price?.price,
                                discount: values.value?.price?.discount,
                                tax: values.value?.price?.tax,
                                total: values.value?.price?.total,
                              }}
                              formikProps={{
                                errors: {
                                  currency:
                                    getIn(errors, `value.price.currency`) ||
                                    getIn(errors, `value.price.price.currency`),
                                  subTotal:
                                    getIn(errors, `value.price.price.price`) ||
                                    getIn(errors, `value.price.price`),
                                  discount: getIn(errors, `value.price.discount`),
                                  tax: getIn(errors, `value.price.tax`),
                                  total: getIn(errors, `value.price.total`),
                                },
                              }}
                              displayProps={{
                                subTotal: true,
                                discount: true,
                                tax: true,
                                insurance: false,
                                total: true,
                                calcExplanationInline: false,
                                calcExplanationModal: true,
                              }}
                              inputsProps={{
                                all: {
                                  margin: "none",
                                },
                                subTotal: {
                                  label: "Price",
                                  required: true,
                                  helperText: (
                                    <Box>
                                      {values.value?.price?.minPrice && (
                                        <Box sx={{ whiteSpace: "nowrap" }}>
                                          Min allowed price:{" "}
                                          <GeneralPriceDisplay
                                            price={values.value?.price?.minPrice}
                                          />
                                        </Box>
                                      )}
                                      {values.value?.price?.maxPrice && (
                                        <Box sx={{ whiteSpace: "nowrap" }}>
                                          Max allowed price:{" "}
                                          <GeneralPriceDisplay
                                            price={values.value?.price?.maxPrice}
                                          />
                                        </Box>
                                      )}
                                    </Box>
                                  ),
                                },
                                total: {
                                  required: true,
                                },
                              }}
                              onChange={(newValue) => {
                                setFieldValue(`value.price.currency`, newValue?.currency);
                                setFieldValue(`value.price.price.currency`, newValue?.currency);
                                setFieldValue(`value.price.price.price`, newValue?.subTotal);
                                setFieldValue(`value.price.discount`, newValue?.discount);
                                setFieldValue(`value.price.tax`, newValue?.tax);
                                setFieldValue(`value.price.total`, newValue?.total);
                              }}
                            />

                            <FormHelperText error>
                              {ValidationHelper.getFormikErrorsAsString(
                                getIn(errors, "value.price"),
                                {
                                  isIncludeNested: false,
                                },
                              )}
                            </FormHelperText>
                          </FormControl>
                        </Box>
                      )}
                    </Box>
                  )}

                  {/* Settings */}
                  {isSettingsVisible && (
                    <Fade in={isSettingsVisible}>
                      <Box>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.settings?.allowChangeProposalResponse || false}
                                onBlur={handleBlur}
                                onChange={(e) =>
                                  setFieldValue(
                                    "settings.allowChangeProposalResponse",
                                    e.target.checked,
                                  )
                                }
                              />
                            }
                            label='Allow change proposal response'
                          />
                        </FormGroup>
                        <FormHelperText>{`Whether it's allowed to change answer.`}</FormHelperText>

                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.settings?.requireProposalConsensus || false}
                                onBlur={handleBlur}
                                onChange={(e) =>
                                  setFieldValue(
                                    "settings.requireProposalConsensus",
                                    e.target.checked,
                                  )
                                }
                              />
                            }
                            label='Require proposal consensus'
                          />
                        </FormGroup>
                        <FormHelperText>
                          Everyone should give the same answer in order to resolve the proposal.
                        </FormHelperText>
                      </Box>
                    </Fade>
                  )}
                  <Box sx={{ mt: 1 }}>
                    <Button
                      variant='text'
                      color='text'
                      onClick={() => setIsSettingsVisible(!isSettingsVisible)}
                    >
                      Settings {!isSettingsVisible && <AppIcon of='expandMore' inText />}
                      {isSettingsVisible && <AppIcon of='expandLess' inText />}
                    </Button>
                  </Box>

                  <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                  <Stack direction='row' spacing={1} sx={{ justifyContent: "flex-end" }}>
                    <Button variant='outlined' color='text' onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={!negotiation || !canCreateProposal}
                      loading={isSubmitting}
                      type='submit'
                    >
                      Save
                    </Button>
                  </Stack>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
