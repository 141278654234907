import { Box, CircularProgress, SxProps, Theme } from "@mui/material";
import { useEffect, useState } from "react";

interface Props {
  imageUrl?: string | null;
  sx?: SxProps<Theme>;
}

export default function SvgInline({ imageUrl, sx }: Props) {
  const [svgContent, setSvgContent] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [__, setIsError] = useState(false);

  useEffect(() => {
    if (imageUrl) {
      fetch(imageUrl, { cache: "default" })
        .then(async (res) => setSvgContent(await res.text()))
        .catch((err) => setIsError(true))
        .finally(() => setIsLoading(false));
    }
  }, [imageUrl]);

  if (isLoading) {
    return <CircularProgress size='24px' />;
  }
  if (!svgContent) {
    return null;
  }
  return (
    <Box
      // component='span'
      sx={{
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "inherit",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        "& svg": {
          width: "100%",
          height: "auto",
          maxHeight: "100%",
          borderRadius: "inherit",
        },
        ...sx,
      }}
      dangerouslySetInnerHTML={{ __html: svgContent }}
    />
  );
}
