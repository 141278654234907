import { useEffect } from "react";

import { ContractListPageLocationState } from "@/App/MainAppView/Contracts/ContractsPage";
import { AssessmentFlowListPageLocationState } from "@/App/MainAppView/Flows/Assessment/AssessmentFlowsPage";
import { AssetSubscriptionListPageLocationState } from "@/App/MainAppView/Products/AssetSubscriptions/AssetSubscriptionsPage";
import { VehicleListPageLocationState } from "@/App/MainAppView/Vehicles/VehiclesPage";
import { ROUTE_PATH } from "@/common/constants/routing";
import { WidgetHelper } from "@/common/helpers/widget";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { cast } from "@/common/ts/conversions";
import { apiClient } from "@/core/api/ApiClient";
import {
  ContractAssessmentFlowStateFilterType,
  ContractFilterType,
  ContractProductsSubscriptionsStateFilterType,
} from "@/core/api/generated";

import AppIcon from "../../Icons/AppIcon";
import AppTypography from "../../Text/AppTypography";
import BaseWidget from "../Base/BaseWidget";
import WidgetContent from "../Base/WidgetContent";
import WidgetHeader from "../Base/WidgetHeader";
import WidgetTitle from "../Base/WidgetTitle";
import WidgetTitleDefaultActions from "../Base/WidgetTitleDefaultActions";
import CounterWidgetCard from "../General/Counter/CounterWidgetCard";
import CounterWidgetCardGrid from "../General/Counter/CounterWidgetCardGrid";
import CounterWidgetContent from "../General/Counter/CounterWidgetContent";

export default function VehicleInFlowSummaryWidget() {
  const dataRequest = useApiRequest(
    apiClient.widgetDataApi.apiV1WidgetDataVehicleInFlowSummaryGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
    {
      deps: [],
    },
  );
  const data = dataRequest?.data;

  useEffect(() => {
    return WidgetHelper.handleRefetchAndRecomputeDataRequestInEffect((requestParams) => {
      dataRequest.refetchWithNewParams({
        widgetDataGetBaseDto: {
          ...requestParams,
        },
      });
    });
  }, [dataRequest]);

  return (
    <BaseWidget>
      <WidgetHeader>
        <WidgetTitle
          icon={<AppIcon of='dataConnected' />}
          actions={
            <WidgetTitleDefaultActions
              data={data}
              onRecompute={async (requestParams) => {
                await dataRequest.refetchWithNewParams({
                  widgetDataGetBaseDto: {
                    ...requestParams,
                  },
                });
              }}
            />
          }
        >
          Vehicles in flow
        </WidgetTitle>
      </WidgetHeader>

      <WidgetContent>
        <CounterWidgetCardGrid>
          <CounterWidgetCard>
            <CounterWidgetContent
              title={
                <AppTypography
                  isInheritFontStyle
                  decoration={{ variant: "helpText" }}
                  tooltipProps={{ title: "Asset subscriptions with not allocated assets." }}
                >
                  Awaiting allocation
                </AppTypography>
              }
              icon={<AppIcon of='pending' />}
              isLoading={dataRequest.isLoading}
              counter={data?.assetSubscriptionAwaitingAllocation?.count}
              counterLinkProps={{
                to: ROUTE_PATH.ASSET_SUBSCRIPTIONS,
                state: cast<AssetSubscriptionListPageLocationState>({
                  initialValues: {
                    filterDefinitionDto:
                      data?.assetSubscriptionAwaitingAllocation?.filterDefinition,
                    filterType: ContractProductsSubscriptionsStateFilterType.All,
                  },
                }),
              }}
            />
          </CounterWidgetCard>

          <CounterWidgetCard>
            <CounterWidgetContent
              title={
                <AppTypography
                  isInheritFontStyle
                  decoration={{ variant: "helpText" }}
                  tooltipProps={{ title: "Contracts that are ready to check-out." }}
                >
                  Ready to check-out
                </AppTypography>
              }
              icon={<AppIcon of='checkOut' />}
              isLoading={dataRequest.isLoading}
              counter={data?.contractCanCheckOut?.count}
              counterLinkProps={{
                to: ROUTE_PATH.CONTRACTS,
                state: cast<ContractListPageLocationState>({
                  initialValues: {
                    isResetFilterDefinition: true,
                    filterType: ContractFilterType.CheckOut,
                  },
                }),
              }}
            />
          </CounterWidgetCard>

          <CounterWidgetCard>
            <CounterWidgetContent
              title={
                <AppTypography
                  isInheritFontStyle
                  decoration={{ variant: "helpText" }}
                  tooltipProps={{ title: "Contracts that are ready to check-in." }}
                >
                  Ready to check-in
                </AppTypography>
              }
              icon={<AppIcon of='checkIn' />}
              isLoading={dataRequest.isLoading}
              counter={data?.contractCanCheckIn?.count}
              counterLinkProps={{
                to: ROUTE_PATH.CONTRACTS,
                state: cast<ContractListPageLocationState>({
                  initialValues: {
                    isResetFilterDefinition: true,
                    filterType: ContractFilterType.CheckIn,
                  },
                }),
              }}
            />
          </CounterWidgetCard>

          <CounterWidgetCard>
            <CounterWidgetContent
              title={
                <AppTypography
                  isInheritFontStyle
                  decoration={{ variant: "helpText" }}
                  tooltipProps={{ title: "Vehicles with returning status." }}
                >
                  Returning
                </AppTypography>
              }
              icon={<AppIcon of='data' />}
              isLoading={dataRequest.isLoading}
              counter={data?.vehicleReturning?.count}
              counterLinkProps={{
                to: ROUTE_PATH.VEHICLES,
                state: cast<VehicleListPageLocationState>({
                  initialValues: {
                    filterDefinitionDto: data?.vehicleReturning?.filterDefinition,
                  },
                }),
              }}
            />
          </CounterWidgetCard>

          <CounterWidgetCard>
            <CounterWidgetContent
              title={
                <AppTypography
                  isInheritFontStyle
                  decoration={{ variant: "helpText" }}
                  tooltipProps={{ title: "Assessment cases (damage & approvals) with issues." }}
                >
                  Cases with issues
                </AppTypography>
              }
              icon={<AppIcon of='data' />}
              isLoading={dataRequest.isLoading}
              counter={data?.assessmentWithIssues?.count}
              counterLinkProps={{
                to: ROUTE_PATH.ASSESSMENT_FLOWS(),
                state: cast<AssessmentFlowListPageLocationState>({
                  initialValues: {
                    filterDefinitionDto: data?.assessmentWithIssues?.filterDefinition,
                    filterType: ContractAssessmentFlowStateFilterType.All,
                  },
                }),
              }}
            />
          </CounterWidgetCard>
        </CounterWidgetCardGrid>
      </WidgetContent>
    </BaseWidget>
  );
}
