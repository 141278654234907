import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormHelperText,
  ModalProps,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";

import ContractAutocomplete from "@/common/components/Entity/Contract/ContractAutocomplete";
import ContractLink from "@/common/components/Entity/Contract/ContractLink";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { ContractDto, ContractStage, DamageCostEvaluationDto } from "@/core/api/generated";

import GeneralValidationError from "../../Error/GeneralValidationError";

type Props = Pick<ModalProps, "open" | "onClose"> & {
  damageCostEvaluation: DamageCostEvaluationDto;
  onSaved?: (newValue: DamageCostEvaluationDto) => void;
};

function AddDamageCostEvaluationToContractModal({
  open,
  onClose,
  damageCostEvaluation,
  onSaved,
}: Props) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const [__, setContract] = useState<ContractDto | undefined>(undefined);

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={onClose}>
      <AppModalTitle onCloseClicked={() => onClose && onClose({}, "escapeKeyDown")}>
        Add damage cost evaluation to a contract
      </AppModalTitle>
      <DialogContent>
        {damageCostEvaluation.contract && (
          <Alert severity='info' sx={{ mb: 1 }}>
            This damage cost evaluation is already added to the contract{" "}
            <ContractLink entity={damageCostEvaluation.contract} />
          </Alert>
        )}

        <DialogContentText
          sx={{ mb: 1 }}
        >{`Make the damage detection visible for the selected contract.`}</DialogContentText>

        <Box>
          <Formik<
            BaseFormikValues & {
              contractId: string;
              isSendToCustomer: boolean;
            }
          >
            enableReinitialize
            initialValues={{
              contractId: damageCostEvaluation.contract?.id || "",
              isSendToCustomer: true,
              submit: "",
            }}
            validationSchema={Yup.object().shape({
              // contractId: Yup.string().required("Required"),
            })}
            onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
              try {
                const response =
                  await apiClient.damageCostEvaluationsApi.apiV1DamageCostEvaluationsDamageCostEvaluationIdAddContractPut(
                    {
                      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                      damageCostEvaluationId: damageCostEvaluation.id!,
                      addDamageCostEvaluationToContractDto: {
                        contractId: values.contractId,
                        isSendToCustomer: values.isSendToCustomer,
                      },
                    },
                  );
                enqueueSnackbar("Successfully added.", {
                  variant: "success",
                });

                if (mounted.current) {
                  setStatus({ success: true });
                  setSubmitting(false);
                }

                onSaved && onSaved(response.data);
                onClose && onClose({}, "escapeKeyDown");
              } catch (err) {
                if (mounted.current) {
                  ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
                  setStatus({ success: false });
                  setSubmitting(false);
                }
              }

              setSubmitting(false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setErrors,
              setFieldValue,
              setValues,
            }) => {
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <FormControl
                    margin='dense'
                    fullWidth
                    error={Boolean(touched.contractId && errors.contractId)}
                  >
                    <ContractAutocomplete
                      entityId={values.contractId}
                      searchFilters={{
                        vehicleId: damageCostEvaluation?.vehicle?.id || undefined,
                        excludeStage: ContractStage.Draft,
                      }}
                      onChange={async (newValue) => {
                        setFieldValue(`contractId`, newValue?.id);
                        setContract(newValue);
                      }}
                    />
                    <FormHelperText>{touched.contractId && errors.contractId}</FormHelperText>
                  </FormControl>

                  <FormControl margin='dense' fullWidth>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isSendToCustomer}
                          onChange={(e) => setFieldValue("isSendToCustomer", e.target.checked)}
                        />
                      }
                      label='Send to customer'
                    />
                  </FormControl>

                  <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                  <Button
                    sx={{ flex: 1, mt: 1 }}
                    fullWidth
                    color='primary'
                    disabled={!values.contractId}
                    loading={isSubmitting}
                    type='submit'
                    variant='contained'
                  >
                    Save
                  </Button>
                </form>
              );
            }}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default AddDamageCostEvaluationToContractModal;
