import { Box, Typography } from "@mui/material";

export default function DevDataGrid() {
  return (
    <Box>
      <Typography component='div' variant='h4' sx={{ my: 2 }}>
        DataGrid
      </Typography>
    </Box>
  );
}
