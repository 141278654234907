import { useEffect } from "react";

import { FileUploadSpecDto } from "@/core/api/generated";
import * as filesSlice from "@/store/files/slice";

import { useAppSelector, useAppThunkDispatch } from "../redux";

export const useFileUploadSpec = (): {
  isLoading: boolean;
  spec: Nil<FileUploadSpecDto>;
} => {
  const dispatch = useAppThunkDispatch();
  const isLoading = useAppSelector((x) => x.files.loading.getFileUploadSpec);
  const fileUploadSpec = useAppSelector((x) => x.files.fileUploadSpec);

  useEffect(() => {
    if (!fileUploadSpec) {
      dispatch(filesSlice.getFileUploadSpec());
    }
  }, []);

  return {
    isLoading: isLoading ?? false,
    spec: fileUploadSpec,
  };
};
