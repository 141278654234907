import { Stack } from "@mui/material";
import { useEffect } from "react";

import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import { UrlHelper } from "@/common/helpers/url";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";

export type InviteNotFoundPageEnterQueryParams = GeneralQueryParams;

export default function InviteNotFoundPage() {
  const { ...generalQueryParams } = useQueryParams<InviteNotFoundPageEnterQueryParams>();

  // redirect to target URL
  useEffect(() => {
    if (generalQueryParams.redirectUrl) {
      UrlHelper.redirectToUrl(generalQueryParams.redirectUrl);
    }
  }, [generalQueryParams.redirectUrl]);

  return (
    <Stack spacing={2}>
      <EntityNotFoundAlert
        title='This invitation is not found.'
        iconProps={{ fontSize: "large" }}
        titleProps={{ variant: "h6" }}
      />
    </Stack>
  );
}
