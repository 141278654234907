import { BoxProps, Stack } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  sx?: BoxProps["sx"];
}

export default function AppPopoverActions({ children, sx }: Props) {
  return (
    <Stack
      direction='row'
      spacing={1}
      sx={{
        alignItems: "center",
        justifyContent: "flex-end",
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
}
