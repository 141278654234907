import { Alert, Box, Button, LinearProgress, Stack, Typography } from "@mui/material";
import { ReactNode, useRef, useState } from "react";
import ReactCrop, { Crop, convertToPercentCrop, convertToPixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { ReactZoomPanPinchRef, ReactZoomPanPinchState } from "react-zoom-pan-pinch";

import { FileItem } from "@/common/fileItem";
import { FileHelper } from "@/common/helpers/file";
import { CustomCropInfo, ImageCropHelper } from "@/common/helpers/imageCropHelper";
import { useEffectWithDebounce } from "@/common/hooks/effect/useEffectWithDebounce";
import { apiClient } from "@/core/api/ApiClient";
import { GeneralImageSizeDto } from "@/core/api/generated";

import AppButton from "../Button/AppButton";
import FormActions from "../Form/FormActions";
import ZoomableBlock from "../Images/ZoomableBlock";

const scaleMin = 1;
const scaleMax = 3;
// const scaleStep = 0.1;
// const scaleStepForScroll = scaleStep * 3;

// const rotationDegreeMin = 0;
// const rotationDegreeMax = 360;
// const rotationDegreeStep = 1;

function PixelCropSizeDisplay({ crop }: { crop: CustomCropInfo }) {
  const naturalPixelCrop = ImageCropHelper.getNaturalPixelCrop(crop);
  return (
    <Box component='span'>
      {Math.floor(naturalPixelCrop.width)}x{Math.floor(naturalPixelCrop.height)} px
    </Box>
  );
}

function PercentCropSizeDisplay({ crop }: { crop: CustomCropInfo }) {
  const naturalPercentCrop = ImageCropHelper.getNaturalPercentCrop(crop);
  return (
    <Box component='span'>
      {Math.floor(naturalPercentCrop.width * (crop.imageRef.current?.naturalWidth ?? 0))}x
      {Math.floor(naturalPercentCrop.height * (crop.imageRef.current?.naturalHeight ?? 0))} px
    </Box>
  );
}

function CropSizeDisplay({ crop }: { crop: CustomCropInfo }) {
  return crop.crop.unit === "px" ? (
    <PixelCropSizeDisplay crop={crop} />
  ) : crop.crop.unit === "%" ? (
    <PercentCropSizeDisplay crop={crop} />
  ) : null;
}

function CroppedImageContainer({ children }: { children: ReactNode }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // use black-grey gradient bg so any image can contrast on it
        background: "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(172,172,172,1) 100%)",
        width: "100%",
        height: "auto",
        p: 4,
        borderRadius: (th) => th.shapeCustom.borderRadius,
      }}
    >
      {children}
    </Box>
  );
}

interface CropPropsComputed {
  image: {
    size: GeneralImageSizeDto;
    naturalSize: GeneralImageSizeDto;
  };
  /** Crop area sizes relatively to the image natural size. */
  cropSizes: {
    inPx: {
      size: GeneralImageSizeDto;
      minSize?: GeneralImageSizeDto;
      maxSize?: GeneralImageSizeDto;
    };
    inPercents: {
      size: GeneralImageSizeDto;
      minSize?: GeneralImageSizeDto;
      maxSize?: GeneralImageSizeDto;
    };
  };
  /** Props for Crop type. */
  cropProps: Crop;
  /** Props for <ReactCrop />.
   * NB: Sizes below are absolute sizes in px, not sizes in px relatively to the image natural size.
   */
  cropComponentProps: {
    minWidth?: number;
    minHeight?: number;
    maxWidth?: number;
    maxHeight?: number;
  };
}

export interface ImageSimpleEditorCropProps {
  /** An initial crop width/height, in pixels relatively to image natural size. */
  initialSize?: GeneralImageSizeDto | null;
  /** An minimum crop width/height, in pixels relatively to image natural size. */
  minSize?: GeneralImageSizeDto | null;
  /** An maximum crop width/height, in pixels relatively to image natural size. */
  maxSize?: GeneralImageSizeDto | null;
  /** If true then the user cannot resize or draw a new crop. */
  isDisabled?: boolean;
  /** If true then the user cannot create or resize a crop, but can still drag the existing crop around. */
  isLocked?: boolean;
}

export interface ImageSimpleEditorProps {
  fileItem: FileItem;
  cropProps?: ImageSimpleEditorCropProps;
  onCancel: () => void;
  /** New file is re-uploaded automatically.  */
  onSave: (newFileItem: FileItem) => void;
}

/** Simple image editor.
 *  Uses https://www.npmjs.com/package/react-image-crop
 */
export default function ImageSimpleEditor({
  fileItem,
  cropProps,
  onCancel,
  onSave,
}: ImageSimpleEditorProps) {
  const editorContainerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null!);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const cropPropsComputedRef = useRef<CropPropsComputed | undefined>(undefined);
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);
  const zoomPanPinchStateRef = useRef<ReactZoomPanPinchState | null>(null);

  const [isLoading, __] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  // const [size, setSize] = useState<GeneralImageSizeDto | undefined>(undefined);
  const [scale, setScale] = useState(1);
  const [rotationDegree, ___] = useState(0);
  const [crop, setCrop] = useState<CustomCropInfo | undefined>(undefined);
  const [completedCrop, setCompletedCrop] = useState<CustomCropInfo | undefined>(undefined);

  // handle cropped image preview
  useEffectWithDebounce(
    async () => {
      if (completedCrop && imageRef.current && previewCanvasRef.current) {
        // use canvas preview as it's much faster than imgPreview.
        ImageCropHelper.renderCanvasPreviewOfCroppedImage({
          image: imageRef.current,
          canvas: previewCanvasRef.current,
          crop: completedCrop,
          scale: scale,
          rotationDegree: rotationDegree,
        });
      }
    },
    100,
    undefined,
    [completedCrop, scale, rotationDegree],
  );

  const getCropPropsComputed = (): CropPropsComputed | undefined => {
    if (!imageRef.current) {
      return undefined;
    }

    // correctly calculate crop area size so it corresponds to the image size in px
    const cropSizeInPx = {
      width: Math.min(
        (completedCrop?.pixelCrop?.width ?? cropProps?.initialSize?.width ?? 50) * scale,
        imageRef.current.naturalWidth,
      ),
      height: Math.min(
        (completedCrop?.pixelCrop?.height ?? cropProps?.initialSize?.height ?? 50) * scale,
        imageRef.current.naturalHeight,
      ),
    };
    const cropMinSizeInPx = cropProps?.minSize
      ? {
          width: Math.max((cropProps?.minSize?.width ?? 0) * scale, 0),
          height: Math.max((cropProps?.minSize?.height ?? 0) * scale, 0),
        }
      : undefined;
    const cropMaxSizeInPx = cropProps?.maxSize
      ? {
          width: Math.min((cropProps?.maxSize?.width ?? 0) * scale, imageRef.current.naturalWidth),
          height: Math.min(
            (cropProps?.maxSize?.height ?? 0) * scale,
            imageRef.current.naturalHeight,
          ),
        }
      : undefined;

    const cropSizeInPercents = {
      width: (cropSizeInPx.width / imageRef.current.naturalWidth) * 100,
      height: (cropSizeInPx.height / imageRef.current.naturalHeight) * 100,
    };
    const cropMinSizeInPercents = cropMinSizeInPx
      ? {
          width: (cropMinSizeInPx.width / imageRef.current.naturalWidth) * 100,
          height: (cropMinSizeInPx.height / imageRef.current.naturalHeight) * 100,
        }
      : undefined;
    const cropMaxSizeInPercents = cropMaxSizeInPx
      ? {
          width: (cropMaxSizeInPx.width / imageRef.current.naturalWidth) * 100,
          height: (cropMaxSizeInPx.height / imageRef.current.naturalHeight) * 100,
        }
      : undefined;

    let xInPercents = (completedCrop?.percentCrop?.x ?? 0) * scale;
    let yInPercents = (completedCrop?.percentCrop?.y ?? 0) * scale;
    if (xInPercents + cropSizeInPercents.width > 100) {
      xInPercents = Math.max(100 - cropSizeInPercents.width, 0);
    }
    if (yInPercents + cropSizeInPercents.height > 100) {
      yInPercents = Math.max(100 - cropSizeInPercents.height, 0);
    }

    const result: CropPropsComputed = {
      image: {
        size: {
          width: imageRef.current.width,
          height: imageRef.current.height,
        },
        naturalSize: {
          width: imageRef.current.naturalWidth,
          height: imageRef.current.naturalHeight,
        },
      },
      cropSizes: {
        inPx: {
          size: cropSizeInPx,
          minSize: cropMinSizeInPx,
          maxSize: cropMaxSizeInPx,
        },
        inPercents: {
          size: cropSizeInPercents,
          minSize: cropMinSizeInPercents,
          maxSize: cropMaxSizeInPercents,
        },
      },
      cropProps: {
        unit: "%",
        width: cropSizeInPercents.width,
        height: cropSizeInPercents.height,
        x: xInPercents,
        y: yInPercents,
      },
      cropComponentProps: {
        // NB: sizes below are absolute sizes in px, not sizes in px relatively to image natural size
        minWidth: cropMinSizeInPercents
          ? (cropMinSizeInPercents.width / 100) * imageRef.current.width
          : undefined,
        minHeight: cropMinSizeInPercents
          ? (cropMinSizeInPercents.height / 100) * imageRef.current.height
          : undefined,
        maxWidth: cropMaxSizeInPercents
          ? (cropMaxSizeInPercents.width / 100) * imageRef.current.width
          : undefined,
        maxHeight: cropMaxSizeInPercents
          ? (cropMaxSizeInPercents.height / 100) * imageRef.current.height
          : undefined,
      },
    };
    return result;
  };

  const getAndRefreshCropPropsComputed = () => {
    cropPropsComputedRef.current = getCropPropsComputed();
    return cropPropsComputedRef.current;
  };

  const getCustomCropInfo = (): CustomCropInfo | undefined => {
    getAndRefreshCropPropsComputed();
    if (imageRef.current && cropPropsComputedRef.current) {
      const newCrop: Crop = cropPropsComputedRef.current.cropProps;
      return {
        imageRef: imageRef,
        crop: newCrop,
        pixelCrop: convertToPixelCrop(newCrop, imageRef.current.width, imageRef.current.height),
        percentCrop: convertToPercentCrop(newCrop, imageRef.current.width, imageRef.current.height),
        scale: scale,
      };
    }
    return undefined;
  };

  const getAndRefreshCustomCropInfo = (): CustomCropInfo | undefined => {
    const newCustomCropInfo = getCustomCropInfo();
    setCrop(newCustomCropInfo);
    return newCustomCropInfo;
  };

  // const resizeDetectorResult = useResizeDetector<HTMLElement>({
  //   targetRef: editorContainerRef,
  //   onResize: () => {
  //     getAndRefreshCropPropsComputed();
  //   },
  // });

  // NB: this event might fire many time on rerenders
  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (!imageRef.current) {
      return;
    }

    getAndRefreshCropPropsComputed();
    if (!cropPropsComputedRef.current) {
      return;
    }

    console.log("onImageLoad", cropProps, cropPropsComputedRef.current);

    if (!crop) {
      getAndRefreshCustomCropInfo();
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSave = async () => {
    if (imageRef.current && completedCrop && previewCanvasRef.current) {
      try {
        setIsSaving(true);

        // build cropped image
        const mimeType = fileItem.mimeType || "image/png";
        const blob = await ImageCropHelper.buildCroppedImage({
          image: imageRef.current,
          previewCanvas: previewCanvasRef.current,
          crop: completedCrop,
          imageEncode: {
            type: mimeType,
            quality: 1,
          },
        });
        // const dataUrl = URL.createObjectURL(blob);
        const file = FileHelper.createFileFromBlob(blob, fileItem.fileName || "", mimeType);

        // upload new image
        const response = await apiClient.filesApi.apiV1FilesUploadPost({ files: [file] });
        if (response.request.status !== 200) {
          throw response;
        }

        const uploadedFile = response.data.files?.at(0);
        if (!uploadedFile) {
          throw new Error(`Can't find uploaded file.`);
        }
        onSave(FileItem.createFrom(uploadedFile));
      } catch (err) {
        console.error(err);
      } finally {
        setIsSaving(false);
      }
    }
  };

  const handleNewScale = (newScale: number, isReactZoomPanPinch: boolean) => {
    newScale = Math.min(newScale, scaleMax);
    newScale = Math.max(newScale, scaleMin);
    if (newScale === scale) {
      return;
    }
    setScale(newScale);

    // sync our scale with ReactZoomPanPinch scale
    if (!isReactZoomPanPinch && transformComponentRef.current) {
      const state: ReactZoomPanPinchState | undefined =
        transformComponentRef.current.state || zoomPanPinchStateRef.current;
      if (newScale !== state?.scale) {
        transformComponentRef.current.setTransform(
          state?.positionX ?? 0,
          state?.positionY ?? 0,
          newScale,
        );
      }
    }

    // apply new scale to the crop
    getAndRefreshCustomCropInfo();
  };

  // const handleNewRotationDegree = (newRotationDegree: number) => {
  //   newRotationDegree = Math.min(newRotationDegree, rotationDegreeMax);
  //   newRotationDegree = Math.max(newRotationDegree, rotationDegreeMin);
  //   setRotationDegree(newRotationDegree);
  // };

  const handleReactZooPanPinchTransformed = (
    ref: ReactZoomPanPinchRef,
    state: {
      scale: number;
      positionX: number;
      positionY: number;
    },
  ) => {
    // const prevState = zoomPanPinchStateRef.current;
    const newState = {
      ...state,
      previousScale: zoomPanPinchStateRef.current?.scale ?? scale,
    };
    zoomPanPinchStateRef.current = newState;
    // console.log("handleReactZooPanPinchTransformed", { state, prevState, newState });

    handleNewScale(newState.scale, true);

    // const isPositionChanged =
    //   newState.positionX !== prevState?.positionX || newState.positionY !== prevState?.positionY;
    // if (isPositionChanged) {
    //   const newCustomCropInfo = getCustomCropInfo();
    //   setCrop(newCustomCropInfo);
    // }
  };

  return (
    <Stack spacing={2}>
      {isLoading && <LinearProgress />}

      <Alert severity='info'>
        <Stack spacing={1}>
          <Box>Use mouse to select desired image area.</Box>
          {(cropProps?.minSize || cropProps?.maxSize) && (
            <Box>
              <Box>Limits:</Box>
              {cropProps?.minSize && (
                <Box>
                  Min size - {cropProps?.minSize?.width}x{cropProps?.minSize?.height} px
                </Box>
              )}
              {cropProps?.maxSize && (
                <Box>
                  Max size - {cropProps?.maxSize?.width}x{cropProps?.maxSize?.height} px
                </Box>
              )}
            </Box>
          )}
        </Stack>
      </Alert>

      <Box sx={{ display: "grid", gridTemplateColumns: { xxs: "1fr", lg: "1fr 1fr" }, gap: 2 }}>
        <Stack spacing={1}>
          <Typography variant='subtitle1'>
            Original - {imageRef.current?.naturalWidth}x{imageRef.current?.naturalHeight} px
          </Typography>

          {/* Editor */}
          <Box ref={editorContainerRef}>
            <CroppedImageContainer>
              <ReactCrop
                crop={crop?.percentCrop}
                aspect={undefined}
                keepSelection // If true is passed then selection can't be disabled if the user clicks outside the selection area.
                circularCrop={false}
                disabled={cropProps?.isDisabled}
                locked={cropProps?.isLocked}
                // NB: sizes below are absolute sizes in px, not sizes in px relatively to image natural size
                minWidth={cropPropsComputedRef.current?.cropComponentProps?.minWidth}
                minHeight={cropPropsComputedRef.current?.cropComponentProps?.minHeight}
                maxWidth={cropPropsComputedRef.current?.cropComponentProps?.maxWidth}
                maxHeight={cropPropsComputedRef.current?.cropComponentProps?.maxHeight}
                onChange={(pixelCrop, percentCrop) => {
                  setCrop({
                    imageRef: imageRef,
                    crop: percentCrop,
                    pixelCrop,
                    percentCrop,
                    scale: scale,
                  });
                }}
                onComplete={(pixelCrop, percentCrop) => {
                  if (imageRef.current) {
                    const { width, height } = imageRef.current;
                    setCompletedCrop({
                      imageRef: imageRef,
                      crop: pixelCrop,
                      pixelCrop,
                      percentCrop,
                      scale: scale,
                    });
                    console.log(
                      "onComplete",
                      pixelCrop,
                      percentCrop,
                      convertToPixelCrop(percentCrop, width, height),
                    );
                  }
                }}
              >
                <ZoomableBlock
                  wrapperRef={transformComponentRef}
                  disabled
                  initialScale={scale}
                  minScale={scaleMin}
                  maxScale={scaleMax}
                  initialPositionX={0}
                  initialPositionY={0}
                  onZoom={(ref, e) => {
                    // setScale(ref.state.scale);
                    handleNewScale(ref.state.scale, true);
                  }}
                  onTransformed={(ref, state) => {
                    handleReactZooPanPinchTransformed(ref, state);
                  }}
                >
                  <Box
                    component='img'
                    sx={{ width: "100%" }}
                    ref={imageRef}
                    src={fileItem.fileUrl}
                    // style={{ transform: `scale(${scale}) rotate(${rotationDegree}deg)` }}
                    // send CORS request.
                    // image data from a CORS-enabled image returned from a CORS request can be reused in the <canvas> element without being marked "tainted"
                    crossOrigin='anonymous'
                    onLoad={onImageLoad}
                  />
                </ZoomableBlock>
              </ReactCrop>
            </CroppedImageContainer>
          </Box>
        </Stack>

        <Stack spacing={1}>
          <Typography variant='subtitle1'>
            Result - {completedCrop && <CropSizeDisplay crop={completedCrop} />}
            {/* <Box>{completedCrop && <CropSizeDisplay crop={completedCrop} />}</Box>
            <Box>{completedCrop && <PixelCropSizeDisplay crop={completedCrop} />}</Box>
            <Box>{completedCrop && <PercentCropSizeDisplay crop={completedCrop} />}</Box> */}
          </Typography>

          {/* Image preview */}
          {completedCrop && (
            <Box>
              <CroppedImageContainer>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    objectFit: "contain",
                    width: completedCrop.pixelCrop.width,
                    height: completedCrop.pixelCrop.height,
                  }}
                />
              </CroppedImageContainer>
            </Box>
          )}
        </Stack>
      </Box>

      {/* TODO: disable controls because scale must be handled correctly first */}
      {/* Controls */}
      {/* <Box>
        <FormControl fullWidth margin='dense'>
          <FormLabel>Scale</FormLabel>
          <Stack direction='row' spacing={2} alignItems='center'>
            <IconButton size='small' onClick={() => handleNewScale(scale - scaleStep, false)}>
              <AppIcon of='zoomOut' />
            </IconButton>
            <Slider
              aria-label='Scale'
              min={scaleMin}
              max={scaleMax}
              step={scaleStep}
              value={scale}
              onChange={(e, newValue) =>
                handleNewScale(_.isArray(newValue) ? newValue[0] : newValue, false)
              }
            />
            <IconButton size='small' onClick={() => handleNewScale(scale + scaleStep, false)}>
              <AppIcon of='zoomIn' />
            </IconButton>
          </Stack>
        </FormControl>

        <FormControl fullWidth margin='dense'>
          <FormLabel>Rotate</FormLabel>
          <Stack direction='row' spacing={2} alignItems='center'>
            <IconButton
              size='small'
              onClick={() => handleNewRotationDegree(rotationDegree - rotationDegreeStep)}
            >
              <AppIcon of='rotateLeft' />
            </IconButton>
            <Slider
              aria-label='Rotate'
              min={rotationDegreeMin}
              max={rotationDegreeMax}
              step={rotationDegreeStep}
              value={rotationDegree}
              onChange={(e, newValue) =>
                handleNewRotationDegree(_.isArray(newValue) ? newValue[0] : newValue)
              }
            />
            <IconButton
              size='small'
              onClick={() => handleNewRotationDegree(rotationDegree + rotationDegreeStep)}
            >
              <AppIcon of='rotateRight' />
            </IconButton>
          </Stack>
        </FormControl>
      </Box> */}

      <FormActions>
        <Button variant='outlined' color='text' onClick={handleCancel}>
          Cancel
        </Button>

        <AppButton variant='contained' color='primary' loading={isSaving} onClick={handleSave}>
          Save
        </AppButton>
      </FormActions>
    </Stack>
  );
}
