import { ListItemIcon, ListItemText, Stack } from "@mui/material";
import { ReactNode } from "react";

import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import EntityMenu from "@/common/components/EntityMenu/EntityMenu";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { ROUTE_PATH } from "@/common/constants/routing";
import { AppPermission, GeneralStatusHistoryDto } from "@/core/api/generated";

import GeneralStatusHistoryViewModal from "./View/GeneralStatusHistoryViewModal";

enum ActionFlags {
  View = "View",
}

const defaultDisplayProps = {
  actions: {
    view: true,
    viewInNewTab: true,
  },
};

interface TriggersProps {
  entity: GeneralStatusHistoryDto | null | undefined;
  definition: typeof ActionFlags;
  handleClose: () => void;
  startAction: (props: ActionFlags) => void;
}

interface Props {
  entity: GeneralStatusHistoryDto | null | undefined;
  disabled?: boolean;
  displayProps?: Partial<typeof defaultDisplayProps>;
  triggers?: (props: TriggersProps) => ReactNode;
  onUpdated: (newValue: GeneralStatusHistoryDto) => void;
  onDeleted: (newValue: GeneralStatusHistoryDto) => void;
}

export default function GeneralStatusHistoryMenu({
  entity,
  disabled,
  triggers,
  displayProps = defaultDisplayProps,
  onUpdated,
  onDeleted,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const canView = true;
  const isMenuEnabled = canView;

  return (
    <EntityMenu
      isEnabled={isMenuEnabled}
      definition={ActionFlags}
      entity={entity}
      disabled={disabled}
      actionTriggers={({ handleClose, startAction }) => (
        <>
          {triggers &&
            triggers({
              entity,
              definition: ActionFlags,
              handleClose,
              startAction,
            })}

          {displayProps?.actions?.view && (
            <AuthorizedMenuItem
              permissions={[AppPermission.FleetAppAccess, AppPermission.GeneralStatusHistoryRead]}
              onClick={() => {
                startAction(ActionFlags.View);
                handleClose();
              }}
            >
              <ListItemIcon>
                <AppIcon of='view' fontSize='small' />
              </ListItemIcon>
              <ListItemText>View</ListItemText>
            </AuthorizedMenuItem>
          )}

          {displayProps?.actions?.viewInNewTab && (
            <AuthorizedMenuItem
              permissions={[AppPermission.FleetAppAccess, AppPermission.GeneralStatusHistoryRead]}
              component={AppLink}
              to={ROUTE_PATH.GENERAL_STATUS_HISTORY_VIEW(entity?.id)}
              target='_blank'
            >
              <ListItemIcon>
                <AppIcon of='openInNew' fontSize='small' />
              </ListItemIcon>
              <ListItemText>View in new tab</ListItemText>
            </AuthorizedMenuItem>
          )}
        </>
      )}
      actionHandlers={({ currentAction, cancelAction, completeAction }) => (
        <Stack>
          {/* View */}
          {entity && (
            <GeneralStatusHistoryViewModal
              open={currentAction === ActionFlags.View}
              onClose={() => cancelAction()}
              viewProps={{
                generalStatusHistoryId: entity.id,
                generalStatusHistory: undefined,
                onUpdated: (newValue) => {
                  onUpdated(newValue);
                  completeAction();
                },
                onDeleted: () => {
                  onDeleted(entity);
                  completeAction();
                },
              }}
            />
          )}
        </Stack>
      )}
    />
  );
}
