import { RefObject } from "react";
import { Crop, PercentCrop, PixelCrop } from "react-image-crop";

const DEGREE_TO_RADIANS = Math.PI / 180;

export interface CustomCropInfo {
  imageRef: RefObject<HTMLImageElement>;
  /** Any of the crops below. */
  crop: Crop;
  /** Absolute value in px, not relatively to image natural size */
  pixelCrop: PixelCrop;
  /** Relatively to image natural size. */
  percentCrop: PercentCrop;
  scale: number;
}

export class ImageCropHelper {
  /** Converts PixelCrop in absolute px values to px values relatively to image natural size.  */
  public static getNaturalPixelCrop(crop: CustomCropInfo): PixelCrop {
    // const pixelRatio = window.devicePixelRatio;

    // scales image from rendered size to natural
    const scaleX =
      (crop.imageRef.current?.naturalWidth ?? 0) / (crop.imageRef.current?.width ?? 1) / crop.scale;
    const scaleY =
      (crop.imageRef.current?.naturalHeight ?? 0) /
      (crop.imageRef.current?.height ?? 1) /
      crop.scale;

    return {
      unit: "px",
      width: Math.floor(crop.pixelCrop.width * scaleX),
      height: Math.floor(crop.pixelCrop.height * scaleY),
      x: Math.floor(crop.pixelCrop.x * scaleX),
      y: Math.floor(crop.pixelCrop.y * scaleY),
    };
  }

  /** Converts PixelCrop in absolute px values to px values relatively to image natural size.  */
  public static getNaturalPercentCrop(crop: CustomCropInfo): PixelCrop {
    // scales image from rendered size to natural
    const scaleX = (crop.imageRef.current?.naturalWidth ?? 0) / 100 / crop.scale;
    const scaleY = (crop.imageRef.current?.naturalHeight ?? 0) / 100 / crop.scale;

    return {
      unit: "px",
      width: crop.pixelCrop.width * scaleX,
      height: crop.pixelCrop.height * scaleY,
      x: crop.pixelCrop.x * scaleX,
      y: crop.pixelCrop.y * scaleY,
    };
  }

  /** Renders preview of the image in the canvas. */
  public static renderCanvasPreviewOfCroppedImage({
    image,
    canvas,
    crop,
    scale = 1,
    rotationDegree = 0,
  }: {
    image: HTMLImageElement;
    canvas: HTMLCanvasElement;
    crop: CustomCropInfo;
    scale: number;
    rotationDegree: number;
  }): void {
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      throw new Error("No 2d context");
    }

    // scales image from rendered size to natural
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    // const pixelRatio = window.devicePixelRatio;
    const pixelRatio = 1;

    canvas.width = Math.floor((crop.pixelCrop.width * scaleX * pixelRatio) / scale);
    canvas.height = Math.floor((crop.pixelCrop.height * scaleY * pixelRatio) / scale);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";

    const cropX = crop.pixelCrop.x * scaleX;
    const cropY = crop.pixelCrop.y * scaleY;

    const rotateRads = rotationDegree * DEGREE_TO_RADIANS;
    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY);
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY);
    // 3) Rotate around the origin
    ctx.rotate(rotateRads);
    // 2) Scale the image
    ctx.scale(scale, scale);
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
    );

    ctx.restore();
  }

  /** Builds result cropped image. */
  public static async buildCroppedImage({
    image,
    previewCanvas,
    crop,
    imageEncode,
  }: {
    image: HTMLImageElement;
    previewCanvas: HTMLCanvasElement;
    crop: CustomCropInfo;
    imageEncode: ImageEncodeOptions;
  }): Promise<Blob> {
    if (!image || !previewCanvas || !crop) {
      throw new Error("Crop canvas does not exist");
    }

    // This will size relative to the uploaded image size.
    // If you want to size according to what they are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreenCanvas = new OffscreenCanvas(
      crop.pixelCrop.width * scaleX,
      crop.pixelCrop.height * scaleY,
    );
    const ctx = offscreenCanvas.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreenCanvas.width,
      offscreenCanvas.height,
    );
    // You might want { type: "image/jpeg", quality: <0 to 1> } to reduce image size
    const blob = await offscreenCanvas.convertToBlob(imageEncode);

    return blob;
  }
}
