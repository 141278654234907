import { CardContent, CardContentProps } from "@mui/material";
import { forwardRef } from "react";

import { AppCardProps } from "./AppCard";

export interface AppCardContentProps extends CardContentProps {}

/** Extends MUI's CardContent. */
export default forwardRef<HTMLDivElement, AppCardProps>(function AppCardContent(
  { sx, children, ...otherProps }: AppCardContentProps,
  ref,
) {
  return (
    <CardContent
      ref={ref}
      sx={{
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </CardContent>
  );
});
