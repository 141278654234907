import RepairOperationPaginatedList from "@/common/components/Entity/RepairOperation/ListView/RepairOperationPaginatedList";
import { useLocationState } from "@/common/hooks/useLocationParams";
import { FilterDefinitionDto } from "@/core/api/generated";

export interface RepairOperationListPageLocationState {
  initialValues?: {
    filterDefinitionDto?: Nil<FilterDefinitionDto>;
  };
}

export default function RepairOperationsPage() {
  const locationState = useLocationState<RepairOperationListPageLocationState>();

  return (
    <>
      <RepairOperationPaginatedList initialValues={locationState?.initialValues} />
    </>
  );
}
