import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid2,
  LinearProgress,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import _ from "lodash";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AdminCreateVehicleGenerationDto,
  AdminUpdateVehicleGenerationDto,
} from "@/core/api/generated";

import VehicleMakeAutocomplete from "../../components/ReferenceData/VehicleMake/VehicleMakeAutocomplete";
import VehicleModelAutocomplete from "../../components/ReferenceData/VehicleModel/VehicleModelAutocomplete";
import ReferenceDataSyncStopAlert from "../ReferenceDataSyncStopAlert";

export default function VehicleGenerationCreateUpdatePage() {
  const { generationId } = useParams<{ generationId?: string }>();
  const mounted = useMounted();
  const history = useHistory();

  const isCreate = !generationId;
  const isEdit = !!generationId;

  const generationRequest = useApiRequest(
    apiClient.adminVehicleGenerationsApi.apiV1AdminReferenceDataVehiclesGenerationsGenerationIdGet,
    {
      generationId: generationId!,
    },
    {
      skip: !generationId,
    },
  );
  const generation = generationRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: generation && {
      idValue: generation.id!,
      newTitle: generation.name || generation.globalNumber || "",
    },
  });

  if (isEdit && generationRequest.isLoading) {
    return <LinearProgress />;
  }
  if (isEdit && !generationRequest.isLoading && !generation) {
    return <EntityNotFoundAlert />;
  }

  return (
    <Formik<AdminCreateVehicleGenerationDto & AdminUpdateVehicleGenerationDto & BaseFormikValues>
      enableReinitialize={!isCreate}
      initialValues={{
        makeId: generation?.make?.id || "",
        modelId: generation?.model?.id || "",
        name: generation?.name || "",
        startYear: generation?.startYear || undefined,
        endYear: generation?.endYear || undefined,
        isEnabled: generation?.isEnabled ?? true,
        sortOrder: generation?.sortOrder || 0,
        submit: "",
      }}
      validationSchema={Yup.object().shape({
        // vehicleType: Yup.string().required("This field is required"),
        // name: Yup.string().required("This field is required"),
      })}
      onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
        try {
          if (isCreate) {
            await apiClient.adminVehicleGenerationsApi.apiV1AdminReferenceDataVehiclesGenerationsPost(
              {
                adminCreateVehicleGenerationDto: {
                  ..._.omit(values, "submit"),
                  startYear: _.isNil(values.startYear) ? undefined : +values.startYear,
                  endYear: _.isNil(values.endYear) ? undefined : +values.endYear,
                },
              },
            );
          } else {
            await apiClient.adminVehicleGenerationsApi.apiV1AdminReferenceDataVehiclesGenerationsGenerationIdPut(
              {
                generationId,
                adminUpdateVehicleGenerationDto: {
                  ..._.omit(values, "submit"),
                  startYear: _.isNil(values.startYear) ? undefined : +values.startYear,
                  endYear: _.isNil(values.endYear) ? undefined : +values.endYear,
                },
              },
            );
          }

          if (mounted.current) {
            setStatus({ success: true });
          }
          history.goBack();
        } catch (err: any) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
          }
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
        setFieldValue,
        setValues,
      }) => {
        return (
          <CreateUpdatePageLayout
            header={
              <SimpleViewPageHeader
                title={isCreate ? "Create new generation" : "Edit generation"}
              />
            }
          >
            <form noValidate onSubmit={handleSubmit}>
              {generation && isEdit && (
                <ReferenceDataSyncStopAlert
                  sx={{ mb: 2 }}
                  isBuiltIn={generation.isBuiltIn ?? false}
                  car2DbId={generation.car2DbId}
                />
              )}

              <Box>
                <Grid2 container columnSpacing={2} rowSpacing={0}>
                  <Grid2 size={{ xxs: 12, md: 6 }}>
                    <FormControl margin='normal' fullWidth>
                      <VehicleMakeAutocomplete
                        disabled={isEdit}
                        entityId={values.makeId}
                        required
                        textFieldProps={{
                          error: Boolean(errors.makeId),
                          helperText: ValidationHelper.getFormikErrorsAsString(errors.makeId),
                        }}
                        onChange={(newValue) => {
                          setFieldValue("makeId", newValue?.id || undefined);
                          setFieldValue("modelId", undefined);
                        }}
                      />
                    </FormControl>
                  </Grid2>
                  <Grid2 size={{ xxs: 12, md: 6 }}>
                    <FormControl margin='normal' fullWidth>
                      <VehicleModelAutocomplete
                        disabled={isEdit || !values.makeId}
                        entityId={values.modelId}
                        required
                        searchFilters={{
                          makeId: values.makeId,
                        }}
                        textFieldProps={{
                          error: Boolean(errors.modelId),
                          helperText: ValidationHelper.getFormikErrorsAsString(errors.modelId),
                        }}
                        onChange={(newValue) => {
                          setFieldValue("modelId", newValue?.id || undefined);
                        }}
                      />
                    </FormControl>
                  </Grid2>
                </Grid2>

                <TextField
                  required
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label='Name'
                  margin='normal'
                  name='name'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.name}
                  variant='outlined'
                />

                <Grid2 container columnSpacing={2} rowSpacing={0}>
                  <Grid2 size={{ xxs: 12, md: 6 }}>
                    <TextField
                      sx={{ minWidth: 200 }}
                      autoFocus
                      error={Boolean(touched.startYear && errors.startYear)}
                      fullWidth
                      helperText={touched.startYear && errors.startYear}
                      label='Start year'
                      margin='normal'
                      name='startYear'
                      onBlur={handleBlur}
                      onChange={(e) =>
                        setFieldValue(
                          "startYear",
                          !_.isNil(e.target.value) && e.target.value !== ""
                            ? +e.target.value
                            : undefined,
                        )
                      }
                      inputMode='numeric'
                      value={values.startYear}
                      variant='outlined'
                    />
                  </Grid2>
                  <Grid2 size={{ xxs: 12, md: 6 }}>
                    <TextField
                      sx={{ minWidth: 200 }}
                      autoFocus
                      error={Boolean(touched.endYear && errors.endYear)}
                      fullWidth
                      helperText={touched.endYear && errors.endYear}
                      label='End year'
                      margin='normal'
                      name='endYear'
                      onBlur={handleBlur}
                      onChange={(e) =>
                        setFieldValue(
                          "endYear",
                          !_.isNil(e.target.value) && e.target.value !== ""
                            ? +e.target.value
                            : undefined,
                        )
                      }
                      inputMode='numeric'
                      value={values.endYear}
                      variant='outlined'
                    />
                  </Grid2>
                </Grid2>
                <TextField
                  autoFocus
                  rows={2}
                  error={Boolean(touched.sortOrder && errors.sortOrder)}
                  fullWidth
                  helperText={touched.sortOrder && errors.sortOrder}
                  label='SortOrder'
                  margin='normal'
                  name='sortOrder'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputMode='numeric'
                  value={values.sortOrder || 0}
                  variant='outlined'
                  InputProps={{ inputProps: { min: 0, max: 10000000 } }}
                />

                <FormControl error={Boolean(errors.isEnabled)}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='isEnabled'
                        checked={values.isEnabled}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    }
                    label='Enabled'
                  />
                  <FormHelperText>
                    Whether this reference data entity is enabled for referencing by other entities.
                    When disabled, the entity is unavailable for paginated/search/list requests but
                    available by id for old entities already referencing it.
                  </FormHelperText>
                  {errors.isEnabled && <FormHelperText error>{errors.isEnabled}</FormHelperText>}
                </FormControl>
              </Box>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <Button
                sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
                color='primary'
                loading={isSubmitting}
                fullWidth
                type='submit'
                variant='contained'
              >
                Save
              </Button>
            </form>
          </CreateUpdatePageLayout>
        );
      }}
    </Formik>
  );
}
