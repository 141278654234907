import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputProps,
  Stack,
  SxProps,
  TextFieldProps,
  Theme,
} from "@mui/material";
import { FormikProps } from "formik";
import { useCallback, useMemo, useRef, useState } from "react";

import { PriceHelper } from "@/common/helpers/price";
import { GeneralPriceSummaryInputDisplayProps } from "@/common/ts/lineItems";
import { ValidationHelper } from "@/common/validation";
import {
  DiscountType,
  DiscountValueType,
  GeneralPriceSummaryDto,
  GeneralPriceSummaryInputDto,
  PriceSummaryCalcType,
  TaxType,
  TaxValueType,
} from "@/core/api/generated";

import AppTooltip from "../AppTooltip";
import GeneralDiscountInput, {
  GeneralDiscountInputProps,
} from "../Entity/General/GeneralDiscount/GeneralDiscountInput";
import GeneralInsuranceInput, {
  GeneralInsuranceInputProps,
} from "../Entity/General/GeneralInsurance/GeneralInsuranceInput";
import GeneralPriceInput, {
  GeneralPriceInputProps,
} from "../Entity/General/GeneralPrice/GeneralPriceInput";
import GeneralTaxInput, {
  GeneralTaxInputProps,
} from "../Entity/General/GeneralTax/GeneralTaxInput";
import AppIcon from "../Icons/AppIcon";
import GeneralPriceSummaryDisplay from "./GeneralPriceSummaryDisplay";
import TotalPriceExplanationModal from "./TotalPriceExplanationModal";

const defaultDisplayProps: Omit<GeneralPriceSummaryInputDisplayProps, "calcExplanationPopover"> = {
  subTotal: true,
  discount: true,
  tax: true,
  insurance: false,
  total: true,
  calcExplanationInline: false,
  calcExplanationModal: true,
};

type TextFieldPropsBaseCustomization = {
  label?: TextFieldProps["label"];
  disabled?: InputProps["disabled"];
  required?: InputProps["required"];
  name?: InputProps["name"];
  helperText?: TextFieldProps["helperText"];
};

type ValuesType = GeneralPriceSummaryDto | GeneralPriceSummaryInputDto;

interface OwnProps {
  values: ValuesType;
  formikProps:
    | Partial<{
        errors: FormikProps<ValuesType>["errors"];
      }>
    | undefined
    | null;
  direction?: "column" | "row";
  displayProps?: Partial<typeof defaultDisplayProps>;
  inputsProps?: {
    /** Applied to all inputs */
    all?: {
      size?: InputProps["size"];
      disabled?: InputProps["disabled"];
      required?: InputProps["required"];
      margin?: InputProps["margin"];
    };
    subTotal?: TextFieldPropsBaseCustomization & Pick<GeneralPriceInputProps, "onChange">;
    discount?: TextFieldPropsBaseCustomization & Pick<GeneralDiscountInputProps, "onChange">;
    tax?: TextFieldPropsBaseCustomization &
      Pick<GeneralTaxInputProps, "onChange" | "vehicleTaxCalcRequestParams">;
    insurance?: TextFieldPropsBaseCustomization & Pick<GeneralInsuranceInputProps, "onChange">;
    total?: TextFieldPropsBaseCustomization & Pick<GeneralPriceInputProps, "onChange">;
  };
  sx?: SxProps<Theme>;
  onChange: (newValue: ValuesType | undefined) => void;
}

type Props = OwnProps;

export default function PriceSummaryInput({
  values,
  formikProps,
  direction = "row",
  displayProps = defaultDisplayProps,
  inputsProps = {
    all: {
      margin: "none",
    },
  },
  sx,
  onChange,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const [defaultCalcType, setDefaultCalcType] = useState<PriceSummaryCalcType>(
    PriceSummaryCalcType.BasedOnSubTotal,
  );
  const [isCalcExplanationModalOpen, setIsCalcExplanationModalOpen] = useState(false);
  const newValueRef = useRef<ValuesType | undefined>(values); // used to handle parallel onChange from underlying components

  const { errors } = formikProps || {};

  const columnCount = useMemo(
    () =>
      (displayProps?.subTotal ? 1 : 0) +
      (displayProps?.discount ? 1 : 0) +
      (displayProps?.tax ? 1 : 0) +
      (displayProps?.insurance ? 1 : 0) +
      (displayProps?.total ? 1 : 0),
    [displayProps],
  );

  const handleChange = useCallback(
    (newValue: ValuesType, calcType: PriceSummaryCalcType | null) => {
      // compute fields based on input field
      const newValueComputed = PriceHelper.calcPriceSummaryFromDetailedSummary({
        summary: newValue,
        calcType: calcType,
        defaultCalcType: defaultCalcType,
      });
      if (calcType) {
        setDefaultCalcType(calcType);
      }

      newValueRef.current = {
        ...newValueRef.current,
        ...newValueComputed,
      };

      onChange && onChange(newValueRef.current);
    },
    [defaultCalcType, newValueRef, onChange],
  );

  return (
    <Stack spacing={1} sx={sx}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns:
            direction === "column"
              ? "repeat(1, 1fr) 1fr"
              : {
                  xxs: `repeat(1, 1fr)`,
                  md: `repeat(2, 1fr)`,
                  lg: `repeat(${columnCount}, 1fr) 0fr`,
                },
          columnGap: 1,
          rowGap: 2,
        }}
      >
        {displayProps?.subTotal && (
          <FormControl>
            <GeneralPriceInput
              {...inputsProps?.all}
              {...inputsProps?.subTotal}
              error={Boolean(errors?.subTotal || errors?.currency)}
              helperText={
                errors?.subTotal || errors?.currency
                  ? ValidationHelper.getFormikErrorsAsString(errors.subTotal) ||
                    ValidationHelper.getFormikErrorsAsString(errors.currency)
                  : undefined
              }
              fullWidth
              required={inputsProps?.subTotal?.required ?? inputsProps?.all?.required}
              label={inputsProps?.subTotal?.label ?? "Sub total"}
              margin={inputsProps?.all?.margin}
              variant='outlined'
              allowCurrencyEdit
              useCurrentCurrencyByDefault={false}
              value={{ price: values.subTotal, currency: values.currency }}
              onChange={(e, newValue) => {
                handleChange(
                  {
                    ...newValueRef.current,
                    currency: newValue?.currency || values.currency,
                    subTotal: newValue?.price,
                  },
                  PriceSummaryCalcType.BasedOnSubTotal,
                );
                inputsProps?.subTotal?.onChange && inputsProps.subTotal.onChange(e, newValue);
              }}
            />
            {inputsProps?.subTotal?.helperText && (
              <FormHelperText variant='standard'>
                {inputsProps?.subTotal?.helperText}
              </FormHelperText>
            )}
          </FormControl>
        )}

        {displayProps?.discount && (
          <GeneralDiscountInput
            {...inputsProps?.all}
            {...inputsProps?.discount}
            error={Boolean(errors?.discount)}
            helperText={
              errors?.discount
                ? ValidationHelper.getFormikErrorsAsString(errors.discount)
                : inputsProps?.discount?.helperText
            }
            fullWidth
            required={inputsProps?.discount?.required ?? inputsProps?.all?.required}
            defaultType={DiscountType.Trade}
            defaultValueType={DiscountValueType.Percent}
            value={values.discount}
            appliesTo={
              values.subTotal
                ? {
                    subTotal: values.subTotal,
                  }
                : undefined
            }
            currency={values.currency}
            label={inputsProps?.discount?.label ?? "Discount"}
            margin={inputsProps?.all?.margin}
            variant='outlined'
            onChange={(newValue) => {
              handleChange(
                {
                  ...newValueRef.current,
                  currency: newValue?.currency || values.currency,
                  discount: newValue || undefined,
                },
                null,
              );
              inputsProps?.discount?.onChange && inputsProps.discount.onChange(newValue);
            }}
          />
        )}

        {displayProps?.tax && (
          <GeneralTaxInput
            {...inputsProps?.all}
            {...inputsProps?.tax}
            error={Boolean(errors?.tax)}
            helperText={
              errors?.tax
                ? ValidationHelper.getFormikErrorsAsString(errors.tax)
                : inputsProps?.tax?.helperText
            }
            fullWidth
            required={inputsProps?.tax?.required ?? inputsProps?.all?.required}
            defaultType={TaxType.Vat}
            defaultValueType={TaxValueType.Percent}
            useCurrentTaxByDefault
            value={values.tax}
            appliesTo={
              values.subTotal
                ? {
                    subTotal: values.subTotal,
                  }
                : undefined
            }
            label={inputsProps?.discount?.label ?? "Tax"}
            margin={inputsProps?.all?.margin}
            variant='outlined'
            onChange={(newValue) => {
              handleChange(
                {
                  ...newValueRef.current,
                  currency: newValue?.currency || values.currency,
                  tax: newValue || undefined,
                },
                null,
              );
              inputsProps?.tax?.onChange && inputsProps.tax.onChange(newValue);
            }}
          />
        )}

        {displayProps?.insurance && (
          <GeneralInsuranceInput
            {...inputsProps?.all}
            {...inputsProps?.insurance}
            error={Boolean(errors?.insurance)}
            helperText={
              errors?.insurance
                ? ValidationHelper.getFormikErrorsAsString(errors.insurance)
                : inputsProps?.insurance?.helperText
            }
            fullWidth
            required={inputsProps?.insurance?.required ?? inputsProps?.all?.required}
            allowCurrencyEdit
            useCurrentCurrencyByDefault={false}
            label={inputsProps?.discount?.label ?? "Insurance"}
            margin={inputsProps?.all?.margin}
            variant='outlined'
            value={values.insurance}
            onChange={(newValue) => {
              handleChange(
                {
                  ...newValueRef.current,
                  currency: newValue?.currency || values.currency,
                  insurance: newValue || undefined,
                },
                null,
              );
              inputsProps?.insurance?.onChange && inputsProps.insurance.onChange(newValue);
            }}
          />
        )}

        {displayProps?.total && (
          <GeneralPriceInput
            {...inputsProps?.all}
            {...inputsProps?.total}
            error={Boolean(errors?.total)}
            helperText={
              errors?.total
                ? ValidationHelper.getFormikErrorsAsString(errors.total)
                : inputsProps?.total?.helperText
            }
            fullWidth
            required={inputsProps?.total?.required ?? inputsProps?.all?.required}
            allowCurrencyEdit
            useCurrentCurrencyByDefault={false}
            label={inputsProps?.discount?.label ?? "Total"}
            margin={inputsProps?.all?.margin}
            variant='outlined'
            value={{ price: values.total, currency: values.currency }}
            onChange={(e, newValue) => {
              handleChange(
                {
                  ...newValueRef.current,
                  currency: newValue?.currency || values.currency,
                  total: newValue?.price || undefined,
                },
                PriceSummaryCalcType.BasedOnTotal,
              );
              inputsProps?.total?.onChange && inputsProps.total.onChange(e, newValue);
            }}
          />
        )}

        {/* Controls */}
        <Stack direction='row' alignItems='center'>
          {displayProps?.calcExplanationModal && (
            <AppTooltip title='View calculations'>
              <IconButton
                onClick={() => {
                  setIsCalcExplanationModalOpen(true);
                }}
              >
                <AppIcon of='calculation' />
              </IconButton>
            </AppTooltip>
          )}
        </Stack>
      </Box>

      {displayProps?.calcExplanationInline && (
        <Box>
          <GeneralPriceSummaryDisplay
            summary={values}
            displayProps={displayProps}
            direction='row'
          />
        </Box>
      )}

      {/* {errors?.currency && (
        <FormHelperText error>
          {ValidationHelper.getFormikErrorsAsString(errors.currency)}
        </FormHelperText>
      )} */}

      {displayProps?.calcExplanationModal && (
        <TotalPriceExplanationModal
          open={isCalcExplanationModalOpen}
          onClose={() => {
            setIsCalcExplanationModalOpen(false);
          }}
          totalPriceExplanationProps={{
            summary: values,
          }}
        />
      )}
    </Stack>
  );
}
