import { Button, Dialog, DialogActions, DialogContent, DialogProps } from "@mui/material";

import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { useModalOpenStatus } from "@/common/hooks/useModalOpenStatus";

import VehicleDamageView, { VehicleDamageViewProps } from "./VehicleDamageView";

export interface OwnProps {
  viewProps: VehicleDamageViewProps;
}
type Props = OwnProps & DialogProps;

export default function VehicleDamageViewModal({ viewProps, ...dialogProps }: Props) {
  const openStatus = useModalOpenStatus(dialogProps);

  return (
    <Dialog fullWidth maxWidth='lg' {...dialogProps}>
      <DialogContent>
        {openStatus.isOpen && (
          <VehicleDamageView
            headerProps={{
              withLink: true,
              typographyProps: {
                variant: "h1",
              },
            }}
            displayProps={{ viewVariant: ViewLayoutVariant.Modal }}
            {...viewProps}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant='text'
          onClick={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
