import { Alert, Button, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import moment from "moment";
import { useMemo } from "react";
import * as Yup from "yup";

import { ContractHelper } from "@/common/helpers/entity/contract";
import { FormikHelper } from "@/common/helpers/formik";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { ContractDto, ContractVehicleCheckOutDto, SpotType } from "@/core/api/generated";

import GeneralValidationError from "../../Error/GeneralValidationError";
import FormActions from "../../Form/FormActions";
import ContractSpotInfoInput from "./ContractSpotInfoInput";

export interface ContractVehicleCheckOutOwnProps {
  contract: ContractDto;
  onSave?: (newValue: ContractDto) => void;
}

export type ContractVehicleCheckOutProps = ContractVehicleCheckOutOwnProps;

export default function ContractVehicleCheckOut({
  contract,
  onSave,
}: ContractVehicleCheckOutProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();

  const contractComputedState = useMemo(
    () => ContractHelper.getComputedState(contract),
    [contract],
  );

  return (
    <Stack spacing={2}>
      {!contractComputedState?.vehicleCheckOut?.can && (
        <Alert severity='warning'>
          <Box>Vehicle check-out is not allowed at the moment.</Box>
          {contractComputedState?.vehicleCheckOut?.reason && (
            <Box>{contractComputedState?.vehicleCheckOut?.reason}</Box>
          )}
        </Alert>
      )}

      {contractComputedState?.vehicleCheckOut?.can && (
        <Formik<ContractVehicleCheckOutDto & BaseFormikValues>
          enableReinitialize
          initialValues={{
            spotInfo:
              (contract?.checkOutSpotInfo
                ? { ...contract?.checkOutSpotInfo, spotId: contract?.checkOutSpotInfo?.spot?.id }
                : undefined) || undefined,
            submit: "",
          }}
          validationSchema={Yup.object().shape({
            // vehicleId: Yup.string().required("Vehicle is required"),
          })}
          onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
            const values2: typeof values = {
              ...values,
              spotInfo: values.spotInfo
                ? {
                    ...values.spotInfo,
                    date: values.spotInfo?.date && moment(values.spotInfo?.date).utc().format(),
                  }
                : undefined,
            };

            try {
              const response =
                await apiClient.contractsApi.apiV1ContractsContractIdVehicleCheckOutPost({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  contractId: contract?.id || "",
                  contractVehicleCheckOutDto: {
                    ...values2,
                  },
                });
              enqueueSnackbar("Saved.", { variant: "success" });
              onSave && onSave(response.data);

              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err: any) {
              if (mounted.current) {
                ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
                setStatus({ success: false });
                setSubmitting(false);
              }
            }
          }}
        >
          {(formikProps) => {
            const { errors, handleSubmit, isSubmitting } = formikProps;

            return (
              <form noValidate onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <Box>
                    <ContractSpotInfoInput
                      contractId={contract.id}
                      spotInfo={contract.checkOutSpotInfo}
                      formikProps={FormikHelper.getSubProps(
                        formikProps,
                        "spotInfo",
                        (v) => v.spotInfo,
                      )}
                      spotType={SpotType.CheckOut}
                    />
                  </Box>

                  <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                  <FormActions>
                    <Button
                      color='primary'
                      loading={isSubmitting}
                      fullWidth
                      type='submit'
                      variant='contained'
                    >
                      Save
                    </Button>
                  </FormActions>
                </Stack>
              </form>
            );
          }}
        </Formik>
      )}
    </Stack>
  );
}
