import { CustomArrayExtensionsManager } from "./array/index";

export class CustomTypeExtensionsManager {
  public static define(): void {
    CustomArrayExtensionsManager.define();
  }

  public static undefine(): void {
    CustomArrayExtensionsManager.undefine();
  }
}
