import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function PinIcon(props: SvgIconProps, ref) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='17'
      height='16'
      viewBox='0 0 17 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.50008 7.33304C7.94875 7.33304 7.50008 6.88437 7.50008 6.33304C7.50008 5.78171 7.94875 5.33304 8.50008 5.33304C9.05141 5.33304 9.50008 5.78171 9.50008 6.33304C9.50008 6.88437 9.05141 7.33304 8.50008 7.33304ZM8.50008 3.99971C7.21341 3.99971 6.16675 5.04637 6.16675 6.33304C6.16675 7.61971 7.21341 8.66637 8.50008 8.66637C9.78675 8.66637 10.8334 7.61971 10.8334 6.33304C10.8334 5.04637 9.78675 3.99971 8.50008 3.99971ZM8.50008 13.0974C7.38341 12.0414 4.50008 9.07671 4.50008 6.61471C4.50008 4.43804 6.29408 2.66671 8.50008 2.66671C10.7061 2.66671 12.5001 4.43804 12.5001 6.61471C12.5001 9.07671 9.61675 12.0414 8.50008 13.0974ZM8.50008 1.33337C5.55941 1.33337 3.16675 3.70204 3.16675 6.61471C3.16675 10.2647 7.86608 14.334 8.06608 14.5054C8.19141 14.6127 8.34541 14.6667 8.50008 14.6667C8.65475 14.6667 8.80875 14.6127 8.93408 14.5054C9.13408 14.334 13.8334 10.2647 13.8334 6.61471C13.8334 3.70204 11.4407 1.33337 8.50008 1.33337Z'
      />
      <mask
        id='mask0_3266_23436'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='3'
        y='1'
        width='11'
        height='14'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.50008 7.33304C7.94875 7.33304 7.50008 6.88437 7.50008 6.33304C7.50008 5.78171 7.94875 5.33304 8.50008 5.33304C9.05141 5.33304 9.50008 5.78171 9.50008 6.33304C9.50008 6.88437 9.05141 7.33304 8.50008 7.33304ZM8.50008 3.99971C7.21341 3.99971 6.16675 5.04637 6.16675 6.33304C6.16675 7.61971 7.21341 8.66637 8.50008 8.66637C9.78675 8.66637 10.8334 7.61971 10.8334 6.33304C10.8334 5.04637 9.78675 3.99971 8.50008 3.99971ZM8.50008 13.0974C7.38341 12.0414 4.50008 9.07671 4.50008 6.61471C4.50008 4.43804 6.29408 2.66671 8.50008 2.66671C10.7061 2.66671 12.5001 4.43804 12.5001 6.61471C12.5001 9.07671 9.61675 12.0414 8.50008 13.0974ZM8.50008 1.33337C5.55941 1.33337 3.16675 3.70204 3.16675 6.61471C3.16675 10.2647 7.86608 14.334 8.06608 14.5054C8.19141 14.6127 8.34541 14.6667 8.50008 14.6667C8.65475 14.6667 8.80875 14.6127 8.93408 14.5054C9.13408 14.334 13.8334 10.2647 13.8334 6.61471C13.8334 3.70204 11.4407 1.33337 8.50008 1.33337Z'
        />
      </mask>
      <g mask='url(#mask0_3266_23436)'>
        <rect x='0.5' width='16' height='16' />
      </g>
    </SvgIcon>
  );
});
