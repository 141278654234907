import { Box, DialogContentText, IconButton, MenuItem } from "@mui/material";
import { useRef } from "react";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import GeneralDiscountDisplay from "@/common/components/Entity/General/GeneralDiscount/GeneralDiscountDisplay";
import GeneralTaxDisplay from "@/common/components/Entity/General/GeneralTax/GeneralTaxDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModalWithTrigger from "@/common/components/Modals/ConfirmationModalWithTrigger";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FileHelper } from "@/common/helpers/file";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { apiClient } from "@/core/api/ApiClient";
import { AdminInvoiceGetPaginatedDto, EntityType, InvoiceStatus } from "@/core/api/generated";

const defaultDisplayProps = {
  viewVariant: ViewLayoutVariant.Page,
};
interface Props {
  displayProps?: Partial<typeof defaultDisplayProps>;
}

export default function InvoicesPage({ displayProps }: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const { enqueueSnackbar } = useAppSnackbar();
  const commonRequestParams = useCommonRequestParams<AdminInvoiceGetPaginatedDto>({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityInAdminArea(EntityType.Invoice),
    },
  });

  const topAnchorRef = useRef<HTMLElement | null>(null);

  const paginatedInvoicesRequest = useApiRequest(
    apiClient.adminInvoicesApi.apiV1AdminInvoicesGetPost,
    {
      adminInvoiceGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedInvoices = paginatedInvoicesRequest.data;

  // const onRefreshClick = () => {
  //   paginatedInvoicesRequest.refetch();
  // };

  return (
    <ViewLayout
      displayProps={displayProps}
      header={<SimpleViewPageHeader title={undefined} primaryActions={undefined} />}
    >
      <Box ref={topAnchorRef} sx={{ m: 0, p: 0 }}></Box>
      <DataTabular
        columns={[
          {
            field: "localNumber",
            title: "Number",
            flex: 1,
            renderCell: (item) => item.localNumber,
          },
          {
            field: "status",
            title: "Status",
            flex: 1,
            renderCell: (item) => <InlineApiEnumValue type='InvoiceStatus' value={item.status} />,
          },
          {
            field: "date",
            title: "Date",
            flex: 1,
            renderCell: (item) => <Datetime datetime={item.date} />,
          },
          {
            field: "paymentMethod",
            title: "Payment method",
            flex: 1,
            renderCell: (item) => (
              <InlineApiEnumValue type='PaymentMethod' value={item.paymentMethod} />
            ),
          },
          {
            field: "subTotal",
            title: "Sub total",
            flex: 1,
            renderCell: (item) => <CurrencyValue value={item.subTotal} currency={item.currency} />,
          },
          {
            field: "discount",
            title: "Discount",
            flex: 1,
            renderCell: (item) => (
              <GeneralDiscountDisplay discount={item.discount} currency={item.currency} />
            ),
          },
          {
            field: "tax",
            title: "Tax",
            flex: 1,
            renderCell: (item) => <GeneralTaxDisplay tax={item.tax} currency={item.currency} />,
          },
          {
            field: "total",
            title: "Total",
            flex: 1,
            renderCell: (item) => <CurrencyValue value={item.total} currency={item.currency} />,
          },
        ]}
        rows={paginatedInvoices?.items}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.ADMIN_INVOICE_VIEW(item.id)}
        renderRowAction={({ item }) => (
          <MenuWithTrigger
            trigger={
              <IconButton>
                <AppIcon of='moreVert' />
              </IconButton>
            }
          >
            {({ handleClose }) => [
              <ConfirmationModalWithTrigger
                key='confirm-payment'
                trigger={
                  <MenuItem
                    disabled={
                      item.status !== InvoiceStatus.Pending && item.status !== InvoiceStatus.Overdue
                    }
                    onClick={() => {}}
                  >
                    Confirm payment
                  </MenuItem>
                }
                title='Confirm invoice payment?'
                body={({ error }) => {
                  return (
                    <DialogContentText>
                      {`Once you confirm the payment, the subscription will be activated. This action can't
                          be undone.`}
                    </DialogContentText>
                  );
                }}
                onConfirm={async () => {
                  await apiClient.adminInvoicesApi.apiV1AdminInvoicesInvoiceIdConfirmPost({
                    invoiceId: item.id!,
                  });
                  enqueueSnackbar("Invoice confirmed.", { variant: "success" });
                  handleClose();
                  paginatedInvoicesRequest.refetch();
                }}
              />,

              <ConfirmationModalWithTrigger
                key='cancel-invoice'
                trigger={
                  <MenuItem
                    disabled={
                      item.status === InvoiceStatus.Paid || item.status === InvoiceStatus.Cancelled
                    }
                  >
                    Cancel
                  </MenuItem>
                }
                title='Cancel invoice?'
                body={({ error }) => {
                  return (
                    <DialogContentText>
                      {`Do you want to cancel this invoice? This action can't be undone, and customer will not
                          be able to activate the subscription.`}
                    </DialogContentText>
                  );
                }}
                onConfirm={async () => {
                  await apiClient.adminInvoicesApi.apiV1AdminInvoicesInvoiceIdCancelPost({
                    invoiceId: item.id!,
                  });
                  handleClose();
                  enqueueSnackbar("Invoice cancelled.", { variant: "success" });
                  paginatedInvoicesRequest.refetch();
                }}
              />,

              <MenuItem
                key='download-pdf'
                onClick={async () => {
                  handleClose();
                  const result = await FileHelper.getDownloadFileApiResult(() =>
                    apiClient.adminInvoicesApi.apiV1AdminInvoicesInvoiceIdDownloadPdfGet({
                      invoiceId: item.id!,
                    }),
                  );
                  FileHelper.downloadBlob(result.blob, result.filename || "invoice.pdf");
                }}
              >
                Download PDF
              </MenuItem>,
            ]}
          </MenuWithTrigger>
        )}
        isLoading={paginatedInvoicesRequest.isLoading}
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
        refetch={commonRequestParams.dataTabularProps.refetch}
      />
    </ViewLayout>
  );
}
