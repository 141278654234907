import {
  Button,
  FormControl,
  FormHelperText,
  Grid2,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { AxiosResponse } from "axios";
import { Formik, getIn } from "formik";
import _ from "lodash";
import { useState } from "react";
import * as Yup from "yup";

import ApiEnumSelect from "@/common/components/Enum/ApiEnumSelect";
import { REPAIR_SPARE_PART_DETALIZATION_PRIORITY } from "@/common/constants/enum";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FileItem } from "@/common/fileItem";
import { ArrayHelper } from "@/common/helpers/array";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useCurrentCurrency } from "@/common/hooks/useCurrentCurrency";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  CreateRepairSparePartDto,
  EntityType,
  RepairSparePartDetalizationType,
  RepairSparePartDto,
  UpdateRepairSparePartDto,
  VehicleType,
} from "@/core/api/generated";

import NoDataAlert from "../../AppAlerts/NoDataAlert";
import ApiEnumAutocomplete from "../../Enum/ApiEnumAutocomplete";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FileUploader from "../../Files/FileUploader";
import FormActions from "../../Form/FormActions";
import AppTextArea from "../../Form/Input/AppTextArea";
import AppIcon from "../../Icons/AppIcon";
import PriceSummaryInput from "../../PriceSummary/PriceSummaryInput";
import VehicleGenerationAutocomplete from "../ReferenceData/VehicleGeneration/VehicleGenerationAutocomplete";
import VehicleMakeAutocomplete from "../ReferenceData/VehicleMake/VehicleMakeAutocomplete";
import VehicleModelAutocomplete from "../ReferenceData/VehicleModel/VehicleModelAutocomplete";
import VehicleModificationAutocomplete from "../ReferenceData/VehicleModification/VehicleModificationAutocomplete";
import VehiclePartTypeAutocompleteV2 from "../ReferenceData/VehiclePartType/VehiclePartTypeAutocompleteV2";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";

type DefaultValues = {
  name?: string;
  partTypeId?: string;
  currency?: CreateRepairSparePartDto["currency"];
  description?: CreateRepairSparePartDto["description"];
  detalizations?: CreateRepairSparePartDto["detalizations"];
};

export interface CreateUpdateRepairSparePartOwnProps
  extends BaseEntityCreateUpdateInheritableProps<RepairSparePartDto, DefaultValues> {
  repairSparePartId?: string;

  createFunc?: (params: {
    dto: CreateRepairSparePartDto;
  }) => Promise<AxiosResponse<RepairSparePartDto, unknown>>;
  updateFunc?: (params: {
    repairSparePartId: string;
    dto: UpdateRepairSparePartDto;
  }) => Promise<AxiosResponse<RepairSparePartDto, unknown>>;
}

export type CreateUpdateRepairSparePartProps = CreateUpdateRepairSparePartOwnProps;

export default function CreateUpdateRepairSparePart({
  repairSparePartId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
  createFunc,
  updateFunc,
}: CreateUpdateRepairSparePartProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const currentCurrency = useCurrentCurrency();
  const [isAttachmentFilesUploading, setIsAttachmentFilesUploading] = useState(false);

  const isCreate = !repairSparePartId;
  const isEdit = !!repairSparePartId;

  const repairSparePartRequest = useApiRequest(
    apiClient.repairSparePartApi.apiV1RepairSparePartsRepairSparePartIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      repairSparePartId: repairSparePartId!,
    },
    {
      skip: !repairSparePartId,
    },
  );
  const repairSparePart = repairSparePartRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: repairSparePart && {
      idValue: repairSparePart.id!,
      newTitle: repairSparePart.localNumber || "",
    },
  });

  const shouldShowInputsForDetalization = (
    selected: RepairSparePartDetalizationType | null | undefined,
    toCheck: RepairSparePartDetalizationType,
  ): boolean => {
    return (
      !!selected &&
      REPAIR_SPARE_PART_DETALIZATION_PRIORITY[toCheck] <=
        REPAIR_SPARE_PART_DETALIZATION_PRIORITY[selected]
    );
  };

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.RepairSparePart}
      entityId={repairSparePartId}
      entity={repairSparePart}
      entityRequest={repairSparePartRequest}
    >
      <Formik<
        BaseFormikValues &
          CreateRepairSparePartDto &
          UpdateRepairSparePartDto & {
            total?: number;
            isAttachmentFilesHaveErrors: boolean;
          }
      >
        enableReinitialize={isEdit}
        initialValues={{
          name: defaultValues?.name || repairSparePart?.name || undefined,
          currency:
            defaultValues?.currency || repairSparePart?.currency || currentCurrency || undefined,
          brandName: repairSparePart?.brandName || undefined,
          partNumber: repairSparePart?.partNumber || undefined,
          description: defaultValues?.description || repairSparePart?.description || undefined,
          unit: repairSparePart?.unit || undefined,
          vehicleType: repairSparePart?.vehicleType || VehicleType.Car,
          detalizations: repairSparePart?.detalizations?.map((item) => ({
            type: item.type || RepairSparePartDetalizationType.General,
            vehicleSize: item.vehicleSize || undefined,
            bodyType: item.bodyType || undefined,
            makeId: item.make?.id || undefined,
            modelId: item.model?.id || undefined,
            generationId: item.generation?.id || undefined,
            modificationId: item.modification?.id || undefined,
          })) ||
            defaultValues?.detalizations || [{ type: RepairSparePartDetalizationType.General }],
          partTypeId: repairSparePart?.partType?.id || defaultValues?.partTypeId || undefined,
          attachments: repairSparePart?.attachments || undefined,
          isAttachmentFilesHaveErrors: false,
          price: repairSparePart?.price ?? undefined,
          discount: repairSparePart?.discount || undefined,
          tax: repairSparePart?.tax || undefined,
          total: repairSparePart?.total || undefined,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // name: Yup.string().required("This field is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            if (isCreate) {
              const response = createFunc
                ? await createFunc({
                    dto: {
                      ..._.omit(values, "submit"),
                    },
                  })
                : await apiClient.repairSparePartApi.apiV1RepairSparePartsPost({
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    createRepairSparePartDto: {
                      ..._.omit(values, "submit"),
                    },
                  });
              enqueueSnackbar("Repair spare part created.", { variant: "success" });
              onCreate && onCreate(response.data);
              onSave && onSave(response.data);
            } else {
              const response = updateFunc
                ? await updateFunc({
                    repairSparePartId,
                    dto: {
                      ..._.omit(values, "submit"),
                    },
                  })
                : await apiClient.repairSparePartApi.apiV1RepairSparePartsRepairSparePartIdPut({
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    repairSparePartId,
                    updateRepairSparePartDto: {
                      ..._.omit(values, "submit"),
                    },
                  });
              enqueueSnackbar("Repair spare part updated.", { variant: "success" });
              onUpdate && onUpdate(response.data);
              onSave && onSave(response.data);
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
            }
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Box>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  required
                  helperText={touched.name && errors.name}
                  label='Name'
                  margin='dense'
                  name='name'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.name || ""}
                  variant='outlined'
                />

                <AppTextArea
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                  helperText={touched.description && errors.description}
                  mode='description'
                  margin='dense'
                  name='description'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description || ""}
                  variant='outlined'
                />

                <FormControl fullWidth margin='dense' error={Boolean(touched.unit && errors.unit)}>
                  <InputLabel required>Unit</InputLabel>
                  <ApiEnumSelect
                    type='MeasurementUnit'
                    value={values.unit}
                    onChange={(newValue) => setFieldValue("unit", newValue)}
                    selectProps={{
                      label: "Unit",
                      required: true,
                    }}
                  />
                  <FormHelperText>{touched.unit && errors.unit}</FormHelperText>
                </FormControl>

                <TextField
                  error={Boolean(touched.partNumber && errors.partNumber)}
                  fullWidth
                  helperText={touched.partNumber && errors.partNumber}
                  label='Part number (manufacturer)'
                  margin='dense'
                  name='partNumber'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.partNumber || ""}
                  variant='outlined'
                />

                <TextField
                  error={Boolean(touched.brandName && errors.brandName)}
                  fullWidth
                  helperText={touched.brandName && errors.brandName}
                  label='Brand name (manufacturer)'
                  margin='dense'
                  name='brandName'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.brandName || ""}
                  variant='outlined'
                />

                <FormControl
                  sx={{ minWidth: 200 }}
                  margin='dense'
                  fullWidth
                  error={Boolean(touched?.vehicleType && errors?.vehicleType)}
                >
                  <InputLabel>Vehicle type</InputLabel>
                  <ApiEnumSelect
                    type='VehicleType'
                    value={values.vehicleType}
                    onChange={(newValue) => setFieldValue("vehicleType", newValue || undefined)}
                    selectProps={{
                      label: "Vehicle type",
                      required: false,
                    }}
                  />
                  <FormHelperText>{touched?.vehicleType && errors?.vehicleType}</FormHelperText>
                </FormControl>

                <Grid2 size={{ xxs: 12, md: 6 }}>
                  <FormControl
                    margin='dense'
                    fullWidth
                    error={Boolean(touched?.partTypeId && errors?.partTypeId)}
                  >
                    <VehiclePartTypeAutocompleteV2
                      isPreload={isCreate}
                      entityId={values.partTypeId}
                      onChange={(newValue) =>
                        setFieldValue("partTypeId", newValue?.id || undefined)
                      }
                    />
                    <FormHelperText>{errors?.partTypeId}</FormHelperText>
                  </FormControl>
                </Grid2>

                <Box sx={{ my: 2 }}>
                  <Typography component='div' sx={{ mb: 1 }} variant='subtitle1'>
                    Detalizations
                  </Typography>

                  {(!values.detalizations || values.detalizations.length === 0) && (
                    <NoDataAlert title='No detalizations yet' />
                  )}

                  <Stack direction='column' spacing={1}>
                    {values.detalizations?.map((detalization, index) => (
                      <Box
                        key={index}
                        sx={{
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          padding: "16px",
                          mb: 2,
                        }}
                      >
                        <FormControl
                          key={index}
                          sx={{ minWidth: 200 }}
                          margin='dense'
                          fullWidth
                          required
                          error={Boolean(getIn(errors, `detalizations[${index}].type`))}
                        >
                          <InputLabel required>Detalization</InputLabel>
                          <ApiEnumSelect
                            type='RepairSparePartDetalizationType'
                            value={detalization?.type}
                            onChange={(newValue) => {
                              const isDecreasedDetalization =
                                REPAIR_SPARE_PART_DETALIZATION_PRIORITY[
                                  newValue || RepairSparePartDetalizationType.None
                                ] <
                                REPAIR_SPARE_PART_DETALIZATION_PRIORITY[
                                  detalization?.type || RepairSparePartDetalizationType.None
                                ];
                              setFieldValue(
                                `detalizations[${index}]`,
                                isDecreasedDetalization
                                  ? { type: newValue || undefined }
                                  : { ...detalization, type: newValue || undefined },
                              );
                            }}
                            selectProps={{
                              label: "Detalization",
                              required: true,
                            }}
                            optionProps={{ withDescription: true }}
                          />
                          <FormHelperText>
                            {getIn(errors, `detalizations[${index}].type`)}
                          </FormHelperText>

                          {/* Detalization inputs */}
                          {detalization?.type !== RepairSparePartDetalizationType.None &&
                            detalization?.type !== RepairSparePartDetalizationType.General && (
                              <Box sx={{ my: 2 }}>
                                <Typography component='div' sx={{ mb: 1 }} variant='subtitle1'>
                                  Detalization for{" "}
                                  <InlineApiEnumValue
                                    type='RepairSparePartDetalizationType'
                                    value={detalization?.type}
                                  />
                                </Typography>

                                <Grid2 container rowSpacing={1} columnSpacing={2}>
                                  <Grid2 size={{ xxs: 12, md: 6 }}>
                                    {shouldShowInputsForDetalization(
                                      detalization?.type,
                                      RepairSparePartDetalizationType.Make,
                                    ) && (
                                      <FormControl
                                        margin='none'
                                        fullWidth
                                        error={Boolean(
                                          getIn(errors, `detalizations[${index}].makeId`),
                                        )}
                                      >
                                        <VehicleMakeAutocomplete
                                          entityId={detalization?.makeId}
                                          entity={undefined}
                                          searchFilters={{
                                            vehicleType: values.vehicleType,
                                          }}
                                          label='Make'
                                          onChange={(newValue) => {
                                            setFieldValue(`detalizations[${index}]`, {
                                              ...detalization,
                                              makeId: newValue?.id,
                                              make: newValue || undefined,
                                              modelId: undefined,
                                              generationId: undefined,
                                              modificationId: undefined,
                                            });
                                          }}
                                          required={_.includes(
                                            [
                                              RepairSparePartDetalizationType.Make,
                                              RepairSparePartDetalizationType.Model,
                                              RepairSparePartDetalizationType.Generation,
                                              RepairSparePartDetalizationType.Modification,
                                            ],
                                            detalization?.type,
                                          )}
                                          disabled={!values.vehicleType}
                                          textFieldProps={{
                                            error: Boolean(
                                              getIn(errors, `detalizations[${index}].makeId`),
                                            ),
                                            helperText: ValidationHelper.getErrorsAsString(
                                              getIn(errors, `detalizations[${index}].makeId`),
                                            ),
                                          }}
                                        />
                                      </FormControl>
                                    )}
                                  </Grid2>
                                  <Grid2 size={{ xxs: 12, md: 6 }}>
                                    {shouldShowInputsForDetalization(
                                      detalization?.type,
                                      RepairSparePartDetalizationType.Model,
                                    ) && (
                                      <FormControl
                                        margin='none'
                                        fullWidth
                                        error={Boolean(
                                          getIn(touched, `detalizations[${index}].modelId`) &&
                                            getIn(errors, `detalizations[${index}].modelId`),
                                        )}
                                      >
                                        <VehicleModelAutocomplete
                                          searchFilters={{
                                            vehicleType: values.vehicleType,
                                            makeId: detalization.makeId!,
                                          }}
                                          entityId={detalization?.modelId}
                                          label='Model'
                                          onChange={(newValue) => {
                                            setFieldValue(`detalizations[${index}]`, {
                                              ...detalization,
                                              modelId: newValue?.id,
                                              model: newValue || undefined,
                                              generationId: undefined,
                                              modificationId: undefined,
                                            });
                                          }}
                                          disabled={!values.vehicleType || !detalization?.makeId}
                                          required={_.includes(
                                            [
                                              RepairSparePartDetalizationType.Model,
                                              RepairSparePartDetalizationType.Generation,
                                              RepairSparePartDetalizationType.Modification,
                                            ],
                                            detalization?.type,
                                          )}
                                        />
                                        <FormHelperText>
                                          {getIn(errors, `detalizations[${index}].modelId`) &&
                                            ValidationHelper.getErrorsAsString(
                                              getIn(errors, `detalizations[${index}].modelId`),
                                            )}
                                        </FormHelperText>
                                      </FormControl>
                                    )}
                                  </Grid2>
                                  <Grid2 size={{ xxs: 12, md: 6 }}>
                                    {shouldShowInputsForDetalization(
                                      detalization?.type,
                                      RepairSparePartDetalizationType.Generation,
                                    ) && (
                                      <FormControl
                                        margin='none'
                                        fullWidth
                                        error={Boolean(
                                          getIn(touched, `detalizations[${index}].generationId`) &&
                                            getIn(errors, `detalizations[${index}].generationId`),
                                        )}
                                      >
                                        <VehicleGenerationAutocomplete
                                          searchFilters={{
                                            vehicleType: values.vehicleType,
                                            makeId: detalization!.makeId!,
                                            modelId: detalization!.modelId!,
                                          }}
                                          entityId={detalization?.generationId}
                                          label='Generation'
                                          onChange={(newValue) => {
                                            setFieldValue(`detalizations[${index}]`, {
                                              ...detalization,
                                              generationId: newValue?.id,
                                              generation: newValue || undefined,
                                              modificationId: undefined,
                                            });
                                          }}
                                          disabled={!values.vehicleType}
                                          required={
                                            detalization?.type ===
                                            RepairSparePartDetalizationType.Generation
                                          }
                                        />
                                        <FormHelperText>
                                          {getIn(errors, `detalizations[${index}].generationId`) &&
                                            ValidationHelper.getErrorsAsString(
                                              getIn(errors, `detalizations[${index}].generationId`),
                                            )}
                                        </FormHelperText>
                                      </FormControl>
                                    )}
                                  </Grid2>
                                  <Grid2 size={{ xxs: 12, md: 6 }}>
                                    {shouldShowInputsForDetalization(
                                      detalization?.type,
                                      RepairSparePartDetalizationType.Modification,
                                    ) && (
                                      <FormControl
                                        margin='none'
                                        fullWidth
                                        error={Boolean(
                                          getIn(
                                            touched,
                                            `detalizations[${index}].modificationId`,
                                          ) &&
                                            getIn(errors, `detalizations[${index}].modificationId`),
                                        )}
                                      >
                                        <VehicleModificationAutocomplete
                                          entityId={detalization?.modificationId}
                                          searchFilters={{
                                            vehicleType: values.vehicleType,
                                            makeId: detalization.makeId!,
                                            modelId: detalization.modelId!,
                                            generationId: detalization?.generationId || undefined,
                                          }}
                                          label='Modification'
                                          onChange={(newValue) => {
                                            setFieldValue(`detalizations[${index}]`, {
                                              ...detalization,
                                              modificationId: newValue?.id,
                                              modification: newValue || undefined,
                                            });
                                          }}
                                          disabled={!values.vehicleType}
                                          required={
                                            detalization?.type ===
                                            RepairSparePartDetalizationType.Modification
                                          }
                                        />
                                        <FormHelperText>
                                          {getIn(
                                            errors,
                                            `detalizations[${index}].modificationId`,
                                          ) &&
                                            ValidationHelper.getErrorsAsString(
                                              getIn(
                                                errors,
                                                `detalizations[${index}].modificationId`,
                                              ),
                                            )}
                                        </FormHelperText>
                                      </FormControl>
                                    )}
                                  </Grid2>

                                  <Grid2 size={{ xxs: 12, md: 6 }}>
                                    {shouldShowInputsForDetalization(
                                      detalization?.type,
                                      RepairSparePartDetalizationType.VehicleSize,
                                    ) && (
                                      <FormControl
                                        sx={{ minWidth: 200 }}
                                        margin='none'
                                        fullWidth
                                        error={Boolean(
                                          getIn(touched, `detalizations[${index}].vehicleSize`) &&
                                            getIn(errors, `detalizations[${index}].vehicleSize`),
                                        )}
                                      >
                                        <ApiEnumAutocomplete
                                          type='VehicleSize'
                                          value={detalization?.vehicleSize}
                                          onChange={(newValue) =>
                                            setFieldValue(
                                              `detalizations[${index}].vehicleSize`,
                                              newValue,
                                            )
                                          }
                                          label='Vehicle size'
                                          required={
                                            detalization?.type ===
                                            RepairSparePartDetalizationType.VehicleSize
                                          }
                                        />
                                        <FormHelperText>
                                          {getIn(errors, `detalizations[${index}].vehicleSize`) &&
                                            ValidationHelper.getErrorsAsString(
                                              getIn(errors, `detalizations[${index}].vehicleSize`),
                                            )}
                                        </FormHelperText>
                                      </FormControl>
                                    )}
                                  </Grid2>
                                  <Grid2 size={{ xxs: 12, md: 6 }}>
                                    {shouldShowInputsForDetalization(
                                      detalization?.type,
                                      RepairSparePartDetalizationType.BodyType,
                                    ) && (
                                      <FormControl
                                        sx={{ minWidth: 200 }}
                                        margin='none'
                                        fullWidth
                                        error={Boolean(
                                          getIn(touched, `detalizations[${index}].bodyType`) &&
                                            getIn(errors, `detalizations[${index}].bodyType`),
                                        )}
                                      >
                                        <ApiEnumAutocomplete
                                          type='VehicleBodyType'
                                          value={detalization?.bodyType}
                                          onChange={(newValue) =>
                                            setFieldValue(
                                              `detalizations[${index}].bodyType`,
                                              newValue,
                                            )
                                          }
                                          label='Body type'
                                          required={
                                            detalization?.type ===
                                            RepairSparePartDetalizationType.BodyType
                                          }
                                        />
                                        <FormHelperText>
                                          {getIn(errors, `detalizations[${index}].bodyType`) &&
                                            ValidationHelper.getErrorsAsString(
                                              getIn(errors, `detalizations[${index}].bodyType`),
                                            )}
                                        </FormHelperText>
                                      </FormControl>
                                    )}
                                  </Grid2>
                                </Grid2>
                              </Box>
                            )}

                          <Stack direction='row' alignItems='flex-start' justifyContent='flex-end'>
                            <IconButton
                              onClick={() =>
                                setFieldValue(
                                  "detalizations",
                                  ArrayHelper.removeByIndex(values.detalizations, index),
                                )
                              }
                            >
                              <AppIcon of='delete' />
                            </IconButton>
                          </Stack>
                        </FormControl>
                      </Box>
                    ))}
                  </Stack>

                  {/* Add detalization */}
                  <Button
                    sx={{ mt: 1 }}
                    color='text'
                    size='small'
                    variant='outlined'
                    startIcon={<AppIcon of='add' />}
                    onClick={() => {
                      // Add a new item to the "detalizations" array
                      setFieldValue("detalizations", [
                        ...(values.detalizations || []),
                        {
                          type: RepairSparePartDetalizationType.General, // or provide a default value if you have one
                          bodyType: undefined,
                          fuelType: undefined,
                          gearboxType: undefined,
                          drivetrainType: undefined,
                          makeId: undefined,
                          modelId: undefined,
                          generationId: undefined,
                          modificationId: undefined,
                        },
                      ]);
                    }}
                  >
                    Add detalization
                  </Button>
                </Box>

                <FormControl fullWidth component='fieldset' margin='dense'>
                  <FileUploader
                    multiple
                    defaultFiles={FileItem.createManyFrom(values.attachments)}
                    onChange={(newFiles) => {
                      setFieldValue(
                        `attachments`,
                        FileItem.toManyGeneralAttachmentInputDto(newFiles),
                      );
                    }}
                    onUploadStarted={() => {
                      setIsAttachmentFilesUploading(true);
                    }}
                    onUploadFinished={() => {
                      setIsAttachmentFilesUploading(false);
                    }}
                    onValidationStatusChange={(filesValidationStatus) => {
                      if (filesValidationStatus)
                        setFieldValue(
                          "isAttachmentFilesHaveErrors",
                          Object.values(filesValidationStatus).some((x) => x === false),
                        );
                    }}
                  />
                </FormControl>

                <FormControl fullWidth margin='dense'>
                  <PriceSummaryInput
                    values={{
                      currency: values.currency,
                      subTotal: values?.price ?? undefined,
                      discount: values.discount,
                      tax: values.tax,
                      total: values.total,
                    }}
                    formikProps={{
                      errors: {
                        subTotal: errors.price,
                        discount: errors.discount,
                        tax: errors.tax,
                        total: errors.total,
                      },
                    }}
                    displayProps={{
                      insurance: false,
                      calcExplanationInline: false,
                      calcExplanationModal: true,
                    }}
                    inputsProps={{
                      all: {
                        margin: "none",
                      },
                      subTotal: {
                        label: "Price",
                        required: false,
                      },
                      total: {
                        required: false,
                      },
                    }}
                    onChange={(newValue) => {
                      setFieldValue(`currency`, newValue?.currency);
                      setFieldValue(`price`, newValue?.subTotal);
                      setFieldValue(`discount`, newValue?.discount);
                      setFieldValue(`tax`, newValue?.tax);
                      setFieldValue(`total`, newValue?.total);
                    }}
                  />
                </FormControl>
              </Box>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <FormActions>
                <Button
                  color='primary'
                  disabled={isAttachmentFilesUploading || values.isAttachmentFilesHaveErrors}
                  loading={isSubmitting}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Save
                </Button>
              </FormActions>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
