import { ListItemIcon, ListItemText, MenuItem, Stack } from "@mui/material";
import { ReactNode } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import { ROUTE_PATH } from "@/common/constants/routing";
import { FileHelper } from "@/common/helpers/file";
import { apiClient } from "@/core/api/ApiClient";
import { AppPermission, DamageDetectionDto } from "@/core/api/generated";

import AuthorizedMenuItem from "../../Auth/AuthorizedMenuItem";
import EntityMenu from "../../EntityMenu/EntityMenu";
import AppIcon from "../../Icons/AppIcon";
import HeaderMenuItem from "../../Menu/HeaderMenuItem";
import LoadingMenuItem from "../../Menu/LoadingMenuItem";
import DamageCostEvaluationCreateUpdateModal from "../DamageCostEvaluation/DamageCostEvaluationCreateUpdateModal";
import RepairOperationCreateUpdateModal from "../RepairOperation/RepairOperationCreateUpdateModal";
import { VehicleViewPageTabs } from "../Vehicle/View/VehicleView";
import AddDamageDetectionToContractModal from "./AddDamageDetectionToContractModal";
import DamageDetectionDeleteModal from "./DamageDetectionDeleteModal";
import DamageDetectionMigrateToNewVisualModelModal from "./DamageDetectionMigrateToNewVisualModelModal";

enum ActionFlags {
  DownloadPdf = "DownloadPdf",
  Edit = "Edit",
  AddToContract = "AddToContract",
  MigrateToNewVisualModel = "MigrateToNewVisualModel",
  ViewAllDamages = "ViewAllDamages",
  Delete = "Delete",
  // quick actions
  CreateDamageCostEvaluation = "CreateDamageCostEvaluation",
  CreateRepairOperation = "CreateRepairOperation",
}
const defaultDisplayProps = {
  actions: {
    downloadPdf: true,
    edit: true,
    addToContract: true,
    migrateToNewVisualModel: true,
    viewAllDamages: true,
    delete: true,
    // quick actions
    createDamageCostEvaluation: true,
    createRepairOperation: true,
  },
};

interface TriggersProps {
  entity?: DamageDetectionDto | null;
  definition: typeof ActionFlags;
  handleClose: () => void;
  startAction: (props: ActionFlags) => void;
}

interface Props {
  entity?: DamageDetectionDto | null;
  disabled?: boolean;
  onUpdate: ((newValue: DamageDetectionDto | null | undefined) => void) | undefined;
  onDelete?: (() => void) | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  customActions?: (props: TriggersProps) => ReactNode;
}

export default function DamageDetectionMenu({
  entity,
  disabled,
  customActions,
  onUpdate,
  onDelete,
  displayProps = defaultDisplayProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const history = useHistory();

  return (
    <EntityMenu
      definition={ActionFlags}
      entity={entity}
      disabled={disabled}
      actionTriggers={({ handleClose, startAction }) => (
        <>
          {customActions &&
            customActions({
              entity,
              definition: ActionFlags,
              handleClose,
              startAction,
            })}

          {displayProps?.actions?.downloadPdf && (
            <LoadingMenuItem
              key='downloadPdf'
              onClick={async () => {
                const downloadResult = await FileHelper.getDownloadFileApiResult(() =>
                  apiClient.damageDetectionsApi.apiV1DamageDetectionsDamageDetectionIdDownloadPdfGet(
                    {
                      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                      damageDetectionId: entity?.id || "",
                    },
                    {
                      responseType: "blob",
                    },
                  ),
                );
                FileHelper.downloadBlobFromApiResult(downloadResult);
              }}
            >
              <ListItemIcon>
                <AppIcon of='download' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Download PDF</ListItemText>
            </LoadingMenuItem>
          )}
          {/* NB: temporary disabled */}
          {/* <MenuItem
            component={RouterLink}
            to={ROUTE_PATH.DOCUMENTS({
              sourceType: EntitySourceType.DamageDetection,
              sourceId: entity?.id,
            })}
          >
            <ListItemIcon>
              <AppIcon of='documents' fontSize='small' />
            </ListItemIcon>
            <ListItemText>Documents</ListItemText>
          </MenuItem> */}
          {displayProps?.actions?.addToContract && (
            <AuthorizedMenuItem
              permissions={[AppPermission.DamageDetectionManage]}
              disabled={!!entity?.contract}
              onClick={() => {
                startAction(ActionFlags.AddToContract);
                handleClose();
              }}
            >
              <ListItemIcon>
                <AppIcon of='add' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Add to contract</ListItemText>
            </AuthorizedMenuItem>
          )}
          {displayProps?.actions?.migrateToNewVisualModel && (
            <AuthorizedMenuItem
              permissions={[AppPermission.DamageDetectionManage]}
              disabled={!entity?.id}
              onClick={() => {
                startAction(ActionFlags.MigrateToNewVisualModel);
                handleClose();
              }}
            >
              <ListItemIcon>
                <AppIcon of='edit' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Migrate to new visual model</ListItemText>
            </AuthorizedMenuItem>
          )}
          {displayProps?.actions?.viewAllDamages && (
            <MenuItem
              component={RouterLink}
              to={ROUTE_PATH.VEHICLE_VIEW(entity?.vehicle?.id, {
                tab: VehicleViewPageTabs.VehicleActualState,
              })}
            >
              <ListItemIcon>
                <AppIcon of='damage' fontSize='small' />
              </ListItemIcon>
              <ListItemText>View all damages</ListItemText>
            </MenuItem>
          )}
          {displayProps?.actions?.delete && (
            <AuthorizedMenuItem
              permissions={[AppPermission.DamageDetectionManage]}
              onClick={() => {
                startAction(ActionFlags.Delete);
                handleClose();
              }}
            >
              <ListItemIcon>
                <AppIcon of='delete' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </AuthorizedMenuItem>
          )}
          {(displayProps?.actions?.createDamageCostEvaluation ||
            displayProps?.actions?.createRepairOperation) && (
            <HeaderMenuItem primaryTitle='Quick actions' />
          )}

          {displayProps?.actions?.createDamageCostEvaluation && (
            <AuthorizedMenuItem
              key='startCostEval'
              permissionsAny={[
                AppPermission.DamageCostEvaluationPerform,
                AppPermission.DamageCostEvaluationManage,
              ]}
              onClick={() => {
                startAction(ActionFlags.CreateDamageCostEvaluation);
                handleClose();
              }}
            >
              <ListItemIcon>
                <AppIcon of='damageCostEvaluation' fontSize='small' />
              </ListItemIcon>
              <ListItemText>New damage cost evaluation</ListItemText>
            </AuthorizedMenuItem>
          )}
          {displayProps?.actions?.createRepairOperation && (
            <AuthorizedMenuItem
              key='repair'
              permissionsAny={[
                AppPermission.RepairOperationPerform,
                AppPermission.RepairOperationManage,
              ]}
              onClick={() => {
                startAction(ActionFlags.CreateRepairOperation);
                handleClose();
              }}
            >
              <ListItemIcon>
                <AppIcon of='repair' fontSize='small' />
              </ListItemIcon>
              <ListItemText>New repair operation</ListItemText>
            </AuthorizedMenuItem>
          )}
        </>
      )}
      actionHandlers={({ currentAction, cancelAction, completeAction }) => (
        <Stack>
          {/* Add to contract */}
          {entity && displayProps?.actions?.addToContract && (
            <AddDamageDetectionToContractModal
              open={currentAction === ActionFlags.AddToContract}
              onClose={() => cancelAction()}
              damageDetection={entity}
              onSaved={(newValue) => {
                completeAction();
                onUpdate && onUpdate(null);
              }}
            />
          )}

          {/* Migrate to new visual model */}
          {entity && (
            <DamageDetectionMigrateToNewVisualModelModal
              open={currentAction === ActionFlags.MigrateToNewVisualModel}
              onClose={() => cancelAction()}
              migrateProps={{
                damageDetection: entity,
                onSave: (newValue) => {
                  completeAction();
                  onUpdate && onUpdate(newValue);
                },
              }}
            />
          )}

          {/* Delete */}
          {entity && (
            <DamageDetectionDeleteModal
              entity={entity}
              open={currentAction === ActionFlags.Delete}
              onClose={() => cancelAction()}
              onDelete={() => {
                onDelete && onDelete();
                completeAction();
              }}
            />
          )}

          {/* Create DamageCostEvaluation */}
          {entity && (
            <DamageCostEvaluationCreateUpdateModal
              open={currentAction === ActionFlags.CreateDamageCostEvaluation}
              onClose={() => cancelAction()}
              createUpdateProps={{
                defaultValues: {
                  vehicleId: entity?.vehicle?.id,
                  contractId: entity?.contract?.id,
                  vehicleDamageIds: entity?.items
                    ?.map((x) => x.damage?.id || "")
                    ?.filter((x) => !!x),
                },
                onSave: (newValue) => {
                  history.push(ROUTE_PATH.DAMAGE_COST_EVALUATION_VIEW(newValue.id));
                },
              }}
            />
          )}

          {/* Create RepairOperation */}
          {entity && (
            <RepairOperationCreateUpdateModal
              open={currentAction === ActionFlags.CreateRepairOperation}
              onClose={() => cancelAction()}
              createUpdateProps={{
                defaultValues: {
                  vehicleId: entity?.vehicle?.id,
                  contractId: entity?.contract?.id,
                  vehicleDamageIds: entity?.items
                    ?.map((x) => x.damage?.id || "")
                    ?.filter((x) => !!x),
                },
                onSave: (newValue) => {
                  history.push(ROUTE_PATH.REPAIR_OPERATION_VIEW(newValue.id));
                },
              }}
            />
          )}
        </Stack>
      )}
    />
  );
}
