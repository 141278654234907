import { Box } from "@mui/material";
import { useRef } from "react";

import { ChatMessageNodeBuilder } from "@/common/builders/chatMessageNode";
import { ChatMessageHelper } from "@/common/helpers/entity/chatMessage";
import { RegexHelper } from "@/common/helpers/regex";
import {
  ChatMessageNodeDto,
  ChatMessageNodeType,
  ChatMessageRootNodeDto,
} from "@/core/api/generated";

interface Props {
  onMessageChange: (newValue: { rootNode: ChatMessageRootNodeDto }) => void;
  children: (props: {
    handleMessageChange: (
      rootNode: ChatMessageRootNodeDto,
      oldSelection: CustomCursorSelection,
      newSelection: CustomCursorSelection,
      newBody: string,
    ) => { isHandled: boolean };
    handleKeyDown: (e: React.KeyboardEvent<HTMLElement>) => { isHandled: boolean };
  }) => React.ReactNode;
}

/** Handles web links (URLs) in message input. */
function ChatMessageWebLinkHandler({ onMessageChange, children }: Props) {
  const _rootNode = useRef<ChatMessageRootNodeDto>({
    nodes: [],
  });
  const messageBody = useRef("");

  // const reset = () => {
  //   _rootNode.current = {
  //     nodes: [],
  //   };
  //   messageBody.current = "";
  // };

  const handleMessageChange = (
    rootNode: ChatMessageRootNodeDto,
    oldSelection: CustomCursorSelection,
    newSelection: CustomCursorSelection,
    newBody: string,
  ): { isHandled: boolean } => {
    _rootNode.current = rootNode;
    messageBody.current = newBody;

    // track URL only on paste
    const { isTextRangeEntered, enteredTextLength } =
      ChatMessageHelper.detectTextChangeFromSelections(oldSelection, newSelection);
    const enteredText = newBody.slice(
      newSelection.endIndex! - enteredTextLength,
      newSelection.endIndex!,
    );
    const isUrlPasted = isTextRangeEntered && RegexHelper.testHttpUrl(enteredText);

    const existingNode = _rootNode.current.nodes!.find(
      (x) =>
        x.type === ChatMessageNodeType.WebLink &&
        ChatMessageHelper.getNodeIntersectionWithSelection(x, {
          startIndex: Math.min(oldSelection.startIndex!, newSelection.startIndex!),
          endIndex: Math.max(oldSelection.endIndex!, newSelection.endIndex!),
        }).isIntersects,
    );
    const isExistingNodeEdited = !!existingNode;

    // console.log("weblink", {
    //   oldSelection,
    //   newSelection,
    //   isTextEntered,
    //   isTextRangeEntered,
    //   enteredTextLength,
    //   enteredText,
    //   isUrlPasted,
    //   existingNode,
    //   isExistingNodeEdited,
    //   _rootNode: _.cloneDeep(_rootNode.current),
    // });

    if (isExistingNodeEdited) {
      const handleResult = ChatMessageHelper.handleWebLinkNodeChange({
        rootNode: _rootNode.current,
        webLinkNode: existingNode,
        oldSelection,
        newSelection,
        change: {
          value: newBody,
        },
      });

      if (handleResult.isHandled) {
        onMessageChange({
          rootNode: _rootNode.current,
        });

        return {
          isHandled: true,
        };
      }
    } else if (isUrlPasted) {
      // check whether URL pasted in the middle of existing node,
      // if yes - split it and insert weblink node in between.
      const selection: CustomCursorSelection = {
        startIndex: oldSelection.startIndex,
        endIndex: newSelection.endIndex,
      };
      const intersectionNode = _rootNode.current.nodes!.find(
        (x) => ChatMessageHelper.getNodeIntersectionWithSelection(x, selection).isIntersects,
      );
      let insertWebLinkAfter: ChatMessageNodeDto | undefined = undefined;
      if (intersectionNode) {
        if (intersectionNode.type !== ChatMessageNodeType.Text) {
          return {
            isHandled: false,
          };
        }

        const splitResult = ChatMessageHelper.splitNodeBySelection(
          _rootNode.current,
          intersectionNode,
          selection,
        );
        insertWebLinkAfter = splitResult.node1;
      }

      const urls = RegexHelper.getHttpUrls(enteredText);

      const enteredUrl = urls[0];
      const newNode = ChatMessageNodeBuilder.newWebLinkNode()
        .withIndexRange({
          startIndex: selection.startIndex!,
          endIndex: selection.endIndex!,
        })
        .withText({
          text: enteredUrl.url,
        })
        .withWebLink({
          url: enteredUrl.url,
        })
        .build();
      if (insertWebLinkAfter) {
        ChatMessageHelper.insertNodeAfter(_rootNode.current, insertWebLinkAfter.id!, newNode);
      } else {
        ChatMessageHelper.insertNode(_rootNode.current, newNode);
      }

      onMessageChange({
        rootNode: _rootNode.current,
      });

      return {
        isHandled: true,
      };
    }

    return {
      isHandled: false,
    };
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    return { isHandled: false };
  };

  return (
    <Box>
      {children({
        handleMessageChange,
        handleKeyDown,
      })}
    </Box>
  );
}

export default ChatMessageWebLinkHandler;
