import { Alert, Button, Checkbox, FormControl, FormControlLabel, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import * as Yup from "yup";

import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  TenantRequestApplyResultDto,
  TenantRequestDto,
  TenantRequestType,
} from "@/core/api/generated";

import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import GeneralValidationError from "../../Error/GeneralValidationError";
import GeneralStrictEntityRelationLink from "../General/Display/GeneralStrictEntityRelationLink";

export interface TenantRequestApplyResultOwnProps {
  tenantRequest: TenantRequestDto;
  onSave?: (newValue: TenantRequestDto) => void;
}

export type TenantRequestApplyResultProps = TenantRequestApplyResultOwnProps;

export default function TenantRequestApplyResult({
  tenantRequest,
  onSave,
}: TenantRequestApplyResultProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();

  const isAnythingToApply = tenantRequest.result?.type !== TenantRequestType.Arbitrary;

  return (
    <Stack spacing={1}>
      <Alert severity='info'>
        Here you can apply the result of the company request to your company.
      </Alert>

      {tenantRequest.appliedResult?.appliedAt && (
        <Alert severity='warning'>The result is already applied.</Alert>
      )}

      {tenantRequest.result?.type === TenantRequestType.Operation && (
        <Box>
          New{" "}
          <strong>
            <InlineApiEnumValue
              type='TenantRequestOperationType'
              value={tenantRequest.result?.operation?.operationType}
            />
          </strong>{" "}
          will be created from{" "}
          <strong>
            <GeneralStrictEntityRelationLink
              value={{
                entityType: tenantRequest.result?.operation?.operationType,
                entityId:
                  tenantRequest.result?.operation?.accessoryCheck?.accessoryCheckId ||
                  tenantRequest.result?.operation?.visualInspection?.visualInspectionId ||
                  tenantRequest.result?.operation?.damageDetection?.damageDetectionId ||
                  tenantRequest.result?.operation?.damageCostEvaluation?.damageCostEvaluationId ||
                  tenantRequest.result?.operation?.repairOperation?.repairOperationId ||
                  tenantRequest.result?.operation?.wheelOperation?.wheelOperationId ||
                  tenantRequest.result?.operation?.wash?.washId ||
                  tenantRequest.result?.operation?.maintenance?.maintenanceId,
              }}
            />
          </strong>
          .
        </Box>
      )}

      <Formik<BaseFormikValues & TenantRequestApplyResultDto>
        enableReinitialize
        initialValues={{
          isReapply: undefined,

          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // vehicleId: Yup.string().required("Vehicle is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            const response =
              await apiClient.tenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdResultApplyPost(
                {
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  tenantRequestId: tenantRequest.id || "",
                  tenantRequestApplyResultDto: {
                    ...values,
                  },
                },
              );
            enqueueSnackbar("Result applied.", {
              variant: "success",
            });
            onSave && onSave(response.data);

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          const canApply =
            isAnythingToApply &&
            (!tenantRequest.appliedResult?.appliedAt ||
              (!!tenantRequest.appliedResult?.appliedAt && values.isReapply));

          return (
            <form noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Stack spacing={2}>
                  <FormControl margin='dense' fullWidth>
                    <FormControlLabel
                      sx={{ margin: 0 }}
                      disableTypography
                      control={
                        <Checkbox
                          checked={values.isReapply ?? false}
                          onChange={(e) => {
                            setFieldValue("isReapply", e.target.checked);
                          }}
                        />
                      }
                      label={"Reapply result"}
                    />
                  </FormControl>
                </Stack>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <Button
                  sx={{ mt: { xs: "auto", md: 2 } }}
                  color='primary'
                  loading={isSubmitting}
                  disabled={!canApply}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  {tenantRequest.appliedResult?.appliedAt ? "Reapply" : "Apply"}
                </Button>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </Stack>
  );
}
