import { useParams } from "react-router";

import SpotView from "@/common/components/Entity/Spot/View/SpotView";

export default function SpotViewPage() {
  const { spotId } = useParams<{ spotId?: string }>();

  return (
    <>
      <SpotView
        spotId={spotId}
        spot={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
