import { customTypeExtensionConfig } from "../config";
import { TypeExtensionHelper } from "../helpers/typeExtension";
import { CustomArrayExtensions } from "./extensions";

export class CustomArrayExtensionsManager {
  public static define(): void {
    TypeExtensionHelper.defineGetProperty({
      prototype: Array.prototype,
      name: customTypeExtensionConfig.containerPropertyName,
      get: function () {
        // NB: each time the property is accessed a new object is created,
        // which is not an issue for general usage but becomes an issue if
        // accessing it in a loop with 10k+ iterations.
        return new CustomArrayExtensions(this);
      },
    });
  }

  public static undefine(): void {
    TypeExtensionHelper.undefineProperty({
      prototype: Array.prototype,
      name: customTypeExtensionConfig.containerPropertyName,
    });
  }
}
