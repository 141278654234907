import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";

async function createObjectsInLoop(params: {
  numberOfIterations: number;
  numberOfObjectsPerIteration: number;
  isSaveIntermediaryObjects?: boolean;
}) {
  const initialMemory = (performance as any).memory.usedJSHeapSize;

  const results = [];
  for (let i = 0; i < params.numberOfIterations; i++) {
    const objs = Array.from({ length: params.numberOfObjectsPerIteration }).map((x, j) => ({
      i,
      j,
      name: `Object${i}`,
      value: Math.random(),
    }));
    if (params.isSaveIntermediaryObjects) {
      results.push(objs);
    }
  }

  const finalMemory = (performance as any).memory.usedJSHeapSize;

  console.log(`Initial memory: ${initialMemory / 1024 / 1024} MiB`);
  console.log(`Final memory: ${finalMemory / 1024 / 1024} MiB`);
  console.log(
    `Memory used by ${params.numberOfIterations * params.numberOfObjectsPerIteration} objects: ${(finalMemory - initialMemory) / 1024 / 1024} MiB`,
  );
}

export default function DevTempPlayground() {
  const [numberOfIterations, setNumberOfIterations] = useState<number>(1000);
  const [numberOfObjectsPerIteration, setNumberOfObjectsPerIteration] = useState<number>(1);

  return (
    <Stack spacing={4}>
      <Typography component='div' variant='h4'>
        Temp playground
      </Typography>

      <Stack spacing={1}>
        <Typography component='div' variant='h5'>
          Test memory allocation when creating objects in a loop
        </Typography>

        <Box>
          <TextField
            label='Number of iterations'
            margin='dense'
            fullWidth
            value={numberOfIterations}
            onChange={(e) => {
              setNumberOfIterations(+e.target.value);
            }}
          />

          <TextField
            label='Number of objects per iteration'
            margin='dense'
            fullWidth
            value={numberOfObjectsPerIteration}
            onChange={(e) => {
              setNumberOfObjectsPerIteration(+e.target.value);
            }}
          />

          <Button
            variant='contained'
            onClick={() => {
              createObjectsInLoop({
                numberOfIterations,
                numberOfObjectsPerIteration,
                isSaveIntermediaryObjects: true,
              });
            }}
          >
            Test
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
}
