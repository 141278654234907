import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function EditIcon(props: SvgIconProps, ref) {
  return (
    <SvgIcon
      width='24'
      height='25'
      viewBox='0 0 24 25'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <path d='M6.97987 21.8004V23.3064C6.97987 24.1485 6.29971 24.8124 5.47387 24.8124C4.64803 24.8124 3.96787 24.1485 3.96787 23.3064V21.8004H2.44556V16.8774C2.44556 15.7925 3.17428 14.9017 4.16212 14.6265L5.92724 10.4322C6.18637 9.81683 6.78553 9.37958 7.49803 9.37958H16.5181C17.2306 9.37958 17.8298 9.81683 18.0889 10.4322L19.8379 14.6265C20.8257 14.9018 21.5544 15.7925 21.5544 16.8774V21.8004H20.016V23.3064C20.016 24.1485 19.3358 24.8124 18.51 24.8124C17.6679 24.8124 17.004 24.1485 17.004 23.3064V21.8004H6.97987ZM5.57099 19.096C6.41306 19.096 7.09321 18.4159 7.09321 17.5738C7.09321 16.7317 6.41306 16.0516 5.57099 16.0516C4.72893 16.0516 4.04878 16.7317 4.04878 17.5738C4.04878 18.4159 4.72884 19.096 5.57099 19.096ZM19.8541 17.5738C19.8541 16.7317 19.1739 16.0516 18.3319 16.0516C17.4898 16.0516 16.8097 16.7317 16.8097 17.5738C16.8097 18.4159 17.4898 19.096 18.3319 19.096C19.1739 19.096 19.8541 18.4159 19.8541 17.5738ZM18.3319 14.5455L16.8259 10.918C16.7611 10.7399 16.583 10.6103 16.3724 10.6103H7.62759C7.43324 10.6103 7.25512 10.7399 7.19034 10.918L5.68434 14.5455H18.3319Z' />
      <path
        d='M8.91166 1.00019C9.11915 1.18342 9.25 1.45142 9.25 1.75C9.25 2.30229 8.80228 2.75 8.25 2.75C7.95142 2.75 7.68341 2.61915 7.50019 2.41166M8.91166 1.00019C8.97072 1 9.03338 1 9.1 1H14.9C14.9666 1 15.0293 1 15.0883 1.00019M8.91166 1.00019C8.47426 1.00154 8.23447 1.01296 8.04601 1.10899C7.85784 1.20486 7.70486 1.35785 7.60899 1.54601C7.51296 1.73448 7.50154 1.97426 7.50019 2.41166M7.50019 2.41166C7.5 2.47072 7.5 2.53338 7.5 2.6V5.4C7.5 5.4666 7.5 5.5293 7.50019 5.58835M15.0883 1.00019C14.8808 1.18342 14.75 1.45142 14.75 1.75C14.75 2.30229 15.1977 2.75 15.75 2.75C16.0485 2.75 16.3166 2.61915 16.4998 2.41166M15.0883 1.00019C15.5257 1.00154 15.7655 1.01296 15.954 1.10899C16.1421 1.20486 16.2951 1.35785 16.391 1.54601C16.487 1.73448 16.4984 1.97426 16.4998 2.41166M7.50019 5.58835C7.68341 5.38085 7.95142 5.25 8.25 5.25C8.80228 5.25 9.25 5.6977 9.25 6.25C9.25 6.54855 9.11915 6.8166 8.91166 6.9998M7.50019 5.58835C7.50154 6.02575 7.51296 6.2655 7.60899 6.454C7.70486 6.64215 7.85784 6.79515 8.04601 6.891C8.23447 6.98705 8.47426 6.99845 8.91166 6.9998M8.91166 6.9998C8.97072 7 9.03338 7 9.1 7H14.9C14.9666 7 15.0293 7 15.0883 6.9998M15.0883 6.9998C14.8808 6.8166 14.75 6.54855 14.75 6.25C14.75 5.6977 15.1977 5.25 15.75 5.25C16.0486 5.25 16.3167 5.38095 16.5 5.58855M15.0883 6.9998C15.5257 6.99845 15.7655 6.98705 15.954 6.891C16.1421 6.79515 16.2951 6.64215 16.391 6.454C16.487 6.26555 16.4986 6.0258 16.5 5.58855M16.5 5.58855C16.5002 5.52945 16.5 5.4667 16.5 5.4V2.6C16.5 2.53338 16.5 2.47072 16.4998 2.41166M13 4C13 4.5523 12.5523 5 12 5C11.4477 5 11 4.5523 11 4C11 3.4477 11.4477 3 12 3C12.5523 3 13 3.4477 13 4Z'
        stroke='#363636'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  );
});
