import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { SupplierDto, SupplierReferenceDto } from "@/core/api/generated";

import AppIcon from "../../Icons/AppIcon";
import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getSupplierOptionTitle } from "./SupplierAutocomplete";

export interface SupplierInlineProps extends BaseEntityInlineInheritableProps {
  entity: SupplierDto | SupplierReferenceDto | null | undefined;
  entityId?: string | null;
}

/** Inline brief information about the entity. */
export default function SupplierInline({ entity, entityId, ...otherProps }: SupplierInlineProps) {
  const request = useApiRequest(
    apiClient.suppliersApi.apiV1SuppliersSupplierIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      supplierId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as SupplierDto;
  // const ref = entity as SupplierReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      icon={<AppIcon of='supplier' inText />}
      content={<>{full && getSupplierOptionTitle(full)}</>}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Supplier "}
    />
  );
}
