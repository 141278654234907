import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  LinearProgress,
  Stack,
  Tooltip,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

import { NegotiationHelper } from "@/common/helpers/entity/negotiation";
import { renderIf } from "@/common/helpers/render/renderIf";
import { TextHelper } from "@/common/helpers/text";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { useCurrentUserPartiesMembership } from "@/common/hooks/entity/party/useCurrentUserPartiesMembership";
import { useRunOnceOnMount } from "@/common/hooks/mount/useRunOnceOnMount";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useModalOpenStatus } from "@/common/hooks/useModalOpenStatus";
import { enumService } from "@/common/services/enum";
import { ValidationHelper } from "@/common/validation";
import {
  ApprovalResponseType,
  GeneralScopeInfoDto,
  PairDtoOfStringAndString,
  RespondOnManyProposalsDto,
  RespondOnManyProposalsInfoDto,
} from "@/core/api/generated";
import * as negotiationsSlice from "@/store/communication/negotiationsSlice";

import InlineApiEnumValue from "../../../Enum/InlineApiEnumValue";
import AppIcon from "../../../Icons/AppIcon";
import AppModalTitle from "../../../Modals/AppModalTitle";
import TooltipFacade from "../../../Mui/Facades/TooltipFacade";
import NegotiationProposal from "./NegotiationProposal";

export interface OwnProps {
  variant: "manyProposalsInOneNegotiation" | "manyProposalsInManyNegotiations";
  byNegotiationAndProposalId?: PairDtoOfStringAndString[] | null;
  byParentScope?: GeneralScopeInfoDto | null;
  byScopes?: GeneralScopeInfoDto[] | null;
  onlyForProposalsWithoutPartyMemberResponse?: RespondOnManyProposalsDto["onlyForProposalsWithoutPartyMemberResponse"];
  onlyForProposalsWithStatuses?: RespondOnManyProposalsDto["onlyForProposalsWithStatuses"];
  disabled?: boolean;
  autoClickOnceOnMountOn?: "approveAll" | "declineAll";
}

type Props = OwnProps;

/** Allows to respond on many proposals in one or many negotiations with one click. */
export default function RespondOnManyNegotiationProposalsControl({
  variant,
  byNegotiationAndProposalId,
  byParentScope,
  byScopes,
  onlyForProposalsWithoutPartyMemberResponse,
  onlyForProposalsWithStatuses,
  disabled,
  autoClickOnceOnMountOn,
}: Props) {
  const canRespondOnAll = !!byNegotiationAndProposalId || !!byParentScope || !!byScopes;
  const _disabled = !canRespondOnAll || disabled;

  const { enqueueSnackbar } = useAppSnackbar();
  const thunkDispatch = useAppThunkDispatch();
  const partiesMembership = useCurrentUserPartiesMembership();
  const authorizationInfo = useAuthorizationInfo();

  const isGetRespondOnAllProposalsInfoLoading = useAppSelector(
    (x) => x.communication.negotiations.loading.getRespondOnAllProposalsInfo,
  );

  const approveAllButtonRef = useRef<HTMLButtonElement>(null);
  const declineAllButtonRef = useRef<HTMLButtonElement>(null);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isRespondLoading, setIsRespondLoading] = useState(false);
  const [postDto, setPostDto] = useState<RespondOnManyProposalsDto | undefined>(undefined);
  const [respondOnManyInfo, setRespondOnManyInfo] = useState<
    RespondOnManyProposalsInfoDto | undefined
  >(undefined);

  const confirmModalOpenStatus = useModalOpenStatus(isConfirmModalOpen);

  const canConfirmAndRespondOnAll =
    !isGetRespondOnAllProposalsInfoLoading &&
    respondOnManyInfo &&
    respondOnManyInfo.proposals!.length !== 0 &&
    postDto;

  useRunOnceOnMount(() => {
    if (autoClickOnceOnMountOn === "approveAll") {
      approveAllButtonRef.current?.click();
    } else if (autoClickOnceOnMountOn === "declineAll") {
      declineAllButtonRef.current?.click();
    }
  });

  useEffect(() => {
    (async () => {
      if (confirmModalOpenStatus.isOpening) {
        setRespondOnManyInfo(undefined);
        try {
          const data = await thunkDispatch(
            negotiationsSlice.getRespondOnManyProposalsInfo({
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              respondOnManyProposalsInfoRequestDto: {
                byNegotiationAndProposalId,
                byScopes,
                byParentScope: byParentScope || undefined,
                onlyForProposalsWithoutPartyMemberResponse,
                onlyForProposalsWithStatuses,
              },
            }),
          );
          setRespondOnManyInfo(data);
        } catch (err) {
          const validation2 = ValidationHelper.handleApiErrorResponse(err);
          if (validation2.hasErrors) {
            enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
          }
        }
      }
    })();
  }, [confirmModalOpenStatus]);

  const handleRespondClick = async (responseType: ApprovalResponseType) => {
    setPostDto({
      byNegotiationAndProposalId,
      byScopes,
      byParentScope: byParentScope || undefined,
      onlyForProposalsWithoutPartyMemberResponse,
      onlyForProposalsWithStatuses,
      responseType,
    });
    setIsConfirmModalOpen(true);
  };

  const respondOnAll = async () => {
    setIsRespondLoading(true);
    if (!postDto || !respondOnManyInfo) {
      return;
    }

    try {
      await thunkDispatch(
        negotiationsSlice.respondOnManyProposals({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          respondOnManyProposalsDto: {
            ...postDto,
            byNegotiationAndProposalId: postDto?.byNegotiationAndProposalId
              ? respondOnManyInfo.proposals?.map((p) => ({ first: p.negotiationId, second: p.id }))
              : undefined,
          },
        }),
      );
      enqueueSnackbar("All responses saved.", { variant: "success" });
      setIsConfirmModalOpen(false);
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      if (validation2.hasErrors) {
        enqueueSnackbar(`Error: ${validation2.getErrorsAsString()}`, { variant: "error" });
      }
    } finally {
      setIsRespondLoading(false);
    }
  };

  return (
    <Stack direction='row' justifyContent='flex-end'>
      <Stack direction='row' spacing={1} alignItems='center'>
        <TooltipFacade title='Approve' enabled={variant === "manyProposalsInOneNegotiation"}>
          <Button
            ref={approveAllButtonRef}
            disabled={_disabled}
            variant='contained'
            color='success'
            size='small'
            startIcon={<AppIcon of='approveAll' />}
            onClick={() => handleRespondClick(ApprovalResponseType.Approve)}
          >
            {NegotiationHelper.getApprovalResponseTypeForManyText(
              ApprovalResponseType.Approve,
              authorizationInfo,
            )}
          </Button>
        </TooltipFacade>
        <TooltipFacade title='Decline' enabled={variant === "manyProposalsInOneNegotiation"}>
          <Button
            ref={declineAllButtonRef}
            disabled={_disabled}
            variant='contained'
            color='error'
            size='small'
            startIcon={<AppIcon of='declineAll' />}
            onClick={() => handleRespondClick(ApprovalResponseType.Decline)}
          >
            {NegotiationHelper.getApprovalResponseTypeForManyText(
              ApprovalResponseType.Decline,
              authorizationInfo,
            )}
          </Button>
        </TooltipFacade>

        {variant === "manyProposalsInManyNegotiations" && (
          <Tooltip title='Respond on all negotiation proposals with one click'>
            <AppIcon
              of='info'
              fontSize='small'
              sx={{ color: (theme) => theme.palette.text.secondary }}
            />
          </Tooltip>
        )}
        {!canRespondOnAll && (
          <Tooltip title={`You can't respond on all proposals.`}>
            <AppIcon
              of='info'
              fontSize='small'
              sx={{ color: (theme) => theme.palette.text.secondary }}
            />
          </Tooltip>
        )}
      </Stack>

      {/* Confirm */}
      <Dialog
        open={isConfirmModalOpen}
        fullWidth
        maxWidth='sm'
        onClose={() => setIsConfirmModalOpen(false)}
      >
        <AppModalTitle onCloseClicked={() => setIsConfirmModalOpen(false)}>
          Respond on all negotiation proposals?
        </AppModalTitle>
        <DialogContent>
          {isGetRespondOnAllProposalsInfoLoading && <LinearProgress />}

          {!isGetRespondOnAllProposalsInfoLoading && respondOnManyInfo && (
            <>
              {renderIf()
                .if(respondOnManyInfo.proposals!.length === 0)
                .then(
                  <Alert severity='success' icon={<AppIcon of='done' fontSize='inherit' />}>
                    You have already responded on all negotiation proposals.
                  </Alert>,
                )
                .else(
                  <Stack spacing={2}>
                    <DialogContentText>
                      {`You're`} going to respond on the next {respondOnManyInfo.proposalCount}{" "}
                      {TextHelper.pluralize("proposal", respondOnManyInfo.proposalCount!)} with a
                      single response -{" "}
                      <InlineApiEnumValue
                        type='ApprovalResponseType'
                        value={postDto?.responseType}
                        sx={{ fontWeight: "bold" }}
                      />
                      :
                    </DialogContentText>
                    <Stack spacing={1}>
                      {respondOnManyInfo.proposals?.map((proposal) => {
                        const negotiation = respondOnManyInfo.negotiations?.find(
                          (n) => n.id === proposal.negotiationId,
                        );
                        return (
                          (negotiation && (
                            <NegotiationProposal
                              key={proposal.id}
                              negotiation={negotiation}
                              proposal={proposal}
                              allowedActions={undefined}
                              partiesMembership={partiesMembership}
                              displayProps={{
                                avatar: false,
                                responses: false,
                                result: false,
                                scrollToChatButton: false,
                                menu: false,
                              }}
                            />
                          )) ||
                          null
                        );
                      })}
                    </Stack>
                  </Stack>,
                )
                .render()}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color='text' onClick={() => setIsConfirmModalOpen(false)}>
            Cancel
          </Button>
          {canConfirmAndRespondOnAll && (
            <Button
              variant='contained'
              color='primary'
              loading={isRespondLoading}
              onClick={respondOnAll}
              autoFocus
            >
              Yes, {enumService.getEnumValueName("ApprovalResponseType", postDto!.responseType!)}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
