import { Box, Chip, Stack, Typography } from "@mui/material";

import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AppTooltip from "@/common/components/AppTooltip";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FileListView from "@/common/components/Files/FileListView";
import AppTypography from "@/common/components/Text/AppTypography";
import { DATETIME_FORMATS } from "@/common/constants/common";
import { FileItem } from "@/common/fileItem";
import { TypeHelper } from "@/common/helpers/type";
import { AppPermission, AutomationCreateMode, GeneralHistoryDto } from "@/core/api/generated";

import GeneralByWhoDisplay from "../../General/Display/GeneralByWhoDisplay";
import GeneralAttachedTagsDisplay from "../../General/GeneralTag/GeneralAttachedTagsDisplay";
import GeneralHistoryMenu from "../GeneralHistoryMenu";
import GeneralHistoryItemTitle from "./GeneralHistoryItemTitle";

type OwnProps = {
  item: GeneralHistoryDto;
  viewVariant: ViewLayoutVariant;
  onUpdated: (newValue: GeneralHistoryDto) => void;
  onDeleted: () => void;
};

type Props = OwnProps;

export default function GeneralHistoryListItem({ item, viewVariant, onUpdated, onDeleted }: Props) {
  return (
    <ViewContentBlock variant='one-sided'>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xxs: "1fr",
            md: "2fr 1fr 0fr",
          },
          gridTemplateRows: "auto",
          rowGap: 1,
          columnGap: 1,
        }}
      >
        <Stack spacing={0.5}>
          {/* Title */}
          <AppTypography
            variant='subtitle1'
            ellipsing={{ enabled: true, linesToStartEllipsing: 5 }}
          >
            <Stack direction='row' spacing={0.5} alignItems='center'>
              <GeneralHistoryItemTitle item={item} />

              <Chip
                variant='outlined'
                size='extraSmall'
                color='default'
                label={
                  <InlineApiEnumValue
                    type='GeneralHistoryType'
                    value={item.type}
                    withHelperTooltip
                  />
                }
              />

              {item.autoCreateMode === AutomationCreateMode.Manual && (
                <Chip
                  variant='outlined'
                  size='extraSmall'
                  color='default'
                  label={
                    <InlineApiEnumValue
                      type='AutomationCreateMode'
                      value={item.autoCreateMode}
                      withHelperTooltip
                    />
                  }
                />
              )}

              <EntityChipList entity={item} variant='compact' />
            </Stack>
          </AppTypography>

          {/* Content */}
          {item.event?.arbitrary?.description && (
            <AppTypography variant='body2' ellipsing={{ enabled: true, linesToStartEllipsing: 5 }}>
              {item.event?.arbitrary?.description}
            </AppTypography>
          )}

          {item.event?.vehicle?.mileageChanged?.notes && (
            <AppTypography variant='body2' ellipsing={{ enabled: true, linesToStartEllipsing: 5 }}>
              {item.event?.vehicle?.mileageChanged?.notes}
            </AppTypography>
          )}

          {/* Attachments */}
          {!TypeHelper.isEmpty(item.attachments) && (
            <FileListView
              files={FileItem.createManyFrom(item.attachments)}
              maxVisibleFiles={10}
              itemWidth={100}
              itemHeight={80}
            />
          )}

          {/* Tags */}
          {item.tags && (
            <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
              <GeneralAttachedTagsDisplay tags={item.tags} defaultIsFolded={false} />
            </AuthorizedElement>
          )}
        </Stack>

        <Typography component='span' variant='body2'>
          <Stack
            direction='row'
            spacing={0.5}
            sx={{ justifyContent: "flex-end", alignItems: "center" }}
          >
            <Stack>
              <Box>
                <span>Raised</span> at{" "}
                {item.raisedAt ? (
                  <AppTooltip
                    title={
                      <Box>
                        Precise time -{" "}
                        <Datetime
                          datetime={item.raisedAt}
                          format={DATETIME_FORMATS.DISPLAY_DATETIME_DETAILED}
                          withDurationFromNow
                        />
                      </Box>
                    }
                  >
                    <AppTypography component='span' decoration={{ variant: "helpText" }}>
                      <Datetime datetime={item.raisedAt} withDurationFromNow />
                    </AppTypography>
                  </AppTooltip>
                ) : (
                  "-"
                )}
              </Box>
              <Box>
                <span>Raised</span> by{" "}
                {item.raisedBy ? <GeneralByWhoDisplay who={item.raisedBy} /> : "-"}
              </Box>
            </Stack>
          </Stack>
        </Typography>

        {/* Controls */}
        <Box>
          <GeneralHistoryMenu entity={item} onUpdated={onUpdated} onDeleted={onDeleted} />
        </Box>
      </Box>
    </ViewContentBlock>
  );
}
